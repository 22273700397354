/* eslint-disable no-restricted-imports */

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogHapusBatal(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="xs"
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{zIndex:'999999999'}}
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            {props.btn2}
          </Button>
          <Button id={props.type+props.id} onClick={props.fun} color="primary">
            {props.btn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogHapusBatal.defaultProps = {
  btn2: "Batal"

}
