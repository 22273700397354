import React, { useEffect } from "react";
import $ from "jquery";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import TemplateUploadData from "../DownloadTemplate/template_upload_all_leasing.csv";
import { ProgressBar, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import API from "../../API";
import { AlertList } from "react-bs-notifier";
import bsCustomFileInput from "bs-custom-file-input";
import DialogHapusBatal from "../Utils/DialogHapusBatal";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

const UploadDataLeasing = (props) => {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [url, setUrl] = React.useState(null);
  const [alerts, setAlerts] = React.useState([]);
  const [uploadtype, setUploadtype] = React.useState(null);
  const [uploadname, setUploadname] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const history = useHistory();

  const fileUpload = (e) => {
    if (file == null) {
      $(".custom-file-label").text("Pilih File");
    }
    setFile(e.target.files[0]);
  };

  function handleClickOpen() {
    if (
      $("#upload_type").val() == null ||
      $("#upload_type").val() == undefined ||
      $("#upload_type").val() == ""
    ) {
      setAlerts([
        {
          id: 1,
          type: "info",
          message: "Silahkan Pilih Tipe Upload",
        },
      ]);
      setTimeout(() => {
        setAlerts([]);
      }, 3000);
    } else if (file == null || file == undefined) {
      $(".custom-file-label").text("Pilih File");
      setAlerts([
        {
          id: 1,
          type: "info",
          message: "Silahkan Pilih File",
        },
      ]);
      setTimeout(() => {
        setAlerts([]);
      }, 3000);
    } else {
      setOpen(true);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    $(document).ready(function() {
      bsCustomFileInput.init();
    });
  });

  function Status(e) {
    console.log(e.target.value);
    var value = e.target.value;
    if (value == 3) {
      // GANTIKAN DATA
      setUploadtype("Gantikan");
    } else if (value == 2) {
      // HAPUS DATA
      setUploadtype("Hapus");
    } else if (value == 1) {
      // TAMBAHKAN DATA
      setUploadtype("Tambahkan");
    } else {
      console.log("Tidak Ada");
      setUploadtype(null);
    }
  }

  function Upload() {
    handleClose();
    console.log($("#upload_file").prop("files")[0]);
    console.log($("#upload_type").val());
    var new_file = $("#upload_file").prop("files")[0];
    var mper_status = $("#upload_type").val();
    var new_url;
    if (mper_status == 3) {
      // GANTIKAN DATA
      new_url = API.Uploadapi + "v1/UploadFile/gantikanDataAllLeasing";
    } else if (mper_status == 2) {
      // HAPUS DATA
      console.log("Hapus Data");
      new_url = API.Uploadapi + "v1/UploadFile/hapusDataAllLeasing";
    } else if (mper_status == 1) {
      // TAMBAHKAN DATA
      new_url = API.Uploadapi + "v1/UploadFile/tambahkanDataAllLeasing";
    } else {
      console.log("Tidak Ada");
    }
    setUploadname(new_file.name);
    $("#uploadform").hide("slow");
    $("#uploadingprogress").show("slow");

    var form = new FormData();
    form.append("uploaded_file", $("#upload_file").prop("files")[0]);

    var settings = {
      xhr: function() {
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function(e) {
          if (e.lengthComputable) {
            console.log(e);
            console.log("Bytes Loaded : " + e.loaded);
            console.log("Total Size : " + e.total);
            console.log("Persen : " + e.loaded / e.total);

            var percent = Math.round((e.loaded / e.total) * 100);
            console.log("Persen : " + percent);

            setProgress(percent);
          }
        });
        return xhr;
      },
      async: true,
      crossDomain: true,
      url: new_url,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    };

    $.ajax(settings)
      .done(function(response) {
        $("#uploadform").show("slow");
        $("#uploadingprogress").hide("slow");
        setProgress(0);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Data Telah Diupload",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          $("#upload_type").val(0);
          $("#upload_file").val(null);
          $(".custom-file-label").text("Pilih File");
        }, 1000);
      })
      .fail(function(response) {
        $("#uploadform").show("slow");
        $("#uploadingprogress").hide("slow");
        setProgress(0);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
      });
  }

  return (
    <>
      <DialogHapusBatal
        open={open}
        close={handleClose}
        title="Upload Data Leasing"
        message={`Anda Yakin Ingin ${uploadtype} Data ?`}
        btn="Upload"
        fun={Upload}
      />
      <Portlet>
        <PortletHeader
          title="Upload Data Semua Leasing"
          toolbar={
            <PortletHeaderToolbar>
              <a href={TemplateUploadData} className="btn btn-label-primary">
                <i className="fas fa-file-download"></i>Download Template
              </a>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <AlertList showIcon={false} alerts={alerts} />
          <div className="input-group" id="uploadform">
            <select
              className="custom-select"
              id="upload_type"
              onChange={Status}
              style={{ width: "10px" }}
              aria-label="Example select with button addon"
            >
              <option selected disabled value="0">
                Pilih Tipe Upload
              </option>
              <option name="mper_status" id="mper_status" value="3">
                Gantikan Data
              </option>
              <option name="mper_status" id="mper_status" value="1">
                Tambahkan Data
              </option>
              {/* <option name="mper_status" id="mper_status" value="2">
                Hapus Data
              </option> */}
            </select>
            <div className="custom-file">
              <input
                type="file"
                name="File"
                className="custom-file-input"
                id="upload_file"
                onChange={fileUpload}
              />
              <label
                className="custom-file-label"
                for="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02"
              >
                Pilih file
              </label>
            </div>
            <div className="input-group-append">
              <button className="btn btn-label-primary" onClick={handleClickOpen} type="button">
                Upload
              </button>
            </div>
          </div>
          <div id="uploadingprogress" style={{ display: "none" }}>
            <Card bg="primary" text="white">
              <Card.Body>
                <Card.Title>
                  <h3>Mengupload Data</h3>
                </Card.Title>
                <hr style={{ borderTop: "1px solid #f4a240" }} />
                <Card.Text>
                  <h4>
                    Nama File : <span>{uploadname}</span>
                  </h4>
                  <h4>
                    Proses :{" "}
                    <span>
                      {progress}% {progress == 100 ? "(Memproses Di Server)" : ""}
                    </span>
                  </h4>
                  <ProgressBar animated now={progress} variant="warning" />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <Row style={{ marginTop: "10px" }}>
            <Col md>
              <Link to="/leasing" className="btn btn-warning btn-elevate">
                <i className="fas fa-arrow-left"></i>Kembali
              </Link>
            </Col>
          </Row>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default UploadDataLeasing;
