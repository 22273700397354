import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import $ from 'jquery';
import 'jquery-validation';

const Transition = React.forwardRef( function Transition( props, ref ) {
    return <Slide direction ="up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

export default function DialogVoucherPaid(props) {
    const classes = useStyles();
    React.useEffect(()=>{
        $(document).ready(function () {
            $("#update_voucher").validate();
            $( '#lvoc_admin_tranfer' ).mask('#.##0', {reverse: true});
        })
    })

    function Submit () {
        if($("#update_voucher").valid() == true){
          props.submit()
        }
    }
    return(
        <>
        <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.close}
            aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" fullWidth={true}
            maxWidth="sm">
            <DialogActions>
                <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
                    <div style={{width:'100%'}}></div>
                <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent>
               <form id="update_voucher" onSubmit={(e) => {e.preventDefault()}}>
                <div className={classes.root}>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Harga</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="lvoc_admin_tranfer" name="lvoc_admin_tranfer"/>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop:'-15px',display:props.batal}}>
                    <label className="col-sm-4 col-form-label">Keterangan</label>
                    <div className="col-sm-8">
                        <textarea rows="3" className="form-control" id="lvoc_note" name="lvoc_note" autoComplete="off"/>
                    </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary btn-elevate pull-right" onClick={Submit}><i className="fas fa-save"></i>Simpan</button>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Batal</button>
               </form>
            </DialogContent>
        </Dialog>
        </>
    );

}