import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'jquery-mask-plugin';
import $ from 'jquery';


const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction = "up"
   ref = {
       ref
   } {
       ...props
   }
   />;
});

const useStyles = makeStyles(theme => ({
   root: {
       display: 'flex',
       flexDirection: 'column',
       margin: 'auto',
       width: '80%',
   },
}));

export default function DialogKuotaLain(props) {
   React.useEffect(() => {
      $('#max_quota_lain').mask('999', {reverse:true})
   })
   const classes = useStyles();

   return (
      <div>
         <Dialog
            open={props.open}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="sm"
            keepMounted
            onClose={props.close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            style={{zIndex:'999999999'}}
         >
            <DialogTitle className="dptitle" id="alert-dialog-slide-title">{props.title}</DialogTitle>
            <DialogContent id="kuotalaindialog">
               <form className={classes.root} id="formsKuotaLain" autoComplete="off">
                  <div className="form-group row" style={{display:props.bonus}}>
                     <label for="inputEmail3" className="col-sm-5 col-form-label">Provinsi</label>
                     <div className="col-sm-7">
                        <input type="text" className="form-control" id="quota_province_lain" disabled />
                     </div>
                  </div>
                  <div className="form-group row" style={{display:props.bonus}}>
                     <label for="inputEmail3" className="col-sm-5 col-form-label">Max Kuota Downline</label>
                     <div className="col-sm-7">
                        <input type="text" className="form-control" id="quota_max_lain" placeholder="" />
                     </div>
                  </div>
               </form>
            </DialogContent>
            <DialogActions>
               <Button onClick={props.close} color="primary">
                  Batal
               </Button>
               <Button onClick={props.fun} color="primary">
                  {props.btn}
               </Button>
          </DialogActions>
         </Dialog>
      </div>
   )
}