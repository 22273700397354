/* eslint-disable no-multi-str */
/* eslint-disable eqeqeq */
import $ from "jquery";
import API from "../../API";
import Utils from "../Utils/FunctionDetail";
import moment from "moment";

const formatter = new Intl.NumberFormat("de-DE");

export default {
  Droppoint: (id, state) => {
    $("#droppoint").DataTable({
      pagingType: "full_numbers",
      processing: true,
      serverSide: true,
      destroy: true,
      ajax: {
        url: API.Userapi + "v1/leasing/droppoint/" + id + "",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, state);
        },
      },
      fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        if (aData.tadp_status == 0) {
          $("td:eq(3)", nRow).css("background-color", "#FD333F");
        }

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }

        if (mm < 10) {
          mm = "0" + mm;
        }

        if (
          aData.tadp_valid_from > `${yyyy}-${mm}-${dd}` ||
          aData.tadp_valid_to < `${yyyy}-${mm}-${dd}`
        ) {
          $("td:eq(2)", nRow).css("background-color", "#FD333F");
        }
      },
      columns: [
        { data: "tadp_code" },
        { data: "tadp_name" },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + full.tadp_valid_from + " - " + full.tadp_valid_to + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + Utils.StatusDroppoint(full.tadp_status) + "</div>";
          },
        },
        {
          // sortable: true,
          // "render": function ( data, type, full, meta ) {
          //     return "<div id='aksi'> <button class='btn yellow-gold btn-xs btn-outline detail tooltips' id='detail' data-id="+full.tadp_id+"> <i class='fa fa-eye'></i></button></div>\
          //             <button class='btn blue btn-xs btn-outline edit tooltips' id='edit' data-id="+full.tadp_id+" mmle-id="+full.mmle_id+"> <i class='fa fa-edit'></i></button></div>\
          //             <button class='btn red btn-xs btn-outline hapus tooltips' id='hapus' data-id="+full.tadp_id+"><i class='fa fa-trash'></i></button></div>";

          sortable: true,
          render: function(data, type, full, meta) {
            // return "<div id='aksi'>"+
            // "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 edit tooltips' id='edit' data-id="+full.tadp_id+" mmle-id="+full.mmle_id+" data-original-title='Edit User'><i class='fa fa-edit'> </i> </button>"+
            // "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detaildp' data-id="+full.tadp_id+
            //     " data-original-title='Detail User'> <i class='fa fa-eye'> </i> </button>"+
            // "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 hapus tooltips' id='hapusdp' data-id="+full.tadp_id+
            //     " data-original-title='Hapus User'><i class='fa fa-trash'> </i></button>"+
            // "</div>";
            return `
                              <button id="editleasing1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="editleasing1">
                                  <button class='dropdown-item' id='edit' data-id="${full.tadp_id}" mmle-id="${full.mmle_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-edit'></i>Edit Drop Point</button>
                                  <button class='dropdown-item' id='detaildp' data-id="${full.tadp_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail Drop Point</button>
                                  <button class='dropdown-item' id='hapusdp' data-id="${full.tadp_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus Drop Point</button>
                              </div>`;
          },
        },
      ],
    });
  },

  Config: (id, state) => {
    $("#config_leasing").DataTable({
      pagingType: "full_numbers",
      processing: true,
      serverSide: true,
      destroy: true,
      ajax: {
        url: API.Userapi + "v1/Leasing/listConfig/" + id,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, state);
        },
      },
      columns: [
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var name = "????";
            if (full.conf_name == "approver_dinamis_conf") {
              name = "SMS Approver";
            }
            return name;
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var name = "Non Aktif";
            if (full.conf_value == 1) {
              name = "Aktif";
            }
            return name;
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return (
              "<div id='aksi'>\
                            <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips' id='changeSetting' data-id=" +
              full.conf_id +
              ">\
                              <i class='fa fa-check-circle'></i></button>\
                              </div>"
            );
          },
        },
      ],
    });
    $(".dataTables_filter,.dataTables_paginate").addClass("pull-right");
  },

  Administrator: (id, state) => {
    $("#administrator").DataTable({
      pagingType: "full_numbers",
      processing: true,
      serverSide: true,
      destroy: true,
      ajax: {
        url: API.Userapi + "v1/leasing/administrator/" + id + "",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, state);
        },
      },
      columns: [
        { data: "mper_name" },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + Utils.MperAll(full.mper_type) + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + Utils.StatusUser(full.mper_status) + "</div>";
          },
        },
        {
          // sortable: true,
          // "render": function ( data, type, full, meta ) {
          //     return "<div id='aksi'>\
          //             <button class='btn yellow-gold btn-xs btn-outline detailAdministrator tooltips' id='detailAdministrator' data-id="+full.mper_id+"> <i class='fa fa-eye'></i></button></div>\
          //             <button class='btn blue btn-xs btn-outline editAdministrator tooltips' id='editAdministrator' data-id="+full.mper_id+" > <i class='fa fa-edit'></i></button></div>\
          //             <button class='btn red btn-xs btn-outline hapusAdministrator tooltips' id='hapusAdministrator' data-id="+full.mper_id+"><i class='fa fa-trash'></i></button></div>";
          sortable: true,
          render: function(data, type, full, meta) {
            //   return "<div id='aksi'>"+
            //   "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 editAdministrator tooltips' id='editAdministrator' data-id="+full.mper_id+
            //     " data-original-title='Edit User'><i class='fa fa-edit'> </i> </button>"+
            //   "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detailAdministrator tooltips' id='detailAdministrator' data-id="+full.mper_id+
            //     " data-original-title='Detail User'> <i class='fa fa-eye'> </i> </button>"+
            //   "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 hapusAdministrator tooltips' id='hapusAdministrator' data-id="+full.mper_id+
            //     " data-original-title='Hapus User'><i class='fa fa-trash'> </i></button>"+
            // "</div>";
            return `
                              <button id="editleasing1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="editleasing1">
                                  <button class='dropdown-item' id='editAdministrator' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-edit'></i>Edit Administrator</button>
                                  <button class='dropdown-item' id='detailAdministrator' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail Administrator</button>
                                  <button class='dropdown-item' id='hapusAdministrator' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus Administrator</button>
                              </div>`;
          },
        },
      ],
    });
  },

  Pricing: (id, state) => {
    $("#pricing").DataTable({
      pagingType: "full_numbers",
      processing: true,
      serverSide: true,
      destroy: true,
      ajax: {
        url: API.Userapi + "v1/leasing/pricing/" + id,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, state);
        },
      },
      dom:
        "<'row'<'col-sm-12 col-md-5'l><'col-sm-12 col-md-7'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          // extend: "excel",
          className: "btn btn-primary mt-4",
          text: '<i class="fas fa-file-excel"></i>Export CSV ALL',
          action: function(e, dt, node, config) {
            $.ajax({
              url: API.Userapi + "v1/leasing/download_pricing/" + id + "",
              method: "GET",
              xhrFields: {
                responseType: "blob",
              },
              success: function(data) {
                var a = document.createElement("a");
                var url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = "pricing_" + id + ".csv";
                document.body.append(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
              },
            });
          },
        },
      ],
      columns: [
        { data: "tprc_ptype" },
        { data: "tprc_vyear" },
        { data: "tprc_cwo_wo" },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + formatter.format(parseInt(full.tprc_base_price)) + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + formatter.format(parseInt(full.tprc_bp_stnk)) + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + formatter.format(parseInt(full.tprc_fee)) + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + formatter.format(parseInt(full.tprc_fee_stnk)) + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            // return "<div id='aksi'>\
            //         <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 editPricing tooltips' id='editPricing' data-id='"+full.tprc_id+"' mmle-id='"+full.mmle_id+"'> <i class='fa fa-edit'></i></button></div>\
            //         <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 hapusPricing tooltips' id='hapusPricing' data-id='"+full.tprc_id+"' data-type='"+full.tprc_ptype+"' data-year='"+full.tprc_vyear+"' data-cwowo='"+full.tprc_cwo_wo+"'>"+
            //           "<i class='fa fa-trash'></i></button></div>";
            return `
                              <button id="editleasing2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="editleasing2">
                                  <button class='dropdown-item' id='editPricing' data-id="${full.tprc_id}" mmle-id="${full.mmle_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-edit'></i>Edit Pricing</button>
                                  <button class='dropdown-item' id='hapusPricing' data-id="${full.tprc_id}" data-type="${full.tprc_ptype}" data-year="${full.tprc_vyear}" data-cwowo="${full.tprc_cwo_wo}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus Pricing</button>
                              </div>`;
          },
        },
      ],
    });
  },

  IncompletePricing: (id, state) => {
    var searchPricing = {
      async: true,
      crossDomain: true,
      url: API.Uploadapi + "v1/IncompletePricing/searchPricing/" + id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
      // dom: "Bfrtip",
      // Excel: [
      //   {
      //     extend: "excel",
      //     className: "btn blue btn-outline",
      //     text: '<i class="fa fa-download">&nbspExcel</i>',
      //     titleAttr: "Export Excel",
      //   },
      // ],
      // lengthMenu: [
      //   [5, 10, 15, 20, -1],
      //   [5, 10, 15, 20, "All"],
      // ],
    };
    $.ajax(searchPricing)
      .done(function(response) {
        console.log(response);
        if (response.draw != 0) {
          $("#incomplete_price").css("display", "none");
        } else {
          $("#incomplete_price_table").DataTable({
            pagingType: "full_numbers",
            aaSorting: [],
            // dom: '<"top"Blf>rt<"bottom"ip><"clear">',
            // dom: '<"row"<"col-sm-12 col-md-6"lB><"col-sm-12 col-md-6"f>>rt<"bottom"ip><"clear">',
            dom:
              "<'row'<'col-sm-12 col-md-5'l><'col-sm-12 col-md-7'Bf>>" +
              "<'row'<'col-sm-12'tr>>" +
              "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
              {
                // extend: "excel",
                className: "btn btn-primary mt-4",
                text: '<i class="fas fa-file-excel"></i>Export CSV ALL',
                action: function(e, dt, node, config) {
                  $.ajax({
                    url: API.Uploadapi + "v1/IncompletePricing/downloadPricing/" + id + "",
                    method: "GET",
                    xhrFields: {
                      responseType: "blob",
                    },
                    success: function(data) {
                      var a = document.createElement("a");
                      var url = window.URL.createObjectURL(data);
                      a.href = url;
                      a.download = "incomplete_pricing_" + id + ".csv";
                      document.body.append(a);
                      a.click();
                      a.remove();
                      window.URL.revokeObjectURL(url);
                    },
                  });
                },
              },
            ],
            lengthMenu: [
              [5, 10, 15, 20, -1],
              [5, 10, 15, 20, "All"],
            ],
            pageLength: 10,
            processing: true,
            serverSide: true,
            destroy: true,
            ajax: {
              url: API.Uploadapi + "v1/IncompletePricing/searchPricing/" + id + "",
              beforeSend: function(xhr) {
                xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
              },
              error: function(xhr, error, thrown, settings) {
                API.Handle403(xhr, state);
              },
            },
            columns: [
              { data: "tdbd_ptype" },
              { data: "tdbd_vyear" },
              { data: "tdbd_cwo_wo" },
              {
                sortable: false,
                render: function(data, type, full, meta) {
                  return "??????";
                },
              },
              {
                sortable: false,
                render: function(data, type, full, meta) {
                  return "??????";
                },
              },
              {
                sortable: false,
                render: function(data, type, full, meta) {
                  return "??????";
                },
              },
              {
                sortable: false,
                render: function(data, type, full, meta) {
                  return "??????";
                },
              },
              {
                sortable: false,
                render: function(data, type, full, meta) {
                  console.log(full);
                  return (
                    "<div id='aksi'>" +
                    // <a href='leasing/add_pricing?mmle_id="+id+"&tdbd_ptype="+full.tdbd_ptype+"&tdbd_vyear="+full.tdbd_vyear+"&tdbd_cwo_wo="+full.tdbd_cwo_wo+"'  "+
                    //   "class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 addPpricing tooltips' id='addPpricing'> <i class='fa fa-edit'></i>"+
                    "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 editPricing tooltips' id='addInPricing' data-cwo='" +
                    full.tdbd_cwo_wo +
                    "' data-ptype='" +
                    full.tdbd_ptype +
                    "' data-vyear='" +
                    full.tdbd_vyear +
                    "'> <i class='fa fa-edit'></i></button></div>\
                                    </div>"
                  );
                },
              },
            ],
          });
          $(".dataTables_filter,.dataTables_paginate").addClass("pull-right");

          $("#incomplete_price").css("display", "block");
          //   $('.dt-buttons').css('text-align','center');
        }
      })
      .fail(function(response) {
        console.log(response);
        API.Handle403(response, state);
      });
  },

  DetailLeasing: (id, state) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/leasing/detail_leasing/" + id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        console.log(response);
        if (response.data != null) {
          $("#mmle_name").val(response.data.mmle_name);
          $("input[name=mmle_status][value=" + response.data.mmle_status + "]").prop(
            "checked",
            true
          );
          $("#mmle_status").val(Utils.StatusUser(response.data.mmle_status));
          $("#mmle_pic_name1").val(response.data.mmle_pic_name1);
          $("#mmle_pic_phone1").val(response.data.mmle_pic_phone1);
          $("#mmle_pic_email1").val(response.data.mmle_pic_email1);
          $("#mmle_ho_address").val(response.data.mmle_ho_address);
          $("#mmle_pic_name2").val(response.data.mmle_pic_name2);
          $("#mmle_pic_phone2").val(response.data.mmle_pic_phone2);
          $("#mmle_pic_email2").val(response.data.mmle_pic_email2);
          $("#app_type").val(response.data.app_type);
          $("#vldfrom").datepicker("setDate", response.data.mmle_valid_from);
          $("#vldto").datepicker("setDate", response.data.mmle_valid_to);
          if (response.data.mmle_logo != null) {
            $("#protleas").hide("slow");
            $("#lihatleas").attr("href", response.data.mmle_logo);
            $("#cancelleas").hide();
            $(document).on("click", "#deleteleas", function() {
              $("#protleas").show("slow");
              $("#cancelleas").show();
              $("#lihatleas").hide();
              $("#deleteleas").hide();
              console.log("Delete");
            });
            $(document).on("click", "#cancelleas", function() {
              $("#lihatleas").show();
              $("#deleteleas").show();
              $("#protleas").hide("slow");
              $("#cancelleas").hide();
            });
          } else {
            $("#lihatleas").hide();
            $("#deleteleas").hide();
            $("#cancelleas").hide();
          }
        }
      })
      .fail(function(response) {
        console.log(response);
        API.Handle403(response, state);
      });
  },
};
