/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import {
    Portlet,
    PortletBody,
    // PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
// import Utils from './FunctionDetail'
import $ from 'jquery'

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset : theme.mixins.toolbar,
    marginTop : '70px'
  } 
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailKendaraan(props) {
  const classes = useStyles();

  React.useEffect(() => {
    // console.log($(this).attr('data-id'))

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Uploadapi +"v1/DetailKendaraan/detail/"+props.id,
      "method": "GET",
      "headers": {
        "Authorization": "Baisc " + localStorage.getItem('jwt')
      }
    }

    if (props.id != null) {      
      $.ajax(settings).done(function (response) {
        console.log(response);
        const formatter = new Intl.NumberFormat('de-DE');
        if (response.data != null) {
            $('#tvec_contract').val(response.data.tvec_contract);
            $('#mmle_name').val(response.data.mmle_name);
            $('#tvec_branch').val(response.data.tvec_branch);
            $('#tvec_customer_name').val(response.data.tvec_customer_name);
            $('#tvec_ic_number').val(response.data.tvec_ic_number);
            $('#tvec_kk_number').val(response.data.tvec_kk_number);
            $('#tvec_debt_date').val(response.data.tvec_debt_date);
            $('#tvec_installment').val(response.data.tvec_installment);
            $('#tvec_invoice_qty').val(response.data.tvec_invoice_qty);
            $('#tvec_duration').val(response.data.tvec_duration);
            $('#tvec_address').val(response.data.tvec_address);
            $('#tvec_vin').val(response.data.tvec_vin);
            $('#tvec_ein').val(response.data.tvec_ein);
            $('#tvec_vdoc_name').val(response.data.tvec_vdoc_name);
            if (response.data.tvec_monthly_charge == null || response.data.tvec_monthly_charge == ""){
              $('#tvec_monthly_charge').val(response.data.tvec_monthly_charge);
            }else{
              $('#tvec_monthly_charge').val(formatter.format(parseInt(response.data.tvec_monthly_charge)));
            }
            if (response.data.tvec_total_unpaid == null || response.data.tvec_total_unpaid == ""){
              $('#tvec_total_unpaid').val(response.data.tvec_total_unpaid);
            }else{
              $('#tvec_total_unpaid').val(formatter.format(parseInt(response.data.tvec_total_unpaid)));
            }
        }
      })
    }

  })

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>                       
            <Typography variant="h6" className={classes.title}>
                Detail Kendaraan
            </Typography>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Portlet className={classes.detail}>
          <PortletBody>
            <Row>
              <Col md={6}>
              <div className="form-group row">
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Polisi</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id='tpnm_vplate' disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Unit Kendaraan</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id='tpnm_vtype' disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Kontrak</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id='tvec_contract' disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Leasing</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id='mmle_name' disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Cabang</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id="tvec_branch" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Pemohon</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id="tvec_customer_name" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor KTP</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id="tvec_ic_number" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor KK</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id='tvec_kk_number' disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Mulai Tunggak</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id="tvec_debt_date" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Angsuran Ke</label>
                <div className="col-sm-7">
                  <input type="email" className="form-control" id="tvec_installment" disabled />
                </div>
              </div>
              </Col>
              <Col md={6}>
              <div className="form-group row" >
                <label for="inputEmail3" className="col-sm-4 col-form-label">Jumlah Kwitansi</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" id="tvec_invoice_qty" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Tenor</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" id="tvec_duration" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Angsuran Perbulan</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" id="tvec_monthly_charge" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Total Tunggakan</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" id="tvec_total_unpaid" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Alamat Konsumen</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" id="tvec_address" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Rangka</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" id="tvec_vin" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Mesin</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" id="tvec_ein" disabled />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px'}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor STNK/BPKP</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" id="tvec_vdoc_name" disabled />
                </div>
              </div>
              </Col>
            </Row>
            <Row>
              <Col md>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Kembali</button>
              </Col>
            </Row>
          </PortletBody>
        </Portlet>
      </Dialog>
    </div>
  );
}
