/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import API from '../../API'
// import $ from 'jquery'
// import { AlertList} from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
}));

export default function DetailDropPoint(props) {

    const classes = useStyles();

    return (
      <div>
          <Dialog
              open={props.open}
              TransitionComponent={Transition}
              fullWidth={true}
              maxWidth="sm"
              keepMounted
              onClose={props.close}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              style={{zIndex:'999999999'}}
          >
          <DialogTitle className="dptitle" id="alert-dialog-slide-title">Detail Drop Point</DialogTitle>
          <DialogContent>
            <form className={classes.root} id="dpleasing" autoComplete="off">
              <div className="form-group row" style={{display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Kode</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_code" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Drop Point</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_name" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Status</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_status" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Lat</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_lat" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Lng</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_lng" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Valid</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tanggal_valid" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Alamat</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_address" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Jam Buka</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_open_time" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Jam Tutup</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_closed_time" />
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      );

}

