/* eslint-disable eqeqeq */
import $ from 'jquery'
import API from '../../API'
import Utils from '../Utils/FunctionDetail'
import moment from 'moment'

var currentTime = moment().format("H:mm:ss");
var currentDate = moment().format("YYYY-MM-DD");

export default {
    ValidasiInforman : () => {
      $('#validasiinforman').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"],
                [1, 'desc']
              ],
              "ajax": {
                "url": API.Foundingapi + "/v1/Penemuan/list_informan/1/admin",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                // console.log(nRow)
                // console.log(aData)
                var jam = moment( aData.tpif_created_time, "H:mm:ss" ).add( 45, 'minutes') ;
                if ( aData.tpif_created_date == currentDate ) {
                  if ( jam.format("H:mm:ss") > currentTime ) {
                    // MASIH TIDAK EXPIRED
                  }else{
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                }else{
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                if ( aData.app_type == "mobil"  ){
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                }else if( aData.app_type == "motor"  ){
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.tpif_type)+"</div>";
                  }
                },
                {
                  "data": "tpif_created_date"
                },
                {
                  "data": "tpif_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // console.log(full)
                    var jam = moment(full.tpif_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name_informan"
                },
                {
                  "data": "mper_phone_informan"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpif_branch"
                },
                {
                  "data": "tpif_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
                    return " <div >  " + Utils.DataNull(full.tpif_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpif_vbrand) + " " + Utils.DataNull(full.tpif_ptype) + " " + Utils.DataNull(full.tpif_vcolor) + " " + Utils.DataNull(full.tpif_vyear) + "</div>";
                    // return " <div >  " + full.tpif_vbrand + " " + full.tpif_vyear + " " + full.tpif_vcolor + "</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (localStorage.getItem('role') != 5) {
                      return `
                        <button id="validasiinforman1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="validasiinforman1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpif_lat_informan+','+full.tpif_lng_informan}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpif_id}" data-unit="${Utils.DataNull(full.tpif_vtype).toUpperCase()} - ${Utils.DataNull(full.tpif_vbrand)} ${Utils.DataNull(full.tpif_ptype)} ${Utils.DataNull(full.tpif_vcolor)} ${Utils.DataNull(full.tpif_vyear)}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
                    } else {
                      return `
                        <button id="validasiinforman2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="validasiinforman2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpif_lat_informan+','+full.tpif_lng_informan}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpif_id}" data-unit="${Utils.DataNull(full.tpif_vtype).toUpperCase()} - ${Utils.DataNull(full.tpif_vbrand)} ${Utils.DataNull(full.tpif_ptype)} ${Utils.DataNull(full.tpif_vcolor)} ${Utils.DataNull(full.tpif_vyear)}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='batalinforman' data-id="${full.tpif_id}" data-step="${full.tpif_step}" data-original-title='Batal Penemuan'><i class='fas fa-times'></i>Batalkan</button>
                        </div>`;
                    }
                  }
                },
              ]
            });
    },

    Matchmaking : () => {
      $('#matchmaking').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"],
                [1, 'desc']
              ],
              "ajax": {
                "url": API.Foundingapi + "/v1/Penemuan/list_informan/2/admin",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                // console.log(nRow)
                // console.log(aData)
                // console.log(iDisplayIndex)
                // console.log(iDisplayIndexFull)
                var jam = moment( aData.tpif_created_time, "H:mm:ss" ).add( 45, 'minutes') ;
                if ( aData.tpif_created_date == currentDate ) {
                  if ( jam.format("H:mm:ss") > currentTime ) {
                    // MASIH TIDAK EXPIRED
                  }else{
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                }else{
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                if ( aData.app_type == "mobil"  ){
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                }else if( aData.app_type == "motor"  ){
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.tpif_type)+"</div>";
                  }
                },
                {
                  "data": "tpif_created_date"
                },
                {
                  "data": "tpif_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var jam = moment(full.tpif_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name_informan"
                },
                {
                  "data": "mper_phone_informan"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpif_branch"
                },
                {
                  "data": "tpif_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
                    return " <div >  " + Utils.DataNull(full.tpif_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpif_vbrand) + " " + Utils.DataNull(full.tpif_ptype) + " " + Utils.DataNull(full.tpif_vcolor) + " " + Utils.DataNull(full.tpif_vyear) + "</div>";
                    // return " <div >  " + full.tpif_vbrand + " " + full.tpif_vyear + " " + full.tpif_vcolor + "</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // console.log(full)
                    if (localStorage.getItem('role') != 5) {
                      return `
                      <button id="matchmaking1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="matchmaking1">
                        <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpif_lat_informan+','+full.tpif_lng_informan}"><i class="fa fa-map-marker"></i>Lokasi</a>
                        <button class='dropdown-item' id='informan' data-id="${full.tpif_id}" data-step="2" data-unit="${Utils.DataNull(full.tpif_vtype).toUpperCase()} - ${Utils.DataNull(full.tpif_vbrand)} ${Utils.DataNull(full.tpif_ptype)} ${Utils.DataNull(full.tpif_vcolor)} ${Utils.DataNull(full.tpif_vyear)}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                      </div>`;
                    } else {
                      return `
                        <button id="matchmaking2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="matchmaking2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpif_lat_informan+','+full.tpif_lng_informan}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpif_id}" data-step="2" data-unit="${Utils.DataNull(full.tpif_vtype).toUpperCase()} - ${Utils.DataNull(full.tpif_vbrand)} ${Utils.DataNull(full.tpif_ptype)} ${Utils.DataNull(full.tpif_vcolor)} ${Utils.DataNull(full.tpif_vyear)}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='batalinforman' data-id="${full.tpif_id}" data-step="2" data-original-title='Batal Penemuan'><i class='fas fa-times'></i>Batalkan</button>
                        </div>`;
                    }
                  }
                },
              ]
            });
    },

    Matched : () => {
      $('#matched-1').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"],
                [1, 'desc']
              ],
              // "ajax": {
              //   "url": API.Foundingapi + "/v1/Penemuan/step/-1",
              //   "type": 'get',
              //   "beforeSend": function (xhr) {
              //     xhr.setRequestHeader('Authorization',
              //       "Basic " + localStorage.getItem('jwt'));
              //   },
              //   // error: function (xhr, error, thrown , settings) {
              //   //       errorData(xhr);
              //   //     }
              // },
              "ajax": {
                "url": API.Foundingapi + "/v1/Penemuan/step/-2",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                console.log(aData)
                var jam = moment( aData.tpnm_created_time, "H:mm:ss" ).add( 45, 'minutes') ;
                if ( aData.tpnm_created_date == currentDate ) {
                  if ( jam.format("H:mm:ss") > currentTime ) {
                    // MASIH TIDAK EXPIRED
                  }else{
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                }else{
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                if ( aData.app_type == "mobil"  ){
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                }else if( aData.app_type == "motor"  ){
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                {
                  "data": "tpnm_created_date"
                },
                {
                  "data": "tpnm_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name_informan"
                },
                {
                  "data": "mper_phone_informan"
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  "data": "tpif_dis_informan_to_mitra"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (localStorage.getItem('role') != 5) {
                      // return "<div id='aksi'>" +
                      //   '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='informan' data-id=" + full.tpnm_id + " data-id2=" + full.tpif_id + " data-step=" + full.tpnm_step + " " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "</div>";
                        return `
                        <button id="matched1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="matched1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
                    } else {
                      // return "<div id='aksi'>" +
                      //   '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='informan' data-id=" + full.tpnm_id + " data-id2=" + full.tpif_id + " data-step=" + full.tpnm_step + " " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-id2=" + full.tpif_id + " data-step=" + full.tpnm_step + " " +
                      //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'> </i> </button>" +
                      //   "</div>";
                        return `
                        <button id="matched2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="matched2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class='fas fa-times'> </i>Batalkan</button>
                        </div>`;
                    }
                  }
                },
              ]
            });
      $('#TableMatched2').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"],
                [1, 'desc']
              ],
              // "ajax": {
              //   "url": API.Foundingapi + "/v1/Penemuan/step/-1",
              //   "type": 'get',
              //   "beforeSend": function (xhr) {
              //     xhr.setRequestHeader('Authorization',
              //       "Basic " + localStorage.getItem('jwt'));
              //   },
              //   // error: function (xhr, error, thrown , settings) {
              //   //       errorData(xhr);
              //   //     }
              // },
              "ajax": {
                "url": API.Foundingapi + "/v1/Penemuan/step/-1",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                console.log(aData)
                var jam = moment( aData.tpnm_created_time, "H:mm:ss" ).add( 45, 'minutes') ;
                if ( aData.tpnm_created_date == currentDate ) {
                  if ( jam.format("H:mm:ss") > currentTime ) {
                    // MASIH TIDAK EXPIRED
                  }else{
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                }else{
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                if ( aData.app_type == "mobil"  ){
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                }else if( aData.app_type == "motor"  ){
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                {
                  "data": "tpnm_created_date"
                },
                {
                  "data": "tpnm_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name_informan"
                },
                {
                  "data": "mper_phone_informan"
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  "data": "tpif_dis_informan_to_mitra"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (localStorage.getItem('role') != 5) {
                      // return "<div id='aksi'>" +
                      //   '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='informan' data-id=" + full.tpnm_id + " data-id2=" + full.tpif_id + " data-step=" + full.tpnm_step + " " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "</div>";
                        return `
                        <button id="btn-matched" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btn-matched">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
                    } else {
                      // return "<div id='aksi'>" +
                      //   '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='informan' data-id=" + full.tpnm_id + " data-id2=" + full.tpif_id + " data-step=" + full.tpnm_step + " " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-id2=" + full.tpif_id + " data-step=" + full.tpnm_step + " " +
                      //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'> </i> </button>" +
                      //   "</div>";
                        return `
                        <button id="btn-matched2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btn-matched2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class='fas fa-times'> </i>Batalkan</button>
                        </div>`;
                    }
                  }
                },
              ]
            });
    },

    Validasi : () => {
      $('#validasi').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"],
                [1, 'desc']
              ],
              "ajax": {
                "url": API.Foundingapi + "/v1/Penemuan/step/1",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                var jam = moment( aData.tpnm_created_time, "H:mm:ss" ).add( 45, 'minutes') ;
                if ( aData.tpnm_created_date == currentDate ) {
                  if ( jam.format("H:mm:ss") > currentTime ) {
                    // MASIH TIDAK EXPIRED
                  }else{
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                }else{
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                // if ( aData.tpif_id != null  ){
                //   $('td:eq(0)', nRow).css('background-color', '#a7e9af');
                // }else if( aData.tpif_id == null  ){
                //   $('td:eq(0)', nRow).css('background-color', '#deddfa');
                // }

                if ( aData.app_type == "mobil"  ){
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                }else if( aData.app_type == "motor"  ){
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                // {
                //   sortable: true,
                //   "render": function (data, type, full, meta) {
                //     console.log(full)
                //     var typeapp
                //     full.tpif_id != null ? typeapp="Mitra (Oleh Informan)" : typeapp="Mitra"
                //     // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                //     return " <div >" + typeapp + "</div>";
                //   }
                // },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    var typeapp
                    full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
                    // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div class='text-center'>" + typeapp + "</div>";
                  }
                },
                {
                  "data": "tpnm_created_date"
                },
                {
                  "data": "tpnm_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (localStorage.getItem('role') != 5) {
                      // return "<div id='aksi'>" +
                      //   '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step='1' data-founding=" + full.tpif_id +" "+
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "</div>";
                        return `
                        <button id="validasi1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="validasi1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
                    } else {
                      // return "<div id='aksi'>" +
                      //   '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step='1' data-founding=" + full.tpif_id +" "+
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                      //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'> </i> </button>" +
                      //   "</div>";
                        return `
                        <button id="validasi2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="validasi2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class='fas fa-times'></i>Batalkan</button>
                        </div>`;
                    }
                  }
                },
              ]
            });
    },
    
    MenungguSuratTugas : () => {
            $('#menunggusurattugas').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"]
              ],
              "ajax": {
                "url": API.Foundingapi + "v1/Penemuan/step/2",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                var jam = moment(aData.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                if (aData.tpnm_created_date == currentDate) {
                  if (jam.format("H:mm:ss") > currentTime) {
                    // MASIH TIDAK EXPIRED
                  } else {
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                } else {
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                if (aData.app_type == "mobil") {
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                } else if (aData.app_type == "motor") {
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.tpif_type)+"</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    var typeapp
                    full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
                    // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div class='text-center'>" + typeapp + "</div>";
                  }
                },
                {
                  "data": "tpnm_created_date"
                },
                {
                  "data": "tpnm_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
  
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (full.tpnm_medi_vld_mobil != null) {
                      // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=2 " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "<a href=" + full.tpnm_medi_vld_mobil + " class='btn blue btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' data-container='body' target='_blank' data-placement='top' data-original-title='Lihat Profile User'><i class='fa fa-user'></i></a> ";
                        return `
                        <button id="menunggu1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="menunggu1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="2" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <a href="${full.tpnm_medi_vld_mobil}" class='dropdown-item' data-container='body' target='_blank' data-placement='top' data-original-title='Lihat Profile User'><i class='fa fa-user'></i></a>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="2" data-original-title='Detail Data Penemuan'><i class='fas fa-times'></i>Batalkan</button>
                        </div>`;
                      } else {
                      // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=2 " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                        return `
                        <button id="menunggu2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="menunggu2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="2" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="2" data-original-title='Detail Data Penemuan'><i class='fas fa-times'></i>Batalkan</button>
                          </div>`;
                    }
                  }
                },
              ]
            });
    },
    
    SuratTugasDiterima : () => {
            $('#surattugasditerima').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"]
              ],
              "ajax": {
                "url": API.Foundingapi + "v1/Penemuan/step/3",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                console.log(aData)
                var jam = moment(aData.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                if (aData.tpnm_created_date == currentDate) {
                  if (jam.format("H:mm:ss") > currentTime) {
                    // MASIH TIDAK EXPIRED
                  } else {
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                } else {
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                // if ( aData.tpif_id != null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#a7e9af');
                // }else if( aData.tpif_id == null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#deddfa');
                // }
                
                if (aData.app_type == "mobil") {
                  if ((aData.is_surat_kuasa == null || aData.is_surat_kuasa == 0) && (aData.is_surat_fidusia == null || aData.is_surat_fidusia == 0)) {
                    $('td:eq(12)', nRow).css('background-color', '#76ff03');
                  } else if (aData.is_surat_kuasa == null || aData.is_surat_kuasa == 0) {
                    $('td:eq(12)', nRow).css('background-color', '#76ff03');
                  } else if (aData.is_surat_fidusia == null || aData.is_surat_fidusia == 0) {
                    $('td:eq(12)', nRow).css('background-color', '#76ff03');
                  }
                  // console.log(aData.is_surat_kuasa + " " + aData.is_surat_fidusia );
                }
                if (aData.app_type == "mobil") {
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                } else if (aData.app_type == "motor") {
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
                // console.log(aData);
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    var typeapp
                    full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
                    // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div class='text-center'>" + typeapp + "</div>";
                  }
                },
                {
                  "data": "tpnm_created_date"
                },
                {
                  "data": "tpnm_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
  
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  "data": "nego_status"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    var msg
                    if (full.is_surat_kuasa == null && full.is_surat_fidusia  == null) {
                      msg = "Tidak Ada Dokumen Nego"
                    }else if(full.is_surat_kuasa == null){
                      msg = "Tidak Ada Surat Kuasa"
                    }else if(full.is_surat_fidusia == null){
                      msg = "Tidak Ada Fidusia"
                    }else{
                      msg = ""
                    }
                    return " <div> " + msg + "</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full);
                    if (full.tpnm_vtype == 'mobil') {
                      if (localStorage.getItem('role') != 5) {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=3 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='btn grey-mint btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil' id='nego' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-upload'></i> </button>";
                          return `
                        <button id="surattugasditerima1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="surattugasditerima1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="3" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class='fas fa-file-upload'></i>Nego</button>
                        </div>`;
                      } else {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=3 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='btn grey-mint btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil' id='nego' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-upload'></i> </button>" +
                        //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'></i> </button>";
                          return `
                        <button id="surattugasditerima1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="surattugasditerima1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="3" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class='fas fa-file-upload'></i>Nego</button>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class='fas fa-times'> </i>Batalkan</button>
                        </div>`;
                      }
                    } else {
                      if (localStorage.getItem('role') != 5) {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=3 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                          return `
                        <button id="surattugasditerima1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="surattugasditerima1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="3" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
                      } else {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=3 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'></i> </button>";
                          return `
                        <button id="surattugasditerima1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="surattugasditerima1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="3" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-id2="${full.tpif_id}" data-original-title='Detail Data Penemuan'><i class='fas fa-times'> </i>Batalkan</button>
                        </div>`;
                      }
                    }
                  }
                },
              ]
            });
    },

    Pengantaran : () => { 
            $('#pengantaran').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"]
              ],
              "ajax": {
                "url": API.Foundingapi + "v1/Penemuan/step/4",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                var jam = moment(aData.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                if (aData.tpnm_created_date == currentDate) {
                  if (jam.format("H:mm:ss") > currentTime) {
                    // MASIH TIDAK EXPIRED
                  } else {
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                } else {
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                //  }
                // if ( aData.tpif_id != null  ){
                //   $('td:eq(0)', nRow).css('background-color', '#a7e9af');
                // }else if( aData.tpif_id == null  ){
                //   $('td:eq(0)', nRow).css('background-color', '#deddfa');
                // }
                if (aData.app_type == "mobil") {
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                } else if (aData.app_type == "motor") {
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    var typeapp
                    full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
                    // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div class='text-center'>" + typeapp + "</div>";
                  }
                },
                {
                  "data": "tpnm_created_date"
                },
                {
                  "data": "tpnm_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
  
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  "data": "nego_status"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (full.tpnm_vtype == "mobil") {
                      if (localStorage.getItem('role') != 5) {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=4 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='btn grey-mint btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil1' id='formmobil1' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-upload'></i> </button>";
                          return `
                          <button id="dalampengantaran1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dalampengantaran2">
                            <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                            <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="4" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                            <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-file-upload"></i>Detail Nego</button>
                          </div>`;
                      } else {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=4 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='btn grey-mint btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil1' id='formmobil1' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-upload'></i> </button>" +
                        //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'> </i> </button>";
                          return `
                          <button id="dalampengantaran2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dalampengantaran2">
                            <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                            <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="4" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                            <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-file-upload"></i>Detail Nego</button>
                            <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-times"></i>Batalkan</button>
                          </div>`;
                      }
                    } else {
                      if (localStorage.getItem('role') != 5) {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=4 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                          return `
                          <button id="dalampengantaran2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dalampengantaran2">
                            <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                            <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="4" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          </div>`;
                      } else {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=4 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'> </i> </button>";
                          return `
                          <button id="dalampengantaran2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dalampengantaran2">
                            <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                            <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="4" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                            <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-times"></i>Batalkan</button>
                          </div>`;
                      }
                    }
                  }
                },
              ]
            });
    },
  
    DalamPengantaran : () => {
            $('#dalampengantaran').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"]
              ],
              "ajax": {
                "url": API.Foundingapi + "v1/Penemuan/step/5",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                var jam = moment(aData.tpnm_created_time, "H:mm:ss").add(90, 'minutes');
                if (aData.tpnm_created_date == currentDate) {
                  if (jam.format("H:mm:ss") > currentTime) {
                    // MASIH TIDAK EXPIRED
                  } else {
                    $('td', nRow).css('background-color', '#FFEA00');
                  }
                } else {
                  $('td', nRow).css('background-color', '#FFEA00');
                }
                // if ( aData.tpif_id != null  ){
                //   $('td:eq(0)', nRow).css('background-color', '#a7e9af');
                // }else if( aData.tpif_id == null  ){
                //   $('td:eq(0)', nRow).css('background-color', '#deddfa');
                // }
                if (aData.app_type == "mobil") {
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                } else if (aData.app_type == "motor") {
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    var typeapp
                    full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
                    // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div class='text-center'>" + typeapp + "</div>";
                  }
                },
                {
                  "data": "tpnm_created_date"
                },
                {
                  "data": "tpnm_created_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var jam = moment(full.tpnm_created_time, "H:mm:ss").add(90, 'minutes');
                    return " <div >" + jam.format("H:mm:ss") + "</div>";
                  }
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "tpnm_delivery_dpname"
                },
                {
                  "data": "mmle_pic_name1"
                },
                {
                  "data": "mmle_pic_phone1"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  "data": "nego_status"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (full.tpnm_vtype == "mobil") {
                      if (localStorage.getItem('role') != 5) {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=5 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='btn grey-mint btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil1' id='formmobil1' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-upload'></i> </button>";
                          return `
                          <button id="dalampengantaran1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dalampengantaran1">
                            <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                            <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="5" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                            <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-file-upload"></i>Detail Nego</button>
                          </div>`;
                      } else {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=5 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='btn grey-mint btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil1' id='formmobil1' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-upload'></i> </button>" +
                        //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'> </i> </button>";
                          return `
                          <button id="dalampengantaran2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dalampengantaran2">
                            <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                            <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="5" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                            <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-file-upload"></i>Detail Nego</button>
                            <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-times"></i>Batalkan</button>
                          </div>`;
                      }
                    } else {
                      if (localStorage.getItem('role') != 5) {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=5 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                          return `
                          <button id="dalampengantaran3" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dalampengantaran3">
                            <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                            <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="5" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          </div>`;
                      } else {
                        // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                        //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                        //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=5 " +
                        //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                        //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batal' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                        //   "data-original-title='Batal Penemuan'> <i class='fas fa-times'> </i> </button>";
                          return `
                          <button id="dalampengantaran4" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dalampengantaran4">
                            <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                            <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="5" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                            <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-times"></i>Batalkan</button>
                          </div>`;
                      }
                    }
                  }
                },
              ]
            });
    },
            
    Selesai : () => {
            $('#selesai').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"]
              ],
              "ajax": {
                "url": API.Foundingapi + "v1/Penemuan/step/6",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                // if ( aData.tpif_id != null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#a7e9af');
                // }else if( aData.tpif_id == null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#deddfa');
                // }
                if (aData.app_type == "mobil") {
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                } else if (aData.app_type == "motor") {
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    var typeapp
                    full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
                    // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                    return " <div class='text-center'>" + typeapp + "</div>";
                  }
                },
                {
                  "data": "tpnm_created_date"
                },
                {
                  "data": "tpnm_created_time"
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "tpnm_delivery_dpname"
                },
                {
                  "data": "mmle_pic_name1"
                },
                {
                  "data": "mmle_pic_phone1"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  "data": "nego_status"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (full.tpnm_vtype == "mobil") {
                      if (localStorage.getItem('role') != 5) {
                        return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class='fas fa-file-upload'></i>Detail Nego</button>
                        </div>`;
                      }else{
                        return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class='fas fa-file-upload'></i>Detail Nego</button>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-times"></i>Batalkan</button>
                        </div>`;
                      }
                      // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=6 " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "<button class='btn grey-mint btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil1' id='nego' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                      //   " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-upload'></i> </button>";
                    } else {
                      if (localStorage.getItem('role') != 5) {
                        return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
                      }else{
                        return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>\
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-times"></i>Batalkan</button>
                        </div>`;
                      }
                      // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=6 " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                        // return `
                        // <button id="selesai2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        //   <i class="fas fa-ellipsis-v"></i>
                        // </button>
                        // <div class="dropdown-menu" aria-labelledby="selesai2">
                        //   <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                        //   <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        // </div>`;
                    }
                  }
                },
              ]
            });
    },

    Selesai_Informan : () => {
            $('#selesai_informan').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"]
              ],
              "ajax": {
                "url": API.Financeapi + "/v1/Voucher_verifikasi/voucherVerifikasiInforman",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                // if ( aData.tpif_id != null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#a7e9af');
                // }else if( aData.tpif_id == null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#deddfa');
                // }
                if (aData.app_type == "mobil") {
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                } else if (aData.app_type == "motor") {
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                {
                  "data": "tvoc_created_date"
                },
                {
                  "data": "tvoc_created_time"
                },
                {
                  "data": "mmle_name"
                },
                {
                  "data": "tpnm_branch"
                },
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    if (full.tpnm_vtype == "mobil") {
                      if (localStorage.getItem('role') != 5) {
                        return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class='fas fa-file-upload'></i>Detail Nego</button>
                        </div>`;
                      }else{
                        return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='nego' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class='fas fa-file-upload'></i>Detail Nego</button>
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-times"></i>Batalkan</button>
                        </div>`;
                      }
                      // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=6 " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "<button class='btn grey-mint btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil1' id='nego' data-id=" + full.tpnm_id + " data-step=" + full.tpnm_step + " " +
                      //   " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-upload'></i> </button>";
                    } else {
                      if (localStorage.getItem('role') != 5) {
                        return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
                      }else{
                        return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>\
                          <button class='dropdown-item' id='batal' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-times"></i>Batalkan</button>
                        </div>`;
                      }
                      // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.tpnm_id + " data-step=6 " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                        // return `
                        // <button id="selesai2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        //   <i class="fas fa-ellipsis-v"></i>
                        // </button>
                        // <div class="dropdown-menu" aria-labelledby="selesai2">
                        //   <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                        //   <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="6" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        // </div>`;
                    }
                  }
                },
              ]
            });
    },
            
    Dibatalkan : () => {
            $('#dibatalkan').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"]
              ],
              "ajax": {
                "url": API.Foundingapi + "v1/Penemuan/step_pembatalan",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                },
              },
              "drawCallback": function(settings) {
                console.log(settings.json);
                if(settings.json.recordsTotal == 0){
                  $('#delete_all').hide()
                }else{
                  $('#delete_all').show()
                }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                // if ( aData.tpif_id != null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#a7e9af');
                // }else if( aData.tpif_id == null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#deddfa');
                // }
                if (aData.app_type == "mobil") {
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                } else if (aData.app_type == "motor") {
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                // {
                //   sortable: true,
                //   "render": function (data, type, full, meta) {
                //     console.log(full)
                //     var typeapp
                //     full.tpif_id != null && full.tpif_id != 0? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
                //     // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                //     return " <div class='text-center'>" + typeapp + "</div>";
                //   }
                // },
                {
                  "data": "tpnm_cancelled_date"
                },
                {
                  "data": "tpnm_cancelled_time"
                },
                {
                  "data": "tpnm_cancelled_by_name"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // return " <div >" + full.tpnm_step + "</div>";
                    return " <div >" + Utils.StepPembatalan(full.tpnm_step) + "</div>";
                  }
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "tpnm_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return " <div> " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
                  }
                },
                {
                  "data": "nego_status"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // return (
                    //       `<a href=${"https://www.google.com/maps/?q="+full.tpnm_lat+","+full.tpnm_lng} class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>
                    //       <button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=${full.tpnm_id} data-step=${full.tpnm_step} data-original-title='Detail Data Penemuan'><i class='fa fa-eye'></i></button>
                    //       <button class='batalNyaBatal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='batalNyaBatal' data-id=${full.tpnm_id } data-step=${full.tpnm_step} data-original-title='Batal Penemuan'><i class='fa fa-trash'></i></button>`
                    //   )
                      return `
                        <button id="dibatalkan" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dibatalakan">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='batalNyaBatal' data-id="${full.tpnm_id }" data-step="${full.tpnm_step}" data-original-title='Batal Penemuan'><i class='fa fa-trash'></i>Hapus</button>
                        </div>`;
                  }
                },
              ]
            });
    },

    PembatalanInforman : () => {
      $('#pembatalaninforman').DataTable({
              "pagingType": "full_numbers",
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "order": [
                [0, "desc"],
                [1, 'desc']
              ],
              "ajax": {
                "url": API.Foundingapi + "v1/penemuan/pembatalan_informan",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    }
              },
              "drawCallback": function(settings) {
                console.log(settings.json);
                if(settings.json.recordsTotal == 0){
                  $('#delete_all_informan').hide()
                }else{
                  $('#delete_all_informan').show()
                }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                console.log(aData)
                // var jam = moment( aData.tpnm_created_time, "H:mm:ss" ).add( 45, 'minutes') ;
                // if ( aData.tpnm_created_date == currentDate ) {
                //   if ( jam.format("H:mm:ss") > currentTime ) {
                //     // MASIH TIDAK EXPIRED
                //   }else{
                //     $('td', nRow).css('background-color', '#FFEA00');
                //   }
                // }else{
                //   $('td', nRow).css('background-color', '#FFEA00');
                // }
                // if ( aData.tpif_id != null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#a7e9af');
                // }else if( aData.tpif_id == null  ){
                //   $('td:eq(1)', nRow).css('background-color', '#deddfa');
                // }
                if ( aData.app_type == "mobil"  ){
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                }else if( aData.app_type == "motor"  ){
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable: true,
                  "render": function ( data, type, full, meta ) {
                    return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
                  }
                },
                // {
                //   sortable: true,
                //   "render": function (data, type, full, meta) {
                //     console.log(full)
                //     var typeapp
                //     full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
                //     // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
                //     return " <div class='text-center'>" + typeapp + "</div>";
                //   }
                // },
                {
                  "data": "tpif_cancelled_by_name"
                },
                {
                  "data": "tpif_cancelled_date"
                },
                {
                  "data": "tpif_cancelled_time"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // return " <div >" + full.tpnm_step + "</div>";
                    var tpif_step,tpif_step2,tpnm_msg
                    tpif_step = full.tpif_tpnm_cancelled_step
                    tpif_step2 = full.tpif_step
                    if(tpif_step == null){
                      if(tpif_step2 == 1){
                        tpnm_msg = "Validasi Informan"
                      }else if(tpif_step2 == 2){
                        tpnm_msg = "Matchmaking"
                      }
                    }else{
                      if(tpif_step == 1){
                        tpnm_msg = "Validasi Mitra";
                      }else if(tpif_step == -1){
                        tpnm_msg = "Matched (Mitra Menuju Informan)";
                      }else if(tpif_step == -2){
                        tpnm_msg = "Matched (Mitra Ditemukan)";
                      }else if(tpif_step == 2){
                        tpnm_msg = "Menunggu Surat Tugas";
                      }else if(tpif_step == 3){
                        tpnm_msg = "Surat Tugas Diterima";
                      }else if(tpif_step == 4){
                        tpnm_msg = "Pengantaran";
                      }else if(tpif_step == 5){
                        tpnm_msg = "Dalam Pengantaran";
                      }
                    }
                    return " <div >" + tpnm_msg + "</div>";
                    // return " <div >" + Utils.StepPembatalan(full.tpif_tpnm_cancelled_step) + "</div>";
                  }
                },
                {
                  "data": "mper_name_informan"
                },
                {
                  "data": "mper_phone_informan"
                },
                {
                  "data": "tpif_vplate"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
                    // return " <div >  " + full.tpif_vbrand + " " + full.tpif_vyear + " " + full.tpif_vcolor + "</div>";
                    return " <div> " +Utils.DataNull(full.tpif_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpif_vbrand)+ " " + Utils.DataNull(full.tpif_type) + " " + Utils.DataNull(full.tpif_vcolor) + " " + Utils.DataNull(full.tpif_vyear) + "</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    if (localStorage.getItem('role') != 5) {
                      // return "<div id='aksi'>" +
                      //   '<a href="https://www.google.com/maps/?q=' + full.tpif_lat_informan + ',' + full.tpif_lng_informan + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detailpembatalan' data-id=" + full.tpif_id + " data-step=1 " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "</div>";
                        return `
                        <button id="pembatalan1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="pembatalan1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpif_lat_informan+','+full.tpif_lng_informan}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detailpembatalan' data-id="${full.tpnm_id}" data-by="${full.tpif_step}" data-unit="${Utils.DataNull(full.tpif_vtype).toUpperCase()} - ${Utils.DataNull(full.tpif_vbrand)} ${Utils.DataNull(full.tpif_ptype)} ${Utils.DataNull(full.tpif_vcolor)} ${Utils.DataNull(full.tpif_vyear)}" data-step="${full.tpnm_step}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
                          // <button class='dropdown-item' id='batalNyaBatal' data-id="${full.tpnm_id }" data-step="${full.tpnm_step}" data-original-title='Batal Penemuan'><i class='fa fa-trash'></i>Hapus</button>
                    } else {
                      // return "<div id='aksi'>" +
                      //   '<a href="https://www.google.com/maps/?q=' + full.tpif_lat_informan + ',' + full.tpif_lng_informan + '" ' +
                      //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                      //   "<button class='details btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detailpembatalan' data-id=" + full.tpif_id + " data-step=1 " +
                      //   "data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>" +
                      //   "<button class='batal btn red btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='hapusbatalinforman' data-id=" + full.tpif_id + " data-step=" + full.tpif_step + " " +
                      //   "data-original-title='Batal Penemuan'><i class='fa fa-trash'></i></button>" +
                      //   "</div>";
                        return `
                        <button id="pembatalan2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="pembatalan2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpif_lat_informan+','+full.tpif_lng_informan}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detailpembatalan' data-id="${full.tpif_id}" data-by="${full.tpif_step}" data-unit="${Utils.DataNull(full.tpif_vtype).toUpperCase()} - ${Utils.DataNull(full.tpif_vbrand)} ${Utils.DataNull(full.tpif_ptype)} ${Utils.DataNull(full.tpif_vcolor)} ${Utils.DataNull(full.tpif_vyear)}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='hapusbatalinforman' data-id="${full.tpif_id}" data-step="${full.tpif_step}" data-original-title='Batal Penemuan'><i class='fa fa-trash'></i>Hapus</button>
                        </div>`;
                    }
                  }
                },
              ]
            });
    },

    CountPenemuan : () => {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/penemuan/totalAllStep",
        "method": "GET",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
          }
      }
      $.ajax(settings).done(function (response) {

          $('#totalMatched1').html(response.data.totalMatched == 0 ? response.data.totalMatched = "" : response.data.totalMatched);
          $('#totalMatched2').html(response.data.totalMatchMaking == 0 ? response.data.totalMatchMaking = "" : response.data.totalMatchMaking);
          // $('#totalAllMatched').html(response.data.totalMatched == 0 && response.data.totalMatchMaking == 0 ? "" : parseInt(response.data.totalMatched)+parseInt(response.data.totalMatchMaking));
          $('#totalAllMatched').html(response.data.totalMatched == 0 && response.data.totalMatchMaking == 0 ? "" : response.data.totalMatched + response.data.totalMatchMaking);
          $('#totalValidasi').html(response.data.totalValidasi == 0 ? response.data.totalValidasi = "" : response.data.totalValidasi);
          $('#totalMenungguSuratTugas').html(response.data.totalMenungguSuratTugas == 0 ? response.data.totalMenungguSuratTugas = "" : response.data.totalMenungguSuratTugas);
          $('#totalSuratTugasDiterima').html(response.data.totalSuratTugasDiterima == 0 ? response.data.totalSuratTugasDiterima = "" : response.data.totalSuratTugasDiterima);
          $('#totalPengantaran').html(response.data.totalPengantaran == 0 ? response.data.totalPengantaran = "" : response.data.totalPengantaran);
          $('#totalDalamPengantaran').html(response.data.totalDalamPengataran == 0 ? response.data.totalDalamPengataran = "" : response.data.totalDalamPengataran);
          $('#totalSelesai').html(response.data.totalSelesai == 0 ? response.data.totalSelesai = "" : response.data.totalSelesai);
          $('#totalCancel').html(response.data.totalCancel == 0 ? response.data.totalCancel = "" : response.data.totalCancel);
        console.log(response);
      }).fail(function (response) {
        // API.Handle403(response,state)
      });

      var informan = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/penemuan/total_step_informan",
        "method": "GET",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
          }
      }
      $.ajax(informan).done(function (response) {

          $('#totalStep1').html(response.data.totalStep1 == 0 ? response.data.totalStep1 = "" : response.data.totalStep1);
          $('#totalStep2').html(response.data.totalStep2 == 0 ? response.data.totalStep2 = "" : response.data.totalStep2);
          $('#stepPembatalan').html(response.data.stepPembatalan == 0 ? response.data.stepPembatalan = "" : response.data.stepPembatalan);
        console.log(response);
      }).fail(function (response) {
        // API.Handle403(response,state)
      });

      var done = {
        "async": true,
        "crossDomain": true,
        "url": API.Financeapi +"v1/Voucher_verifikasi/totalDataVoucherInforman",
        "method": "GET",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
          }
      }
      $.ajax(done).done(function (response) {
        $('#totalSelesai_informan').html(response.data.total_voucher == 0 ? response.data.total_voucher = "" : response.data.total_voucher);
        console.log(response);
      }).fail(function (response) {
        // API.Handle403(response,state)
      });
    },
    
    // Laporan Penemuan

    LPMatchmaking : (from,to) => {
      $('#matchmaking').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step_informan/2?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 45, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }
            if ( aData.tpif_vtype == "mobil"  ){
              $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
            }else if( aData.tpif_vtype == "motor"  ){
              $('td:eq(0)', nRow).css('background-color', '#F4A460');
            }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.tpif_type)+"</div>";
            }
          },
          {
            "data": "tanggal_validasi"
          },
          {
            "data": "jam_validasi"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.jam_validasi, "hh:mm:ss").add(45, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name_informan"
          },
          {
            "data": "mper_phone_informan"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpif_branch"
          },

          {
            "data": "tpif_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
              return " <div >  " + full.tpif_vtype.toUpperCase() + " - " + full.tpif_vbrand + " " + full.tpif_ptype + " " + full.tpif_vcolor + " " + full.tpif_vyear + "</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // return '<a href="https://www.google.com/maps/?q=' + full.tpif_lat + ',' + full.tpif_lng + '" ' +
              //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
              //   "<div id='aksi'> <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button> ";
                return `
                        <button id="matchmaking1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="matchmaking1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpif_lat_dp+','+full.tpif_lng_dp}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpif_id}" data-step="2" data-unit="${full.tpif_vtype.toUpperCase() + " - " + full.tpif_vbrand + " " + full.tpif_ptype + " " + full.tpif_vcolor + " " + full.tpif_vyear}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
            }
          },
        ]
      });
    },

    LPValidasiInforman : (from,to) => {
      $('#laporanvalidasiinforman').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step_informan/1?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 45, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }
          if ( aData.tpif_vtype == "mobil"  ){
            $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
          }else if( aData.tpif_vtype == "motor"  ){
            $('td:eq(0)', nRow).css('background-color', '#F4A460');
          }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.tpif_vtype)+"</div>";
            }
          },
          {
            "data": "tanggal_validasi"
          },
          {
            "data": "jam_validasi"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.jam_validasi, "hh:mm:ss").add(45, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name_informan"
          },
          {
            "data": "mper_phone_informan"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpif_branch"
          },

          {
            "data": "tpif_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
              return " <div >  " + full.tpif_vtype.toUpperCase() + " - " + full.tpif_vbrand + " " + full.tpif_ptype + " " + full.tpif_vcolor + " " + full.tpif_vyear + "</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // return '<a href="https://www.google.com/maps/?q=' + full.tpif_lat + ',' + full.tpif_lng + '" ' +
              //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
              //   "<div id='aksi'> <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button> ";
                return `
                        <button id="validasiinforman1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="validasiinforman1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpif_lat_dp+','+full.tpif_lng_dp}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpif_id}" data-step="1" data-unit="${full.tpif_vtype.toUpperCase() + " - " + full.tpif_vbrand + " " + full.tpif_ptype + " " + full.tpif_vcolor + " " + full.tpif_vyear}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
            }
          },
        ]
      });
    },

    LPMatched : (from,to) => {
      $('#lpmatched2').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step/-2?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 45, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }
            if ( aData.app_type == "mobil"  ){
              $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
            }else if( aData.app_type == "motor"  ){
              $('td:eq(0)', nRow).css('background-color', '#F4A460');
            }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            "data": "tpnm_created_date"
          },
          {
            "data": "tpnm_created_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.tpnm_created_time, "hh:mm:ss").add(45, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name"
          },
          {
            "data": "mper_phone"
          },
          {
            "data": "mper_name_informan"
          },
          {
            "data": "mper_phone_informan"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpnm_branch"
          },

          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            "data": "tpif_dis_informan_to_mitra"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
              //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
              //   "<div id='aksi'> <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button> ";
                return `
                        <button id="matched1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="matched1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpnm_id}" data-step="-2" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
            }
          },
        ]
      });

      $('#lpmatched1').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step/-1?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 45, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }
            if ( aData.app_type == "mobil"  ){
              $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
            }else if( aData.app_type == "motor"  ){
              $('td:eq(0)', nRow).css('background-color', '#F4A460');
            }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            "data": "tpnm_created_date"
          },
          {
            "data": "tpnm_created_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.tpnm_created_time, "hh:mm:ss").add(45, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name"
          },
          {
            "data": "mper_phone"
          },
          {
            "data": "mper_name_informan"
          },
          {
            "data": "mper_phone_informan"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpnm_branch"
          },

          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            "data": "tpif_dis_informan_to_mitra"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
              //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
              //   "<div id='aksi'> <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button> ";
                return `
                        <button id="matched1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="matched1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='informan' data-id="${full.tpnm_id}" data-step="-1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
            }
          },
        ]
      });
    },

    LPValidasi: (from,to) => {
      $('#validasi').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step/1?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 45, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }
            if ( aData.app_type == "mobil"  ){
              $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
            }else if( aData.app_type == "motor"  ){
              $('td:eq(0)', nRow).css('background-color', '#F4A460');
            }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              console.log(full)
              var typeapp
              full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
              // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
              return " <div class='text-center'>" + typeapp + "</div>";
            }
          },
          {
            "data": "tpnm_created_date"
          },
          {
            "data": "tpnm_created_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.tpnm_created_time, "hh:mm:ss").add(45, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name"
          },
          {
            "data": "mper_phone"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpnm_branch"
          },

          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
              //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
              //   "<div id='aksi'> <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button> ";
                return `
                        <button id="validasimitra1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="validasimitra1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
            }
          },
        ]
      });
    },
    
    LPMenungguSuratTugas : (from,to) => {
      $('#menunggusurattugas').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step/2?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 45, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }

            if ( aData.app_type == "mobil"  ){
              $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
            }else if( aData.app_type == "motor"  ){
              $('td:eq(0)', nRow).css('background-color', '#F4A460');
            }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              console.log(full)
              var typeapp
              full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
              // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
              return " <div class='text-center'>" + typeapp + "</div>";
            }
          },
          {
            "data": "tpnm_created_date"
          },
          {
            "data": "tpnm_created_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.tpnm_created_time, "hh:mm:ss").add(45, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name"
          },
          {
            "data": "mper_phone"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpnm_branch"
          },

          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
              //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
              //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button> " +
              //   "</div>";
                return `
                        <button id="menunggu1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="menunggu1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
            }
          },
        ]
      });
    },

    LPSuratTugasDiterima : (from,to) => {
      $('#surattugasditerima').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step/3?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 45, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }

            if ( aData.app_type == "mobil"  ){
              $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
            }else if( aData.app_type == "motor"  ){
              $('td:eq(0)', nRow).css('background-color', '#F4A460');
            }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              console.log(full)
              var typeapp
              full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
              // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
              return " <div class='text-center'>" + typeapp + "</div>";
            }
          },
          {
            "data": "tpnm_created_date"
          },
          {
            "data": "tpnm_created_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.tpnm_created_time, "hh:mm:ss").add(45, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name"
          },
          {
            "data": "mper_phone"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpnm_branch"
          },

          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            "data": "nego_status"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              if (full.chnl_id != null) {
                // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "<button class='btn green btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "</div>";
                  return `
                        <button id="surattugasditerima1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="surattugasditerima1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Nego Detail</button>
                        </div>`;
              } else {
                // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "</div>";
                  return `
                        <button id="surattugasditerima2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="surattugasditerima2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
              }
            }
          },
        ]
      });
    },

    LPPengantaran: (from,to) => {
      $('#pengantaran').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step/4?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 45, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }
            if ( aData.app_type == "mobil"  ){
              $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
            }else if( aData.app_type == "motor"  ){
              $('td:eq(0)', nRow).css('background-color', '#F4A460');
            }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              console.log(full)
              var typeapp
              full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
              // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
              return " <div class='text-center'>" + typeapp + "</div>";
            }
          },
          {
            "data": "tpnm_created_date"
          },
          {
            "data": "tpnm_created_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.tpnm_created_time, "hh:mm:ss").add(45, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name"
          },
          {
            "data": "mper_phone"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpnm_branch"
          },

          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            "data": "nego_status"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              if (full.chnl_id != null || full.tpnm_vtype == 'mobil') {
                // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "<button class='btn green btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "</div>";
                  return `
                        <button id="pengantaran1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="pengantaran1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Nego Detail</button>
                        </div>`;
              } else {
                // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "</div>";
                  return `
                        <button id="pengantaran2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="pengantaran2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
              }
            }
          },
        ]
      });
    },

    LPDalamPengantaran : (from,to) => {
      $('#dalampengantaran').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step/5?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
            // var currentTime = moment().format("hh:mm:ss");
            // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 90, 'minutes') ;
            // var currentDate = moment().format("YYYY-MM-DD");
            // if ( aData.tpnm_created_date > currentDate ) {
            //   if ( jam._i > currentTime ) {
            //     // MASIH TIDAK EXPIRED
            //   }else{
            //     $('td', nRow).css('background-color', '#FFEA00');
            //   }
            // }else{
            //   $('td', nRow).css('background-color', '#FFEA00');
            // }

            if ( aData.app_type == "mobil"  ){
              $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
            }else if( aData.app_type == "motor"  ){
              $('td:eq(0)', nRow).css('background-color', '#F4A460');
            }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              console.log(full)
              var typeapp
              full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
              // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
              return " <div class='text-center'>" + typeapp + "</div>";
            }
          },
          {
            "data": "tpnm_created_date"
          },
          {
            "data": "tpnm_created_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              var jam = moment(full.tpnm_created_time, "hh:mm:ss").add(60, 'minutes');
              return " <div >" + jam.format("hh:mm:ss") + "</div>";
            }
          },
          {
            "data": "mper_name"
          },
          {
            "data": "mper_phone"
          },
          {
            "data": "tpnm_delivery_dpname"
          },
          {
            "data": "mmle_pic_name1"
          },
          {
            "data": "mmle_pic_phone1"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpnm_branch"
          },
          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            "data": "nego_status"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              if (full.chnl_id != null || full.tpnm_vtype == 'mobil') {
                // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "<button class='btn green btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "</div>";
                  return `
                        <button id="dalampengantaran1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dalampengantaran1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Nego Detail</button>
                        </div>`;
              } else {
                // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "</div>";
                  return `
                        <button id="dalampengantaran2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dalampengantaran2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
              }
            }
          },
        ]
      });
    },

    LPSelesai: (from,to) => {
      $('#selesai').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step_selesai/6?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
          // var currentTime = moment().format("hh:mm:ss");
          // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 90, 'minutes') ;
          // var currentDate = moment().format("YYYY-MM-DD");
          // if ( aData.tpnm_created_date > currentDate ) {
          //   if ( jam._i > currentTime ) {
          //     // MASIH TIDAK EXPIRED
          //   }else{
          //     $('td', nRow).css('background-color', '#FFEA00');
          //   }
          // }else{
          //   $('td', nRow).css('background-color', '#FFEA00');
          // }

          if ( aData.app_type == "mobil"  ){
            $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
          }else if( aData.app_type == "motor"  ){
            $('td:eq(0)', nRow).css('background-color', '#F4A460');
          }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              console.log(full)
              var typeapp
              full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
              // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
              return " <div class='text-center'>" + typeapp + "</div>";
            }
          },
          {
            "data": "tpnm_created_date"
          },
          {
            "data": "tpnm_created_time"
          },
          {
            "data": "mper_name"
          },
          {
            "data": "tpnm_delivery_dpname"
          },
          {
            "data": "mmle_pic_name1"
          },
          {
            "data": "mmle_pic_phone1"
          },
          {
            "data": "mmle_name"
          },
          {
            "data": "tpnm_branch"
          },
          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            "data": "nego_status"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              if (full.chnl_id != null || full.tpnm_vtype == 'mobil') {
                // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "<button class='btn green btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 formmobil tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "</div>";
                  return `
                        <button id="selesai1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Nego Detail</button>
                        </div>`;
              } else {
                // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
                //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
                //   "<div id='aksi'> <button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>" +
                //   "</div>";
                  return `
                        <button id="selesai2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="selesai2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
              }
            }
          },
        ]
      });
    },

    LPDibatalkan: (from,to) => {
      $('#dibatalkan').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/step_pembatalan?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
          // var currentTime = moment().format("hh:mm:ss");
          // var jam = moment( aData.tpnm_created_time, "hh:mm:ss" ).add( 90, 'minutes') ;
          // var currentDate = moment().format("YYYY-MM-DD");
          // if ( aData.tpnm_created_date > currentDate ) {
          //   if ( jam._i > currentTime ) {
          //     // MASIH TIDAK EXPIRED
          //   }else{
          //     $('td', nRow).css('background-color', '#FFEA00');
          //   }
          // }else{
          //   $('td', nRow).css('background-color', '#FFEA00');
          // }

          if ( aData.app_type == "mobil"  ){
            $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
          }else if( aData.app_type == "motor"  ){
            $('td:eq(0)', nRow).css('background-color', '#F4A460');
          }
        },
        "columns": [
          {
            sortable: true,
            "render": function ( data, type, full, meta ) {
              return "<div id='status'>"+Utils.StatusAppType(full.app_type)+"</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              console.log(full)
              var typeapp
              full.tpif_id != null ? typeapp="<i style='color:#183c7a' class='fas fa-check-circle fa-2x'></i>" : typeapp=""
              // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
              return " <div class='text-center'>" + typeapp + "</div>";
            }
          },
          {
            "data": "tpnm_cancelled_date"
          },
          {
            "data": "tpnm_cancelled_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              return " <div >" + Utils.StepPembatalan(full.tpnm_step) + "</div>";
            }
          },
          {
            "data": "mper_name"
          },
          {
            "data": "mper_phone"
          },
          {
            "data": "tpnm_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              return " <div > " +Utils.DataNull(full.tpnm_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpnm_vbrand)+ " " + Utils.DataNull(full.tpnm_type) + " " + Utils.DataNull(full.tpnm_vcolor) + " " + Utils.DataNull(full.tpnm_vyear) + "</div>";
            }
          },
          {
            "data": "nego_status"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
              //   ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
              //   "<div id='aksi'><button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>";
                return `
                        <button id="dibatalkan1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dibatalkan1">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='detail' data-id="${full.tpnm_id}" data-step="1" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
            }
          },
        ]
      });
    },

    LPPembatalanInforman: (from,to) => {
      $('#pembatalanInfroman').DataTable({
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Foundingapi +"v1/Laporan_penemuan/pembatalan_informan?from="+from+"&to="+to,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            
          }
        },
        "columns": [
          {
            "data": "tpif_vtype"
          },
          // {
          //   sortable: true,
          //   "render": function (data, type, full, meta) {
          //     var typeapp
          //     full.tpif_id != null ? typeapp="Mitra (Oleh Informan)" : typeapp="Mitra"
          //     // var jam = moment(full.tpnm_created_time, "H:mm:ss").add(45, 'minutes');
          //     return " <div >" + typeapp + "</div>";
          //   }
          // },
          {
            "data": "tpif_cancelled_by"
          },
          {
            "data": "tpif_cancelled_date"
          },
          {
            "data": "tpif_cancelled_time"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // return " <div >" + full.tpnm_step + "</div>";
              return " <div >" + Utils.StepPembatalan(full.tpif_step) + "</div>";
            }
          },
          {
            "data": "mper_name_informan"
          },
          {
            "data": "mper_phone_informan"
          },
          {
            "data": "tpif_vplate"
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
              // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
              return " <div > " +Utils.DataNull(full.tpif_vtype).toUpperCase() + " - " + Utils.DataNull(full.tpif_vbrand)+ " " + Utils.DataNull(full.tpif_type) + " " + Utils.DataNull(full.tpif_vcolor) + " " + Utils.DataNull(full.tpif_vyear) + "</div>";
            }
          },
          {
            sortable: true,
            "render": function (data, type, full, meta) {
            //   return '<a href="https://www.google.com/maps/?q=' + full.tpnm_lat + ',' + full.tpnm_lng + '" ' +
            //     ' class="btn purple btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 tooltips" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>' +
            //     "<div id='aksi'><button class='btn yellow-gold btn btn-outline-brand btn-icon btn-sm mb-1 mr-1 detail tooltips' id='detail' data-id=" + full.tpnm_id + "> <i class='fa fa-eye'></i> </button>";
                return `
                        <button id="surattugasditerima2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="surattugasditerima2">
                          <a class="dropdown-item" target="_blank" href="https://www.google.com/maps/?q=${full.tpnm_lat+','+full.tpnm_lng}"><i class="fa fa-map-marker"></i>Lokasi</a>
                          <button class='dropdown-item' id='batalinforman' data-id="${full.tpif_id}" data-step="1" data-unit="${Utils.DataNull(full.tpif_vtype).toUpperCase()} - ${Utils.DataNull(full.tpif_vbrand)} ${Utils.DataNull(full.tpif_ptype)} ${Utils.DataNull(full.tpif_vcolor)} ${Utils.DataNull(full.tpif_vyear)}" data-original-title='Detail Data Penemuan'><i class="fas fa-eye"></i>Detail</button>
                        </div>`;
            }
          },
        ]
      });
    },

    LPTotal : (from,to,state) => {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/penemuan/totalAllStepPembatalan?from="+from+"&to="+to,
        "method": "GET",
        "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
      }

      $.ajax(settings).done(function (response) {
          $('#totalMatched1').html(response.data.totalMatched);
          $('#totalMatched2').html(response.data.totalMatchMaking);
          $('#totalMatched').html(parseInt(response.data.totalMatched)+parseInt(response.data.totalMatchMaking));
          $('#totalValidasi').html(response.data.totalValidasi);
          $('#totalMenungguSuratTugas').html(response.data.totalMenungguSuratTugas);
          $('#totalSuratTugasDiterima').html(response.data.totalSuratTugasDiterima);
          $('#totalPengantaran').html(response.data.totalPengantaran);
          $('#totalDalamPengataran').html(response.data.totalDalamPengataran);
          $('#totalSelesai').html(response.data.totalSelesai);
          $('#totalCancel').html(response.data.totalCancel);
        }).fail(function (response) {
          API.Handle403(response,state)
        })
    },

    LPTotalTpif : (from,to,state) => {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/penemuan/total_step_informan_lp?from="+from+"&to="+to,
        "method": "GET",
        "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
      }

      $.ajax(settings).done(function (response) {
          $('#totalValidasiTpif').html(response.data.totalStep1);
          $('#totalMatchmaking').html(response.data.totalStep2);
          $('#totalPembatalanInforman').html(response.data.pembatalanInforman);
        }).fail(function (response) {
          API.Handle403(response,state)
        })
    },

}