/* eslint-disable no-restricted-imports */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import "jquery-mask-plugin";
import $ from "jquery";
import API from "../../API";
import "select2";
import { AlertList } from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "80%",
  },
}));

export default function DialogInputTarikanWeb(props) {
  const [alerts, setAlerts] = useState([]);
  const [fileKendaraan1, setFileKendaraan1] = useState(null);
  const [fileKendaraan2, setFileKendaraan2] = useState(null);
  const [fileSTNK, setFileSTNK] = useState(null);
  const [fileBASTK, setFileBASTK] = useState(null);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    $(document).ready(function() {
      $("#user_mitra").select2({
        minimumInputLength: 0,
        dropdownParent: $("#input-tarikan-web-dialog"),
        ajax: {
          type: "get",
          url: API.Userapi + "v1/user/mitra",
          headers: {
            Authorization: "Basic " + localStorage.getItem("jwt"),
          },
          dataType: "json",
          error: function(xhr, error, thrown, settings) {
            API.Handle403(xhr, setAlerts);
          },
          data: function(params) {
            return {
              select2: params.term,
              length: 10,
            };
          },
          processResults: function(response, params) {
            return {
              results: $.map(response.data, function(item) {
                return {
                  mper_phone: item.mper_phone,
                  text: item.mper_name,
                  id: item.mper_id,
                };
              }),
            };
          },
        },
      });
      $("#user_mitra").on("change", function() {
        var data = $("#user_mitra").select2("data")[0];
        $("#no-hp-mitra").val(data.mper_phone);
      });

      $("#drop_point").select2({
        minimumInputLength: 0,
        dropdownParent: $("#input-tarikan-web-dialog"),
        ajax: {
          type: "get",
          url: API.Userapi + "v1/leasing/droppoint/" + props.mmle_id,
          headers: {
            Authorization: "Basic " + localStorage.getItem("jwt"),
          },
          dataType: "json",
          error: function(xhr, error, thrown, settings) {
            API.Handle403(xhr, setAlerts);
          },
          data: function(params) {
            return {
              select2: params.term,
              length: 10,
            };
          },
          processResults: function(response, params) {
            return {
              results: $.map(response.data, function(item) {
                return {
                  text: item.tadp_code + " - " + item.tadp_name,
                  id: item.tadp_id,
                };
              }),
            };
          },
        },
      });
    });
  });

  const classes = useStyles();

  const cleanForm = () => {
    $("#form-input-tarikan-web")[0].reset();
    $("#user_mitra")
      .val(0)
      .trigger("change");
    $("#drop_point")
      .val(0)
      .trigger("change");
  };

  const handleSubmit = () => {
    if (loading) return;

    setLoading(true);

    var form = new FormData();

    var selectData = $("#user_mitra").select2("data")[0];

    form.append("mper_id", $("#user_mitra").val());
    form.append("mper_name", selectData.text);
    form.append("mper_phone", $("#no-hp-mitra").val());
    form.append("tadp_id", $("#drop_point").val());
    form.append("tvec_id", props.tvec_id);

    form.append("tpnm_delivered_receiver_name", $("#tpnm_delivered_receiver_name").val());
    form.append("tpnm_delivered_receiver_phone", $("#tpnm_delivered_receiver_phone").val());

    form.append("tbnk_name", $("#tbnk_name").val());
    form.append("tbnk_number", $("#tbnk_number").val());
    form.append("tbnk_receiver_name", $("#tbnk_receiver_name").val());

    form.append("file_kendaraan1", fileKendaraan1);
    form.append("file_kendaraan2", fileKendaraan2);
    form.append("file_stnk", fileSTNK);
    form.append("file_bastk", fileBASTK);

    var settings = {
      async: true,
      crossDomain: true,
      url: API.Foundingapi + "v1/founding/input_tarikan_web",
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    };

    $.ajax(settings)
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Input Tarikan Web Telah Ditambahkan",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          setLoading(false);
          cleanForm();
          props.close();
        }, 1000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          setLoading(false);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="sm"
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: "999999999" }}
      >
        <DialogTitle className="dptitle" id="alert-dialog-slide-title">
          {props.title}
        </DialogTitle>
        <DialogContent id="input-tarikan-web-dialog">
          <AlertList showIcon={false} alerts={alerts} />
          <form className={classes.root} id="form-input-tarikan-web" autoComplete="off">
            <div className="form-group row">
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Nama Mitra
              </label>
              <div className="col-sm-7">
                <select className="form-control" id="user_mitra" style={{ width: "100%" }}>
                  <option value="0" selected disabled>
                    Pilih mitra
                  </option>
                </select>
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                No Hp Mitra
              </label>
              <div className="col-sm-7">
                <input type="text" className="form-control" id="no-hp-mitra" />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Drop Point
              </label>
              <div className="col-sm-7">
                <select className="form-control" id="drop_point" style={{ width: "100%" }}>
                  <option value="0" selected disabled>
                    Pilih Drop Point
                  </option>
                </select>
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Nama Penerima
              </label>
              <div className="col-sm-7">
                <input type="text" className="form-control" id="tpnm_delivered_receiver_name" />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                No HP Penerima
              </label>
              <div className="col-sm-7">
                <input type="text" className="form-control" id="tpnm_delivered_receiver_phone" />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Nama Bank
              </label>
              <div className="col-sm-7">
                <input type="text" className="form-control" id="tbnk_name" />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                No Rekening
              </label>
              <div className="col-sm-7">
                <input type="text" className="form-control" id="tbnk_number" />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Nama Pemilik Rekening
              </label>
              <div className="col-sm-7">
                <input type="text" className="form-control" id="tbnk_receiver_name" />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Upload Photo Motor 1
              </label>
              <div className="col-sm-7 custom-file">
                <input
                  type="file"
                  id="file-kendaraan1"
                  onChange={(e) => setFileKendaraan1(e.target.files[0])}
                  required
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Upload Photo Motor 2
              </label>
              <div className="col-sm-7 custom-file">
                <input
                  type="file"
                  id="file-kendaraan2"
                  onChange={(e) => setFileKendaraan2(e.target.files[0])}
                  required
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Upload STNK
              </label>
              <div className="col-sm-7 custom-file">
                <input
                  type="file"
                  id="file-stnk"
                  onChange={(e) => setFileSTNK(e.target.files[0])}
                  // required
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: "-15px" }}>
              <label for="inputEmail3" className="col-sm-5 col-form-label">
                Upload BASTK
              </label>
              <div className="col-sm-7 custom-file">
                <input
                  type="file"
                  id="file-bastk"
                  onChange={(e) => setFileBASTK(e.target.files[0])}
                  required
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              cleanForm();
              props.close();
            }}
            color="primary"
          >
            Batal
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading ? "disabled" : ""}>
            {props.btn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
