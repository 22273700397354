import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'jquery-mask-plugin'
import $ from 'jquery'
import API from '../../API'
// import $ from 'jquery'
// import { AlertList} from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
}));

export default function DialogIsiBank(props) {

    React.useEffect(() => {
        $( '#tvoc_bank_ekspedisi_account_number' ).mask('###0', {reverse: true});  
    })

    const classes = useStyles();

    return (
      <div>
          <Dialog
              open={props.open}
              TransitionComponent={Transition}
              fullWidth={true}
              maxWidth="sm"
              keepMounted
              onClose={props.close}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              style={{zIndex:'999999999'}}
          >
          <DialogTitle className="dptitle" id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <DialogContent id="isibank">
            <form className={classes.root} id="isibank" autoComplete="off">
                <div className="form-group row" style={{display:props.nego}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Ekspedisi</label>
                  <div className="col-sm-8">
                     <input type="text" className="form-control" id="tvoc_ekspedisi_name" name="tvoc_ekspedisi_name"/>
                  </div>
                </div>
                <div className="form-group row" style={{display:props.nego}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Bank</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" id="tvoc_bank_ekspedisi_name" name="tvoc_bank_ekspedisi_name"/>
                </div>
                </div>
                <div className="form-group row" style={{display:props.nego}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">No Rekening</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" id="tvoc_bank_ekspedisi_account_number" name="tvoc_bank_ekspedisi_account_number"/>
                </div>
                </div>
                <div className="form-group row" style={{display:props.nego}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" id="tvoc_bank_ekspedisi_account_name" name="tvoc_bank_ekspedisi_account_name"/>
                </div>
                </div>
            </form>
          </DialogContent>
          <DialogActions style={{height:'100px'}}>
            <Button style={{marginTop:'40px'}} onClick={props.close} color="primary">
              Batal
            </Button>
            <Button style={{marginTop:'40px'}} onClick={props.fun} color="primary">
              {props.btn}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      );

}

