/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../../API'
// import DialogOnboard from './Utils/DialogOnboard'
// import DialogHapusBatal from './Utils/DialogHapusBatal'
// import { AlertList} from "react-bs-notifier";
import {
Portlet,
PortletBody,
PortletHeader,
PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import ExcelDroppoint from './droppoint_leasing.xlsx'
import Table from './TableLeasing'
import DetailDropPoint from '../Utils/DetailDropPoint'
import DialogDropPoint from '../Utils/DialogDropPoint'
import DialogHapusBatal from '../Utils/DialogHapusBatal'
import Utils from '../Utils/FunctionDetail'
import { AlertList} from "react-bs-notifier";
import bsCustomFileInput from 'bs-custom-file-input'
import {ProgressBar, Card} from 'react-bootstrap';

export default function Droppoint(props) {

    React.useEffect(()=>{
        $(document).ready(function () {
            if(props.id != null ) {
                Table.Droppoint(props.id,setAlerts1);
                $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
            }
    
            $('#droppoint').off('click').on('click', function() {
                $('#droppoint').on('click', '#edit', function (){
                    handleClickOpen4()
                    setEditId($(this).attr('data-id'));
                     var settings = {
                      "async": true,
                      "crossDomain": true,
                      "url": API.Userapi+"v1/leasing/detail_dp/"+$(this).attr('data-id'),
                      "method": "GET",
                      "headers": {
                          "Authorization": "Baisc "+localStorage.getItem('jwt')
                        }
                    }
            
                    $.ajax(settings).done(function (response) {
                      console.log(parseInt(response.data.tadp_status));
                      if( response.data != null ){
                        $('#tadp_code1').val(response.data.tadp_code);
                        $('#tadp_name1').val(response.data.tadp_name);
                        if (parseInt(response.data.tadp_status) == 0) {
                            $('#tadp_status0').prop( "checked", true );
                        }else if(parseInt(response.data.tadp_status) == 1){
                            $('#tadp_status1').prop( "checked", true );
                        }
                        // $('#tadp_status1').val(response.data.tadp_status);
                        $('#tadp_lat1').val(response.data.tadp_lat);
                        $('#tadp_open_time1').val(response.data.tadp_open_time);
                        $('#tadp_address1').val(response.data.tadp_address);
                        $('#tadp_lng1').val(response.data.tadp_lng);
                        $('#tadp_closed_time1').val(response.data.tadp_closed_time);
                        $('#dp_valid_from1').datepicker('setDate', response.data.tadp_valid_from);
                        $('#dp_valid_to1').datepicker('setDate', response.data.tadp_valid_to);
                    }
                    }).fail(function (response) {
                        API.Handle403(response,setAlerts1)
                    })
                })
            
                $('#droppoint').on('click', '#detaildp', function (){
                    // tadp_id = $(this).attr('data-id');
                    console.log("detaildp")
                    handleClickOpen()
            
                    var settings = {
                      "async": true,
                      "crossDomain": true,
                      "url": API.Userapi+"v1/leasing/detail_dp/"+$(this).attr('data-id'),
                      "method": "GET",
                      "headers": {
                          "Authorization": "Baisc "+localStorage.getItem('jwt')
                        }
                    }
                    $.ajax(settings).done(function (response) {
                      console.log(response);
                      if( response.data != null ){
                        $('#tadp_code').val(response.data.tadp_code).attr("disabled",true);
                        $('#tadp_name').val(response.data.tadp_name).attr("disabled",true);
                        $('#tadp_status').val(Utils.StatusDroppoint(response.data.tadp_status)).attr("disabled",true);
                        $('#tadp_lat').val(response.data.tadp_lat).attr("disabled",true);
                        $('#tadp_open_time').val(response.data.tadp_open_time).attr("disabled",true);
                        $('#tadp_address').val(response.data.tadp_address).attr("disabled",true);
                        $('#tadp_lng').val(response.data.tadp_lng).attr("disabled",true);
                        $('#tadp_closed_time').val(response.data.tadp_closed_time).attr("disabled",true);
                        $('#tanggal_valid').val(response.data.tadp_valid_from+" - "+response.data.tadp_valid_to).attr("disabled",true);
                      }
                    }).fail(function (response) {
                      console.log(response);
                      API.Handle403(response,setAlerts1)
                    });
                });
            
                $('#droppoint').on('click', '#hapusdp', function (){
                    setTadpId($(this).attr('data-id'));
                    handleClickOpen1()
                })
            })

            bsCustomFileInput.init()
  
            // $('#aplodleasing,#cancelaplod').on('click',function () {
            //     $('#aplodform').toggle('slow')
            // })
        })
    })

    const [open4, setOpen4] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [tadpid, setTadpId] = React.useState(null);
    const [editid, setEditId] = React.useState(null);
    const [filedp, setFiledp] = React.useState(null);
    const [alerts1, setAlerts1] = React.useState([]);
    const [uploadname, setUploadname] = React.useState(null);
    const [progress, setProgress] = React.useState(0);

    function ToggleUpload() {
        $('#aplodform').toggle('slow')
    }

    function handleClickOpen() {
        setOpen(true);
        $('#dpleasing input').val('').attr("disabled",false)
    }

    function handleClose() {
        setOpen(false);
    }

    function handleClickOpen1() {
        setOpen1(true);
    }

    function handleClose1() {
        setOpen1(false);
    }

    function handleClickOpen2() {
        setOpen2(true);
    }

    function handleClose2() {
        setOpen2(false);
    }

    function handleClickOpen4() {
        setOpen4(true);
    }

    function handleClose4() {
        $("#dpleasing input").val('').prop('checked', false);
        setOpen4(false);
        setEditId(null)
    }

    function FileDP(e) {
        // setFileDP(e.target.files[0])
        setFiledp(e.target.files[0])
        // console.log(b)
    }

    function UploadDP() {
        handleClose2()
        var form = new FormData();
        form.append("uploaded_file", filedp);
        setUploadname(filedp.name)
        $('#uploading').show('slow')
        $('#form_dp').hide('slow')

        var settings = {
            xhr : function() {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener('progress', function(e){
                    if(e.lengthComputable){
                        console.log(e);
                        console.log('Bytes Loaded : ' + e.loaded);
                        console.log('Total Size : ' + e.total);
                        console.log('Persen : ' + (e.loaded / e.total));
                        
                        var percent = Math.round((e.loaded / e.total) * 100);
                        console.log("Persen : "+ percent)
                        
                        setProgress(percent)
                    }
                });
                return xhr;
            },
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/Droppoint/upload/"+props.id,
            "method": "POST",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem("jwt"),
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": form
        }

        $.ajax(settings).done(function (response) {
            // $('#uploading').hide('slow')
            // $('#form_dp').show('slow')
            setAlerts1([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Drop Point Telah Diupload"
            }])
            setTimeout(() => {
                setAlerts1([])
                $('#uploading').hide('slow')
                $('#form_dp').show('slow')
            }, 3000)
        }).fail(function (response) {
            $('#uploading').hide('slow')
            $('#form_dp').show('slow')
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code " + response.status + "]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts1)
        })
    }
    
    function TambahDropPoint() {
        var mmle_id = props.id;
        var tadp_code = $("#tadp_code1").val();
        var tadp_name = $("#tadp_name1").val();
        var tadp_status = $('input[name=tadp_status1]:checked').val();
        // var user_api = $('#user_api').val();

        // console.log(tadp_status);
        if (tadp_status == "Aktif") {
        tadp_status = 0;
        } else if (tadp_status == " Tidak Aktif") {
        tadp_status = 1;
        }
        var tadp_lat = $("#tadp_lat1").val();
        var tadp_lng = $("#tadp_lng1").val();
        var dp_valid_from = $("#dp_valid_from1").val();
        var dp_valid_to = $("#dp_valid_to1").val();
        var tadp_address = $("#tadp_address1").val();
        var tadp_open_time = $("#tadp_open_time1").val();
        var tadp_closed_time = $("#tadp_closed_time1").val();

        var data = {
        'mmle_id': mmle_id,
        'tadp_code': tadp_code,
        'tadp_name': tadp_name,
        'tadp_status': tadp_status,
        'tadp_lat': tadp_lat,
        'tadp_lng': tadp_lng,
        'tadp_valid_from': dp_valid_from,
        'tadp_valid_to': dp_valid_to,
        'tadp_address': tadp_address,
        'tadp_open_time': tadp_open_time,
        'tadp_closed_time': tadp_closed_time,
        };

        var url = API.Userapi + "v1/leasing/add_dp/"+props.id;

        if (editid != null) {
        url = API.Userapi + "v1/leasing/update_dp/" + editid;
        }

        var settings = {
        "async": true,
        "crossDomain": true,
        "url": url,
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",
            "Authorization": "Baisc " + localStorage.getItem('jwt')
        },
        "processData": false,
        "data": JSON.stringify(data)
        }
        $.ajax(settings).done(function (response) {
            handleClose4()
            setAlerts1([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Drop Point Telah Disimpan"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
        }).fail(function (response) {
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code " + response.status + "]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts1)
        })
    }

    function HapusDP() {
        handleClose1()
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/leasing/delete_dp/"+tadpid,
            "method": "GET",
            "headers": {
              "Authorization": "Baisc "+localStorage.getItem('jwt')
              }
            }
            $.ajax(settings).done(function (response) {
                setAlerts1([{
                    id: 1,
                    type: "success",
                    headline: "Berhasil",
                    message: "Drop Point Telah Dihapus"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
                return 0;
            }).fail(function (response) {
                handleClose1()
                setAlerts1([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code " + response.status + "]"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
                API.Handle403(response,setAlerts1)
                return 0;
            })
    }

    return(
        <>
            <AlertList showIcon={false} alerts={alerts1} />
            <DialogHapusBatal 
                open={open1} 
                close={handleClose1} 
                title="Hapus Drop Point Leasing" 
                message="Anda Yakin Ingin Hapus Drop Point Ini ?" 
                btn="Hapus" 
                fun={HapusDP}
                // type="hapusleasing" 
                // id={idhapus}
            />
            <DialogHapusBatal 
                open={open2} 
                close={handleClose2} 
                title="Upload Drop Point Leasing" 
                message="Anda Yakin Ingin Upload File Drop Point Ini ?" 
                btn="Upload" 
                fun={UploadDP}
                // type="hapusleasing" 
                // id={idhapus}
            />
            <DetailDropPoint
            open={open}
            close={handleClose}
            />
            <DialogDropPoint
                open={open4}
                close={handleClose4}
                //   title={editid != null ? "Edit Drop Point" : "Tambah Drop Point"}
                //   btn={editid != null ? "Edit" : "Tambah"}
                fun={TambahDropPoint}
            />
            <Portlet>
                <PortletHeader title="Drop Point" toolbar={(
                    <PortletHeaderToolbar>
                        <a
                            href={ExcelDroppoint}
                            type="button"
                            className="btn btn-label-primary btn-sm"
                        >
                            <i class="fas fa-file-download"></i>Download Template
                        </a>
                        <button
                            // to="/tambahleasing"
                            type="button"
                            className="btn btn-label-primary btn-sm mr-1 ml-1"
                            onClick={ToggleUpload}
                        >
                            <i class="fas fa-file-upload"></i>Upload Data
                        </button>
                        <button
                            // to="/tambahleasing"
                            onClick={handleClickOpen4}
                            type="button"
                            className="btn btn-label-primary btn-sm"
                        >
                            <i class="fas fa-plus"></i>Tambah Data
                        </button>
                    </PortletHeaderToolbar>
                )}/>
                <PortletBody>
                <div class="mb-2" id="aplodform" style={{display:'none'}}>
                    <div id="form_dp" className="input-group col-md-12">
                        <div class="input-group-prepend">
                            <button class="btn btn-warning btn-elevate" type="button" onClick={ToggleUpload} >Batal</button>
                        </div>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customfile" onChange={FileDP} aria-describedby="customfile"/>
                            <label class="custom-file-label" for="customfile">Pilih File</label>
                        </div>
                        <div class="input-group-append">
                            <button class="btn btn-primary btn-elevate" type="button" onClick={handleClickOpen2} id="inputGroupFileAddon04">Upload</button>
                        </div>
                    </div>
                    <div id="uploading" className="col-md-12"style={{display:'none'}}>
                        <Card bg="primary" text="white">
                            <Card.Body>
                            <Card.Title>
                                <h3>
                                    Mengupload Data
                                </h3>
                            </Card.Title>
                            <hr style={{borderTop: '1px solid #f4a240'}}/>
                            <Card.Text>
                                <h4>Nama File : <span>{uploadname}</span></h4>
                                <h4>Proses : <span>{progress}% {progress == 100 ? "(Memproses Di Server)" : ""}</span></h4>
                                <ProgressBar animated  now={progress} variant="warning"/>
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                    {/* <Divider variant="middle" /> */}
                    <table className="table table-hover table-responsive-md" id="droppoint" style={{width:'100%',overflow:'auto'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                <th class="all"> Code </th>
                                <th class="all"> Nama </th>
                                <th class="all"> Tanggal Valid </th>
                                <th class="all"> Status </th>
                                <th class="all"> Aksi </th>
                                </tr>
                            </thead>
                    </table>
                </PortletBody>
            </Portlet>
        </>
    )
}