/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
  } from "../../partials/content/Portlet";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Check from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PaymentIcon from '@material-ui/icons/Payment';
import moment from 'moment' 
import API from '../API'
import DetailVoucherRefund from './Utils/DetailVoucherRefund'
import { AlertList} from "react-bs-notifier";
import { useHistory  } from "react-router-dom";

const formatter = new Intl.NumberFormat('de-DE');

const useStyles = makeStyles(theme => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
    },
    dense: {
      marginTop: 19,
    },
}));

const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 35,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,#183c7a 0%,#a1783f 50%,#f4a240 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,#153264 0%,#153264 50%,#153264 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 70,
      height: 70,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, #153264 0%, #153264 50%, #153264 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, #183c7a 0%, #183c7a 50%, #183c7a 100%)',
    },
  });
  
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    const icons = {
      1: <AssignmentIcon fontSize="large"/>,
      2: <AssignmentTurnedInIcon fontSize="large"/>,
      3: <PaymentIcon fontSize="large" color="secondary"/>,
      4: <DoneAllIcon fontSize="large"/>,
    };
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? icons[String(props.icon)] : icons[String(props.icon)]}
        {/* {completed ? <Check className={classes.completed} fontSize="large"/> : icons[String(props.icon)]} */}
        
      </div>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

function getSteps() {
    return ['Menunggu Persetujuan', 'Voucher Diapprove', 'Menunggu Pembayaran', 'Voucher Sudah Lunas'];
  }

export default function VoucherRefund() {
    const classes = useStyles();
    const history = useHistory();
    const [alerts,setAlerts] = React.useState([]);
    const [open , setOpen] = React.useState(false);
    const [id , setId] = React.useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClickClose = () => {
        setOpen(false);
    }

    useEffect(() => {
      $(document).ready(function () {
        $(function () {
          $('#vldfrom,#vldto').datepicker({
           todayHighlight: true,
           autoclose: true,
           format: "yyyy-mm-dd",
         })
        });
        $('#vldfrom').val(moment().format('YYYY-MM-DD'))
        $('#vldto').val(moment().format('YYYY-MM-DD'))
      })
      $(document).on("click","#details" , function (){
        handleClickOpen()
        setId($(this).attr("data-id"));
      });
    },[setAlerts])


    function FilterSiapDibayar() {
      if( $('#vldfrom').val() == 0 || $('#vldto').val() == 0){
        return 0 ;
      }
        var from = moment($('#vldfrom').val()).format('MM-DD-YYYY')
        var to = moment($('#vldto').val()).format('MM-DD-YYYY')
        var url
        if( from != 'Invalid date' && to != 'Invalid date' ){
            url = API.Financeapi +"/v1/VoucherRefund/index?from="+from+"&to="+to;
          }else{
            url = API.Financeapi +"/v1/PVoucherRefund/index";
          }
          $('#voucher-refund').DataTable( {
            "pagingType": "full_numbers",
            "processing": true,
            "serverSide": true,
            "destroy":true,
            "ajax": {
                "url": url,
                "type": 'get',
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
              },
              error: function (xhr, error, thrown , settings) {
                API.Handle403(xhr,setAlerts)
              }
            },
            "columns": [
                { "data": "mper_name" },
                { "data": "thrf_total" },
                { "data": "thrf_status" },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                      console.log(full)
                        return `
                          <button id="ready1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="ready1">
                              <button class='dropdown-item' id='details' data-id="${full.thrf_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail</button>
                          </div>`;
                    }
                    // Generate Voucher
                    // <button class='dropdown-item' id='print' data-id="${full.tpnm_id}" data-tvoc="${full.tvoc_id}" data-tvoctype="${full.tvoc_informan_type}" data-fee="${full.tvoc_ammount}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-print'></i>Generate Voucher</button>
                },
            ]
      });
            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
    }    
    return(
        <>     
            <AlertList showIcon={false} alerts={alerts}/>
            <DetailVoucherRefund 
            open = {open}
            close = {handleClickClose}
            id = {id}/>
            <Portlet>
                <Portlet>
                    <PortletHeader title="Data Voucher Refund" />
                    <PortletBody>
                        <PortletBody>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                    <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                                            <input type="text" className="form-control" name="start" id="vldfrom" autocomplete="off"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">To</span>
                                            </div>
                                            <input type="text" className="form-control" name="end" id="vldto" autocomplete="off"/>
                                        </div>
                                      <button type="button" class="btn btn-brand btn-elevate btn-sm" onClick={FilterSiapDibayar} id="cari" style={{width:'100%'}}>Set Filter</button>
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                </Grid>
                            </Grid> 
                        </PortletBody>
                        <table className="table table-hover table-responsive-md" id="voucher-refund" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                  <th scope="col">Nama</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                        </table>
                    </PortletBody>
                </Portlet>
            </Portlet>
        </>
    )
}