/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import {
    Portlet,
    PortletBody,
    // PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
// import Utils from './FunctionDetail'
import $ from 'jquery'
import moment from 'moment'
import 'moment/locale/id';
import { getFeeMitra } from '../../../functions/invoice' 

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset : theme.mixins.toolbar,
    marginTop : '70px'
  },
  detail2: {
    offset : theme.mixins.toolbar,
    marginTop : '100px'
  } 
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailKendaraan(props) {
  const classes = useStyles();

  React.useEffect(() => {
    
    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Financeapi +"/v1/Voucher_lunas/printInvoice/"+props.id+"?informan_type="+props.mpertype,
      "method": "GET",
      "headers": {
        "Authorization": "Baisc " + localStorage.getItem('jwt')
      }
    }
    if(props.id != null && props.mpertype != null ) {
      $.ajax(settings).done(function (response) {

        const formatter = new Intl.NumberFormat('de-DE');
        if (response.data != null) {
            if(props.mpertype === 'mitra') {
              $('#type_mitra').show()
              $('#type_informan').hide()
              $('#ekspedisi').show()
              $('#type_upline').hide()
              $('#referal').hide()
            }else if(props.mpertype === 'informan'){
              $('#type_mitra').hide()
              $('#type_informan').show()
              $('#ekspedisi').hide()
              $('#type_upline').hide()
              $('#referal').hide()
            }else{
              $('#type_mitra').hide()
              $('#type_informan').hide()
              $('#ekspedisi').hide()
              $('#type_upline').show()
              $('#referal').show()
            }
            $('#mper_name_pdf').val(response.data.mper_name);
            $('#mmle_name_pdf').val(`${response.data.mmle_name} - ${response.data.tpnm_branch}`);
            $('#tpnm_type_pdf').val(response.data.tpnm_type);
            $('#tpnm_vplate_pdf').val(response.data.tpnm_vplate);
            $('#tpnm_vyear_pdf').val(response.data.tpnm_vyear);
            moment.locale('id');
            $('#tvoc_created_date_pdf').val(`     ${moment(response.data.tvoc_created_date).format('LL')}`);
  
            if(response.data.tvoc_status_stnk == 1 || response.data.tvoc_status_stnk == '1'){
              $('#tvoc_status_stnk_pdf').val('Ada');
            }else{
              $('#tvoc_status_stnk_pdf').val('Tidak Ada');
            }
  
            var fee_mitra = response.data.tvoc_ammount_revisi != null ? response.data.tvoc_ammount_revisi : response.data.tvoc_ammount
            var fee_stnk = response.data.tvoc_fee_stnk_revisi != null ? response.data.tvoc_fee_stnk_revisi : response.data.tvoc_fee_stnk
            var ammount = getFeeMitra(response.data.tvoc_status_stnk,fee_mitra, fee_stnk)
            $('#tvoc_ammount_pdf').val(formatter.format(parseInt(ammount)));
  
            
            $('#tbnk_name_pdf').val(response.data.tbnk_name);
            $('#tbnk_number_pdf').val(response.data.tbnk_number);
            $('#tbnk_receiver_name_pdf').val(response.data.tbnk_receiver_name);
            $('#ekspedisi_name_pdf').val(response.data.tvoc_ekspedisi_name);
            $('#tvoc_bank_ekspedisi_name_pdf').val(response.data.tvoc_bank_ekspedisi_name);
            $('#tvoc_bank_ekspedisi_number_pdf').val(response.data.tvoc_bank_ekspedisi_account_number);
            $('#tvoc_bank_ekspedisi_account_pdf').val(response.data.tvoc_bank_ekspedisi_account_name);
            
            var biaya_kirim_pdf = response.data.tvoc_biaya_kirim_real ? response.data.tvoc_biaya_kirim_real : 0
            $('#tvoc_biaya_kirim_pdf').val(formatter.format(parseInt(biaya_kirim_pdf)));
  
            $('#tvoc_ekspedisi_created_date_pdf').val(`     ${moment(response.data.tvoc_created_date).format('LL')}`);
            $('#mper_referal_name_pdf').val(response.data.mper_referal_name);
        }
      })
    }

  })

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>           
            <Button
              onClick={()=>window.print()}
              color="inherit"
              size="large"
              startIcon={<PrintIcon />}
            >
              Print
            </Button>
            <Typography variant="h6" className={classes.title}>
                {/* Detail Kendaraan */}
            </Typography>
            <IconButton color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TabPanel value={0} index={0}>
          <Portlet className={classes.detail} style={{marginTop:'20px'}}>
            <PortletBody style={{ height:'100vh' }}>
                <Row>
                  <Col md={12} className="mb-3">
                    <div style={{borderBottom: 'solid black 1.5px'}}>
                      <h1>
                        PT. BETA ASET INDONESIA
                      </h1>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ padding:'10px 40px 10px'}}>
                  <h1 className="mb-5">Perihal      :       Pengajuan Gaji Harian Lepas</h1>
                  <div className="form-group row">
                    <h4 id="type_mitra" className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Nama Mitra</h4>
                    <h4 id="type_informan" className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Nama Informan</h4>
                    <h4 id="type_upline" className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Nama Upline</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='mper_name_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Nama Leasing</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='mmle_name_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Merk/Type</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='tpnm_type_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Nomor Polisi</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='tpnm_vplate_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>STNK</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='tvoc_status_stnk_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Tahun</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='tpnm_vyear_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Pembayaran</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='tvoc_ammount_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div id="referal" className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Penemuan Dari Mitra</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='mper_referal_name_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Pembayaran ke</h4>
                    <div className="col-8">
                      <div className="form-group row">
                        <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Bank</h4>
                        <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                          <input type="text" readonly class="form-control-plaintext" id='tbnk_name_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>No. Rekening</h4>
                        <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                          <input type="text" readonly class="form-control-plaintext" id='tbnk_number_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>A/N</h4>
                        <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                          <input type="text" readonly class="form-control-plaintext" id='tbnk_receiver_name_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={12} style={{ padding:'10px 40px 10px'}}>
                  <div className="form-group row">
                    <h4 className="col-form-label" style={{fontSize:'25px',color:'black'}}>Jakarta,</h4>
                    <div className="col-8">
                      <input type="text" readonly class="form-control-plaintext" id='tvoc_created_date_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4} style={{ padding:'10px 40px 10px'}}>
                  <div>
                    <h2 style={{marginBottom:'100px'}}>Dibuat Oleh:</h2>
                    <h2>Debora</h2>
                  </div>
                </Col>
                <Col md={4} style={{ padding:'10px 40px 10px'}}>
                  <div className="text-center">
                    <h2 style={{marginBottom:'100px'}}>Diperiksa Oleh:</h2>
                    <h2>Yayuk</h2>
                  </div>
                </Col>
                <Col md={4} style={{ padding:'10px 40px 10px'}}>
                  <div className="pull-right">
                    <h2 style={{marginBottom:'100px'}}>Disetujui Oleh:</h2>
                    <h2>Eduardus B</h2>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>

          <Portlet className={classes.detail2} id="ekspedisi" >
            <PortletBody  >
              <Row>
                  <Col md={12} className="mb-3">
                    <div style={{borderBottom: 'solid black 1.5px'}}>
                      <h1>
                        PT. BETA ASET INDONESIA
                      </h1>
                    </div>
                  </Col>
                </Row>
              <Row>
                <Col md={12} style={{ padding:'10px 40px 10px'}}>
                  <h1 className="mb-5">Perihal      :       Pembayaran Biaya Kirim Ekspedisi</h1>

                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Nama Ekspedisi</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='ekspedisi_name_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>

                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Pembayaran</h4>
                    <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                      <input type="text" readonly class="form-control-plaintext" id='tvoc_biaya_kirim_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                  <div className="form-group row" style={{marginTop:'-15px'}}>
                    <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Pembayaran ke</h4>
                    <div className="col-8">
                      <div className="form-group row">
                        <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>Bank</h4>
                        <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                          <input type="text" readonly class="form-control-plaintext" id='tvoc_bank_ekspedisi_name_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>No. Rekening</h4>
                        <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                          <input type="text" readonly class="form-control-plaintext" id='tvoc_bank_ekspedisi_number_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                        </div>
                      </div>
                      <div className="form-group row">
                        <h4 className="col-4 col-form-label" style={{fontSize:'25px',color:'black'}}>A/N</h4>
                        <div className="col-8" style={{borderBottom: 'dotted black 1px'}}>
                          <input type="text" readonly class="form-control-plaintext" id='tvoc_bank_ekspedisi_account_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={12} style={{ padding:'10px 40px 10px'}}>
                  <div className="form-group row">
                    <h4 className="col-form-label" style={{fontSize:'25px',color:'black'}}>Jakarta,</h4>
                    <div className="col-8">
                      <input type="text" readonly class="form-control-plaintext" id='tvoc_ekspedisi_created_date_pdf' disabled style={{fontSize:'25px',color:'black'}}/>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4} style={{ padding:'10px 40px 10px'}}>
                  <div>
                    <h2 style={{marginBottom:'100px'}}>Dibuat Oleh:</h2>
                    <h2>Debora</h2>
                  </div>
                </Col>
                <Col md={4} style={{ padding:'10px 40px 10px'}}>
                  <div className="text-center">
                    <h2 style={{marginBottom:'100px'}}>Diperiksa Oleh:</h2>
                    <h2>Yayuk</h2>
                  </div>
                </Col>
                <Col md={4} style={{ padding:'10px 40px 10px'}}>
                  <div className="pull-right">
                    <h2 style={{marginBottom:'100px'}}>Disetujui Oleh:</h2>
                    <h2>Eduardus B</h2>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
        </TabPanel>
      </Dialog>
    </div>
  );
}
