/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'jquery-mask-plugin'
import $ from 'jquery'
// import API from '../../API'
// import $ from 'jquery'
// import { AlertList} from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
}));

export default function DialogPricing(props) {

    React.useEffect(() => {
      $( '#tprc_base_price,#tprc_fee,#tprc_fee_stnk,#tprc_bp_stnk' ).mask('0.000.000.000', {reverse: true});
    })

    const classes = useStyles();

    return (
      <div>
          <Dialog
              open={props.open}
              TransitionComponent={Transition}
              fullWidth={true}
              maxWidth="sm"
              keepMounted
              onClose={props.close}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              style={{zIndex:'999999999'}}
          >
          <DialogTitle className="dptitle" id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <DialogContent id="pricedialog">
            <form className={classes.root} id="dpleasing" autoComplete="off">
              <div className="form-group row" style={{display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-5 col-form-label">Tipe Kendaraan</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="tprc_ptype" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-5 col-form-label">Tahun Kendaraan</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="tprc_vyear" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-5 col-form-label">Kategori</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="tprc_cwo_wo" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-5 col-form-label">Base Price</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="tprc_base_price" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-5 col-form-label">BP STNK</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="tprc_bp_stnk" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-5 col-form-label">Fee Ke Mitra Eksekutor</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="tprc_fee" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-5 col-form-label">Fee STNK</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="tprc_fee_stnk" />
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close} color="primary">
              Batal
            </Button>
            <Button onClick={props.fun} color="primary">
              {props.btn}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      );

}

