/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React, {useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../partials/content/Portlet";
import API from '../API'
// import TemplateUploadData from './DownloadTemplate/template_upload_data.xlsx'
// import { AlertList} from "react-bs-notifier";
// import bsCustomFileInput from 'bs-custom-file-input'
// import DialogHapusBatal from './Utils/DialogHapusBatal'
import UploaddataLeasing from './UploadData/UploaddataLeasing'
import { AlertList} from "react-bs-notifier";

export default function UploadData() {

    const [alerts, setAlerts] = React.useState([]);

     useEffect(() => {
         $(document).ready(function () {

            $('#dp_terdaftar').DataTable( {
                "pagingType": "full_numbers",
                "processing": true,
                "serverSide": true,
                "destroy": true,
                "ajax": {
                    "url": API.Userapi+"v1/Droppoint/dpTerdaftar",
                    "type": 'get',
                    "beforeSend": function (xhr) {
                        xhr.setRequestHeader('Authorization',
                            "Basic " + localStorage.getItem('jwt'));
                  },
                },
                "columns": [
                    { "data": "tadp_code" },
                    { "data": "tadp_name" },
                ]
            });
            
            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
         })
     },[setAlerts])

    return(
        <> 
            <AlertList  showIcon={false} alerts={alerts}/> 
            <Portlet>
                <PortletHeader title="Drop Point Terdaftar"/>
                <PortletBody>
                    <table className="display table table-hover table-responsive-md" id="dp_terdaftar" style={{width:'100%'}}>
                        <thead className="bg-primary text-center" style={{color:'white'}}>
                            <tr>
                                <th scope="col">Kode Drop Point</th>
                                <th scope="col">Nama Drop Point</th>
                            </tr>
                        </thead>
                    </table>
                </PortletBody>
            </Portlet>
            <UploaddataLeasing/>
        </>
    )
}