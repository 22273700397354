export default {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "fas fa-chart-line",
        page: "dashboard",
        id: "dashboard-aside",
      },
      {
        title: "Penemuan",
        root: true,
        icon: "fas fa-tasks",
        page: "penemuan",
        id: "penemuan-aside",
      },
      {
        title: "User",
        root: true,
        icon: "fas fa-user-tie",
        page: "user",
        id: "user-aside",
      },
      {
        title: "Koordinator",
        root: true,
        icon: "fas fa-user-friends",
        page: "koordinator",
        id: "koordinator-aside",
      },
      {
        title: "Leasing",
        root: true,
        icon: "fas fa-building",
        page: "leasing",
        id: "leasing-aside",
      },
      {
        title: "Setting Konfigurasi",
        root: true,
        icon: "fas fa-cogs",
        page: "settingconfig",
        id: "settingconfig-aside",
      },
      {
        title: "Setting Role Akses",
        root: true,
        icon: "fas fa-unlock-alt",
        page: "roleakses",
        id: "roleakses-aside",
      },
      {
        title: "Template Reason",
        root: true,
        icon: "fas fa-exclamation-circle",
        bullet: "dot",
        page: "templatereason",
        id: "templatereason-aside",
        submenu: [
          {
            title: "Reason Batal",
            page: "reasonbatal",
            id: "reasonbatal-aside",
          },
          {
            title: "Reason Nego",
            page: "reasonnego",
            id: "reasonnego-aside",
          },
        ],
      },
      {
        title: "Upload Data",
        root: true,
        icon: "fas fa-upload",
        page: "uploaddata",
        id: "uploaddata-aside",
      },
      {
        title: "Data Terupload",
        root: true,
        icon: "fas fa-file-import",
        page: "terupload",
        id: "terupload-aside",
      },
      {
        title: "Leasing Data Terupload",
        root: true,
        icon: "fas fa-file-alt",
        page: "leasinguploaded",
        id: "leasinguploaded-aside",
      },
      {
        title: "History Upload",
        root: true,
        icon: "fas fa-history",
        page: "historydataupload",
        id: "historydataupload-aside",
      },
      {
        title: "History Upload",
        root: true,
        icon: "fas fa-history",
        page: "dataapprover",
        id: "dataapprover-aside",
      },
      {
        title: "Hasil Data Terupload",
        root: true,
        icon: "flaticon-download-1",
        page: "hasildata",
        id: "hasildata-aside",
      },
      {
        title: "Data Kendaraan Aktif",
        root: true,
        icon: "fas fa-motorcycle",
        page: "kendaraan",
        id: "kendaraan-aside",
      },
      {
        title: "Laporan",
        root: true,
        icon: "fas fa-chart-pie",
        bullet: "dot",
        id: "laporan-aside",
        submenu: [
          {
            title: "Penemuan",
            page: "laporanpenemuan",
            id: "laporanpenemuan-aside",
          },
          {
            title: "Invoice",
            page: "laporaninvoice",
            id: "laporaninvoice-aside",
          },
          {
            title: "Pembatalan",
            page: "laporanpembatalan",
            id: "laporanpembatalan-aside",
          },
          {
            title: "Jumlah Penemuan Mitra",
            page: "laporanmonthpenemuan",
            id: "laporanmonthpenemuan-aside",
          },
          {
            title: "Jumlah Pendaftaran Mitra",
            page: "laporanmitraregister",
            id: "laporanmitraregister-aside",
          },
          {
            title: "Lokasi",
            page: "lokasi",
            id: "lokasi-aside",
          },
          {
            title: "Biaya Kirim",
            page: "laporanbiayakirim",
            id: "laporanbiayakirim-aside",
          },
          {
            title: "Pendapatan",
            page: "laporanpendapatan",
            id: "laporanpendapatan-aside",
          },
        ],
      },
      {
        title: "Voucher Verifikasi Informan",
        root: true,
        icon: "fas fa-check-double",
        page: "voucherinforman",
        id: "voucherinforman-aside",
      },
      {
        title: "Voucher Refund",
        root: true,
        icon: "fas fa-check-double",
        page: "voucherrefund",
        id: "voucherrefund-aside",
      },
      {
        title: "Data Voucher Verifikasi",
        root: true,
        icon: "fas fa-check-double",
        page: "voucherverifikasi",
        id: "voucherverifikasi-aside",
      },
      {
        title: "Data Voucher Siap Dibayar",
        root: true,
        icon: "fas fa-file-invoice",
        page: "siapdibayar",
        id: "siapdibayar-aside",
      },
      {
        title: "Data Voucher Paid",
        root: true,
        icon: "fas fa-handshake",
        page: "voucherpaid",
        id: "voucherpaid-aside",
      },
      {
        title: "Data Voucher Direject",
        root: true,
        icon: "fas fa-times-circle",
        page: "voucherreject",
        id: "voucherreject-aside",
      },
      {
        title: "Version",
        root: true,
        icon: "fas fa-code-branch",
        page: "version",
        id: "version-aside",
      },
      {
        title: "Onboard",
        root: true,
        icon: "fas fa-mobile-alt",
        page: "onboard",
        id: "onboard-aside",
      },
      {
        title: "Komisi & Bonus",
        root: true,
        icon: "fas fa-cogs",
        page: "komisi",
        id: "komisi-aside",
      },
    ],
  },
};
