import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'jquery-mask-plugin';
import $ from 'jquery';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
 });
 
 const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
 }));

export default function DialogRange(props){

    React.useEffect(() => {
        $( '#start_range,#end_range,#potongan_minimal,#upline1,#upline2,#komisi_informan,#potongan_stnk' ).mask('#.##0', {reverse: true});
        $( '#potongan_percent' ).mask('###0.00%', {reverse: true});
     })
    const classes = useStyles();


    return (
        <div>
            <Dialog
            open={props.open}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="sm"
            keepMounted
            onClose={props.close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            style={{zIndex:'999999999'}}
            >
            <DialogTitle className="dptitle" id="alert-dialog-slide-title">{props.title}</DialogTitle>
            <DialogContent id="rangedialog">
                <form className={classes.root} id="formRange" autoComplete="off">
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nama Range</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="name_range" disabled />
                        </div>
                    </div>
                    <div id="div_start_range" className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Start Range</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="start_range" />
                        </div>
                    </div>
                    <div id="div_end_range" className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">End Range</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="end_range" />
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Potongan (%)</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="potongan_percent" />
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Potongan Minimal</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="potongan_minimal" />
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Upline 1</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="upline1" />
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Upline 2</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="upline2" />
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Komisi Informan</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="komisi_informan" />
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Potongan STNK</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="potongan_stnk" />
                        </div>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="primary">
                Batal
                </Button>
                <Button onClick={props.fun} color="primary">
                {props.btn}
                </Button>
            </DialogActions>
            </Dialog>
        </div>
    )
}