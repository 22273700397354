import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import Penemuan from "./Penemuan";
import User from "./User";
import Koordinator from "./Koordinator/KoordinatorPage";
import TambahKoordinator from "./Koordinator/TambahKoordinator";
import TambahUser from "./User/TambahUser";
import EditUser from "./User/EditUser";
import Leasing from "./Leasing";
import TambahLeasing from "./Leasing/TambahLeasing";
import UploadDataLeasing from "./Leasing/UploadDataSemuaLeasing";
import ReasonBatal from "./ReasonBatal";
import ReasonNego from "./ReasonNego";
import DataTerupload from "./DataTerupload";
import HasilTerupload from "./HasilTerupload";
import KendaraanAktif from "./KendaraanAktif";
import TambahDataNPL from "./TambahDataNPL";
import LaporanPenemuan from "./LaporanPenemuan";
import LaporanInvoice from "./LaporanInvoice";
import LaporanPembatalan from "./LaporanPembatalan";
import LaporanJumlahPenemuan from "./LaporanJumlahPenemuan";
import LaporanJumlahPendaftaran from "./LaporanJumlahPendaftaran";
import LaporanBiayaKirim from "./LaporanBiayaKirim/LaporanBiayaKirim";
import LaporanPendapatan from "./LaporanPendapatan";
import Lokasi from "./Lokasi";
import VoucherInforman from "./VoucherInforman";
import VoucherVerifikasi from "./VoucherVerifikasi";
import VoucherSiapDibayar from "./VoucherSiapDibayar";
import VoucherPaid from "./VoucherPaid";
import VoucherDireject from "./VoucherDireject";
import UploadData from "./UploadData";
import Version from "./Version";
import Onboard from "./Onboard";
import VoucherRefund from "./VoucherRefund";
import KomisiBonus from "./Komisi&Bonus";
import HasilDataApprover from "./HasilDataApprover";
import HasilDataLeasing from "./HasilDataLeasing";
import HistoryDataUpload from "./HistoryDataUpload";
import SettingAkun from "./Akun/SettingAkun";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import Settings from "./Settings";
import RoleAkses from "./RoleAkses";

const GoogleMaterialPage = lazy(() => import("./google-material/GoogleMaterialPage"));
const ReactBootstrapPage = lazy(() => import("./react-bootstrap/ReactBootstrapPage"));

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/penemuan" component={Penemuan} />
        <Route path="/user" component={User} />
        <Route path="/koordinator" component={Koordinator} />
        <Route path="/tambahkoordinator" component={TambahKoordinator} />
        <Route path="/tambahuser" component={TambahUser} />
        <Route path="/edituser" component={EditUser} />
        <Route path="/leasing" component={Leasing} />
        <Route path="/tambahleasing" component={TambahLeasing} />
        <Route path="/upload-data-leasing" component={UploadDataLeasing} />
        <Route path="/reasonbatal" component={ReasonBatal} />
        <Route path="/reasonnego" component={ReasonNego} />
        <Route path="/terupload" component={DataTerupload} />
        <Route path="/hasildata" component={HasilTerupload} />
        <Route path="/kendaraan" component={KendaraanAktif} />
        <Route path="/tambahdatanpl" component={TambahDataNPL} />
        <Route path="/laporanpenemuan" component={LaporanPenemuan} />
        <Route path="/laporaninvoice" component={LaporanInvoice} />
        <Route path="/laporanpembatalan" component={LaporanPembatalan} />
        <Route path="/laporanmonthpenemuan" component={LaporanJumlahPenemuan} />
        <Route path="/laporanmitraregister" component={LaporanJumlahPendaftaran} />
        <Route path="/laporanbiayakirim" component={LaporanBiayaKirim} />
        <Route path="/laporanpendapatan" component={LaporanPendapatan} />
        <Route path="/lokasi" component={Lokasi} />
        <Route path="/voucherinforman" component={VoucherInforman} />
        <Route path="/voucherverifikasi" component={VoucherVerifikasi} />
        <Route path="/siapdibayar" component={VoucherSiapDibayar} />
        <Route path="/voucherpaid" component={VoucherPaid} />
        <Route path="/voucherreject" component={VoucherDireject} />
        <Route path="/uploaddata" component={UploadData} />
        <Route path="/version" component={Version} />
        <Route path="/onboard" component={Onboard} />
        <Route path="/komisi" component={KomisiBonus} />
        <Route path="/historydataupload" component={HistoryDataUpload} />
        <Route path="/dataapprover" component={HasilDataApprover} />
        <Route path="/leasinguploaded" component={HasilDataLeasing} />
        <Route path="/setting" component={SettingAkun} />
        <Route path="/voucherrefund" component={VoucherRefund} />
        <Route path="/settingconfig" component={Settings} />
        <Route path="/roleakses" component={RoleAkses} />

        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
