/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
// import Utils from './FunctionDetail'
import $ from 'jquery'

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const formatter = new Intl.NumberFormat('de-DE');

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset : theme.mixins.toolbar,
    marginTop : '70px'
  } 
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailInforman(props) {
  const classes = useStyles();
  const [carprice, setCarprice] = React.useState(null)
  const [bikeprice, setBikeprice] = React.useState(null)

  React.useEffect(() => {
    if (props.id != null && props.step != null) { 

      if (props.step == 1) {
        $('#data_mitra').hide()
        $('#foto_validasi').hide()
        $('#tgl_jam_matched').hide()
      }else if (props.step == 2){
        $('#data_mitra').hide()
        $('#foto_validasi').show()
        $('#tgl_jam_matched').hide()
      }


      var komisi = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi + "v1/InformanConfig/list",
        "method": "GET",
        "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
      }

      $.ajax(komisi).done(function (response) {
        console.log(response.data[0])
        setCarprice(response.data[0].tcif_informan_car_fee_nominal)
        setBikeprice(response.data[0].tcif_informan_bike_fee_nominal)
      })

      if (props.step == -2 || props.step == -1) {
        var Founded = {
          "async": true,
          "crossDomain": true,
          "url": API.Foundingapi +"v1/penemuan/getPenemuanMaster/"+props.id+"/"+props.step,
          "method": "GET",
          "headers": {
              "Authorization": "Baisc "+localStorage.getItem('jwt')
            }
        }
        
        $.ajax(Founded).done(function (response) {
            console.log(response)
            if( response.data != null ){
              $('#tpif_vplate').val(response.data.tpnm_vplate)
              $('#tpif_vin').val(response.data.tpnm_vin)
              $('#tpif_ein').val(response.data.tpnm_ein)
              $('#tpif_vtype').val(`(${response.data.tpnm_vtype.toUpperCase()}) ${response.data.tpnm_vbrand} ${response.data.tpnm_type}`)
              $('#tpif_vdoc_name').val(response.data.tpnm_vdoc_name)
              $('#tpif_contract').val(response.data.tpnm_contract)
              $('#tpif_branch').val(response.data.tpnm_branch)
              $('#tpif_customer_name').val(response.data.tpnm_customer_name)
              $('#tpif_address').val(response.data.tpnm_address)
              $('#tpif_ic_number').val(response.data.tpnm_ic_number)
              $('#tpif_kk_number').val(response.data.tpnm_kk_number)
              $('#tpif_debt_date').val(response.data.tpnm_debt_date)
              $('#tpif_installment').val(response.data.tpnm_installment)
              $('#tpif_invoice_qty').val(response.data.tpnm_invoice_qty)
              $('#tpif_duration').val(response.data.tpnm_duration)
              $('#tpif_monthly_charge').val(formatter.format(response.data.tpnm_monthly_charge))
              $('#tpif_total_unpaid').val(formatter.format(response.data.tpnm_total_unpaid))
              $('#mper_name_informan').val(response.data.mper_name_informan)
              $('#mper_phone_informan').val(response.data.mper_phone_informan)
              $('#tpif_lat-long_informan').val(response.data.tpif_lat_informan+" , "+response.data.tpif_lng_informan)
              $('#tpif_address_informan').val(response.data.tpif_address_informan)
              $('#mper_name').val(response.data.mper_name)
              $('#mper_phone').val(response.data.mper_phone)

              if(response.data.tpnm_vtype == 'motor'){
                $('#tpif_fee').val(formatter.format(bikeprice))
              }else if(response.data.tpnm_vtype == 'mobil'){
                $('#tpif_fee').val(formatter.format(carprice))
              }
              // $('#tpif_fee').val(formatter.format(response.data.tpif_fee))
              if( response.data.tpif_photo_validation != null ){
                $('#tpif_photo_validation').attr("href", response.data.tpif_photo_validation);
              }else{
                $('#tpif_photo_validation').attr("disabled", "disabled");
              }
              $('#tpif_matchmaking_datetime').val(response.data.tanggal_matchmaking+' , '+response.data.jam_matchmaking)
              $('#tpif_created_timedate').val(response.data.tpnm_created_date+' , '+response.data.tpnm_created_time)
              $("#mmle_name").val(response.data.mmle_name)
            }
          })
      }

      if (props.step == 1 || props.step == 2) {
        var settings = {
          "async": true,
          "crossDomain": true,
          "url": API.Foundingapi +"v1/penemuan/detail_penemuan_informan/"+props.id+"/"+props.step,
          "method": "GET",
          "headers": {
            "Authorization": "Baisc " + localStorage.getItem('jwt')
          }
        }

          
        $.ajax(settings).done(function (response) {
          console.log(response);
          if (response.data != null) {
            $('#tpif_vplate').val(response.data.tpif_vplate)
            $('#tpif_vin').val(response.data.tpif_vin)
            $('#tpif_ein').val(response.data.tpif_ein)
            // $('#tpif_vtype').val(response.data.tpif_vtype)
            $('#tpif_vdoc_name').val(response.data.tpif_vdoc_name)
            $('#tpif_contract').val(response.data.tpif_contract)
            $('#tpif_branch').val(response.data.tpif_branch)
            $('#tpif_customer_name').val(response.data.tpif_customer_name)
            $('#tpif_address').val(response.data.tpif_address)
            $('#tpif_ic_number').val(response.data.tpif_ic_number)
            $('#tpif_kk_number').val(response.data.tpif_kk_number)
            $('#tpif_debt_date').val(response.data.tpif_debt_date)
            $('#tpif_installment').val(response.data.tpif_installment)
            $('#tpif_invoice_qty').val(response.data.tpif_invoice_qty)
            $('#tpif_duration').val(response.data.tpif_duration)
            $('#tpif_monthly_charge').val(formatter.format(response.data.tpif_monthly_charge))
            $('#tpif_total_unpaid').val(formatter.format(response.data.tpif_total_unpaid))
            $('#mper_name_informan').val(response.data.mper_name_informan)
            $('#mper_phone_informan').val(response.data.mper_phone_informan)
            $('#tpif_lat-long_informan').val(response.data.tpif_lat_informan+" , "+response.data.tpif_lng_informan)
            $('#tpif_address_informan').val(response.data.tpif_address_informan)
            $('#mper_name').val(response.data.mper_name)
            $('#mper_phone').val(response.data.mper_phone)
            if(response.data.tpif_vtype == 'motor'){
              $('#tpif_fee').val(formatter.format(bikeprice))
            }else if(response.data.tpif_vtype == 'mobil'){
              $('#tpif_fee').val(formatter.format(carprice))
            }
            // $('#tpif_fee').val(formatter.format(response.data.tpif_fee))
            if( response.data.tpif_photo_validation != null ){
              $('#tpif_photo_validation').attr("href", response.data.tpif_photo_validation);
            }else{
              $('#tpif_photo_validation').addClass("disabled");
            }
            $('#tpif_matchmaking_datetime').val(response.data.tpif_matchmaking_date+' , '+response.data.tpif_matchmaking_time)
            $('#tpif_created_timedate').val(response.data.tpif_created_date+' , '+response.data.tpif_created_time)
            $("#mmle_name").val(response.data.mmle_name)
          }
        })
      }
    }

  })

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>                       
            <Typography variant="h6" className={classes.title}>
                Detail Penemuan Informan
            </Typography>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TabPanel value={0} index={0} className={classes.detail} id="detailpenemuaninforman">
          <Portlet>
            <PortletHeader title="Data Kendaraan"/>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Polisi</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id='tpif_vplate' disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Rangka</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="tpif_vin" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Mesin</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="tpif_ein" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Type Motor/Mobil</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="tpif_vtype" disabled/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nama STNK/BPKB</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_vdoc_name" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Kontrak</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_contract" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Asal Cabang</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_branch" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Leasing</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="mmle_name" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
          <Portlet>
            <PortletHeader title="Profile Debitur"></PortletHeader>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Debitur</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="tpif_customer_name" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Konsumen</label>
                    <div class="col-sm-7">
                      {/* <input type="text" class="form-control" id="tpif_address" disabled/> */}
                      <textarea className="form-control" id="tpif_address" rows="3" disabled></textarea>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor KTP</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="tpif_ic_number" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor KK</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="tpif_kk_number" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal Mulai Tunggak</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="tpif_debt_date" disabled/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Angsuran Ke</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_installment" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Jumlah Kwitansi</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_invoice_qty" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Tenor</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_duration" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Angsuran Perbulan</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_monthly_charge" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Total Tunggakkan</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_total_unpaid" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
          <Portlet>
            <PortletHeader title="Data Informan"></PortletHeader>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Informan</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="mper_name_informan" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Informan</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="mper_phone_informan" disabled/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Lat-Long Informan</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_lat-long_informan" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Posisi Informan</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_address_informan" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
          <Portlet id="data_mitra">
            <PortletHeader title="Data Mitra"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Mitra</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="mper_name" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Mitra</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="mper_phone" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
          </Portlet>
          <Portlet>
            <PortletHeader title="Order Detail"></PortletHeader>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Fee Informan</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="tpif_fee" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" id="foto_validasi" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Foto Validasi Informan</label>
                    <div class="col-sm-7">
                      <a type="button" className="btn btn-outline-brand btn-icon" id="tpif_photo_validation" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                    </div>
                  </div>
                </Col>
                <Col md={6}>                  
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Validasi Informan</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_created_timedate" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" id="tgl_jam_matched" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Matched</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="tpif_matchmaking_datetime" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md>
                  <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
        </TabPanel>
      </Dialog>
    </div>
  );
}
