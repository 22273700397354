/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import "jquery-mask-plugin";
import $ from "jquery";
import API from "../../API";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "80%",
  },
}));

const getMaskingValue = (id) => {
  return $(id)
    .val()
    .replace(/\D/g, "");
};

export default function DialogPricing(props) {
  const formatter = new Intl.NumberFormat("de-DE");
  const [rangePricings, setRangePricings] = useState([]);
  const [listPotongan, setListPotongan] = useState([{ nominal_potongan: 0 }]);

  const getRangePricing = (basePrice) => {
    if (rangePricings.length > 0) {
      var pricing = rangePricings.filter(function(el) {
        return (
          parseInt(el.start_range_price) <= basePrice &&
          parseInt(el.end_range_price) >= basePrice &&
          el.mmle_id == props.mmle_id
        );
      });
      console.log("pricing 1", pricing);
      if (pricing.length == 0)
        pricing = rangePricings.filter(function(el) {
          return (
            parseInt(el.start_range_price) <= basePrice &&
            parseInt(el.end_range_price) >= basePrice &&
            el.mmle_id == null
          );
        });
      console.log("pricing 2", pricing);
      if (pricing.length > 0)
        return {
          potonganMinimum: pricing[0].potongan_minimal,
          potonganPersen: pricing[0].potongan_percent,
          potonganSTNK: pricing[0].potongan_stnk !== "" ? pricing[0].potongan_stnk : 0,
        };
      else {
        return {
          potonganMinimum: 100000,
          potonganPersen: 10,
          potonganSTNK: 50000,
        };
      }
    }

    var potonganPersen = props.potongan_persen !== null ? parseInt(props.potongan_persen) : 10;
    var potonganMinimum =
      props.potongan_minimum !== null ? parseInt(props.potongan_minimum) : 100000;
    var potonganSTNK = props.potongan_stnk !== null ? parseInt(props.potongan_stnk) : 50000;

    return {
      potonganMinimum: potonganMinimum,
      potonganPersen: potonganPersen,
      potonganSTNK: potonganSTNK,
    };
  };

  const calculatePricing = () => {
    var basePrice = getMaskingValue("#price_tpnm_base_price");
    var pendapatanLainLain = getMaskingValue("#pendapatan-lain-lain");
    var basePriceSTNK = getMaskingValue("#price_tpnm_bp_stnk");
    var cadanganKorwil =
      $("#cadangan_korwil").val() == null ? 0 : getMaskingValue("#cadangan_korwil");
    var biayaKirim = $("#price_tpnm_biaya_kirim_estimasi").val();

    // var potonganPersen = props.potongan_persen !== null ? parseInt(props.potongan_persen) : 10;
    // var potonganMinimum =
    //   props.potongan_minimum !== null ? parseInt(props.potongan_minimum) : 100000;
    // var potonganSTNK = props.potongan_stnk !== null ? parseInt(props.potongan_stnk) : 50000;
    // console.log(props);

    var pricing = getRangePricing(basePrice);
    var potonganTambahanPersen = parseFloat($("#potongan_tambahan_persen").val());

    var potonganUnit = (basePrice * pricing.potonganPersen) / 100;

    if (potonganUnit < pricing.potonganMinimum) potonganUnit = pricing.potonganMinimum;
    potonganUnit = potonganUnit + (basePrice * potonganTambahanPersen) / 100;

    var feeSTNK = basePriceSTNK - pricing.potonganSTNK;
    if (feeSTNK < 0) feeSTNK = 0;

    var feeUnit = basePrice - potonganUnit - cadanganKorwil - biayaKirim - pendapatanLainLain;
    if (feeUnit < 0) feeUnit = 0;

    $("#potongan_unit").val(formatter.format(parseInt(potonganUnit)));
    $("#potongan_stnk").val(formatter.format(parseInt(pricing.potonganSTNK)));
    // $("#cadangan_korwil").val(formatter.format(parseInt(cadanganKorwil)));
    // $("#price_tpnm_biaya_kirim_estimasi").val(formatter.format(parseInt(biayaKirim)));

    $("#price_tpnm_fee_mitra").val(formatter.format(parseInt(feeUnit)));
    $("#price_tpnm_fee_stnk").val(formatter.format(parseInt(feeSTNK)));
  };

  React.useEffect(() => {
    $("#price_tpnm_base_price, #price_tpnm_bp_stnk, #pendapatan-lain-lain").keyup(function() {
      calculatePricing();
    });

    $("#price_tpnm_biaya_kirim_estimasi, #cadangan_korwil, #potongan_tambahan_persen").change(
      function() {
        calculatePricing();
      }
    );

    $(".mask-currency").mask("#.##0", { reverse: true });
    if (props.idTvoc !== null) calculatePricing();
  });

  useEffect(() => {
    setListPotongan([{ nominal_potongan: 0 }]);
    setRangePricings([]);
    if (props.koordinator_mper_id !== null) {
      $.ajax({
        async: true,
        crossDomain: true,
        url: API.Userapi + "v1/Koordinator/range_pricing_koordinator/" + props.koordinator_mper_id,
        method: "GET",
        headers: {
          Authorization: "Basic " + localStorage.getItem("jwt"),
        },
      }).done(function(response) {
        if (response.data != null) {
          setRangePricings(response.data);
          console.log(rangePricings);
        }
      });

      $.ajax({
        async: true,
        crossDomain: true,
        url: API.Userapi + "v1/Koordinator/koordinator_potongan/" + props.koordinator_mper_id,
        method: "GET",
        headers: {
          Authorization: "Basic " + localStorage.getItem("jwt"),
        },
      }).done(function(response) {
        if (response.data != null) {
          setListPotongan([{ nominal_potongan: 0 }].concat(response.data));
          console.log(listPotongan);
        }
      });
    }
  }, [props.koordinator_mper_id]);

  const classes = useStyles();

  useEffect(() => {
    $("#transit_pool").select2({
      minimumInputLength: 0,
      placeholder: "Pilih Transit Pool",
      dropdownParent: $("#pricedialog"),
      allowClear: true,
      ajax: {
        type: "get",
        url: API.Userapi + "v1/koordinator/selectTransitPool",
        headers: {
          Authorization: "Basic " + localStorage.getItem("jwt"),
        },
        dataType: "json",
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, props.setAlerts);
        },
        data: function(params) {
          return {
            select2: params.term,
            length: 10,
          };
        },
        processResults: function(response, params) {
          return {
            results: $.map(response.data, function(item) {
              return {
                text: item.transit_pool,
                id: item.transit_pool,
              };
            }),
          };
        },
      },
    });
  });

  function Submit() {
    if ($('[type="submit"]').valid() == true) {
      $('[type="submit"]').prop("disabled", true);
      UpdatePricing();
    }
  }

  //update pricing dan biaya kirim
  const UpdatePricing = () => {
    var url = `${API.Financeapi}/v1/Voucher_verifikasi/updatePricingTvoc/${props.idTpnm}/${props.idTvoc}`;
    var data = {
      tpnm_base_price: getMaskingValue("#price_tpnm_base_price"),
      tprc_bp_stnk: getMaskingValue("#price_tpnm_bp_stnk"),
      tvoc_ammount: getMaskingValue("#price_tpnm_fee_mitra"),
      tvoc_fee_stnk: getMaskingValue("#price_tpnm_fee_stnk"),
      pendapatan_lain_lain: getMaskingValue("#pendapatan-lain-lain"),
      cadangan_korwil: $("#cadangan_korwil").val(),
      // 'tvoc_ammount_revisi' : $('#price_rev_fee_mitra').val().replace(/\D/g, ''),
      // 'tvoc_fee_stnk_revisi' : $('#price_rev_fee_stnk').val().replace(/\D/g, ''),
      tvoc_biaya_kirim_estimasi: $("#price_tpnm_biaya_kirim_estimasi").val(),
      transit_pool: $("#transit_pool").val(),
      pic_tagih: $("#pic_tagih").val(),
      tvoc_status_kirim: $("#status-kirim").is(":checked") ? "Siap Tagih" : "Intransit",
      // tvoc_biaya_kirim_real: getMaskingValue("#price_tpnm_biaya_kirim_real"),
      // tvoc_biaya_kirim_subleasing: getMaskingValue("#price_tpnm_biaya_kirim_subleasing"),
      // tvoc_biaya_kirim_submitra: getMaskingValue("#price_tpnm_biaya_kirim_submitra"),
    };
    $.ajax({
      async: true,
      crossDomain: true,
      url: url,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      data: JSON.stringify(data),
    })
      .done(function(response) {
        props.close();
        props.setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Pricing Telah Diubah",
          },
        ]);
        setTimeout(() => {
          props.setAlerts([]);
        }, 3000);
      })
      .fail(function(response) {
        props.setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          props.setAlerts([]);
        }, 3000);
        API.Handle403(response, props.setAlerts);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="sm"
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: "999999999" }}
      >
        <DialogTitle className="dptitle" id="alert-dialog-slide-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <form
            id="pricedialog"
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={classes.root}>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">
                  Fee Unit Leasing <span style={{ color: "red" }}>(Base Price)</span>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control mask-currency"
                    id="price_tpnm_base_price"
                  />
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">
                  Fee STNK Leasing <span style={{ color: "red" }}>(BP stnk)</span>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control mask-currency"
                    id="price_tpnm_bp_stnk"
                  />
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">Potongan Tambahan</label>
                <div className="col-sm-7">
                  <select className="form-control" id="potongan_tambahan_persen">
                    <option value="0">0 %</option>
                    <option value="2">2 %</option>
                    <option value="5">5 %</option>
                  </select>
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">Potongan Unit</label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control mask-currency"
                    id="potongan_unit"
                    disabled
                  />
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">Potongan STNK</label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control mask-currency"
                    id="potongan_stnk"
                    disabled
                  />
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">Cadangan Korwil</label>
                <div className="col-sm-7">
                  {/* <input
                    type="text"
                    className="form-control mask-currency"
                    id="cadangan_korwil"
                    disabled
                  /> */}
                  <select className="form-control" id="cadangan_korwil">
                    {listPotongan.map(function(object, i) {
                      var selected =
                        object.nominal_potongan == props.cadangan_korwil ? "selected" : "";
                      return (
                        <option value={object.nominal_potongan} selected={selected}>
                          {formatter.format(parseInt(object.nominal_potongan))}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">Biaya Kirim</label>
                <div className="col-sm-7">
                  {/* <input
                    type="text"
                    className="form-control mask-currency"
                    id="price_tpnm_biaya_kirim_estimasi"
                    disabled
                  /> */}
                  <select className="form-control" id="price_tpnm_biaya_kirim_estimasi">
                    <option value="0">0</option>
                    <option value="50000">50.000</option>
                    <option value="75000">75.000</option>
                    <option value="100000">100.000</option>
                  </select>
                </div>
              </div>
              <div
                className="form-group row"
                style={{ marginBottom: "1rem" }}
                id="intransit-wrapper"
              >
                <label className="col-sm-5 col-form-label">Intransit</label>
                <div className="col-sm-7">
                  <select
                    className="form-control"
                    id="transit_pool"
                    style={{ width: "100%" }}
                    required
                  >
                    <option value="">Pilih Transit Pool</option>
                  </select>
                </div>
              </div>
              <div
                className="form-group row"
                style={{ marginBottom: "1rem" }}
                id="intransit-wrapper"
              >
                <label className="col-sm-5 col-form-label"></label>
                <div className="col-sm-7">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="status-kirim"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  />
                  <label class="form-check-label" for="status-kirim" style={{ marginLeft: "30px" }}>
                    Siap Tagih
                  </label>
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">Pendapatan Lain - Lain</label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control mask-currency"
                    id="pendapatan-lain-lain"
                  />
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">
                  Fee Mitra <span style={{ color: "red" }}>(Final)</span>
                </label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="price_tpnm_fee_mitra" disabled />
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: "1rem" }}>
                <label className="col-sm-5 col-form-label">
                  Fee STNK Mitra <span style={{ color: "red" }}>(Final)</span>
                </label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="price_tpnm_fee_stnk" disabled />
                </div>
              </div>

              <div className="form-group row" style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <label className="col-sm-5 col-form-label">PIC Tagih</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="pic_tagih" />
                </div>
              </div>

              {/* <div className="form-group row" style={{ marginBottom: '1rem' }}>
                <label className="col-sm-5 col-form-label">Biaya Kirim Subsidi Leasing</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control subsidi-leasing" id="price_tpnm_biaya_kirim_subleasing" />
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: '1rem' }}>
                <label className="col-sm-5 col-form-label">Biaya Kirim Estimasi</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control biaya-estimasi" id="price_tpnm_biaya_kirim_estimasi" />
                </div>
              </div>
              <div className="form-group row" style={{ marginBottom: '1rem' }}>
                <label className="col-sm-5 col-form-label">Biaya Kirim Real</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control biaya-real" id="price_tpnm_biaya_kirim_real" />
                </div>
              </div>

              <div className="form-group row" style={{ marginBottom: '1rem' }}>
                <label className="col-sm-5 col-form-label">Biaya Kirim Subsidi Mitra</label>
                <div className="col-sm-7">
                  <input type="text" className="form-control" id="price_tpnm_biaya_kirim_submitra" disabled />
                </div>
              </div> */}
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-elevate pull-right"
              onClick={Submit}
            >
              <i className="fas fa-save"></i>Simpan
            </button>
            <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}>
              <i className="fas fa-arrow-left"></i>Batal
            </button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
