import React, { useEffect, useState } from "react";
import { Portlet, PortletHeader, PortletBody } from "../../../partials/content/Portlet";
import { AlertList } from "react-bs-notifier";
import API from "../../API";
import $ from "jquery";
import "datatables.net-bs4";
import EditLaporanBiayaKirim from "./EditLaporanBiayaKirim";
import { set } from "lodash";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

const formatter = new Intl.NumberFormat("currency");

const LaporanBiayaKirim = () => {
  const [alerts, setAlerts] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [biayaKirimData, setBiayaKirimData] = useState(null);
  var dttable;

  const initPage = () => {
    $(document).ready(function() {
      FilterLaporanPenemuan();
      $(function() {
        $("#vldto,#vldfrom").datepicker({
          todayHighlight: true,
          autoclose: true,
          format: "yyyy-mm-dd",
        });
      });
      $("#vldfrom,#vldto").val(moment().format("YYYY-MM-DD"));
    });
  };

  const TableJumlahPenemuan = (from, to) => {
    dttable = $("#table-laporan-biaya-kirim").DataTable({
      processing: true,
      serverSide: true,
      destroy: true,
      dom:
        "<'row'<'col-sm-12 col-md-5'l><'col-sm-12 col-md-7'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      lengthMenu: [
        [5, 10, 15, 20, -1],
        [5, 10, 15, 20, "All"],
      ],
      buttons: [
        {
          extend: "excelHtml5",
          className: "btn btn-primary mt-4",
          text: '<i class="fas fa-file-excel"></i>Export Excel',
          titleAttr: "Export Excel",
          customize: function(xlsx, row) {
            var sheet = xlsx.xl.worksheets["sheet1.xml"];
            $('row c[r^="L"], row c[r^="Q"]', sheet).attr("s", 63);
          },
        },
      ],
      ajax: {
        url: API.Financeapi + `v1/BiayaKirim/index?from=${from}&to=${to}&length=full`,

        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      columns: [
        { data: "lvoc_created_date" },
        { data: "mper_name" },
        { data: "mmle_name" },
        { data: "tpnm_vplate" },
        {
          data: "tvoc_status_stnk",
          render: function(data, row, type) {
            return parseInt(data) === 1 ? "Ada" : "Tidak Ada";
          },
        },
        { data: "transit_pool" },
        {
          data: "tvoc_biaya_kirim_estimasi",
          render: function(data, row, type) {
            data = data === null ? 0 : data;
            return formatter.format(parseInt(data));
          },
        },
        {
          data: "tvoc_biaya_kirim_real",
          render: function(data, row, type) {
            data = data === null ? 0 : data;
            return formatter.format(parseInt(data));
          },
        },
        {
          render: function(data, type, row) {
            var selisih_biaya_kirim = row.tvoc_biaya_kirim_estimasi - row.tvoc_biaya_kirim_real;
            return formatter.format(parseInt(selisih_biaya_kirim));
          },
        },
        { data: "tpnm_delivery_dpname" },
        { data: "tvoc_tanggal_kirim" },
        { data: "tvoc_status_kirim" },
        {
          render: function(data, type, row) {
            var btn = `
          <button id="laporan-biayakirim" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="laporan-biayakirim">
                  <button class='dropdown-item edit' data-id="${row.tvoc_id}">
                      <i class='fa fa-edit'></i> Edit
                  </button>
               </div>
               `;
            return btn;
          },
        },
      ],
    });

    $(".dataTables_filter,.dataTables_paginate")
      .addClass("pull-right")
      .css("float", "right");

    $("#table-laporan-biaya-kirim").on("click", ".edit", function() {
      // alert($(this).data("id"));
      var data = dttable.row($(this).parents("tr")).data();
      setBiayaKirimData(data);
      setOpenEdit(true);
    });
  };

  function FilterLaporanPenemuan() {
    if ($("#vldfrom").val() == 0 || $("#vldto").val() == 0) {
      return 0;
    }

    var from = moment($("#vldfrom").val()).format("MM-DD-YYYY");
    var to = moment($("#vldto").val()).format("MM-DD-YYYY");

    TableJumlahPenemuan(from, to);

    $(".dataTables_filter,.dataTables_paginate")
      .addClass("pull-right")
      .css("float", "right");
  }

  useEffect(() => {
    initPage();
  }, []);

  return (
    <>
      <AlertList showIcon={false} alerts={alerts} />
      <EditLaporanBiayaKirim
        data={biayaKirimData}
        open={openEdit}
        close={() => {
          setOpenEdit(false);
          setBiayaKirimData(null);
        }}
        updateDatatable={initPage}
        setAlerts={setAlerts}
      />
      <Portlet>
        <PortletHeader title="Filter Tanggal"></PortletHeader>
        <PortletBody>
          <Grid container spacing={2}>
            <Grid item xs></Grid>
            <Grid item xs={6} className="text-center">
              <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                <input
                  type="text"
                  className="form-control"
                  name="start"
                  id="vldfrom"
                  autocomplete="off"
                />
                <div className="input-group-append">
                  <span className="input-group-text">To</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="end"
                  id="vldto"
                  autocomplete="off"
                />
              </div>
              <button
                type="button"
                class="btn btn-brand btn-elevate btn-sm"
                onClick={FilterLaporanPenemuan}
                id="cari"
                style={{ width: "100%" }}
              >
                Set Filter
              </button>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </PortletBody>
      </Portlet>
      <Portlet>
        <PortletBody>
          <div style={{ overflow: "auto" }}>
            <table
              className="table table-hover table-responsive-md"
              id="table-laporan-biaya-kirim"
              style={{ width: "100%" }}
            >
              <thead className="bg-primary" style={{ color: "white", fontSize: "12px" }}>
                <tr>
                  <th scope="col">Tanggal</th>
                  <th scope="col">Nama Mitra</th>
                  <th scope="col">Nama Leasing</th>
                  <th scope="col">Nopol</th>
                  <th scope="col">STNK</th>
                  <th scope="col">Drop Point Koordinator</th>
                  <th scope="col">Cadangan Biaya Kirim</th>
                  <th scope="col">Biaya Kirim</th>
                  <th scope="col">Selisih Biaya Kirim</th>
                  <th scope="col">Drop Point Leasing</th>
                  <th scope="col">Tanggal Kirim</th>
                  <th scope="col">Status</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
            </table>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default LaporanBiayaKirim;
