import React from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../../API';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import Table from './TableSetting';
import bsCustomFileInput from 'bs-custom-file-input';
import {AlertList} from "react-bs-notifier";
import DialogUpload from './DialogUpload';
import {ProgressBar, Card} from 'react-bootstrap';
import {Row, Col} from 'react-bootstrap';
import ExcelNamaLokasi from './template_penamaan_lokasi.xlsx';
import ExcelSkema from './template_skema.xlsx';
import DialogSkema from './DialogSkema';

export default function Skema() {
    const [openskm, setOpenSkema] = React.useState(false) //upload excel skema
    const [open1, setOpen1] = React.useState(false) // edit skema
    const [open2, setOpen2] = React.useState(false) // hapus skema
    const [open3, setOpen3] = React.useState(false) //hapus all skema
    const [skemaId, setSkemaId] = React.useState(null)
    const [fileskm, setFileSkema] = React.useState(null)
    const [uploadnameskm, setUploadnameSkema] = React.useState(null)
    const [progresskm, setProgresSkema] = React.useState(0)
    const [alerts, setAlerts] = React.useState([])

    const ToggleUploadSkema = () => {
        $('#uploadformskema').toggle('slow')
    }
    const FileSkema = (e) => {
        setFileSkema(e.target.files[0])
    }

    const handleClickOpenSkema = () => {
        setOpenSkema(true)
    }
    const handleCloseSkema = () => {
        setOpenSkema(false)
    }
    const handleClickOpen1 = () => {
        setOpen1(true)
        $('#skemadialog input').val('')
    }
    const handleClose1 = () => {
        setOpen1(false)
    }
    const handleClickOpen2 = () => {
        setOpen2(true)
    }

    const handleClose2 = () => {
        setOpen2(false)
    }

    const handleClickOpen3 = () => {
        setOpen3(true)
    }
    const handleClose3 = () => {
        setOpen3(false)
    }

    React.useEffect(() => {
        $(document).ready(function() {
            TableSkema()

            $('#skema').on('click', '#editSkema', function() {
                setSkemaId($(this).attr('data-id'))
                handleClickOpen1()

                var editSkema = {
                    "async":true,
                    "crossDomain":true,
                    "url": API.Userapi + "v1/master/getSkema/"+ $(this).attr('data-id'),
                    "method": "GET",
                    "headers": {
                        "Authorization": "Basic "+ localStorage.getItem('jwt')
                    }
                }
                $.ajax(editSkema).done(function (response) {
                    
                    if(response.data !=null ) {
                        $('#skema_province').val(response.data.skema_province)
                        $('#skema_city').val(response.data.skema_city)
                        $('#skema_value').val(response.data.skema_value)
                    }
                }).fail(function (response) {
                    API.Handle403(response,setAlerts)
                    return 0
                })
            })

            $('#skema').on('click', '#hapusSkema', function(){
                setSkemaId($(this).attr('data-id'))
                handleClickOpen2()
            })
    
            bsCustomFileInput.init()
        })
    }, [setAlerts])

    const TableSkema = () => {
        Table.Skema(setAlerts);
        $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
	}

    const UploadSkema = () => {
        handleCloseSkema()
        var formSkema = new FormData()
        formSkema.append('uploaded_fileskm', fileskm)
        setUploadnameSkema(fileskm.name)
        $("#uploadingskema").show('slow')
        $("#form_skema").hide('slow')

        var uploadSkema = {
            xhr: function() {
				var xhr = new window.XMLHttpRequest();
				xhr.upload.addEventListener('progress', function(e) {
					if(e.lengthComputable) {
						console.log(e);
						console.log('Bytes Loaded : ' + e.loaded);
                        console.log('Total Size : ' + e.total);
						console.log('Persen : ' + (e.loaded / e.total));
						
						var percentskm = Math.round((e.loaded / e.total) * 100);
						console.log("Persen : " + percentskm)

						setProgresSkema(percentskm)
					}
				})
				return xhr;
            },
            "async":true,
            "crossDomain":true,
            "url": API.Userapi+"v1/Master/uploadSkema",
            "method": "POST",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem("jwt"),
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": formSkema
        }
        $.ajax(uploadSkema).done(function (response) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Konfigurasi Skema Telah Diupload"
            }])
            setTimeout(() => {
                $('#uploadingskema').hide('slow')
                $('#form_skema').show('slow')
                setAlerts([])
            }, 3000)
            TableSkema()
        }).fail(function (response ) {
            $("#uploadingskema").hide('slow')
            $("#form_skema").show('slow')
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            },3000)
            API.Handle403(response, setAlerts)
        })
    }

    const SubmitSkema = () => {
        
        var skema_province = $('#skema_province').val()
        var skema_city = $('#skema_city').val()
        var skema_value = $('#skema_value').val()

        var dataskema = {
            'skema_province': skema_province,
            'skema_city': skema_city,
            'skema_value': skema_value
        }

        var url = API.Userapi + "v1/Master/putSkema/" + skemaId
        
        var skemas = {
            "async":true,
            "crossDomain": true,
            "url": url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('jwt')
            },
            "processData": false,
            "data": JSON.stringify(dataskema)
        }
        $.ajax(skemas).done(function(response){
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Skema Telah Disimpan"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose1()
            TableSkema()
        }).fail(function (response) {
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    const DeleteSkema = () => {
        
        var delSkema = {
            "async": true,
            "crossDomain":true,
            "url": API.Userapi + "v1/Master/delSkema/"+skemaId,
            "method": "GET",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem('jwt')
            }
        }
        $.ajax(delSkema).done(function( response ) {
            setAlerts([{
                id: 1,
				type: "success",
				headline: "Berhasil",
				message: "Skema Berhasil Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose2()
            TableSkema()
        }).fail(function (response ) {
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
			setTimeout(() => {
					setAlerts([])
			}, 3000)
			API.Handle403(response,setAlerts)
        })
    }

    const DeleteAllSkema = () => {
        var delAllSkema = {
            "async":true,
            "crossDomain":true,
            "url": API.Userapi + "v1/Master/delAllSkema",
            "method": "GET",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem('jwt')
            }
        }
        $.ajax(delAllSkema).done(function (response ) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Total "+response.total+" Skema Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose3()
            TableSkema()
        }).fail(function (response) {
			setAlerts([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]"
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
		})
    }

    return (
        <>
            <Portlet>
                <DialogUpload
                    open={openskm}
                    close={handleCloseSkema}
                    title="Upload Skema"
                    message="Anda Yakin Ingin Upload File Skema Ini ?"
                    btn="Upload"
                    fun={UploadSkema}
                />
                <DialogSkema 
                    open={open1}
                    close={handleClose1}
                    title={skemaId !=null ? "Edit Skema" : "Tambah Skema"}
                    btn={skemaId !=null ? "Edit" : "Tambah"}
                    fun={SubmitSkema}
                />

                <DialogUpload
                    open={open2}
                    close={handleClose2}
                    title="Hapus Data Skema"
                    message="Anda Yakin Ingin Hapus Data Skema Ini ?"
                    btn="Hapus"
                    fun={DeleteSkema}
                />
                <DialogUpload 
					open={open3}
					close={handleClose3}
					title="Hapus Semua Skema"
					message="Anda Yakin Ingin Hapus Semua Data Skema Ini ?"
					btn= "Hapus Semua"
					fun={DeleteAllSkema}
				/>
                <AlertList showIcon={false} alerts={alerts}/>
                <PortletHeader title="Konfigurasi Skema" toolbar={(
                    <PortletHeaderToolbar>
                        <a
                            href={ExcelNamaLokasi}
                            type="button"
                            className="btn btn-label-primary btn-sm mr-1 ml-1"
                        >
                            <i class="fas fa-file-download"></i>Panduan Penamaan Lokasi
                        </a>
                        <a
                            href={ExcelSkema}
                            type="button"
                            className="btn btn-label-primary btn-sm mr-1 ml-1"
                        >
                            <i class="fas fa-file-download"></i>Template Pengisian Data
                        </a>
                        <button
                            type="button"
                            className="btn btn-label-primary btn-sm mr-1 ml-1"
                            onClick={ToggleUploadSkema}
                        >
                            <i class="fas fa-file-upload"></i>Upload Data
                        </button>
                    </PortletHeaderToolbar>
                )} />
                <PortletBody>
                <div class="mb-2" id="uploadformskema" style={{display:'none'}}>
                    <div id="form_skema" className="input-group col-md-12">
                        <div class="input-group-prepend">
                            <button class="btn btn-warning btn-elevate" type="button" onClick={ToggleUploadSkema} >Batal</button>
                        </div>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customfile" onChange={FileSkema} aria-describedby="customfile"/>
                            <label class="custom-file-label" for="customfile">Pilih File</label>
                        </div>
                        <div class="input-group-append">
                            <button class="btn btn-primary btn-elevate" type="button" onClick={handleClickOpenSkema} id="inputGroupFileAddon04">Upload</button>
                        </div>
                    </div>
                    <div id="uploadingskema" className="col-md-12"style={{display:'none'}}>
                        <Card bg="primary" text="white">
                            <Card.Body>
                            <Card.Title>
                                <h3>
                                    Mengupload Data
                                </h3>
                            </Card.Title>
                            <hr style={{borderTop: '1px solid #f4a240'}}/>
                            <Card.Text>
                                <h4>Nama File : <span>{uploadnameskm}</span></h4>
                                <h4>Proses : <span>{progresskm}% {progresskm === 100 ? "(Memproses Di Server)" : ""}</span></h4>
                                <ProgressBar animated  now={progresskm} variant="warning"/>
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <Row>
                    <Col>
                        <button type="button" class="btn btn-danger btn-elevate mb-2" onClick={handleClickOpen3} id="delete_all"><i class='fa fa-trash'></i> Hapus Semua</button>
                    </Col>
                </Row>
                <table className="table table-hover table-responsive-md" id="skema" style={{width:'100%',overflow:'auto'}}>
                        <thead className="bg-primary" style={{color:'white'}}>
                            <tr> 
                                <th class="all"> Provinsi </th>
                                <th class="all"> Kabupaten</th>
                                <th class="all"> Skema</th>
                                <th class="all"> Keterangan</th>
                                <th class="all"> Aksi</th>
                            </tr>
                        </thead>
                </table>
                </PortletBody>
            </Portlet>
        </>
    )
}