/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import {Row, Col} from 'react-bootstrap'
import DialogHapusBatal from './DialogHapusBatal'
import {
   Portlet,
   PortletBody
   } from "../../../partials/content/Portlet"
import { AlertList} from "react-bs-notifier"
import Box from '@material-ui/core/Box'
import API from '../../API'
import $ from 'jquery'

function TabPanel(props) {
   const { children, value, index, ...other } = props;
 
   return (
     <Typography
       component="div"
       role="tabpanel"
       hidden={value !== index}
       id={`scrollable-auto-tabpanel-${index}`}
       aria-labelledby={`scrollable-auto-tab-${index}`}
       {...other}
     >
       {value === index && <Box p={3}>{children}</Box>}
     </Typography>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
}

const useStyles = makeStyles(theme => ({
   appBar: {
     position: 'fixed',
   },
   title: {
     marginLeft: theme.spacing(2),
     flex: 1,
   },
   detail: {
     offset : theme.mixins.toolbar,
     marginTop : '70px'
   } 
}))

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

export default function DetailRoleAkses(props) {
   const classes = useStyles()
   const [alerts, setAlerts ] = React.useState([])
   
   React.useEffect(() => {
      $(document).ready(function() {
         TableMenu()
         $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')

         $('#tabelmenu').on('click', '#changeAkses', function() {
            console.log($(this).attr('data-id'))
            console.log($(this).attr('data-role'))

         })        
        
      })
   })

   const TableMenu = () => {
      $('#tabelmenu').DataTable({
         "pagingType": "full_numbers",
         "processing": true,
         "serverSide": true,
         "destroy": true,
         "ajax": {
             "url": API.Userapi + "/v1/master/getMenu",
             "type": 'get',
             "beforeSend": function (xhr) {
                 xhr.setRequestHeader('Authorization',
                     "Basic " + localStorage.getItem('jwt'));
             },
             error: function (xhr, error, thrown, settings) {
                 API.Handle403(xhr,setAlerts)
             }
         },
         "columns": [
            {"data":"title"},
            {
               sortable: true,
               "render": function (data, type, full, meta) {
                  return "<div id='aksi'>\
                         <input type='checkbox' id='changeAkses' data-id="+full.menu_id+" data-role="+props.id+">\
                           </div>";
               }
            }
         ]
      })
   }

   return (
      <>
         <DialogHapusBatal
               
         />
         <AlertList showIcon={false} alerts={alerts}/>
         <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
               <Toolbar>                       
                  <Typography variant="h6" className={classes.title}>
                     Beri Hak Akses: {props.name}
                  </Typography>
                  <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
                     <CloseIcon />
                  </IconButton>
               </Toolbar>
            </AppBar>
            <Portlet className={classes.detail}>
            <PortletBody>
                  <table className="display table table-hover table-responsive-md" id="tabelmenu" style={{width:'100%'}}>
                     <thead className="bg-primary" style={{color:'white'}}>
                           <tr>
                              <th scope="col">Nama Menu Aplikasi</th>
                              <th scope="col">Aksi</th>
                           </tr>
                     </thead>
                  </table>
               <Row>
                  <Col md>
                     <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Kembali</button>
                     <button type="sumbit" className="btn btn-brand btn-elevate pull-right" onClick=""><i class="fas fa-save"></i>Simpan</button>
                  </Col>
               </Row>
            </PortletBody>
            </Portlet>

         </Dialog>

      </>
   )
}