/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, {
    useEffect
} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
} from "../../partials/content/Portlet";
import API from '../API'
import { useHistory  } from "react-router-dom";
// import DialogReason from './Utils/DialogReason'
import Thbdtype from './Utils/FunctionDetail'
import DialogHapusBatal from './Utils/DialogHapusBatal';
import { AlertList} from "react-bs-notifier";

export default function HasilDataLeasing() {
    const [id, setId] = React.useState(null);
    const [approve , setApprove] = React.useState(false);
    const [reject , setReject] = React.useState(false);
    const [alerts, setAlerts] = React.useState([]);
    const history = useHistory();
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    const openApprove = () => {
        setApprove(true);
    }
    const closeApprove = () => {
        setApprove(false);
    }
    const openReject = () => {
        setReject(true);
    }
    const closeReject = () => {
        setReject(false);
    }

    useEffect(() => {
        $(document).ready(function () {
            $('#data_terupload').DataTable( {
                "pagingType": "full_numbers",
                "processing": true,
                "serverSide": true,
                "destroy": true,
                "ajax": {
                  "url": API.Uploadapi+"v1/Data_terupload_leasing/index",
                  "type": 'get',
                  "beforeSend": function (xhr) {
                      xhr.setRequestHeader('Authorization',
                          "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    API.Handle403(xhr,setAlerts)
                }
                },
                "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
                    if ( aData.thbd_status == 1  ){ // Uploaded
                      $('td:eq(7)', nRow).css('background-color', '#81D4FA');
                    }else if ( aData.thbd_status == 2  ){ // Approved by Leasing Admin
                      $('td:eq(7)', nRow).css('background-color', '#FFE800');
                    }else if ( aData.thbd_status == 3  ){ // Rejected by Leasing Admin
                      $('td:eq(7)', nRow).css('background-color', '#FD333F');
                    }else if ( aData.thbd_status == 4  ){ // Approve by BETA
                      $('td:eq(7)', nRow).css('background-color', '#6CFF00');
                    }else if ( aData.thbd_status == 5  ){ // Reject By BETA
                      $('td:eq(7)', nRow).css('background-color', '#FD333F');
                    }
                },
                "columns": [
                    { "data": "thbd_created_date" },
                    { "data": "thbd_created_time" },
                    { "data": "mmle_name" },
                    { "data": "mmle_pic_name1" },
                    { "data": "mmle_pic_phone1" },
                    { "data": "thbd_count" },
                    {
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return " <div >"+Thbdtype.TypeUpload(full.thbd_type)+"</div>";
                        }
                    },
                    {
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                          return "<div id='status'>"+Thbdtype.StatusUpload(full.thbd_status)+"</div>";
                        }
                    },
                    {
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                          return "<button class='btn btn-outline-success btn-sm approve_btn tooltips mb-1 mr-1 app' data-original-title='Approve Data Kendaraan' data-id=" + full.thbd_id + " id='approve'><i class='fas fa-check'></i> Approve</button>"+
                          "<button class='btn btn-outline-danger btn-sm reject_btn tooltips mb-1 mr-1 app' data-original-title='Reject Data Kendaraan' data-id=" + full.thbd_id + " id='reject'><i class='fas fa-times'></i> Reject</button>";
                        }
                    },
                ]
            } );
            
            $('#data_terupload').off('click').on('click', function() {
                $('#data_terupload').on('click','#approve',function (){
                    openApprove();
                    setId($(this).attr('data-id'))
                })
                $('#data_terupload').on('click','#reject',function (){
                    openReject();
                    setId($(this).attr('data-id'))
                })
            })

            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
        })
    })

        
        const Approve = () => {
            closeApprove()
            var settings = {
                "async": true,
                "crossDomain": true,
                "url": API.Uploadapi+"v1/LeasingStatus/approver/"+id,
                "method": "GET",
                "headers": {
                    "Authorization": "Baisc " + localStorage.getItem('jwt')
                }
            }
            $.ajax(settings).done(function (response) {
                closeApprove()
                setAlerts([{
                    id: 1,
                    type: "success",
                    headline: "Berhasil",
                    message: "Data Berhasil Diapprove"
                }])
                setTimeout(() => {
                    setAlerts([])
                    // history.push("/dataapprover");
                }, 1000)
                return 0
            }).fail(function (response) {
                console.log(response)
                closeApprove()
                setAlerts([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code "+response.status+"]"
                }])
                setTimeout(() => {
                    setAlerts([])
                }, 3000)
                API.Handle403(response,setAlerts)
                return 0
            })
        }  

        const Reject = () => {
            closeReject()
                var settings = {
                    "async": true,
                    "crossDomain": true,
                    "url": API.Uploadapi+"v1/LeasingStatus/reject/"+id,
                    "method": "GET",
                    "headers": {
                        "Authorization": "Baisc " + localStorage.getItem('jwt')
                    }
                }

                $.ajax(settings).done(function (response) {
                    closeReject()
                    setAlerts([{
                        id: 1,
                        type: "success",
                        headline: "Berhasil",
                        message: "Data Berhasil Direject"
                    }])
                    setTimeout(() => {
                        setAlerts([])
                        // history.push("/dataapprover");
                    }, 1000)
                    return 0
                }).fail(function (response) {
                    console.log(response)
                    closeReject()
                    setAlerts([{
                        id: 1,
                        type: "danger",
                        headline: "Gagal",
                        message: "Terjadi Kendala [Error Code "+response.status+"]"
                    }])
                    setTimeout(() => {
                        setAlerts([])
                    }, 3000)
                    API.Handle403(response,setAlerts)
                    return 0
                })
        }    

    return(
        <>  
            <AlertList  showIcon={false} alerts={alerts}/>
            <DialogHapusBatal 
            open={approve} 
            close={closeApprove} 
            title="Approve Data" 
            message="Anda Yakin Ingin Approve Data Ini ?" 
            btn="Ya"
            fun={Approve} 
            // type="delete" 
            // id={id}
        />
        <DialogHapusBatal 
            open={reject} 
            close={closeReject} 
            title="Reject Data" 
            message="Anda Yakin Ingin Reject Data Ini ?" 
            btn="Ya"
            fun={Reject} 
            // type="delete" 
            // id={id}
        />
        <Portlet>
            <PortletHeader title="Leasing Data Terupload"/>
            <PortletBody>
                <table className="table table-hover table-responsive-md" id="data_terupload" style={{width:'100%'}}>
                    <thead className="bg-primary" style={{color:'white'}}>
                        <tr>
                            <th scope="col">Tanggal Diupload</th>
                            <th scope="col">Jam Diupload</th>
                            <th scope="col">Nama Leasing</th>
                            <th scope="col">Nama PIC 1</th>
                            <th scope="col">No Handphone PIC 1</th>
                            <th scope="col">Jumlah Data</th>
                            <th scope="col">Tipe Upload</th>
                            <th scope="col">Status Upload</th>
                            <th scope="col">Aksi</th>
                        </tr>
                    </thead>
                </table>
                <div>
                    <button type="button" class="btn btn-default" style={{background:"#81D4FA"}}></button><span style={{color:"black"}}> : Uploaded  </span>
                </div>
            </PortletBody>
        </Portlet>
    </>
    )
}