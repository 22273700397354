import React, {useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
  } from "../../partials/content/Portlet";
import API from '../API'
import DialogReason from './Utils/DialogReason'
import DialogHapusBatal from './Utils/DialogHapusBatal'
import { AlertList} from "react-bs-notifier";

export default function ReasonNego() {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [alerts, setAlerts] = React.useState([]);
    const [id, setId] = React.useState(null);
    const [idhapus, setIdhapus] = React.useState(null);

    const handleClickOpen = () => {
        setId(null)
        setOpen(true);
        $('#ngrs_name').val('')
        $('#ngrs_desc').val('')
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const HapusReasonNego = () => {
        console.log(idhapus)
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Foundingapi+"/v1/Negoreasons/del/"+idhapus,
            "method": "GET",
            "headers": {
                "Authorization": "Baisc "+localStorage.getItem('jwt')
                }
            }

            $.ajax(settings).done(function (response) {
                handleClose2()
                setAlerts([{
                    id: 1,
                    type: "success",
                    headline: "Berhasil",
                    message: "Reason Nego Telah Dihapus"
                }])
                setTimeout(() => {
                    setAlerts([])
                }, 3000)
                return 0
            }).fail(function (response) {
                handleClose2()
                console.log(response)
                setAlerts([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code " + response.status + "]"
                }])
                setTimeout(() => {
                    setAlerts([])
                }, 3000)
                API.Handle403(response,setAlerts)
                return 0
            })
    };

     useEffect(() => {
        $(document).ready(function () {
            $('#reason_mobil').DataTable({
                "pagingType": "full_numbers",
                "processing": true,
                "serverSide": true,
                "destroy": true,
                "ajax": {
                    "url": API.Foundingapi + "/v1/Negoreasons/index",
                    "type": 'get',
                    "beforeSend": function (xhr) {
                        xhr.setRequestHeader('Authorization',
                            "Basic " + localStorage.getItem('jwt'));
                    },
                    error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts)
                    }
                },
                "columns": [{
                        "data": "ngrs_name"
                    },
                    {
                        "data": "ngrs_desc"
                    },
                    {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            return `
                                <button id="reasonnego1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="reasonnego1">
                                    <button class='dropdown-item' id='edit' data-id="${full.ngrs_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-edit'></i>Edit Reason Nego</button>
                                    <button class='dropdown-item' id='hapus' data-id="${full.ngrs_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus Reason Nego</button>
                                </div>`;
                        }
                    },
                ]
            });
            $('#reason_mobil').off('click').on('click', function() {
                $('#reason_mobil').on('click', '#edit', function () {
                    handleClickOpen()
                    setId($(this).attr('data-id'))
                    var settings = {
                        "async": true,
                        "crossDomain": true,
                        "url": API.Foundingapi + "v1/Negoreasons/edit/" +$(this).attr('data-id'),
                        "method": "GET",
                        "headers": {
                          "Authorization": "Baisc "+localStorage.getItem('jwt')
                        }
                      }
    
                      $.ajax(settings).done(function (response) {
                        $("#ngrs_name").val(response.data.ngrs_name);
                        $("#ngrs_desc").val(response.data.ngrs_desc);
                      })
                })
                // .fail(function (response) {
                //     console.log(response)
                //     API.Handle403(response,setAlerts)
                //     return 0
                // })
    
                $('#reason_mobil').on('click', '#hapus', function () {
                    handleClickOpen2()
                    setIdhapus($(this).attr('data-id'))
                })
            })

            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
            return 0
        })
     },[setAlerts])

     const SubmitBatal = () => {

        var data = {
            'ngrs_name': $('#ngrs_name').val(),
            'ngrs_desc': $('#ngrs_desc').val()
        };
        console.log(data)
        var url
        if (id != null) {
            url = API.Foundingapi + "v1/Negoreasons/update/" + id;
        } else {
            url = API.Foundingapi + "v1/Negoreasons/add/";
        }
        console.log(url)

        var settings = {
            "async": true,
            "crossDomain": true,
            "url": url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Baisc " + localStorage.getItem('jwt')
            },
            "processData": false,
            "data": JSON.stringify(data)
        }

        $.ajax(settings).done(function (response) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: id!=null?"Reason Nego Telah Diubah":"Reason Nego Telah Ditambahkan"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            $('#ngrs_name').val('')
            $('#ngrs_desc').val('')
            handleClose()
            return 0
        }).fail(function (response) {
            console.log(response)
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code " + response.status + "]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

    return(
        <>
            <AlertList  showIcon={false} alerts={alerts}/>
            <DialogReason 
                open={open} 
                close={handleClose} 
                title={id!=null?"Edit Reason Nego":"Tambah Reason Nego"} 
                batal="none" 
                submit={SubmitBatal}
            />
            <DialogHapusBatal 
                open={open2} 
                close={handleClose2} 
                title="Hapus Reason Nego" 
                message="Anda Yakin Ingin Hapus Reason Ini ?" 
                btn="Hapus" 
                fun={HapusReasonNego}
                // type="delete" 
                // id={idhapus}
            />
            <Portlet>
                <PortletHeader
                    title="Data Reason Nego"
                    toolbar={(
                        <PortletHeaderToolbar>
                            <button
                                type="button"
                                className="btn btn-label-primary"
                                onClick={handleClickOpen}
                            >
                                <i class="fas fa-plus"></i>Tambah Reason Nego
                            </button>
                        </PortletHeaderToolbar>
                    )}
                >
                </PortletHeader>
                <PortletBody>
                    <table className="table table-hover table-responsive-md" id="reason_mobil" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                <th scope="col">Nama</th>
                                <th scope="col">Deskripsi</th>
                                <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                    </table>
                </PortletBody>
            </Portlet>
        </>
    )
}