import $ from 'jquery'
import API from '../../API'

const formatter = new Intl.NumberFormat('de-DE')

export default {
    Pricing : (state) => {
        $('#rangepricing').DataTable( {
            "pagingType": "full_numbers",
            "lengthMenu": [
                [5, 10, 15, 20, -1],
                [5, 10, 15, 20, "All"]
            ],
            "processing": true,
            "serverSide": true,
            "destroy" : true,
            "ajax" : {
                "url" : API.Userapi+"v1/master/rangePricing",
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    API.Handle403(xhr,state)
                }
            },
            "columns": [
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.range_price_name
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.type_range
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.jenis_kendaraan != null ? full.jenis_kendaraan : "MOTOR-MOBIL"
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        var start_range = full.start_range_price != null ? formatter.format(parseInt(full.start_range_price)) : '-'
                        return " <div >" +start_range+ "</div>";
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        var end_range = full.end_range_price !=null ? formatter.format(parseInt(full.end_range_price)) : '-'
                        return " <div >" +end_range+ "</div>";
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return "<div>" +full.potongan_percent+ "% </div>";
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return formatter.format(parseInt(full.potongan_minimal))
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return formatter.format(parseInt(full.prc_upline1))
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return formatter.format(parseInt(full.prc_upline2))
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return formatter.format(parseInt(full.komisi_informan)) 
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return formatter.format(parseInt(full.potongan_stnk))
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                        return `
                        <button id="settings" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="editleasing2">
                            <button class='dropdown-item' id='editRangePricing' data-id="${full.range_price_id}"  data-toggle='tooltip' title='Edit Range Pricing'><i class='fa fa-edit'></i>Edit Pricing</button>
                            <button class='dropdown-item' id='hapusRangePricing' data-id="${full.range_price_id}" data-toggle='tooltip' title='Hapus Range Pricing'><i class='fa fa-trash'></i>Hapus Pricing</button>
                        </div>`;
                    }
                },
                
            ]
        })
    },
    KuotaDownlineAsal: (state) => {
        $('#kuotadownlineasal').DataTable( {
            "pagingType": "full_numbers",
            "lengthMenu": [
                [5, 10, 15, 20, -1],
                [5, 10, 15, 20, "All"]
            ],
            "processing": true,
            "serverSide": true,
            "destroy" : true,
            "ajax" : {
                "url" : API.Userapi+"v1/master/kuotaDownline?type=0",
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    API.Handle403(xhr,state)
                }
            },
            "columns": [
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.province
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.city                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.max_quota_value
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                        return `
                        <button id="editleasing2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="editleasing2">
                            <button class='dropdown-item' id='editKuotaAsal' data-id="${full.quota_downline_id}"  data-toggle='tooltip' title='Hapus Kuota Downline Kota Asal'><i class='fa fa-edit'></i>Edit Kuota</button>
                            <button class='dropdown-item' id='hapusKuotaAsal' data-id="${full.quota_downline_id}"  data-toggle='tooltip' title='Hapus Kuota Downline Kota Asal'><i class='fa fa-trash'></i>Hapus Kuota</button>
                        </div>`;
                    }
                },
            ]
        })
    },
    KuotaDownlineLain: (state) => {
        $('#kuotadownlinelain').DataTable( {
            "pagingType": "full_numbers",
            "lengthMenu": [
                [5, 10, 15, 20, -1],
                [5, 10, 15, 20, "All"]
            ],
            "processing": true,
            "serverSide": true,
            "destroy" : true,
            "ajax" : {
                "url" : API.Userapi+"v1/master/kuotaDownline?type=1",
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    API.Handle403(xhr,state)
                }
            },
            "columns": [
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.province
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.max_quota_value
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                        return `
                        <button id="editleasing2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="editleasing2">
                            <button class='dropdown-item' id='editKuotaKotaLain' data-id="${full.quota_downline_id}"  data-toggle='tooltip' title='Edit Kuota Downline Kota Lain'><i class='fa fa-edit'></i>Edit Kuota Kota Lain</button>
                            <button class='dropdown-item' id='hapusKuotaKotaLain' data-id="${full.quota_downline_id}"  data-toggle='tooltip' title='Hapus Kuota Downline Kota Lain'><i class='fa fa-trash'></i>Hapus Kuota Kota Lain</button>
                        </div>`;
                    }
                },
            ]

        })
    },
    Skema: (state) => {
        $('#skema').DataTable( {
            "pagingType": "full_numbers",
            "lengthMenu": [
                [5, 10, 15, 20, -1],
                [5, 10, 15, 20, "All"]
            ],
            "processing": true,
            "serverSide": true,
            "destroy" : true,
            "ajax" : {
                "url" : API.Userapi+"v1/master/skema",
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    API.Handle403(xhr,state)
                }
            },
            "columns": [
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.skema_province
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.skema_city
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.skema_value
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        var status = full.skema_value
                        return status == 1 ? 'Downline' : 'Penemuan';
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                        return `
                        <button id="editleasing2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="editleasing2">
                            <button class='dropdown-item' id='editSkema' data-id="${full.skema_id}"  data-toggle='tooltip' title='Edit Skema'><i class='fa fa-edit'></i>Edit</button>
                            <button class='dropdown-item' id='hapusSkema' data-id="${full.skema_id}"  data-toggle='tooltip' title='Hapus Skema'><i class='fa fa-trash'></i>Hapus</button>
                        </div>`;
                    }
                },
            ]
        })
    },

    AksesData: (state) => {
        $('#aksesdata').DataTable( {
            "pagingType": "full_numbers",
            "lengthMenu": [
                [5, 10, 15, 20, -1],
                [5, 10, 15, 20, "All"]
            ],
            "processing":true,
            "serverSide":true,
            "destroy": true,
            "ajax": {
                "url" : API.Userapi+"v1/master/aksesdata",
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown , settings) {
                    API.Handle403(xhr,state)
                }
            },
            "columns": [
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.nama_leasing
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.jenis_kendaraan != null ? full.jenis_kendaraan : "MOTOR-MOBIL"
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.provinsi
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.kabupaten
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.akses_data
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.range_price_name
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta) {
                        return full.range_price_type ? full.range_price_type : ""
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                        return `
                        <button id="editleasing2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="editleasing2">
                            <button class='dropdown-item' id='editAksesData' data-id="${full.akses_data_id}"  data-toggle='tooltip' title='Edit Akses Data'><i class='fa fa-edit'></i>Edit</button>
                            <button class='dropdown-item' id='hapusAksesData' data-id="${full.akses_data_id}"  data-toggle='tooltip' title='Hapus Akses Data'><i class='fa fa-trash'></i>Hapus</button>
                        </div>`;
                    }
                },
            ]
        })
    },
    dropdownProvinsi: (state) => {
        $('#akses_provinsi').select2({
            minimumInputLength: 0,
            dropdownParent: $('#aksespotongandialog'),
            ajax: {
                type: 'get',
                url: API.Utilsapi+'v1/address/provinsi',
                headers: {
                    "Authorization": "Basic "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,state);
                },
                data: function (params) {
                    return {
                        "select2" : params.term,
                        "length": 34,
                    };
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.mprv_name,
                                id: item.mprv_id,
                            };
                        })
                    };
                }
            }
        }).on('change', function(){
            $('#akses_kabupaten').val(0).trigger('change');
        });
    },
    dropdownKota: (state) => {
        $('#akses_kabupaten').select2({
            minimumInputLength: 0,
            dropdownParent: $('#aksespotongandialog'),
            ajax: {
                type: 'get',
                url: API.Utilsapi+'v1/address/kota',
                headers: {
                    "Authorization": "Basic "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,state);
                },
                data: function (params) {
                    var mper_provinsi = $( "#akses_provinsi option:selected" ).text();
                    if(  mper_provinsi != 'Pilih Provinsi' ) {
                        return {
                            "select2" : params.term,
                            "mprv": mper_provinsi,
                            "length": 514,
                        };
                    }else{
                        return {
                            "select2" : params.term,
                            "length": 514,
                        };
                    }
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.mcty_name,
                                id: item.mcty_id,
                            };
                        })
                    };
                }
            }
        })
    }
}