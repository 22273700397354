/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import API from '../../API'
import $ from 'jquery'
import { AlertList} from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
}));

export default function DialogVersion(props) {

    const [alerts,setAlerts] = React.useState([])

    const classes = useStyles();

    const Submit = () => {
            var vrsn_name = $('#versi').val()
            var vrsn_is_force = $('#force').val()
            var vrsn_tipe = $('#type').val()

            var data = {
                'vrsn_name': vrsn_name,
                'vrsn_is_force': vrsn_is_force,
                'vrsn_tipe' : vrsn_tipe
              };

            var settings = {
                "async": true,
                "crossDomain": true,
                "url": API.Authapi + "v1/version/add/" + vrsn_name + "/" + vrsn_is_force + "/" + vrsn_tipe,
                "method": "GET",
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Baisc " + localStorage.getItem('jwt')
                },
                "processData": false,
                "data": JSON.stringify(data)
            }

            $.ajax(settings).done(function (response) {
                props.close()
                $('#versi').val('')
                $('#force').val(0)
                $('#type').val(0)
                setAlerts([
                    {
                        id: 1,
                        type: "success",
                        headline: "Berhasil",
                        message: "Version Telah Ditambahkan"
                    }
                ])
                setTimeout(()=>{
                    setAlerts([])
                },3000)
                return 0
            }).fail(function (response) {
                console.log(response);
                setAlerts([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code "+response.status+"]"
                }])
                setTimeout(()=>{
                    setAlerts([])
                },3000)
                return 0
            });
    }

  return (
    <div>
        <AlertList showIcon={false} alerts={alerts}/>
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="xs"
            keepMounted
            onClose={props.close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
            <div className={classes.root}>
                <div className="form-group row">
                    <label for="inputEmail3" className="col-sm-4 col-form-label">Version</label>
                    <div className="col-sm-8">
                        <input type="email" className="form-control" id="versi" />
                    </div>
                </div>
                <div className="form-group row">
                    <label for="inputEmail3" className="col-sm-4 col-form-label">Is Force</label>
                    <div className="col-sm-8">
                        <select id="force" className="form-control">
                            <option value="0" selected disabled> Pilih Role</option>
                            <option value="yes">Yes </option>
                            <option value="no">No </option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label for="inputEmail3" className="col-sm-4 col-form-label">Tipe</label>
                    <div className="col-sm-8">
                        <select id="type" className="form-control">
                            <option value="0" selected disabled> Pilih Role</option>
                            <option value="motor_mobil">Motor/Mobil </option>
                            <option value="informan">Informan </option>
                        </select>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            Batal
          </Button>
          <Button onClick={Submit} color="primary">
            Tambahkan
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

