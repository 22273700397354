/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Row, Col } from "react-bootstrap";
import {
  Portlet,
  PortletBody,
  // PortletHeader,
  // PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import Box from "@material-ui/core/Box";
import API from "../../API";
import { AlertList } from "react-bs-notifier";
import Utils from "./FunctionDetail";
import $ from "jquery";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset: theme.mixins.toolbar,
    marginTop: "70px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailUser(props) {
  const classes = useStyles();
  const [alerts, setAlerts] = React.useState([]);
  React.useEffect(() => {
    // console.log($(this).attr('data-id'))

    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/user/detail/" + props.id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    var getupline = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/user/cekupline/" + props.id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    var getdownline = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/user/cekdownline/" + props.id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    if (props.id != null) {
      $.ajax(settings)
        .done(function(response) {
          console.log(response);
          if (response.data != null) {
            $("#mper_ic_number").val(response.data.mper_ic_number);
            $("#mper_name").val(response.data.mper_name);
            $("#mper_phone").val(response.data.mper_phone);
            $("#mper_emergency_phone").val(response.data.mper_emergency_phone);
            $("#tanggal_lahir").val(response.data.mper_dob);
            $("#mper_address").val(response.data.mper_address);

            if (response.data.mper_province != "") {
              Utils.setProvinsi(response.data.mper_province, "#mper_province1");
            } else {
              $("#mper_province1").val("???????");
            }
            if (response.data.mper_district != "") {
              Utils.setKecamatan(response.data.mper_district, "#mper_kec1");
            } else {
              $("#mper_kec1").val("???????");
            }
            if (response.data.mper_city != "") {
              Utils.setKota(response.data.mper_city, "#mper_kota1");
            } else {
              $("#mper_kota1").val("???????");
            }
            console.log(Utils.setKota(response.data.mper_city, "#mper_kota1"));
            $("#mper_postal_code").val(response.data.mper_postal_code);
            $("#mper_last_job").val(response.data.mper_last_job);
            $("#mper_type").val(Utils.MperAll(response.data.mper_type));
            $("#mper_registration_date").val(response.data.mper_registration_date);
            $("#mper_registration_via").val(
              Utils.StatusRegistrasi(response.data.mper_registration_via)
            );
            $("#mper_status").val(Utils.StatusUser(response.data.mper_status));

            $("#tgl_valid").val(
              response.data.mper_valid_from + " - " + response.data.mper_valid_to
            );

            if (response.data.mper_medi_profile == null || response.data.mper_medi_profile == "") {
              $("#foto_user").addClass("disabled");
            } else {
              $("#foto_user").attr("href", response.data.mper_medi_profile);
            }

            if (response.data.mper_medi_ktp == null || response.data.mper_medi_ktp == "") {
              $("#foto_ktp").addClass("disabled");
            } else {
              $("#foto_ktp").attr("href", response.data.mper_medi_ktp);
            }

            if (response.data.mper_srtf_user == null || response.data.mper_srtf_user == "") {
              $("#foto_srtf").addClass("disabled");
            } else {
              $("#foto_srtf").attr("href", response.data.mper_srtf_user);
            }
          }
        })
        .fail(function(response) {
          API.Handle403(response, setAlerts);
        });

      $.ajax(getupline)
        .done(function(response) {
          if (response.data != null) {
            if (response.data.upline1 == null || response.data.upline1 == "") {
              $("#upline1").hide();
            } else {
              $("#upline1")
                .val(response.data.upline1.mper_name)
                .show();
            }
            if (response.data.upline2 == null || response.data.upline2 == "") {
              $("#div-upline2, #upline2").hide();
            } else {
              $("#div-upline2, #upline2").show();
              $("#upline2")
                .val(response.data.upline2.mper_name)
                .show();
            }
          } else {
            $("#upline1,#div-upline2,#upline2").hide();
          }
        })
        .fail(function(response) {
          API.Handle403(response, setAlerts);
        });

      $.ajax(getdownline)
        .done(function(response) {
          var downline = response.data.downline1;
          var htmlDownline1 = "";
          var htmlDownline2 = "";
          if (downline != null) {
            downline.forEach((downline1) => {
              htmlDownline1 +=
                " <label for='inputEmail3' class='col-sm-4 col-form-label'>" +
                downline1.mper_name +
                "</label> <hr />";
              if (downline1.downline2 != null) {
                downline1.downline2.forEach((downline2) => {
                  htmlDownline2 +=
                    "<label for='inputEmail3' class='col-sm-4 col-form-label'>" +
                    downline2.mper_name +
                    "</label> <hr />";
                });
              }
            });

            if (htmlDownline1 != "" && htmlDownline2 != "") {
              $("#downline1")
                .html(htmlDownline1)
                .show();
              $("#downline2")
                .html(htmlDownline2)
                .show();
            } else if (htmlDownline1 != "" && htmlDownline2 == "") {
              $("#downline1")
                .html(htmlDownline1)
                .show();
              $("#downline2").hide();
            } else {
              $("#downline1, #downline2").hide();
            }
          } else {
            $("#downline1, #downline2").hide();
          }
        })
        .fail(function(response) {
          API.Handle403(response, setAlerts);
        });
    }
  });

  return (
    <div>
      <AlertList showIcon={false} alerts={alerts} />
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Detail User
            </Typography>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Portlet className={classes.detail}>
          <PortletBody>
            <Row>
              <Col md={6}>
                <div className="form-group row">
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Nomor KTP
                  </label>
                  <div className="col-sm-7">
                    <input type="text" className="form-control" id="mper_ic_number" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Nama
                  </label>
                  <div className="col-sm-7">
                    <input type="text" className="form-control" id="mper_name" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Nomor Handphone
                  </label>
                  <div className="col-sm-7">
                    <input type="text" className="form-control" id="mper_phone" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Nomor Handphone Darurat
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control"
                      id="mper_emergency_phone"
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Tanggal Lahir
                  </label>
                  <div className="col-sm-7">
                    <input type="text" className="form-control" id="tanggal_lahir" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Alamat
                  </label>
                  <div className="col-sm-7">
                    <input type="text" className="form-control" id="mper_address" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Provinsi
                  </label>
                  <div className="col-sm-7">
                    <select className="form-control" id="mper_province1" disabled>
                      {/* <option value="0" selected disabled> Pilih Provinsi</option> */}
                    </select>
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Kota/Kabupaten
                  </label>
                  <div className="col-sm-7">
                    <select className="form-control" id="mper_kota1" disabled>
                      {/* <option value="0" selected disabled> Pilih Kota/Kabupaten</option> */}
                    </select>
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Kecamatan
                  </label>
                  <div className="col-sm-7">
                    <select className="form-control" id="mper_kec1" disabled>
                      {/* <option value="0" selected disabled> Pilih Kecamatan</option> */}
                    </select>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group row">
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Kode Pos
                  </label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="mper_postal_code" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Pekerjaan Terakhir
                  </label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="mper_last_job" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Role
                  </label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="mper_type" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Tanggal Registrasi
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="mper_registration_date"
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Registrasi Via
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="mper_registration_via"
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Status
                  </label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="mper_status" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Tanggal Valid
                  </label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="tgl_valid" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Foto User
                  </label>
                  <div className="col-sm-8">
                    <a
                      type="button"
                      className="btn btn-outline-brand btn-icon"
                      id="foto_user"
                      target="_blank"
                      disabled="disabled"
                    >
                      <i className="far fa-image"></i>
                    </a>
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Foto KTP
                  </label>
                  <div className="col-sm-8">
                    <a
                      type="button"
                      className="btn btn-outline-brand btn-icon"
                      id="foto_ktp"
                      target="_blank"
                      disabled="disabled"
                    >
                      <i className="far fa-image"></i>
                    </a>
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Foto Sertifikat
                  </label>
                  <div className="col-sm-8">
                    <a
                      type="button"
                      className="btn btn-outline-brand btn-icon"
                      id="foto_srtf"
                      target="_blank"
                      disabled="disabled"
                    >
                      <i className="far fa-image"></i>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div id="div-upline2" className="form-group row">
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Upline level 2
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control-plaintext"
                      style={{ color: "black" }}
                      id="upline2"
                      disabled
                    />{" "}
                    <hr />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Upline level 1
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control-plaintext"
                      style={{ color: "black" }}
                      id="upline1"
                      disabled
                    />{" "}
                    <hr />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-group row">
                  <label for="inputEmail3" className="col-sm-4 col-form-label downline1">
                    Downline level 1
                  </label>
                  <div className="col-sm-7" id="downline1"></div>
                </div>
                <div className="form-group row" id="div-downline2">
                  <label for="inputEmail3" className="col-sm-4 col-form-label downline2">
                    Downline level 2
                  </label>
                  <div className="col-sm-7" id="downline2"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}>
                  <i class="fas fa-arrow-left"></i>Kembali
                </button>
              </Col>
            </Row>
          </PortletBody>
        </Portlet>
      </Dialog>
    </div>
  );
}
