/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import {
    Portlet,
    PortletBody,
    // PortletHeader,
    // PortletHeaderToolbar
  } from "../../partials/content/Portlet";
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme  } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import $ from 'jquery';
import 'datatables.net-bs4';
// import Slide from '@material-ui/core/Slide';
import SwipeableViews from 'react-swipeable-views';
import Table from './Penemuan/TablePenemuan'
import DetailPenemuan from './Utils/DetailPenemuan'
import DetailInforman from './Utils/DetailInforman'
import DetailPembatalanInforman from './Utils/DetailPembatalanInforman'
import DetailNego from './Utils/DetailNego'
import DialogHapusBatal from './Utils/DialogHapusBatal'
import {Badge} from 'react-bootstrap'
import { AlertList} from "react-bs-notifier";
import {Tabs as Tabs2, Tab as Tab2, Row, Col} from 'react-bootstrap';
import API from '../API'
import 'bootstrap';

const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    // backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'bold',
    minWidth: 80,
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#ffffff',
      opacity: 1,
    },
    '&$selected': {
      color: '#ffffff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#ffffff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },  
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

  export default function Penemuan() {

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);
    const [open7, setOpen7] = React.useState(false);
    const [open8, setOpen8] = React.useState(false);
    const [open9, setOpen9] = React.useState(false);
    const [open10, setOpen10] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [idinforman, setIdinforman] = React.useState(null);
    const [idnego, setIdnego] = React.useState(null);
    const [idpembatalan, setIdpembatalan] = React.useState(null);
    const [idbatal, setIdbatal] = React.useState(null);
    const [step, setStep] = React.useState(null);
    const [stepinforman, setStepinforman] = React.useState(null);
    const [stepnego, setStepnego] = React.useState(null);
    const [stepbatal, setStepbatal] = React.useState(null);
    const [by, setBy] = React.useState(null);
    const [alerts, setAlerts] = React.useState([]);
    // const [mpertype, setMperType] = React.useState(null);

    useEffect(() => {

        $(document).ready(function () {

          Table.ValidasiInforman()
          Table.Matchmaking()
          Table.Matched()
          Table.Validasi()
          Table.MenungguSuratTugas()
          Table.SuratTugasDiterima()
          Table.Pengantaran()
          Table.DalamPengantaran()
          Table.Selesai()
          Table.Selesai_Informan()
          Table.Dibatalkan()
          Table.CountPenemuan()
          Table.PembatalanInforman()

          $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').off('click').on('click', function() {

            $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').on('click','#detail',function () {
                handleClickOpen()
                setId($(this).attr('data-id'))
                setStep($(this).attr('data-step'))
            });

            $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').on('click','#informan',function () {
              handleClickOpen5()
              setIdinforman($(this).attr('data-id'))
              setStepinforman($(this).attr('data-step'))
              $('#tpif_vtype').val($(this).attr('data-unit'))
            });

            $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').on('click','#detailpembatalan',function () {
              handleClickOpen6()
              setIdpembatalan($(this).attr('data-id'))
              setBy($(this).attr('data-by'))
              // console.log($(this).attr('data-id'))
            });

            $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').on('click','#nego',function () {
              handleClickOpen2()
              setIdnego($(this).attr('data-id'))
              setStepnego($(this).attr('data-step'))
            });

            $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').on('click','#batal',function () {
              handleClickOpen3()
              setId($(this).attr('data-id'))
              setStep($(this).attr('data-step'))
            });

            $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').on('click','#batalinforman',function () {
              handleClickOpen7()
              setIdbatal($(this).attr('data-id'))
              setStepbatal($(this).attr('data-step'))
            });

            $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').on('click','#hapusbatalinforman',function () {
              handleClickOpen8()
              setId($(this).attr('data-id'))
              setStep($(this).attr('data-step'))
            });

            $('#validasiinforman,#matchmaking,#matched-1,#TableMatched2,#validasi,#menunggusurattugas,#surattugasditerima,#pengantaran,#dalampengantaran,#selesai,#dibatalkan,#pembatalaninforman,#selesai_informan').on('click','#batalNyaBatal',function () {
              handleClickOpen4()
              setId($(this).attr('data-id'))
              setStep($(this).attr('data-step'))
            });
          })

          $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
        })
    });
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangeIndex = index => {
      setValue(index);
    };

    function handleClickOpen() {
      setOpen(true);
    }

    function handleClose() {
      setOpen(false);
    }

    function handleClickOpen2() {
      setOpen2(true);
    }

    function handleClose2() {
      setOpen2(false);
    }

    function handleClickOpen3() {
      setOpen3(true);
    }

    function handleClose3() {
      setOpen3(false);
      $('#tpnm_reason_text').val('')
    }

    function handleClickOpen4() {
      setOpen4(true);
    }

    function handleClose4() {
      setOpen4(false);
    }

    function handleClickOpen5() {
      setOpen5(true);
    }

    function handleClose5() {
      $('#detailpenemuaninforman input').val('')
      setIdinforman(null)
      setStepinforman(null)
      setOpen5(false);
    }

    function handleClickOpen6() {
      setOpen6(true);
    }

    function handleClose6() {
      setOpen6(false);
    }

    function handleClickOpen7() {
      setOpen7(true);
      $('#tpif_reason_text').val('')
    }

    function handleClose7() {
      setOpen7(false);
    }

    function handleClickOpen8() {
      setOpen8(true);
    }

    function handleClose8() {
      setOpen8(false);
      setId(null)
      setStep(null)
    }

    function handleClickOpen9() {
      setOpen9(true);
    }

    function handleClose9() {
      setOpen9(false);
    }

    function handleClickOpen10() {
      setOpen10(true);
    }

    function handleClose10() {
      setOpen10(false);
    }
    
    function Batal() {
      console.log(id)
      console.log(step)

      var data = {
        "tpnm_reason_text" : $('#tpnm_reason_text').val()
      }
      
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/pembatalan_penemuan/pembatalan/"+id+"/"+step,
        "method": "POST",
        "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt'),
            "Content-Type": "application/json"
        },
        "processData": false,
        "data": JSON.stringify(data)
      }
      
      if ($('#tpnm_reason_text').val() != null || $('#tpnm_reason_text').val() != undefined) {
        $.ajax(settings).done(function (response) {
          handleClose3()
          setAlerts([{
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Penemuan Telah Dibatalkan"
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          return 0
        }).fail(function (response) {
          handleClose3()
          console.log(response)
          setAlerts([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]"
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
          return 0
        })
      }
    }

    function BatalInforman() {
      // console.log(idbatal)
      // console.log(stepbatal)
      console.log($('#tpif_reason_text').val())

      var data = {
        "tpif_reason_text" : $('#tpif_reason_text').val()
      }

      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/pembatalan_penemuan/pembatalan_informan/"+idbatal+"/"+stepbatal,
        "method": "POST",
        "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt'),
            "Content-Type": "application/json"
        },
        "processData": false,
        "data": JSON.stringify(data)
      }
      
      if ($('#tpif_reason_text').val() != null || $('#tpif_reason_text').val() != undefined) {
        $.ajax(settings).done(function (response) {
          handleClose7()
          setAlerts([{
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Penemuan Telah Dibatalkan"
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          return 0
        }).fail(function (response) {
          handleClose7()
          console.log(response)
          setAlerts([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]"
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
          return 0
        })
      }
    }

    function HapusBatal() {
      console.log(id)
      console.log(step)
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/pembatalan_penemuan/pembatalan_dibatalkan/"+id+"/"+step,
        "method": "GET",
        "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt')
          }
      }
  
      $.ajax(settings).done(function (response) {
        handleClose4()
        setAlerts([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: "Pembatalan Telah Dihapus"
        }])
        setTimeout(() => {
          setAlerts([])
        }, 3000)
        return 0
      }).fail(function (response) {
        handleClose4()
        console.log(response)
        setAlerts([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code " + response.status + "]"
        }])
        setTimeout(() => {
          setAlerts([])
        }, 3000)
        API.Handle403(response,setAlerts)
        return 0
      })
    }

    function HapusSemuaPembatalan() {
      handleClose9()

      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"/v1/Pembatalan_penemuan/deleteAll",
        "method": "GET",
        "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt')
          }
      }
  
      $.ajax(settings).done(function (response) {
        setAlerts([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: "Semua Pembatalan Telah Dihapus"
        }])
        setTimeout(() => {
          setAlerts([])
        }, 3000)
        return 0
      }).fail(function (response) {
        console.log(response)
        setAlerts([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code " + response.status + "]"
        }])
        setTimeout(() => {
          setAlerts([])
        }, 3000)
        API.Handle403(response,setAlerts)
        return 0
      })
    }

    function HapusSemuaPembatalanInforman() {
      handleClose10()

      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"/v1/Pembatalan_penemuan/deleteAllTpif",
        "method": "GET",
        "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt')
          }
      }
  
      $.ajax(settings).done(function (response) {
        setAlerts([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: "Semua Pembatalan Informan Telah Dihapus"
        }])
        setTimeout(() => {
          setAlerts([])
        }, 3000)
        return 0
      }).fail(function (response) {
        console.log(response)
        setAlerts([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code " + response.status + "]"
        }])
        setTimeout(() => {
          setAlerts([])
        }, 3000)
        API.Handle403(response,setAlerts)
        return 0
      })
    }

    function HapusInforman() {
      console.log(id)
      console.log(step)
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/pembatalan_penemuan/hapus_pembatalan_informan/"+id+"/"+step,
        "method": "GET",
        "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt')
          }
      }
  
      $.ajax(settings).done(function (response) {
        handleClose8()
        setAlerts([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: "Pembatalan Informan Telah Dihapus"
        }])
        setTimeout(() => {
          setAlerts([])
        }, 3000)
        return 0
      }).fail(function (response) {
        handleClose8()
        console.log(response)
        setAlerts([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code " + response.status + "]"
        }])
        setTimeout(() => {
          setAlerts([])
        }, 3000)
        API.Handle403(response,setAlerts)
        return 0
      })
    }

    return (
    <>
      <AlertList  showIcon={false} alerts={alerts}/>
      <DetailPenemuan open={open} close={handleClose} id={id} step={step}/>
      <DetailInforman open={open5} close={handleClose5} id={idinforman} step={stepinforman}/>
      <DetailPembatalanInforman open={open6} close={handleClose6} id={idpembatalan} by={by}/>
      <DetailNego open={open2} close={handleClose2} id={idnego} step={stepnego}/>
      <DialogHapusBatal                 
        open={open3} 
        close={handleClose3} 
        title="Pembatalan Penemuan" 
        message={
          <div>
            Anda Yakin Ingin Membatalkan Penemuan Ini ?
            <div className="form-group mt-3">
              <label>Alasan Pembatalan</label>
              <input type="text" className="form-control" id="tpnm_reason_text" autoComplete="off"/>
            </div>
          </div>
        }
        btn="Batalkan" 
        btn2="Kembali" 
        // type="okbatal" 
        fun={Batal}
      />
      <DialogHapusBatal                 
        open={open4} 
        close={handleClose4} 
        title="Hapus Pembatalan" 
        message="Anda Yakin Ingin Menghapus Pembatalan Ini ?" 
        btn="Hapus" 
        btn2="Batal" 
        // type="okbatal" 
        fun={HapusBatal}
      />
      <DialogHapusBatal                 
        open={open7} 
        close={handleClose7} 
        title="Pembatalan Penemuan Informan" 
        message={
          <div>
            Anda Yakin Ingin Membatalkan Penemuan Ini ?
            <div className="form-group mt-3">
              <label>Alasan Pembatalan </label>
              <input type="text" className="form-control" id="tpif_reason_text" autoComplete="off"/>
            </div>
          </div>
        }
        btn="Batalkan" 
        btn2="Kembali" 
        // type="okbatal" 
        fun={BatalInforman}
      />
      <DialogHapusBatal                 
        open={open8} 
        close={handleClose8} 
        title="Hapus Pembatalan Informan" 
        message="Anda Yakin Ingin Menghapus Pembatalan Ini ?" 
        btn="Hapus" 
        btn2="Batal" 
        // type="okbatal" 
        fun={HapusInforman}
      />
      <DialogHapusBatal                 
        open={open9} 
        close={handleClose9} 
        title="Hapus Semua Data Dibatalkan" 
        message="Anda Yakin Ingin Menghapus Semua Data Pembatalan Ini ?" 
        btn="Hapus" 
        btn2="Batal" 
        // type="okbatal" 
        fun={HapusSemuaPembatalan}
      />
      <DialogHapusBatal                 
        open={open10} 
        close={handleClose10} 
        title="Hapus Semua Data Pembatalan Informan" 
        message="Anda Yakin Ingin Menghapus Semua Data Pembatalan Informan ?" 
        btn="Hapus" 
        btn2="Batal" 
        // type="okbatal" 
        fun={HapusSemuaPembatalanInforman}
      />
      <Portlet>
        <PortletBody>
          <div className={classes.root}>
            <AppBar position="static" color="primary" style={{borderRadius:'10px'}}>
              <AntTabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable"
                scrollButtons="auto" aria-label="scrollable auto tabs example">
                <AntTab label={<div style={{display:'inline'}}>Validasi Informan <Badge pill variant="warning" id="totalStep1"></Badge></div>} {...a11yProps(0)}/>
                <AntTab label={<div style={{display:'inline'}}>Matchmaking <Badge pill variant="warning" id="totalStep2"></Badge></div>} {...a11yProps(1)}/>
                <AntTab label={<div style={{display:'inline'}}>Matched <Badge pill variant="warning" id="totalAllMatched"></Badge></div>} {...a11yProps(2)}/>
                <AntTab label={<div style={{display:'inline'}}>Validasi Mitra <Badge pill variant="warning" id="totalValidasi"></Badge></div>} {...a11yProps(3)}/>
                <AntTab label={<div style={{display:'inline'}}>Menunggu Surat Tugas <Badge pill variant="warning" id="totalMenungguSuratTugas"></Badge></div>} {...a11yProps(4)} />
                <AntTab label={<div style={{display:'inline'}}>Surat Tugas Diterima <Badge pill variant="warning" id="totalSuratTugasDiterima"></Badge></div>} {...a11yProps(5)} />
                <AntTab label={<div style={{display:'inline'}}>Pengantaran <Badge pill variant="warning" id="totalPengantaran"></Badge></div>} {...a11yProps(6)} />
                <AntTab label={<div style={{display:'inline'}}>Dalam Pengantaran <Badge pill variant="warning" id="totalDalamPengantaran"></Badge></div>} {...a11yProps(7)} />
                <AntTab label={<div style={{display:'inline'}}>Selesai Mitra <Badge pill variant="warning" id="totalSelesai"></Badge></div>} {...a11yProps(8)} />
                <AntTab label={<div style={{display:'inline'}}>Selesai Informan <Badge pill variant="warning" id="totalSelesai_informan"></Badge></div>} {...a11yProps(9)} />
                <AntTab style={{display: localStorage.getItem("role") != 5 ? 'none' : 'block'}} label={<div style={{display:'inline'}}>Dibatalkan <Badge pill variant="warning" id="totalCancel"></Badge></div>} {...a11yProps(10)} />
                <AntTab style={{display: localStorage.getItem("role") != 5 ? 'none' : 'block'}} label={<div style={{display:'inline'}}>Pembatalan Informan <Badge pill variant="warning" id="stepPembatalan"></Badge></div>} {...a11yProps(11)} />
              </AntTabs>
            </AppBar>
            <SwipeableViews axis={theme.direction==='rtl' ? 'x-reverse' : 'x' } index={value}
              onChangeIndex={handleChangeIndex}>
              <TabPanel value={value} index={0}>
                <table className="table table-hover table-responsive-md" id="validasiinforman" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Validasi</th>
                      <th scope="col">Jam Validasi</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Informan</th>
                      <th scope="col">No HP Informan</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nopol</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <table className="table table-hover table-responsive-md" id="matchmaking" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Validasi</th>
                      <th scope="col">Jam Validasi</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Informan</th>
                      <th scope="col">No HP Informan</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nopol</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Tabs2 defaultActiveKey="Tab1" id="uncontrolled-tab-example">
                    <Tab2 eventKey="Tab1" title={<div style={{display:'inline'}}>Mitra Ditemukan <Badge pill variant="primary" id="totalMatched1"></Badge></div>}>
                      <table className="table table-hover table-responsive-md" id="matched-1" style={{width:'100%'}}>
                        <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                          <tr>
                            <th scope="col">Tipe</th>
                            <th scope="col">Tanggal Matched</th>
                            <th scope="col">Jam Matched</th>
                            <th scope="col">Reminder 45 Menit</th>
                            <th scope="col">Nama Informan</th>
                            <th scope="col">No HP Informan</th>
                            <th scope="col">Nama Mitra</th>
                            <th scope="col">No HP Mitra</th>
                            <th scope="col">Leasing</th>
                            <th scope="col">Cabang</th>
                            <th scope="col">Nopol</th>
                            <th scope="col">Unit</th>
                            <th scope="col">Jarak Mitra Ke Informan</th>
                            <th scope="col">Aksi</th>
                          </tr>
                        </thead>
                      </table>
                    </Tab2>
                    <Tab2 eventKey="Tab2" title={<div style={{display:'inline'}}>Mitra Menuju Informan <Badge pill variant="primary" id="totalMatched2"></Badge></div>}>
                      <table className="table table-hover table-responsive-md" id="TableMatched2" style={{width:'100%'}}>
                        <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                          <tr>
                            <th scope="col">Tipe</th>
                            <th scope="col">Tanggal Matched</th>
                            <th scope="col">Jam Matched</th>
                            <th scope="col">Reminder 45 Menit</th>
                            <th scope="col">Nama Informan</th>
                            <th scope="col">No HP Informan</th>
                            <th scope="col">Nama Mitra</th>
                            <th scope="col">No HP Mitra</th>
                            <th scope="col">Leasing</th>
                            <th scope="col">Cabang</th>
                            <th scope="col">Nopol</th>
                            <th scope="col">Unit</th>
                            <th scope="col">Jarak Mitra Ke Informan</th>
                            <th scope="col">Aksi</th>
                          </tr>
                        </thead>
                      </table>
                    </Tab2>
                  </Tabs2>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <table className="table table-hover table-responsive-md" id="validasi" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      {/* <th scope="col">Penemuan</th> */}
                      <th scope="col">Informan</th>
                      <th scope="col">Tanggal Validasi</th>
                      <th scope="col">Jam Validasi</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <table className="table table-hover table-responsive-md" id="menunggusurattugas" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'10px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Informan</th>
                      <th scope="col">Tanggal Menunggu Surat Tugas</th>
                      <th scope="col">Jam Menunggu Surat Tugas</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <table className="table table-hover table-responsive-md" id="surattugasditerima" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Informan</th>
                      <th scope="col">Tanggal Surat Tugas Diterima</th>
                      <th scope="col">Jam Surat Tugas Diterima</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">File Nego</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <table className="table table-hover table-responsive-md" id="pengantaran" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Informan</th>
                      <th scope="col">Tanggal Pengantaran</th>
                      <th scope="col">Jam Pengantaran</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <table className="table table-hover table-responsive-md" id="dalampengantaran" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Informan</th>
                      <th scope="col">Tanggal Dalam Pengantaran</th>
                      <th scope="col">Jam Dalam Pengantaran</th>
                      <th scope="col">Reminder 90 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Drop Point</th>
                      <th scope="col">Nama PIC</th>
                      <th scope="col">No HP PIC</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
              </TabPanel>
              <TabPanel value={value} index={8}>
                <table className="table table-hover table-responsive-md" id="selesai" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Informan</th>
                      <th scope="col">Tanggal Selesai</th>
                      <th scope="col">Jam Selesai</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">Drop Point</th>
                      <th scope="col">Nama PIC</th>
                      <th scope="col">No HP PIC</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
              </TabPanel>
              <TabPanel value={value} index={9}>
                <table className="table table-hover table-responsive-md" id="selesai_informan" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Selesai</th>
                      <th scope="col">Jam Selesai</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
              </TabPanel>
              <TabPanel value={value} index={10}>
                <Row>
                  <Col>
                    <button type="button" class="btn btn-danger btn-elevate mb-2" onClick={handleClickOpen9} id="delete_all"><i class='fa fa-trash'></i> Hapus Semua Data Pembatalan</button>
                  </Col>
                </Row>
                <table className="table table-hover table-responsive-md" id="dibatalkan" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Dibatalkan</th>
                      <th scope="col">Jam Dibatalkan</th>
                      <th scope="col">Dibatalkan Oleh</th>
                      <th scope="col">Step Dibatalkan</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
              </TabPanel>
              <TabPanel value={value} index={11}>
                <Row>
                  <Col>
                    <button type="button" class="btn btn-danger btn-elevate mb-2" onClick={handleClickOpen10} id="delete_all_informan"><i class='fa fa-trash'></i> Hapus Semua Data Pembatalan</button>
                  </Col>
                </Row>
                <table className="table table-hover table-responsive-md" id="pembatalaninforman" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Pembatalan Oleh</th>
                      <th scope="col">Tanggal Pembatalan</th>
                      <th scope="col">Jam Pembatalan</th>
                      <th scope="col">Step Pembatalan</th>
                      <th scope="col">Nama Informan</th>
                      <th scope="col">No HP Informan</th>
                      <th scope="col">Nopol</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
              </TabPanel>
            </SwipeableViews>
          </div>
        </PortletBody>
      </Portlet>
    </>
    );
  }
