import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { metronic } from "../../_metronic";
import $ from 'jquery';
import API from '../pages/API'
import moment from 'moment'

export default function OrderStatisticsChart(props) {
  const ref = useRef();
  const { brandColor, shape2Color, shape3Color } = useSelector(state => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.brand"
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.2"
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.3"
    )
  }));

  const [date, setDate] = React.useState([]);
  const [dataGraph, setDataGraph] = React.useState([]);

  const data = useMemo(
    () => ({
      labels: date,
      datasets: [
        {
          fill: true,
          // borderWidth: 0,
          backgroundColor: Chart.helpers
            .color(brandColor)
            .alpha(0.6)
            .rgbString(),

          borderColor: Chart.helpers
            .color(brandColor)
            .alpha(0)
            .rgbString(),

          pointHoverRadius: 4,
          pointHoverBorderWidth: 12,
          pointBackgroundColor: Chart.helpers
            .color("#000000")
            .alpha(0)
            .rgbString(),
          pointBorderColor: Chart.helpers
            .color("#000000")
            .alpha(0)
            .rgbString(),
          pointHoverBackgroundColor: brandColor,
          pointHoverBorderColor: Chart.helpers
            .color("#000000")
            .alpha(0.1)
            .rgbString(),

          data: dataGraph
          // data: [1, 20, 90,1, 20, 90,1, 20, 90,1, 20, 90,1, 20, 90]
        },
      ]
    }),
    [brandColor,date]
  );

  useEffect(() => {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    const chart = new Chart(ref.current, {
      data,
      type: "line",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              categoryPercentage: 0.35,
              barPercentage: 0.7,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month"
              },
              gridLines: false,
              ticks: {
                display: true,
                beginAtZero: true,
                fontColor: shape3Color,
                fontSize: 13,
                padding: 10
              }
            }
          ],
          yAxes: [
            {
              categoryPercentage: 0.35,
              barPercentage: 0.7,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value"
              },
              gridLines: {
                color: shape2Color,
                drawBorder: false,
                offsetGridLines: false,
                drawTicks: false,
                borderDash: [3, 4],
                zeroLineWidth: 1,
                zeroLineColor: shape2Color,
                zeroLineBorderDash: [3, 4]
              },
              ticks: {
                max: 120,
                // max: 70,
                stepSize: 10,
                display: true,
                beginAtZero: true,
                fontColor: shape3Color,
                fontSize: 13,
                padding: 10
              }
            }
          ]
        },
        title: {
          display: false
        },
        hover: {
          mode: "ErrorsPage.js"
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "nearest",
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: brandColor,
          titleFontColor: "#ffffff",
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 5,
            bottom: 5
          }
        }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data, brandColor, shape2Color, shape3Color, props.total]);

  const getDaysArray = () => {
    
    const monthIndex = parseInt(moment().format('MM')) - 1
    const date = new Date(moment().format('YYYY'), monthIndex, 1);
    const result = [];
    const resultData = [];

    while (date.getMonth() == monthIndex) {
      result.push(`${date.getDate()}`);
      date.setDate(date.getDate() + 1);
    }

    var pendaftaran = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Financeapi}v1/Voucher_lunas/dataDiagramDashboard`,
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }
    
    $.ajax(pendaftaran).done(function (response){
      console.log(response)
      for (let index2 = 0; index2 < result.length; index2++) {
        for (let index = 0; index < response.data.length; index++) {
          if (index < 1) {
            if (moment(response.data[index].tvoc_created_date).format('DD') == result[index2]) {
              resultData.push(response.data[index].total)
            }else{
              resultData.push(0)
            }
          }
        }
      }
    })
    
    console.log(resultData)
    setDate(result);
    setDataGraph(resultData)
  }

  useEffect(() => {
    getDaysArray()
  },[])

  return (
    <div className="kt-widget12">
      <div className="kt-widget12__content">
        <div className="kt-widget12__item">
          <div className="kt-widget12__info" id="handleRegist">
            <span className="kt-widget12__desc">Jumlah Pendaftaran</span>
            <span className="kt-widget12__value">{props.pendaftaran}</span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Jumlah Data</span>
            <span className="kt-widget12__value">{props.total}</span>
          </div>
        </div>
        <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Jumlah Penemuan</span>
            <span className="kt-widget12__value">{props.penemuan}</span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Jumlah Penemuan Berhasil</span>
            <span className="kt-widget12__value">{props.done}</span>
          </div>
        </div>
      </div>
      <div className="kt-widget12__chart" style={{ height: "250px" }}>
        <h4>Grafik Penemuan Berhasil Bulan Ini</h4>
        <canvas
          ref={ref}
          // width={2000}
          width={683}
          height={312}
          // height={312}
          id="kt_chart_order_statistics"
        />
      </div>
    </div>
  );
}
