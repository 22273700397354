/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React from "react";
import $ from "jquery";
import "datatables.net-bs4";
import API from "../../API";
// import DialogOnboard from './Utils/DialogOnboard'
// import DialogHapusBatal from './Utils/DialogHapusBatal'
// import { AlertList} from "react-bs-notifier";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import Table from "./TableLeasing";
// import Utils from '../Utils/FunctionDetail'
import ExcelPricing from "./pricing_leasing.csv";
import DialogHapusBatal from "../Utils/DialogHapusBatal";
import DialogPricing from "../Utils/DialogPricing";
import { AlertList } from "react-bs-notifier";
import { ProgressBar, Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

const formatter = new Intl.NumberFormat("de-DE");

export default function Pricing(props) {
  React.useEffect(() => {
    $(document).ready(function() {
      if (props.id != null) {
        Table.Pricing(props.id, setAlerts1);
        Table.IncompletePricing(props.id, setAlerts1);
      }
      $(".dataTables_filter,.dataTables_paginate").addClass("pull-right");

      $("#pricing")
        .off("click")
        .on("click", function() {
          $("#pricing").on("click", "#editPricing", function() {
            handleClickOpen3();
            setTprcId($(this).attr("data-id"));
            var settings = {
              async: true,
              crossDomain: true,
              url:
                API.Userapi +
                "v1/pricing/getTprc/" +
                $(this).attr("data-id") +
                "?mmle_id=" +
                props.id,
              method: "GET",
              headers: {
                Authorization: "Baisc " + localStorage.getItem("jwt"),
              },
            };
            $.ajax(settings)
              .done(function(response) {
                console.log(response);
                if (response.data != null) {
                  $("#tprc_ptype").val(response.data.tprc_ptype);
                  $("#tprc_vyear").val(response.data.tprc_vyear);
                  $("#tprc_cwo_wo").val(response.data.tprc_cwo_wo);
                  $("#tprc_base_price").val(
                    formatter.format(parseInt(response.data.tprc_base_price))
                  );
                  $("#tprc_fee").val(formatter.format(parseInt(response.data.tprc_fee)));
                  $("#tprc_bp_stnk").val(formatter.format(parseInt(response.data.tprc_bp_stnk)));
                  $("#tprc_fee_stnk").val(formatter.format(parseInt(response.data.tprc_fee_stnk)));
                }
              })
              .fail(function(response) {
                API.Handle403(response, setAlerts1);
                return 0;
              });
          });

          $("#pricing").on("click", "#hapusPricing", function() {
            setTprcId($(this).attr("data-id"));
            // setTprcPtype($(this).attr('data-type'));
            // setTprcVyear($(this).attr('data-year'));
            // setTprcCwoWo($(this).attr('data-cwowo'));
            handleClickOpen2();
          });

          $("#pricing").on("click", "#hapussemuaPricing", function() {
            handleClickOpen4();
          });
        });
      $("#incomplete_price_table").on("click", "#addInPricing", function() {
        handleClickOpen3();
        $("#tprc_ptype").val($(this).attr("data-ptype"));
        $("#tprc_vyear").val($(this).attr("data-vyear"));
        $("#tprc_cwo_wo").val($(this).attr("data-cwo"));
      });
    });
  });

  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  // const [id, setId] = React.useState(null);
  // const [admin, setAdmin] = React.useState(false);
  // const [hapusid, setHapusId] = React.useState(null);
  const [tprcid, setTprcId] = React.useState(null);
  // const [tprcptype, setTprcPtype] = React.useState(null);
  // const [tprcvyear, setTprcVyear] = React.useState(null);
  // const [tprccwowo, setTprcCwoWo] = React.useState(null);
  const [filepricing, setFilePricing] = React.useState(null);
  const [uploadname, setUploadname] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [alerts1, setAlerts1] = React.useState([]);

  function ToggleUpload() {
    $("#aplodformpricing").toggle("slow");
  }

  function handleClickOpen2() {
    setOpen2(true);
  }

  function handleClose2() {
    setOpen2(false);
  }

  function handleClickOpen3() {
    setOpen3(true);
    setTprcId(null);
    $("#pricedialog input").val("");
  }

  function handleClose3() {
    setOpen3(false);
  }

  function handleClickOpen4() {
    setOpen4(true);
  }

  function handleClose4() {
    setOpen4(false);
  }

  function FilePricing(e) {
    setFilePricing(e.target.files[0]);
  }

  function UploadPricing() {
    var form = new FormData();
    form.append("uploaded_file", filepricing);
    setUploadname(filepricing.name);
    $("#uploading_price").show("slow");
    $("#form_price").hide("slow");

    var settings = {
      xhr: function() {
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function(e) {
          if (e.lengthComputable) {
            console.log(e);
            console.log("Bytes Loaded : " + e.loaded);
            console.log("Total Size : " + e.total);
            console.log("Persen : " + e.loaded / e.total);

            var percent = Math.round((e.loaded / e.total) * 100);
            console.log("Persen : " + percent);

            setProgress(percent);
          }
        });
        return xhr;
      },
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/pricing/upload/" + props.id + "/1",
      method: "POST",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    };

    $.ajax(settings)
      .done(function(response) {
        ToggleUpload();
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Pricing Telah Diupload",
          },
        ]);
        setTimeout(() => {
          $("#uploading_price").hide("slow");
          $("#form_price").show("slow");
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        $("#uploading_price").hide("slow");
        $("#form_price").show("slow");
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
        API.Handle403(response, setAlerts1);
      });
  }

  function HapusPricing() {
    handleClose2();
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/pricing/delTprc/" + tprcid,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Pricing Telah Dihapus",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
        API.Handle403(response, setAlerts1);
      });
  }

  function TambahPricing() {
    handleClose3();
    var tprc_ptype = $("#tprc_ptype").val();
    var tprc_vyear = $("#tprc_vyear").val();
    var tprc_cwo_wo = $("#tprc_cwo_wo").val();
    var tprc_base_price = $("#tprc_base_price").val();
    var tprc_fee = $("#tprc_fee").val();
    var tprc_bp_stnk = $("#tprc_bp_stnk").val();
    var tprc_fee_stnk = $("#tprc_fee_stnk").val();

    var data = {
      tprc_ptype: tprc_ptype,
      tprc_vyear: tprc_vyear,
      tprc_cwo_wo: tprc_cwo_wo,
      tprc_base_price: tprc_base_price,
      tprc_fee: tprc_fee,
      tprc_bp_stnk: tprc_bp_stnk,
      tprc_fee_stnk: tprc_fee_stnk,
      mmle_id: props.id,
    };

    console.log(data);
    var url = API.Userapi + "v1/pricing/addTprc?mmle_id=" + props.id;
    if (tprcid) {
      url = API.Userapi + "v1/pricing/putTprc/" + tprcid + "?mmle_id=" + props.id;
    }

    var settings = {
      async: true,
      crossDomain: true,
      url: url,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
      processData: false,
      data: JSON.stringify(data),
    };
    $.ajax(settings)
      .done(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Pricing Telah Disimpan",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
      })
      .fail(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
        API.Handle403(response, setAlerts1);
      });
  }

  function HapusSemuaPembatalan() {
    handleClose4();

    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "/v1/Pricing/deleteAllPricing/" + props.id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        setAlerts1([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Semua Pembatalan Telah Dihapus",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts1([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts1([]);
        }, 3000);
        API.Handle403(response, setAlerts1);
        return 0;
      });
  }

  return (
    <>
      <DialogPricing
        open={open3}
        close={handleClose3}
        title={tprcid != null ? "Edit Pricing" : "Tambah Pricing"}
        btn={tprcid != null ? "Edit" : "Tambah"}
        fun={TambahPricing}
      />
      <DialogHapusBatal
        open={open2}
        close={handleClose2}
        title="Hapus Pricing Leasing"
        message="Anda Yakin Ingin Hapus Pricing Ini ?"
        btn="Hapus"
        fun={HapusPricing}
        // type="hapusleasing"
        // id={idhapus}
      />
      <DialogHapusBatal
        open={open4}
        close={handleClose4}
        title="Hapus Semua Pricing Leasing"
        message="Anda Yakin Ingin Hapus Semua Pricing ?"
        btn="Hapus"
        fun={HapusSemuaPembatalan}
        // type="hapusleasing"
        // id={idhapus}
      />
      <AlertList showIcon={false} alerts={alerts1} />
      <Portlet>
        <PortletHeader
          title="Pricing"
          toolbar={
            <PortletHeaderToolbar>
              <a href={ExcelPricing} type="button" className="btn btn-label-primary btn-sm">
                <i class="fas fa-file-download"></i>Download Template
              </a>
              <button
                // to="/tambahleasing"
                type="button"
                className="btn btn-label-primary btn-sm mr-1 ml-1"
                onClick={ToggleUpload}
              >
                <i class="fas fa-file-upload"></i>Upload Data
              </button>
              <button
                // to="/tambahleasing"
                onClick={handleClickOpen3}
                type="button"
                className="btn btn-label-primary btn-sm"
              >
                <i class="fas fa-plus"></i>Tambah Data
              </button>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <AlertList showIcon={false} alerts={alerts1} />
          <div class="mb-2" id="aplodformpricing" style={{ display: "none" }}>
            <div id="form_price" className="input-group col-md-12">
              <div class="input-group-prepend">
                <button
                  class="btn btn-warning btn-elevate"
                  type="button"
                  onClick={ToggleUpload}
                  id="cancelaplodpricing"
                >
                  Batal
                </button>
              </div>
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="inputGroupFile04"
                  onChange={FilePricing}
                  aria-describedby="inputGroupFileAddon04"
                />
                <label class="custom-file-label" for="inputGroupFile04">
                  Choose file
                </label>
              </div>
              <div class="input-group-append">
                <button
                  class="btn btn-primary btn-elevate"
                  type="button"
                  onClick={UploadPricing}
                  id="inputGroupFileAddon04"
                >
                  Upload
                </button>
              </div>
            </div>
            <div id="uploading_price" className="col-md-12" style={{ display: "none" }}>
              <Card bg="primary" text="white">
                <Card.Body>
                  <Card.Title>
                    <h3>Mengupload Data</h3>
                  </Card.Title>
                  <hr style={{ borderTop: "1px solid #f4a240" }} />
                  <Card.Text>
                    <h4>
                      Nama File : <span>{uploadname}</span>
                    </h4>
                    <h4>
                      Proses :{" "}
                      <span>
                        {progress}% {progress == 100 ? "(Memproses Di Server)" : ""}
                      </span>
                    </h4>
                    <ProgressBar animated now={progress} variant="warning" />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <Row>
            <Col>
              <button
                type="button"
                class="btn btn-danger btn-elevate mb-2"
                onClick={handleClickOpen4}
                id="delete_all"
              >
                <i class="fa fa-trash"></i> Hapus Semua Data Pembatalan
              </button>
            </Col>
          </Row>
          <table
            className="table table-hover table-responsive-md"
            id="pricing"
            style={{ width: "100%", overflow: "auto" }}
          >
            <thead className="bg-primary" style={{ color: "white" }}>
              <tr>
                <th class="all"> Tipe Kendaraan </th>
                <th class="all"> Tahun Kendaraan </th>
                <th class="all"> Kategori </th>
                <th class="all"> Base Price </th>
                <th class="all"> BP STNK </th>
                <th class="all"> Fee Ke Mitra Eksekutor </th>
                <th class="all"> Fee STNK</th>
                <th class="all"> Aksi </th>
              </tr>
            </thead>
          </table>
        </PortletBody>
      </Portlet>
      <Portlet id="incomplete_price">
        <PortletHeader title="Incomplete Pricing" />
        <PortletBody>
          <table
            className="table table-hover table-responsive-md"
            id="incomplete_price_table"
            style={{ width: "100%", overflow: "auto" }}
          >
            <thead className="bg-primary" style={{ color: "white" }}>
              <tr>
                <th class="all"> Tipe Kendaraan </th>
                <th class="all"> Tahun Kendaraan </th>
                <th class="all"> Kategori </th>
                <th class="all"> Base Price </th>
                <th class="all"> BP STNK </th>
                <th class="all"> Fee Ke Mitra Eksekutor </th>
                <th class="all"> Fee STNK </th>
                <th class="all"> Aksi </th>
              </tr>
            </thead>
          </table>
        </PortletBody>
      </Portlet>
    </>
  );
}
