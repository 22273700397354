/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../partials/content/Portlet";
import PropTypes from 'prop-types';
import { makeStyles, 
  // withStyles, 
  useTheme  } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import {Tabs as Tabs2, Tab as Tab2} from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import $ from 'jquery';
import 'datatables.net-bs4';
// import Slide from '@material-ui/core/Slide';
import SwipeableViews from 'react-swipeable-views';
import Table from './Penemuan/TablePenemuan'
import DetailLaporanPenemuan from './Utils/DetailLaporanPenemuan'
import DetailLaporanInforman from './Utils/DetailLaporanInforman'
import DetailLaporanPembatalanInforman from './Utils/DetailLaporanPembatalanInforman'
// import DetailInforman from './Utils/DetailInforman'
// import DetailNego from './Utils/DetailNego'
import {Badge} from 'react-bootstrap'
import Grid from '@material-ui/core/Grid';
import { AlertList} from "react-bs-notifier";
import moment from 'moment'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },  
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

  export default function Penemuan() {

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    // const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [step, setStep] = React.useState(null);
    const [by, setBy] = React.useState(null);
    const [alerts, setAlerts] = React.useState([]);

    useEffect(() => {

        $(document).ready(function () {

          $(function() {
            $('#vldfrom,#vldto').datepicker({
              todayHighlight: true,
              autoclose: true,
              format: "yyyy-mm-dd",
            })
          });
          // console.log(moment($('#datepicker').datepicker('getDate')).format('YYYY-MM-DD'))
          $('#vldfrom').val(moment().format('YYYY-MM-DD'))
          $('#vldto').val(moment().format('YYYY-MM-DD'))
          $(document).on('click','#detail',function () {
            handleClickOpen()
            setId($(this).attr('data-id'))
            setStep($(this).attr('data-step'))
          });

          $(document).on('click','#informan',function () {
            handleClickOpen3()
            $('#tpif_vtype').val($(this).attr('data-unit'))
            setId($(this).attr('data-id'))
            setStep($(this).attr('data-step'))
          });

          $(document).on('click','#nego',function () {
            // handleClickOpen2()
            setId($(this).attr('data-id'))
            setStep($(this).attr('data-step'))
          });

          $(document).on('click','#batalinforman',function () {
            handleClickOpen4()
            setId($(this).attr('data-id'))
            setStep($(this).attr('data-step'))
            setBy($(this).attr('data-by'))
          });

        })
    });

    const handleChangeIndex = index => {
      setValue(index);
    };

    function handleClickOpen() {
      setOpen(true);
    }

    function handleClose() {
      setOpen(false);
    }

    // function handleClickOpen2() {
    //   setOpen2(true);
    // }

    // function handleClose2() {
    //   setOpen2(false);
    // }

    function handleClickOpen3() {
      setOpen3(true);
    }

    function handleClose3() {
      setOpen3(false);
    }

    function handleClickOpen4() {
      setOpen4(true);
    }

    function handleClose4() {
      setOpen4(false);
    }

    function FilterLaporanPenemuan() {
      if( $('#vldfrom').val() == 0 || $('#vldto').val() == 0){
        return 0 ;
      }
      
      var from = moment($('#vldfrom').val()).format('MM-DD-YYYY')
      var to = moment($('#vldto').val()).format('MM-DD-YYYY')

      Table.LPValidasiInforman(from,to)
      Table.LPMatchmaking(from,to)
      Table.LPMatched(from,to)
      Table.LPValidasi(from,to)
      Table.LPMenungguSuratTugas(from,to)
      Table.LPSuratTugasDiterima(from,to)
      Table.LPPengantaran(from,to)
      Table.LPDalamPengantaran(from,to)
      Table.LPSelesai(from,to)
      Table.LPDibatalkan(from,to)
      Table.LPPembatalanInforman(from,to)
      Table.LPTotal(from,to,setAlerts)
      Table.LPTotalTpif(from,to,setAlerts)
      $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
    }

    return (
    <>
      <AlertList  showIcon={false} alerts={alerts}/>
      <DetailLaporanPenemuan open={open} close={handleClose} id={id} step={step}/>
      <DetailLaporanInforman open={open3} close={handleClose3} id={id} step={step}/>
      <DetailLaporanPembatalanInforman open={open4} close={handleClose4} id={id} step={step} by={by}/>
      <Portlet>
            <PortletHeader
                title="Filter Tanggal"
            >
            </PortletHeader>
                <PortletBody>
                    <Grid container spacing={2}>
                        <Grid item xs>
                        </Grid>
                        <Grid item xs={6} className="text-center">
                            <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                                <input type="text" className="form-control" name="start" id="vldfrom" autocomplete="off"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">To</span>
                                </div>
                                <input type="text" className="form-control" name="end" id="vldto" autocomplete="off"/>
                            </div>
                            <button type="button" class="btn btn-brand btn-elevate btn-sm" onClick={FilterLaporanPenemuan} id="cari" style={{width:'100%'}}>Set Filter</button>
                        </Grid>
                        <Grid item xs>
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet>
      <Portlet>
        <PortletBody>
                      <Tabs2 defaultActiveKey="Tab4" id="uncontrolled-tab-example">
                        <Tab2 eventKey="Tab1" title={<div style={{display:'inline'}}>Validasi Informan <Badge pill variant="primary" id="totalValidasiTpif"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="laporanvalidasiinforman" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Tanggal Validasi</th>
                                  <th scope="col">Jam Validasi</th>
                                  <th scope="col">Reminder 45 Menit</th>
                                  <th scope="col">Nama Informan</th>
                                  <th scope="col">No HP Informan</th>
                                  <th scope="col">Leasing</th>
                                  <th scope="col">Cabang</th>
                                  <th scope="col">Nopol</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab2" title={<div style={{display:'inline'}}>Matchmaking <Badge pill variant="primary" id="totalMatchmaking"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="matchmaking" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Tanggal Validasi</th>
                                  <th scope="col">Jam Validasi</th>
                                  <th scope="col">Reminder 45 Menit</th>
                                  <th scope="col">Nama Informan</th>
                                  <th scope="col">No HP Informan</th>
                                  <th scope="col">Leasing</th>
                                  <th scope="col">Cabang</th>
                                  <th scope="col">Nopol</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab3" title={<div style={{display:'inline'}}>Matched <Badge pill variant="primary" id="totalMatched"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <Tabs2 defaultActiveKey="Tab1" id="uncontrolled-tab-example">
                              <Tab2 eventKey="Tab1" title={<div style={{display:'inline'}}>Mitra Ditemukan <Badge pill variant="primary" id="totalMatched1"></Badge></div>}>
                                <table className="table table-hover table-responsive-md" id="lpmatched2" style={{width:'100%'}}>
                                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                    <tr>
                                      <th scope="col">Tipe</th>
                                      <th scope="col">Tanggal Matched</th>
                                      <th scope="col">Jam Matched</th>
                                      <th scope="col">Reminder 45 Menit</th>
                                      <th scope="col">Nama Informan</th>
                                      <th scope="col">No HP Informan</th>
                                      <th scope="col">Nama Mitra</th>
                                      <th scope="col">No HP Mitra</th>
                                      <th scope="col">Leasing</th>
                                      <th scope="col">Cabang</th>
                                      <th scope="col">Nopol</th>
                                      <th scope="col">Unit</th>
                                      <th scope="col">Jarak Mitra Ke Informan</th>
                                      <th scope="col">Aksi</th>
                                    </tr>
                                  </thead>
                                </table>
                              </Tab2>
                              <Tab2 eventKey="Tab2" title={<div style={{display:'inline'}}>Mitra Menuju Informan <Badge pill variant="primary" id="totalMatched2"></Badge></div>}>
                                <table className="table table-hover table-responsive-md" id="lpmatched1" style={{width:'100%'}}>
                                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                    <tr>
                                      <th scope="col">Tipe</th>
                                      <th scope="col">Tanggal Matched</th>
                                      <th scope="col">Jam Matched</th>
                                      <th scope="col">Reminder 45 Menit</th>
                                      <th scope="col">Nama Informan</th>
                                      <th scope="col">No HP Informan</th>
                                      <th scope="col">Nama Mitra</th>
                                      <th scope="col">No HP Mitra</th>
                                      <th scope="col">Leasing</th>
                                      <th scope="col">Cabang</th>
                                      <th scope="col">Nopol</th>
                                      <th scope="col">Unit</th>
                                      <th scope="col">Jarak Mitra Ke Informan</th>
                                      <th scope="col">Aksi</th>
                                    </tr>
                                  </thead>
                                </table>
                              </Tab2>
                            </Tabs2>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab4" title={<div style={{display:'inline'}}>Validasi Mitra <Badge pill variant="primary" id="totalValidasi"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="validasi" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                    <th scope="col">Tipe</th>
                                    <th scope="col">Informan</th>
                                    <th scope="col">Tanggal Validasi</th>
                                    <th scope="col">Jam Validasi</th>
                                    <th scope="col">Reminder 45 Menit</th>
                                    <th scope="col">Nama Mitra</th>
                                    <th scope="col">No HP Mitra</th>
                                    <th scope="col">Leasing</th>
                                    <th scope="col">Cabang</th>
                                    <th scope="col">Nomor Polisi</th>
                                    <th scope="col">Unit</th>
                                    <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                            <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab5" title={<div style={{display:'inline'}}>Menunggu Surat Tugas <Badge pill variant="primary" id="totalMenungguSuratTugas"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="menunggusurattugas" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'10px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Informan</th>
                                  <th scope="col">Tanggal Menunggu Surat Tugas</th>
                                  <th scope="col">Jam Menunggu Surat Tugas</th>
                                  <th scope="col">Reminder 45 Menit</th>
                                  <th scope="col">Nama Mitra</th>
                                  <th scope="col">No HP Mitra</th>
                                  <th scope="col">Leasing</th>
                                  <th scope="col">Cabang</th>
                                  <th scope="col">Nomor Polisi</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                            <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab6" title={<div style={{display:'inline'}}>Surat Tugas Diterima <Badge pill variant="primary" id="totalSuratTugasDiterima"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="surattugasditerima" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Informan</th>
                                  <th scope="col">Tanggal Surat Tugas Diterima</th>
                                  <th scope="col">Jam Surat Tugas Diterima</th>
                                  <th scope="col">Reminder 45 Menit</th>
                                  <th scope="col">Nama Mitra</th>
                                  <th scope="col">No HP Mitra</th>
                                  <th scope="col">Leasing</th>
                                  <th scope="col">Cabang</th>
                                  <th scope="col">Nomor Polisi</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Nego Status</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                            <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab7" title={<div style={{display:'inline'}}>Pengantaran <Badge pill variant="primary" id="totalPengantaran"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="pengantaran" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Informan</th>
                                  <th scope="col">Tanggal Pengantaran</th>
                                  <th scope="col">Jam Pengantaran</th>
                                  <th scope="col">Reminder 45 Menit</th>
                                  <th scope="col">Nama Mitra</th>
                                  <th scope="col">No HP Mitra</th>
                                  <th scope="col">Leasing</th>
                                  <th scope="col">Cabang</th>
                                  <th scope="col">Nomor Polisi</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Nego Status</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                            <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab8" title={<div style={{display:'inline'}}>Dalam Pengantaran <Badge pill variant="primary" id="totalDalamPengataran"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="dalampengantaran" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Informan</th>
                                  <th scope="col">Tanggal Dalam Pengantaran</th>
                                  <th scope="col">Jam Dalam Pengantaran</th>
                                  <th scope="col">Reminder 90 Menit</th>
                                  <th scope="col">Nama Mitra</th>
                                  <th scope="col">No HP Mitra</th>
                                  <th scope="col">Drop Point</th>
                                  <th scope="col">Nama PIC</th>
                                  <th scope="col">No HP PIC</th>
                                  <th scope="col">Leasing</th>
                                  <th scope="col">Cabang</th>
                                  <th scope="col">Nomor Polisi</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Nego Status</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                            <button type="button" class="btn btn-default" style={{background:"#FFEA00"}}></button><span style={{color:"black"}}> : Telah lewat batas waktu </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab9" title={<div style={{display:'inline'}}>Selesai <Badge pill variant="primary" id="totalSelesai"></Badge></div>}>
                         <div style={{overflow:'auto'}}>
                          <table className="table table-hover table-responsive-md" id="selesai" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Informan</th>
                                  <th scope="col">Tanggal Selesai</th>
                                  <th scope="col">Jam Selesai</th>
                                  <th scope="col">Nama Mitra</th>
                                  <th scope="col">Drop Point</th>
                                  <th scope="col">Nama PIC</th>
                                  <th scope="col">No HP PIC</th>
                                  <th scope="col">Leasing</th>
                                  <th scope="col">Cabang</th>
                                  <th scope="col">Nomor Polisi</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Nego Status</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                         </div>
                        </Tab2>
                        <Tab2 eventKey="Tab10" title={<div style={{display:'inline'}}>Dibatalkan <Badge pill variant="primary" id="totalCancel"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="dibatalkan" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Informan</th>
                                  <th scope="col">Tanggal Dibatalkan</th>
                                  <th scope="col">Jam Dibatalkan</th>
                                  {/* <th scope="col">Dibatalkan Oleh</th> */}
                                  <th scope="col">Step Dibatalkan</th>
                                  <th scope="col">Nama Mitra</th>
                                  <th scope="col">No HP</th>
                                  <th scope="col">Nomor Polisi</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Nego Status</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                          </div>
                        </Tab2>
                        <Tab2 eventKey="Tab11" title={<div style={{display:'inline'}}>Pembatalan Informan <Badge pill variant="primary" id="totalPembatalanInforman"></Badge></div>}>
                          <div style={{overflow:'auto'}}>
                            <table className="table table-hover table-responsive-md" id="pembatalanInfroman" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  {/* <th scope="col">Informan</th> */}
                                  <th scope="col">Pembatalan Oleh</th>
                                  <th scope="col">Tanggal Pembatalan</th>
                                  <th scope="col">Jam Pembatalan</th>
                                  <th scope="col">Step Pembatalan</th>
                                  <th scope="col">Nama Mitra</th>
                                  <th scope="col">No HP Mitra</th>
                                  <th scope="col">Nopol</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                              </thead>
                            </table>
                            <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                            <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                          </div>
                        </Tab2>
                      </Tabs2>
          <div className={classes.root}>
            {/* <AppBar position="static" color="primary">
              <AntTabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable"
                scrollButtons="auto" aria-label="scrollable auto tabs example">
                <AntTab label={<div style={{display:'inline'}}>Validasi Informan <Badge pill variant="warning" id=""></Badge></div>} {...a11yProps(0)}/>
                <AntTab label={<div style={{display:'inline'}}>Matchmaking <Badge pill variant="warning" id=""></Badge></div>} {...a11yProps(1)}/>
                <AntTab label={<div style={{display:'inline'}}>Matched <Badge pill variant="warning" id=""></Badge></div>} {...a11yProps(2)}/>
                <AntTab label={<div style={{display:'inline'}}>Validasi <Badge pill variant="warning" id="totalValidasi"></Badge></div>} {...a11yProps(3)}/>
                <AntTab label={<div style={{display:'inline'}}>Menunggu Surat Tugas <Badge pill variant="warning" id="totalMenungguSuratTugas"></Badge></div>} {...a11yProps(4)} />
                <AntTab label={<div style={{display:'inline'}}>Surat Tugas Diterima <Badge pill variant="warning" id="totalSuratTugasDiterima"></Badge></div>} {...a11yProps(5)} />
                <AntTab label={<div style={{display:'inline'}}>Pengantaran <Badge pill variant="warning" id="totalPengantaran"></Badge></div>} {...a11yProps(6)} />
                <AntTab label={<div style={{display:'inline'}}>Dalam Pengantaran <Badge pill variant="warning" id="totalDalamPengantaran"></Badge></div>} {...a11yProps(7)} />
                <AntTab label={<div style={{display:'inline'}}>Selesai <Badge pill variant="warning" id="totalSelesai"></Badge></div>} {...a11yProps(8)} />
                <AntTab label={<div style={{display:'inline'}}>Dibatalkan <Badge pill variant="warning" id="totalCancel"></Badge></div>} {...a11yProps(9)} />
                <AntTab label={<div style={{display:'inline'}}>Pembatalan Informan <Badge pill variant="warning" id=""></Badge></div>} {...a11yProps(10)} />
              </AntTabs>
            </AppBar> */}
            <SwipeableViews axis={theme.direction==='rtl' ? 'x-reverse' : 'x' } index={value}
              onChangeIndex={handleChangeIndex}>
              {/* <TabPanel value={value} index={0}>
                <table className="table table-hover table-responsive-md" id="validasiinforman" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Validasi</th>
                      <th scope="col">Jam Validasi</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Informan</th>
                      <th scope="col">No HP Informan</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nopol</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <table className="table table-hover table-responsive-md" id="matchmaking" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Validasi</th>
                      <th scope="col">Jam Validasi</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Informan</th>
                      <th scope="col">No HP Informan</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nopol</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <table className="table table-hover table-responsive-md" id="matched" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Matched</th>
                      <th scope="col">Jam Matched</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Informan</th>
                      <th scope="col">No HP Informan</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nopol</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Jarak Mitra Ke Informan</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <table className="table table-hover table-responsive-md" id="validasi" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Validasi</th>
                      <th scope="col">Jam Validasi</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <table className="table table-hover table-responsive-md" id="menunggusurattugas" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'10px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Menunggu Surat Tugas</th>
                      <th scope="col">Jam Menunggu Surat Tugas</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <table className="table table-hover table-responsive-md" id="surattugasditerima" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col" style={{minWidth:'120px'}}>Tanggal Surat Tugas Diterima</th>
                      <th scope="col" style={{minWidth:'120px'}}>Jam Surat Tugas Diterima</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <table className="table table-hover table-responsive-md" id="pengantaran" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Pengantaran</th>
                      <th scope="col">Jam Pengantaran</th>
                      <th scope="col">Reminder 45 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <table className="table table-hover table-responsive-md" id="dalampengantaran" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Dalam Pengantaran</th>
                      <th scope="col">Jam Dalam Pengantaran</th>
                      <th scope="col">Reminder 90 Menit</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Drop Point</th>
                      <th scope="col">Nama PIC</th>
                      <th scope="col">No HP PIC</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={8}>
                <table className="table table-hover table-responsive-md" id="selesai" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Selesai</th>
                      <th scope="col">Jam Selesai</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">Drop Point</th>
                      <th scope="col">Nama PIC</th>
                      <th scope="col">No HP PIC</th>
                      <th scope="col">Leasing</th>
                      <th scope="col">Cabang</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={9}>
                <table className="table table-hover table-responsive-md" id="dibatalkan" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Tanggal Dibatalkan</th>
                      <th scope="col">Jam Dibatalkan</th>
                      <th scope="col">Step Dibatalkan</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP</th>
                      <th scope="col">Nomor Polisi</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Nego Status</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel>
              <TabPanel value={value} index={10}>
                <table className="table table-hover table-responsive-md" id="pembatalaninfroman" style={{width:'100%'}}>
                  <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                    <tr>
                      <th scope="col">Penemuan</th>
                      <th scope="col">Tipe</th>
                      <th scope="col">Pembatalan Oleh</th>
                      <th scope="col">Tanggal Pembatalan</th>
                      <th scope="col">Jam Pembatalan</th>
                      <th scope="col">Step Pembatalan</th>
                      <th scope="col">Nama Mitra</th>
                      <th scope="col">No HP Mitra</th>
                      <th scope="col">Nopol</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                </table>
              </TabPanel> */}
            </SwipeableViews>
          </div>
        </PortletBody>
      </Portlet>
    </>
    );
  }
