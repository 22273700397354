import React, { useEffect, useState } from "react";
import { Portlet, PortletHeader, PortletBody } from "../../partials/content/Portlet";
import API from "../API";
import $ from "jquery";
import "datatables.net-bs4";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

const formatter = new Intl.NumberFormat("currency");
const getInt = (value) => {
  return value === null ? 0 : parseInt(value);
};

const LaporanPendapatan = () => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    $(document).ready(function() {
      FilterLaporanPenemuan();
      $(function() {
        $("#vldto,#vldfrom").datepicker({
          todayHighlight: true,
          autoclose: true,
          format: "yyyy-mm-dd",
        });
      });
      $("#vldfrom,#vldto").val(moment().format("YYYY-MM-DD"));
    });
  });

  const TableJumlahPenemuan = (from, to) => {
    $("#table-laporan-pendapatan").DataTable({
      processing: true,
      serverSide: true,
      destroy: true,
      dom:
        "<'row'<'col-sm-12 col-md-5'l><'col-sm-12 col-md-7'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      lengthMenu: [
        [5, 10, 15, 20, -1],
        [5, 10, 15, 20, "All"],
      ],
      buttons: [
        {
          extend: "excelHtml5",
          className: "btn btn-primary mt-4",
          text: '<i class="fas fa-file-excel"></i>Export Excel',
          titleAttr: "Export Excel",
          customize: function(xlsx, row) {
            var sheet = xlsx.xl.worksheets["sheet1.xml"];
            $('row c[r^="L"], row c[r^="Q"]', sheet).attr("s", 63);
          },
        },
      ],
      ajax: {
        url: API.Financeapi + `v1/LaporanPendapatan/index?from=${from}&to=${to}&length=full`,

        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      columns: [
        { data: "tvoc_created_date" },
        { data: "koordinator_mper_name" },
        { data: "mmle_name" },
        { data: "tpnm_vplate" },
        { data: "tpnm_type" },
        {
          data: "tvoc_status_stnk",
          render: function(data, row, type) {
            return parseInt(data) === 1 ? "Ada" : "Tidak Ada";
          },
        },
        {
          data: "total_paid_mitra",
          render: function(data, row, type) {
            data = data === null ? 0 : data;
            return formatter.format(parseInt(data));
          },
        },
        {
          data: "total_paid_informan",
          render: function(data, row, type) {
            data = data === null ? 0 : data;
            return formatter.format(parseInt(data));
          },
        },
        {
          data: "tvoc_biaya_kirim_estimasi",
          render: function(data, row, type) {
            data = data === null ? 0 : data;
            return formatter.format(parseInt(data));
          },
        },
        {
          data: "tvoc_biaya_kirim_real",
          render: function(data, row, type) {
            data = data === null ? 0 : data;
            return formatter.format(parseInt(data));
          },
        },
        {
          render: function(data, type, row) {
            var selisih_biaya_kirim = row.tvoc_biaya_kirim_estimasi - row.tvoc_biaya_kirim_real;
            return formatter.format(parseInt(selisih_biaya_kirim));
          },
        },
        {
          data: "cadangan_korwil",
          render: function(data, row, type) {
            data = data === null ? 0 : data;
            return formatter.format(parseInt(data));
          },
        },
        {
          data: "total",
          render: function(data, type, row) {
            return formatter.format(getInt(data));
          },
        },
        {
          data: "tpnm_base_price",
          render: function(data, type, row) {
            return formatter.format(getInt(data));
          },
        },
        {
          render: function(data, type, row) {
            var margin = row.tpnm_base_price - row.total - row.pendapatan_lain_lain;
            return formatter.format(getInt(margin));
          },
        },
        {
          render: function(data, type, row) {
            var margin =
              ((row.tpnm_base_price - row.total - row.pendapatan_lain_lain) / row.tpnm_base_price) *
              100;
            return formatter.format(margin.toFixed(2)) + "%";
          },
        },
        {
          data: "pendapatan_lain_lain",
          render: function(data, type, row) {
            return formatter.format(getInt(data));
          },
        },
      ],
    });
  };

  function FilterLaporanPenemuan() {
    if ($("#vldfrom").val() == 0 || $("#vldto").val() == 0) {
      return 0;
    }

    var from = moment($("#vldfrom").val()).format("MM-DD-YYYY");
    var to = moment($("#vldto").val()).format("MM-DD-YYYY");

    TableJumlahPenemuan(from, to);

    $(".dataTables_filter,.dataTables_paginate")
      .addClass("pull-right")
      .css("float", "right");
  }

  return (
    <>
      <Portlet>
        <PortletHeader title="Filter Tanggal"></PortletHeader>
        <PortletBody>
          <Grid container spacing={2}>
            <Grid item xs></Grid>
            <Grid item xs={6} className="text-center">
              <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                <input
                  type="text"
                  className="form-control"
                  name="start"
                  id="vldfrom"
                  autocomplete="off"
                />
                <div className="input-group-append">
                  <span className="input-group-text">To</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="end"
                  id="vldto"
                  autocomplete="off"
                />
              </div>
              <button
                type="button"
                class="btn btn-brand btn-elevate btn-sm"
                onClick={FilterLaporanPenemuan}
                id="cari"
                style={{ width: "100%" }}
              >
                Set Filter
              </button>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </PortletBody>
      </Portlet>
      <Portlet>
        <PortletBody>
          <div style={{ overflow: "auto" }}>
            <table
              className="table table-hover table-responsive-md"
              id="table-laporan-pendapatan"
              style={{ width: "100%" }}
            >
              <thead className="bg-primary" style={{ color: "white", fontSize: "12px" }}>
                <tr>
                  <th scope="col">Tanggal</th>
                  <th scope="col">Nama Korwil</th>
                  <th scope="col">Nama Leasing</th>
                  <th scope="col">Nopol</th>
                  <th scope="col">Type Motor</th>
                  <th scope="col">STNK</th>
                  <th scope="col">Total Paid Mitra</th>
                  <th scope="col">Total Paid Informan</th>
                  <th scope="col">Cadangan Biaya Kirim</th>
                  <th scope="col">Biaya Kirim</th>
                  <th scope="col">Margin Biaya Kirim</th>
                  <th scope="col">Titipan Korwil</th>
                  <th scope="col">Total Pengeluaran</th>
                  <th scope="col">Total Tagih</th>
                  <th scope="col">Margin</th>
                  <th scope="col">%</th>
                  <th scope="col">Pendapatan Lain Lain</th>
                </tr>
              </thead>
            </table>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default LaporanPendapatan;
