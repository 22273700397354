/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import API from '../../API'
import $ from 'jquery'
import moment from 'moment'
// import { AlertList} from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
}));

export default function DialogDropPoint(props) {

    const classes = useStyles();

    React.useEffect(() => {
      $('#dp_valid_from1,#dp_valid_to1').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: "yyyy-mm-dd",
      });
      $('#dp_valid_from1').on('change',function () {
        console.log(moment($('#dp_valid_from1').datepicker('getDate')).add(1, 'years').toDate())
        $('#dp_valid_to1').datepicker('setDate', moment($('#dp_valid_from1').datepicker('getDate')).add(1, 'years').toDate())
        
    })
    })

    return (
      <div>
          <Dialog
              open={props.open}
              TransitionComponent={Transition}
              fullWidth={true}
              maxWidth="sm"
              keepMounted
              onClose={props.close}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              style={{zIndex:'999999999'}}
          >
          <DialogTitle className="dptitle" id="alert-dialog-slide-title">Drop Point</DialogTitle>
          <DialogContent>
            <form className={classes.root} id="dpleasing" autoComplete="off">
              <div className="form-group row" style={{display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Kode</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_code1" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Drop Point</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_name1" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Status</label>
                <div className="col-sm-8">
                  <div className="form-check form-check-inline mt-1">
                      <input className="form-check-input" type="radio" name="tadp_status1" id="tadp_status0" value="0"/>
                      <label className="form-check-label" for="exampleRadios1">
                          Tidak Aktif
                      </label>
                  </div>
                  <div className="form-check form-check-inline mt-1">
                      <input className="form-check-input" type="radio" name="tadp_status1" id="tadp_status1" value="1"/>
                      <label className="form-check-label" for="exampleRadios1">
                          Aktif
                      </label>
                  </div>
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Lat</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_lat1" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Lng</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_lng1" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Valid</label>
                <div className="col-sm-8">
                  <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                      <input type="text" className="form-control" name="start" id="dp_valid_from1" autocomplete="off"/>
                      <div className="input-group-append">
                          <span className="input-group-text">To</span>
                      </div>
                      <input type="text" className="form-control" name="end" id="dp_valid_to1" autocomplete="off"/>
                  </div>
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Alamat</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" id="tadp_address1" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Jam Buka</label>
                <div className="col-sm-8">
                  <input type="time" className="form-control" id="tadp_open_time1" />
                </div>
              </div>
              <div className="form-group row" style={{marginTop:'-15px',display:props.bonus}}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">Jam Tutup</label>
                <div className="col-sm-8">
                  <input type="time" className="form-control" id="tadp_closed_time1" />
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close} color="primary">
              Batal
            </Button>
            <Button onClick={props.fun} color="primary">
              Simpan
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      );

}

