/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React, {useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
  } from "../../partials/content/Portlet";
import API from '../API'
// import { makeStyles } from '@material-ui/core/';
// import Slide from '@material-ui/core/Slide';
// import { Link } from "react-router-dom";
// import {Row, Col} from 'react-bootstrap';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AlertList} from "react-bs-notifier";
import DetailUser from './Utils/DetailUser'
import EditUser from './User/EditUser'
import DialogHapusBatal from './Utils/DialogHapusBatal'
import Utils from './Utils/FunctionDetail'
import NoImage from "./Leasing/noimage.jpg";
// const useStyles = makeStyles(theme => ({
//     appBar: {
//         position: 'relative',
//     },
//     title: {
//         marginLeft: theme.spacing(2),
//         flex: 1,
//     },
// }));

export default function User() {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [idhapus, setIdhapus] = React.useState(null);
    const [idreset, setIdreset] = React.useState(null);
    const [idlogin, setIdlogin] = React.useState(null);
    const [idtoken, setIdToken] = React.useState(null);
    const [alerts, setAlerts] = React.useState([]);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleClickOpen2() {
        setOpen2(true);
    }

    function handleClose2() {
        setOpen2(false);
    }

    function handleClickOpen3() {
        setOpen3(true);
    }

    function handleClose3() {
        setOpen3(false);
    }

    function handleClickOpen4() {
        setOpen4(true);
    }

    function handleClose4() {
        setOpen4(false);
    }

    function handleClickOpen5() {
        setOpen5(true);
    }

    function handleClose5() {
        setOpen5(false);
    }

    function handleClickOpen6() {
        setOpen6(true);
    }

    function handleClose6() {
        setOpen6(false);
    }

    function ResetUser() {
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/User/reset_password/"+idreset,
            "method": "GET",
            "headers": {
              "Authorization": "Basic " + localStorage.getItem('jwt'),
            }
          }
      
        $.ajax(settings).done(function (response) {
            handleClose3()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Pin User Telah Direset"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose3()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

    function UpdateNopol() {
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"/v1/User/editLastLoginDate/"+idlogin,
            "method": "POST",
            "headers": {
              "Authorization": "Basic " + localStorage.getItem('jwt'),
            }
          }
      
        $.ajax(settings).done(function (response) {
            handleClose5()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Data Nopol User Telah Diupdate"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose5()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

    function CopyToken() {
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Authapi+"/v1/Version/getToken/"+idtoken,
            "method": "GET",
            "headers": {
              "Authorization": "Basic " + localStorage.getItem('jwt'),
            }
          }
      
        $.ajax(settings).done(function (response) {
            handleClose6()
            console.log(response)
            var text = response.data.tokn_hash
            const textarea = document.createElement('textarea');
            document.body.appendChild(textarea);
            textarea.value = text;
            textarea.select();
            textarea.setSelectionRange(0, 99999);
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Token User Berhasil Di Copy"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose6()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

    function HapusUser() {
        console.log(idreset)
        console.log(idhapus)
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/user/delete/"+idhapus,
            "method": "GET",
            "headers": {
                "Authorization": "Baisc " + localStorage.getItem('jwt')
            }
        }
        $.ajax(settings).done(function (response) {
            handleClose4()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "User Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose4()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

    useEffect(() => {
        $(document).ready(function () {

        if ($.fn.DataTable.isDataTable("#user")) {
            $('#user').DataTable().clear().destroy();
            }

            $('#user').DataTable({
                "pagingType": "full_numbers",
            //  "aaSorting": [],
            //  dom: '<"top"lf>Brt<"bottom"ip><"clear">',
                buttons: [{
                    extend: 'excel',
                    className: 'btn blue btn-outline',
                    text: '<i class="fa fa-download">&nbspExcel</i>',
                    titleAttr: "Export Excel"
                }],
                "lengthMenu": [
                    [5, 10, 15, 20, -1],
                    [5, 10, 15, 20, "All"]
                ],
                "pageLength": 10,
                "processing": true,
                "serverSide": true,
                "ajax": {
                    "url": API.Userapi + "v1/user/index",
                    "type": 'get',
                    "beforeSend": function (xhr) {
                        xhr.setRequestHeader('Authorization',
                            "Basic " + localStorage.getItem('jwt'));
                    },
                    error: function (xhr, error, thrown, settings) {
                     API.Handle403(xhr,setAlerts);
                    }
                },
                "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    if (aData.app_type == "mobil") {
                        $('td:eq(1)', nRow).css('background-color', '#AFEEEE');
                    } else if (aData.app_type == "motor") {
                        $('td:eq(1)', nRow).css('background-color', '#F4A460');
                    }
                    if (aData.app_type == "mobil") {
                        $('td:eq(1)', nRow).css('background-color', '#AFEEEE');
                    }
                    if (aData.mper_status == "Non Aktif") {
                        $('td:eq(7)', nRow).css('background-color', '#EF5350');
                    }
                },
                "columns": [
                    {
                        sortable: true, 
                        "render": function ( data, type, full, meta ) {
                          if ( full.mper_medi_profile == null){
                            return "<img src='"+NoImage+"' style='height:70px;weight:50px;'>";
                        }else{
                            return  "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+full.mper_medi_profile+">"+
                            "<img src="+full.mper_medi_profile+" style='height:70px;width:70px;border:1px solid #c5c8f0;border-radius:10px;'>"+
                          "</a>"
                        }
                      }
                    },
                    {
                        "data": "mper_id"
                    },
                    {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                        // return " <div >"+getStatusMperAll(full.mper_type, full.mper_id)+"</div>";
                        return " <div >" + Utils.StatusAppType(full.app_type) + "</div>";
                    }
                    },
                    {
                        "data": "mper_created_date"
                    },
                    {
                        "data": "mper_ic_number"
                    },
                    {
                        "data": "mper_name"
                    },
                    {
                        "data": "mper_phone"
                    },
                    {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                        // return " <div >"+getStatusMperAll(full.mper_type, full.mper_id)+"</div>";
                        return " <div >" + full.mper_type + "</div>";
                    }
                    },
                    {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            // return " <div >"+getStatusUser(full.mper_status)+"</div>";
                            return " <div >" + full.mper_status + "</div>";
                        }
                    },
                    {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            return full.mper_valid_from + " - " + full.mper_valid_to;
                        }
                    },
                    {
                    "data": "mper_last_login_date"
                    },
                    {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            if (full.mper_type == "Leasing Cabang" ||
                                full.mper_type == "Leasing Cabang & Eksekutor" ||
                                full.mper_type == "Leasing Admin" ||
                                full.mper_type == "Leasing Approver" ||
                                full.mper_type == "Admin Finance"
                            ) {
                            //  return (
                            //      "<div id='aksi'>" +
                            //      "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='reset' data-id=" + full.mper_id +
                            //      " data-toggle='tooltip' title='Reset Password'><i class='fas fa-lock'> </i> </button>" +
                            //      "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='edit' data-id=" + full.mper_id +
                            //      " data-original-title='Edit User'><i class='fa fa-edit'> </i> </button>" +
                            //      "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id=" + full.mper_id +
                            //      " data-original-title='Detail User'> <i class='fa fa-eye'> </i> </button>" +
                            //      "<button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='hapus' data-id=" + full.mper_id +
                            //      "><i class='fa fa-trash'> </i></button>" +
                            //      "</div>"
                            //  )
                                return `
                                <button id="user1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="user1">
                                    <button class='dropdown-item' id='reset' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fas fa-lock'></i>Reset Pin User</button>
                                    <button class='dropdown-item' id='edit' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-edit'></i>Edit User</button>
                                    <button class='dropdown-item' id='detail' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail User</button>
                                    <button class='dropdown-item' id='token' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fas fa-copy'></i>Copy Token</button>
                                    <button class='dropdown-item' id='hapus' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus User</button>
                                </div>`;
                            } else if (full.mper_type == "Eksekutor") {
                            return `
                                <button id="user2" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="user2">
                                    <button class='dropdown-item' id='reset' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fas fa-lock'></i>Reset Pin User</button>
                                    <button class='dropdown-item' id='edit' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-edit'></i>Edit User</button>
                                    <button class='dropdown-item' id='detail' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail User</button>
                                    <button class='dropdown-item' id='logindate' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fas fa-sync'></i>Update Nopol</button>
                                    <button class='dropdown-item' id='token' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fas fa-copy'></i>Copy Token</button>
                                    <button class='dropdown-item' id='hapus' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus User</button>
                                </div>`;
                            } else {
                            return `
                            <button id="user3" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="user3">
                                <button class='dropdown-item' id='edit' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-edit'></i>Edit User</button>
                                <button class='dropdown-item' id='detail' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail User</button>
                                <button class='dropdown-item' id='token' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fas fa-copy'></i>Copy Token</button>
                                <button class='dropdown-item' id='hapus' data-id="${full.mper_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus User</button>
                            </div>`;

                            }
                        }
                    },
                ]
            });
            
            $('#user').off('click').on('click', function() {
                $('#user').on('click','#detail',function () {
                    handleClickOpen()
                    setId($(this).attr('data-id'))
                })
                
                $('#user').on('click','#edit',function () {
                    handleClickOpen2()
                    setId($(this).attr('data-id'))
                    // setStep($(this).attr('data-step'))
                })
                
                $('#user').on('click','#reset',function () {
                handleClickOpen3()
                setIdreset($(this).attr('data-id'))
            })

                $('#user').on('click','#logindate',function () {
                handleClickOpen5()
                setIdlogin($(this).attr('data-id'))
            })

                $('#user').on('click','#token',function () {
                handleClickOpen6()
                setIdToken($(this).attr('data-id'))
                var text = 'janc*k'
                const textarea = document.createElement('textarea');
                document.body.appendChild(textarea);
                textarea.value = text;
                textarea.select();
                textarea.setSelectionRange(0, 99999);
                document.execCommand('copy');
                document.body.removeChild(textarea);
            })

                $('#user').on('click','#hapus',function () {
                handleClickOpen4()
                setIdhapus($(this).attr('data-id'))
            })
            })

            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
        })
        
    })

    return(
        <>
            <AlertList showIcon={false} alerts={alerts}/>
            <EditUser open={open2} close={handleClose2} id={id}/>
            <DetailUser open={open} close={handleClose} id={id}/>
            <DialogHapusBatal 
                open={open3} 
                close={handleClose3} 
                title="Reset User" 
                message="Anda Yakin Ingin Reset User Ini ?" 
                btn="Reset" 
                // type="resetuser" 
                // id={idreset}
                fun={ResetUser}
            />
            <DialogHapusBatal 
                open={open5} 
                close={handleClose5} 
                title="Update Data Nopol" 
                message="Anda Yakin Ingin Update Data Nopol User Ini ?" 
                btn="Update" 
                // type="resetuser" 
                // id={idreset}
                fun={UpdateNopol}
            />
            <DialogHapusBatal 
                open={open6} 
                close={handleClose6} 
                title="Copy Token" 
                message="Anda Yakin Ingin Copy Token User Ini ?" 
                btn="Copy"
                fun={CopyToken}
            />
            <DialogHapusBatal 
                open={open4} 
                close={handleClose4} 
                title="Hapus User" 
                message="Anda Yakin Ingin Hapus User Ini ?" 
                btn="Hapus" 
                fun={HapusUser}
                // type="hapususer" 
                // id={idhapus}
            />
            <Portlet>
                <PortletHeader title="Data User" toolbar={( <PortletHeaderToolbar>
                        <Link to="/tambahuser" className="btn btn-label-primary">
                            <i class="fas fa-user-plus"></i>Tambah User
                        </Link>
                    </PortletHeaderToolbar>
                    )}
                    >
                </PortletHeader>
                <PortletBody>
                    <div style={{overflow:'auto'}}>
                        <table className="display table table-hover table-responsive-md" id="user" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                    <th scope="col">Foto User</th>
                                    <th scope="col">ID User</th>
                                    <th scope="col">Tipe</th>
                                    <th scope="col">Tanggal Registrasi</th>
                                    <th scope="col">Nomor KTP</th>
                                    <th scope="col">Nama</th>
                                    <th scope="col">Nomor Handphone</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Tanggal Validitas</th>
                                    <th scope="col">Last Login Date</th>
                                    <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div>
                        <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor   </span>
                        <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil   </span>
                        <button type="button" class="btn btn-default" style={{background:"#EF5350"}}></button><span style={{color:"black"}}> : Non Aktif   </span>
                    </div>    
                </PortletBody>
            </Portlet>
        </>
    )
}