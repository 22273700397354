/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme  } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import DialogHapusBatal from './DialogHapusBatal'
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import { AlertList} from "react-bs-notifier";
import Box from '@material-ui/core/Box';
import API from '../../API'
import $ from 'jquery'

const formatter = new Intl.NumberFormat('de-DE');

const AntTabs = withStyles({
    root: {
      // borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      // backgroundColor: '#1890ff',
    },
  })(Tabs);
  
  const AntTab = withStyles(theme => ({
    root: {
      textTransform: 'bold',
      minWidth: 80,
      // fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#ffffff',
        opacity: 1,
      },
      '&$selected': {
        color: '#ffffff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#f4a240',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailVoucherInforman(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const [setOpen] = React.useState(false);    
  const [alerts,setAlerts] = React.useState([])
  const [alerts1,setAlerts1] = React.useState([])
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [carprice, setCarprice] = React.useState(null)
  const [bikeprice, setBikeprice] = React.useState(null)
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  React.useEffect(() => {
    var komisi = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi + "v1/InformanConfig/list",
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }
    
    $.ajax(komisi).done(function (response) {
      console.log(response)
      setCarprice(response.data[0].tcif_informan_car_fee_nominal)
      setBikeprice(response.data[0].tcif_informan_bike_fee_nominal)
    })

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi +"v1/penemuan/getPenemuanMaster/"+props.id+"/"+props.step,
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }
    
    if (props.id != null && props.step != null) {      
      $.ajax(settings).done(function (response) {
        console.log(response)
        if( response.data != null ){
          $('#mper_name').val(response.data.mper_name);
          $('#mper_phone').val(response.data.mper_phone);
          $('#tpnm_id').val(response.data.tpnm_id);
          $('#tpnm_vplate').val(response.data.tpnm_vplate);
          $('#tpnm_vin').val(response.data.tpnm_vin);
          $('#tpnm_ein').val(response.data.tpnm_ein);
          $('#tpnm_vtype').val(response.data.tpnm_vtype+" - "+response.data.tpnm_type+" "+response.data.tpnm_vyear+" "+response.data.tpnm_vcolor);
          if(response.data.tpnm_vtype == 'motor'){
            $('#tpif_fee').val(formatter.format(bikeprice))
          }else if(response.data.tpnm_vtype == 'mobil'){
            $('#tpif_fee').val(formatter.format(carprice))
          }
          $('#tpnm_vdoc_name').val(response.data.tpnm_vdoc_name);
          $('#tpnm_contract').val(response.data.tpnm_contract);
          $('#tpnm_branch').val(response.data.tpnm_branch);
          $('#tpnm_customer_name').val(response.data.tpnm_customer_name);
          $('#tpnm_address').val(response.data.tpnm_address);
          $('#tpnm_installment').val(response.data.tpnm_installment);
          $('#tpnm_ic_number').val(response.data.tpnm_ic_number);
          $('#tpnm_kk_number').val(response.data.tpnm_kk_number);
          $('#tpnm_debt_date').val(response.data.tpnm_debt_date);
          $('#tpnm_invoice_qty').val(response.data.tpnm_invoice_qty);
          $('#tpnm_duration').val(response.data.tpnm_duration);
          $('#tpnm_monthly_charge').val(response.data.tpnm_monthly_charge);
          $('#tpnm_total_unpaid').val(response.data.tpnm_total_unpaid);
          $('#tpnm_monthly_charge').val(response.data.tpnm_monthly_charge);
          $('#tpnm_created_date').val( response.data.tpnm_created_date+" - "+response.data.tpnm_created_time );
          $('#tpnm_sk_initiated_date').val( response.data.tpnm_sk_initiated_date+" - "+response.data.tpnm_sk_initiated_time );
          $('#tpnm_data_approved_date').val( response.data.tpnm_data_approved_date+" - "+response.data.tpnm_data_approved_time );
          $('#tpnm_sk_approved_date').val( response.data.tpnm_sk_approved_date+" - "+response.data.tpnm_sk_approved_time );
          $('#tpnm_delivery_dpname').val( response.data.tpnm_delivery_dpname);
          $('#tpnm_delivery_dplat').val( response.data.tpnm_delivery_dplat+" , "+response.data.tpnm_delivery_dplng);
          $('#tpnm_delivery_dpaddress').val( response.data.tpnm_delivery_dpaddress);
          $('#tpnm_delivery_dpopen').val( response.data.tpnm_delivery_dpopen+" - "+response.data.tpnm_delivery_dpclose);
          $('#tpnm_delivery_date').val( response.data.tpnm_delivery_date+" - "+response.data.tpnm_delivery_time);
          $('#tpnm_delivered_receiver_name').val( response.data.tpnm_delivered_receiver_name);
          $('#tpnm_delivered_receiver_phone').val( response.data.tpnm_delivered_receiver_phone);
          $('#tpnm_delivered_received_date').val( response.data.tpnm_delivered_received_date+" "+response.data.tpnm_delivered_received_time);
          $('#mper_name_informan').val(response.data.mper_name_informan)
          $('#mper_phone_informan').val(response.data.mper_phone_informan)
          $('#tpif_lat-long_informan').val(response.data.tpif_lat_informan+" , "+response.data.tpif_lng_informan)
          $('#tpif_address_informan').val(response.data.tpif_address_informan)
          $('#tanggal_jam_validasi').val(response.data.tanggal_validasi+" "+response.data.jam_validasi)
          $('#tanggal_jam_match').val(response.data.tanggal_matchmaking+" "+response.data.jam_matchmaking)
          // $('#tpif_fee').val(formatter.format(response.data.tpif_fee));
          // $('#tpif_photo_validation').val(response.data.tpif_photo_validation)
    
          if(response.data.tpnm_vtype == 'mobil'){
            $('#detail_mobil').hide();
            $('#foto_mobil').show();
          }else{
            $('#detail_mobil').show();
            $('#foto_mobil').hide();
          }
    
          if( response.data.tpnm_medi_sk_template != null ){
            $('#tpnm_medi_sk_template').attr("href", response.data.tpnm_medi_sk_template);
          }else{
            $('#tpnm_medi_sk_template').addClass('disabled');;
          }
          if( response.data.tpif_photo_validation != null ){
            $('#tpif_photo_validation').attr("href", response.data.tpif_photo_validation);
          }else{
            $('#tpif_photo_validation').addClass('disabled');;
          }
          if( response.data.tpnm_medi_vphoto_sisi1 != null ){
            $('#tpnm_medi_vphoto_sisi1').attr("href", response.data.tpnm_medi_vphoto_sisi1);
          }else{
            $('#tpnm_medi_vphoto_sisi1').addClass('disabled');;
          }
          $('#tpnm_medi_vphoto_sisi2').prop( "disabled", true );
          if( response.data.tpnm_medi_vphoto_sisi2 != null ){
            $('#tpnm_medi_vphoto_sisi2').attr("href", response.data.tpnm_medi_vphoto_sisi2);
          }else{
            $('#tpnm_medi_vphoto_sisi2').addClass('disabled');;
          }
          if( response.data.tpnm_delivered_stnk_photo != null ){
            // console.log(response.data.tpnm_delivered_stnk_photo);
            $('#tpnm_delivered_stnk_photo').attr("href", response.data.tpnm_delivered_stnk_photo);
          }else{
            $('#tpnm_delivered_stnk_photo').addClass('disabled');;
          }
          if( response.data.tpnm_medi_bast_template != null ){
            $('#tpnm_medi_bast_template').attr("href", response.data.tpnm_medi_bast_template);
          }else{
            $('#tpnm_medi_bast_template').addClass('disabled');;
          }
          if( response.data.tpnm_medi_bast_final != null ){
            $('#tpnm_medi_bast_final').attr("href", response.data.tpnm_medi_bast_final);
          }else{
            $('#tpnm_medi_bast_final').addClass('disabled');;
          }
          
          if( response.data.tpnm_medi_bast_backup != null ){
            $('#tpnm_revisi_bastk').attr("href", response.data.tpnm_medi_bast_backup);
            $('#tpnm_revisi_bastk').css("display","inline");
            $('#foto_srtf_delete').css("display", "inline");
            $('#foto_revbast_add').css("display", "none");
          }else{
            $('#tpnm_revisi_bastk').css("display","none");
            $('#foto_srtf_delete').css("display", "none");
            $('#foto_revbast_add').css("display", "block");
          }
    
          console.log("backup media "+response.data.tpnm_medi_bast_backup);
    
          if( props.tvoctype == "Referred" ){
            if( response.data.tpnm_status_stnk == 1 ){
              $('#total_fee').val( formatter.format(parseInt(response.data.tpnm_fee_stnk) + parseInt(response.data.tpnm_fee)));
            }else{
              $('#total_fee').val( formatter.format(response.data.tpnm_fee) );
            }
          }else if(props.tvoctype == "Referall"){
            $('#total_fee').val(formatter.format(25000) );
          }
          console.log(props.tvoctype);
    
          if(props.tvoctype == "Referred"){
          $('#tpnm_fee_stnk').val(formatter.format(response.data.tpnm_fee_stnk));
          $('#tpnm_fee').val(formatter.format(response.data.tpnm_fee));
          }else if(props.tvoctype == "Referall"){
            $('#tpnm_fee_stnk').val(0);
            $('#tpnm_fee').val(0);
          }
    
          var details ;
          if ( props.tvoctype == "Referred"){
          if( response.data.tpnm_status_stnk == null){
            details = "<input selected type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 0){
            details = "<input checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else{
            details = "<input type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_1').html(details);
          if(response.data.tpnm_status_stnk == null){
            details = "<input selected type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 1){
            details = "<input checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else{
            details = "<input type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_2').html(details);
        }else{
          if( response.data.tpnm_status_stnk == null){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 0){
            details = "<input selected disabled checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else{
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_1').html(details);
          if(response.data.tpnm_status_stnk == null){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 1){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else{
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_2').html(details);
          // if( response.data.tpnm_medi_bast_backup == null || response.data.tpnm_medi_bast_backup == '' ){
          //   details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>\
          //              <button disabled type='button' name='foto' style='margin-top: 0px;'' id='foto_srtf_delete' class='btn btn-outline btn-circle btn-md blue'><i class='fas fa-eraser'></i> Hapus</button>";
          // }else{
          //   details = "<a href='"+response.data.tpnm_medi_bast_backup+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>\
          //             <button type='button' name='foto' style='margin-top: 0px;'' id='foto_srtf_delete' class='btn btn-outline btn-circle btn-md blue'><i class='fas fa-eraser'></i> Hapus</button>";
          // }
          // $('#foto_srtf').html(details);
        }
          if( response.data.tpnm_status_stnk == 1 || response.data.tpnm_status_stnk == 0 ){
            $('#idReject').css('display','block');
            $('#idApprove').css('display','block');
            $('#idVerifikasi').css('display','none');
          }else{
            $('#idReject').css('display','none');
            $('#idApprove').css('display','none');
            $('#idVerifikasi').css('display','block');
          }
    
          if( response.data.tpnm_medi_bast_template == null || response.data.tpnm_medi_bast_template == '' ){
            // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
            $('#tpnm_medi_bast_template_1').attr('disabled',true);
          }else{
            // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
            // details = "<a href='"+response.data.tpnm_medi_bast_template+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>";
            $('#tpnm_medi_bast_template_1').attr('href',response.data.tpnm_medi_bast_template);
          }
          // $('#tpnm_medi_bast_template_1').html(details);
    
          if( response.data.tpnm_medi_bast_final == null || response.data.tpnm_medi_bast_final == '' ){
            details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
          }else{
            // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
            details = "<a href='"+response.data.tpnm_medi_bast_final+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>";
          }
          $('#tpnm_medi_bast_final_1').html(details);
    
            $("input[type='radio']").on('change', function() {
              var tpnm_status_stnk = $("input[name='tpnm_status_stnk']:checked").val();
              var tpnm_fee_stnk = $('#tpnm_fee_stnk').val();
              var tpnm_fee = $('#tpnm_fee').val();
              var tpnm_fee_stnk_string =  tpnm_fee_stnk.replace(/\./g,'');
              var tpnm_fee_string = tpnm_fee.replace(/\./g,'');
              if( tpnm_status_stnk == 1 ){
                $('#total_fee').val(formatter.format(parseInt(tpnm_fee_stnk_string) + parseInt(tpnm_fee_string)));
              }else{
                $('#total_fee').val(formatter.format(parseInt(tpnm_fee_string)));
              }
              console.log("| "+tpnm_status_stnk);
            });
        }
      })
    }

    $(document).on('change', '#foto_revbast_add', function () {
      // console.log(foto_revbast_add.files[0] + " " + tpnm_id);
      var form = new FormData();
      // form.append("upload_data", foto_revbast_add.files[0]);

      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi + "/v1/Founding/upload_photos/revisi_bstk/" + props.id,
        "method": "POST",
        "headers": {
          "Authorization": "Basic " + localStorage.getItem('jwt'),
        },
        "processData": false,
        "contentType": false,
        "data": form
      }

      $.ajax(settings).done(function (response) {
        console.log(response);
      }).fail(function (response) {
        console.log(response);
        // Handler403(response.status)
      })
    })

  })


  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const Verifikasi = () => {
        handleClose()
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Financeapi+"v1/voucher/verification_voucher_informan/"+props.tpif,
            "method": "GET",
            "headers": {
                "Authorization": "Baisc "+localStorage.getItem('jwt')
            }
          }
          $.ajax(settings).done(function (response) {
            props.close()
            setAlerts([{
              id: 1,
              type: "success",
              headline: "Berhasil",
              message: "Voucher Telah Diverifikasi"
            }])
            setTimeout(()=>{
                setAlerts([])
            },3000)
          }).fail(function (response) {
            console.log(response);
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(()=>{
                setAlerts1([])
            },3000)
          })
  }
  
  return (
    <div>
      <AlertList showIcon={false} alerts={alerts}/>
      <DialogHapusBatal                 
          open={open} 
          close={handleClose} 
          title="Verifikasi Voucher Informan" 
          message="Anda Yakin Ingin Verifikasi Voucher Ini ?" 
          btn="Verifikasi" 
          btn2="Batal" 
          // type="okbatal" 
          fun={Verifikasi}
      />
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <AntTabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable"
                scrollButtons="auto" aria-label="scrollable auto tabs example" className={classes.title}>
                <AntTab label="Data Kendaraan" {...a11yProps(0)} />
                <AntTab label="Order Details" {...a11yProps(1)} />
                <AntTab label="Dokumen Verifikasi" {...a11yProps(2)} />
            </AntTabs>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <AlertList showIcon={false} alerts={alerts1}/>
        <SwipeableViews axis={theme.direction==='rtl' ? 'x-reverse' : 'x' } index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0}>
            <Portlet>
                <PortletHeader title="Data Kendaraan"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Polisi</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id='tpnm_vplate' disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Rangka</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_vin" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Mesin</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_ein" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Type Motor/Mobil</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_vtype" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama STNK/BPKB</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_vdoc_name" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Kontrak</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_contract" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Leasing</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_branch" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Asal Cabang</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_branch" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                </Portlet>
                <Portlet>
                <PortletHeader title="Profile Debitur"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Debitur</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_customer_name" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Alamat Konsumen</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_address" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor KTP</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_ic_number" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor KK</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_kk_number" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Mulai Tunggak</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_debt_date" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Angsuran Ke</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_installment" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Jumlah Kwitansi</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_invoice_qty" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tenor</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_duration" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Angsuran Perbulan</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_monthly_charge" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Total Tunggakkan</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_total_unpaid" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                </Portlet>
                <Portlet>
                  <PortletHeader title="Data Informan"></PortletHeader>
                  <PortletBody>
                    <Row>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Informan</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" id="mper_name_informan" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Informan</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" id="mper_phone_informan" disabled/>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Lat-Long Informan</label>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="tpif_lat-long_informan" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Posisi Informan</label>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="tpif_address_informan" disabled/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </PortletBody>
                </Portlet>
                <Portlet>
                <PortletHeader title="Data Mitra"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Mitra</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="mper_name" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">No HP Mitra</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="mper_phone" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
              </Portlet>
              <Row>
                <Col md>
                  <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Portlet>
                <PortletHeader title="Order Detail"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Validasi Informan</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tanggal_jam_validasi" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Matched</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tanggal_jam_match" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Validasi Mitra</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_created_date" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Menunggu SK</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_sk_initiated_date" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Surat Kerja Diapprove</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_data_approved_date" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Informan</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpif_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Mitra</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Surat Tugas</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_sk_template" target='_blank' disabled="disabled"><i className="far fa-file-pdf"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Motor Sisi 1</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_vphoto_sisi1" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Motor Sisi 2</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_vphoto_sisi2" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                       <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Validasi Informan</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpif_photo_validation" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                <PortletHeader title="Detail Drop Point"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Drop Point</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivery_dpname" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Drop Point Lat-Lng</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivery_dplat" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Alamat Drop Point</label>
                        <div className="col-sm-7">
                          <textarea type="text" className="form-control" id="tpnm_delivery_dpaddress" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Jam Operasional Drop Point</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_delivery_dpopen" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Dan Jam Antar</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_delivery_date" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
              </Portlet>
              <Row>
                <Col md>
                  <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Portlet>
                <PortletHeader title="Dokumen Verifikasi"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Penerima</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivered_receiver_name" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">No HP Penerima</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivered_receiver_phone" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Penerima</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivered_received_date" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-3 col-form-label">PDF BAST</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_bast_template_1" target='_blank' disabled="disabled"><i className="far fa-file-pdf"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-3 col-form-label">BAST Akhir</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_bast_final" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-3 col-form-label">Foto STNK</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_delivered_stnk_photo" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md>
                      <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                      <button type="button" className="btn btn-brand btn-elevate pull-right" id="idVerifikasi" onClick={handleClickOpen}><i class="fas fa-check-double"></i>Verifikasi</button>
                    </Col>
                  </Row>
                      {/* <button type="button" className="btn btn-brand btn-elevate" id="verify" onClick={handleClickOpen}>Verifikasi</button> */}
                  {/* <Row>
                    <Col md={6}>
                      <button type="button" className="btn btn-danger btn-elevate" id="reject" onClick="" style={{width:'100%',display:'none'}}>Reject</button>
                    </Col>
                    <Col md={6}>
                      <button type="button" className="btn btn-success btn-elevate" id="approve" onClick="" style={{width:'100%',display:'none'}}>Approve</button>
                    </Col>
                  </Row> */}
                </PortletBody>
              </Portlet>
            </TabPanel>
        </SwipeableViews>
      </Dialog>
    </div>
  );
}
