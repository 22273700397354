/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../../API'
// import DialogOnboard from './Utils/DialogOnboard'
// import DialogHapusBatal from './Utils/DialogHapusBatal'
// import { AlertList} from "react-bs-notifier";
import {
Portlet,
PortletBody,
PortletHeader,
PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import Table from './TableLeasing'
// import Utils from '../Utils/FunctionDetail'
import EditAdministrator from './EditAdministrator'
import DetailUser from '../Utils/DetailUser'
import DialogHapusBatal from '../Utils/DialogHapusBatal'
import { AlertList} from "react-bs-notifier";

export default function Administrator(props) {

    React.useEffect(()=>{
        $(document).ready(function () {
            if(props.id != null){
                Table.Administrator(props.id,setAlerts1);
            }
            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')

            $('#administrator').off('click').on('click', function() {
                $('#administrator').on('click', '#detailAdministrator', function (){
                    handleClickOpen6()
                    setId($(this).attr('data-id'));
                })
            
                $('#administrator').on('click', '#editAdministrator', function (){
                    handleClickOpen7()
                    // setAdmin(true)
                    setId($(this).attr('data-id'));
                })
        
                $('#administrator').on('click', '#hapusAdministrator', function (){
                    handleClickOpen5()
                    setHapusId($(this).attr('data-id'));
                })
            })

            $(document).on('click', '#tambahadmin', function (){
                handleClickOpen7()
            })
        })
    })

    const [open5, setOpen5] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);
    const [open7, setOpen7] = React.useState(false);
    const [id, setId] = React.useState(null);
    // const [admin, setAdmin] = React.useState(false);
    const [hapusid, setHapusId] = React.useState(null);
    const [alerts1, setAlerts1] = React.useState([]);

    function handleClickOpen5() {
        setHapusId(null)
        setOpen5(true);
    }

    function handleClose5() {
        setOpen5(false);
    }

    function handleClickOpen6() {
        setOpen6(true);
    }

    function handleClose6() {
        setId(null)
        setOpen6(false);
    }

    function handleClickOpen7() {
        setOpen7(true);
    }

    function handleClose7() {
        setId(null)
        setOpen7(false);
    }

    
    function HapusAdministrator() {
        handleClose5()
        var settings = {
         "async": true,
         "crossDomain": true,
         "url": API.Userapi+"v1/user/delete/"+hapusid,
         "method": "GET",
         "headers": {
             "Authorization": "Baisc "+localStorage.getItem('jwt')
           }
         }
 
        $.ajax(settings).done(function (response) {
            setAlerts1([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Administrator Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
        }).fail(function (response) {
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts1)
        })
     }

    return(
        <>  
            <EditAdministrator 
                open={open7} 
                close={handleClose7} 
                id={id} 
                edit={true}
                mmle={props.id}
                title={id != null ? "Edit Administrator" : "Tambah Administrator"}
            />
            <DetailUser open={open6} close={handleClose6} id={id}/>
            <DialogHapusBatal 
                open={open5} 
                close={handleClose5} 
                title="Hapus Administrator" 
                message="Anda Yakin Ingin Hapus Administrator Ini ?" 
                btn="Hapus" 
                fun={HapusAdministrator}
                // type="hapusleasing" 
                // id={idhapus}
            />
            <AlertList showIcon={false} alerts={alerts1} />
            <Portlet>
                    <PortletHeader title="Administrator" toolbar={(
                            <PortletHeaderToolbar>
                                <button
                                    // to="/tambahleasing"
                                    type="button"
                                    className="btn btn-label-primary btn-sm"
                                    id="tambahadmin"
                                    onClick={handleClickOpen7}
                                >
                                    <i class="fas fa-plus"></i>Tambah Data
                                </button>
                            </PortletHeaderToolbar>
                        )}/>
                    <PortletBody>
                      <table className="table table-hover table-responsive-md" id="administrator" style={{width:'100%',overflow:'auto'}}>
                                  <thead className="bg-primary" style={{color:'white'}}>
                                      <tr>
                                        <th class="all"> Nama </th>
                                        <th class="all"> Role </th>
                                        <th class="all"> Status </th>
                                        <th class="all"> Aksi </th>
                                      </tr>
                                  </thead>
                          </table>
                    </PortletBody>
                </Portlet>
                
        </>
    )
}