/* eslint-disable eqeqeq */
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { AlertList} from "react-bs-notifier";
import { Link, useHistory } from "react-router-dom";
import DialogHapusBatal from '../Utils/DialogHapusBatal'
import $ from 'jquery';
import 'jquery-validation';
import 'bootstrap-datepicker'
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import API from '../../API'
import moment from 'moment'

export default function TambahLeasing() {
    const [alerts, setAlerts] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        $("form").validate();
        $(document).ready(function () {
            $('#lahir,#vldfrom,#vldto').datepicker({
                todayHighlight: true,
                autoclose: true,
                format: "yyyy-mm-dd",
            });
            $('#vldfrom').on('change',function () {
                console.log(moment($('#vldfrom').datepicker('getDate')).add(1, 'years').toDate())
                $('#vldto').datepicker('setDate', moment($('#vldfrom').datepicker('getDate')).add(1, 'years').toDate())
                
            })
        })
    })

    function handleClickOpen() {
        if ($('form').valid() == false) {
            setAlerts([{
                id: 1,
                type: "info",
                // headline: "Validate",
                message: "Lengkapi Data Yang Tersedia Dengan Benar"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
        }else{
            setOpen(true);
        }
    }

    function handleClose() {
        setOpen(false);
    }

    const Submit = () => {
        handleClose()
        var mmle_name = $("#mmle_name").val();
        // var mmle_status = $('input[name=mmle_status]:checked').val();
        // console.log(mmle_status);
        // if( mmle_status == "Aktif" ) {
        //     mmle_status = 0;
        // }else if(mmle_status == " Tidak Aktif") {
        //     mmle_status = 1;
        // }
        var mmle_pic_name1 = $("#mmle_pic_name1").val();
        var mmle_pic_phone1 = $("#mmle_pic_phone1").val();
        var mmle_pic_email1 = $("#mmle_pic_email1").val();
        var mmle_ho_address = $("#mmle_ho_address").val();
        var mmle_pic_name2 = $("#mmle_pic_name2").val();
        var mmle_pic_phone2 = $("#mmle_pic_phone2").val();
        var mmle_pic_email2 = $("#mmle_pic_email2").val();
        var mmle_valid_from = $("#vldfrom").val();
        var mmle_valid_to = $("#vldto").val();
        var app_type = $("#app_type").val();

        var data = {
            'mmle_name': mmle_name,
            'mmle_pic_name1': mmle_pic_name1,
            'mmle_pic_phone1': mmle_pic_phone1,
            'mmle_pic_email1': mmle_pic_email1,
            'mmle_ho_address': mmle_ho_address,
            'mmle_pic_name2': mmle_pic_name2,
            'mmle_pic_phone2': mmle_pic_phone2,
            'mmle_pic_email2': mmle_pic_email2,
            'mmle_valid_from': mmle_valid_from,
            'mmle_valid_to': mmle_valid_to,
            'app_type': app_type,
        };
        console.log(data)
        // eslint-disable-next-line no-unused-vars
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi + "v1/leasing/add_leasing",
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Baisc " + localStorage.getItem('jwt')
            },
            "processData": false,
            "data": JSON.stringify(data)
        }

        $.ajax(settings).done(function (response) {
            console.log(response)
            $("#tambahleasing input,#tambahleasing textarea,#tambahleasing select").val("")
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Leasing Telah Ditambahkan"
            }])
            setTimeout(() => {
                setAlerts([])
                history.push("/leasing");
            }, 1000)
            return 0
        }).fail(function (response) {
            console.log(response)
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code " + response.status + "]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

    return(
        <>
            <DialogHapusBatal 
                open={open} 
                close={handleClose} 
                title="Tambah Leasing" 
                message="Anda Yakin Ingin Menambah Leasing ?" 
                btn="Tambahkan"
                fun={Submit} 
                // type="delete" 
                // id={id}
            />
            <AlertList  showIcon={false} alerts={alerts}/>
            <Portlet>
                <PortletHeader title="Tambah Leasing"/>
                <PortletBody>
                    <form onSubmit={(e)=>{e.preventDefault()}} id="tambahleasing">
                        <Row>
                                <Col md={6}>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Type App<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select id="app_type" name="app_type" className="form-control" required>
                                            <option selected disabled value="0">Pilih Type App</option>
                                            <option value="motor">Motor</option>
                                            <option value="mobil">Mobil</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nama Leasing<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mmle_name" name="mmle_name" required/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Alamat Leasing<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <textarea className="form-control" id="mmle_ho_address" name="mmle_ho_address" rows="6" required></textarea>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Tanggal Valid<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <div className="input-daterange input-group date" id="kt_datepicker_5">
                                            <input type="text" className="form-control" name="start" id="vldfrom"
                                                autocomplete="off" />
                                            <div className="input-group-append">
                                                <span className="input-group-text">To</span>
                                            </div>
                                            <input type="text" className="form-control" name="end" id="vldto"
                                                autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                                </Col>
                                <Col md={6}>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Nama PIC 1<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="mmle_pic_name1" name="mmle_pic_name1" required/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">No Handphone PIC 1<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="mmle_pic_phone1" name="mmle_pic_phone1" required/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Email PIC 1<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="email" className="form-control" id="mmle_pic_email1" name="mmle_pic_email1" required/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Nama PIC 2<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="mmle_pic_name2" name="mmle_pic_name2" required/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">No Handphone PIC 2<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="mmle_pic_phone2" name="mmle_pic_phone2" required/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Email PIC 2<span
                                            style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="email" className="form-control" id="mmle_pic_email2" name="mmle_pic_email2" required/>
                                    </div>
                                </div>
                                </Col>
                        </Row>
                        <Row>
                            <Col md>
                            <Link to="/leasing" className="btn btn-warning btn-elevate">
                                <i className="fas fa-arrow-left"></i>Kembali
                            </Link>
                                {/* <button type="button" className="btn btn-danger btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Kembali</button> */}
                                <button type="submit" className="btn btn-brand btn-elevate pull-right" onClick={handleClickOpen}><i className="fas fa-save"></i>Simpan</button>
                            </Col>
                        </Row>
                    </form>
                    {/* <button type="button" className="btn btn-brand btn-elevate" onClick={handleClickOpen} style={{width:'100%'}}>Simpan</button> */}
                </PortletBody>
            </Portlet>
        </>
    )
}