/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
// import InputAdornment from '@material-ui/core/InputAdornment'
// import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from "@material-ui/core/styles";
import API from "../API";
import Utils from "./Utils/FunctionDetail";
import DetailKendaraan from "./Utils/DetailKendaraan";
import DialogHapusBatal from "./Utils/DialogHapusBatal";
import { AlertList } from "react-bs-notifier";
import DialogInputTarikanWeb from "./Utils/DialogInputTarikanWeb";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%",
  },
  dense: {
    marginTop: 19,
  },
}));

export default function KendaraanAktif() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [openInputTarikanWeb, setOpenInputTarikanWeb] = React.useState(false);
  const [idhapus, setIdhapus] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [mmleId, setMmleId] = React.useState(null);
  const [alerts, setAlerts] = React.useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleCloseInputTarikanWeb = () => {
    setOpenInputTarikanWeb(false);
  };

  // const tambahInputTarikanWeb = () => {
  //   alert();
  // };

  const HapusReasonBatal = () => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Uploadapi + "v1/Data_kendaraan_aktif/deleteDataAktif/" + idhapus,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        handleClose2();
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Reason Batal Telah Dihapus",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        handleClose2();
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  useEffect(() => {
    $(document).ready(function() {
      $(document).on("click", "#detail", function() {
        handleClickOpen();
        setId($(this).attr("data-id"));
        $("#tpnm_vplate").val($(this).attr("data-tvec"));
        $("#tpnm_vtype").val($(this).attr("data-unit"));
      });
      $("#kendaraan_aktif").on("click", "#hapus", function() {
        handleClickOpen2();
        setIdhapus($(this).attr("data-id"));
      });
      $("#kendaraan_aktif").on("click", "#input-tarikan-web", function() {
        setOpenInputTarikanWeb(true);
        setId($(this).attr("data-id"));
        setMmleId($(this).attr("data-mmle_id"));
      });
    });
  }, setAlerts);

  function FilterKendaraan() {
    if ($("#nopol").val() == 0) {
      return 0;
    }
    var api;
    if (localStorage.getItem("role") == 5) {
      // internal admin
      api = API.Uploadapi + "v1/Data_kendaraan_aktif/index?nopol=";
    } else {
      api = API.Uploadapi + "v1/Data_kendaraan_aktif_leasing/index?nopol=";
    }

    console.log("cari");
    $("#kendaraan_aktif").DataTable({
      pagingType: "full_numbers",
      // "bFilter": false,
      processing: true,
      serverSide: true,
      destroy: true,
      ajax: {
        url: api + $("#nopol").val(),
        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        console.log(aData);
      },
      columns: [
        {
          sortable: true,
          render: function(data, type, full, meta) {
            console.log(full);
            return "<div>" + Utils.StatusUser(full.tvec_status) + " </div>";
          },
        },
        {
          data: "tvec_vplate",
        },
        {
          data: "mmle_name",
        },
        {
          data: "tvec_vtype",
        },
        {
          data: "tvec_vbrand",
        },
        {
          data: "tvec_ptype",
        },
        {
          data: "tvec_vyear",
        },
        {
          data: "tvec_vcolor",
        },
        // {
        //   data: "pricing_fee",
        // },
        // {
        //   data: "pricing_fee_stnk",
        // },
        // {
        //     sortable: true,
        //     "render": function (data, type, full, meta) {
        //         return "<button class='detail btn btn-outline-brand btn-icon btn-sm' id='detail' data-id='" + full.tvec_id + "' data-tvec='" + full.tvec_vplate + "' data-unit='"+`${Utils.DataNull(full.tvec_vtype)} - ${Utils.DataNull(full.tvec_vbrand)} ${Utils.DataNull(full.tvec_ptype)} ${Utils.DataNull(full.tvec_vcolor)} ${Utils.DataNull(full.tvec_vyear)} data-original-title='Detail Data Kendaraan'> <i class='fa fa-eye'> </i> </button>";
        //     }
        // },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return `
                          <button id="kendaraanAktif" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="kendaraanAktif">
                          <button class='dropdown-item' id='input-tarikan-web' data-id="${
                            full.tvec_id
                          }" data-mmle_id="${
              full.mmle_id
            }" data-toggle='tooltip' title='Input Tarikan Web'><i class='fa fa-trash'></i>Input Tarikan Web</button>
                          <button class='dropdown-item' id='detail' data-id="${
                            full.tvec_id
                          }" data-tvec="${full.tvec_vplate}" data-unit="${Utils.DataNull(
              full.tvec_vtype
            )} - ${Utils.DataNull(full.tvec_vbrand)} ${Utils.DataNull(
              full.tvec_ptype
            )} ${Utils.DataNull(full.tvec_vcolor)} ${Utils.DataNull(
              full.tvec_vyear
            )}"> <i class='fa fa-eye'> </i> Detail Kendaraan Aktif </button>
                          <button class='dropdown-item' id='hapus' data-id="${
                            full.tvec_id
                          }" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus Reason Batal</button>
                          </div>`;
          },
        },
      ],
    });
    $(".dataTables_filter,.dataTables_paginate").addClass("pull-right");
  }

  return (
    <>
      <DialogInputTarikanWeb
        open={openInputTarikanWeb}
        close={handleCloseInputTarikanWeb}
        title={"Input Tarikan Web"}
        tvec_id={id}
        mmle_id={mmleId}
        btn={"Tambah"}
        // setAlerts={setAlerts}
      />
      <AlertList showIcon={false} alerts={alerts} />
      <DetailKendaraan open={open} close={handleClose} id={id} />
      <DialogHapusBatal
        open={open2}
        close={handleClose2}
        title="Hapus Reason Batal"
        message="Anda Yakin Ingin Hapus Reason Ini ?"
        btn="Hapus"
        fun={HapusReasonBatal}
        // type="delete"
        // id={idhapus}
      />
      <Portlet>
        <PortletHeader title="Cari Nomor Polisi" />
        <PortletBody>
          <Grid container spacing={2}>
            <Grid item xs></Grid>
            <Grid item xs={6} className="text-center">
              <TextField
                id="nopol"
                label="Masukan Nomor Polisi"
                placeholder="Cth: B1234ABC"
                className={classes.textField}
                autoComplete="off"
                // margin="normal"
              />
              <button
                type="button"
                className="btn btn-brand btn-elevate btn-sm mt-2"
                onClick={FilterKendaraan}
                style={{ width: "97%" }}
              >
                Cari
              </button>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </PortletBody>
      </Portlet>
      <Portlet>
        <PortletHeader
          title="Data Kendaraan Aktif"
          toolbar={
            <PortletHeaderToolbar>
              <Link
                to="/tambahdatanpl"
                className="btn btn-label-primary"
                style={{ display: localStorage.getItem("role") != 5 ? "none" : "block" }}
              >
                <i class="fas fa-plus"></i>Tambah Data
              </Link>
            </PortletHeaderToolbar>
          }
        ></PortletHeader>
        <PortletBody>
          <table
            className="table table-hover table-responsive-md"
            id="kendaraan_aktif"
            style={{ width: "100%" }}
          >
            <thead className="bg-primary" style={{ color: "white" }}>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Nomor Polisi</th>
                <th scope="col">Nama Leasing</th>
                <th scope="col">Jenis Kendaraan</th>
                <th scope="col">Merk</th>
                <th scope="col">Tipe</th>
                <th scope="col">Tahun</th>
                <th scope="col">Warna</th>
                {/* <th scope="col">Fee Mitra</th>
                <th scope="col">Fee STNK</th> */}
                <th scope="col">Aksi</th>
              </tr>
            </thead>
          </table>
        </PortletBody>
      </Portlet>
    </>
  );
}
