/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme  } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useHistory  } from "react-router-dom";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import { AlertList} from "react-bs-notifier";
import Box from '@material-ui/core/Box';
import DialogHapusBatal from './DialogHapusBatal'
import API from '../../API'
import $ from 'jquery'
import bsCustomFileInput from 'bs-custom-file-input'
import { getFeeMitra } from '../../../functions/invoice'

const formatter = new Intl.NumberFormat('de-DE');

const AntTabs = withStyles({
    root: {
      // borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      // backgroundColor: '#1890ff',
    },
  })(Tabs);
  
  const AntTab = withStyles(theme => ({
    root: {
      textTransform: 'bold',
      minWidth: 80,
      // fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#ffffff',
        opacity: 1,
      },
      '&$selected': {
        color: '#ffffff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#f4a240',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailVoucherVerifikasi(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const [setOpen] = React.useState(false);    
  const history = useHistory(); 
  const [alerts,setAlerts] = React.useState([])
  const [alerts1,setAlerts1] = React.useState([])
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [informan, setInforman] = React.useState();
  const [carprice, setCarprice] = React.useState(null)
  const [bikeprice, setBikeprice] = React.useState(null)
  const tvoctype = props.tvoctype
  const type = props.type
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  React.useEffect(() => {
    $(document).ready(function () {
      var komisi = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi + "v1/InformanConfig/list",
        "method": "GET",
        "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt')
          }
      }
      
      if (props.id != null && props.step != null && props.type != null) { 
        $.ajax(komisi).done(function (response) {
          console.log(response)
          setCarprice(response.data[0].tcif_informan_car_fee_nominal)
          setBikeprice(response.data[0].tcif_informan_bike_fee_nominal)
        })

        bsCustomFileInput.init()

        if(load == true){
          RequestPenemuan()
        }
      }

    })


    // $(document).on('change', '#foto_revbast_add', function () {
      // console.log(foto_revbast_add.files[0] + " " + tpnm_id);
      // var form = new FormData();
      // form.append("upload_data", foto_revbast_add.files[0]);

      // var settings = {
      //   "async": true,
      //   "crossDomain": true,
      //   "url": API.Foundingapi + "/v1/Founding/upload_photos/revisi_bstk/" + props.id,
      //   "method": "POST",
      //   "headers": {
      //     "Authorization": "Basic " + localStorage.getItem('jwt'),
      //   },
      //   "processData": false,
      //   "contentType": false,
      //   "data": form
      // }

      // $.ajax(settings).done(function (response) {
      //   console.log(response);
      // }).fail(function (response) {
      //   console.log(response);
        // Handler403(response.status)
      // })
    // })

  })

  const Verifikasi = () => {

      handleClose2()
      var tpnm_fee_stnk = $('#tpnm_fee_stnk').val();
      tpnm_fee_stnk = tpnm_fee_stnk.replace(/\./g,'');
      var tpnm_status_stnk = $("input[name='tpnm_status_stnk']:checked").val();
          var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Financeapi+"v1/VoucherTranskasi/simpanVouhcer/"+props.tvoc,
            "method": "POST",
            "headers": {
              "Content-Type": "application/json",
              "Authorization": "Basic "+localStorage.getItem('jwt'),
            },
            "processData": false,
            "data": "{\n\t\"tvoc_fee_stnk\": "+tpnm_fee_stnk+",\n\t\"tvoc_status_stnk\": "+tpnm_status_stnk+"\n}"
          }

          var verify = {
            "async": true,
            "crossDomain": true,
            "url": API.Foundingapi +"v1/penemuan/simpanTpnm/"+props.id,
            "method": "POST",
            "headers": {
              "Content-Type": "application/json",
              "Authorization": "Basic "+localStorage.getItem('jwt'),
            },
            "processData": false,
            "data": "{\n\t\"tpnm_fee_stnk\": "+tpnm_fee_stnk+",\n\t\"tpnm_status_stnk\": "+tpnm_status_stnk+"\n}"
          }

          if (tpnm_status_stnk == undefined && informan == false) {
            setAlerts1([{
              id: 1,
              type: "info",
              // headline: "Berhasil",
              message: "Mohon Pilih Cek Keaslian STNK"
            }])
            setTimeout(()=>{
                setAlerts1([])
            },3000)
          } else {
            $.ajax(settings).done(function (response) {
              RequestPenemuan()
              setLoad(true)
            }).fail(function (response) {
              console.log(response);
            });
  
            $.ajax(verify).done(function (response) {
              console.log(response);
              RequestPenemuan()
              setLoad(true)
              setAlerts1([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Voucher Telah Diverifikasi"
              }])
              setTimeout(()=>{
                  setAlerts1([])
              },3000)
            }).fail(function (response) {
              console.log(response);
              setAlerts1([{
                  id: 1,
                  type: "danger",
                  headline: "Gagal",
                  message: "Terjadi Kendala [Error Code "+response.status+"]"
              }])
              setTimeout(()=>{
                  setAlerts1([])
              },3000)
            })
          }
  }

  function RequestPenemuan() {
    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi +"v1/penemuan/getPenemuanMaster/"+props.id+"/"+props.step+"?mper_type="+type,
      "method": "GET",
      "headers": {
          "Authorization": "Basic "+localStorage.getItem('jwt')
        }
    }
    
    if (props.id != null && props.step != null && type != null) {      
      $.ajax(settings).done(function (response) {
        console.log(response)
        if( response.data != null ){
          $('#mper_name').val(response.data.mper_name);
          $('#mper_phone').val(response.data.mper_phone);
          $('#tpnm_id').val(response.data.tpnm_id);
          $('#tpnm_vplate').val(response.data.tpnm_vplate);
          $('#tpnm_vin').val(response.data.tpnm_vin);
          $('#tpnm_ein').val(response.data.tpnm_ein);
          $('#tpnm_vtype').val(response.data.tpnm_vtype+" - "+response.data.tpnm_type+" "+response.data.tpnm_vyear+" "+response.data.tpnm_vcolor);
          $('#tpnm_vdoc_name').val(response.data.tpnm_vdoc_name);
          $('#tpnm_contract').val(response.data.tpnm_contract);
          $('#tpnm_branch').val(response.data.tpnm_branch);
          $('#tpnm_customer_name').val(response.data.tpnm_customer_name);
          $('#tpnm_address').val(response.data.tpnm_address);
          $('#tpnm_installment').val(response.data.tpnm_installment);
          $('#tpnm_ic_number').val(response.data.tpnm_ic_number);
          $('#tpnm_kk_number').val(response.data.tpnm_kk_number);
          $('#tpnm_debt_date').val(response.data.tpnm_debt_date);
          $('#tpnm_invoice_qty').val(response.data.tpnm_invoice_qty);
          $('#tpnm_duration').val(response.data.tpnm_duration);
          $('#tpnm_monthly_charge').val(response.data.tpnm_monthly_charge);
          $('#tpnm_total_unpaid').val(response.data.tpnm_total_unpaid);
          $('#tpnm_monthly_charge').val(response.data.tpnm_monthly_charge);
          $('#tpnm_created_date').val( response.data.tpnm_created_date+" - "+response.data.tpnm_created_time );
          $('#tpnm_sk_initiated_date').val( response.data.tpnm_sk_initiated_date+" - "+response.data.tpnm_sk_initiated_time );
          $('#tpnm_data_approved_date').val( response.data.tpnm_data_approved_date+" - "+response.data.tpnm_data_approved_time );
          $('#tpnm_sk_approved_date').val( response.data.tpnm_sk_approved_date+" - "+response.data.tpnm_sk_approved_time );
          $('#tpnm_delivery_dpname1').val( response.data.tpnm_delivery_dpname);
          $('#tpnm_delivery_dplat').val( response.data.tpnm_delivery_dplat+" , "+response.data.tpnm_delivery_dplng);
          $('#tpnm_delivery_dpaddress').val( response.data.tpnm_delivery_dpaddress);
          $('#tpnm_delivery_dpopen').val( response.data.tpnm_delivery_dpopen+" - "+response.data.tpnm_delivery_dpclose);
          $('#tpnm_delivery_date').val( response.data.tpnm_delivery_date+" - "+response.data.tpnm_delivery_time);
          $('#tpnm_delivered_receiver_name').val( response.data.tpnm_delivered_receiver_name);
          $('#tpnm_delivered_receiver_phone').val( response.data.tpnm_delivered_receiver_phone);
          $('#tpnm_delivered_received_date').val( response.data.tpnm_delivered_received_date+" - "+response.data.tpnm_delivered_received_time);

          if(response.data.tpnm_fee_revisi != null){
            $('#mitra_revisi').show();
            $('#stnk_revisi').show();
            $('#tpnm_fee_revisi').val(formatter.format(parseInt(response.data.tpnm_fee)));
            $('#tpnm_fee_stnk_revisi').val(formatter.format(parseInt(response.data.tpnm_fee_stnk)));
          }else{
            $('#mitra_revisi').hide();
            if(response.data.tpnm_fee_stnk_revisi != null ){
              $('#stnk_revisi').show();
              $('#tpnm_fee_stnk_revisi').val(formatter.format(parseInt(response.data.tpnm_fee_stnk)));
            }else{
              $('#stnk_revisi').hide();
            }

          }

          if(response.data.tpnm_vtype == 'mobil'){
            $('#validasi_mobil').show();
            if( response.data.tpnm_medi_vld_mobil != null ){
              $('#tpnm_medi_vld_mobil').attr("href", response.data.tpnm_medi_vld_mobil);
            }else{
              $('#tpnm_medi_vld_mobil').addClass("disabled")
            }
            $('#detail_mobil').hide();
            $('#foto_mobil-1,#foto_mobil-2').show();
          }else{
            $('#detail_mobil').show();
            $('#foto_mobil-1,#foto_mobil-2').hide();
            $('#validasi_mobil').hide();
          }
    
          if( response.data.tpnm_medi_sk_template != null ){
            $('#tpnm_medi_sk_template').attr("href", response.data.tpnm_medi_sk_template);
          }else{
            $('#tpnm_medi_sk_template').addClass('disabled');
          }
          if( response.data.tpnm_medi_vphoto_sisi1 != null ){
            $('#tpnm_medi_vphoto_sisi1').attr("href", response.data.tpnm_medi_vphoto_sisi1);
            $('#tpnm_medi_vphoto_sisi_1').attr("href", response.data.tpnm_medi_vphoto_sisi1).show('slow');
            $('#foto_revm1_form').hide('slow')
            $('#foto_revm1_delete').show('slow')
          }else{
            $('#tpnm_medi_vphoto_sisi1').addClass('disabled');
            $('#tpnm_medi_vphoto_sisi_1').hide('slow');
            $('#foto_revm1_delete').hide('slow')
            $('#foto_revm1_form').show('slow')
          }
          $('#tpnm_medi_vphoto_sisi2').prop( "disabled", true );
          if( response.data.tpnm_medi_vphoto_sisi2 != null ){
            $('#tpnm_medi_vphoto_sisi2').attr("href", response.data.tpnm_medi_vphoto_sisi2);
            $('#vphoto_sisi_2').attr("href", response.data.tpnm_medi_vphoto_sisi2).show('slow');
            $('#foto_revm2_form').hide('slow')
            $('#foto_revm2_delete').show('slow')
          }else{
            $('#vphoto_sisi_2').hide('slow');
            $('#tpnm_medi_vphoto_sisi2').addClass('disabled');
            $('#foto_revm2_delete').hide('slow')
            $('#foto_revm2_form').show('slow')
          }
          if( response.data.tpnm_delivered_stnk_photo != null ){
            $('#tpnm_delivered_stnk_photo, #stnk_photo_2').attr("href", response.data.tpnm_delivered_stnk_photo);
            $('#stnk_photo_2').show('slow');
            $('#foto_revstnk_add').hide('slow')
            $('#foto_revstnk_delete').show('slow')
          }else{
            $('#tpnm_delivered_stnk_photo').addClass('disabled');
            $('#stnk_photo_2').hide('slow');
            $('#foto_revstnk_add').show('slow')
            $('#foto_revstnk_delete').hide('slow')
          }
          if( response.data.tpnm_medi_bast_template != null ){
            $('#tpnm_medi_bast_template').attr("href", response.data.tpnm_medi_bast_template);
          }else{
            $('#tpnm_medi_bast_template').addClass('disabled');
          }
          if( response.data.tpnm_medi_bast_final != null ){
            $('#tpnm_medi_bast_final').attr("href", response.data.tpnm_medi_bast_final);
          }else{
            $('#tpnm_medi_bast_final').addClass('disabled');
          }
          
          if( response.data.tpnm_medi_bast_backup != null ){
            $('#tpnm_revisi_bastk').attr("href", response.data.tpnm_medi_bast_backup).show('slow');
            $('#foto_revbast_delete').show('slow');
            $('#foto_revbast_add').hide('slow');
          }else{
            $('#tpnm_revisi_bastk, #foto_revbast_delete').hide('slow');
            $('#foto_revbast_add').show('slow');
          }

          if( response.data.tpnm_medi_bukti_biaya_kirim != null ) {
            $('#biayakirim_photo').attr("href", response.data.tpnm_medi_bukti_biaya_kirim).show('slow');
            $('#bukti_biayakirim_delete').show('slow');
            $('#bukti_biaya_kirim_add').hide('slow');
          }else{
            $('#biayakirim_photo, #bukti_biayakirim_delete').hide('slow');
            $('#bukti_biaya_kirim_add').show('slow');
          }
    
          // console.log("backup media "+response.data.tpnm_medi_bast_backup);
          // console.log("tvoc_type| " +props.tvoctype)
          //get fee mitra 
          // var tvoctype = props.tvoctype
          if( tvoctype == "Referred" ){
            var fee_mitra = response.data.tpnm_fee_revisi != null ? response.data.tpnm_fee_revisi : response.data.tpnm_fee
            var fee_stnk = response.data.tpnm_fee_stnk_revisi != null ? response.data.tpnm_fee_stnk_revisi : response.data.tpnm_fee_stnk
            var fee = getFeeMitra(response.data.tpnm_status_stnk,fee_mitra, fee_stnk)
            var tpnm_biaya_kirim = response.data.tpnm_biaya_kirim != null ? response.data.tpnm_biaya_kirim : 0
            $('#tpnm_fee').val(formatter.format(parseInt(fee_mitra)));
            $('#tpnm_fee_stnk').val(formatter.format(parseInt(fee_stnk)));
            $('#total_fee').val(formatter.format(parseInt(fee)));
            $('#tpnm_biaya_kirim').val(formatter.format(parseInt(tpnm_biaya_kirim)))

            console.log("total fee| " + fee)
          }

          if(tvoctype == "Referall"){
            $('#tpnm_fee').val(0);
            $('#tpnm_fee_stnk').val(0);
          }

    
          var details ;
          if ( tvoctype == "Referred"){
            if( response.data.tpnm_status_stnk == null){
              details = "<input selected type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
            }else if(response.data.tpnm_status_stnk == 0){
              details = "<input checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' disabled value='0'> Tidak Ada<br> <option></option><span></span>";
            }else{
              details = "<input type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' disabled value='0'> Tidak Ada<br><option></option><span></span>";
            }
            $('#tpnm_status_stnk_1').html(details);
            if(response.data.tpnm_status_stnk == null){
              details = "<input selected type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
            }else if(response.data.tpnm_status_stnk == 1){
              details = "<input checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' disabled value='1'> Ada<br> <option></option><span></span>";
            }else{
              details = "<input type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' disabled value='1'> Ada<br><option></option><span></span>";
            }
            $('#tpnm_status_stnk_2').html(details);
        }else{
          if( response.data.tpnm_status_stnk == null){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 0){
            details = "<input selected disabled checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' disabled value='0'> Tidak Ada<br> <option></option><span></span>";
          }else{
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' disabled value='0'> Tidak Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_1').html(details);
          if(response.data.tpnm_status_stnk == null){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 1){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' disabled value='1'> Ada<br> <option></option><span></span>";
          }else{
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' disabled value='1'> Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_2').html(details);
          // if( response.data.tpnm_medi_bast_backup == null || response.data.tpnm_medi_bast_backup == '' ){
          //   details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>\
          //              <button disabled type='button' name='foto' style='margin-top: 0px;'' id='foto_srtf_delete' class='btn btn-outline btn-circle btn-md blue'><i class='fas fa-eraser'></i> Hapus</button>";
          // }else{
          //   details = "<a href='"+response.data.tpnm_medi_bast_backup+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>\
          //             <button type='button' name='foto' style='margin-top: 0px;'' id='foto_srtf_delete' class='btn btn-outline btn-circle btn-md blue'><i class='fas fa-eraser'></i> Hapus</button>";
          // }
          // $('#foto_srtf').html(details);
        }
          if( response.data.tpnm_status_stnk == 1 || response.data.tpnm_status_stnk == 0 ){
            $('#idReject').show('slow');
            $('#idApprove').show('slow');
            $('#idVerifikasi').hide('slow');
          }else{
            $('#idReject').hide('slow');
            $('#idApprove').hide('slow');
            $('#idVerifikasi').show('slow');
          }
    
          if( response.data.tpnm_medi_bast_template == null || response.data.tpnm_medi_bast_template == '' ){
            // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
            $('#tpnm_medi_bast_template_1').addClass('disabled');
          }else{
            // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
            // details = "<a href='"+response.data.tpnm_medi_bast_template+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>";
            $('#tpnm_medi_bast_template_1').attr('href',response.data.tpnm_medi_bast_template);
          }
          // $('#tpnm_medi_bast_template_1').html(details);
    
          if( response.data.tpnm_medi_bast_final == null || response.data.tpnm_medi_bast_final == '' ){
            details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
          }else{
            // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
            details = "<a href='"+response.data.tpnm_medi_bast_final+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>";
          }
          $('#tpnm_medi_bast_final_1').html(details);
    
            $("input[type='radio']").on('change', function() {
              var tpnm_status_stnk = $("input[name='tpnm_status_stnk']:checked").val();
              var tpnm_fee_stnk = $('#tpnm_fee_stnk').val();
              var tpnm_fee = $('#tpnm_fee').val();
              var tpnm_fee_stnk_string =  tpnm_fee_stnk.replace(/\./g,'');
              var tpnm_fee_string = tpnm_fee.replace(/\./g,'');
              if( tpnm_status_stnk == 1 ){
                $('#total_fee').val(formatter.format(parseInt(tpnm_fee_stnk_string) + parseInt(tpnm_fee_string)));
              }else{
                $('#total_fee').val(formatter.format(parseInt(tpnm_fee_string)));
              }
              console.log("| "+tpnm_status_stnk);
            });
        }
          //   if (response.data.tpif_photo_validation != null) {
          //   setInforman(true)
          //   $('#tpif_fee').val(formatter.format(props.fee))
          //   $('#total_fee').val(formatter.format(props.fee))
          //   $('#Mitra_Fee').hide()
          //   $('#Mitra_STNK').hide()
          //   $('#Mitra_CekSTNK').hide()
          //   $('#Informan_Fee').show()
          // } else {
          //   setInforman(false)
          //   if (response.data.tpnm_status_stnk != null) {
          //     if (response.data.tpnm_status_stnk == 1) {
          //       $('#total_fee').val(formatter.format(parseInt(response.data.tpnm_fee)+parseInt(response.data.tpnm_fee_stnk)))
          //     }else {
          //       $('#total_fee').val(formatter.format(parseInt(response.data.tpnm_fee)))
          //     }    
          //   }
          //   $('#Mitra_Fee').show()
          //   $('#Mitra_STNK').show()
          //   $('#Mitra_CekSTNK').show()
          //   $('#Informan_Fee').hide()
          // }


          $('#mper_name_informan').val(response.data.mper_name_informan)
          $('#mper_phone_informan').val(response.data.mper_phone_informan)
          $('#tpif_lat-long_informan').val(response.data.tpif_lat_informan+" , "+response.data.tpif_lng_informan)
          $('#tpif_address_informan').val(response.data.tpif_address_informan)
          $('#tanggal_jam_validasi').val(response.data.tanggal_validasi+' - '+response.data.jam_validasi)
          $('#tanggal_jam_matched').val(response.data.tanggal_matchmaking+' - '+response.data.jam_matchmaking)
        

          if (type == 'informan') {
            $('#tpif_fee').val(formatter.format(response.data.tpif_fee_informan))
            $('#total_fee').val(formatter.format(response.data.tpif_fee_informan))
            $('#tvoc_total_fee').val(formatter.format(response.data.tpif_fee_informan))
            $('#tvoc_total_transfer').val(formatter.format(response.data.tpif_fee_informan))
            $('#Mitra_Fee').hide()
            $('#Referall_Fee').hide()
            $('#Mitra_STNK').hide()
            $('#Mitra_CekSTNK').hide()
            $('#mitra_revisi').hide()
            $('#stnk_revisi').hide()
            $('#Informan_Fee').show()
            $('#order_informan').show()
            $('#biayakirim').hide('fast')
            $("#check_lvoc_fee_antar_bank").on('change', function() {
              if( $(this).prop("checked") == true ){
                $('#tvoc_total_transfer').val(formatter.format(parseInt(response.data.tpif_fee_informan)+6500))
              }else{
                $('#tvoc_total_transfer').val(formatter.format(response.data.tpif_fee_informan))
              }
            });
            $('#tpif_photo_validation').attr("href", response.data.tpif_photo_validation);
            $('#data_informan').show()
            $('#tpif_fee').val(formatter.format(response.data.tpif_fee_informan))
            $('#foto_biayakirim').hide('fast')
          }else if(type == 'mitra' && response.data.tpif_photo_validation!= null){
            $('#tpif_photo_validation').attr("href", response.data.tpif_photo_validation);
            $('#Mitra_Fee').show()
            $('#Referall_Fee').hide()
            $('#Mitra_STNK').show()
            $('#Mitra_CekSTNK').show()
            $('#Informan_Fee').hide()
            $('#data_informan').show()
            $('#order_informan').show()
            $('#biayakirim').show('fast')
            $('#tpif_fee').val(formatter.format(response.data.tpif_fee_informan))
            $('#foto_biayakirim').show('fast')
          }else if( type == 'mitra'){
            $('#order_informan').hide()
            $('#Mitra_Fee').show()
            $('#Referall_Fee').hide()
            $('#Mitra_STNK').show()
            $('#Mitra_CekSTNK').show()
            $('#Informan_Fee').hide()
            $('#data_informan').hide()
            $('#biayakirim').show('fast')
            $('#foto_biayakirim').show('fast')
          }else if( type == 'uplinelevel1' || type == 'uplinelevel2'){
            $('#Referall_Fee').show()
            $('#Mitra_Fee').hide()
            $('#Mitra_STNK').hide()
            $('#Informan_Fee').hide()
            $('#biayakirim').hide()
            $('#foto_biayakirim').hide()
            $('#Mitra_CekSTNK').hide()
            $('#mitra_revisi').hide()
            $('#stnk_revisi').hide()
            $('#data_informan').hide()
            $('#order_informan').hide()
            $('#total_fee, #referall_fee').val(formatter.format(response.data.tvoc_ammount ? response.data.tvoc_ammount : 0))
            
          }
      })

      var fotoMobil = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi+"v1/Founding/listFotoMobil/"+props.id,
        "method": "GET",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
          }
        }
      $.ajax(fotoMobil).done(function (response){
        console.log(response);
        var htmlFoto = "";
        if(response.data != null){
  
          for(let i=0; i < response.data.length; i++){
            console.log(response.data);
            htmlFoto +=  "<div class='column' style='margin:5px;display:inline-block;' >"+
            "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+response.data[i].medi_url+">"+
              "<img style='border-radius:5px;' src="+response.data[i].medi_url+" width='120' height='120'>"+
            "</a>"+
            "</div>";
          }
          console.log(htmlFoto);
          $('#foto_mobil_url').html(htmlFoto);
        }
      })
    }
  }

  function Approve() {
    handleClose()
    var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Financeapi+"v1/VoucherTranskasi/approver/"+props.tvoc,
        "method": "GET",
        "headers": {
          "Authorization": "Basic "+localStorage.getItem('jwt'),
        }
      }

      $.ajax(settings).done(function (response) {

        props.close()
        setLoad(true)
        setAlerts([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: "Voucher Telah Diapprove"
        }])
        setTimeout(()=>{
            setAlerts([])
            history.push("/siapdibayar");
        },1000)
      }).fail(function (response) {
        console.log(response);
        setAlerts1([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code "+response.status+"]"
        }])
        setTimeout(()=>{
            setAlerts1([])
        },3000)
      })
  }

  function Reject() {
      handleClose1()
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Financeapi+"v1/VoucherTranskasi/reject/"+props.tvoc,
        "method": "GET",
        "headers": {
          "Authorization": "Basic "+localStorage.getItem('jwt'),
        }
      }
      $.ajax(settings).done(function (response) {

        props.close()
        setLoad(true)
        setAlerts([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: "Voucher Telah Direject"
        }])
        setTimeout(()=>{
            setAlerts([])
            history.push("/voucherreject");
        },1000)
      }).fail(function (response) {
        console.log(response);
        setAlerts1([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code "+response.status+"]"
        }])
        setTimeout(()=>{
            setAlerts1([])
        },3000)
      })
  }
  
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen1() {
    setOpen1(true);
  }

  function handleClose1() {
    setOpen1(false);
  }

  function handleClickOpen2() {
    setOpen2(true);
    setLoad(false)
  }

  function handleClose2() {
    setOpen2(false);
  }

  function UploadBAST(e) {
      console.log(e.target.id)
      var input = e.target.id
      var key,message,kop

      console.log(input == "foto_revbast_add")
      console.log(input == "foto_revstnk_add")

      if (input == "foto_revbast_add") {
        key = "revisi_bstk"
        message = "BAST"
        kop = "Revisi"
      }else if (input == "foto_revstnk_add"){
        key = "revisi_stnk"
        message = "STNK"
        kop = "Revisi"
      }else if (input == "foto_revm1_add"){
        key = "revisi_foto_sisi1"
        message = "Foto Sisi Motor 1"
        kop = "Revisi"
      }else if (input == "foto_revm2_add"){
        key = "revisi_foto_sisi2"
        message = "Foto Sisi Motor 2"
        kop = "Revisi"
      }else if(input == "bukti_biaya_kirim_add"){
        key = "bukti_biaya_kirim"
        message = "Bukti Biaya Kirim"
        kop = "File"
      }

      var form = new FormData();
      form.append("upload_data", e.target.files[0]);

      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi + `/v1/Founding/upload_photos/${key}/${props.id}`,
        "method": "POST",
        "headers": {
          "Authorization": "Basic " + localStorage.getItem('jwt'),
        },
        "processData": false,
        "contentType": false,
        "data": form
      }

      $.ajax(settings).done(function (response) {
        RequestPenemuan()
        setAlerts1([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: `${kop} ${message} Telah Diupload`
        }])
        setTimeout(()=>{
            setAlerts1([])
        },3000)
      }).fail(function (response) {
        setAlerts1([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code "+response.status+"]"
        }])
        setTimeout(()=>{
            setAlerts1([])
        },3000)
      })
  }

  function HapusBAST(e) {

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_medi_bast_backup`,
      "method": "POST",
      "headers": {
        "Authorization": "Basic "+localStorage.getItem('jwt')
      }
    }

    $.ajax(settings).done(function (response) {
      RequestPenemuan()
      setAlerts1([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: `Revisi BAST Telah Dihapus`
      }])
      setTimeout(()=>{
          setAlerts1([])
        },3000)
    }).fail(function (response) {
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
  }

  function HapusSTNK() {

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_delivered_stnk_photo`,
      "method": "POST",
      "headers": {
        "Authorization": "Basic "+localStorage.getItem('jwt')
      }
    }

    $.ajax(settings).done(function (response) {
      RequestPenemuan()
      setAlerts1([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: `Revisi STNK Telah Dihapus`
      }])
      setTimeout(()=>{
          setAlerts1([])
        },3000)
    }).fail(function (response) {
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
  }

  function HapusM1() {

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_medi_vphoto_sisi1`,
      "method": "POST",
      "headers": {
        "Authorization": "Basic "+localStorage.getItem('jwt')
      }
    }

    $.ajax(settings).done(function (response) {
      RequestPenemuan()
      setAlerts1([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: `Revisi Foto Motor Sisi 1 Telah Dihapus`
      }])
      setTimeout(()=>{
          setAlerts1([])
        },3000)
    }).fail(function (response) {
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
  }

  function HapusM2() {

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_medi_vphoto_sisi2`,
      "method": "POST",
      "headers": {
        "Authorization": "Basic "+localStorage.getItem('jwt')
      }
    }

    $.ajax(settings).done(function (response) {
      RequestPenemuan()
      setAlerts1([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: `Revisi Foto Motor Sisi 2 Telah Dihapus`
      }])
      setTimeout(()=>{
          setAlerts1([])
        },3000)
    }).fail(function (response) {
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
  }

  //Delete Bukti Biaya Kirim
  function HapusBukti_BK() {
    var settings = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Foundingapi}v1/founding/deleteFoto/${props.id}/tpnm_medi_bukti_biaya_kirim`,
      "method": "POST",
      "headers": {
        "Authorization": "Basic "+localStorage.getItem('jwt')
      }
    }

    $.ajax(settings).done(function (response) {
      RequestPenemuan()
      setAlerts1([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: `Bukti Biaya Kirim Telah Dihapus`
      }])
      setTimeout(()=>{
          setAlerts1([])
        },3000)
    }).fail(function (response) {
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
  }

  return (
    <div>
      <AlertList showIcon={false} alerts={alerts}/>
      <DialogHapusBatal                 
          open={open2} 
          close={handleClose2} 
          title="Verifikasi STNK Voucher" 
          message="Anda Yakin Ingin Verifikasi Voucher Ini ?" 
          btn="Verifikasi" 
          btn2="Batal" 
          // type="okbatal" 
          fun={Verifikasi}
      />
      <DialogHapusBatal                 
          open={open} 
          close={handleClose} 
          title="Approve Voucher" 
          message="Anda Yakin Ingin Approve Voucher Ini ?" 
          btn="Approve" 
          btn2="Batal" 
          // type="okbatal" 
          fun={Approve}
      />
      <DialogHapusBatal                 
          open={open1} 
          close={handleClose1} 
          title="Reject Voucher" 
          message="Anda Yakin Ingin Reject Voucher Ini ?" 
          btn="Reject" 
          btn2="Batal" 
          // type="okbatal" 
          fun={Reject}
      />
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <AntTabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable"
                scrollButtons="auto" aria-label="scrollable auto tabs example" className={classes.title}>
                <AntTab label="Data Kendaraan" {...a11yProps(0)} />
                <AntTab label="Order Details" {...a11yProps(1)} />
                <AntTab label="Dokumen Verifikasi" {...a11yProps(2)} />
            </AntTabs>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <AlertList showIcon={false} alerts={alerts1}/>
        <SwipeableViews axis={theme.direction==='rtl' ? 'x-reverse' : 'x' } index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0}>
            <Portlet>
                <PortletHeader title="Data Kendaraan"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Polisi</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id='tpnm_vplate' disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Rangka</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_vin" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Mesin</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_ein" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Type Motor/Mobil</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_vtype" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama STNK/BPKB</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_vdoc_name" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor Kontrak</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_contract" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Asal Cabang</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_branch" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                <PortletHeader title="Profile Debitur"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Debitur</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_customer_name" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Alamat Konsumen</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_address" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor KTP</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_ic_number" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nomor KK</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_kk_number" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Mulai Tunggak</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_debt_date" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Angsuran Ke</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_installment" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Jumlah Kwitansi</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_invoice_qty" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tenor</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_duration" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Angsuran Perbulan</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_monthly_charge" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Total Tunggakkan</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_total_unpaid" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                <Portlet id='data_informan'>
                  <PortletHeader title="Data Informan"></PortletHeader>
                  <PortletBody>
                    <Row>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Informan</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" id="mper_name_informan" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Informan</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" id="mper_phone_informan" disabled/>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Lat-Long Informan</label>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="tpif_lat-long_informan" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Posisi Informan</label>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="tpif_address_informan" disabled/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </PortletBody>
                </Portlet>
                <PortletHeader title="Data Mitra"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Mitra</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="mper_name" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">No HP Mitra</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="mper_phone" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
              </Portlet>
              <Row>
                <Col md>
                  <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Portlet>
                <PortletHeader title="Order Detail"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Validasi</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_created_date" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Menunggu SK</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_sk_initiated_date" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Surat Kerja Diapprove</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_data_approved_date" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Informan</label>
                        <div className="col-sm-8">
                        <input type="text" class="form-control" id="tpif_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Mitra</label>
                        <div className="col-sm-8">
                        <input type="text" class="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Surat Tugas</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_sk_template" target='_blank' disabled="disabled"><i className="far fa-file-pdf"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" id="validasi_mobil" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Validasi Mobil</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_vld_mobil" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                      <Row id="detail_mobil">
                        <Col>
                          <div className="form-group row" style={{marginTop:'-15px'}}>
                            <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Motor Sisi 1</label>
                            <div className="col-sm-8">
                              <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_vphoto_sisi1" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                            </div>
                          </div>
                          <div className="form-group row" style={{marginTop:'-15px'}}>
                            <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Motor Sisi 2</label>
                            <div className="col-sm-8">
                              <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_vphoto_sisi2" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                            </div>
                          </div>
                        </Col>
                      </Row>   
                    </Col>
                  </Row>
                </PortletBody>
                <Portlet id="order_informan">
                <PortletHeader title="Informan"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Validasi Informan</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tanggal_jam_validasi" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Matched</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tanggal_jam_matched" disabled/>
                        </div>
                      </div>
                      {/* <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Validasi Mitra</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_created_date2" disabled/>
                        </div>
                      </div> */}
                    </Col>
                    <Col md={6} id="detail_mobil">
                      {/* <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Informan</label>
                        <div className="col-sm-8">
                        <input type="text" class="form-control" id="tpif_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Mitra</label>
                        <div className="col-sm-8">
                        <input type="text" class="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div> */}
                      <div className="form-group row" 
                      // style={{marginTop:'-15px'}}
                      >
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Validasi Informan</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpif_photo_validation" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                </Portlet>
                <PortletHeader title="Detail Drop Point"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Drop Point</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivery_dpname1" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Drop Point Lat-Lng</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivery_dplat" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Alamat Drop Point</label>
                        <div className="col-sm-7">
                          <textarea type="text" className="form-control" id="tpnm_delivery_dpaddress" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Jam Operasional Drop Point</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_delivery_dpopen" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Dan Jam Antar</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_delivery_date" disabled/>
                        </div>
                      </div>
                      {/* <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Ke Mitra Eksekutor</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div> */}
                    </Col>
                  </Row>
                </PortletBody>
                <div id="foto_mobil-1">
                  <PortletHeader title="Foto"/>
                  <PortletBody id="foto_mobil-2">
                    <div class='col-md-12' style={{marginTop:'10px',overflow:'auto',height:'150px',whiteSpace: 'nowrap'}} id='foto_mobil_url'></div>
                  </PortletBody>
                </div>
              </Portlet>
              <Row>
                <Col md>
                  <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <Portlet>
                <PortletHeader title="Dokumen Verifikasi"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Penerima</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivered_receiver_name" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">No HP Penerima</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivered_receiver_phone" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal & Jam Penerima</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_delivered_received_date" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="Informan_Fee">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Informan</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpif_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="Referall_Fee">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Referall</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="referall_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="mitra_revisi">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Ke Mitra Eksekutor Sebelum Revisi</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_fee_revisi" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="stnk_revisi">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee STNK Sebelum Revisi</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_fee_stnk_revisi" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="biayakirim">
                          <label for="inputEmail3" className="col-sm-4 col-form-label">Biaya Kirim Subsidi Mitra</label>
                          <div className="col-sm-7">
                            <input type="text" className="form-control" id="tpnm_biaya_kirim" disabled/>
                          </div>
                        </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="Mitra_Fee">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Ke Mitra Eksekutor</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="Mitra_STNK">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee STNK</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="tpnm_fee_stnk" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Total Fee</label>
                        <div className="col-sm-7">
                          <input type="text" className="form-control" id="total_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="Mitra_CekSTNK">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Cek Keaslian STNK</label>
                        <div className="col-sm-7">
                          <div className="form-check form-check-inline mt-2">
                              {/* <input className="form-check-input" type="radio" name="mper_status" id="mper_status" value="0"/> */}
                              <div id='tpnm_status_stnk_1'> </div>
                              {/* <label className="form-check-label" for="exampleRadios1">
                                  Tidak Ada
                              </label> */}
                          </div>
                          <div className="form-check form-check-inline">
                              {/* <input className="form-check-input" type="radio" name="mper_status" id="mper_status" value="1"/> */}
                              <div id='tpnm_status_stnk_2'> </div>
                              {/* <label className="form-check-label" for="exampleRadios1">
                                  Ada
                              </label> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-3 col-form-label">PDF BAST</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_bast_template_1" target='_blank' disabled="disabled"><i className="far fa-file-pdf"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-3 col-form-label">BAST Akhir</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_bast_final" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-3 col-form-label">Foto STNK</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_delivered_stnk_photo" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-3 col-form-label">Revisi BAST</label>
                        <div className="col-sm-8" style={{display:'inline'}}>
                          <input id="foto_revbast_add" type="file" onChange={UploadBAST} className="form-control" name="rev"/>
                          <a type="button" className="btn btn-outline-brand btn-icon mr-1" id="tpnm_revisi_bastk" target='_blank'><i className="far fa-image"></i></a>
                          <button type="button" className="btn btn-outline-brand" onClick={HapusBAST} id="foto_revbast_delete" target='_blank'><i className="fas fa-eraser"></i>Hapus</button>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-3 col-form-label">Revisi STNK</label>
                        <div className="col-sm-8" style={{display:'inline'}}>
                          <input id="foto_revstnk_add" type="file" onChange={UploadBAST} className="form-control" name="rev"/>
                          <a type="button" className="btn btn-outline-brand btn-icon mr-1" id="stnk_photo_2" target='_blank'><i className="far fa-image"></i></a>
                          <button type="button" className="btn btn-outline-brand" onClick={HapusSTNK} id="foto_revstnk_delete" target='_blank'><i className="fas fa-eraser"></i>Hapus</button>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-3 col-form-label">Revisi 2 Sisi Motor</label>
                        <div className="col-sm-8 col-form-label" style={{display:'inline'}}>
                          <div class="form-group" style={{border:'solid #e2e5ec 1px',padding:'5px',borderRadius:'5px'}}>
                            <label for="exampleFormControlInput1">Foto Motor Sisi 1</label>
                            <div class="custom-file" id="foto_revm1_form">
                              <input type="file" class="custom-file-input" onChange={UploadBAST} id="foto_revm1_add"/>
                              <label class="custom-file-label" for="customFile">Pilih file</label>
                            </div>
                            {/* <a type="button" className="btn btn-outline-brand btn-icon mr-1" id="tpnm_revisi_bastk" target='_blank'><i className="far fa-image"></i></a> */}
                              <a type="button" className="btn btn-outline-brand btn-icon mr-1 ml-4" id="tpnm_medi_vphoto_sisi_1" target='_blank'><i className="far fa-image"></i></a>
                            <button type="button" className="btn btn-outline-brand ml-1" onClick={HapusM1} id="foto_revm1_delete" target='_blank'><i className="fas fa-eraser"></i>Hapus</button>
                          </div>
                          <div class="form-group" style={{marginTop:'-15px',border:'solid #e2e5ec 1px',padding:'5px',borderRadius:'5px'}}>
                            <label for="exampleFormControlInput1">Foto Motor Sisi 2</label>
                            <div class="custom-file" id="foto_revm2_form">
                              <input type="file" class="custom-file-input" onChange={UploadBAST} id="foto_revm2_add"/>
                              <label class="custom-file-label" for="customFile">Pilih file</label>
                            </div>
                            {/* <a type="button" className="btn btn-outline-brand btn-icon mr-1" id="tpnm_revisi_bastk" target='_blank'><i className="far fa-image"></i></a> */}
                              <a type="button" className="btn btn-outline-brand btn-icon mr-1 ml-4" id="vphoto_sisi_2" target='_blank'><i className="far fa-image"></i></a>
                            <button type="button" className="btn btn-outline-brand ml-1" onClick={HapusM2} id="foto_revm2_delete" target='_blank'><i className="fas fa-eraser"></i>Hapus</button>
                          </div>
                          {/* <input id="foto_revbast_add" type="file" onChange={UploadBAST} className="form-control" name="rev"/>
                          <a type="button" className="btn btn-outline-brand btn-icon mr-1" id="tpnm_revisi_bastk" target='_blank'><i className="far fa-image"></i></a>
                          <button type="button" className="btn btn-outline-brand" onClick={HapusBAST} id="foto_srtf_delete" target='_blank'><i className="fas fa-eraser"></i>Hapus</button> */}
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id= "foto_biayakirim">
                        <label for="inputEmail3" className="col-sm-3 col-form-label">Bukti Tagihan Biaya Kirim</label>
                        <div className="col-sm-8" style={{display:'inline'}}>
                          <input id="bukti_biaya_kirim_add" type="file" onChange={UploadBAST} className="form-control" name="rev"/>
                          <a type="button" className="btn btn-outline-brand btn-icon mr-1" id="biayakirim_photo" target='_blank'><i className="far fa-image"></i></a>
                          <button type="button" className="btn btn-outline-brand" onClick={HapusBukti_BK} id="bukti_biayakirim_delete" target='_blank'><i className="fas fa-eraser"></i>Hapus</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md>
                      <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                      <button type="button" className="btn btn-success btn-elevate pull-right" id="idApprove" onClick={handleClickOpen}><i class="fas fa-check"></i> Approve</button>
                      <button type="button" className="btn btn-danger btn-elevate pull-right mr-1" id="idReject" onClick={handleClickOpen1}><i class="fas fa-times"></i> Reject</button>
                      <button type="button" className="btn btn-brand btn-elevate pull-right" id="idVerifikasi" onClick={handleClickOpen2}><i className="fas fa-check-double"></i>Verifikasi</button>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={6}>
                    </Col>
                    <Col md={6}>
                    </Col>
                  </Row> */}
                </PortletBody>
              </Portlet>
            </TabPanel>
        </SwipeableViews>
      </Dialog>
    </div>
  );
}
