/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import API from '../../API'
import $ from 'jquery'
import 'jquery-validation'
import { AlertList} from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
}));

export default function DialogOnboard(props) {

    const [alerts,setAlerts] = React.useState([])
    const [onboard, setOnboard ] = React.useState(null)

    const classes = useStyles();

    React.useEffect(()=>{
        $("#dialogonboard").validate();
    })

    const Submit = () => {
            var form = new FormData();

            form.append("mtob_title", $("#mtob_title").val().toString());
            form.append("mtob_body", $("#mtob_body").val().toString());
            form.append("mtob_type", "informan");
            form.append("foto_onboard", onboard);

            var settings = {
                "async": true,
                "crossDomain": true,
                "url": API.Userapi + "v1/user/addOnBoard",
                "method": "POST",
                "headers": {
                    "Authorization": "Baisc " + localStorage.getItem('jwt')
                },
                "contentType": false,
                "mimeType": "multipart/form-data",
                "processData": false,
                "data": form
            }

            console.log($("#dialogonboard").valid())
            if($("#dialogonboard").valid() == true){
                $.ajax(settings).done(function (response) {
                    props.close()
                    setAlerts([{
                            id: 1,
                            type: "success",
                            headline: "Berhasil",
                            message: "Onboard Telah Ditambahkan"
                        }])
                    setTimeout(()=>{
                        setAlerts([])
                    },3000)
                    return 0
                }).fail(function (response) {
                    console.log(response);
                    setAlerts([{
                        id: 1,
                        type: "danger",
                        headline: "Gagal",
                        message: "Terjadi Kendala [Error Code "+response.status+"]"
                    }])
                    setTimeout(()=>{
                        setAlerts([])
                    },3000)
                    return 0
                });
            }
    }

     const Onboard = (e) => {
        setOnboard(e.target.files[0])
    }

  return (
    <div>
        <AlertList showIcon={false} alerts={alerts}/>
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="xs"
            keepMounted
            onClose={props.close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
                <form id="dialogonboard" onSubmit={(e)=>{e.preventDefault()}}>
            <div className={classes.root}>
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Gambar</label>
                        <input type="file" className="form-control" id="foto_user_add" onChange={Onboard} name="Fuser" required/>
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Judul</label>
                        <input type="text" className="form-control" id="mtob_title" name="mtob_title" required/>
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Teks</label>
                        <textarea className="form-control" id="mtob_body" name="mtob_body" rows="3" required></textarea>
                    </div>
            </div>
                    <button type="submit" className="btn btn-primary btn-elevate pull-right" onClick={Submit} color="primary"><i className="fas fa-plus"></i>Tambah</button>
                    <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Batal</button>
                </form>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={props.close} color="primary">
            Batal
          </Button>
          <Button onClick={Submit} color="primary">
            Tambah
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );

}

