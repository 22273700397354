/* eslint-disable no-cond-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme  } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { AlertList} from "react-bs-notifier";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
import $ from 'jquery'
import DialogHapusBatal from './DialogHapusBatal'
import 'jquery-mask-plugin'
// import DialogNotif from './DialogNotif'
// import { set } from 'date-fns/esm';

const formatter = new Intl.NumberFormat('de-DE');

const AntTabs = withStyles({
    root: {
      // borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      // backgroundColor: '#1890ff',
    },
  })(Tabs);
  
  const AntTab = withStyles(theme => ({
    root: {
      textTransform: 'bold',
      minWidth: 80,
      // fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#ffffff',
        opacity: 1,
      },
      '&$selected': {
        color: '#ffffff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#f4a240',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailNego(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);    
  const [open1, setOpen1] = React.useState(false);    
  const [open2, setOpen2] = React.useState(false);    
  // const [open3, setOpen3] = React.useState(false);    
  const [open4, setOpen4] = React.useState(false);    
  const [value, setValue] = React.useState(0);
  const [id, setId] = React.useState(null);
  const [idletter, setIdLetter] = React.useState(null);
  const [filesuratkuasa, setFileSuratKuasa] = React.useState(null);
  const [filefidusia, setFileFidusia] = React.useState(null);
  const [filekartupiutang, setFileKartuPiutang] = React.useState(null);
  const [filesuratperingatan, setFileSuratPeringatan] = React.useState(null);
  const [filejanjibayar, setFileJanjiBayar] = React.useState(null);
  const [filelainya, setFileLainya] = React.useState(null);
  const [suratkuasa, setSuratKuasa] = React.useState(1);
  const [fidusia, setFidusia] = React.useState(1);
  const [kartu_piutang, setKartuPiutang] = React.useState(1);
  const [suratperingatan, setSuratPeringatan] = React.useState(1);
  const [janji_bayar, setJanjiBayar] = React.useState(1);
  const [lainya, setLainya] = React.useState(1);
  // const [status, setStatus] = React.useState("");
  const [alerts, setAlerts] = React.useState([]);
  const [alerts1, setAlerts1] = React.useState([]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen1() {
    setOpen1(true);
  }

  function handleClose1() {
    setOpen1(false);
  }

  function handleClickOpen2() {
    setOpen2(true);
  }

  function handleClose2() {
    setOpen2(false);
  }

  // function handleClickOpen3() {
  //   setOpen3(true);
  // }

  // function handleClose3() {
  //   setOpen3(false);
  // }

  function handleClickOpen4() {
    setOpen4(true);
  }

  function handleClose4() {
    setOpen4(false);
  }

  function ApproveNego() {
    var harga_nego = $("#chnl_nominal_after").val();
    harga_nego = harga_nego.replace(/\./g,'');
    var harga_nego_diskon = parseInt(harga_nego * 10/100);
    var harga_after_diskon = (harga_nego - harga_nego_diskon);
    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi+"/v1/Founding/checkLeasingNego/"+props.id,
      "method": "POST",
      "headers": {
        "Authorization":  "Basic "+localStorage.getItem('jwt'),
        "Content-Type": "application/json",
      },
      "processData": false,
      "data": "{\n\t\"status\":2,\n\t\"harga_nego\": "+harga_after_diskon+"\n}"
    }
    handleClose1()
    $.ajax(settings).done(function (response) {
      props.close()
      setAlerts([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: "Nego Telah Diapprove"
      }])
      setTimeout(()=>{
          setAlerts([])
      },3000)
    }).fail(function (response) {
      console.log(response);
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
  }

  function RejectNego() {
    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi+"/v1/Founding/checkLeasingNego/"+props.id,
      "method": "POST",
      "headers": {
        "Authorization":  "Basic "+localStorage.getItem('jwt'),
        "Content-Type": "application/json",
      },
      "processData": false,
      "data": "{\n\t\"status\":3,\n\t\"harga_nego\": 0\n}"
    }
    handleClose()
    $.ajax(settings).done(function (response) {
      props.close()
      setAlerts([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: "Nego Telah Direject"
      }])
      setTimeout(()=>{
          setAlerts([])
      },3000)
    }).fail(function (response) {
      console.log(response);
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
  }

  function HapusNego() {
    var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi+"v1/Letter/del/"+idletter,
        "method": "GET",
        "headers": {
          "Authorization": "Basic "+ localStorage.getItem('jwt'),
        },
        "processData": false,
        "contentType": false,
      }
      
    handleClose2()

    $.ajax(settings).done(function (response) {
      setAlerts1([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: "File Nego Telah Dihapus"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    }).fail(function (response) {
      console.log(response);
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
    Request()
  }

  const ImageChange = (e) => { 
    console.log(e.target.id)
    if (e.target.id = "filesuratkuasa") {
      setFileSuratKuasa(e.target.files[0]);
    }
    if (e.target.id = "filefidusia") {
      console.log("FidusiaTrue")
      setFileFidusia(e.target.files[0])
    }
    if (e.target.id = "filesuratperingatan") {
      setFileSuratPeringatan(e.target.files[0])
    }
    if (e.target.id = "filejanjibayar") {
      setFileJanjiBayar(e.target.files[0])
    }
    if (e.target.id = "filekartupiutang") {
      setFileKartuPiutang(e.target.files[0])
    }
    if (e.target.id = "filelainya") {
      setFileLainya(e.target.files[0])
    }
    console.log(e.target.files[0])
  }

  function Upload() {
    var key = ""
    var file = undefined
    var count = undefined
    // var counter = undefined
    var name = ""
    console.log(id)
    if(id == "suratkuasa"){
      key = "surat_kuasa"
      count = suratkuasa
      file = filesuratkuasa 
      name = "Surat Kuasa "
    }else if(id == "fidusia"){
      key = "fidusia"
      count = fidusia
      file = filefidusia
      name = "Fidusia "
    }else if(id == "suratperingatan"){
      key = "surat_peringatan"
      count = suratperingatan
      file = filesuratperingatan
      name = "Surat Peringatan "
    }else if(id == "janjibayar"){
      key = "bukti_janji_bayar"
      count = janji_bayar
      file = filejanjibayar
      name = "Bukti Janji Bayar "
    }else if(id == "kartupiutang"){
      key = "kartu_piutang"
      count = kartu_piutang
      file = filekartupiutang
      name = "Kartu Piutang "
    }else if(id == "lainya"){
      key = "lain_lain"
      count = lainya
      file = filelainya
      name = $('#filelainya-title').val().replace(new RegExp(/\//g),'').replace(new RegExp(/\\/g),'')+" "
    }

    console.log("key = "+key)
    console.log(file)
    // console.log("count = "+count)

    var form = new FormData();
    form.append("key", key);
    form.append("upload_data", file);
    form.append("tpnm_id", props.id);
    form.append("letter_name", name + String(count))

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi  +"v1/Letter/add",
      "method": "POST",
      "headers": {
          "Authorization": "Basic "+ localStorage.getItem('jwt'),
        },
        "processData": false,
        "contentType": false,
        "data": form
      }
    $.ajax(settings).done(function (response) {
      $('input[type="file"]').val('')

      if(id == "suratkuasa"){
        setSuratKuasa(count+1)
      }else if(id == "fidusia"){
        setFidusia(count+1)
      }else if(id == "suratperingatan"){
        setSuratPeringatan(count+1)
      }else if(id == "janjibayar"){
        setJanjiBayar(count+1)
      }else if(id == "kartupiutang"){
        setKartuPiutang(count+1)
      }else if(id == "lainya"){
        setLainya(count+1)
      }

      setAlerts1([{
        id: 1,
        type: "success",
        headline: "Berhasil",
        message: "File"+name+"Telah Diupload"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    }).fail(function (response) {
      console.log(response);
      setAlerts1([{
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Terjadi Kendala [Error Code "+response.status+"]"
      }])
      setTimeout(()=>{
          setAlerts1([])
      },3000)
    })
  }

  function Request() {
    var i
    if(props.id != null){
      var AlasanNego = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi+"v1/Chronology/index/"+props.id,
        "method": "GET",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
          }
        }
        $.ajax(AlasanNego).done(function (response){
          // console.log(response);
          var htmlAlasan = "";
          var htmlUrl = "";
          if( response.data != null){
            if(response.data['kronologi']['chnl_url'] != null){
              $('#tpnm_nego_kronologi').attr("href", response.data['kronologi']['chnl_url']);
              $('#tpnm_nego_kronologi').prop("disabled",true);
              // console.log(response.data['kronologi']['chnl_url'] +" sbhfkjds");
            }
            if( localStorage.getItem('role') == "5" ) {
              $('#div_surat_nego_mitra').css('display','block');
            }
            if( localStorage.getItem('role') == "5" || localStorage.getItem('role') == "4" ){
              $('#div_surat_nego_leasing').css('display','block');
              if(response.data['kronologi']['chnl_nominal_pdf_leasing'] != null){
                $('#tpnm_nego_kronologi_leasing').attr("href", response.data['kronologi']['chnl_nominal_pdf_leasing']);
                $('#tpnm_nego_kronologi_leasing').prop("disabled",true);
                // console.log(response.data['kronologi']['chnl_url'] +" sbhfkjds");
              }
            }
            // $( '#chnl_nominal_after' ).mask('0.000.000.000', {reverse: true});
            var nominal_before_1 = response.data['kronologi']['chnl_nominal_before'] * 11.13 / 100;
            console.log(nominal_before_1 +"  Before");
            var telo = Math.ceil(nominal_before_1);
            // console.log(telo + "  Telo");
            // console.log(Math.ceil( telo / Math.pow(10, telo.toString().length - 3) ) * Math.pow(10, telo.toString().length - 3) + " TEST WOIII");
            nominal_before_1 = Math.ceil( telo / Math.pow(10, telo.toString().length - 3) ) * Math.pow(10, telo.toString().length - 3);
            var nominal_before = response.data['kronologi']['chnl_nominal_before'];
            var nominal_base = response.data['kronologi']['chnl_nominal_base'];
            // var harga_total = parseInt(nominal_before_1) + parseInt(nominal_before);
  
            $('#chnl_nominal_base').val( formatter.format(parseInt(nominal_base) ) );
            $('#chnl_nominal_before').val(formatter.format(parseInt(nominal_before_1) + parseInt(nominal_before)));
  
            if( response.data['nego'] != null ){
                for(i=0; i < response.data['nego'].length; i++){
                  htmlAlasan += "<div class='form-check'>"+
                  "<input class='form-check-input' type='checkbox' checked='true' value='' id='defaultCheck1>"+
                  "<label type='text' class='form-check-label' for='defaultCheck1' id='ngrs_name'>&nbsp"+response.data['nego'][i].ngrs_name+"</label>"+
                "</div>";
                }
            }
            if(response.data['kronologi']['chnl_status'] == 0){
              $('#approve').hide();
              $('#reject').show('slow');
            }
            else if(response.data['kronologi']['chnl_status'] != 1){
              $('#approve').hide();
              $('#reject').hide('slow');
            }else{
              var harga_nego = $('#chnl_nominal_after').val();
              if(harga_nego == ""){
                $('#approve').hide();
                $('#reject').show('slow');
              }
              $(document).on("keyup",".harga_nego",function () {
                harga_nego = $('#chnl_nominal_after').val();
                harga_nego = harga_nego.replace(/\./g,'');
                // if(parseInt(harga_nego) <= nominal_base){
                if(parseInt(harga_nego) < nominal_base || parseInt(harga_nego) === nominal_base){
                // if(parseInt(harga_nego) <= harga_total+(harga_total*(5/100))){
                  $('#approve').hide();
                  $('#reject').show('slow');
                  $('#message').html('Harga nego tidak boleh kurang dari harga awal').css('color', 'red');
                }else if (harga_nego == ""){
                  $('#approve').hide();
                  $('#reject').show('slow');
                  $('#message').html('');
                }else{
                  $('#approve').show();
                  $('#reject').show('slow');
                  $('#message').html('Harga nego diterima').css('color', 'green');
                }
            });
  
          }
            // console.log(htmlAlasan);
            $('#semua_alasan').html( htmlAlasan);
            if( response.data['url'] != null ){
                for(i=0; i < response.data['url'].length; i++){
                  // console.log(response.data['url'][i].chnl_url);
                  htmlUrl +=  "<div class='column' style='margin:5px;display:inline-block;' >"+
                  "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+response.data['url'][i].chnl_url+">"+
                    "<img style='border-radius:5px;max-width:200px;max-height:200px' src="+response.data['url'][i].chnl_url+">"+
                  "</a>"+
                "</div>";
                }
            }
            // console.log(htmlUrl);
            $('#url_mobil').html( htmlUrl);
          }
  
          if((response.data['kronologi']['chnl_status'] == 3 || response.data['kronologi']['chnl_status'] == 2)){
            // eslint-disable-next-line no-unused-vars
            var a = $('#chnl_nominal_after').val(formatter.format(parseInt(response.data.kronologi.chnl_nominal_after) / 0.9)).attr("disabled","disabled");
            // console.log(formatter.format(parseInt(response.data.kronologi.chnl_nominal_after) - parseInt(response.data.kronologi.chnl_nominal_after *10/100)) + "  INI MAS");
          }
  
      })
  
      var Terupload = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi+"v1/Letter/index/"+props.id,
        "method": "GET",
        "headers": {
            "Authorization": "Basic "+ localStorage.getItem('jwt'),
          },
          "processData": false,
          "contentType": false,
        }
      
      $.ajax(Terupload).done(function (response) {
        var histsuratkuasa = 0
        var histfidusia = 0
        var histsuratperingatan = 0
        var histjanjibayar = 0
        var histkartupiutang = 0
        var histlainya = 0
        histsuratkuasa = 0
        histfidusia = 0
        histsuratperingatan = 0
        histjanjibayar = 0
        histkartupiutang = 0
        histlainya = 0
        // console.log(response)
        $('#FileTerupload').html(``)
        for (var index = 0; index < response.data.length; index++) {
          // console.log(response.data[index]);
          // console.log(response.data.length);
          // console.log(index);
              if (response.data[index].letter_type == 'surat_kuasa') {
                histsuratkuasa++    
              }else if (response.data[index].letter_type == 'fidusia') {
                histfidusia++
              }else if (response.data[index].letter_type == 'kartu_piutang') {
                histkartupiutang++
              }else if (response.data[index].letter_type == 'surat_peringatan') {
                histsuratperingatan++
              }else if (response.data[index].letter_type == 'bukti_janji_bayar') {
                histjanjibayar++
              }else if (response.data[index].letter_type == 'lain_lain') {
                histlainya++
              }
              console.log(histsuratkuasa)
              console.log(histfidusia)
              console.log(histkartupiutang)
              console.log(histsuratperingatan)
              console.log(histjanjibayar)
              console.log(histlainya)
              if (histsuratkuasa > 0) {
                $("#ResetSuratKuasa").show()
              }
              if (histfidusia > 0) {
                $("#ResetFidusia").show()
              }
              if (histsuratperingatan > 0) {
                $("#ResetPeringatan").show()
              }
              if (histjanjibayar > 0) {
                $("#ResetJanjiBayar").show()
              }
              if (histkartupiutang > 0) {
                $("#ResetPiutang").show()
              }
              if (histlainya > 0) {
                $("#ResetLainya").show()
              }
              
          // setSuratKuasa(suratkuasa+histsuratkuasa)
          // setFidusia(fidusia+histfidusia)
          // setSuratPeringatan(suratperingatan+histsuratperingatan)
          // setJanjiBayar(janji_bayar+histjanjibayar)
          // setKartuPiutang(kartu_piutang+histkartupiutang)
          // setLainya(lainya+histlainya)
          if (props.step != 3) {
            $('.terupload').css('max-height','40vh');
            $('#semuaupload').hide();
            $('#FileTerupload').css('height','31vh');
            $('#FileTerupload').append(`
            <div class="row" style="width:300px;">
              <div class='col-md-8'>
                <label style="margin-top: 10px;">${response.data[index].letter_name}</label>
                <small id="emailHelp" class="form-text text-muted" style="display:block;margin-top:-7px">${response.data[index].letter_created_date} | ${response.data[index].letter_created_time}</small>
              </div>
              <div class="entry input-group col-md-3" style="margin-top:5px;">
                <!-- <input class="form-control" style="margin-top: 5px;" id="" type="text" placeholder="Placeholder" value="${response.data[index].letter_file}" disabled/> -->
                <span class="input-group-btn">
                  <a type="button" href="${response.data[index].letter_file}" target="_blank" class="btn btn-outline-info btn-icon btn-sm mb-1 mr-1" style="margin:2px;margin-top: 5px;border-radius: 5px;">
                    <i class="fas fa-eye"></i>
                  </a>
                </span>
              </div>
            </div>
            `);
          } else {
            $('#semuaupload').show();
            $('#FileTerupload').append(`
            <div class="row" style="width:250px;">
              <div class='col-md-6'>
                <label">${response.data[index].letter_name}</label>
              </div>
              <div class="entry input-group col-md-5">
                  <button type="button" id="${response.data[index].letter_id}" class="btn btn-outline-danger btn-icon btn-sm mb-1 mr-1 hapusletter">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <a type="button" href="${response.data[index].letter_file}" target="_blank" title="That&apos;s what this widget is" class="btn btn-outline-brand btn-icon btn-sm mb-1 mr-1">
                    <i class="fas fa-eye"></i>
                  </a>
              </div>
            </div>
            `);
          }
        }
        return 0
      })
      }
  }

  function SimpanNego(){
    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi+"v1/Letter/save_foreground/"+props.id,
      "method": "GET",
      "headers": {
        "Authorization": "Basic "+ localStorage.getItem('jwt'),
        },
        "processData": false,
        "contentType": false,
      }
      
      handleClose4()
      $.ajax(settings).done(function (response) {
        setAlerts1([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: "File Nego Telah Disimpan"
        }])
        setTimeout(()=>{
            setAlerts1([])
        },3000)
      }).fail(function (response) {
        console.log(response);
        setAlerts1([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code "+response.status+"]"
        }])
        setTimeout(()=>{
            setAlerts1([])
        },3000)
      })
  }

  React.useEffect(() => {
    $( '#chnl_nominal_after' ).mask('0.000.000.000', {reverse: true});
    var i

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi +"v1/penemuan/getPenemuanMaster/"+props.id+"/"+props.step,
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }
    
    if (props.id != null && props.step != null) {      
      $.ajax(settings).done(function (response) {
        // console.log(response)
        if( response.data != null ){
          $('#mper_name').val(response.data.mper_name);
          $('#mper_phone').val(response.data.mper_phone);
          $('#tpnm_vplate').val(response.data.tpnm_vplate);
          $('#tpnm_vin').val(response.data.tpnm_vin);
          $('#tpnm_ein').val(response.data.tpnm_ein);
          $('#tpnm_vtype').val(response.data.tpnm_vtype);
          $('#tpnm_vdoc_name').val(response.data.tpnm_vdoc_name);
          $('#tpnm_contract').val(response.data.tpnm_contract);
          $('#tpnm_branch').val(response.data.tpnm_branch);
          $('#tpnm_customer_name').val(response.data.tpnm_customer_name);
          $('#tpnm_address').val(response.data.tpnm_address);
          $('#tpnm_installment').val(response.data.tpnm_installment);
          $('#tpnm_ic_number').val(response.data.tpnm_ic_number);
          $('#tpnm_kk_number').val(response.data.tpnm_kk_number);
          $('#tpnm_debt_date').val(response.data.tpnm_debt_date);
          $('#tpnm_invoice_qty').val(response.data.tpnm_invoice_qty);
          $('#tpnm_duration').val(response.data.tpnm_duration);
          if(response.data.tpnm_monthly_charge != null && response.data.tpnm_monthly_charge != ""){
          $('#tpnm_monthly_charge').val((parseInt(response.data.tpnm_monthly_charge)));
          // $('#tpnm_monthly_charge').val(formatter.format(parseInt(response.data.tpnm_monthly_charge)));
          $('#tpnm_total_unpaid').val((parseInt(response.data.tpnm_total_unpaid)));
          // $('#tpnm_total_unpaid').val(formatter.format(parseInt(response.data.tpnm_total_unpaid)));
          }else{
          $('#tpnm_monthly_charge').val(response.data.tpnm_monthly_charge);
          $('#tpnm_total_unpaid').val(response.data.tpnm_total_unpaid);
          }
  
          $('#tpnm_created_date').val( response.data.tpnm_created_date+" - "+response.data.tpnm_created_date );
          $('#tpnm_sk_initiated_date').val( response.data.tpnm_sk_initiated_date+" - "+response.data.tpnm_sk_initiated_time );
  
          $('#tpnm_data_approved_date').val( response.data.tpnm_data_approved_date+" - "+response.data.tpnm_data_approved_time );
          $('#tpnm_cancelled_date').val(response.data.tpnm_cancelled_date+ " - "+ response.data.tpnm_cancelled_time);
          $('#tpnm_cancelled_reason').val(response.data.tpnm_cancelled_reason);
          $('#tpnm_cancelled_by').val(response.data.tpnm_cancelled_by);
          $('#tpnm_debitur_name').val(response.data.tpnm_cancelled_vholder_name);
          $('#tpnm_debitur_phone').val(response.data.tpnm_cancelled_vholder_phone);
  
          if(response.data.tpnm_vtype == 'mobil'){
            $('#detail_mobil').hide();
            $('#foto_mobil').show();
          }else{
            $('#detail_mobil').show();
            $('#foto_mobil').hide();
          }
          if(response.data.tpnm_cancelled_step == 7){
            $('#penemuan_dibatalkan').show();
            $('.dibatalin2,.dibatalin3,.dibatalin4').show();
            $('.dibatalin').css('height','113vh');
          }else{
            $('#penemuan_dibatalkan').hide();
            $('.dibatalin2,.dibatalin3,.dibatalin4').hide();
          }
          // console.log(response.data.tpnm_cancelled_step);
          if( response.data.tpnm_medi_sk_template != null ){
            $('#tpnm_medi_sk_template').attr("href", response.data.tpnm_medi_sk_template);
          }else{
            $('#tpnm_medi_sk_template').attr("disabled", "disabled");
          }
          if( response.data.tpnm_medi_vphoto_sisi1 != null ){
            $('#tpnm_medi_vphoto_sisi1').attr("href", response.data.tpnm_medi_vphoto_sisi1);
          }else{
            $('#tpnm_medi_vphoto_sisi1').attr("disabled", "disabled");
          }
          $('#tpnm_medi_vphoto_sisi2').prop( "disabled", true );
          if( response.data.tpnm_medi_vphoto_sisi2 != null ){
            $('#tpnm_medi_vphoto_sisi2').attr("href", response.data.tpnm_medi_vphoto_sisi2);
          }else{
            $('#tpnm_medi_vphoto_sisi2').attr("disabled", "disabled");
          }
          if( response.data.tpnm_delivered_stnk_photo != null ){
            $('#tpnm_delivered_stnk_photo').attr("href", response.data.tpnm_delivered_stnk_photo);
          }else{
            $('#tpnm_delivered_stnk_photo').attr("disabled", "disabled");
          }
          if( response.data.tpnm_medi_bast_template != null ){
            $('#tpnm_medi_bast_template_1').attr("href", response.data.tpnm_medi_bast_template);
          }else{
            $('#tpnm_medi_bast_template_1').attr("disabled", "disabled");
          }
          if( response.data.tpnm_medi_bast_final != null ){
            $('#tpnm_medi_bast_final').attr("href", response.data.tpnm_medi_bast_final);
          }else{
            $('#tpnm_medi_bast_final').attr("disabled","disabled");
          }
          $('#tpnm_fee').val((parseInt(response.data.tpnm_fee)));
          // $('#tpnm_fee').val(formatter.format(parseInt(response.data.tpnm_fee)));
  
          $('#tpnm_delivery_dpname').val( response.data.tpnm_delivery_dpname);
          $('#tpnm_delivery_dplat').val( response.data.tpnm_delivery_dplat+" , "+response.data.tpnm_delivery_dplng);
          $('#tpnm_delivery_dpaddress').val( response.data.tpnm_delivery_dpaddress);
          $('#tpnm_delivery_dpopen').val( response.data.tpnm_delivery_dpopen+" - "+response.data.tpnm_delivery_dpclose);
          $('#tpnm_delivery_date').val( response.data.tpnm_delivery_date+" - "+response.data.tpnm_delivery_time);
  
          $('#tpnm_delivered_receiver_name').val( response.data.tpnm_delivered_receiver_name);
          $('#tpnm_delivered_receiver_phone').val( response.data.tpnm_delivered_receiver_phone);
          $('#tpnm_delivered_received_date').val( response.data.tpnm_delivered_received_date+" - "+response.data.tpnm_delivered_received_time);
  
          if( response.data.tpnm_status_stnk == 1 ){
            $('#total_fee').val( parseInt(response.data.tpnm_fee_stnk) + parseInt(response.data.tpnm_fee) );
          }else{
            $('#total_fee').val(response.data.tpnm_fee);
          }
  
          $('#tpnm_fee_stnk').val( response.data.tpnm_fee_stnk );
          var details ;
          if( response.data.tpnm_status_stnk == null){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 0){
            details = "<input selected disabled checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else{
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_1').html(details);
          if(response.data.tpnm_status_stnk == null){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 1){
            details = "<input selected disabled checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else{
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_2').html(details);
  
          // if( response.data.tpnm_medi_bast_template == null || response.data.tpnm_medi_bast_template == '' ){
          //   details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
          // }else{
          //   // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
          //   details = "<a href='"+response.data.tpnm_medi_bast_template+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>";
          // }
          // $('#tpnm_medi_bast_template_1').html(details);
  
          if( response.data.tpnm_medi_bast_final == null || response.data.tpnm_medi_bast_final == '' ){
            details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
          }else{
            // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
            details = "<a href='"+response.data.tpnm_medi_bast_final+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>";
          }
          $('#tpnm_medi_bast_final_1').html(details);
        }
      })
    }

    if(props.id != null){
      var AlasanNego = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi+"v1/Chronology/index/"+props.id,
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
      }

      $.ajax(AlasanNego).done(function (response){
        // console.log(response);
        var htmlAlasan = "";
        var htmlUrl = "";
        if( response.data != null){
          if(response.data['kronologi']['chnl_url'] != null){
            $('#tpnm_nego_kronologi').attr("href", response.data['kronologi']['chnl_url']);
            $('#tpnm_nego_kronologi').prop("disabled",true);
            // console.log(response.data['kronologi']['chnl_url'] +" sbhfkjds");
          }
          if( localStorage.getItem('role') == "5" ) {
            $('#div_surat_nego_mitra').css('display','block');
          }
          if( localStorage.getItem('role') == "5" || localStorage.getItem('role') == "4" ){
            $('#div_surat_nego_leasing').css('display','block');
            if(response.data['kronologi']['chnl_nominal_pdf_leasing'] != null){
              $('#tpnm_nego_kronologi_leasing').attr("href", response.data['kronologi']['chnl_nominal_pdf_leasing']);
              $('#tpnm_nego_kronologi_leasing').prop("disabled",true);
              // console.log(response.data['kronologi']['chnl_url'] +" sbhfkjds");
            }
          }
          console.log(response.data['kronologi']['chnl_nominal_before'])
          // $( '#chnl_nominal_after' ).mask('0.000.000.000', {reverse: true});
          var nominal_before_1 = response.data['kronologi']['chnl_nominal_before'] * 11.13 / 100;
          console.log(nominal_before_1 +"  Before");
          var telo = Math.ceil(nominal_before_1);
          console.log(telo + "  Telo");
          console.log(Math.ceil( telo / Math.pow(10, telo.toString().length - 3) ) * Math.pow(10, telo.toString().length - 3) + " TEST WOIII");
          nominal_before_1 = Math.ceil( telo / Math.pow(10, telo.toString().length - 3) ) * Math.pow(10, telo.toString().length - 3);
          var nominal_before = response.data['kronologi']['chnl_nominal_before'];
          var nominal_base = response.data['kronologi']['chnl_nominal_base'];
          var harga_total = parseInt(nominal_before_1) + parseInt(nominal_before);
          console.log(harga_total)
          $('#chnl_nominal_base').val( formatter.format(parseInt(nominal_base) ) );
          $('#chnl_nominal_before').val(formatter.format(parseInt(nominal_before_1) + parseInt(nominal_before)));

          if( response.data['nego'] != null ){
              for(i=0; i < response.data['nego'].length; i++){
                htmlAlasan += "<div class='form-check'>"+
                "<input class='form-check-input' type='checkbox' checked='true' value='' id='defaultCheck1>"+
                "<label type='text' class='form-check-label' for='defaultCheck1' id='ngrs_name'>&nbsp"+response.data['nego'][i].ngrs_name+"</label>"+
              "</div>";
              }
          }
          if(response.data['kronologi']['chnl_status'] == 0){
            $('#approve').hide();
            $('#reject').show('slow');
          }
          else if(response.data['kronologi']['chnl_status'] != 1){
            $('#approve').hide();
            $('#reject').hide('slow');
          }else{
            var harga_nego = $('#chnl_nominal_after').val();
            if(harga_nego == ""){
              $('#approve').hide();
              $('#reject').show('slow');
            }
            $(document).on("keyup",".harga_nego",function () {
              harga_nego = $('#chnl_nominal_after').val();
              harga_nego = harga_nego.replace(/\./g,'');
              if(parseInt(harga_nego) < nominal_base || parseInt(harga_nego) === nominal_base){
              // if(parseInt(harga_nego) <= harga_total+(harga_total*(5/100))){
                $('#approve').hide();
                $('#reject').show('slow');
                $('#message').html('Harga nego tidak boleh kurang dari harga awal').css('color', 'red');
              }else if (harga_nego == ""){
                $('#approve').hide();
                $('#reject').show('slow');
                $('#message').html('');
              }else{
                $('#approve').show();
                $('#reject').show('slow');
                $('#message').html('Harga nego diterima').css('color', 'green');
              }
          });

        }
          // console.log(htmlAlasan);
          $('#semua_alasan').html( htmlAlasan);
          if( response.data['url'] != null ){
              for(i=0; i < response.data['url'].length; i++){
                // console.log(response.data['url'][i].chnl_url);
                htmlUrl +=  "<div class='column' style='margin:5px;display:inline-block;' >"+
                "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+response.data['url'][i].chnl_url+">"+
                  "<img style='border-radius:5px;max-width:200px;max-height:200px' src="+response.data['url'][i].chnl_url+">"+
                "</a>"+
              "</div>";
              }
          }
          // console.log(htmlUrl);
          $('#url_mobil').html( htmlUrl);
        }

        if((response.data['kronologi']['chnl_status'] == 3 || response.data['kronologi']['chnl_status'] == 2)){
          // eslint-disable-next-line no-unused-vars
          var a = $('#chnl_nominal_after').val(formatter.format(parseInt(response.data.kronologi.chnl_nominal_after) / 0.9)).attr("disabled","disabled");
          // console.log(formatter.format(parseInt(response.data.kronologi.chnl_nominal_after) - parseInt(response.data.kronologi.chnl_nominal_after *10/100)) + "  INI MAS");
        }

      })



      var fotoMobil = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi+"v1/Founding/listFotoMobil/"+props.id,
        "method": "GET",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
          }
        }
      $.ajax(fotoMobil).done(function (response){
        // console.log(response);
        var htmlFoto = "";
        if(response.data != null){
  
          for(let i=0; i < response.data.length; i++){
            // console.log(response.data);
            htmlFoto +=  "<div class='column' style='margin:5px;display:inline-block;' >"+
            "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+response.data[i].medi_url+">"+
              "<img style='border-radius:5px;' src="+response.data[i].medi_url+" width='120' height='120'>"+
            "</a>"+
            "</div>";
          }
          // console.log(htmlFoto);
          $('#url_mobil_foto').html(htmlFoto);
        }
    })
    }

    $(document).on('click','.upload',function () {
      setId($(this).attr('id'))
    })

    $(document).on('click','.hapusletter',function () {
      setIdLetter($(this).attr('id'))
      handleClickOpen2()
      // console.log($(this).attr('id'))
    })

    $(document).on('click','#ResetSuratKuasa, #ResetFidusia, #ResetLainya, #ResetPeringatan, #ResetPiutang, #ResetJanjiBayar',function () {
              var resetkey = ''
              if (this.id == 'ResetSuratKuasa') {
                resetkey = 'surat_kuasa'
                console.log('Reset SuratKuasa');
              }else if(this.id == 'ResetFidusia'){
                resetkey = 'fidusia'
                console.log('ResetFidusia');
              }else if(this.id == 'ResetLainya'){
                resetkey = 'lain_lain'
                console.log('ResetLainya');
              }else if(this.id == 'ResetPeringatan'){
                resetkey = 'surat_peringatan'
                console.log('Reset Peringatan');
              }else if(this.id == 'ResetPiutang'){
                resetkey = 'kartu_piutang'
                console.log('Reset Piutang');
              }else if(this.id == 'ResetJanjiBayar'){
                resetkey = 'bukti_janji_bayar'
                console.log('Reset JanjiBayar');
              }

              var settings = {
                "async": true,
                "crossDomain": true,
                "url": API.Foundingapi+"v1/Letter/delSuratByKey/"+props.id+"/"+resetkey,
                "method": "GET",
                "headers": {
                    "Authorization": "Basic "+ localStorage.getItem('jwt'),
                  },
                  "processData": false,
                  "contentType": false,
                }

                $.ajax(settings).done(function (response) {Request()})
    })

  })
  
  return (
    <>
      <AlertList showIcon={false} alerts={alerts}/>
      <DialogHapusBatal 
                open={open2} 
                close={handleClose2} 
                title="Hapus File Ini" 
                message="Anda Yakin Ingin Hapus File Ini ?" 
                btn="Hapus"
                fun={HapusNego}
            />
      <DialogHapusBatal 
                open={open} 
                close={handleClose} 
                title="Reject Nego" 
                message="Anda Yakin Ingin Reject Nego Ini ?" 
                btn="Reject"
                fun={RejectNego}
            />
      <DialogHapusBatal 
                open={open1} 
                close={handleClose1} 
                title="Approve Nego" 
                message="Anda Yakin Ingin Approve Nego Ini ?" 
                btn="Approve"
                fun={ApproveNego}
            />
      <DialogHapusBatal 
                open={open4} 
                close={handleClose4} 
                title="Simpan File Nego" 
                message="Anda Yakin Ingin Simpan File Nego Ini ?" 
                btn="Simpan"
                fun={SimpanNego}
            />
      <div>
        <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <AntTabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable"
                  scrollButtons="auto" aria-label="scrollable auto tabs example" className={classes.title}>
                  <AntTab label="Data Kendaraan" {...a11yProps(0)} />
                  <AntTab label="Upload Dokumen" {...a11yProps(1)} />
                  <AntTab label="Nego Detail" {...a11yProps(2)} />
              </AntTabs>
              <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <AlertList showIcon={false} alerts={alerts1}/>
          <SwipeableViews axis={theme.direction==='rtl' ? 'x-reverse' : 'x' } index={value} onChangeIndex={handleChangeIndex}>
              <TabPanel value={value} index={0}>
              <Portlet>
                  <PortletHeader title="Data Kendaraan"></PortletHeader>
                  <PortletBody>
                    <Row>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Polisi</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id='tpnm_vplate' disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Rangka</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_vin" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Mesin</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_ein" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Type Motor/Mobil</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_vtype" disabled/>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nama STNK/BPKB</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="tpnm_vdoc_name" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Kontrak</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="tpnm_contract" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Asal Cabang</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="tpnm_branch" disabled/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </PortletBody>
                  <PortletHeader title="Profile Debitur"></PortletHeader>
                  <PortletBody>
                    <Row>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Debitur</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_customer_name" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Konsumen</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_address" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor KTP</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_ic_number" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor KK</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_kk_number" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal Mulai Tunggak</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_debt_date" disabled/>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Angsuran Ke</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="tpnm_installment" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Jumlah Kwitansi</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="tpnm_invoice_qty" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Tenor</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="tpnm_duration" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Angsuran Perbulan</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="tpnm_monthly_charge" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Total Tunggakkan</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="tpnm_total_unpaid" disabled/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </PortletBody>
                  <PortletHeader title="Data Mitra"></PortletHeader>
                  <PortletBody>
                    <Row>
                      <Col md={6}>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Mitra</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="mper_name" disabled/>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Mitra</label>
                          <div class="col-sm-8">
                            <input type="email" class="form-control" id="mper_phone" disabled/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </PortletBody>
                </Portlet>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Portlet>
                  <PortletHeader title="File Terupload" />
                  <PortletBody>
                    <div id="FileTerupload" style={{display:'flex',flexWrap: 'wrap'}}>

                    </div>
                  </PortletBody>
                </Portlet>
                <Row id="semuaupload">
                  <Col md={4}>
                    <Portlet>
                      <PortletHeader title={<h3 class="kt-portlet__head-title">Upload Surat Kuasa <span style={{color:'#F44336'}}>*</span></h3>} toolbar={(
                              <PortletHeaderToolbar>
                                  <button
                                      // to="/tambahleasing"
                                      type="button"
                                      className="btn btn-label-primary btn-sm"
                                      id="ResetSuratKuasa"
                                      style={{display:'none'}}
                                  >
                                      <i class="fas fa-trash"></i>Hapus Semua
                                  </button>
                              </PortletHeaderToolbar>
                          )}/>
                      <PortletBody>
                        <div class="entry input-group">
                          <input class="form-control" id="filesuratkuasa" onChange={ImageChange} accept=".doc,.docx,application/msword,image/jpg,image/jpeg,image/png,application/pdf" type="file" placeholder="Placeholder" />
                          <span class="input-group-btn">
                            <button type="button" class="btn btn-success btn-elevate upload" id="suratkuasa" onClick={Upload}>
                              <i class="fas fa-file-upload" style={{color:'white'}}></i>Upload
                            </button>
                          </span>
                        </div>
                      </PortletBody>
                    </Portlet>
                  </Col>
                  <Col md={4}>
                    <Portlet>
                      <PortletHeader title={<h3 class="kt-portlet__head-title">Upload Fidusia <span style={{color:'#F44336'}}>*</span></h3>} toolbar={(
                              <PortletHeaderToolbar>
                                  <button
                                      // to="/tambahleasing"
                                      type="button"
                                      className="btn btn-label-primary btn-sm"
                                      id="ResetFidusia"
                                      style={{display:'none'}}
                                  >
                                      <i class="fas fa-trash"></i>Hapus Semua
                                  </button>
                              </PortletHeaderToolbar>
                          )}/>
                      <PortletBody>
                        <div class="entry input-group">
                          <input class="form-control" id="filefidusia" onChange={ImageChange} accept=".doc,.docx,application/msword,image/jpg,image/jpeg,image/png,application/pdf" type="file" placeholder="Placeholder" />
                          <span class="input-group-btn">
                            <button type="button" class="btn btn-success btn-elevate upload" id="fidusia" onClick={Upload}>
                              <i class="fas fa-file-upload" style={{color:'white'}}></i>Upload
                            </button>
                          </span>
                        </div>
                      </PortletBody>
                    </Portlet>
                  </Col>
                  <Col md={4}>
                    <Portlet>
                      <PortletHeader title="Upload Surat Peringatan" toolbar={(
                              <PortletHeaderToolbar>
                                  <button
                                      // to="/tambahleasing"
                                      type="button"
                                      className="btn btn-label-primary btn-sm"
                                      id="ResetPeringatan"
                                      style={{display:'none'}}
                                  >
                                      <i class="fas fa-trash"></i>Hapus Semua
                                  </button>
                              </PortletHeaderToolbar>
                          )}/>
                      <PortletBody>
                        <div class="entry input-group">
                          <input class="form-control" id="filesuratperingatan" onChange={ImageChange} accept=".doc,.docx,application/msword,image/jpg,image/jpeg,image/png,application/pdf" type="file" placeholder="Placeholder" />
                          <span class="input-group-btn">
                            <button type="button" class="btn btn-success btn-elevate upload" id="suratperingatan" onClick={Upload}>
                              <i class="fas fa-file-upload" style={{color:'white'}}></i>Upload
                            </button>
                          </span>
                        </div>
                      </PortletBody>
                    </Portlet>
                  </Col>
                  <Col md={4}>
                    <Portlet>
                      <PortletHeader title="Upload Bukti Janji Bayar" toolbar={(
                              <PortletHeaderToolbar>
                                  <button
                                      // to="/tambahleasing"
                                      type="button"
                                      className="btn btn-label-primary btn-sm"
                                      id="ResetJanjiBayar"
                                      style={{display:'none'}}
                                  >
                                      <i class="fas fa-trash"></i>Hapus Semua
                                  </button>
                              </PortletHeaderToolbar>
                          )}/>
                      <PortletBody>
                        <div class="entry input-group">
                          <input class="form-control" id="filejanjibayar" onChange={ImageChange} accept=".doc,.docx,application/msword,image/jpg,image/jpeg,image/png,application/pdf" type="file" placeholder="Placeholder" />
                          <span class="input-group-btn">
                            <button type="button" class="btn btn-success btn-elevate upload" id="janjibayar" onClick={Upload}>
                              <i class="fas fa-file-upload" style={{color:'white'}}></i>Upload
                            </button>
                          </span>
                        </div>
                      </PortletBody>
                    </Portlet>
                  </Col>
                  <Col md={4}>
                    <Portlet>
                      <PortletHeader title="Upload Kartu Piutang" toolbar={(
                              <PortletHeaderToolbar>
                                  <button
                                      // to="/tambahleasing"
                                      type="button"
                                      className="btn btn-label-primary btn-sm"
                                      id="ResetPiutang"
                                      style={{display:'none'}}
                                  >
                                      <i class="fas fa-trash"></i>Hapus Semua
                                  </button>
                              </PortletHeaderToolbar>
                          )}/>
                      <PortletBody>
                        <div class="entry input-group">
                          <input class="form-control" id="filekartupiutang" onChange={ImageChange} accept=".doc,.docx,application/msword,image/jpg,image/jpeg,image/png,application/pdf" type="file" placeholder="Placeholder" />
                          <span class="input-group-btn">
                            <button type="button" class="btn btn-success btn-elevate upload" id="kartupiutang" onClick={Upload}>
                              <i class="fas fa-file-upload" style={{color:'white'}}></i>Upload
                            </button>
                          </span>
                        </div>
                      </PortletBody>
                    </Portlet>
                  </Col>
                  <Col md={4}>
                    <Portlet>
                      <PortletHeader title="Upload Lainya" toolbar={(
                              <PortletHeaderToolbar>
                                  <button
                                      // to="/tambahleasing"
                                      type="button"
                                      className="btn btn-label-primary btn-sm"
                                      id="ResetLainya"
                                      style={{display:'none'}}
                                  >
                                      <i class="fas fa-trash"></i>Hapus Semua
                                  </button>
                              </PortletHeaderToolbar>
                          )}/>
                      <PortletBody>
                        <div class="entry input-group">
                          <input class="form-control" id="filelainya-title" type="text" placeholder="Nama File" />
                          <input class="form-control" id="filelainya" onChange={ImageChange} accept=".doc,.docx,application/msword,image/jpg,image/jpeg,image/png,application/pdf" type="file" placeholder="Placeholder" />
                          <span class="input-group-btn">
                            <button type="button" class="btn btn-success btn-elevate upload" id="lainya" onClick={Upload}>
                              <i class="fas fa-file-upload" style={{color:'white'}}></i>Upload
                            </button>
                          </span>
                        </div>
                      </PortletBody>
                    </Portlet>
                  </Col>
                </Row>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                <button type="button" className="btn btn-primary btn-elevate pull-right mr-1" id="reject" onClick={handleClickOpen4}><i className="fas fa-save"></i>Simpan</button>
              </TabPanel>
              <TabPanel value={value} index={2}>
              <Portlet>
                  <PortletHeader title="Kronologi Detail"></PortletHeader>
                  <PortletBody>
                    <Row>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal Pengajuan Nego</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="tpnm_created_date" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Harga Awal</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="chnl_nominal_base" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Harga Pengajuan</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" id="chnl_nominal_before" disabled/>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-6 col-form-label">Alasan Pengajuan</label>
                          <div class="col-sm-5">
                            <div class='mt-2' id='semua_alasan'></div>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-6 col-form-label">Dokumen Kronologis Nego Mitra</label>
                          <div class="col-sm-5">
                            <a type="button" class="btn btn-outline-brand btn-icon" id="tpnm_nego_kronologi" target='_blank' disabled="disabled"><i class="far fa-file-pdf"></i></a>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-6 col-form-label">Dokumen Kronologis Nego Leasing</label>
                          <div class="col-sm-5">
                          <a type="button" class="btn btn-outline-brand btn-icon" id="tpnm_nego_kronologi_leasing" target='_blank' disabled="disabled"><i class="far fa-file-pdf"></i></a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </PortletBody>
                </Portlet>
                <Portlet>
                  <PortletHeader title="Foto"/>
                  <PortletBody>
                    <div class='col-md-12' style={{marginTop:'10px',overflow:'auto',height:'150px',whiteSpace: 'nowrap'}} id="url_mobil_foto">

                    </div>
                  </PortletBody>
                </Portlet>
                <Portlet>
                  <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Harga Nego</label>
                        <div class="col-sm-7">
                          <input type="email" class="form-control harga_nego" id="chnl_nominal_after"/><span id='message'></span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                    {/* <Row>
                      <Col md={6}>
                        <button type="button" className="btn btn-danger btn-elevate" id="reject" onClick={handleClickOpen} style={{width:'100%'}}>Reject</button>
                      </Col>
                      <Col md={6}>
                        <button type="button" className="btn btn-success btn-elevate" id="approve" onClick={handleClickOpen1} style={{width:'100%'}}>Approve</button>
                      </Col>
                    </Row> */}
                  </PortletBody>
                </Portlet>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                <button type="button" className="btn btn-success btn-elevate pull-right" id="approve" onClick={handleClickOpen1}><i class="fas fa-check"></i>Approve</button>
                <button type="button" className="btn btn-danger btn-elevate pull-right mr-1" id="reject" onClick={handleClickOpen}><i class="fas fa-times"></i>Reject</button>
              </TabPanel>
          </SwipeableViews>
        </Dialog>
      </div>
    </>
  );
}
