import React from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../../API';

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import {Row, Col} from 'react-bootstrap';
import Table from '../Settings/TableSetting';
import { AlertList} from "react-bs-notifier";
import KuotaDownlineLain from '../Settings/KuotaDownlineLain';
import DialogUpload from './DialogUpload';
import ExcelDownlineAsal from './kuota_downline_asal.xlsx';
import ExcelNamaLokasi from './template_penamaan_lokasi.xlsx';
import bsCustomFileInput from 'bs-custom-file-input';
import {ProgressBar, Card} from 'react-bootstrap';
import DialogKuota from './DialogKuota';

export default function KuotaDownline() {
    const [openkda, setOpenKDA] = React.useState(false); //Upload excel downline asal
    const [open1, setOpen1] = React.useState(false); //edit kuota downline
    const [open2, setOpen2] = React.useState(false); //delete kuota downline
    const [open3, setOpen3] = React.useState(false); //delete semua kuota downline 
    const [quotaId, setQuotaId] = React.useState(null);
    const [filekda, setFileKDA] = React.useState(null);
    const [uploadnamekda, setUploadnamekda] = React.useState(null);
    const [progresskda, setProgressKDA] = React.useState(0);
    const [alerts, setAlerts] = React.useState([]);

    const ToggleUploadKDA = () => {
        $('#uploadformKDA').toggle('slow')
    }

    const FileKDA = (e) => {
        setFileKDA(e.target.files[0])
    }

    const handleClickOpenKDA = () => {
        setOpenKDA(true)
    }
    const handleCloseKDA = () => {
        setOpenKDA(false)
    }
    const handleClickOpen1 = () => {
        setOpen1(true)

        $('#kuotadialog input').val('')
    }
    const handleClose1 = () => {
        setOpen1(false)
        
    }
    const handleClickOpen2 = () => {
        setOpen2(true)
    }
    const handleClose2 = () => {
        setOpen2(false)
    }
    const handleClickOpen3 = () => {
        setOpen3(true)
    }
    const handleClose3 = () => {
        setOpen3(false)
    }

    React.useEffect(() => {
        $(document).ready(function() {
            TableKuota()
            $('#kuotadownlineasal').on('click', '#editKuotaAsal', function() {
                setQuotaId($(this).attr('data-id'))
                handleClickOpen1()

                var editKuota = {
                    "async":true,
                    "crossDomain":true,
                    "url": API.Userapi + "v1/master/getKuota/" + $(this).attr('data-id')+"?type=0",
                    "method":"GET",
                    "headers": {
                        "Authorization": "Basic "+localStorage.getItem('jwt')
                    }
                }
                $.ajax(editKuota).done(function(response) {
                    if(response.data !=null ) {
                        $('#quota_province').val(response.data.province)
                        $('#quota_city').val(response.data.city)
                        $('#quota_max').val(response.data.max_quota_value)
                    }
                }).fail(function(response) {
                    API.Handle403(response,setAlerts)
                    return 0
                })

            })
            $('#kuotadownlineasal').on('click', '#hapusKuotaAsal', function() {
                setQuotaId($(this).attr('data-id'))
                handleClickOpen2()
            })
            bsCustomFileInput.init()
        })
    },[setAlerts])

    const TableKuota = () => {
        Table.KuotaDownlineAsal(setAlerts);
        $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
	}

    const UploadKDA = () => {
        handleCloseKDA()
        var formKDA = new FormData()
        formKDA.append('uploaded_filekd', filekda)
        setUploadnamekda(filekda.name)
        $("#uploadingkda").show('slow')
        $("#form_kda").hide('slow')

        var uploadKDA = {
            xhr: function() {
				var xhr = new window.XMLHttpRequest();
				xhr.upload.addEventListener('progress', function(e) {
					if(e.lengthComputable) {
						console.log(e);
						console.log('Bytes Loaded : ' + e.loaded);
                        console.log('Total Size : ' + e.total);
						console.log('Persen : ' + (e.loaded / e.total));
						
						var percentkda = Math.round((e.loaded / e.total) * 100);
						console.log("Persen : " + percentkda)

						setProgressKDA(percentkda)
					}
				})
				return xhr;
            },
            "async":true,
            "crossDomain":true,
            "url": API.Userapi+"v1/Master/uploadKuotaDownline?type=0",
            "method": "POST",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem("jwt"),
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": formKDA
        }
        $.ajax(uploadKDA).done(function (response ){
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Downline Kota Asal Telah Diupload"
            }])
            setTimeout(() => {
                $('#uploadingkda').hide('slow')
				$('#form_kda').show('slow')
				setAlerts([])
            }, 3000)
            TableKuota()
        }).fail(function (response ) {
            $("#uploadingkda").hide('slow')
            $("#form_kda").show('slow')
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    const SubmitKuota = () => {
        
        // var quota_province = $('#quota_province').val()
        // var quota_city = $('#quota_city').val()
        var quota_max = $('#quota_max').val()

        var dataKuota = {
            // 'province': quota_province,
            // 'city': quota_city,
            'max_quota_value': quota_max
        }

        var url = API.Userapi + "v1/master/putKuotaDownline/" + quotaId + "?type=0"

        var quotas = {
            "async":true,
            "crossDomain":true,
            "url": url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('jwt')
            },
            "processData":false,
            "data": JSON.stringify(dataKuota)
        }
        $.ajax(quotas).done(function(response){
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Kuota Telah Disimpan"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose1()
            TableKuota()
        }).fail(function (response) {
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    const DeleteKuota = () => {
        
        var delKuota = {
            "async":true,
            "crossDomain":true,
            "url": API.Userapi + "v1/master/delKuota/"+quotaId+"?type=0",
            "method": "GET",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem('jwt')
            }
        }
        $.ajax(delKuota).done(function(response){
            setAlerts([{
                id: 1,
				type: "success",
				headline: "Berhasil",
				message: "Kuota Downline Berhasil Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose2()
            TableKuota()
        }).fail(function (response ) {
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
			setTimeout(() => {
					setAlerts([])
			}, 3000)
			API.Handle403(response,setAlerts)
        })
    }

    const DeleteAllKuota = () => {
        var delAllKuotaAsal = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi + "v1/master/delAllKuota?type=0",
            "method": "GET",
            "headers": {
                "Authorization": "Basic "+ localStorage.getItem('jwt')
            }
        }
        $.ajax(delAllKuotaAsal).done(function (response ) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Total "+response.total+" Kuota Downline Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose3()
            TableKuota()
        }).fail(function (response ) {
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code " + response.status + "]"
            }])
            setTimeout(() => {
            setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    return (
        <>
            <Portlet>
                <DialogUpload
                    open={openkda}
                    close={handleCloseKDA}
                    title="Upload Max Kuota Downline Asal"
                    message="Anda Yakin Ingin Upload File Kuota Downline Asal Ini ?"
                    btn="Upload"
                    fun={UploadKDA}
                />
                <DialogKuota 
                    open={open1}
                    close={handleClose1}
                    title={quotaId != null ? "Edit Kuota Downline" : "Tambah Kuota Downline"}
                    btn = {quotaId != null ? "Edit" : "Tambah"}
                    fun = {SubmitKuota}
                />
                <DialogUpload
                    open={open2}
                    close={handleClose2}
                    title="Hapus Kuota Downline Asal"
                    message="Anda Yakin Ingin Hapus Data Kuota Downline Asal Ini ?"
                    btn="Hapus"
                    fun={DeleteKuota}
                />
                 <DialogUpload 
					open={open3}
					close={handleClose3}
					title="Hapus Semua Quota Downline Kota Asal"
					message="Anda Yakin Ingin Hapus Semua Data Quota Downline Kota Asal Ini ?"
					btn= "Hapus Semua"
					fun={DeleteAllKuota}
				/>
                <AlertList  showIcon={false} alerts={alerts}/>
                <PortletHeader title="Setting Kuota Downline" toolbar={(
                    <PortletHeaderToolbar>
                        <a
                            href={ExcelNamaLokasi}
                            type="button"
                            className="btn btn-label-primary btn-sm"
                        >
                            <i class="fas fa-file-download"></i>Panduan Penamaan Lokasi
                        </a>
                    </PortletHeaderToolbar>
                )} />
                <Row>
                    <Col md={6} style={{background:'#f2f3f8'}}>
                    <Portlet>
                        <PortletHeader title="Kuota Downline Asal" toolbar={(
                            <PortletHeaderToolbar>
                                <a
                                    href={ExcelDownlineAsal}
                                    type="button"
                                    className="btn btn-label-primary btn-sm"
                                >
                                    <i class="fas fa-file-download"></i>Template Pengisian Data
                                </a>
                                <button
                                    type="button"
                                    className="btn btn-label-primary btn-sm mr-1 ml-1"
                                    onClick={ToggleUploadKDA}
                                >
                                    <i class="fas fa-file-upload"></i>Upload Data
                                </button>
                            </PortletHeaderToolbar>
                        )} />
                        <PortletBody>
                            <div class="mb-2" id="uploadformKDA" style={{display:'none'}}>
                                <div id="form_kda" className="input-group col-md-12">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-warning btn-elevate" type="button" onClick={ToggleUploadKDA} >Batal</button>
                                    </div>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="customfile" onChange={FileKDA} aria-describedby="customfile"/>
                                        <label class="custom-file-label" for="customfile">Pilih File</label>
                                    </div>
                                    <div class="input-group-append">
                                        <button class="btn btn-primary btn-elevate" type="button" onClick={handleClickOpenKDA} id="inputGroupFileAddon04">Upload</button>
                                    </div>
                                </div>
                                <div id="uploadingkda" className="col-md-12"style={{display:'none'}}>
                                    <Card bg="primary" text="white">
                                        <Card.Body>
                                        <Card.Title>
                                            <h3>
                                                Mengupload Data
                                            </h3>
                                        </Card.Title>
                                        <hr style={{borderTop: '1px solid #f4a240'}}/>
                                        <Card.Text>
                                            <h4>Nama File : <span>{uploadnamekda}</span></h4>
                                            <h4>Proses : <span>{progresskda}% {progresskda === 100 ? "(Memproses Di Server)" : ""}</span></h4>
                                            <ProgressBar animated  now={progresskda} variant="warning"/>
                                        </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <Row>
                                <Col>
                                    <button type="button" class="btn btn-danger btn-elevate mb-2" onClick={handleClickOpen3} id="delete_all"><i class='fa fa-trash'></i> Hapus Semua</button>
                                </Col>
                            </Row>
                          <table className="table table-hover table-responsive-md" id="kuotadownlineasal" style={{width:'100%',overflow:'auto'}}>
                                  <thead className="bg-primary" style={{color:'white'}}>
                                      <tr>
                                        <th class="all"> Provinsi Pendaftaran </th>
                                        <th class="all"> Kabupaten</th>
                                        <th class="all"> Max Kuota</th>
                                        <th class="all"> Aksi </th>
                                      </tr>
                                  </thead>
                          </table>
                        </PortletBody>
                    </Portlet>
                    </Col>
                    <Col md={6} style={{background:'#f2f3f8'}}>
                        <KuotaDownlineLain />
                    </Col>
                </Row>
            </Portlet>
        </>   
    )
}