/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogHapusBatal from './DialogHapusBatal';
import {Row, Col} from 'react-bootstrap';
// import $ from 'jquery'
// import API from '../../API'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '95%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

export default function DialogBayarBonus(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
      setOpen(true);
    }

    function handleClose() {
      setOpen(false);
    }

    function Submit(params) {
      props.submit()
      handleClose()
    }

  return (
    <div>
      <DialogHapusBatal                 
        open={open} 
        close={handleClose} 
        title="Konfirmasi Pembayaran" 
        message="Anda Yakin Ingin Bayar Bonus Ini ?" 
        btn="Bayar" 
        btn2="Batal" 
        // type="okbatal" 
        fun={Submit}
      />
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.close}
        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" fullWidth={true}
        maxWidth="md">
        <DialogActions>
          <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <div style={{width:'100%'}}></div>
          <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close" style={{marginTop:'-15px'}}>
                  <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
          <Row>
              <Col md={6}>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-4 col-form-label">Rekening Perusahaan</label>
              <div class="col-sm-8">
                <select className="form-control" id="lvoc_admin_bank_1">
                    <option value="6241889898" selected>- 6241889898 - BCA -</option>
                </select>
              </div>
            </div>
            <div class="form-group row" style={{marginTop:'-15px'}}>
              <label for="inputEmail3" class="col-sm-4 col-form-label">Bank Perusahaan</label>
              <div class="col-sm-8">
                <input type='hidden' id='tbnk_id_1' name='tbnk_id' value='0' />
                <input type="text" class="form-control" id="lvoc_bank_1" value='BCA'/>
              </div>
            </div>
            <div class="form-group row" style={{marginTop:'-15px'}}>
              <label for="inputEmail3" class="col-sm-4 col-form-label">Total Fee</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="tvoc_total_fee_1" disabled/>
              </div>
            </div>
            <div class="form-group row" style={{marginTop:'-15px'}}>
              <label for="inputEmail3" class="col-sm-4 col-form-label">Fee Antar Bank</label>
              <div class="col-sm-3">
                <input type="text" class="form-control" id="lvoc_fee_antar_bank_1" value="6500" disabled/>
                <input type="hidden" class="form-control" id="lvoc_fee_antar_bank_0" value="0" disabled/>
              </div>
                <div class="form-group form-check col-sm-4 pt-2">
                  <input type="checkbox" class="form-check-input" id="check_lvoc_fee_antar_bank_1"/>
                  <label class="form-check-label" for="exampleCheck1">Fee Antar Bank</label>
                </div>
            </div>
            <div class="form-group row" style={{marginTop:'-30px'}}>
              <label for="inputEmail3" class="col-sm-4 col-form-label">Total Transfer</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="tvoc_total_transfer_1" disabled/>
              </div>
            </div>
            <div class="form-group row" style={{marginTop:'-15px'}}>
              <label for="inputEmail3" class="col-sm-4 col-form-label">Note</label>
              <div class="col-sm-8">
                <textarea type="text" class="form-control" id="lvoc_note_1" placeholder="Noted" />
              </div>
            </div>
            </Col>
              <Col md={6}>
                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Nama</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="tvoc_account_name_2" disabled/>
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:'-15px'}}>
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Bank</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="tvoc_bank_name_2" disabled/>
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:'-15px'}}>
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Rekening</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="tvoc_account_number_2" disabled/>
                  </div>
                </div>
              </Col>
            </Row>
          <button type="button" className="btn btn-brand btn-elevate" onClick={handleClickOpen} style={{width:'100%'}}>Bayar</button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );

}

