import React from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../../API';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import Table from './TableSetting';
import bsCustomFileInput from 'bs-custom-file-input';
import {AlertList} from "react-bs-notifier";
import {ProgressBar, Card} from 'react-bootstrap';
import {Row, Col} from 'react-bootstrap';
import ExcelAksesData from './konfigurasi_akses_data.xlsx';
import ExcelNamaLokasi from './template_penamaan_lokasi.xlsx';
import DialogUpload from './DialogUpload';
import DialogAksesData from './DialogAksesData';
import 'select2';
import Utils from '../Utils/FunctionDetail';

export default function AksesData() {

   const [open, setOpen] = React.useState(false) //excel akses potongan
   const [open1, setOpen1] = React.useState(false) //edit akses potongan
   const [open2, setOpen2] = React.useState(false) //hapus akses potongan 
   const [open3, setOpen3] = React.useState(false) // hapus all akses potongan
   const [aksesId, setAksesId] = React.useState(null)
   const [filead, setFileAD] = React.useState(null)
   const [uploadname, setUploadname] = React.useState(null)
   const [progress, setProgress] = React.useState(0)
   const [alerts, setAlerts] = React.useState([])

   const ToggleUpload = () => {
      $('#uploadformAD').toggle('slow')
   }

   const FileAkses = (e) => {
      setFileAD(e.target.files[0])
   }
   const handleClickOpen = () => {
      setOpen(true)
   }
   const handleClose = () => {
      setOpen(false)
   }
   const handleClickOpen1 = () => {
      setOpen1(true)

      $('#aksesdatadialog input').val('')
   }
   const handleClose1 = () => {
      setOpen1(false)
   }
   const handleClickOpen2 = () => {
      setOpen2(true)
   }
   const handleClose2 = () => {
      setOpen2(false)
   }
   const handleClickOpen3 = () => {
      setOpen3(true)
   }
   const handleClose3 = () => {
      setOpen3(false)
   }

   React.useEffect(() => {
      $(document).ready(function() {
         TableAksesData()
   
         $('#aksesdata').on('click', '#editAksesData', function() {
            setAksesId($(this).attr('data-id'))
            handleClickOpen1()

            var editAkses = {
               "async": true,
               "crossDomain":true,
               "url": API.Userapi + "v1/Master/getAksesData/"+ $(this).attr('data-id'),
               "method": "GET",
               "headers": {
                  "Authorization": "Basic "+localStorage.getItem('jwt')
               }
            }
            $.ajax(editAkses).done(function(response){
               if(response.data !=null ) {
                  $('#nama_leasing').val(response.data.nama_leasing)
                  $('#jenis_kendaraan').val(response.data.jenis_kendaraan)
                  $('#akses_provinsi').val(response.data.provinsi)
                  $('#akses_kabupaten').val(response.data.kabupaten)
                  $('#akses_data').val(response.data.akses_data)
                  $('#akses_range_price').val(response.data.range_price_name)
                  $('#akses_range_type').val(response.data.range_price_type)

                  // if( response.data.mper_province != "" ){
                  //    Utils.setProvinsi(response.data.mper_province,'#akses_provinsi');
                  // }else{
                  //    $('#akses_provinsi').val("???????");
                  // }
                  // if( response.data.mper_city != "" ){
                  //    Utils.setKota(response.data.mper_city,'#akses_kabupaten');
                  // }else{
                  //    $('#akses_kabupaten').val("???????");
                  // }
               }

            }).fail(function(response) {
               API.Handle403(response,setAlerts)
               return 0
            })
         })
         $('#aksesdata').on('click', '#hapusAksesData', function() {
            setAksesId($(this).attr('data-id'))
            handleClickOpen2()
         })

         bsCustomFileInput.init()
      })
   },[setAlerts])

   const TableAksesData = () => {
      Table.AksesData(setAlerts)
      $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
	}

   const UploadAksesData = () => {
      handleClose()
      var form = new FormData()
      form.append('uploaded_file', filead)
      setUploadname(filead.name)
      $('#uploading_ad').show('slow')
      $('#form_ad').hide('slow')

      var uploadAkses = {
         xhr: function() {
				var xhr = new window.XMLHttpRequest();
				xhr.upload.addEventListener('progress', function(e) {
					if(e.lengthComputable) {
						console.log(e);
						console.log('Bytes Loaded : ' + e.loaded);
                        console.log('Total Size : ' + e.total);
						console.log('Persen : ' + (e.loaded / e.total));
						
						var percent = Math.round((e.loaded / e.total) * 100);
						console.log("Persen : " + percent)

						setProgress(percent)
					}
				})
				return xhr;
         },
         "async":true,
         "crossDomain":true,
         "url": API.Userapi+"v1/Master/uploadAksesData",
         "method": "POST",
         "headers": {
             "Authorization": "Basic "+localStorage.getItem("jwt"),
         },
         "processData": false,
         "contentType": false,
         "mimeType": "multipart/form-data",
         "data": form
      }
      $.ajax(uploadAkses).done(function (response ){
         setAlerts([{
            id:1,
            type:"success",
            headline: "Berhasil",
            message: "File Akses Data Telah Diupload"
         }])
         setTimeout(() => {
            $('#uploading_ad').hide('slow')
            $('#form_ad').show('slow')
            setAlerts([])
         },3000)
         TableAksesData()
      }).fail(function (response){
         $('#uploading_ad').hide('slow')
         $('#form_ad').show('slow')
         setAlerts([{
            id:1,
            type:"danger",
            headline:"Gagal",
            message: "Terjadi Kendala [Error Code "+response.status+"]"
         }])
         setTimeout(() => {
            setAlerts([])
         }, 3000)
         API.Handle403(response, setAlerts)
      })
   }

   const SubmitData = () => {
      // var nama_leasing = $('#nama_leasing').val()
      // var mper_province = $('#akses_provinsi').val()
      // var mper_city = $('#akses_kabupaten').val()
      var jenis_kendaraan = $('#jenis_kendaraan').val().toUpperCase()
      var akses_data = $('#akses_data').val()
      var akses_range_price = $('#akses_range_price').val().toUpperCase()
      var akses_range_type = $('#akses_range_type').val().toLowerCase()

      var dataAkses = {
         // "nama_leasing": nama_leasing,
         // "mper_province": mper_province,
         // "mper_city": mper_city,
         "jenis_kendaraan": jenis_kendaraan,
         "akses_data": akses_data,
         "range_price_name": akses_range_price,
         "range_price_type": akses_range_type
      }
      // console.log(dataAkses)
      var url = API.Userapi + "v1/master/putAksesData/" + aksesId

      var aksesp = {
         "async":true,
         "crossDomain":true,
         "url": url,
         "method": "POST",
         "headers": {
               "Content-Type": "application/json",
               "Authorization": "Basic " + localStorage.getItem('jwt')
         },
         "processData":false,
         "data": JSON.stringify(dataAkses)
      }
      $.ajax(aksesp).done(function(response){
         setAlerts([{
               id: 1,
               type: "success",
               headline: "Berhasil",
               message: "Data Telah Disimpan"
         }])
         setTimeout(() => {
               setAlerts([])
         }, 3000)
         handleClose1()
         TableAksesData()
      }).fail(function (response) {
         setAlerts([{
               id: 1,
               type: "danger",
               headline: "Gagal",
               message: "Terjadi Kendala [Error Code "+response.status+"]"
         }])
         setTimeout(() => {
               setAlerts([])
         }, 3000)
         API.Handle403(response,setAlerts)
      })

   }
   const DeleteAksesData = () => {
      
      var delAksesPotongan = {
         "async":true,
         "crossDomain": true,
         "url": API.Userapi + "v1/master/delAksesData/"+aksesId,
         "method": "GET",
         "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt')
         }
      }
      $.ajax(delAksesPotongan).done(function(response){
         setAlerts([{
            id:1,
            type:"success",
            headline: "Berhasil",
            message: "Data Konfigurasi Akses Data dan Skema Pricing berhasil dihapus"
         }])
         setTimeout(() => {
            setAlerts([])
         },3000)
         handleClose2()
         TableAksesData()
      }).fail(function(response){
         setAlerts([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code "+response.status+"]"
         }])
         setTimeout(() => {
            setAlerts([])
         }, 3000)
         API.Handle403(response,setAlerts)
      })
   }

   const DeleteAllAksesData = () => {
      var delAll = {
         "async": true,
         "crossDomain":true,
         "url": API.Userapi + "v1/master/delAksesDataAll",
         "method": "GET",
         "headers": {
            "Authorization": "Basic "+localStorage.getItem('jwt')
         }
      }
      $.ajax(delAll).done(function (response) {
         setAlerts([{
            id:1,
            type: "success",
            message: "Berhasil",
            headline: "Semua Data Akses Potongan Telah Dihapus"
         }])
         setTimeout(() => {
            setAlerts([])
         }, 3000)
         handleClose3()
         TableAksesData()
      }).fail(function (response) {
         setAlerts([{
            id:1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]"
         }])
         setTimeout(() => {
            setAlerts([])
         }, 3000)
         API.Handle403(response,setAlerts)
      })

   }

   return (
      <>
         <Portlet>
            <DialogUpload
               open={open}
               close={handleClose}
               title="Upload Konfigutasi Akses Data dan Skema Pricing"
               message="Anda Yakin Ingin Upload File Konfigutasi Akses Data dan Skema Pricing ini?"
               btn="Upload"
               fun={UploadAksesData}
            />
            <DialogAksesData
               open={open1}
               close={handleClose1}
               title={aksesId != null ? "Edit Konfigutasi Akses Data dan Skema Pricing" : "Tambah Konfigutasi Akses Data dan Skema Pricing"}
               btn = {aksesId != null ? "Edit" : "Tambah"}
               fun = {SubmitData}
            />
            <DialogUpload
               open={open2}
               close={handleClose2}
               title="Hapus Konfigutasi Akses Data dan Skema Pricing"
               message="Anda Yakin Ingin Hapus Konfigutasi Akses Data dan Skema Pricing Ini ?"
               btn="Hapus"
               fun={DeleteAksesData}
            />
             <DialogUpload 
					open={open3}
					close={handleClose3}
					title="Hapus Semua Konfigutasi Akses Data"
					message="Anda Yakin Ingin Hapus Semua Konfigutasi Akses Data Ini ?"
					btn= "Hapus Semua"
					fun={DeleteAllAksesData}
				/>
            <AlertList showIcon={false} alerts={alerts}/>
            <PortletHeader title="Setting Area dan Konfigurasi Pricing" toolbar={(
               <PortletHeaderToolbar>
                  <a
                     href={ExcelNamaLokasi}
                     type="button"
                     className="btn btn-label-primary btn-sm mr-1 ml-1"
                  >
                     <i class="fas fa-file-download"></i>Panduan Penamaan Lokasi
                  </a>
                  <a
                     href={ExcelAksesData}
                     type="button"
                     className="btn btn-label-primary btn-sm mr-1 ml-1"
                  >
                     <i class="fas fa-file-download"></i>Template Pengisian Data
                  </a>
                  <button
                     type="button"
                     className="btn btn-label-primary btn-sm mr-1 ml-1"
                     onClick={ToggleUpload}
                  >
                     <i class="fas fa-file-upload"></i>Upload Data
                  </button>
               </PortletHeaderToolbar>
            )} />
            <PortletBody>
               <div class="mb-2" id="uploadformAD" style={{display:'none'}}>
                    <div id="form_ad" className="input-group col-md-12">
                        <div class="input-group-prepend">
                            <button class="btn btn-warning btn-elevate" type="button" onClick={ToggleUpload} >Batal</button>
                        </div>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customfile" onChange={FileAkses} aria-describedby="customfile"/>
                            <label class="custom-file-label" for="customfile">Pilih File</label>
                        </div>
                        <div class="input-group-append">
                            <button class="btn btn-primary btn-elevate" type="button" onClick={handleClickOpen} id="inputGroupFileAddon04">Upload</button>
                        </div>
                    </div>
                    <div id="uploading_ad" className="col-md-12"style={{display:'none'}}>
                        <Card bg="primary" text="white">
                            <Card.Body>
                            <Card.Title>
                                <h3>
                                    Mengupload Data
                                </h3>
                            </Card.Title>
                            <hr style={{borderTop: '1px solid #f4a240'}}/>
                            <Card.Text>
                                <h4>Nama File : <span>{uploadname}</span></h4>
                                <h4>Proses : <span>{progress}% {progress === 100 ? "(Memproses Di Server)" : ""}</span></h4>
                                <ProgressBar animated  now={progress} variant="warning"/>
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <Row>
                  <Col>
                        <button type="button" class="btn btn-danger btn-elevate mb-2" onClick={handleClickOpen3} id="delete_all"><i class='fa fa-trash'></i> Hapus Semua</button>
                  </Col>
               </Row>
               <table className="table table-hover table-responsive-md" id="aksesdata" style={{width:'100%',overflow:'auto'}}>
                  <thead className="bg-primary" style={{color:'white'}}>
                     <tr> 
                        <th class="all"> Nama Leasing </th>
                        <th class="all"> Jenis Kendaraan </th>
                        <th class="all"> Provinsi </th>
                        <th class="all"> Kabupaten</th>
                        <th class="all"> Akses Data</th>
                        <th class="all"> Nama Range Price</th>
                        <th class="all"> Tipe Range Price</th>
                        <th class="all"> Aksi</th>
                     </tr>
                  </thead>
               </table>
            </PortletBody>
         </Portlet>
      </>
   )
}