import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import {Row, Col} from 'react-bootstrap';
import {
    Portlet,
    PortletBody,
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API';
import Logo from '../../auth/BetaImg/logo-text.png';
import $ from 'jquery';
import moment from 'moment'
import 'moment/locale/id';
import { AlertList} from "react-bs-notifier";
import {getTotalInvoice, getStatusStnk, getFeeTarik} from '../../../functions/invoice'; //formula invoice
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const formatter = new Intl.NumberFormat('de-DE');
const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'fixed',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    detail: {
      offset : theme.mixins.toolbar,
      marginTop : '70px'
    } 
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailPrintAll(props) {
    const classes = useStyles()
    const [alerts, setAlerts] = React.useState([])

    const invc = props.data
    var datas = new Array()
    for(let i=0; i < props.data.length; i++){
      datas[i]= props.data[i].invc_id;
    }
    
    console.log(invc)
    const updatePrintAll = () => {
      var printed = {
        "async": true,
        "crossDomain": true,
       "url": API.Financeapi +"v1/Invoice/updateStatusPrintAll",
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",
            "Authorization": "Basic " + localStorage.getItem('jwt')
        },
        "processData": false,
        "data": JSON.stringify(datas)
      }

      console.log(datas)
      $.ajax(printed).done(function (response) {
          setAlerts([{
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Invoice Berhasil Di Print`
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          window.print()
      }).fail(function (response) {
          setAlerts([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]"
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
      })
    }

    return (
        <> 
        <AlertList showIcon={false} alerts={alerts}/>
        <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <Button
                // onClick={()=>window.print()}
                onClick={updatePrintAll}
                color="inherit"
                size="large"
                startIcon={<PrintIcon />}
                >
                Print
                </Button>
                <Typography variant="h6" className={classes.title}>
                </Typography>
                <IconButton color="inherit" onClick={props.close} aria-label="Close">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            </AppBar>
            <TabPanel value={0} index={0}>
                <Portlet className={classes.detail} style={{marginTop:'20px'}}>

                  {invc.map((data,invc_id) => {
                  if(data.invc_printed_date == null) {
                      return <PortletBody key={invc_id} style={{height:'100vh'}}>
                        <Row>
                          <Col md={6}>
                            <div className='pull-left' style={{ padding:'10px 40px 10px'}}>
                                <img alt="logo" src={Logo} style={{margin:'auto',width:'80%'}}></img>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className='pull-right'>
                                <h1>
                                    <b>
                                    PT. BETA ASET INDONESIA
                                    </b>
                                </h1>
                                <h3>
                                Jl.Raya Bekasi KM 25 No.79,
                                </h3>
                                <h3>
                                Cakung - Jakarta Timur
                                </h3>                
                                <h3>
                                Telp & WA: +62812-8918-8680
                                </h3>
                            </div>
                          </Col>  
                        </Row>
                        <div style={{borderBottom: 'double black 3.5px'}}/>
                        <Row>
                          <Col md={12}>
                            <div style={{marginTop:'-1px'}}>
                              <h1 className="text-center"><b>INVOICE</b></h1>
                            </div>
                              <Row>
                                <Col sm={12}>
                                  <Row>
                                    <Col sm={6}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Nama leasing</h6>
                                      </Col>
                                      <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-67%'}}>: {data.mmle_name}</label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Cabang Asal</h6>
                                      </Col>
                                      <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-67%'}}>: {data.tpnm_branch}</label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Cabang Penerima</h6>
                                      </Col>
                                      <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-67%'}}>: {data.tpnm_delivery_dpname}</label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                          </Col>
                        </Row>
                        <Row></Row>
                        <Row>
                          <Col sm={12}>
                            <Row style={{marginTop:"-75px",marginLeft:"600px"}}>
                              <Col sm={6}>
                                <h6 style={{fontSize:'18px',color:'black'}}>Tanggal Invoice </h6>
                                </Col>
                                <Col sm={6}>
                                <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-30%'}}>: {data.invc_created_date}</label>
                              </Col>
                            </Row>
                            <Row style={{marginTop:"0px",marginLeft:"600px"}}>
                              <Col sm={6}>
                                <h6 style={{fontSize:'18px',color:'black'}}>No. Invoice </h6>
                                </Col>
                                <Col sm={6}>
                                <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-30%'}}>: {data.invc_no}</label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                          <table class="tg" style={{tableLayout: "fixed" , width: "100%"}}>
                            <colgroup>
                            <col style={{width: '610px'}}/>
                            <col style={{width: '160px'}}/>
                            </colgroup>
                            <thead>
                              <tr>
                                <th class="tg-0pky" style={{width:'70%'}}>
                                  <h6 style={{fontSize:'18px',color:'black',marginLeft:'350px',marginTop:'5px'}}>DESKRIPSI </h6>
                                </th>
                                <th class="tg-0pky">
                                <h6 style={{fontSize:'18px',color:'black',marginLeft:'70px',marginTop:'5px'}}>TOTAL </h6>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="tg-0pky" rowSpan="8">
                                  <Row style={{marginTop:'1px'}}>
                                    <Col sm={5}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Nama Debitur </h6>
                                    </Col>
                                    <Col sm={1}>
                                      <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                    </Col>
                                    <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-175px'}}>{data.tpnm_customer_name}</label>
                                    </Col>
                                  </Row><br />
                                  <Row style={{marginTop:'-25px'}}>
                                    <Col sm={5}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>No. Kontrak </h6>
                                    </Col>
                                    <Col sm={1}>
                                      <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                    </Col>
                                    <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-175px'}}>{data.tpnm_contract}</label>
                                    </Col>
                                  </Row><br />
                                  <Row style={{marginTop:'-25px'}}>
                                    <Col sm={5}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>STNK </h6>
                                    </Col>
                                    <Col sm={1}>
                                      <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                    </Col>
                                    <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-175px'}}>{getStatusStnk(data.tpnm_status_stnk)}</label>
                                    </Col>
                                  </Row><br />
                                  <Row style={{marginTop:'-25px'}}>
                                    <Col sm={5}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Type Kendaraan </h6>
                                    </Col>
                                    <Col sm={1}>
                                      <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                    </Col>
                                    <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-175px'}}>{data.tpnm_type}</label>
                                    </Col>
                                  </Row><br />
                                  <Row style={{marginTop:'-25px'}}>
                                    <Col sm={5}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Tahun </h6>
                                    </Col>
                                    <Col sm={1}>
                                      <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                    </Col>
                                    <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-175px'}}>{data.tpnm_vyear}</label>
                                    </Col>
                                  </Row><br />
                                  <Row style={{marginTop:'-25px'}}>
                                    <Col sm={5}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Nomor Polisi </h6>
                                    </Col>
                                    <Col sm={1}>
                                      <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                    </Col>
                                    <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-175px'}}>{data.tpnm_vplate}</label>
                                    </Col>
                                  </Row><br />
                                  <Row style={{marginTop:'-25px'}}>
                                    <Col sm={5}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Nomor Rangka </h6>
                                    </Col>
                                    <Col sm={1}>
                                      <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                    </Col>
                                    <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-175px'}}>{data.tpnm_vin}</label>
                                    </Col>
                                  </Row><br />
                                  <Row style={{marginTop:'-25px'}}>
                                    <Col sm={5}>
                                      <h6 style={{fontSize:'18px',color:'black'}}>Nomor Mesin </h6>
                                    </Col>
                                    <Col sm={1}>
                                      <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                    </Col>
                                    <Col sm={6}>
                                      <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'-175px'}}>{data.tpnm_ein}</label>
                                    </Col>
                                  </Row><br />
                                </td>
                                <td class="tg-0pky" rowSpan="8">
                                  <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'40px'}}> Rp. {formatter.format(getFeeTarik(data))} </label>                             
                                </td>
                              </tr>
                              <tr>
                              </tr>
                              <tr>
                              </tr>
                              <tr>
                              </tr>
                              <tr>
                              </tr>
                              <tr>
                              </tr>
                              <tr>
                              </tr>
                              <tr>
                              </tr>
                              <tr style={{marginTop:"-10px"}}>
                                <td class="tg-0pky">
                                <h6 style={{fontSize:'18px',color:'black',marginLeft:'340px',marginTop:"6px"}}>Biaya Pengiriman </h6>
                                </td>
                                <td class="tg-0pky">
                                  <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'40px'}}>Rp. {data.tvoc_biaya_kirim_subleasing ? formatter.format(parseInt(data.tvoc_biaya_kirim_subleasing)) : 0 }</label>
                                </td>
                              </tr>
                              <tr style={{marginTop:"-10px"}}>
                                <td class="tg-0pky">
                                <h6 style={{fontSize:'18px',color:'black',marginLeft:'370px',marginTop:"6px"}}>TOTAL </h6>
                                </td>
                                <td class="tg-0pky">
                                  <label  class="form-control-plaintext" style={{fontSize:'18px', color:'black',marginTop:"-10px",marginLeft:'40px'}}>Rp. {formatter.format(getTotalInvoice(data))}</label>
                                </td>
                              </tr>
                              <Col sm={12}>
                                <Row style={{marginTop:"5px"}}>
                                  <Col sm={5}>
                                  <h6 style={{fontSize:'18px',color:'black'}}>Pembayaran Ke :  </h6>
                                  </Col>
                                  <Col sm={1}>
                                  <h6 style={{fontSize:'18px',color:'black',opacity:'0'}}> : </h6>
                                  </Col>
                                  <Col sm={6}>
                                  <input type="text" readonly class="form-control-plaintext" disabled style={{fontSize:'18px', color:'black',marginTop:"-10px"}}/>
                                  </Col>
                                </Row><br/>
                                <Row style={{marginTop:"-25px"}}>
                                  <Col sm={5}>
                                  <h6 style={{fontSize:'18px',color:'black'}}>BANK BCA No. Rek  </h6>
                                  </Col>
                                  <Col sm={1}>
                                  <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                  </Col>
                                  <Col sm={6}>
                                  <input type="text" readonly class="form-control-plaintext" value="624-1889898" disabled style={{fontSize:'18px', color:'black',marginTop:"-10px",fontWeight: 'bold',marginLeft:'-175px'}}/>
                                  </Col>
                                </Row><br/>
                                <Row style={{marginTop:"-25px"}}>
                                  <Col sm={5}>
                                  <h6 style={{fontSize:'18px',color:'black'}}>Atas Nama  </h6>
                                  </Col>
                                  <Col sm={1}>
                                  <h6 style={{fontSize:'18px',color:'black',marginLeft:'-130px'}}> : </h6>
                                  </Col>
                                  <Col sm={6}>
                                  <input type="text" readonly class="form-control-plaintext" value='PT. BETA ASET INDONESIA ' disabled style={{fontSize:'18px', color:'black',marginTop:"-10px",fontWeight: 'bold',marginLeft:'-175px'}}/>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={12} style={{ marginLeft:'180px',marginTop:'-80px'}}>
                              <div className="pull-right">
                                <h6 style={{fontSize:'18px',marginBottom:'130px',marginLeft:'35px'}}>Dibuat Oleh:</h6>
                                <h6 style={{fontSize:'18px'}}>(Cahya Hendri Yanto)</h6>
                              </div>
                            </Col>
                            </tbody>
                          </table>
                          </Col>
                        </Row>
                      </PortletBody>
                    }
                  })}
                  
                </Portlet>
            </TabPanel>
        </Dialog>
        </>
    )
}