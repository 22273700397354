//get total invoice
export function getTotalInvoice(data) {
  var totals;
  var biaya_kirim =
    data.tvoc_biaya_kirim_subleasing == null || data.tvoc_biaya_kirim_subleasing == ""
      ? 0
      : data.tvoc_biaya_kirim_subleasing;
  if (data.tpnm_status_stnk == 1 && data.tpnm_fee_stnk != 0) {
    totals =
      (data.tpnm_base_price != null ? parseInt(data.tpnm_base_price) : 0) +
      fee(data.tpnm_fee_stnk) +
      parseInt(biaya_kirim);
  } else {
    totals =
      parseInt(data.tpnm_base_price != null ? data.tpnm_base_price : 0) + parseInt(biaya_kirim);
  }
  return totals;
}

//get fee tarik
export function getFeeTarik(data) {
  var tagihan =
    (data.tpnm_base_price != null ? parseInt(data.tpnm_base_price) : 0) + fee(data.tpnm_fee_stnk);
  return parseInt(tagihan);
}
//get base price
export function getBasePrice(total, fee_stnk, biaya_kirim) {
  let base_price = 0;
  if (fee_stnk > 0 && biaya_kirim > 0) {
    base_price = total - fee_stnk - biaya_kirim;
  } else if (fee_stnk > 0) {
    base_price = total - fee_stnk;
  } else {
    base_price = total;
  }
  return base_price;
}

//get total tagihan
export function getTagihan(data) {
  var tagihan;
  var biaya_kirim = data.tvoc_biaya_kirim_subleasing != null ? data.tvoc_biaya_kirim_subleasing : 0;
  if (data.invc_last_outstanding != null) {
    tagihan = data.invc_last_outstanding;
  } else {
    tagihan =
      (data.tpnm_base_price != null ? parseInt(data.tpnm_base_price) : 0) +
      fee(data.tpnm_fee_stnk) +
      parseInt(biaya_kirim);
  }
  return tagihan;
}

//get fee stnk
export function getFeeStnk(data) {
  return fee(data.tpnm_fee_stnk);
}

//get positif number
export function getPosNum(negNum) {
  var posNum;
  return (posNum = negNum < 0 ? negNum * -1 : negNum);
}
//get Status STNK
export function getStatusStnk(status_stnk) {
  return status_stnk == 1 ? "Ada" : status_stnk == 0 ? "Tidak ada" : "";
}

//get fee stnk (internal request)
function fee(fee_stnk) {
  if (fee_stnk != null) {
    return fee_stnk > 0 ? parseInt(fee_stnk) : 0;
  } else {
    return (fee_stnk = 0);
  }
}

export function getFeeMitra(status_stnk = null, fee = null, fee_stnk = null) {
  var total;
  if (status_stnk == 1) {
    if (fee_stnk == null || fee_stnk == 0) {
      total = parseInt(fee);
    } else {
      total = parseInt(fee) + parseInt(fee_stnk);
    }
  } else {
    total = parseInt(fee);
  }

  return total;
}
