/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
  } from "../../partials/content/Portlet";
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import SettingsIcon from '@material-ui/icons/Settings';
import Check from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PaymentIcon from '@material-ui/icons/Payment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DetailVoucherReject from './Utils/DetailVoucherReject'
import API from '../API'
import Utils from './Utils/FunctionDetail'
import { AlertList} from "react-bs-notifier";
import { getFeeMitra } from '../../functions/invoice'
// const useStyles = makeStyles(theme => ({
//     textField: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//       width: '100%',
//     },
//     dense: {
//       marginTop: 19,
//     },
// }));
const formatter = new Intl.NumberFormat('de-DE');
const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 35,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,#183c7a 0%,#972127 50%,#ff0000 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,#153264 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 70,
      height: 70,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, #ff0000 0%, #ff0000 50%, #ff0000 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, #183c7a 0%, #183c7a 50%, #183c7a 100%)',
    },
  });
  
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    const icons = {
      1: <AssignmentIcon fontSize="large"/>,
      2: <CloseIcon fontSize="large" />,
      3: <PaymentIcon fontSize="large"/>,
      4: <DoneAllIcon fontSize="large"/>,
    };
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? icons[String(props.icon)] : icons[String(props.icon)]}
        
      </div>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

function getSteps() {
    return ['Menunggu Persetujuan', 'Voucher Ditolak','Menunggu Pembayaran', 'Voucher Sudah Lunas'];
  }

export default function VoucherDireject() {
    // const classes = useStyles();

    const [activeStep] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [alerts,setAlerts] = React.useState([])
    const [type, setType] = React.useState(null) //tipe voucher
    const [mpertype, setMperType] = React.useState(null) //tipe mper
    const [tvocId, setTvocId] = React.useState(null) //tvocId
    // const [step, setStep] = React.useState(6);
    const steps = getSteps();

    useEffect(() => {
        $(document).ready(function () {            
              $('#voucher-reject').DataTable({
                "pagingType": "full_numbers",
                "processing": true,
                "serverSide": true,
                "destroy": true,
                "ajax": {
                  "url": API.Financeapi + "v1/Voucher_ditolak/index",
                  "type": 'get',
                  "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                      "Basic " + localStorage.getItem('jwt'));
                  },
                  error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts)
                  }
                },
                "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                  if (aData.tvoc_status == "Voucher sudah diisi") {
                    $('td:eq(9)', nRow).css('background-color', '#81D4FA');
                  } else if (aData.tvoc_status == "Siap Dibayar") {
                    $('td:eq(9)', nRow).css('background-color', '#FFE800');
                  } else if (aData.tvoc_status == "Ditolak") {
                    $('td:eq(9)', nRow).css('background-color', '#FD333F');
                  } else if (aData.tvoc_status == "Telah Dibayar") {
                    $('td:eq(9)', nRow).css('background-color', '#6CFF00');
                  }
                  if (aData.tvoc_type == "Referall") {
                    $('td:eq(1)', nRow).css('background-color', '#9999ff');
                    $('td:eq(8)', nRow).css('background-color', '#9999ff');
                  }
                  if (aData.app_type == "mobil") {
                    $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                  } else if (aData.app_type == "motor") {
                    $('td:eq(0)', nRow).css('background-color', '#F4A460');
                  }
                },
                "columns": [
                  {
                    sortable : true,
                    "render" : function (data,type,full,meta){
                      return "<div>"+ Utils.StatusAppType(full.app_type)+"</div>"
                    }
                  },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    var type_user
                    if (full.tvoc_informan_type == 'mitra'&& full.tpif_id != null) {
                      type_user = "Mitra - Informan"
                    }else{
                      type_user = full.tvoc_informan_type
                    }
                    return " <div > " + type_user + "</div>";
                  }
                },
                  {
                    "data": "lvoc_created_date"
                  },
                  {
                    "data": "lvoc_created_time"
                  },
                  {
                    sortable :true,
                    "render" : function (data, type, full, meta) {
                      var mper_name = full.tvoc_account_name ? full.tvoc_account_name : full.mper_name
                      return " <div >" + mper_name + "</div>";

                    }
                  },
                  {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                      var fee_mitra = full.tvoc_ammount_revisi != null ? full.tvoc_ammount_revisi : full.tvoc_ammount
                      var fee_stnk = full.tvoc_fee_stnk_revisi != null ? full.tvoc_fee_stnk_revisi : full.tvoc_fee_stnk
                      var harga = getFeeMitra(full.tvoc_status_stnk,fee_mitra, fee_stnk)

                      console.log("fee: " + harga)
                      return " <div >" + formatter.format(harga) + "</div>";
                    }
                  },
                  {
                    "data": "tvoc_vehicle_number"
                  },
                  {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                      console.log(full)
                      return " <div > " + full.tpnm_type + " " + full.tpnm_vyear + " " + full.tpnm_vcolor + "</div>";
                    }
                  },
                  {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                      return " <div >" + full.tvoc_type + "</div>";
                    }
                  },
                  {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                      console.log(full)
                      return " <div>" + full.tvoc_status + "</div>";
                    }
                  },
                  {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                      return "<button class='details btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='details' data-type=" + full.tvoc_type + " data-id=" + full.tpnm_id +
                      " data-mpertype="+ full.tvoc_informan_type + " data-tvoc=" + full.tvoc_id + " data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                    }
                  },
                ]
              });
                    $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
                })

                $(document).on('click','#details',function () {
                  setId($(this).attr('data-id'))
                  setType($(this).attr('data-type'))
                  setMperType($(this).attr('data-mpertype'))
                  setTvocId($(this).attr('data-tvoc'))
                  handleClickOpen()
                })
    },[setAlerts])

    function handleClickOpen() {
      setOpen(true);
    }

    function handleClose() {
      setOpen(false);
    }

    return(
        <>              
            <AlertList showIcon={false} alerts={alerts}/>
            <DetailVoucherReject
              open={open} 
              close={handleClose} 
              id={id}
              step={6}
              mpertype={mpertype}
              tvoc={tvocId}
              tvoctype={type}
            />
            <Portlet>
                <PortletHeader
                    title="Step Voucher"
                >
                </PortletHeader>
                <PortletBody>
                    <Grid container spacing={3}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} style={{width:'100%'}}>
                            {steps.map(label => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </PortletBody>
            </Portlet>
            <Portlet>
                <PortletBody>
                    <table className="table table-hover table-responsive-md" id="voucher-reject" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Tipe User</th>
                                  <th scope="col">Tanggal</th>
                                  <th scope="col">Jam</th>
                                  <th scope="col">Nama</th>
                                  <th scope="col">Harga</th>
                                  <th scope="col">Nopol</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Tipe Voucher</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                    </table>
                    <div>
                      <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor  </span>
                      <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil  </span>
                      <button type="button" class="btn btn-default" style={{background:"#9999ff"}}></button><span style={{color:"black"}}> : Referall  </span>
                      <button type="button" class="btn btn-default" style={{background:"#FD333F"}}></button><span style={{color:"black"}}> : Voucher Ditolak  </span>
                    </div>
                </PortletBody>
            </Portlet>
        </>
    )
}