/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, {
    useEffect
} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import API from '../API'
import DialogReason from './Utils/DialogReason'
import DialogHapusBatal from './Utils/DialogHapusBatal'
import { AlertList} from "react-bs-notifier";
import Utils from './Utils/FunctionDetail'


export default function ReasonBatal() {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [idhapus, setIdhapus] = React.useState(null);
    const [alerts, setAlerts] = React.useState([]);

    const handleClickOpen = () => {
        setId(null)
        setOpen(true);
        $('#alasanaja').val('')
        $('#cancel_type').val('pilih')
        $('#role_type').val('pilih')
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const HapusReasonBatal = () => {
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Utilsapi + "v1/reason/delete/" + idhapus,
            "method": "GET",
            "headers": {
                "Authorization": "Basic " + localStorage.getItem('jwt')
            }
        }

        $.ajax(settings).done(function (response) {
            handleClose2()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Reason Batal Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            TableReasonBatal()
        }).fail(function (response) {
            console.log(response)
            handleClose2()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code " + response.status + "]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts);
        })
    };

    useEffect(() => {
        $(document).ready(function () {
            $('#cancel_type').attr('disabled',true)
            if ($('#role_type option:selected').val() == "mitra" || $('#role_type option:selected').val() == "informan") {
                $('#cancel_type').removeAttr('disabled')
            }
            $(document).on('change', '#role_type', function () {
                var mrsn_role = $("#role_type").val();
                $('#cancel_type').removeAttr('disabled')
                $('#cancel_type').val('pilih')
                // eslint-disable-next-line eqeqeq
                if(mrsn_role == "mitra"){
                  $("#pembatalan_menunggu_mitra").hide();
                  $("#pembatalan_validasi").show();
                  $("#pembatalan_surat_tugas").show();
                  $("#pembatalan_sebelum_validasi").show();
                }else{
                  $("#pembatalan_surat_tugas").hide();
                  $("#pembatalan_menunggu_mitra").show();
                  $("#pembatalan_validasi").show();
                  $("#pembatalan_sebelum_validasi").hide();
                }
                });

            
            $('#reason').off('click').on('click', function() {
                $('#reason').on('click', '#edit', function () {
                    handleClickOpen()
                    setId($(this).attr('data-id'))
                    var settings = {
                        "async": true,
                        "crossDomain": true,
                        "url": API.Utilsapi + "v1/reason/edit/" + $(this).attr('data-id'),
                        "method": "GET",
                        "headers": {
                            "Authorization": "Basic " + localStorage.getItem('jwt')
                        }
                    }
                    $.ajax(settings).done(function (response) {
                        $("#cancel_type").val(response.data.mrsn_type).removeAttr('disabled');
                        $("#alasanaja").val(response.data.mrsn_text);
                        $("#role_type").val(response.data.mrsn_role);
                        console.log(response)
                    }).fail(function (response) {
                        console.log(response)
                        API.Handle403(response,setAlerts)
                        return 0
                    })
                    
            })

            $('#reason').on('click', '#hapus', function () {
                handleClickOpen2()
                setIdhapus($(this).attr('data-id'))
            })

            });
            TableReasonBatal()
            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')

        })
    },[setAlerts])

    const TableReasonBatal = () => {
        $('#reason').DataTable({
            "pagingType": "full_numbers",
            "processing": true,
            "serverSide": true,
            "destroy": true,
            "ajax": {
                "url": API.Utilsapi + "/v1/reason/index",
                "type": 'get',
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts);
                }
            },
            "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                if ( aData.mrsn_role == "informan"  ){
                  $('td:eq(0)', nRow).css('background-color', '#a7e9af');
                }else if( aData.mrsn_role == "mitra"  ){
                  $('td:eq(0)', nRow).css('background-color', '#deddfa');
                }
              },
            "columns": [
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                      return " <div >"+Utils.StatusRole(full.mrsn_role)+"</div>";
                        // return " <div >"+getTypeMrsn(full.mrsn_type)+"</div>";
                    }
                },
                {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                        return " <div >" + full.mrsn_type + "</div>";
                        // return " <div >"+getTypeMrsn(full.mrsn_type)+"</div>";
                    }
                },
                {
                    "data": "mrsn_text"
                },
                {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                        return " <div >" + full.mrsn_status + "</div>";
                        // return " <div >"+Utils.StatusUser(full.mrsn_status)+"</div>";
                    }
                },
                {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                        return `
                          <button id="reasonbatal1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="reasonbatal1">
                              <button class='dropdown-item' id='edit' data-id="${full.mrsn_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-edit'></i>Edit Reason Batal</button>
                              <button class='dropdown-item' id='hapus' data-id="${full.mrsn_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus Reason Batal</button>
                          </div>`;
                    }
                },
            ]
        });
    }
    const SubmitBatal = () => {
        // console.log($('#dialogreason').valid())
        var data = {
            'mrsn_type': $('#cancel_type').val(),
            'mrsn_text': $('#alasanaja').val(),
            'mrsn_role': $('#role_type').val()
        };
        console.log(data)
        console.log($('#alasanaja').val())
        var url
        if (id != null) {
            url = API.Utilsapi + "v1/reason/update/" + id;
        } else {
            url = API.Utilsapi + "v1/reason/add";
        }

        var settings = {
            "async": true,
            "crossDomain": true,
            "url": url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('jwt')
            },
            "processData": false,
            "data": JSON.stringify(data)
        }

        $.ajax(settings).done(function (response) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: id!=null?"Reason Batal Telah Diubah":"Reason Batal Telah Ditambahkan"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose()
            TableReasonBatal()
        }).fail(function (response) {
            console.log(response)
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code " + response.status + "]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    return(
        <>  
            <AlertList showIcon={false} alerts={alerts}/>
            <DialogReason 
                open={open} 
                close={handleClose} 
                title={id!=null?"Edit Reason Batal":"Tambah Reason Batal"} 
                nego="none" 
                submit={SubmitBatal}
            />
            <DialogHapusBatal 
                open={open2} 
                close={handleClose2} 
                title="Hapus Reason Batal" 
                message="Anda Yakin Ingin Hapus Reason Ini ?" 
                btn="Hapus" 
                fun={HapusReasonBatal}
                // type="delete" 
                // id={idhapus}
            />
            <Portlet>
                <PortletHeader
                    title="Data Reason Batal"
                    toolbar={(
                        <PortletHeaderToolbar>
                            <button
                                type="button"
                                className="btn btn-label-primary"
                                onClick={handleClickOpen}
                            >
                                <i class="fas fa-plus"></i>Tambah Reason Batal
                            </button>
                        </PortletHeaderToolbar>
                    )}
                >
                </PortletHeader>
                <PortletBody>
                    <table className="table table-hover table-responsive-md" id="reason" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                <th scope="col">Role</th>
                                <th scope="col">Tipe</th>
                                <th scope="col">Alasan</th>
                                <th scope="col">Status</th>
                                <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                    </table>
                    <div>
                        <button type="button" class="btn btn-default" style={{background:"#deddfa"}}></button><span style={{color:"black"}}> : Mitra   </span>
                        <button type="button" class="btn btn-default" style={{background:"#a7e9af"}}></button><span style={{color:"black"}}> : Mitra (Oleh Informan)   </span>
                    </div>
                </PortletBody>
            </Portlet>
        </>
    )
}