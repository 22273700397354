/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React, {useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../API'
import DialogConfigKomisi from './Utils/DialogConfigKomisi'
import DialogConfigBonus from './Utils/DialogConfigBonus'
// import DialogHapusBatal from './Utils/DialogHapusBatal'
import { AlertList} from "react-bs-notifier";
import {
Portlet,
PortletBody,
PortletHeader,
// PortletHeaderToolbar
} from "../../partials/content/Portlet";
import Utils from './Utils/FunctionDetail'

export default function KomisiBonus() {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [id, setID] = React.useState(null);
    // const [id, setId] = React.useState('');
    const [alerts, setAlerts] = React.useState([]);
    const formatter = new Intl.NumberFormat('de-DE');

    function handleClickOpen() {
        // setId(null)
        //  $('#alasanaja').val('')
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    
    function handleClickOpen2() {
        setOpen2(true);
    }

    function handleClose2() {
        setOpen2(false);
    }

    function SubmitKomisi() {

        var data = {
            "tcif_informan_bike_fee_type":$('#tcif_informan_bike_fee_type').val(),
            "tcif_informan_bike_fee_nominal":$('#tcif_informan_bike_fee_nominal').val(),
            "tcif_informan_car_fee_type":$('#tcif_informan_car_fee_type').val(),
            "tcif_informan_car_fee_nominal":$('#tcif_informan_car_fee_nominal').val()
        }

        console.log(data)
        
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Foundingapi + "v1/informanConfig/update",
            "method": "POST",
            "headers": {
                "Authorization": "Baisc " + localStorage.getItem('jwt')
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": JSON.stringify(data)
        }

        $.ajax(settings).done(function (response) {
            handleClose()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Komisi Telah Diedit"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

    function SubmitBonus() {

        var data = {
            "cbif_get":$('#cbif_get').val(),
            "cbif_min_out":$('#cbif_min_out').val(),
            "cbif_max_out":$('#cbif_max_out').val(),
            "cbif_multiple":$('#cbif_multiple').val()
        }

        console.log(data)
        
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi + "v1/pricing/update_bonus_informan/"+id,
            "method": "POST",
            "headers": {
                "Authorization": "Baisc " + localStorage.getItem('jwt')
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": JSON.stringify(data)
        }

        $.ajax(settings).done(function (response) {
            handleClose2()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Bonus Telah Diubah"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose2()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

     useEffect(() => {
         $(document).ready(function () {
             $('#komisi').DataTable({
                 "pagingType": "full_numbers",
                 "processing": true,
                 "serverSide": true,
                 "destroy": true,
                 "ajax": {
                     "url": API.Foundingapi + "v1/InformanConfig/list",
                     "type": 'get',
                     "beforeSend": function (xhr) {
                         xhr.setRequestHeader('Authorization',
                             "Basic " + localStorage.getItem('jwt'));
                     },
                     error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts)
                     }
                 },
                 "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                  console.log(aData)
                },
                 "columns": [
                    //  {
                    //      "data": "mtob_image"
                    //  },
                    //  {
                    //     sortable: true, 
                    //     "render": function ( data, type, full, meta ) {
                    //         // if ( full.mmle_logo == null){
                    //     return "<img src='"+full.mtob_image+"' style='height:70px;weight:50px;border:1px solid #c5c8f0'>";
                    //     // }else{
                    //     //     return  "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+full.mmle_logo+">"+
                    //     //     "<img src="+full.mmle_logo+" style='height:70px;weight:50px;border:1px solid #c5c8f0'>"+
                    //     //     "</a>"
                    //     // }
                    //  }
                    //  },
                     {
                         "data": "tcif_informan_id"
                     },
                     {
                         "data": "tcif_informan_bike_fee_type"
                     },
                     {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            // return " <div >"+getStatusMperAll(full.mper_type, full.mper_id)+"</div>";
                            return " <div >" + formatter.format(full.tcif_informan_bike_fee_nominal) + "</div>";
                        }
                     },
                    //  {
                    //      "data": "tcif_informan_bike_fee_nominal"
                    //  },
                     {
                         "data": "tcif_informan_car_fee_type"
                     },
                     {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            // return " <div >"+getStatusMperAll(full.mper_type, full.mper_id)+"</div>";
                            return " <div >" + formatter.format(full.tcif_informan_car_fee_nominal) + "</div>";
                        }
                     },
                    //  {
                    //      "data": "tcif_informan_car_fee_nominal"
                    //  },
                     {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            return "<div id='aksi'>" +
                                "<button class='btn btn-outline-brand btn-icon btn-sm hapus tooltips mr-1 mb-1' data-toggle='tooltip' data-placement='top' title='Tooltip on top' id='edit' data-id=" + full.tcif_informan_id +
                                " data-original-title='Hapus User'><i class='fa fa-edit'></i></button>" +
                                "</div>";
                        }
                     },
                 ]
             });
             $('#bonus').DataTable({
                 "pagingType": "full_numbers",
                 "processing": true,
                 "serverSide": true,
                 "destroy": true,
                 "ajax": {
                     "url": API.Userapi + "v1/Pricing/list_bonus_informan",
                     "type": 'get',
                     "beforeSend": function (xhr) {
                         xhr.setRequestHeader('Authorization',
                             "Basic " + localStorage.getItem('jwt'));
                     },
                     error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts)
                     }
                 },
                 "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    if ( aData.app_type == "mobil"  ){
                        $('td:eq(1)', nRow).css('background-color', '#AFEEEE');
                      }else if( aData.app_type == "motor"  ){
                        $('td:eq(1)', nRow).css('background-color', '#F4A460');
                      }
                },
                 "columns": [
                    //  {
                    //      "data": "mtob_image"
                    //  },
                    //  {
                    //     sortable: true, 
                    //     "render": function ( data, type, full, meta ) {
                    //         // if ( full.mmle_logo == null){
                    //     return "<img src='"+full.mtob_image+"' style='height:70px;weight:50px;border:1px solid #c5c8f0'>";
                    //     // }else{
                    //     //     return  "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+full.mmle_logo+">"+
                    //     //     "<img src="+full.mmle_logo+" style='height:70px;weight:50px;border:1px solid #c5c8f0'>"+
                    //     //     "</a>"
                    //     // }
                    //  }
                    //  },
                     {
                         "data": "cbif_id"
                     },
                     {
                       sortable : true,
                        "render" : function (data , type , full , meta){
                            return "<div>"+ Utils.StatusAppType(full.app_type)+"</div>"
                        }
                     },
                     {
                         "data": "cbif_get"
                     },
                     {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            // return " <div >"+getStatusMperAll(full.mper_type, full.mper_id)+"</div>";
                            return " <div >" + formatter.format(full.cbif_min_out) + "</div>";
                        }
                     },
                     {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            // return " <div >"+getStatusMperAll(full.mper_type, full.mper_id)+"</div>";
                            return " <div >" + formatter.format(full.cbif_max_out) + "</div>";
                        }
                     },
                     {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                            // return " <div >"+getStatusMperAll(full.mper_type, full.mper_id)+"</div>";
                            return " <div >" + formatter.format(full.cbif_multiple) + "</div>";
                        }
                     },
                    //  {
                    //     "data": "cbif_min_out"
                    //  },
                    //  {
                    //      "data": "cbif_max_out"
                    //  },
                    //  {
                    //      "data": "cbif_multiple"
                    //  },
                    //  {
                    //      "data": "cbif_updated_date"
                    //  },
                    //  {
                    //      "data": "cbif_updated_time"
                    //  },
                     {
                        sortable: true,
                        "render": function (data, type, full, meta) {
                          // (BEBEK) HONDA NEW VARIO 110 E 2016 ABU ABU
                          return " <div > " + full.cbif_updated_date + " - " + full.cbif_updated_time + " </div>";
                        }
                      },
                     {
                         sortable: true,
                         "render": function (data, type, full, meta) {
                             console.log(full)
                             return "<div id='aksi'>" +
                                 "<button class='btn btn-outline-brand btn-icon btn-sm hapus tooltips mr-1 mb-1' data-toggle='tooltip' data-placement='top' title='Tooltip on top' id='edit2' data-id=" + full.cbif_id +" data-get=" + full.cbif_get +" data-min="+full.cbif_min_out+" data-max="+full.cbif_max_out+" data-multi="+full.cbif_multiple+
                                 " data-original-title='Hapus User'><i class='fa fa-edit'></i></button>" +
                                 "</div>";
                         }
                     },
                 ]
             });

             $(document).on('click', '#edit', function () {
                handleClickOpen()
                 var settings = {
                    "async": true,
                    "crossDomain": true,
                    "url": API.Foundingapi + "v1/informanConfig/list",
                    "method": "GET",
                    "headers": {
                        "Authorization": "Baisc " + localStorage.getItem('jwt')
                    }
                }
                $.ajax(settings).done(function (response) {
                    console.log(response.data[0])
                    $('#tcif_informan_bike_fee_type').val(response.data[0].tcif_informan_bike_fee_type)
                    $('#tcif_informan_bike_fee_nominal').val(response.data[0].tcif_informan_bike_fee_nominal)
                    $('#tcif_informan_car_fee_type').val(response.data[0].tcif_informan_car_fee_type)
                    $('#tcif_informan_car_fee_nominal').val(response.data[0].tcif_informan_car_fee_nominal)
                })
                //  setId($(this).attr('data-id'))
             })

             $(document).on('click', '#edit2', function () {
                 handleClickOpen2()
                    setID($(this).attr('data-id'))
                    $('#cbif_get').val($(this).attr('data-get'))
                    $('#cbif_min_out').val($(this).attr('data-min'))
                    $('#cbif_max_out').val($(this).attr('data-max'))
                    $('#cbif_multiple').val($(this).attr('data-multi'))
             })

             $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
         })
     },[setAlerts])

    return(
        <>
            <AlertList  showIcon={false} alerts={alerts}/>
            <DialogConfigKomisi
                open={open} 
                close={handleClose} 
                title="Edit Komisi"
                // bonus="none"
                fun={SubmitKomisi}
            />
            <DialogConfigBonus
                open={open2} 
                close={handleClose2} 
                title="Edit Bonus"
                // komisi="none"
                fun={SubmitBonus}
            />
            <div className="row" style={{background:'transparent'}}>
                <div className="col-xl-5">
                    <Portlet>
                        <PortletHeader title="Komisi" />
                        <PortletBody>
                            <table className="display table table-hover table-responsive-md" id="komisi"
                                style={{width:'100%'}}>
                                <thead className="bg-primary" style={{color:'white'}}>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Tipe Fee Motor</th>
                                        <th scope="col">Nominal Fee Motor</th>
                                        <th scope="col">Tipe Fee Mobil</th>
                                        <th scope="col">Nominal Fee Mobil</th>
                                        <th scope="col">Aksi</th>
                                    </tr>
                                </thead>
                            </table>
                        </PortletBody>
                    </Portlet>
                </div>
                <div className="col-xl-7">
                    <Portlet>
                        <PortletHeader title="Bonus" />
                        <PortletBody>
                            <table className="display table table-hover table-responsive-md" id="bonus"
                                style={{width:'100%'}}>
                                <thead className="bg-primary" style={{color:'white'}}>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Tipe</th>
                                        <th scope="col">Bonus</th>
                                        <th scope="col">Minimal Penarikan</th>
                                        <th scope="col">Maksimal Penarikan</th>
                                        <th scope="col">Kelipatan</th>
                                        {/* <th scope="col">Aksi</th> */}
                                        <th scope="col">Waktu</th>
                                        <th scope="col">Aksi</th>
                                    </tr>
                                </thead>
                            </table>
                            <div>
                                <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}>   Motor   </span>
                                <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}>   Mobil   </span>    
                            </div>
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
        </>
    )
}