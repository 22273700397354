import React, {useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import {
	Portlet,
	PortletBody,
	PortletHeader,
	PortletHeaderToolbar
  } from "../../partials/content/Portlet";
import API from '../API';
import { AlertList} from "react-bs-notifier";
import ExcelRangePricing from './Settings/range_pricing.xlsx';
import {ProgressBar, Card} from 'react-bootstrap';
import {Row, Col} from 'react-bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';
import DialogUpload from './Settings/DialogUpload';
import Table from './Settings/TableSetting';
import KuotaDownline from './Settings/KuotaDownline';
import Skema from './Settings/Skema';
import DialogRange from './Settings/DialogRange';
import AksesData from './Settings/AksesData';


const formatter = new Intl.NumberFormat('de-DE');

export default function Settings() {

	const [openrp, setOpenRP] = React.useState(false); //Upload xcel range pricing
	const [open1, setOpen1] = React.useState(false); //edit range pricing
	const [open2, setOpen2] = React.useState(false); //hapus range pricing
	const [open3, setOpen3] = React.useState(false); //hapus all range pricing
	const [filerp, setFileRP] = React.useState(null);
	const [rprcId, setRprcId] = React.useState(null); //range pricing Id
	const [typerp, setTypeRP]= React.useState(null);
	const [uploadnamerp, setUploadnamerp] = React.useState(null);
	const [progressrp, setProgressRP] = React.useState(0);
	const [alerts, setAlerts] = React.useState([]);

	const ToggleUploadRP = () =>{
        $('#uploadformRP').toggle('slow')
	}

	const FileRP = (e) => {
        setFileRP(e.target.files[0])
	}

	const handleClickOpenRP =() => {
        setOpenRP(true);
    }

	const handleCloseRP = () => {
        setOpenRP(false);
	}
	const handleClickOpen1 = () => {
		setOpen1(true)
	}
	const handleClose1 = () => {
		setOpen1(false)
	}
	const handleClickOpen2 = () => {
		setOpen2(true)
	}
	const handleClose2 = () => {
		setOpen2(false)
	}
	const handleClickOpen3 = () => {
		setOpen3(true)
	}
	const handleClose3 = () => {
		setOpen3(false)
	}
	
	useEffect(() => {
		$(document).ready(function () {
			TablePricing()

			$('#rangepricing').on('click', '#editRangePricing', function() {
				handleClickOpen1()
				setRprcId($(this).attr('data-id'));

				var editPrice = {
					"async":true,
					"crossDomain":true,
					"url": API.Userapi + "v1/Master/getRprc/" + $(this).attr('data-id'),
					"method": "GET",
					"headers": {
						"Authorization": "Basic "+ localStorage.getItem('jwt')
					}
				}
				$.ajax(editPrice).done(function (response ) {
					if(response.data !=null ) {
						if(response.data.type_range === 'nonrange') {
							$('#div_start_range').hide()
							$('#div_end_range').hide()
						}else{
							$('#div_start_range').show()
							$('#div_end_range').show()
						}
						setTypeRP(response.data.type_range)
						$('#name_range').val(response.data.range_price_name)
						$('#start_range').val(formatter.format(parseInt(response.data.start_range_price)))
						$('#end_range').val(formatter.format(parseInt(response.data.end_range_price)))
						$('#potongan_percent').val(response.data.potongan_percent+"%")
						$('#potongan_minimal').val(formatter.format(parseInt(response.data.potongan_minimal)))
						$('#upline1').val(formatter.format(parseInt(response.data.prc_upline1)))
						$('#upline2').val(formatter.format(parseInt(response.data.prc_upline2)))
						$('#potongan_stnk').val(formatter.format(parseInt(response.data.potongan_stnk)))
						$('#komisi_informan').val(formatter.format(parseInt(response.data.komisi_informan)))
					}
				}).fail(function (response) {
					API.Handle403(response,setAlerts)
					return 0
				})
			})

			$('#rangepricing').on('click', '#hapusRangePricing', function (){
				setRprcId($(this).attr('data-id'));
				handleClickOpen2()
			})
		
			bsCustomFileInput.init()
		})
	},[setAlerts])

	const TablePricing = () => {
		Table.Pricing(setAlerts);
		$('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
	}
	const UploadRP = () => {
		handleCloseRP()
		var formRP = new FormData()
		formRP.append("uploaded_filerp", filerp)
		setUploadnamerp(filerp.name)
		$("#uploadingrp").show('slow')
		$("form_rp").hide('slow')

		var uploadRP = {
			xhr: function() {
				var xhr = new window.XMLHttpRequest();
				xhr.upload.addEventListener('progress', function(e) {
					if(e.lengthComputable) {
						console.log(e);
						console.log('Bytes Loaded : ' + e.loaded);
                        console.log('Total Size : ' + e.total);
						console.log('Persen : ' + (e.loaded / e.total));
						
						var percentrp = Math.round((e.loaded / e.total) * 100);
						console.log("Persen : " + percentrp)

						setProgressRP(percentrp)
					}
				})
				return xhr;
			},
			"async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/Master/upldRangePricing",
            "method": "POST",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem("jwt"),
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": formRP
		}

		$.ajax(uploadRP).done(function (response){
			setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Range Pricing Telah Diupload"
            }])
            setTimeout(() => {
               $('#uploadingrp').hide('slow')
					$('#form_rp').show('slow')
				setAlerts([])
			}, 3000)
			TablePricing()
		}).fail(function (response) {
            $('#uploading').hide('slow')
            $('#form_rp').show('slow')
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
               setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
	}

	const SubmitPricing = () => {
		
		if(typerp === 'nonrange') {
			var start_range = null
			var end_range = null
		}else{
			var start_range = $('#start_range').val().replace(/\D/g, '')
			var end_range = $('#end_range').val().replace(/\D/g, '')
		}
		var potongan_percent = $('#potongan_percent').val()
		var potongan_minimal = $('#potongan_minimal').val().replace(/\D/g, '')
		var upline1 = $('#upline1').val().replace(/\D/g, '')
		var upline2 = $('#upline2').val().replace(/\D/g, '')
		var potongan_stnk = $('#potongan_stnk').val().replace(/\D/g, '')
		var komisi_informan = $('#komisi_informan').val().replace(/\D/g, '')

		var datarangeprc = {
			'start_range_price': start_range,
			'end_range_price': end_range,
			'potongan_percent': potongan_percent,
			'potongan_minimal': potongan_minimal,
			'prc_upline1': upline1,
			'prc_upline2': upline2,
			'potongan_stnk': potongan_stnk,
			'komisi_informan': komisi_informan
		}
		var url = API.Userapi + "v1/Master/putRangePrc/" + rprcId+ "?type="+typerp

		var rangeprc = {
			"async":true,
			"crossDomain":true,
			"url": url,
			"method": "POST",
			"headers": {
				"Content-Type": "application/json",
				"Authorization": "Basic " + localStorage.getItem('jwt')
			},
			"processData":false,
			"data":JSON.stringify(datarangeprc)
		}
		$.ajax(rangeprc).done(function(response){
			setAlerts([{
					id: 1,
					type: "success",
					headline: "Berhasil",
					message: "Range Price Telah Disimpan"
			}])
			setTimeout(() => {
					setAlerts([])
			}, 3000)
			handleClose1()
			TablePricing()
		}).fail(function (response) {
			setAlerts([{
				 id: 1,
				 type: "danger",
				 headline: "Gagal",
				 message: "Terjadi Kendala [Error Code "+response.status+"]"
			}])
			setTimeout(() => {
				 setAlerts([])
			}, 3000)
			API.Handle403(response,setAlerts)
	  })
	}

	function HapusRangePricing() {
		
		var delPricing = {
			"async": true,
			"crossDomain":true,
			"url": API.Userapi + "v1/Master/delRprc/"+rprcId,
			"method": "GET",
			"headers": {
				"Authorization": "Basic "+localStorage.getItem('jwt')
			}
		}
		$.ajax(delPricing).done(function( response ) {
			setAlerts([{
				id: 1,
				type: "success",
				headline: "Berhasil",
				message: "Range Pricing Berhasil Dihapus"
			}])
			setTimeout(() => {
                setAlerts([])
			}, 3000)
			handleClose2()
			TablePricing()
		}).fail(function (response) {
			setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
			setTimeout(() => {
					setAlerts([])
			}, 3000)
			API.Handle403(response,setAlerts)
		})
	}

	function HapusAllRangePricing() {
		var delAllRange = {
			"async":true,
			"crossDomain": true,
			"url": API.Userapi + "v1/Master/delAllRprc",
			"method": "GET",
			"headers": {
				"Authorization": "Basic "+localStorage.getItem('jwt')
			}
		}
		$.ajax(delAllRange).done(function (response ) {
			setAlerts([{
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Total "+response.total+" Range Price Telah Dihapus"
          }])
          setTimeout(() => {
            setAlerts([])
			 }, 3000)
			 handleClose3()
			 TablePricing()
		}).fail(function (response) {
			setAlerts([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]"
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
		})

	}

	return (
		<>
			<Portlet>
				<DialogUpload 
					open={openrp}
					close={handleCloseRP}
					title="Upload Range Pricing"
					message="Anda Yakin Ingin Upload File Range Pricing Ini ?"
					btn="Upload"
					fun={UploadRP}
				/>
				<DialogRange 
					open={open1}
					close={handleClose1}
					title={rprcId !=null ? "Edit Range Pricing" : "Tambah Range Pricing" }
					btn={rprcId !=null ? "Edit" : "Tambah"}
					fun={SubmitPricing}
				/>
				<DialogUpload 
					open={open2}
					close={handleClose2}
					title="Hapus Range Pricing"
					message="Anda Yakin Ingin Hapus Data Range Pricing Ini ?"
					btn= "Hapus"
					fun={HapusRangePricing}
				/>
				<DialogUpload 
					open={open3}
					close={handleClose3}
					title="Hapus Semua Range Pricing"
					message="Anda Yakin Ingin Hapus Semua Data Range Pricing Ini ?"
					btn= "Hapus Semua"
					fun={HapusAllRangePricing}
				/>
				<AlertList  showIcon={false} alerts={alerts}/>
				
				<PortletHeader title="Setting Range Price" toolbar={(
					<PortletHeaderToolbar>
						<a
							href={ExcelRangePricing}
							type="button"
							className="btn btn-label-primary btn-sm"
						>
							<i class="fas fa-file-download"></i>Template Pengisian Data
						</a>
						<button
							type="button"
							className="btn btn-label-primary btn-sm mr-1 ml-1"
							onClick={ToggleUploadRP}
						>
							<i class="fas fa-file-upload"></i>Upload Data
						</button>
					</PortletHeaderToolbar>
				)}/>
				<PortletBody>
					<div class="mb-2" id="uploadformRP" style={{display:'none'}}>
						<div id="form_rp" className="input-group col-md-12">
							<div class="input-group-prepend">
								<button class="btn btn-warning btn-elevate" type="button" onClick={ToggleUploadRP} >Batal</button>
							</div>
							<div class="custom-file">
								<input type="file" class="custom-file-input" id="customfile" onChange={FileRP} aria-describedby="customfile"/>
								<label class="custom-file-label" for="customfile">Pilih File</label>
							</div>
							<div class="input-group-append">
								<button class="btn btn-primary btn-elevate" type="button" onClick={handleClickOpenRP} id="inputGroupFileAddon04">Upload</button>
							</div>
						</div>
						<div id="uploadingrp" className="col-md-12" style={{display:'none'}}>
							<Card bg="primary" text="white">
								<Card.Body>
								<Card.Title>
									<h3>
										Mengupload Data
									</h3>
								</Card.Title>
								<hr style={{borderTop: '1px solid #f4a240'}}/>
								<Card.Text>
									<h4>Nama File : <span>{uploadnamerp}</span></h4>
									<h4>Proses : <span>{progressrp}% {progressrp == 100 ? "(Memproses Di Server)" : ""}</span></h4>
									<ProgressBar animated  now={progressrp} variant="warning"/>
								</Card.Text>
								</Card.Body>
							</Card>
						</div>
					</div>
					<Row>
						<Col>
							<button type="button" class="btn btn-danger btn-elevate mb-2" onClick={handleClickOpen3} id="delete_all"><i class='fa fa-trash'></i> Hapus Semua</button>
						</Col>
					</Row>
					<table className="table table-hover table-responsive-md" id="rangepricing" style={{width:'100%'}}>
						<thead className="bg-primary" style={{color:'white'}}>
							<tr>
								<th scope="col">Nama</th>
								<th scope="col">Type Range</th>
								<th scope="col">Jenis Kendaraan</th>
								<th scope="col">Start Range</th>
								<th scope="col">End Range</th>
								<th scope="col">Potongan %</th>
								<th scope="col">Potongan Minimal</th>
								<th scope="col">Upline lv 1</th>
								<th scope="col">Upline lv 2</th>
								<th scope="col">Komisi Informan</th>
								<th scope="col">Potongan STNK</th>
								<th scope="col">Aksi</th>
							</tr>
						</thead>
					</table>
				</PortletBody>
			</Portlet>
			<AksesData />
			<KuotaDownline />
			<Skema />
		</>
	)
}