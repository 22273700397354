/* eslint-disable eqeqeq */
import $ from "jquery";
import API from "../../API";

export default {
  Provinsi: (id, html) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Utilsapi + "v1/address/provinsi?mprv_id=" + id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings).done(function(response) {
      $(html).val(response.data[0].mprv_name);
    });
  },
  Kota: (id, html) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Utilsapi + "v1/address/kota?mprv_id=" + id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings).done(function(response) {
      $(html).val(response.data[0].mcty_name);
    });
  },
  Kecamatan: (id, html) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Utilsapi + "v1/address/distrik?mprv_id=" + id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings).done(function(response) {
      $(html).val(response.data[0].mdst_name);
    });
  },
  MperAll: (mper_type) => {
    var role_type = "??????";
    if (mper_type == 1) {
      role_type = "Eksekutor";
    } else if (mper_type == 2) {
      role_type = "Informan";
    } else if (mper_type == 3) {
      role_type = "Leasing Admin";
    } else if (mper_type == 4) {
      role_type = "Leasing Approver";
    } else if (mper_type == 5) {
      role_type = "Internal Admin";
    } else if (mper_type == 6) {
      role_type = "Admin Operation";
    } else if (mper_type == 7) {
      role_type = "Admin Registrasi";
    } else if (mper_type == 8) {
      role_type = "Admin Finance";
    } else if (mper_type == 9) {
      role_type = "Leasing Cabang";
    } else if (mper_type == 10) {
      role_type = "Admin Verifikasi";
    } else if (mper_type == 11) {
      role_type = "Leasing Cabang & Eksekutor";
    }
    return role_type;
  },
  DataNull: (tpnm_data) => {
    var data = tpnm_data;
    if (tpnm_data == null || tpnm_data == "") {
      data = "";
    }
    return data;
  },
  StatusAppType: (app_type) => {
    var status = "???";
    if (app_type == "motor") {
      status = "Motor";
    } else if (app_type == "mobil") {
      status = "Mobil";
    } else if (app_type == "bonus") {
      status = "Bonus";
    } else if (app_type == "bpkb") {
      status = "BPKB";
    }
    return status;
  },
  StatusRole: (mrsn_role) => {
    var role = "???";
    if (mrsn_role == "informan") {
      role = "Informan";
    } else if (mrsn_role == "mitra") {
      role = "Mitra";
    }
    return role;
  },
  StatusRegistrasi: (mper_status) => {
    var status = "Offline";
    if (mper_status == 1) {
      status = "Offline";
    } else if (mper_status == 2) {
      status = "Online";
    }
    return status;
  },
  StatusUser: (mper_status) => {
    var status = "??????";
    if (mper_status == 0) {
      status = "Non Aktif";
    } else if (mper_status == 1) {
      status = "Aktif";
    }
    return status;
  },
  StatusDroppoint: (tadp_status) => {
    var status = "??????";
    if (tadp_status == 0) {
      status = "Non Aktif";
    } else if (tadp_status == 1) {
      status = "Aktif";
    }
    return status;
  },
  TypeUpload: (thdb_status) => {
    var thdb_msg = "??? (Hubungi Admin)";
    if (thdb_status == 1) {
      thdb_msg = "Hapus Data";
    } else if (thdb_status == 2) {
      thdb_msg = "Tambahkan Data";
    } else if (thdb_status == 3) {
      thdb_msg = "Gantikan Data";
    } else if (thdb_status == 4) {
      thdb_msg = "Gantikan Data Semua Leasing";
    } else if (thdb_status == 5) {
      thdb_msg = "Tambahkan Data Semua Leasing";
    } else if (thdb_status == 6) {
      thdb_msg = "Tambahkan Data BPKB";
    } else if (thdb_status == 7) {
      thdb_msg = "Gantikan Data BPKB";
    }
    return thdb_msg;
  },
  StatusUpload: (thdb_status) => {
    var thdb_msg = "??????";
    if (thdb_status == 1) {
      thdb_msg = "Uploaded";
    } else if (thdb_status == 2) {
      thdb_msg = "Approved by Leasing Approver";
    } else if (thdb_status == 3) {
      thdb_msg = "Rejected by Leasing Approver";
    } else if (thdb_status == 4) {
      thdb_msg = "Approve by BETA";
    } else if (thdb_status == 5) {
      thdb_msg = "Reject By BETA";
    }
    return thdb_msg;
  },
  AppType: (id) => {
    var msg = "???";
    if (id == 1) {
      msg = "Motor";
    } else if (id == 2) {
      msg = "Mobil";
    }
    return msg;
  },
  StepPembatalan: ($tpif_step) => {
    var tpnm_msg = "???";
    if ($tpif_step == null) {
      if ($tpif_step == 1) {
        tpnm_msg = "Validasi Informan";
      } else if ($tpif_step == 2) {
        tpnm_msg = "Matchmaking";
      }
    } else {
      if ($tpif_step == 1) {
        tpnm_msg = "Validasi";
      } else if ($tpif_step == -1) {
        tpnm_msg = "Matched (Mitra Menuju Informan)";
      } else if ($tpif_step == -2) {
        tpnm_msg = "Matched (Mitra Ditemukan)";
      } else if ($tpif_step == 2) {
        tpnm_msg = "Menunggu Surat Tugas";
      } else if ($tpif_step == 3) {
        tpnm_msg = "Surat Tugas Diterima";
      } else if ($tpif_step == 4) {
        tpnm_msg = "Pengantaran";
      } else if ($tpif_step == 5) {
        tpnm_msg = "Dalam Pengantaran";
      }
    }
    return tpnm_msg;
  },
  Referal: (mper_id, id_html) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/user/referal?mper=" + mper_id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings).done(function(response) {
      if (response.data[0] != null) {
        var newState = new Option(response.data[0].mper_name, mper_id, true, true);
        $(id_html)
          .append(newState)
          .trigger("change");
      }
    });
  },

  RoleType: (mper_type, id_html) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/user/mperole?mper_type=" + mper_type,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings).done(function(response) {
      if (response.data[0] != null) {
        var newState = new Option(response.data[0].mple_name, mper_type, true, true);
        $(id_html)
          .append(newState)
          .trigger("change");
      }
    });
  },
  setProvinsi: (mprv_id, id_html) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Utilsapi + "v1/address/provinsi?mprv_id=" + mprv_id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings).done(function(response) {
      var newState = new Option(response.data[0].mprv_name, mprv_id, true, true);
      $(id_html)
        .append(newState)
        .trigger("change");
      // $(id_html).val(response.data[0].mprv_name);
    });
  },
  setKecamatan: (mdst_id, id_html) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Utilsapi + "v1/address/distrik?mdst_id=" + mdst_id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings).done(function(response) {
      if (response.data[0] != null) {
        var newState = new Option(response.data[0].mdst_name, mdst_id, true, true);
        $(id_html)
          .append(newState)
          .trigger("change");
      }
    });
  },
  setKota: (mcty_id, id_html) => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Utilsapi + "v1/address/kota?mcty_id=" + mcty_id,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings).done(function(response) {
      var newState = new Option(response.data[0].mcty_name, mcty_id, true, true);
      $(id_html)
        .append(newState)
        .trigger("change");
    });
  },
};
