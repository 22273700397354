import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'jquery-mask-plugin';

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction = "up"
   ref = {
       ref
   } {
       ...props
   }
   />;
});

const useStyles = makeStyles(theme => ({
   root: {
       display: 'flex',
       flexDirection: 'column',
       margin: 'auto',
       width: '80%',
   },
}));

export default function DialogAksesData(props) {

    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth="sm"
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                style={{zIndex:'999999999'}}
            >
            <DialogTitle className="dptitle" id="alert-dialog-slide-title">{props.title}</DialogTitle>
            <DialogContent id="aksespotongandialog">
                <form className={classes.root} id="formAksesPotongan" autoComplete="off">
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nama Leasing</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="nama_leasing" disabled/>
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Provinsi</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="akses_provinsi" disabled/>
                        </div>
                    </div>
                    
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Kabupaten</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="akses_kabupaten" disabled/>
                        </div>
                    </div>

                    {/* <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Provinsi</label>
                        <div className="col-sm-7">
                            <select className="form-control" id="akses_provinsi">
                                <option value="0" selected disabled> Pilih Provinsi</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Kota/Kabupaten</label>
                        <div className="col-sm-7">
                            <select className="form-control" id="akses_kabupaten">
                                <option value="0" selected disabled> Pilih Kota/Kabupaten</option>
                            </select>
                        </div>
                    </div> 
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Jenis kendaraan</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="jenis_kendaraan"/>
                        </div>
                    </div> */}
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Jenis kendaraan</label>
                        <div className="col-sm-7">
                            <select id="jenis_kendaraan" className="form-control" required>
                                <option disabled selected value="pilih">Pilih Kendaraan</option>
                                <option value="motor">motor</option>
                                <option value="mobil">mobil</option>
                                <option value="motor-mobil">motor-mobil</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Akses Data</label>
                        <div className="col-sm-7">
                            <select id="akses_data" className="form-control" required>
                                <option disabled selected value="pilih">Pilih Status</option>
                                <option value="yes">YES</option>
                                <option value="no">NO</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nama Range</label>
                        <div className="col-sm-7">
                            <input type="text" className="form-control" id="akses_range_price"/>
                        </div>
                    </div>
                    <div className="form-group row" style={{display:props.bonus}}>
                        <label for="inputEmail3" className="col-sm-5 col-form-label">Akses Data</label>
                        <div className="col-sm-7">
                            <select id="akses_range_type" className="form-control" required>
                                <option disabled selected value="pilih">Pilih Status</option>
                                <option value="range">range</option>
                                <option value="nonrange">nonrange</option>
                            </select>
                        </div>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="primary">
                    Batal
                </Button>
                <Button onClick={props.fun} color="primary">
                    {props.btn}
                </Button>
            </DialogActions>
            </Dialog>
        </div>
    )
}