/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React, {useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../API'
import DialogOnboard from './Utils/DialogOnboard'
import DialogHapusBatal from './Utils/DialogHapusBatal'
import { AlertList} from "react-bs-notifier";
import {
Portlet,
PortletBody,
PortletHeader,
PortletHeaderToolbar
} from "../../partials/content/Portlet";

export default function Onboard() {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [id, setId] = React.useState('');
    const [alerts, setAlerts] = React.useState([]);

    function handleClickOpen() {
        setId(null)
        //  $('#alasanaja').val('')
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    
    function handleClickOpen2() {
        setOpen2(true);
    }

    function handleClose2() {
        setOpen2(false);
    }

    function HapusOnboard() {
        
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi + "v1/Onboard/delete_onboard/" + id,
            "method": "GET",
            "headers": {
                "Authorization": "Baisc " + localStorage.getItem('jwt')
            }
        }

        $.ajax(settings).done(function (response) {
            handleClose2()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Onboard Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose2()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

     useEffect(() => {
         $(document).ready(function () {
             $('#version').DataTable({
                 "pagingType": "full_numbers",
                 "processing": true,
                 "serverSide": true,
                 "destroy": true,
                 "ajax": {
                     "url": API.Userapi + "v1/Onboard/list",
                     "type": 'get',
                     "beforeSend": function (xhr) {
                         xhr.setRequestHeader('Authorization',
                             "Basic " + localStorage.getItem('jwt'));
                     },
                     error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts)
                     }
                 },
                 "columns": [
                    //  {
                    //      "data": "mtob_image"
                    //  },
                     {
                        sortable: true, 
                        "render": function ( data, type, full, meta ) {
                            // if ( full.mmle_logo == null){
                        // return "<img class='fancybox' data-lightbox='galery' src='"+full.mtob_image+"' style='height:70px;width:70px;border:1px solid #c5c8f0;border-radius:10px;'>";
                        // }else{
                            return  "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+full.mtob_image+">"+
                            "<img src="+full.mtob_image+" style='height:70px;width:70px;border:1px solid #c5c8f0border-radius:10px;'>"+
                            "</a>"
                        // }
                     }
                     },
                     {
                         "data": "mtob_title"
                     },
                     {
                         "data": "mtob_order"
                     },
                     {
                         sortable: true,
                         "render": function (data, type, full, meta) {
                             return "<div id='aksi'>" +
                                 "<button class='btn btn-outline-brand btn-icon btn-sm hapus tooltips mr-1 mb-1' data-toggle='tooltip' data-placement='top' title='Tooltip on top' id='hapus' data-id=" + full.mtob_id +
                                 " data-original-title='Hapus User'><i class='fa fa-trash'> </i></button>" +
                                 "</div>";
                         }
                     },
                 ]
             });

             $(document).on('click', '#hapus', function () {
                 handleClickOpen2()
                 setId($(this).attr('data-id'))
             })

             $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
         })
     })

    return(
        <>
            <AlertList  showIcon={false} alerts={alerts}/>
            <DialogOnboard 
                open={open} 
                close={handleClose} 
                title="Tambah Onboard"
                // btn={id!=null?"Edit":"Tambah"}
            />
            <DialogHapusBatal 
                open={open2} 
                close={handleClose2} 
                title="Hapus Onboard" 
                message="Anda Yakin Ingin Hapus Onboard Ini ?" 
                btn="Hapus"
                fun={HapusOnboard} 
                // type="delete" 
                // id={id}
            />
            <Portlet>
                <PortletHeader title="Data Onboard" toolbar={( <PortletHeaderToolbar>
                    <button className="btn btn-label-primary" onClick={handleClickOpen} id="addversion">
                        <i class="fas fa-plus"></i>Tambah Onboard
                    </button>
                    </PortletHeaderToolbar>
                    )}
                    >
                </PortletHeader>
                <PortletBody>
                    <table className="display table table-hover table-responsive-md" id="version" style={{width:'100%'}}>
                        <thead className="bg-primary" style={{color:'white'}}>
                            <tr>
                                <th scope="col">Gambar Onboard</th>
                                <th scope="col">Judul</th>
                                <th scope="col">Page</th>
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                    </table>
                </PortletBody>
            </Portlet>
        </>
    )
}