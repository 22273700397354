/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
// import Divider from '@material-ui/core/Divider';
import {Row, Col} from 'react-bootstrap';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
// import Utils from '../Utils/FunctionDetail'
import $ from 'jquery'
import "jquery-validation";
import Table from './TableLeasing'
import 'bootstrap-datepicker'
import { AlertList} from "react-bs-notifier";
import DialogHapusBatal from '../Utils/DialogHapusBatal'
import Droppoint from './Droppoint'
import Administrator from './Administrator'
import Pricing from './Pricing'
import UploaddataLeasing from '../UploadData/UploaddataLeasing'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import ExcelRangePricing from '../Settings/range_pricing.xlsx';
import UploaddataBpkb from '../UploadData/UploaddataBpkb'
    
    const AntTabs = withStyles({
        root: {
        // borderBottom: '1px solid #e8e8e8',
        },
        indicator: {
        // backgroundColor: '#1890ff',
        },

    })(Tabs);

    const AntTab = withStyles(theme => ({
        root: {
          textTransform: 'bold',
          minWidth: 80,
          // fontWeight: theme.typography.fontWeightRegular,
          marginRight: theme.spacing(4),
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          '&:hover': {
            color: '#ffffff',
            opacity: 1,
          },
          '&$selected': {
            color: '#ffffff',
            fontWeight: theme.typography.fontWeightMedium,
          },
          '&:focus': {
            color: '#f4a240',
          },
        },
        selected: {},
    }))(props => <Tab disableRipple {...props} />);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
          id: `scrollable-auto-tab-${index}`,
          'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const useStyles = makeStyles(theme => ({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        detail: {
            offset : theme.mixins.toolbar,
            marginTop : '5px'
        } 
    }));

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

export default function EditLeasing(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [alerts, setAlerts] = React.useState([]);
  const [alerts1, setAlerts1] = React.useState([]);
  const [open8, setOpen8] = React.useState(false);
  const [load, setLoad] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  React.useEffect(() => {
    $(document).ready(function () {
        if (props.id != null) {      
            Table.Config(props.id)
            if(load==true){
                Table.DetailLeasing(props.id,setAlerts1)
                Table.Config(props.id,setAlerts1)
            }
        }

        $('#vldfrom,#vldto').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: "yyyy-mm-dd",
        });
        $("form").validate();

        $('#config_leasing').off('click').on('click', function() {
            $('#config_leasing').on('click', '#changeSetting', function (){
                var settings = {
                "async": true,
                "crossDomain": true,
                "url": API.Userapi+"v1/Leasing/putConfig/"+props.id+"/"+$(this).attr('data-id'),
                "method": "GET",
                "headers": {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                    }
                }
        
                $.ajax(settings).done(function (response) {
                console.log(response);
                Table.Config(props.id)
                }).fail(function (response) {
                console.log(response);
                API.Handle403(response,setAlerts)
                });
            });
        })
        })
    })

    const SubmitEdit = () => {
            var mmle_id = $("#mmle_id").val();
            var mmle_name = $("#mmle_name").val();
            var mmle_status = $('input[name=mmle_status]:checked').val();
            console.log(mmle_status);
            if( mmle_status == "Aktif" ) {
                mmle_status = 0;
            }else if(mmle_status == " Tidak Aktif") {
                mmle_status = 1;
            }
            var mmle_pic_name1 = $("#mmle_pic_name1").val();
            var mmle_pic_phone1 = $("#mmle_pic_phone1").val();
            var mmle_pic_email1 = $("#mmle_pic_email1").val();
            var mmle_ho_address = $("#mmle_ho_address").val();
            var mmle_pic_name2 = $("#mmle_pic_name2").val();
            var mmle_pic_phone2 = $("#mmle_pic_phone2").val();
            var mmle_pic_email2 = $("#mmle_pic_email2").val();
            var mmle_valid_from = $("#vldfrom").val();
            var mmle_valid_to = $("#vldto").val();
            var app_type = $("#app_type").val();

            var data = {
                'mmle_id' : mmle_id,
                'mmle_status' : mmle_status,
                'mmle_name': mmle_name,
                'mmle_pic_name1': mmle_pic_name1,
                'mmle_pic_phone1': mmle_pic_phone1,
                'mmle_pic_email1': mmle_pic_email1,
                'mmle_ho_address': mmle_ho_address,
                'mmle_pic_name2': mmle_pic_name2,
                'mmle_pic_phone2': mmle_pic_phone2,
                'mmle_pic_email2': mmle_pic_email2,
                'mmle_valid_from': mmle_valid_from,
                'mmle_valid_to': mmle_valid_to,
                'app_type': app_type,
            };
            console.log(data)
            // eslint-disable-next-line no-unused-vars
            var settings = {
                "async": true,
                "crossDomain": true,
                "url": API.Userapi + "v1/leasing/update_leasing/"+props.id,
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Baisc " + localStorage.getItem('jwt')
                },
                "processData": false,
                "data": JSON.stringify(data)
            }

            $.ajax(settings).done(function (response) {
                console.log(response)
                setAlerts([{
                    id: 1,
                    type: "success",
                    headline: "Berhasil",
                    message: "Leasing Telah Diubah"
                }])
                setTimeout(() => {
                    setAlerts([])
                }, 3000)
                props.close()
                setLoad(true)
                return 0;
            }).fail(function (response) {
                console.log(response)
                setAlerts([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code " + response.status + "]"
                }])
                setTimeout(() => {
                    setAlerts([])
                }, 3000)
                API.Handle403(response,setAlerts)
                return 0
            })
    }

    function UploadLogoLeasing(e) {
        console.log(e.target.files[0])
        var form = new FormData();
        form.append("foto", e.target.files[0]);

        var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Userapi+"v1/Leasing/updateLogo/logo_leasing/"+props.id,
        "method": "POST",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form
        }
        $.ajax(settings).done(function (response) {
                setAlerts1([{
                    id: 1,
                    type: "success",
                    headline: "Berhasil",
                    message: "Logo Leasing Telah Diupload"
                }])
                setTimeout(() => {
                    setAlerts1([])
                    // Table.DetailLeasing(props.id)
                }, 3000)
            }).fail(function (response) {
                console.log(response)
                setAlerts1([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code " + response.status + "]"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
                API.Handle403(response,setAlerts)
            })
    }

    function handleClose8() {
        setOpen8(false);
    }

  return (
    <div>
        <DialogHapusBatal 
            open={open8} 
            close={handleClose8} 
            title="Edit Leasing" 
            message="Anda Yakin Ingin Edit Leasing Ini ?" 
            btn="Edit" 
            fun={SubmitEdit}
        />
        <AlertList showIcon={false} alerts={alerts} />
        <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AlertList showIcon={false} alerts={alerts1} />
            <AppBar className={classes.appBar}>
                <Toolbar>
                <AntTabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable"
                    scrollButtons="auto" aria-label="scrollable auto tabs example" className={classes.title}>
                    <AntTab label="Edit Leasing" {...a11yProps(0)} />
                    <AntTab label="Konfigurasi" {...a11yProps(1)} style={{display:'none'}} />
                    {/* <AntTab label="Setting Konfigurasi" {...a11yProps(1)} /> */}
                </AntTabs>
                    <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <SwipeableViews axis={theme.direction==='rtl' ? 'x-reverse' : 'x' } index={value} onChangeIndex={handleChangeIndex}>

            <TabPanel value={value} index={0} className={classes.detail}>
                <Portlet>
                    <PortletHeader title="Data Leasing" />
                    <PortletBody>
                        <Row>
                            <Col md={6}>
                            <input hidden type="text" id="mmle_id" value={props.id} />
                            <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Type App<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <select id="app_type" className="form-control" disabled>
                                        <option selected disabled value="0">Pilih Type App</option>
                                        <option value="motor">Motor</option>
                                        <option value="mobil">Mobil</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Leasing<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" id="mmle_name" />
                                </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Alamat Leasing<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <textarea type="text" rows="3" className="form-control" id="mmle_ho_address" />
                                    </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Status</label>
                                <div className="col-sm-8 pt-1">
                                <div class="form-check form-check-inline mt-1">
                                        <input class="form-check-input" type="radio" name="mmle_status" id="mmle_status" value="0"/>
                                        <label class="form-check-label" for="exampleRadios1">
                                            Tidak Aktif
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline mt-1">
                                        <input class="form-check-input" type="radio" name="mmle_status" id="mmle_status" value="1"/>
                                        <label class="form-check-label" for="exampleRadios1">
                                            Aktif
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Valid<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <div className="input-daterange input-group date" id="kt_datepicker_5">
                                        <input type="text" className="form-control" name="start" id="vldfrom" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">To</span>
                                        </div>
                                        <input type="text" className="form-control" name="end" id="vldto" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row" id="div_foto_user_add" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Logo Leasing<span style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8 custom-file">
                                    <input id="protleas" type="file" onChange={UploadLogoLeasing} class="form-control" name="mmle_ho_address"/>
                                    <a id='lihatleas' href='#' target='_blank' type='button' class='btn btn-brand btn-elevate btn-elevate-air mb-1 mr-1'><i class='fa fa-image'></i> Lihat Logo</a>
                                    <button type="button" name="foto" id="deleteleas" class="btn btn-warning btn-elevate btn-elevate-air mb-1 mr-1"><i class="fas fa-eraser"></i> Hapus</button>
                                    <button type="button" name="foto" id="cancelleas" class="btn btn-brand btn-elevate btn-elevate-air mb-1 mr-1 mt-2"><i class="fas fa-times"></i> Batal</button>
                                </div>
                            </div>
                            </Col>
                            <Col md={6}>
                            <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama PIC 1<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" id="mmle_pic_name1" />
                                </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">No Handphone PIC 1<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" id="mmle_pic_phone1" />
                                </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Email PIC 1<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" id="mmle_pic_email1" />
                                </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Nama PIC 2<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" id="mmle_pic_name2" />
                                </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">No Handphone PIC 2<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" id="mmle_pic_phone2" />
                                </div>
                            </div>
                            <div className="form-group row" style={{marginTop:'-15px'}}>
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Email PIC 2<span
                                        style={{color:'#F44336'}}>*</span></label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" id="mmle_pic_email2" />
                                </div>
                            </div>
                            </Col>
                        </Row>                    
                    </PortletBody>
                </Portlet>
                <Row>
                    <Col md={6} style={{background:'#f2f3f8'}}>
                        <Droppoint id={props.id}/>
                    </Col>
                    <Col md={6} style={{background:'#f2f3f8'}}>
                    <Portlet>
                        <PortletHeader title="Konfigurasi" />
                        <PortletBody>
                          <table className="table table-hover table-responsive-md" id="config_leasing" style={{width:'100%',overflow:'auto'}}>
                                  <thead className="bg-primary" style={{color:'white'}}>
                                      <tr>
                                        <th class="all"> Nama </th>
                                        <th class="all"> Value </th>
                                        <th class="all"> Aksi </th>
                                      </tr>
                                  </thead>
                          </table>
                        </PortletBody>
                    </Portlet>
                    </Col>
                </Row>
                <Administrator id={props.id}/>
                <Pricing id={props.id}/>
                <UploaddataLeasing id={props.id}/>
                <UploaddataBpkb id={props.id}/>
                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                <button type="button" class="btn btn-brand btn-elevate pull-right" onClick={SubmitEdit} ><i class="fas fa-save"></i>Simpan</button>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.detail}>
                {/** */}
            </TabPanel>
            </SwipeableViews>
        </Dialog>
    </div>
  );
}
