/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
// import Utils from './FunctionDetail'
import $ from 'jquery'

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const formatter = new Intl.NumberFormat('de-DE');

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset : theme.mixins.toolbar,
    marginTop : '70px'
  } 
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailLaporanPembatalanInforman(props) {
  const classes = useStyles();

  React.useEffect(() => {
    if (props.id != null) { 

      if (props.step == 1) {
        $('#data_mitra').hide()
        $('#foto_validasi').hide()
        $('#tgl_jam_matched').hide()
      }else if (props.step == 2){
        $('#data_mitra').hide()
        $('#foto_validasi').show()
        $('#tgl_jam_matched').hide()
      }

      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/laporan_penemuan/detail_pembatalan_informan/"+props.id,
        "method": "GET",
        "headers": {
          "Authorization": "Baisc " + localStorage.getItem('jwt')
        }
      }

         
      $.ajax(settings).done(function (response) {
        console.log(response);
        if (response.data != null) {
          $('#tpif_vplate').val(response.data.tpif_vplate)
          $('#tpif_vin').val(response.data.tpif_vin)
          $('#tpif_ein').val(response.data.tpif_ein)
          // $('#tpif_vtype').val(response.data.tpif_vtype)
          $('#tpif_vdoc_name').val(response.data.tpif_vdoc_name)
          $('#tpif_contract').val(response.data.tpif_contract)
          $('#tpif_branch').val(response.data.tpif_branch)
          $('#tpif_customer_name').val(response.data.tpif_customer_name)
          $('#tpif_address').val(response.data.tpif_address)
          $('#tpif_ic_number').val(response.data.tpif_ic_number)
          $('#tpif_kk_number').val(response.data.tpif_kk_number)
          $('#tpif_debt_date').val(response.data.tpif_debt_date)
          $('#tpif_installment').val(response.data.tpif_installment)
          $('#tpif_invoice_qty').val(response.data.tpif_invoice_qty)
          $('#tpif_duration').val(response.data.tpif_duration)
          $('#tpif_monthly_charge').val(formatter.format(response.data.tpif_monthly_charge))
          $('#tpif_total_unpaid').val(formatter.format(response.data.tpif_total_unpaid))
          $('#mper_name_informan').val(response.data.mper_name_informan)
          $('#mper_phone_informan').val(response.data.mper_phone_informan)
          $('#tpif_lat-long_informan').val(response.data.tpif_lat_informan+" , "+response.data.tpif_lng_informan)
          $('#tpif_address_informan').val(response.data.tpif_address_informan)
          $('#tpif_fee').val(formatter.format(response.data.tpif_fee))
          $('#tervalidasi').val(response.data.tanggal_validasi+" , "+response.data.jam_validasi)
          $('#tpif_cancelled').val(response.data.tpif_cancelled_date+" , "+response.data.tpif_cancelled_time)
          $('#tpif_cancelled_reason').val(response.data.tpif_cancelled_reason)
          $('#tpif_reason_text_detail').val(response.data.tpif_reason_text)
          $('#tpif_cancelled_by_name').val(response.data.tpif_cancelled_by_name)
          var tpif_step,tpif_step2,tpnm_msg
          tpif_step = response.data.tpif_tpnm_cancelled_step
          tpif_step2 = props.by
          if(tpif_step == null){
            if(tpif_step2 == 1){
              tpnm_msg = "Validasi Informan"
            }else if(tpif_step2 == 2){
              tpnm_msg = "Matchmaking"
            }
          }else{
            if(tpif_step == 1){
              tpnm_msg = "Validasi Mitra";
            }else if(tpif_step == -1){
              tpnm_msg = "Matched (Mitra Menuju Informan)";
            }else if(tpif_step == -2){
              tpnm_msg = "Matched (Mitra Ditemukan)";
            }else if(tpif_step == 2){
              tpnm_msg = "Menunggu Surat Tugas";
            }else if(tpif_step == 3){
              tpnm_msg = "Surat Tugas Diterima";
            }else if(tpif_step == 4){
              tpnm_msg = "Pengantaran";
            }else if(tpif_step == 5){
              tpnm_msg = "Dalam Pengantaran";
            }
          }
          $('#tpif_tpnm_cancelled_step').val(tpnm_msg)
        }
      })
    }

  })

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>                       
            <Typography variant="h6" className={classes.title}>
                Detail Pembatalan Informan
            </Typography>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TabPanel value={0} index={0} className={classes.detail}>
          <Portlet>
            <PortletHeader title="Data Kendaraan"/>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Polisi</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id='tpif_vplate' disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Rangka</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_vin" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Mesin</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_ein" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Type Motor/Mobil</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_vtype" disabled/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nama STNK/BPKB</label>
                    <div class="col-sm-8">
                      <input type="email" class="form-control" id="tpif_vdoc_name" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Kontrak</label>
                    <div class="col-sm-8">
                      <input type="email" class="form-control" id="tpif_contract" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Asal Cabang</label>
                    <div class="col-sm-8">
                      <input type="email" class="form-control" id="tpif_branch" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
          <Portlet>
            <PortletHeader title="Profile Debitur"></PortletHeader>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Debitur</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_customer_name" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Konsumen</label>
                    <div class="col-sm-7">
                      {/* <input type="email" class="form-control" id="tpif_address" disabled/> */}
                      <textarea className="form-control" id="tpif_address" rows="3" disabled></textarea>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor KTP</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_ic_number" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor KK</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_kk_number" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal Mulai Tunggak</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_debt_date" disabled/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Angsuran Ke</label>
                    <div class="col-sm-8">
                      <input type="email" class="form-control" id="tpif_installment" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Jumlah Kwitansi</label>
                    <div class="col-sm-8">
                      <input type="email" class="form-control" id="tpif_invoice_qty" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Tenor</label>
                    <div class="col-sm-8">
                      <input type="email" class="form-control" id="tpif_duration" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Angsuran Perbulan</label>
                    <div class="col-sm-8">
                      <input type="email" class="form-control" id="tpif_monthly_charge" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Total Tunggakkan</label>
                    <div class="col-sm-8">
                      <input type="email" class="form-control" id="tpif_total_unpaid" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
          <Portlet>
            <PortletHeader title="Data Informan"></PortletHeader>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Informan</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="mper_name_informan" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Informan</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="mper_phone_informan" disabled/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Lat-Long Informan</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_lat-long_informan" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Posisi Informan</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_address_informan" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
          <Portlet>
            <PortletHeader title="Order Detail"></PortletHeader>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Fee Informan</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_fee" disabled/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>                  
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Validasi</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tervalidasi" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
          <Portlet>
            <PortletHeader title="Pembatalan"></PortletHeader>
            <PortletBody>
              <Row>
                <Col md={6}>
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Pembatalan</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_cancelled" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Dibatalkan Oleh</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_cancelled_by_name" disabled/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>                  
                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Step Pembatalan</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_tpnm_cancelled_step" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Alasan Pembatalan</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_cancelled_reason" disabled/>
                    </div>
                  </div>
                  <div class="form-group row" style={{marginTop:'-15px'}}>
                    <label for="inputEmail3" class="col-sm-4 col-form-label">Alasan Pembatalan Admin</label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" id="tpif_reason_text_detail" disabled/>
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
          <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
        </TabPanel>
      </Dialog>
    </div>
  );
}
