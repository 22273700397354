/* eslint-disable eqeqeq */
/* eslint-disable no-multi-str */
import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { AlertList } from "react-bs-notifier";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import jsZip from "jszip";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import API from "../API";
import EditLeasing from "./Leasing/EditLeasing";
import DialogHapusBatal from "./Utils/DialogHapusBatal";
import Utils from "./Utils/FunctionDetail";
import NoImage from "./Leasing/noimage.jpg";

window.JSZip = jsZip;

export default function Leasing() {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [idhapus, setIdhapus] = React.useState(null);
  const [alerts, setAlerts] = React.useState([]);

  useEffect(() => {
    $(document).ready(function() {
      $("#leasing").DataTable({
        pagingType: "full_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        ajax: {
          url: API.Userapi + "v1/leasing/index",
          beforeSend: function(xhr) {
            xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
          },
          error: function(xhr, error, thrown, settings) {
            API.Handle403(xhr, setAlerts);
          },
        },
        fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          if (aData.app_type == "mobil") {
            $("td:eq(1)", nRow).css("background-color", "#AFEEEE");
          } else if (aData.app_type == "motor") {
            $("td:eq(1)", nRow).css("background-color", "#F4A460");
          }
          if (aData.mmle_status == "Non Aktif") {
            $("td:eq(10)", nRow).css("background-color", "#EF5350");
          }
        },
        columns: [
          { data: "mmle_id" },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              return "<div>" + Utils.StatusAppType(full.app_type) + "</div>";
            },
          },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              if (full.mmle_logo == null) {
                return "<img src='" + NoImage + "' style='height:70px;weight:50px;'>";
              } else {
                return (
                  "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href=" +
                  full.mmle_logo +
                  ">" +
                  "<img src=" +
                  full.mmle_logo +
                  " style='height:70px;width:70px;border:1px solid #c5c8f0;border-radius:10px;'>" +
                  "</a>"
                );
              }
            },
          },
          { data: "mmle_name" },
          { data: "mmle_ho_address" },
          { data: "mmle_pic_name1" },
          { data: "mmle_pic_phone1" },
          { data: "mmle_pic_name2" },
          { data: "mmle_pic_phone2" },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              return full.mmle_valid_from + " - " + full.mmle_valid_to;
            },
          },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              return " <div >" + full.mmle_status + "</div>";
            },
          },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              // return "<div id='aksi'> <button class='btn btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail' data-id="+full.mmle_id+"> <i class='fa fa-eye'></i></button></div>\
              // <div id='aksi'> <button class='btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='hapus' data-id="+full.mmle_id+"> <i class='fa fa-trash'></i> </button> </div>";
              return `
                              <button id="leasing1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="leasing1">
                                  <button class='dropdown-item' id='detail' data-id="${full.mmle_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Edit Leasing</button>
                                  <button class='dropdown-item' id='hapus' data-id="${full.mmle_id}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-trash'></i>Hapus Leasing</button>
                              </div>`;
            },
          },
        ],
      });
      $(".dataTables_filter,.dataTables_paginate").addClass("pull-right");
      $("#leasing")
        .off("click")
        .on("click", function() {
          $("#leasing").on("click", "#detail", function() {
            handleClickOpen();
            setId($(this).attr("data-id"));
          });

          $("#leasing").on("click", "#hapus", function() {
            handleClickOpen1();
            setIdhapus($(this).attr("data-id"));
          });
        });
    });
  }, [setAlerts]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen1() {
    setOpen1(true);
  }

  function handleClose1() {
    setOpen1(false);
  }

  function HapusLeasing() {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/leasing/delete_leasing/" + idhapus,
      method: "GET",
      headers: {
        Authorization: "Baisc " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        handleClose1();
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Leasing Telah Dihapus",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        handleClose1();
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  }
  return (
    <>
      <AlertList showIcon={false} alerts={alerts} />
      <EditLeasing open={open} close={handleClose} id={id} />
      <DialogHapusBatal
        open={open1}
        close={handleClose1}
        title="Hapus Leasing"
        message="Anda Yakin Ingin Hapus Leasing Ini ?"
        btn="Hapus"
        fun={HapusLeasing}
        // type="hapusleasing"
        // id={idhapus}
      />
      <Portlet>
        <PortletHeader
          title="Data Leasing"
          toolbar={
            <PortletHeaderToolbar>
              <Link to="/upload-data-leasing" type="button" className="btn btn-label-primary">
                <i class="fas fa-upload"></i>Upload Data Leasing
              </Link>
              &nbsp;
              <Link to="/tambahleasing" type="button" className="btn btn-label-primary">
                <i class="fas fa-plus"></i>Tambah Leasing
              </Link>
            </PortletHeaderToolbar>
          }
        ></PortletHeader>
        <PortletBody>
          <div className="" style={{ width: "100%", overflow: "auto" }}>
            <table
              className="table table-hover table-responsive-md"
              id="leasing"
              style={{ width: "100%", overflow: "auto" }}
            >
              <thead className="bg-primary" style={{ color: "white" }}>
                <tr>
                  <th scope="col">ID Leasing</th>
                  <th scope="col">Tipe</th>
                  <th scope="col">Logo</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Alamat</th>
                  <th scope="col">Nama PIC 1</th>
                  <th scope="col">No HP PIC 1</th>
                  <th scope="col">Nama PIC 2</th>
                  <th scope="col">No HP PIC 2</th>
                  <th scope="col">Tanggal Valid</th>
                  <th scope="col">Status</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
            </table>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#F4A460" }}
            ></button>
            <span style={{ color: "black" }}> Motor </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#AFEEEE" }}
            ></button>
            <span style={{ color: "black" }}> Mobil </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#EF5350" }}
            ></button>
            <span style={{ color: "black" }}> Non Aktif</span>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}
