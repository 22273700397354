/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
  } from "../../partials/content/Portlet";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Check from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PaymentIcon from '@material-ui/icons/Payment';
import moment from 'moment' 
import API from '../API'
import DetailVoucherSiapDibayar from './Utils/DetailVoucherSiapDibayar'
import DetailPdfInvoice from './Utils/DetailPdfInvoice'
import DialogBayarBonus from './Utils/DialogBayarBonus'
import DialogEditBank from './Utils/DialogEditBank'
import Utils from './Utils/FunctionDetail'
import { AlertList} from "react-bs-notifier";
import { useHistory  } from "react-router-dom";
import DialogHapusBatal from './Utils/DialogHapusBatal';
import { getFeeMitra } from '../../functions/invoice';
import DialogIsiBank from './Utils/DialogIsiBank';

const formatter = new Intl.NumberFormat('de-DE');

const useStyles = makeStyles(theme => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
    },
    dense: {
      marginTop: 19,
    },
}));

const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 35,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,#183c7a 0%,#a1783f 50%,#f4a240 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,#153264 0%,#153264 50%,#153264 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 70,
      height: 70,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, #153264 0%, #153264 50%, #153264 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, #183c7a 0%, #183c7a 50%, #183c7a 100%)',
    },
  });
  
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    const icons = {
      1: <AssignmentIcon fontSize="large"/>,
      2: <AssignmentTurnedInIcon fontSize="large"/>,
      3: <PaymentIcon fontSize="large" color="secondary"/>,
      4: <DoneAllIcon fontSize="large"/>,
    };
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? icons[String(props.icon)] : icons[String(props.icon)]}
        {/* {completed ? <Check className={classes.completed} fontSize="large"/> : icons[String(props.icon)]} */}
        
      </div>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

function getSteps() {
    return ['Menunggu Persetujuan', 'Voucher Diapprove', 'Menunggu Pembayaran', 'Voucher Sudah Lunas'];
  }

export default function VoucherSiapDabayar() {
    const classes = useStyles();
    const history = useHistory(); 

    // eslint-disable-next-line no-unused-vars
    const [alerts,setAlerts] = React.useState([])
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [activeStep, setActiveStep] = React.useState(2);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [tvocid, setTvocId] = React.useState(null);
    const [tvoc, setTvoc] = React.useState(null);
    const [tvoc_account_name, setTvocaccname] = React.useState(null);
    const [tvoctype, setTvoctype] = React.useState(null);
    const [tvocbankname, setTvocbankname] = React.useState(null);
    const [tvocnumber, setTvocnumber] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [amount, setAmount] = React.useState(null);
    const steps = getSteps();
    const [id2 , setId2] = React.useState(null);
    const [open3, setOpen3] = React.useState(false);
    const [id3 , setId3] = React.useState(null);
    const [open4, setOpen4] = React.useState(false);
    //isi bank ekspedisi
    const [open5, setOpen5] = React.useState(false);
    const [id5 , setId5] = React.useState(null);

    function handleDateChange(date) {
    setSelectedDate(date);
    }

    useEffect(() => {
      $(document).ready(function () {
        $(function () {
          $('#vldfrom,#vldto').datepicker({
           todayHighlight: true,
           autoclose: true,
           format: "yyyy-mm-dd",
         })
        });

        $('#vldfrom').val(moment().format('YYYY-MM-DD'))
        $('#vldto').val(moment().format('YYYY-MM-DD'))
          
          $(document).on('click','#details',function () {
            handleClickOpen()
            setId($(this).attr('data-id'))
            setTvoc($(this).attr('data-tvoc'))
            setTvocaccname($(this).attr('data-tvoc-account-name'))
            setTvoctype($(this).attr('data-tvoc-type'))
            setTvocbankname($(this).attr('data-tvoc-bank-name'))
            setTvocnumber($(this).attr('data-tvoc-account-number'))
            setType($(this).attr('data-tvoctype'))
            setAmount($(this).attr('data-fee'))
          })
          $(document).on('click','#bonus',function () {
            handleClickOpen1()
            var total = $(this).attr('data-amount')
            setTvocId($(this).attr('data-tvoc'))
            $('#tvoc_total_fee_1').val(formatter.format(total))
            $('#tvoc_total_transfer_1').val(formatter.format(total))
            $('#tvoc_account_name_2').val($(this).attr('data-tvoc-account-name'))
            $('#tvoc_bank_name_2').val($(this).attr('data-tvoc-bank-name'))
            $('#tvoc_account_number_2').val($(this).attr('data-tvoc-account-number'))
            $('#check_lvoc_fee_antar_bank_1').on('change',function () {
              if ($(this).is(':checked')) {
                // console.log('test')
                $('#tvoc_total_transfer_1').val(formatter.format(parseInt(total)+6500))
              }else {
                // console.log('no test')
              $('#tvoc_total_transfer_1').val(formatter.format(total))
              }
            })
          })
          $(document).on('click','#print',function () {
            handleClickOpen2()
            setId($(this).attr('data-id'))
            setTvoc($(this).attr('data-tvoc'))
            setType($(this).attr('data-tvoctype'))
          })
          $(document).on('click','#editBank',function (){
            handleClickOpen3()
            setId2($(this).attr('data-id-bank'))
          })
          $(document).on('click','#backStep',function (){
            handleClickOpen4()
            setId3($(this).attr('data-id-step'))
          })
          $(document).on('click','#isiBank',function (){
            handleClickOpen5()
            setId5($(this).attr('data-id-bank-eks'))

            if ($(this).attr('data-ekspedisi-name') != "null") {
              $('#tvoc_ekspedisi_name').val($(this).attr('data-ekspedisi-name'))
            }else{
              $('#tvoc_ekspedisi_name').val(' ')
            }

            if ($(this).attr('data-bank-name-ekspedisi') != "null") {
              $('#tvoc_bank_ekspedisi_name').val($(this).attr('data-bank-name-ekspedisi'))
            }else{
              $('#tvoc_bank_ekspedisi_name').val(' ')
            }

            if ($(this).attr('data-bank-account-number-ekspedisi') != "null") {
              $('#tvoc_bank_ekspedisi_account_number').val($(this).attr('data-bank-account-number-ekspedisi'))
            }else{
              $('#tvoc_bank_ekspedisi_account_number').val(' ')
            }

            if ($(this).attr('data-bank-account-number-ekspedisi') != "null") {
              $('#tvoc_bank_ekspedisi_account_name').val($(this).attr('data-bank-account-name-ekspedisi'))
            }else{
              $('#tvoc_bank_ekspedisi_account_name').val(' ')
            }

          })
      })
    },[setAlerts])

   function handleClickOpen() {
     setOpen(true);
     $('#idVerifikasi').prop('disabled',false)
   }

   function handleClose() {
     setOpen(false);
   }

   function handleClickOpen1() {
     setOpen1(true);
     setTvocId(null)
   }

   function handleClose1() {
     setOpen1(false);
     setTvocId(null)
   }

   function handleClickOpen2() {
    setOpen2(true);
  }

  function handleClose2() {
    setOpen2(false);
  }

  function handleClickOpen3(){
    setOpen3(true);
  }
  function handleClose3() {
    setOpen3(false);
  }
  function handleClickOpen4(){
    setOpen4(true);
  }
  function handleClose4() {
    setOpen4(false);
  }

  function handleClickOpen5(){
    setOpen5(true);
  }
  function handleClose5() {
    setOpen5(false);
  }

    function FilterSiapDibayar() {
      if( $('#vldfrom').val() == 0 || $('#vldto').val() == 0){
        return 0 ;
      }
      var from = moment($('#vldfrom').val()).format('MM-DD-YYYY')
      var to = moment($('#vldto').val()).format('MM-DD-YYYY')
      console.log(from)
      TableSiapBayar(from,to)
    }

    const TableSiapBayar = (from,to) => {
      var url
      if( from != 'Invalid date' && to != 'Invalid date' ){
        url = API.Financeapi +"/v1/Pembayaran_voucher/index?from="+from+"&to="+to;
      }else{
        url = API.Financeapi +"/v1/Pembayaran_voucher/index";
      }
      $('#vouchersiapDibayar').DataTable( {
        "pagingType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "destroy":true,
        "ajax": {
            "url": url,
            "type": 'get',
            "beforeSend": function (xhr) {
                xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown , settings) {
            API.Handle403(xhr,setAlerts)
          }
        },
        "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
          if ( aData.tvoc_status == "Voucher sudah diisi"  ){
            $('td:eq(14)', nRow).css('background-color', '#81D4FA');
          }else if ( aData.tvoc_status == "Siap Dibayar"  ){
            $('td:eq(14)', nRow).css('background-color', '#FFE800');
          }else if ( aData.tvoc_status == "Ditolak"  ){
            $('td:eq(14)', nRow).css('background-color', '#FD333F');
          }else if ( aData.tvoc_status == "Telah Dibayar"  ){
            $('td:eq(14)', nRow).css('background-color', '#6CFF00');
          }
          if ( aData.tvoc_type == "Referall"  ){
            $('td:eq(1)', nRow).css('background-color', '#9999ff');
            $('td:eq(13)', nRow).css('background-color', '#9999ff');
          }
          if ( aData.app_type == "mobil"  ){
            $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
          }else if( aData.app_type == "motor"  ){
            $('td:eq(0)', nRow).css('background-color', '#F4A460');
          }else if(aData.app_type == 'bonus'){
            // $('td:eq(7)', nRow).html('-');
            // $('td:eq(8)', nRow).html('-');
            // $('td:eq(9)', nRow).html('-');
            $('td:eq(0)', nRow).css('background-color', '#ffb6b9');
          }
        },
        "columns": [
            {
              sortable : true,
              "render" : function (data,type,full,meta){
                return "<div>"+ Utils.StatusAppType(full.app_type)+"</div>"
              }
            },
            {
              sortable: true,
              "render": function (data, type, full, meta) {
                var type_user
                if (full.tvoc_informan_type == 'mitra'&& full.tpif_id != null) {
                  type_user = "Mitra - Informan"
                }else{
                  type_user = full.tvoc_informan_type
                }
                return " <div > " + type_user + "</div>";
              }
            },
            { "data": "lvoc_created_date" },
            { "data": "lvoc_created_time" },
            { "data": "tvoc_account_name" },
            { "data": "tvoc_bank_name" },

            { "data": "tvoc_account_number" },
            {
              sortable: true,
              "render": function ( data, type, full, meta ) {
                //get fee 
                var fee_mitra = full.tvoc_ammount_revisi != null ? full.tvoc_ammount_revisi : full.tvoc_ammount
                var fee_stnk = full.tvoc_fee_stnk_revisi != null ? full.tvoc_fee_stnk_revisi : full.tvoc_fee_stnk
                var fee = getFeeMitra(full.tvoc_status_stnk,fee_mitra, fee_stnk)

                console.log("fee: " + fee);
                console.log("status_stnk: " + full.tvoc_status_stnk);
                return " <div >" +formatter.format(fee)+ "</div>";
              }
            },
            {
              sortable: true,
              "render": function( data, type, full, meta ) {

                var biaya_kirim = full.tvoc_biaya_kirim_real!= null ? full.tvoc_biaya_kirim_real: 0
                if(full.tvoc_informan_type == 'mitra') {
                  return " <div >" +formatter.format(biaya_kirim)+ "</div>"
                }else{
                  return " <div > - </div>"
                }
              }
            },
            {
              sortable: true,
              "render": function( data, type, full, meta ) {
                var fee_mitra = full.tvoc_ammount_revisi != null ? full.tvoc_ammount_revisi : full.tvoc_ammount
                var fee_stnk = full.tvoc_fee_stnk_revisi != null ? full.tvoc_fee_stnk_revisi : full.tvoc_fee_stnk
                var fee = getFeeMitra(full.tvoc_status_stnk,fee_mitra, fee_stnk)

                var biaya_kirim = full.tvoc_biaya_kirim_real!= null ? parseInt(full.tvoc_biaya_kirim_real): 0
                if(full.tvoc_informan_type == 'mitra') {
                  return " <div >" +formatter.format(biaya_kirim+fee)+ "</div>"
                }else{
                  return " <div >" +formatter.format(fee)+ "</div>"
                }
              }
            },
            { "data": "tvoc_vehicle_number" },
            { "data": "mmle_name" },

            {
                sortable: true,
                "render": function ( data, type, full, meta ) {
                    return " <div > "+full.tpnm_vbrand+" "+full.tpnm_type+" "+full.tpnm_vyear+" "+full.tpnm_vcolor+"</div>";
                }
            },
            {
              sortable: true,
              "render": function ( data, type, full, meta ) {
                return " <div >"+full.tvoc_type+"</div>";
              }
            },
            {
                sortable: true,
                "render": function ( data, type, full, meta ) {
                    return " <div >"+full.tvoc_status+"</div>";
                    // return " <div >"+getStatusVoucher(full.tvoc_status)+"</div>";
                }
            },
            {
                sortable: true,
                "render": function ( data, type, full, meta ) {
                  console.log(full.tvoc_informan_type)
                  if (full.app_type == null || full.app_type == 'bonus') {
                    return "<button class='details btn btn-outline-brand btn-icon btn-sm' id='bonus' data-id=" + full.tpnm_id + " data-amount=" + full.tvoc_ammount + " data-tvoc=" + full.tvoc_id +
                    " data-tvoc-account-name='" + full.tvoc_account_name + "'  data-tvoc-type=" + full.tvoc_type + " data-tvoc-bank-name='" + full.tvoc_bank_name + "' data-tvoc-account-number='" + full.tvoc_account_number +
                    "' data-original-title='Detail Data Penemuan'> <i class='fas fa-file-invoice-dollar'></i> </button>";
                  }else{
                    // return "<button class='details btn btn-outline-brand btn-icon btn-sm' id='details' data-id=" + full.tpnm_id + " data-tvoc=" + full.tvoc_id + " data-tvoctype=" + full.tvoc_informan_type + " data-fee=" + full.tvoc_ammount +
                    // " data-tvoc-account-name='" + full.tvoc_account_name + "'  data-tvoc-type=" + full.tvoc_type + " data-tvoc-bank-name='" + full.tvoc_bank_name + "' data-tvoc-account-number='" + full.tvoc_account_number +
                    // "' data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                    if(full.tvoc_informan_type == 'mitra') {
                      return `
                      <button id="ready1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="ready1">
                          <button class='dropdown-item' id='isiBank' data-id-bank-eks="${full.tvoc_id}" data-bank-name-ekspedisi="${full.tvoc_bank_ekspedisi_name}" data-bank-account-name-ekspedisi="${full.tvoc_bank_ekspedisi_account_name}" data-bank-account-number-ekspedisi="${full.tvoc_bank_ekspedisi_account_number}" data-ekspedisi-name="${full.tvoc_ekspedisi_name}" title='Reset Password'><i class='fas fa-money-bill'></i>Isi Bank Ekspedisi</button>
                          <button class='dropdown-item' id='details' data-id="${full.tpnm_id}" data-tvoc="${full.tvoc_id}" data-tvoctype="${full.tvoc_informan_type}" data-fee="${full.tvoc_ammount}" data-tvoc-account-name="${full.tvoc_account_name}"  data-tvoc-type="${full.tvoc_type}" data-tvoc-bank-name="${full.tvoc_bank_name}" data-tvoc-account-number="${full.tvoc_account_number}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail</button>
                          <button class='dropdown-item' id='print' data-id="${full.tpnm_id}" data-tvoc="${full.tvoc_id}" data-tvoctype="${full.tvoc_informan_type}" data-fee="${full.tvoc_ammount}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-print'></i>Print</button>
                          <button class='dropdown-item' id='editBank' data-id-bank="${full.tvoc_id}" title='Reset Password'><i class='fas fa-money-bill'></i>Edit Bank</button>
                          <button class='dropdown-item' id='backStep' data-id-step="${full.tvoc_id}" title='Reset Password'><i class='fas fa-step-backward'></i>Mundur Step</button>
                      </div>`;
                    }else{
                      return `
                      <button id="ready1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="ready1">
                          <button class='dropdown-item' id='details' data-id="${full.tpnm_id}" data-tvoc="${full.tvoc_id}" data-tvoctype="${full.tvoc_informan_type}" data-fee="${full.tvoc_ammount}" data-tvoc-account-name="${full.tvoc_account_name}"  data-tvoc-type="${full.tvoc_type}" data-tvoc-bank-name="${full.tvoc_bank_name}" data-tvoc-account-number="${full.tvoc_account_number}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-eye'></i>Detail</button>
                          <button class='dropdown-item' id='print' data-id="${full.tpnm_id}" data-tvoc="${full.tvoc_id}" data-tvoctype="${full.tvoc_informan_type}" data-fee="${full.tvoc_ammount}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-print'></i>Print</button>
                          <button class='dropdown-item' id='editBank' data-id-bank="${full.tvoc_id}" title='Reset Password'><i class='fas fa-money-bill'></i>Edit Bank</button>
                          <button class='dropdown-item' id='backStep' data-id-step="${full.tvoc_id}" title='Reset Password'><i class='fas fa-step-backward'></i>Mundur Step</button>
                      </div>`;
                    }
                    
                  }
                  // edit bank
                  // <button class='dropdown-item' id='editBank' data-id-bank="${full.tvoc_id}" title='Reset Password'><i class='fas fa-money-bill'></i>Edit Bank</button>
                }
            },
        ]
      })

      $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
    }
    
    function BayarVoucherInforman() {
      handleClose1()
      var tbnk_id = $('#tbnk_id_1').val();
      var drop_down_rekening = $('#lvoc_admin_bank_1').val();
      var lvoc_bank = $('#lvoc_bank_1').val();
      var tvoc_total_transfer_hidden = $('#tvoc_total_transfer_1').val();
      var lvoc_fee_antar_bank = $('#lvoc_fee_antar_bank_1').val();
      var lvoc_note = $('#lvoc_note_1').val();
        if ($('#check_lvoc_fee_antar_bank_1').is(':checked')) {
          // console.log('test')
          lvoc_fee_antar_bank = $('#lvoc_fee_antar_bank_1').val();
        }else {
          // console.log('no test')
          lvoc_fee_antar_bank = $('#lvoc_fee_antar_bank_0').val();
        }
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Financeapi+"v1/VoucherTranskasi/bayarVouhcer/"+tvocid,
        "method": "POST",
        "headers": {
          "Content-Type": "application/json",
          "Authorization": "Baisc "+localStorage.getItem('jwt'),
        },
        "processData": false,
        "data": "{\n\t\"tbnk_id\": \""+tbnk_id+"\",\n\t\"tvoc_fee_extra1\": \""+lvoc_fee_antar_bank+
                "\",\n\t\"lvoc_admin_bank\": \""+lvoc_bank+"\",\n\t\"lvoc_admin_tranfer\": \""+tvoc_total_transfer_hidden+
                "\",\n\t\"lvoc_note\": \""+lvoc_note+"\"\n}"
      }

      $.ajax(settings).done(function (response) {
        setAlerts([{
          id: 1,
          type: "success",
          headline: "Berhasil",
          message: "Voucher Telah Dibayar"
        }])
        setTimeout(()=>{
            setAlerts([])
            history.push("/voucherpaid");
        },1000)
      }).fail(function (response) {
        if (response.status == 200) {
          setAlerts([{
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Voucher Telah Dibayar"
          }])
          setTimeout(()=>{
              setAlerts([])
              history.push("/voucherpaid");
          },1000)
        }else {
          console.log(response);
          setAlerts([{
              id: 1,
              type: "danger",
              headline: "Gagal",
              message: "Terjadi Kendala [Error Code "+response.status+"]"
          }])
          setTimeout(()=>{
              setAlerts([])
          },3000)
        }
      })
    }

    //fun isi bank ekspedisi
    const IsiDataBank = () => {
      var from = moment($('#vldfrom').val()).format('MM-DD-YYYY')
      var to = moment($('#vldto').val()).format('MM-DD-YYYY')

      var ekspedisi = {
        'tvoc_ekspedisi_name': $('#tvoc_ekspedisi_name').val(),
        'tvoc_bank_ekspedisi_name': $("#tvoc_bank_ekspedisi_name").val(),
        'tvoc_bank_ekspedisi_account_number': $("#tvoc_bank_ekspedisi_account_number").val(),
        'tvoc_bank_ekspedisi_account_name' : $("#tvoc_bank_ekspedisi_account_name").val()
      }
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Financeapi+"v1/Pembayaran_voucher/isiDataBank/"+id5,
        "method": "POST",
        "headers": {
        "Authorization": "Baisc "+localStorage.getItem('jwt')
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": JSON.stringify(ekspedisi)
      }

      $.ajax(settings).done(function (response) {
          console.log(response)
          
          setAlerts([{
              id: 1,
              type: "success",
              headline: "Berhasil",
              message: "Data bank telah disimpan"
          }])
          setTimeout(() => {
              setAlerts([])
          }, 3000)
          handleClose5()
          TableSiapBayar(from,to)
      }).fail(function (response) {
          console.log(response)
          setAlerts([{
              id: 1,
              type: "danger",
              headline: "Gagal",
              message: "Terjadi Kendala [Error Code "+response.status+"]"
          }])
          setTimeout(() => {
              setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
      })
    }
    function UpdateBank() {
      var from = moment($('#vldfrom').val()).format('MM-DD-YYYY')
      var to = moment($('#vldto').val()).format('MM-DD-YYYY')

      var data = {
        'tvoc_bank_name': $("#tvoc_bank_name").val(),
        'tvoc_account_number': $("#tvoc_account_number").val(),
        'tvoc_account_name': $("#tvoc_account_name").val(),
        'tbnk_name': $("#tvoc_bank_name").val(),
        'tbnk_number': $("#tvoc_account_number").val(),
        'tbnk_receiver_name': $("#tvoc_account_name").val(),
      }
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Financeapi+"v1/Pembayaran_voucher/ubahDataBank/"+id2,
        "method": "POST",
        "headers": {
        "Authorization": "Baisc "+localStorage.getItem('jwt')
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": JSON.stringify(data)
      }

      $.ajax(settings).done(function (response) {
          console.log(response)
          
          setAlerts([{
              id: 1,
              type: "success",
              headline: "Berhasil",
              message: "Data bank telah diubah"
          }])
          setTimeout(() => {
              setAlerts([])
          }, 3000)
          handleClose3()
          TableSiapBayar(from,to)
      }).fail(function (response) {
          console.log(response)
          setAlerts([{
              id: 1,
              type: "danger",
              headline: "Gagal",
              message: "Terjadi Kendala [Error Code "+response.status+"]"
          }])
          setTimeout(() => {
              setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
      })
     }

     function backStep() {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Financeapi+"v1/VoucherTranskasi/stepBackVoucher/"+id3,
        "method": "GET",
        "headers": {
        "Authorization": "Baisc "+localStorage.getItem('jwt')
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data"
      }

      $.ajax(settings).done(function (response) {
          console.log(response)
          // $('#vouchersiapDibayar').DataTable().ajax.reload();
          handleClose4()
          setAlerts([{
              id: 1,
              type: "success",
              headline: "Berhasil",
              message: "Voucher telah berhasil mundur"
          }])
          setTimeout(() => {
              setAlerts([])
              history.push("/voucherverifikasi");
          }, 3000)
      }).fail(function (response) {
          console.log(response)
          setAlerts([{
              id: 1,
              type: "danger",
              headline: "Gagal",
              message: "Terjadi Kendala [Error Code "+response.status+"]"
          }])
          setTimeout(() => {
              setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
      })
     }

    return(
        <>     
            <AlertList showIcon={false} alerts={alerts}/>
            <DetailPdfInvoice open={open2} close={handleClose2} id={id} mpertype={type}/>
            <DetailVoucherSiapDibayar
              open={open} 
              close={handleClose} 
              id={id} 
              step={6} 
              tvocname={tvoc_account_name} 
              tvocbankname={tvocbankname} 
              tvocnumber={tvocnumber}
              tvoctype={tvoctype}
              tvocid={tvoc}
              type={type} 
              fee={amount}
            />     
            <DialogBayarBonus 
                open={open1} 
                close={handleClose1} 
                title="Bayar Bonus" 
                submit={BayarVoucherInforman}
            />
           <DialogEditBank
                open={open3}
                close={handleClose3}
                title={"Edit Data Bank"}
                btn={"Edit"}
                fun={UpdateBank}
            />
            <DialogHapusBatal 
                open={open4} 
                close={handleClose4} 
                title="Mundur Step" 
                message="Anda yakin merubah voucher ke verifikasi ?" 
                btn="Ya"
                fun={backStep}
            />
            <DialogIsiBank
                open={open5}
                close={handleClose5}
                title={"Isi Data Bank Ekspedisi"}
                btn={"Simpan"}
                fun={IsiDataBank}
            />
            <Portlet>
                <PortletHeader
                    title="Step Voucher"
                >
                </PortletHeader>
                <PortletBody>
                    <Grid container spacing={3}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} style={{width:'100%'}}>
                            {steps.map(label => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </PortletBody>
            </Portlet>
            <Portlet>
                <Portlet>
                    <PortletHeader title="Data Voucher Siap Dibayar" />
                    <PortletBody>
                        <PortletBody>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                    <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                                            <input type="text" className="form-control" name="start" id="vldfrom" autocomplete="off"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">To</span>
                                            </div>
                                            <input type="text" className="form-control" name="end" id="vldto" autocomplete="off"/>
                                        </div>
                                      <button type="button" class="btn btn-brand btn-elevate btn-sm" onClick={FilterSiapDibayar} id="cari" style={{width:'100%'}}>Set Filter</button>
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                </Grid>
                            </Grid> 
                        </PortletBody>
                        <table className="table table-hover table-responsive-md" id="vouchersiapDibayar" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                  <th scope="col">Tipe</th>
                                  <th scope="col">Tipe User</th>
                                  <th scope="col">Tanggal</th>
                                  <th scope="col">Jam</th>
                                  <th scope="col">Nama</th>
                                  <th scope="col">Nama Bank</th>
                                  <th scope="col">Nomor Rekening</th>
                                  <th scope="col">Harga</th>
                                  <th scope="col">Biaya Pengiriman</th>
                                  <th scope="col">Total Dibayar</th>
                                  <th scope="col">Nopol</th>
                                  <th scope="col">Leasing</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Tipe Voucher</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                        </table>
                        <div>
                          <button type="button" class="btn btn-default" style={{background:"#F4A460"}}></button><span style={{color:"black"}}> : Motor  </span>
                          <button type="button" class="btn btn-default" style={{background:"#AFEEEE"}}></button><span style={{color:"black"}}> : Mobil  </span>
                          <button type="button" class="btn btn-default" style={{background:"#ffb6b9"}}></button><span style={{color:"black"}}> : Bonus  </span>
                          <button type="button" class="btn btn-default" style={{background:"#9999ff"}}></button><span style={{color:"black"}}> : Referall  </span>
                          <button type="button" class="btn btn-default" style={{background:"#FFE800"}}></button><span style={{color:"black"}}> : Voucher Siap Dibayar  </span>
                        </div>
                    </PortletBody>
                </Portlet>
            </Portlet>
        </>
    )
}