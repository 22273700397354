import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme  } from '@material-ui/core/styles';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import { AlertList} from "react-bs-notifier";
import Box from '@material-ui/core/Box';
import DialogHapusBatal from './DialogHapusBatal'
import API from '../../API'
import $ from 'jquery'
import bsCustomFileInput from 'bs-custom-file-input'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'fixed',
      },
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      detail: {
        offset : theme.mixins.toolbar,
        marginTop : '70px'
      } 
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function DetailVoucherRefund(props) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();
    const [alerts,setAlerts] = React.useState([])
    const formatter = new Intl.NumberFormat('de-DE');

    React.useEffect(() => {
       $(document).ready(function () {
         $('#voucherRefund1').DataTable({
           "pagingType": "full_numbers",
           "aaSorting": [],
           "processing": true,
           "serverSide": true,
           "destroy": true,
           "ajax": {
             "url": API.Financeapi + "v1/VoucherRefund/detailVoucherRefund/"+props.id,
             "type": 'get',
             "beforeSend": function (xhr) {
               xhr.setRequestHeader('Authorization',
                 "Basic " + localStorage.getItem('jwt'));
             },
             error: function (xhr, error, thrown, settings) {
                API.Handle403(xhr,setAlerts)
             }
           },
           "columns": [
            {
            "data": "tvoc_created_date"
            },
            {
            "data": "mper_name"
            },
             {
               "data": "tpnm_vplate"
             },
             {
               "data": "tpnm_type"
             },
             {
               "data": "tpnm_vcolor"
             },
             {
                "data": "tpnm_vyear"
            },
            {
                "data": "tpnm_vbrand"
            },
           ]
         });
         $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
       })
     })
    return(
        <>  
            <AlertList  showIcon={false} alerts={alerts}/>     
            <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>                       
                <Typography variant="h6" className={classes.title}>
                    Detail Voucher Refund
                </Typography>
                <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
                <CloseIcon />
                </IconButton>
            </Toolbar>
            </AppBar>   
            <Portlet>
                <PortletHeader title="Data Detail Voucher Refund"/>
                <PortletBody>
                    <table className="table table-hover table-responsive-md" id="voucherRefund1" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                <th scope="col">Tanggal Penemuan</th>
                                <th scope="col">Nama Mitra</th>
                                <th scope="col">Nomor Polisi</th>
                                <th scope="col">Tipe</th>
                                <th scope="col">Warna</th>
                                <th scope="col">Tahun</th>
                                <th scope="col">Merk</th>
                                </tr>
                            </thead>
                    </table>
                </PortletBody>
            </Portlet>
            </Dialog>
        </>
    )
}