/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import $ from 'jquery';
import 'jquery-validation'
// import $ from 'jquery'
// import API from '../../API'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

export default function DialogReason(props) {
    const classes = useStyles();

    React.useEffect(()=>{
      $(document).ready(function () {
        $("#dialogreason").validate();
      })
    })

    function Submit () {
      console.log($("#dialogreason").valid())
      if($("#dialogreason").valid() == true){
        props.submit()
      }
    }

  return (
    <div>
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.close}
        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" fullWidth={true}
        maxWidth="xs">
        <DialogActions>
          <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <div style={{width:'100%'}}></div>
          <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
                  <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <form id="dialogreason" onSubmit={(e)=>{e.preventDefault()}}>
          <div className={classes.root}>
            <div className="form-group row" style={{display:props.batal}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Role</label>
              <div className="col-sm-8">
                <select id="role_type" name="role_type" className="form-control" required>
                  <option disabled selected value="pilih">Pilih Role</option>
                  <option value="mitra">Mitra</option>
                  <option value="informan">Informan</option>
                </select>
              </div>
            </div>
            <div className="form-group row" style={{marginTop:'-15px',display:props.batal}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Tipe</label>
              <div className="col-sm-8">
                <select id="cancel_type" name="cancel_type" className="form-control" required>
                  <option disabled selected value="pilih">Pilih Tipe </option>
                  <option value="1" id="pembatalan_validasi">Pembatalan Validasi </option>
                  <option value="2" id="pembatalan_surat_tugas">Pembatalan Surat Tugas </option>
                  <option value="3" id="pembatalan_menunggu_mitra">Pembatalan Menunggu Surat Mitra </option>
                  <option value="4" id="pembatalan_sebelum_validasi">Pembatalan Sebelum Validasi </option>
                </select>
              </div>
            </div>
            <div className="form-group row" style={{marginTop:'-15px',display:props.batal}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Alasan</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="alasanaja" name="alasanaja" autoComplete="off" required/>
              </div>
            </div>
            <div className="form-group row" style={{display:props.nego}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Nama<span
                  style={{color:'#F44336'}}>*</span></label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="ngrs_name" name="ngrs_name" required/>
              </div>
            </div>
            <div className="form-group row" style={{marginTop:'-15px',display:props.nego}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Deskripsi<span
                  style={{color:'#F44336'}}>*</span></label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="ngrs_desc" required/>
              </div>
            </div>
          </div>
              <button type="submit" className="btn btn-primary btn-elevate pull-right" onClick={Submit}><i className="fas fa-save"></i>Simpan</button>
              <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Batal</button>
          </form>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={props.close} color="primary">
            Batal
          </Button>
          <Button color="primary" onClick={props.submit}>
            Simpan
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );

}

