/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import $ from "jquery";
import "jquery-validation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "80%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
}));

export default function DialogEditInvoice(props) {
  const classes = useStyles();

  React.useEffect(() => {
    $(document).ready(function() {
      $("#edt_invc_total").mask("#.##0", { reverse: true });
      $("#edit_invoice").validate();
      $(function() {
        $(".datepicker").datepicker({
          todayHighlight: true,
          autoclose: true,
          format: "yyyy-mm-dd",
        });
      });
    });
  });

  function Submit() {
    if ($("#edit_invoice").valid() == true) {
      props.submit();
    }
  }
  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogActions>
          <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <div style={{ width: "100%" }}></div>
          <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <form
            id="edit_invoice"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={classes.root}>
              <div className="form-group row" style={{ display: props.nego }}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  Nama Leasing
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="edt_invc_mmle_name"
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="form-group row" style={{ display: props.nego }}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  Nama Debitur
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="edt_invc_tpnm_customer_name"
                    name="edt_invc_tpnm_customer_name"
                  />
                </div>
              </div>
              <div className="form-group row" style={{ display: props.nego }}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  No. Kontrak
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="edt_invc_tpnm_contract"
                    name="edt_invc_tpnm_contract"
                  />
                </div>
              </div>
              <div className="form-group row" style={{ display: props.nego }}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  Cabang Penerima
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="edt_invc_tpnm_delivery_dpname"
                    name="edt_invc_tpnm_delivery_dpname"
                  />
                </div>
              </div>
              <div className="form-group row" style={{ display: props.batal }}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  Status STNK
                </label>
                <div className="col-sm-8">
                  <select
                    id="edt_invc_status_stnk"
                    name="edt_invc_status_stnk"
                    className="form-control"
                    required
                  >
                    <option disabled selected value="pilih">
                      Pilih Status
                    </option>
                    <option value="1">Ada</option>
                    <option value="0">Tidak Ada</option>
                  </select>
                </div>
              </div>
              <div className="form-group row" style={{ display: props.nego }}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  Total Tagihan{" "}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="edt_invc_total"
                    name="edt_invc_total"
                  />
                </div>
              </div>
              <div className="form-group row" style={{ marginTop: "-15px", display: props.batal }}>
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  Keterangan Edit Invoice
                </label>
                <div className="col-sm-8">
                  <textarea
                    rows="2"
                    className="form-control"
                    id="edt_invc_note_msg"
                    name="edit_invc_note_msg"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  Pic Tagih
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="edt_pic_tagih"
                    name="edt_pic_tagih"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="inputEmail3" className="col-sm-4 col-form-label">
                  Tanggal Siap Tagih
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control datepicker"
                    id="edt_tanggal_siap_tagih"
                    name="edt_tanggal_siap_tagih"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-elevate pull-right"
              onClick={Submit}
            >
              <i className="fas fa-save"></i>Simpan
            </button>
            <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}>
              <i className="fas fa-arrow-left"></i>Batal
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
