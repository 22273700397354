/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import $ from 'jquery';
import 'jquery-validation';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

export default function DialogInvoice(props) {
    const classes = useStyles();

    React.useEffect(()=>{
      $(document).ready(function () {
        //$("#input_payment").validate();
        $( '#invc_base_price,#fee_stnk,#invc_tagihan,#invc_bayar' ).mask('#.##0', {reverse: true});
        $(function() {
          $('#invc_date_paid,#invc_send_date').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: "yyyy-mm-dd",
          })
        });
      })
    })

    function Submit () {
      if($('[type="submit"]').valid() == true){
        $('[type="submit"]').prop('disabled',true)
        props.submit()
      }
    }

  return (
    <div>
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.close}
        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" fullWidth={true}
        maxWidth="sm">
        <DialogActions>
          <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <div style={{width:'100%'}}></div>
          <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
                  <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <form id="input_payment" onSubmit={(e)=>{e.preventDefault()}}>
          <div className={classes.root}>
            <div className="form-group row" style={{display:props.nego}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Debitur</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="invc_customer_name" disabled/>
              </div>
            </div>
            <div className="form-group row" style={{display:props.nego}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Base Price</label>
              <div className="col-sm-3">
                <input type="text" className="form-control" id="invc_base_price" disabled/>
              </div>
              <label className="col-form-label">&nbsp;+&nbsp;</label>
              <label for="inputEmail3" className="col-form-label">Fee Stnk</label>
              <div className="col-sm-3">
                <input type="text" className="form-control" id="fee_stnk" name="fee_stnk" disabled/>
              </div>
            </div>
            <div className="form-group row" style={{display:props.nego}}>
              <label for="inputEmail3" id="tagihan" className="col-sm-4 col-form-label">Biaya Kirim</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="biaya_kirim_leasing" disabled/>
              </div>
            </div>
            <div className="form-group row" style={{display:props.nego}}>
              <label for="inputEmail3" id="tagihan" className="col-sm-4 col-form-label">Tagihan</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="invc_tagihan" placeholder="jika tidak ada cicilan maka kosong" disabled/>
              </div>
            </div>
            <div className="form-group row" style={{display:props.nego}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Jumlah Bayar</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="invc_bayar"  name="invc_bayar" placeholder="Masukan nominal angka pembayaran"/>
              </div>
            </div>
            <div className="form-group row" style={{marginTop:'-15px',display:props.batal}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Keterangan Bayar Invoice</label>
              <div className="col-sm-8">
                <textarea rows="2" className="form-control" id="invc_keterangan" name="invc_keterangan" autoComplete="off" />
              </div>
            </div>
            <div className="form-group row" style={{display:props.nego}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Bayar Invoice</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="invc_send_date" name="invc_send_date"/>
              </div>
            </div>      
            <div className="form-group row" style={{display:props.nego}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Lunas</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="invc_date_paid" name="invc_date_paid" placeholder="Kosongkan jika belum lunas"/>
              </div>
            </div>
              {/* <div className="form-group row" style={{display:props.batal}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Status Invoice</label>
              <div className="col-sm-8">
                <select id="invc_status" name="invc_status" className="form-control" required>
                  <option disabled selected value="pilih">Pilih Status</option>
                  <option value="Menunggu Pambayaran">Menunggu Pembayaran</option>
                  <option value="Lunas">Lunas</option>
                </select>
              </div>
            </div> */}
          </div>
              <button type="submit" className="btn btn-primary btn-elevate pull-right" onClick={Submit}><i className="fas fa-save"></i>Simpan</button>
              <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Batal</button>
          </form>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );

}

