/* eslint-disable no-restricted-imports */
import React,
 { 
  useEffect
  //  useMemo
  } 
 from "react";
// import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  // PortletHeader,
  // PortletHeaderToolbar
} from "../../partials/content/Portlet";
// import { metronic } from "../../../_metronic";
// import QuickStatsChart from "../../widgets/QuickStatsChart";
import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
import Grid from '@material-ui/core/Grid';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import moment from 'moment'
import API from '../API'
import { AlertList} from "react-bs-notifier";
// import OrdersWidget from "../../widgets/OrdersWidget";
// import SalesBarChart from "../../widgets/SalesBarChart";
// import DownloadFiles from "../../widgets/DownloadFiles";
// import NewUsers from "../../widgets/NewUsers";
// import LatestUpdates from "../../widgets/LatestUpdates";
// import BestSellers from "../../widgets/BestSellers";
// import RecentActivities from "../../widgets/RecentActivities";
// import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";

export default function Dashboard() {
  useEffect(() => {
    $(document).ready(function () {
      $(function () {
        $('#tglto,#tglfrom').datepicker({
         todayHighlight: true,
         autoclose: true,
         format: "yyyy-mm-dd",
         maxDate: 'today',
         endate: 'today',
        })
      });

      $('#tglto').val(moment().format('YYYY-MM-DD'))
      $('#tglfrom').val(moment().format('YYYY-MM-DD'))

      Request(`${moment().format('YYYY-MM-DD')}&to=${moment().format('YYYY-MM-DD')}`)

    })
  }, [mitra, informan, car, motorcycle, total, penemuan, pendaftaran, done])

  const [alerts, setAlerts] = React.useState([]);
  const [col, setCol] = React.useState(9);
  const [mitra, setMitra] = React.useState(0);
  const [informan, setInforman] = React.useState(0);
  const [car, setCar] = React.useState(0);
  const [motorcycle, setMotorcycle] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [penemuan, setPenemuan] = React.useState(0);
  const [pendaftaran, setPendaftaran] = React.useState(0);
  const [done, setDone] = React.useState(0);

  function Request(tanggal){
    var Setting = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Financeapi}/v1/Voucher_lunas/totalPenemuanMI?from=${tanggal}`,
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }

    $.ajax(Setting).done(function (response){
      console.log(response);
      setMitra(response.data.total_penemuan_mitra)
      setInforman(response.data.total_penemuan_informan)
      setDone(parseInt(response.data.total_penemuan_mitra) + parseInt(response.data.total_penemuan_informan))
    }).fail(function (response) {
      API.Handle403(response,setAlerts)
    })

    var url

    if (localStorage.getItem('role') == 3 || localStorage.getItem('role') == 4) {
      url = `${API.Uploadapi}/v1/Dashboard/totalDataLeasing?from=${tanggal}`
      setCol(12)
      $('#handleRole,#handleRegist').hide()
    } else {
      setCol(9)
      url = `${API.Uploadapi}/v1/Dashboard/dashboard?from=${tanggal}`
      $('#handleRole,#handleRegist').show()
    }

    var dashboard = {
      "async": true,
      "crossDomain": true,
      "url": url,
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }

    $.ajax(dashboard).done(function (response){
      var total_data
      console.log(response);
      setCar(response.data.total_car)
      setMotorcycle(response.data.total_motorcycle)
      if (localStorage.getItem('role') == 3 || localStorage.getItem('role') == 4) {
        total_data = parseInt(response.data.total_data)
      } else {
        total_data = parseInt(response.data.total_car) + parseInt(response.data.total_motorcycle)
      }
      setTotal(total_data)
    }).fail(function (response) {
      API.Handle403(response,setAlerts)
    })

    var penemuan = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Foundingapi}/v1/Penemuan/getTotalPenemuan?from=${tanggal}`,
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }
    
    $.ajax(penemuan).done(function (response){
      console.log(response);
      setPenemuan(response.data.total_penemuan)
    }).fail(function (response) {
      API.Handle403(response,setAlerts)
    })

    var pendaftaran = {
      "async": true,
      "crossDomain": true,
      "url": `${API.Userapi}/v1/user/laporanTotalPendaftaran?from=${tanggal}`,
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }
    
    $.ajax(pendaftaran).done(function (response){
      console.log(response);
      setPendaftaran(response.total.total_pendaftaran_mitra_count)
    }).fail(function (response) {
      API.Handle403(response,setAlerts)
    })
  }

  return (
    <>
      <AlertList  showIcon={false} alerts={alerts}/>
      <div className="row" style={{background:'transparent'}}>

        <div className="col-xl-12">
          <Portlet>
            <PortletBody>
              <Grid container spacing={2}>
                  {/* <Grid item xs>
                  </Grid> */}
                  <Grid item md={6}>
                      {/* <Grid container spacing={2}> */}
                          <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                              <input type="text" className="form-control" name="start" id="tglfrom" data-date-end-date="0d" autocomplete="off"/>
                              <div className="input-group-append">
                                  <span className="input-group-text">To</span>
                              </div>
                              <input type="text" className="form-control" name="end" id="tglto" data-date-end-date="0d" autocomplete="off"/>
                          </div>
                        <button type="button" class="btn btn-brand btn-elevate" onClick={()=>{Request(`${$('#tglfrom').val()}&to=${$('#tglto').val()}`)}} id="cari" style={{width:'100%'}}>Set Filter</button>
                      {/* </Grid> */}
                  </Grid>
                  <Grid item md={6}>
                    <Row className="mb-2">
                      <Col md={6}>
                        <button 
                          type="button" 
                          class="btn btn-secondary btn-elevate" 
                          onClick={()=>{
                            Request(`${moment().subtract(1, 'weeks').format('YYYY-MM-DD')}&to=${moment().format('YYYY-MM-DD')}`)
                            $('#tglfrom').val(moment().subtract(1, 'weeks').format('YYYY-MM-DD'))
                            $('#tglto').val(moment().format('YYYY-MM-DD'))
                          }} 
                          style={{width:'100%'}}
                        >
                          Seminggu Terakhir
                        </button>
                      </Col>
                      <Col md={6}>
                        <button 
                          type="button" 
                          class="btn btn-secondary btn-elevate" 
                          onClick={()=>{
                            Request(`${moment().subtract(6, 'months').format('YYYY-MM-DD')}&to=${moment().format('YYYY-MM-DD')}`)
                            $('#tglfrom').val(moment().subtract(6, 'months').format('YYYY-MM-DD'))
                            $('#tglto').val(moment().format('YYYY-MM-DD'))
                          }} 
                          style={{width:'100%'}}
                        >
                          6 Bulan Terakhir
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <button 
                          type="button" 
                          class="btn btn-secondary btn-elevate" 
                          onClick={()=>{
                            Request(`${moment().subtract(1, 'months').format('YYYY-MM-DD')}&to=${moment().format('YYYY-MM-DD')}`)
                            $('#tglfrom').val(moment().subtract(1, 'months').format('YYYY-MM-DD'))
                            $('#tglto').val(moment().format('YYYY-MM-DD'))
                          }} 
                          style={{width:'100%'}}
                        >
                          Sebulan Terakhir
                        </button>
                      </Col>
                      <Col md={6}>
                        <button 
                          type="button" 
                          class="btn btn-secondary btn-elevate" 
                          onClick={()=>{
                            Request(`${moment().subtract(1, 'years').format('YYYY-MM-DD')}&to=${moment().format('YYYY-MM-DD')}`)
                            $('#tglfrom').val(moment().subtract(1, 'years').format('YYYY-MM-DD'))
                            $('#tglto').val(moment().format('YYYY-MM-DD'))
                          }} 
                          style={{width:'100%'}}
                        >
                          Setahun Terakhir
                        </button>
                      </Col>
                    </Row>
                  </Grid>
              </Grid> 
            </PortletBody>
          </Portlet>
          <Row style={{background:'transparent'}}>
            <Col md={3} id="handleRole">
              <Portlet>
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Penemuan Berhasil Mitra</span>
                          <span className="kt-widget12__value">{mitra}</span>
                        </div>
                      </div>
                      {/* <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Jumlah Penemuan Informan</span>
                          <span className="kt-widget12__value">{informan}</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </PortletBody>
              </Portlet>
              <Portlet>
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      {/* <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Jumlah Penemuan Mitra</span>
                          <span className="kt-widget12__value">{mitra}</span>
                        </div>
                      </div> */}
                      <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Penemuan Berhasil Informan</span>
                          <span className="kt-widget12__value">{informan}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              </Portlet>
              <Portlet>
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      {/* <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Jumlah Penemuan Mitra</span>
                          <span className="kt-widget12__value">{mitra}</span>
                        </div>
                      </div> */}
                      <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Jumlah Data Motor</span>
                          <span className="kt-widget12__value">{motorcycle}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              </Portlet>
              <Portlet>
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      {/* <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Jumlah Penemuan Mitra</span>
                          <span className="kt-widget12__value">{mitra}</span>
                        </div>
                      </div> */}
                      <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Jumlah Data Mobil</span>
                          <span className="kt-widget12__value">{car}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletBody>
              </Portlet>
            </Col>
            <Col md={col}>
              <Portlet>
                <PortletBody>
                  <OrderStatisticsChart total={total} done={done} penemuan={penemuan} pendaftaran={pendaftaran}/>
                  {/* <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span className="kt-widget12__desc">Jumlah Penemuan Informan</span>
                          <span className="kt-widget12__value">{informan}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </PortletBody>
              </Portlet>
            </Col>
          </Row>
          {/* <Portlet>
            <PortletHeader
              title="Order Statistics"
              toolbar={
                <PortletHeaderToolbar>
                  <PortletHeaderDropdown />
                </PortletHeaderToolbar>
              }
            />

            <PortletBody>
              <OrderStatisticsChart />
            </PortletBody>
          </Portlet> */}
        </div>
      </div>

      {/* <Portlet>
        <PortletBody fit={true}>
          <div className="row row-no-padding row-col-separator-xl">
            <div className="col-xl-4">
              <OrdersWidget />
            </div>
            <div className="col-xl-4">
              <SalesBarChart
                title="Daily Sales"
                desc="Check out each column for more details"
              />
            </div>
            <div className="col-xl-4">
              <SalesBarChart
                title="Revenue Change"
                desc="Revenue change breakdown by cities"
              />
            </div>
          </div>
        </PortletBody>
      </Portlet> */}

      {/* <div className="row">
        <div className="col-xl-4">
          <DownloadFiles />
        </div>
        <div className="col-xl-4">
          <NewUsers />
        </div>
        <div className="col-xl-4">
          <LatestUpdates />
        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-xl-8"></div>
        <div className="col-xl-4">
          <AuthorsProfit />
        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-xl-8">
          <BestSellers />
        </div>
        <div className="col-xl-4">
          <RecentActivities />
        </div>
      </div> */}
    </>
  );
}
