/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../partials/content/Portlet";
import PropTypes from 'prop-types';
import { makeStyles, 
  // withStyles, 
  useTheme  } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import {Tabs as Tabs2, Tab as Tab2} from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import $ from 'jquery';
import 'datatables.net-bs4';
// import Slide from '@material-ui/core/Slide';
import SwipeableViews from 'react-swipeable-views';
import Table from './Penemuan/TablePenemuan'
import DetailLaporanPenemuan from './Utils/DetailLaporanPenemuan'
import DetailLaporanInforman from './Utils/DetailLaporanInforman'
import DetailLaporanPembatalanInforman from './Utils/DetailLaporanPembatalanInforman'
// import DetailInforman from './Utils/DetailInforman'
// import DetailNego from './Utils/DetailNego'
import {Badge} from 'react-bootstrap'
import API from '../API'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import { AlertList} from "react-bs-notifier";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  export default function JumlahPenemuan() {

    const [alerts, setAlerts] = React.useState([]);

    useEffect(() => {

        $(document).ready(function () {

          $(function() {
            $('#vldfrom,#vldto').datepicker({
              todayHighlight: true,
              autoclose: true,
              format: "yyyy-mm-dd",
            })
          });
          // console.log(moment($('#datepicker').datepicker('getDate')).format('YYYY-MM-DD'))
          $('#vldfrom').val(moment().format('YYYY-MM-DD'))
          $('#vldto').val(moment().format('YYYY-MM-DD'))
        })
    });

    function FilterLaporanPenemuan() {
      if( $('#vldfrom').val() == 0 || $('#vldto').val() == 0){
        return 0 ;
      }
      
      var from = moment($('#vldfrom').val()).format('MM-DD-YYYY')
      var to = moment($('#vldto').val()).format('MM-DD-YYYY')

      TableJumlahPenemuan(from,to)

      $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
    }

    const TableJumlahPenemuan = (from,to) => {
      $('#jumlahPenemuan').DataTable({
        "pagingType": "full_numbers",
        dom: "<'row'<'col-sm-12 col-md-5'l><'col-sm-12 col-md-7'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          { extend: 'excel', className: 'btn btn-primary mt-4', text:'<i class="fas fa-file-excel"></i>Export Excel', titleAttr: "Export Excel" }
        ],
         "lengthMenu": [
            [5, 10, 15, 20, -1],
            [5, 10, 15, 20, "All"]
        ],
        "processing": true,
        // "serverSide": true,
        "destroy": true,
        "ajax": {
          "url": API.Financeapi +`v1/Voucher/laporanPenemuanMitra?from=${from}&to=${to}&length=full`,
          "type": 'get',
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Basic " + localStorage.getItem('jwt'));
          },
          error: function (xhr, error, thrown, settings) {
            API.Handle403(xhr,setAlerts)
          }
        },
        "columns": [
          {
            "data" : "mper_name"
          },
          {
            "data" : "total_penemuan_mitra"
          },
        ]
      });
    }

    return (
    <>
      <AlertList showIcon={false} alerts={alerts}/>
      <Portlet>
            <PortletHeader
                title="Filter Tanggal"
            >
            </PortletHeader>
                <PortletBody>
                    <Grid container spacing={2}>
                        <Grid item xs>
                        </Grid>
                        <Grid item xs={6} className="text-center">
                            <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                                <input type="text" className="form-control" name="start" id="vldfrom" autocomplete="off"/>
                                <div className="input-group-append">
                                    <span className="input-group-text">To</span>
                                </div>
                                <input type="text" className="form-control" name="end" id="vldto" autocomplete="off"/>
                            </div>
                            <button type="button" class="btn btn-brand btn-elevate btn-sm" onClick={FilterLaporanPenemuan} id="cari" style={{width:'100%'}}>Set Filter</button>
                        </Grid>
                        <Grid item xs>
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet>
      <Portlet>
        <PortletBody>
          <div style={{overflow:'auto'}}>
            <table className="table table-hover table-responsive-md" id="jumlahPenemuan" style={{width:'100%'}}>
              <thead className="bg-primary" style={{color:'white',fontSize:'12px'}}>
                <tr>
                  <th scope="col">Nama Mitra</th>
                  <th scope="col">Jumlah Penemuan</th>
                </tr>
              </thead>
            </table>
          </div>
        </PortletBody>
      </Portlet>
    </>
    );
  }
