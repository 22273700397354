/* eslint-disable eqeqeq */
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { AlertList} from "react-bs-notifier";
import DialogHapusBatal from './Utils/DialogHapusBatal'
import { Link, useHistory  } from "react-router-dom";
// import { useForm } from 'react-hook-form'
import $ from 'jquery';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../partials/content/Portlet";
import API from '../API'
import 'jquery-validation'
// import 'jquery-form-validator'
import 'bootstrap-datepicker'
import 'select2'
import moment from 'moment'

export default function TambahDataNPL() {
    const [alerts, setAlerts] = React.useState([]);
    const [fktp, setFktp] = React.useState(null)
    const [fuser, setFuser] = React.useState(null)
    const [fsrtf, setFsrtf] = React.useState(null)
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    // const { register, handleSubmit, watch, errors } = useForm()

    React.useEffect(() => {
        $(document).ready(function () {
            $('#tdbd_debt_date').datepicker({
                todayHighlight: true,
                autoclose: true,
                format: "yyyy-mm-dd",
            });
            // $('#vldfrom').on('change',function () {
            //     console.log(moment($('#vldfrom').datepicker('getDate')).add(1, 'years').toDate())
            //     $('#vldto').datepicker('setDate', moment($('#vldfrom').datepicker('getDate')).add(1, 'years').toDate())
                
            // })
            $("#tambahuser").validate();
            $('#mmle_name').select2({
                minimumInputLength: 0,
                ajax: {
                    type: 'get',
                    url:  API.Userapi+"v1/leasing/index",
                    headers: {
                        "Authorization": "Baisc "+localStorage.getItem('jwt')
                    },
                    dataType: "json",
                    error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts);
                    },
                    data: function (params) {
                        return {
                            "select2" : params.term,
                            "length": 34,
                        };
                    },
                    processResults: function (data, params) {
                        console.log(data)
                        return {
                            results: $.map(data.data, function (item) {
                                return {
                                    text: item.mmle_name,
                                    id: item.mmle_id,
                                };
                            })
                        };
                    }
                }
            });
            $('#mper_ic_number').keypress(function(event) {
                return /\d/.test(String.fromCharCode(event.keyCode));
            }).bind('keyup paste', function(){
                this.value = this.value.replace(/[^0-9]/g, '');
            });
        })
    })

    function handleClickOpen() { 
        if ($('#tambahuser').valid() == false) {
            setAlerts([{
                id: 1,
                type: "info",
                headline: "Validate",
                message: "Lengkapi Data Yang Tersedia"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
        }else{
            setOpen(true);
        }
    }

    function handleClose() {
        setOpen(false);
    }

    const Submit = () => {
                handleClose()
                var form = {

                    "tdbd_vplate":$('#tdbd_vplate').val().toUpperCase(),
                    "tdbd_branch":$('#tdbd_branch').val().toUpperCase(),
                    "tdbd_vtype":$('#app_type').val(),
                    "tdbd_ptype":$('#tdbd_vtype').val().toUpperCase(),
                    "tdbd_vin":$('#tdbd_vin').val(),
                    "tdbd_ein":$('#tdbd_ein').val(),
                    "app_type":$('#app_type').val(),
                    "mmle_id":$('#mmle_name').val(),
                    "mmle_name":$('#mmle_name option:selected').text()
                    
                }

                if ($('#tdbd_cwo_wo').val() != "") {
                    form["tdbd_cwo_wo"]=$('#tdbd_cwo_wo').val()               
                }

                if ($('#tdbd_contract').val() != "") {
                    form["tdbd_contract"]=$('#tdbd_contract').val()               
                }

                if ($('#tdbd_customer_name').val() != "") {
                    form["tdbd_customer_name"]=$('#tdbd_customer_name').val()               
                }

                if ($('#tdbd_ic_number').val() != "") {
                    form["tdbd_ic_number"]=$('#tdbd_ic_number').val()               
                }

                if ($('#tdbd_kk_number').val() != "") {
                    form["tdbd_kk_number"]=$('#tdbd_kk_number').val()               
                }

                if ($('#tdbd_debt_date').val() != "") {
                    form["tdbd_debt_date"]=$('#tdbd_debt_date').val()               
                }

                if ($('#tdbd_installment').val() != "") {
                    form["tdbd_installment"]=$('#tdbd_installment').val()               
                }

                if ($('#tdbd_invoice_qty').val() != "") {
                    form["tdbd_invoice_qty"]=$('#tdbd_invoice_qty').val()               
                }

                if ($('#tdbd_duration').val() != "") {
                    form["tdbd_duration"]=$('#tdbd_duration').val()               
                }

                if ($('#tdbd_monthly_charge').val() != "") {
                    form["tdbd_monthly_charge"]=$('#tdbd_monthly_charge').val()               
                }

                if ($('#tdbd_total_unpaid').val() != "") {
                    form["tdbd_total_unpaid"]=$('#tdbd_total_unpaid').val()               
                }

                if ($('#tdbd_address').val() != "") {
                    form["tdbd_address"]=$('#tdbd_address').val()               
                }

                if ($('#tdbd_vbrand').val() != "") {
                    form["tdbd_vbrand"]=$('#tdbd_vbrand').val()               
                }

                if ($('#tdbd_vyear').val() != "") {
                    form["tdbd_vyear"]=$('#tdbd_vyear').val()               
                }

                if ($('#tdbd_vcolor').val() != "") {
                    form["tdbd_vcolor"]=$('#tdbd_vcolor').val()               
                }

                if ($('#tdbd_vdoc_name').val() != "") {
                    form["tdbd_vdoc_name"]=$('#tdbd_vdoc_name').val()               
                }


                var settings = {
                    "async": true,
                    "crossDomain": true,
                    "url": API.Uploadapi+"v1/UploadFile/tambahkanDataAdmin",
                    "method": "POST",
                    "headers": {
                      "Authorization": "Baisc "+localStorage.getItem('jwt')
                    },
                    "processData": false,
                    "contentType": false,
                    "mimeType": "multipart/form-data",
                    "data": JSON.stringify(form)
                }
                
                
                    $.ajax(settings).done(function (response) {
                        console.log(response)
                        $("#tambahuser input").val('')
                        setAlerts([{
                            id: 1,
                            type: "success",
                            headline: "Berhasil",
                            message: "Data Telah Ditambahkan"
                        }])
                        setTimeout(() => {
                            setAlerts([])
                            history.push("/kendaraan");
                        }, 2000)
                        return 0
                    }).fail(function (response) {
                        console.log(response)
                        API.Handle403(response,setAlerts)
                        setAlerts([{
                            id: 1,
                            type: "danger",
                            headline: "Gagal",
                            message: "Terjadi Kendala [Error Code "+response.status+"]"
                        }])
                        setTimeout(() => {
                            setAlerts([])
                        }, 3000)
                        return 0
                    })

    }

    return(
        <>
        <AlertList  showIcon={false} alerts={alerts}/>
        <DialogHapusBatal 
                open={open} 
                close={handleClose} 
                title="Tambah User" 
                message="Anda Yakin Ingin Menambah User ?" 
                btn="Tambahkan"
                fun={Submit} 
            />
        <Portlet>
            <PortletHeader title="Tambah Data NPL"/>
                    <PortletBody>
                        <form id="tambahuser" onSubmit={(e)=>{e.preventDefault()}}>
                            <Row>
                                <Col md={6}>
                                    <div className="form-group row">
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Leasing<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <select className="form-control" id="mmle_name" name="mmle_name" required>
                                                <option value="0" selected disabled> Pilih Leasing</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Asal Cabang<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <select className="form-control" id="mper_kota" name="mper_kota" required>
                                                <option value="0" selected disabled> Pilih Asal Cabang</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Asal Cabang<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : Bekasi" className="form-control" id="tdbd_branch" name="tdbd_branch" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">No Kontrak</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="1234xxx" className="form-control" id="tdbd_contract" name="tdbd_contract"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nama Debitur</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Nama Lengkap" className="form-control" id="tdbd_customer_name" name="tdbd_customer_name"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Alamat</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Alamat Lengkap" className="form-control" id="tdbd_address" name="tdbd_address"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">No KTP</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 3710298703432156" className="form-control" id="tdbd_ic_number" name="tdbd_ic_number"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">No KK</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 3710298703432156" className="form-control" id="tdbd_kk_number" name="tdbd_kk_number"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Tanggal Mulai Tunggak</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : YYYY-MM-DD" className="form-control" id="tdbd_debt_date" name="tdbd_debt_date"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Angsuran Ke</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 3" className="form-control" id="tdbd_installment" name="tdbd_installment"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Jumlah Kwitansi</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 3" className="form-control" id="tdbd_invoice_qty" name="tdbd_invoice_qty"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Tenor</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 3" className="form-control" id="tdbd_duration" name="tdbd_duration"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Angsuran Perbulan</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 50000" className="form-control" id="tdbd_monthly_charge" name="tdbd_monthly_charge"/>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group row">
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Total Tunggakan</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 200000" className="form-control" id="tdbd_total_unpaid" name="tdbd_total_unpaid"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor Polisi<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : B1234ABC" className="form-control" id="tdbd_vplate" name="tdbd_vplate" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Merk</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : Honda" className="form-control" id="tdbd_vbrand" name="tdbd_vbrand"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Jenis Kendaraan<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            {/* <input type="text" placeholder="Contoh : Karyawan Swasta" className="form-control" id="app_type" name="app_type" required/> */}
                                            <select className="form-control" id="app_type" name="app_type" required>
                                                <option value="" selected disabled> Pilih Jenis Kendaraan</option>
                                                <option value="motor"> Motor</option>
                                                <option value="mobil"> Mobil</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Tipe<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : Mio" className="form-control" id="tdbd_vtype" name="tdbd_vtype" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Tahun</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 2012" className="form-control" id="tdbd_vyear" name="tdbd_vyear"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Warna</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : Biru" className="form-control" id="tdbd_vcolor" name="tdbd_vcolor"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">No Rangka<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="2134565464xxx" className="form-control" id="tdbd_vin" name="tdbd_vin" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">No Mesin<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="2134565464xxx" className="form-control" id="tdbd_ein" name="tdbd_ein" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">STNK Atas Nama</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Nama" className="form-control" id="tdbd_vdoc_name" name="tdbd_vdoc_name"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Kategori</label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : CWO/WO/Standar" className="form-control" id="tdbd_cwo_wo" name="tdbd_cwo_wo"/>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                <Link to="/kendaraan" className="btn btn-warning btn-elevate">
                                    <i className="fas fa-arrow-left"></i>Kembali
                                </Link>
                                    {/* <button type="button" className="btn btn-danger btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Kembali</button> */}
                                    <button type="submit" className="btn btn-brand btn-elevate pull-right" onClick={handleClickOpen}><i className="fas fa-save"></i>Simpan</button>
                                </Col>
                            </Row>
                        </form>
                        {/* <button type="button" class="btn btn-brand btn-elevate" onClick={handleClickOpen} style={{width:'100%'}}>Simpan</button> */}
                    </PortletBody>
                </Portlet>
        </>
    )
}