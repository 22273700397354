/* eslint-disable eqeqeq */
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Check from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import clsx from 'clsx';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { Portlet, PortletBody, PortletHeader } from "../../partials/content/Portlet";
import API from '../API';
import DetailVoucherInforman from './Utils/DetailVoucherInforman';
import { AlertList} from "react-bs-notifier";
import DialogHapusBatal from './Utils/DialogHapusBatal';
import Utils from './Utils/FunctionDetail';

// const useStyles = makeStyles(theme => ({
//     textField: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//       width: '100%',
//     },
//     dense: {
//       marginTop: 19,
//     },
// }));

const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 35,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,#153264 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,#153264 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 70,
      height: 70,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, #153264 0%, #153264 50%, #153264 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, #183c7a 0%, #183c7a 50%, #183c7a 100%)',
    },
  });
  
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    const icons = {
      1: <AssignmentIcon fontSize="large" color="secondary"/>,
      2: <AssignmentTurnedInIcon fontSize="large"/>,
      3: <DoneAllIcon fontSize="large"/>,
    };
    return (
        <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
          })}
        >
          {completed ? <Check className={classes.completed} fontSize="large"/> : icons[String(props.icon)]}
          
        </div>
      );
    }
    
    ColorlibStepIcon.propTypes = {
      active: PropTypes.bool,
      completed: PropTypes.bool,
      icon: PropTypes.node,
    };  

    function getSteps() {
        return ['Menunggu Persetujuan', 'Voucher Diapprove', 'Voucher Sudah Lunas'];
      }

export default function VoucherInforman(){    
      
    // const classes = useStyles();
    const [alerts, setAlerts] = React.useState([]);
    const [activeStep, 
      // setActiveStep
    ] = React.useState(0);
    const steps = getSteps();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [tpif, setTpif] = React.useState(null);
    const [tvoc, setTvoc] = React.useState(null);
    const [tvoctype, setTvoctype] = React.useState(null);
    const [IdVerifikasi , setIdVerifikasi] = React.useState(null);

    function handleClickOpen() {
        setOpen(true);
    }
    function handleClickOpen1() {
        setOpen1(true);
    }
    function handleClose() {
        setOpen(false);
    }
    function handleClose1() {
        setOpen1(false);
    }
    useEffect(() => {
        $(document).ready(function () {
            $('#voucher-informan').DataTable({
              "pagingType": "full_numbers",
              "aaSorting": [],
              "processing": true,
              "serverSide": true,
              "destroy": true,
              "ajax": {
                "url": API.Financeapi + "v1/voucher/list_voucher_informan/index",
                "type": 'get',
                "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                    "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown, settings) {
                  // errorData(xhr);
                }
              },
              "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                console.log(aData.tvoc_type);
                if (aData.tvoc_status == "Waiting") {
                  $('td:eq(7)', nRow).css('background-color', '#FFE800');
                }
                // if (aData.tvoc_type == "Referall") {
                //     $('td:eq(8)', nRow).css('background-color', '#9999ff');
                //   }
                if (aData.app_type == "mobil") {
                  $('td:eq(0)', nRow).css('background-color', '#AFEEEE');
                } else if (aData.app_type == "motor") {
                  $('td:eq(0)', nRow).css('background-color', '#F4A460');
                }
              },
              "columns": [
                {
                  sortable : true,
                  "render" : function (data,type,full,meta){
                    return "<div>"+ Utils.StatusAppType(full.app_type)+"</div>"
                  }
                },
                {
                  "data": "mper_name"
                },
                {
                  "data": "mper_phone"
                },
                {
                  "data": "tpnm_delivery_dpname"
                },
                {
                  "data": "mmle_name"
                },
                // {
                //   sortable: true,
                //   "render": function (data, type, full, meta) {
                //     console.log(full)
                //     if (full.tvoc_status_stnk == 1) {
                //       // full.tvoc_ammount = formatter.format(parseInt(full.tvoc_fee_stnk) + parseInt(full.tvoc_ammount));
                //     } else {
                //       // full.tvoc_ammount = formatter.format(parseInt(full.tvoc_ammount));
                //     }
                //     console.log("| " + full.tvoc_ammount);
                //     return " <div >" + full.tvoc_ammount + "</div>";
                //   }
                // },
                // {
                //   "data": "tvoc_vehicle_number"
                // },
                {
                  "data": "tvoc_vehicle_number"
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    console.log(full)
                    return " <div > " + full.tpnm_type + " " + full.tpnm_vyear + " " + full.tpnm_vcolor + "</div>";
                  }
                },
                // {
                //   sortable: true,
                //   "render": function (data, type, full, meta) {
                //     return " <div >" + full.tvoc_type + "</div>";
                //   }
                // },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return " <div >" + full.tvoc_status + "</div>";
                  }
                },
                {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                    return "<button class='details btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='detail_informan' data-tpif=" + full.tpif_id + " data-type=" + full.tvoc_type + " data-id=" + full.tpnm_id + " data-tvoc=" + full.tvoc_id + 
                      " data-original-title='Detail Data Penemuan'> <i class='fa fa-eye'> </i> </button>";
                      // "<button class='verifikasi_informan btn btn-outline-brand btn-icon btn-sm mb-1 mr-1' id='verifikasi_informan' data-id="+full.tvoc_id+" " +
                      // "> <i class='fa fa-check'> </i> </button>";
                  }
                },
              ]
            });
            $(document).on('click','#verifikasi_informan', function (){
                handleClickOpen1()
                setIdVerifikasi($(this).attr('data-id'))
            })

            $(document).on('click', '#detail_informan', function () {
                handleClickOpen()
                setId($(this).attr('data-id'))
                setTpif($(this).attr('data-tpif'))
                setTvoc($(this).attr('data-tvoc'))
                setTvoctype($(this).attr('data-type'))
                console.log(id)
              })

            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')

            $(document).on('click','#detail_informan',function () {
              console.log('informan')
            })

          })
    })

    const Submit = () => {
        console.log(IdVerifikasi);
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Financeapi+"v1/voucher/verification_voucher_informan/"+IdVerifikasi,
            "method": "GET",
            "headers": {
                "Authorization": "Baisc "+localStorage.getItem('jwt')
            }
          }
          $.ajax(settings).done(function (response) {
            handleClose1()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Voucher Berhasil Diverifikasi"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose1()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        })
    }

    return (
        <>
        <AlertList  showIcon={false} alerts={alerts}/>
        <DialogHapusBatal 
                open={open1} 
                close={handleClose1} 
                title="Tambah User" 
                message="Anda Yakin Ingin Verfikasi Voucher Ini ?" 
                btn="Ya"
                fun={Submit} 
                // type="delete" 
                // id={id}
            />
        <DetailVoucherInforman open={open} close={handleClose} tpif={tpif} id={id} step={6} tvoctype={tvoctype} tvoc={tvoc}/>
        <Portlet>
            <PortletHeader
                title="Step Voucher"
            >
            </PortletHeader>
            <PortletBody>
                <Grid container spacing={3}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} style={{width:'100%'}}>
                        {steps.map(label => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </Grid>
            </PortletBody>
        </Portlet>
        <Portlet>
            <PortletHeader title="Data Voucher Verifikasi Informan"/>
            <PortletBody>
                <table className="table table-hover table-responsive-md" id="voucher-informan" style={{width:'100%'}}>
                    <thead className="bg-primary" style={{color:'white'}}>
                        <tr>
                            <th scope="col">Tipe</th>
                            <th scope="col">Nama Mitra</th>
                            <th scope="col">No HP Mitra</th>
                            <th scope="col">Drop Point</th>
                            {/* <th scope="col">Nama</th>
                            <th scope="col">Harga</th> */}
                            <th scope="col">Leasing</th>
                            <th scope="col">Nopol</th>
                            <th scope="col">Unit</th>
                            {/* <th scope="col">Tipe Voucher</th> */}
                            <th scope="col">Status</th>
                            <th scope="col">Aksi</th>
                        </tr>
                    </thead>
                </table>
                    <div>
                        <button type="button" class="btn btn-default" style={{background:'#F4A460',width:'1%'}}></button><span style={{color:'black'}}> : Motor   </span>
                        <button type="button" class="btn btn-default" style={{background:'#AFEEEE',width:'1%'}}></button><span style={{color:'black'}}> : Mobil   </span>
                        <button type="button" class="btn btn-default" style={{background:'#9999ff',width:'1%'}}></button><span style={{color:'black'}}> : Referall   </span>
                        <button type="button" class="btn btn-default" style={{background:'#FFE800',width:'1%'}}></button><span style={{color:'black'}}> : Waiting   </span>
                    </div>
            </PortletBody>
        </Portlet>
        </>
    )
}