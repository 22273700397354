import React from "react";
import $ from "jquery";
import "datatables.net-bs4";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { ProgressBar, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import API from "../../API";
import { AlertList } from "react-bs-notifier";
import bsCustomFileInput from "bs-custom-file-input";
import DialogHapusBatal from "../Utils/DialogHapusBatal";
import TemplateUploadDataBpkb from "../DownloadTemplate/template_upload_bpkb_leasing.csv";

export default function UploaddataBpkp(props) {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [alerts, setAlerts] = React.useState([]);
  const [uploadtype, setUploadtype] = React.useState(null);
  const [uploadname, setUploadname] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const history = useHistory();

  const fileUpload = (e) => {
    if (file == null) {
      $(".custom-file-label").text("Pilih File");
    }
    setFile(e.target.files[0]);
  };

  React.useEffect(() => {
    $(document).ready(function() {
      bsCustomFileInput.init();
    });
  });

  function Status(e) {
    console.log(e.target.value);
    let value = e.target.value;
    if (value == 1) {
      setUploadtype("Tambahkan");
    } else if (value == 2) {
      setUploadtype("Gantikan");
    } else if (value == 3) {
      setUploadtype("Hapus");
    } else {
      setUploadtype(null);
      console.log("Tidak Ada");
    }
  }

  function Upload() {
    handleClose();

    let new_file = $("#upload_file_bpkb").prop("files")[0];
    let status = $("#upload_type_bpkb").val();
    let new_url;
    console.log(new_file);
    if (status == 1) {
      //TAMBAH DATA
      new_url = API.Uploadapi + "v1/UploadBpkb/tambahData";
    } else if (status == 2) {
      //GANTIKAN DATA
      new_url = API.Uploadapi + "v1/UploadBpkb/gantikanData";
    } else if (status == 3) {
      //HAPUS DATA
      console.log("Hapus Data");
      new_url = API.Uploadapi + "v1/UploadBpkb/hapusData";
    } else {
      console.log("Tidak Ada");
    }

    setUploadname(new_file.name);
    $("#upload_bpkb").hide("slow");
    $("#uploadingbpkb").show("slow");

    let form = new FormData();
    form.append("uploaded_file", $("#upload_file_bpkb").prop("files")[0]);
    form.append("mmle_id", props.id);
    form.append("upload_type", status); //type upload

    let settings = {
      xhr: function() {
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener("progress", function(e) {
          if (e.lengthComputable) {
            console.log(e);
            console.log("Bytes Loaded : " + e.loaded);
            console.log("Total Size : " + e.total);
            console.log("Persen : " + e.loaded / e.total);

            var percent = Math.round((e.loaded / e.total) * 100);
            console.log("Persen : " + percent);

            setProgress(percent);
          }
        });
        return xhr;
      },
      async: true,
      crossDomain: true,
      url: new_url,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    };

    $.ajax(settings)
      .done(function(response) {
        $("#upload_bpkb").show("slow");
        $("#uploadingbpkb").hide("slow");
        setProgress(0);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Data Telah Diupload",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          if (props.id == null) {
            history.push("/historydataupload");
          } else {
            $("#upload_type").val(0);
            $("#upload_file").val(null);
            $(".custom-file-label").text("Pilih File");
          }
        }, 3000);
      })
      .fail(function(response) {
        $("#upload_bpkb").show("slow");
        $("#uploadingbpkb").hide("slow");
        setProgress(0);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
      });
  }

  function handleClickOpen() {
    if (
      $("#upload_type_bpkb").val() == null ||
      $("#upload_type_bpkb").val() == undefined ||
      $("#upload_type_bpkb").val() == ""
    ) {
      setAlerts([
        {
          id: 1,
          type: "danger",
          headline: "Gagal",
          message: "Silahkan Pilih Tipe Upload",
        },
      ]);
      setTimeout(() => {
        setAlerts([]);
      }, 3000);
    } else if (file == null || file == undefined) {
      $(".custom-file-label").text("Pilih File");
      setAlerts([
        {
          id: 1,
          type: "info",
          message: "Silahkan Pilih File",
        },
      ]);
      setTimeout(() => {
        setAlerts([]);
      }, 3000);
    } else {
      setOpen(true);
    }
  }

  function handleClose() {
    setOpen(false);
  }
  console.log(props.id);
  return (
    <>
      <DialogHapusBatal
        open={open}
        close={handleClose}
        title="Upload Data BPKB"
        message={`Ada Yakin Ingin ${uploadtype} Data ini ?`}
        btn="Upload"
        fun={Upload}
      />
      <Portlet>
        <PortletHeader
          title="Upload BPKB"
          toolbar={
            <PortletHeaderToolbar>
              <a href={TemplateUploadDataBpkb} className="btn btn-label-primary">
                <i className="fas fa-file-download"></i>Download Template BPKB
              </a>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <AlertList showIcon={false} alerts={alerts} />
          <div className="input-group" id="upload_bpkb">
            <select
              className="custom-select"
              id="upload_type_bpkb"
              onChange={Status}
              style={{ width: "10px" }}
              aria-label="Example select with button addon"
            >
              <option selected disabled value="0">
                Pilih Tipe Upload
              </option>
              <option name="type_upload" id="mper_status" value="1">
                Tambahkan Data
              </option>
              <option name="type_upload" id="mper_status" value="2">
                Gantikan Data
              </option>
              {/* <option name="type_upload" id="mper_status" value="3">Hapus Data</option> */}
            </select>
            <div className="custom-file">
              <input
                type="file"
                name="File"
                className="custom-file-input"
                id="upload_file_bpkb"
                onChange={fileUpload}
              />
              <label
                className="custom-file-label"
                for="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02"
              >
                Pilih file
              </label>
            </div>
            <div className="input-group-append">
              <button className="btn btn-label-primary" onClick={handleClickOpen} type="button">
                Upload
              </button>
            </div>
          </div>
          <div id="uploadingbpkb" style={{ display: "none" }}>
            <Card bg="primary" text="white">
              <Card.Body>
                <Card.Title>
                  <h3>Mengupload Data</h3>
                </Card.Title>
                <hr style={{ borderTop: "1px solid #f4a240" }} />
                <Card.Text>
                  <h4>
                    Nama File : <span>{uploadname}</span>
                  </h4>
                  <h4>
                    Proses :{" "}
                    <span>
                      {progress}% {progress == 100 ? "(Memproses Di Server)" : ""}
                    </span>
                  </h4>
                  <ProgressBar animated now={progress} variant="warning" />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}
