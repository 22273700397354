/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import API from '../../API'
// import $ from 'jquery'
// import { AlertList} from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
}));

export default function DialogConfig(props) {

    const classes = useStyles();

  return (
    <div>
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="sm"
            keepMounted
            onClose={props.close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div className="form-group row" style={{display:props.komisi}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Tipe Fee Motor</label>
              <div className="col-sm-8">
                <select id="tcif_informan_bike_fee_type" name="tcif_informan_bike_fee_type" className="form-control">
                  <option disabled selected value="pilih">Pilih Tipe</option>
                  <option value="nominal">Nominal</option>
                  <option value="persen">Persen</option>
                </select>
              </div>
            </div>
            <div className="form-group row" style={{marginTop:'-15px',display:props.komisi}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Nominal Fee Motor</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="tcif_informan_bike_fee_nominal"/>
              </div>
            </div>
            <div className="form-group row" style={{marginTop:'-15px',display:props.komisi}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Tipe Fee Mobil</label>
              <div className="col-sm-8">
                <select id="tcif_informan_car_fee_type" className="form-control">
                  <option disabled selected value="pilih">Pilih Tipe </option>
                  <option value="nominal">Nominal</option>
                  <option value="persen">Persen</option>
                </select>
              </div>
            </div>
            <div className="form-group row" style={{marginTop:'-15px',display:props.komisi}}>
              <label for="inputEmail3" className="col-sm-4 col-form-label">Nominal Fee Mobil</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="tcif_informan_car_fee_nominal"/>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            Batal
          </Button>
          <Button onClick={props.fun} color="primary">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

