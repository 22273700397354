/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import "datatables.net-bs4";
import { Portlet, PortletBody, PortletHeader } from "../../partials/content/Portlet";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { AlertList } from "react-bs-notifier";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PaymentIcon from "@material-ui/icons/Payment";
import moment from "moment";
import API from "../API";
import DetailVoucherPaid from "./Utils/DetailVoucherPaid";
import Utils from "./Utils/FunctionDetail";
import DetailBonusLunas from "./Utils/DetailBonusLunas";
import DialogVoucherPaid from "./Utils/DialogVoucherPaid";
import DialogHapusBatal from "./Utils/DialogHapusBatal";

const formatter = new Intl.NumberFormat("currency");

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  dense: {
    marginTop: 19,
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 35,
  },
  active: {
    "& $line": {
      backgroundImage: "linear-gradient( 95deg,#183c7a 0%,#a1783f 50%,#f4a240 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage: "linear-gradient( 95deg,#153264 0%,#153264 50%,#153264 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 70,
    height: 70,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: "linear-gradient( 136deg, #153264 0%, #153264 50%, #153264 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: "linear-gradient( 136deg, #183c7a 0%, #183c7a 50%, #183c7a 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AssignmentIcon fontSize="large" />,
    2: <AssignmentTurnedInIcon fontSize="large" />,
    3: <PaymentIcon fontSize="large" />,
    4: <DoneAllIcon fontSize="large" color="secondary" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? icons[String(props.icon)] : icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return [
    "Menunggu Persetujuan",
    "Voucher Diapprove",
    "Menunggu Pembayaran",
    "Voucher Sudah Lunas",
  ];
}

export default function VoucherPaid() {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [alerts, setAlerts] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(3);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [idTvoc, setIdTvoc] = React.useState(null);
  const [idLvoc, setIdLvoc] = React.useState(null);
  const [idbonus, setIdBonus] = React.useState(null);
  const [idhapus, setIdhapus] = React.useState(null);
  const [tvoc, setTvoc] = React.useState(null);
  const [tvoc_account_name, setTvocaccname] = React.useState(null);
  const [tvoctype, setTvoctype] = React.useState(null);
  const [tvocbankname, setTvocbankname] = React.useState(null);
  const [tvocnumber, setTvocnumber] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const steps = getSteps();

  useEffect(() => {
    $(document).ready(function() {
      $("#voucher-lunas").on("click", "#edit", function() {
        handleClickOpen2();
        $("#update_voucher input, #update_voucher textarea").val("");
        setIdTvoc($(this).attr("data-id"));
        setIdLvoc($(this).attr("data-lvoc_id"));
        $("#lvoc_admin_tranfer").val($(this).attr("data-lvoc_admin_tranfer"));
        $("#lvoc_note").val($(this).attr("data-lvoc_note"));
      });
      $(function() {
        $("#vldfrom,#vldto").datepicker({
          todayHighlight: true,
          autoclose: true,
          format: "yyyy-mm-dd",
        });
      });

      $("#vldfrom").val(moment().format("YYYY-MM-DD"));
      $("#vldto").val(moment().format("YYYY-MM-DD"));

      $(document).on("click", "#details", function() {
        handleClickOpen();
        setId($(this).attr("data-id"));
        setTvoc($(this).attr("data-tvoc"));
        setTvocaccname($(this).attr("data-tvoc-account-name"));
        setTvoctype($(this).attr("data-tvoc-type"));
        setTvocbankname($(this).attr("data-tvoc-bank-name"));
        setTvocnumber($(this).attr("data-tvoc-account-number"));
        setType($(this).attr("data-tvoctype"));
        setAmount($(this).attr("data-fee"));
      });

      $(document).on("click", "#detailbonus", function() {
        handleClickOpen1();
        setIdBonus($(this).attr("data-tvoc"));
      });

      $(document).on("click", "#hapus", function() {
        handleClickOpen3();
        setIdhapus($(this).attr("data-id"));
      });
    });
  }, [setAlerts]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen1() {
    setOpen1(true);
  }

  function handleClose1() {
    setOpen1(false);
  }

  function handleClickOpen2() {
    setOpen2(true);
  }

  function handleClose2() {
    setOpen2(false);
  }

  function handleClickOpen3() {
    setOpen3(true);
  }

  function handleClose3() {
    setOpen3(false);
  }

  function HapusVoucher() {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Financeapi + "v1/Voucher_lunas/removeDuplicateData/" + idhapus,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        handleClose3();
        FilterPaid();
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Voucher Telah Dihapus",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        handleClose3();
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  }

  function FilterPaid() {
    if ($("#vldfrom").val() == 0 || $("#vldto").val() == 0) {
      return 0;
    }
    var from = moment($("#vldfrom").val()).format("MM-DD-YYYY");
    var to = moment($("#vldto").val()).format("MM-DD-YYYY");
    var url;
    if (from != "Invalid date" && to != "Invalid date") {
      url = API.Financeapi + "v1/Voucher_lunas/index?from=" + from + "&to=" + to;
    } else {
      url = API.Financeapi + "v1/Voucher_lunas/index";
    }
    $("#voucher-lunas").DataTable({
      pagingType: "full_numbers",
      dom:
        "<'row'<'col-sm-12 col-md-5'l><'col-sm-12 col-md-7'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          extend: "excelHtml5",
          className: "btn btn-primary mt-4",
          text: '<i class="fas fa-file-excel"></i>Export Excel',
          titleAttr: "Export Excel",
          customize: function(xlsx, row) {
            var sheet = xlsx.xl.worksheets["sheet1.xml"];
            $('row c[r^="L"], row c[r^="Q"]', sheet).attr("s", 63);
          },
        },
      ],
      processing: true,
      serverSide: true,
      destroy: true,
      lengthMenu: [
        [5, 10, 15, 20, -1],
        [5, 10, 15, 20, "All"],
      ],
      ajax: {
        url: url,
        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        if (aData.tvoc_status == "Voucher sudah diisi") {
          $("td:eq(14)", nRow).css("background-color", "#81D4FA");
        } else if (aData.tvoc_status == "Siap Dibayar") {
          $("td:eq(14)", nRow).css("background-color", "#FFE800");
        } else if (aData.tvoc_status == "Ditolak") {
          $("td:eq(14)", nRow).css("background-color", "#FD333F");
        } else if (aData.tvoc_status == "Telah Dibayar") {
          $("td:eq(14)", nRow).css("background-color", "#6CFF00");
        }
        if (aData.tvoc_type == "Referall") {
          $("td:eq(1)", nRow).css("background-color", "#9999ff");
          $("td:eq(13)", nRow).css("background-color", "#9999ff");
        }
        if (aData.app_type == "mobil") {
          $("td:eq(0)", nRow).css("background-color", "#AFEEEE");
        } else if (aData.app_type == "motor") {
          $("td:eq(0)", nRow).css("background-color", "#F4A460");
        } else if (aData.app_type == "bonus") {
          // $('td:eq(7)', nRow).html('-');
          // $('td:eq(8)', nRow).html('-');
          // $('td:eq(9)', nRow).html('-');
          $("td:eq(0)", nRow).css("background-color", "#ffb6b9");
        }
      },
      columns: [
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return "<div>" + Utils.StatusAppType(full.app_type) + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var type_user;
            if (full.tvoc_informan_type == "mitra" && full.tpif_id != null) {
              type_user = "Mitra - Informan";
            } else {
              type_user = full.tvoc_informan_type;
            }
            return " <div > " + type_user + "</div>";
          },
        },
        {
          data: "lvoc_created_date",
        },
        {
          data: "lvoc_created_time",
        },
        {
          data: "tvoc_account_name",
        },
        {
          data: "tvoc_bank_name",
        },

        {
          data: "tvoc_account_number",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + formatter.format(parseInt(full.lvoc_admin_tranfer)) + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var biaya_kirim =
              full.lvoc_admin_transfer_ekspedisi != null ? full.lvoc_admin_transfer_ekspedisi : 0;
            if (full.tvoc_informan_type == "mitra") {
              return " <div>" + formatter.format(parseInt(biaya_kirim)) + "</div>";
            } else {
              return " <div>  </div>";
            }
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var fee = full.lvoc_admin_tranfer != null ? full.lvoc_admin_tranfer : 0;
            var biaya_kirim =
              full.lvoc_admin_transfer_ekspedisi != null ? full.lvoc_admin_transfer_ekspedisi : 0;

            if (full.tvoc_informan_type == "mitra") {
              return " <div >" + formatter.format(parseInt(fee) + parseInt(biaya_kirim)) + "</div>";
            } else {
              return " <div >" + formatter.format(parseInt(fee)) + "</div>";
            }
          },
        },
        {
          data: "tvoc_vehicle_number",
        },
        {
          data: "mmle_name",
        },

        {
          sortable: true,
          render: function(data, type, full, meta) {
            return (
              " <div > " +
              full.tpnm_type +
              " " +
              full.tpnm_vyear +
              " " +
              full.tpnm_vcolor +
              "</div>"
            );
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + full.tvoc_type + "</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return " <div >" + full.tvoc_status + "</div>";
            // return " <div >"+getStatusVoucher(full.tvoc_status)+"</div>";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var admin_transfer;
            if (full.lvoc_admin_tranfer != "") {
              admin_transfer = formatter.format(parseInt(full.lvoc_admin_tranfer));
            } else {
              admin_transfer = " ";
            }

            if (full.app_type == "bonus") {
              return (
                "<button class='details btn btn-outline-brand btn-icon btn-sm' id='detailbonus' data-id=" +
                full.tpnm_id +
                " data-tvoc=" +
                full.tvoc_id +
                " data-tvoc-account-name=" +
                full.tvoc_account_name +
                "  data-tvoc-type=" +
                full.tvoc_type +
                " data-tvoc-bank-name=" +
                full.tvoc_bank_name +
                " data-tvoc-account-number=" +
                full.tvoc_account_number +
                " data-original-title='Detail Data Penemuan'> <i class='fas fa-file-invoice-dollar'></i> </button>"
              );
            } else {
              return `
                    <button id="user3" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="user3">
                        <button class='dropdown-item' id='details' data-id="${full.tpnm_id}" data-tvoc="${full.tvoc_id}" data-tvoctype="${full.tvoc_informan_type}" data-fee="${full.tvoc_ammount}" data-tvoc-account-name="${full.tvoc_account_name}"  data-tvoc-type="${full.tvoc_type}" data-tvoc-bank-name="${full.tvoc_bank_name}" data-tvoc-account-number="${full.tvoc_account_number}" data-toggle='tooltip' title='Detail'><i class='fa fa-eye'></i>Detail</button>
                        <button class='dropdown-item edit'
                          id ='edit'
                          data-id='${full.tvoc_id}'
                          data-lvoc_id = '${full.lvoc_id}'
                          data-lvoc_admin_tranfer = '${admin_transfer}'
                          data-lvoc_note = '${full.lvoc_note}'
                        >
                        <i class='fa fa-edit'></i> Edit
                        </button>
                        <button class='dropdown-item' id='hapus' data-id="${full.tvoc_vehicle_number}" data-toggle='tooltip' title='Hapus Leasing'><i class='fa fa-trash'></i> Hapus</button>
                    </div>`;
            }
          },
        },
      ],
    });
    $(".dataTables_filter,.dataTables_paginate").addClass("pull-right");
  }

  const SubmitVoucher = () => {
    var data = {
      tvoc_id: idTvoc,
      lvoc_id: idLvoc,
      lvoc_admin_tranfer: $("#lvoc_admin_tranfer")
        .val()
        .replace(/\D/g, ""),
      lvoc_note: $("#lvoc_note").val(),
    };

    var saving = {
      async: true,
      crossDomain: true,
      url: `${API.Financeapi}v1/Voucher_lunas/updateVoucherPaid/${idLvoc}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      data: JSON.stringify(data),
    };

    $.ajax(saving)
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Invoice Berhasil Di Isi`,
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        handleClose2();
        FilterPaid();
      })
      .fail(function(response) {
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
      });
  };
  return (
    <>
      <AlertList showIcon={false} alerts={alerts} />
      <DetailVoucherPaid
        open={open}
        close={handleClose}
        id={id}
        step={6}
        tvocname={tvoc_account_name}
        tvocbankname={tvocbankname}
        tvocnumber={tvocnumber}
        tvoctype={tvoctype}
        tvocid={tvoc}
        type={type}
        fee={amount}
      />
      <DetailBonusLunas open={open1} close={handleClose1} id={idbonus} title="Detail Pembayaran" />
      <DialogVoucherPaid
        open={open2}
        close={handleClose2}
        title={`Edit Voucher Paid`}
        submit={SubmitVoucher}
      />
      <DialogHapusBatal
        open={open3}
        close={handleClose3}
        title="Hapus Voucher"
        message="Anda Yakin Ingin Hapus Voucher Ini ?"
        btn="Hapus"
        fun={HapusVoucher}
      />
      <Portlet>
        <PortletHeader title="Step Voucher"></PortletHeader>
        <PortletBody>
          <Grid container spacing={3}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              style={{ width: "100%" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </PortletBody>
      </Portlet>
      <Portlet>
        <Portlet>
          <PortletHeader title="Data Voucher Lunas"></PortletHeader>
          <PortletBody>
            <PortletBody>
              <Grid container spacing={2}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                      <input
                        type="text"
                        className="form-control"
                        name="start"
                        id="vldfrom"
                        autocomplete="off"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">To</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="end"
                        id="vldto"
                        autocomplete="off"
                      />
                    </div>
                    <button
                      type="button"
                      class="btn btn-brand btn-elevate btn-sm"
                      onClick={FilterPaid}
                      id="cari"
                      style={{ width: "100%" }}
                    >
                      Set Filter
                    </button>
                  </Grid>
                </Grid>
                <Grid item xs></Grid>
              </Grid>
            </PortletBody>
            <table
              className="table table-hover table-responsive-md"
              id="voucher-lunas"
              style={{ width: "100%" }}
            >
              <thead className="bg-primary" style={{ color: "white" }}>
                <tr>
                  <th scope="col">Tipe</th>
                  <th scope="col">Tipe User</th>
                  <th scope="col">Tanggal</th>
                  <th scope="col">Jam</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Nama Bank</th>
                  <th scope="col">Nomor Rekening</th>
                  <th scope="col">Harga</th>
                  <th scope="col">Biaya Pengiriman</th>
                  <th scope="col">Total Dibayar</th>
                  <th scope="col">Nopol</th>
                  <th scope="col">Leasing</th>
                  <th scope="col">Unit</th>
                  <th scope="col">Tipe Voucher</th>
                  <th scope="col">Status</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
            </table>
            <div>
              <button
                type="button"
                class="btn btn-default"
                style={{ background: "#F4A460" }}
              ></button>
              <span style={{ color: "black" }}> : Motor </span>
              <button
                type="button"
                class="btn btn-default"
                style={{ background: "#AFEEEE" }}
              ></button>
              <span style={{ color: "black" }}> : Mobil </span>
              <button
                type="button"
                class="btn btn-default"
                style={{ background: "#ffb6b9" }}
              ></button>
              <span style={{ color: "black" }}> : Bonus </span>
              <button
                type="button"
                class="btn btn-default"
                style={{ background: "#9999ff" }}
              ></button>
              <span style={{ color: "black" }}> : Referall </span>
              <button
                type="button"
                class="btn btn-default"
                style={{ background: "#6CFF00" }}
              ></button>
              <span style={{ color: "black" }}> : Voucher telah dibayar</span>
            </div>
          </PortletBody>
        </Portlet>
      </Portlet>
    </>
  );
}
