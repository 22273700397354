/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { 
  // FormattedMessage, 
  injectIntl 
} from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import IconButton from '@material-ui/core/IconButton';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as auth from "../../store/ducks/auth.duck";
import sha256 from 'sha256'
// import { login } from "../../crud/auth.crud";
import $ from 'jquery'
import './Login.css'
import Logo from './BetaImg/beta-logo.png'
import API from '../API'
// import Img2 from '../Assets/image2.jpg'
// import Img3 from '../Assets/logobeta.png'
import {
    // Alert,
    // Badge,
    // Button,
    // ButtonDropdown,
    // ButtonGroup,
    // ButtonToolbar,
    // Card,
    // CardBody,
    // CardFooter,
    // CardHeader,
    // CardTitle,
    // CardText,
    // Container,
    // NavLink,
    // NavItem,
    // Nav,
    Col,
    // Dropdown,
    // DropdownItem,
    // DropdownMenu,
    // DropdownToggle,
    // Progress,
    Row,
    // Table,
    // Form,
    // FormGroup,
    // Label,
    // Input,
    // Modal,
    // ModalHeader,
    // ModalBody,
    // ModalFooter,
    // FormText,
    // TabContent,
    // TabPane
  } from 'reactstrap';

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const [values2, setValues2] = React.useState({
    amount: '',
    // password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  // const handleChange = prop => event => {
  //   setValues2({ ...values2, [prop]: event.target.value });
  // };

  const handleClickShowPassword = () => {
    setValues2({ ...values2, showPassword: !values2.showPassword });
  };

  return (
    <>
                  {/* <Row className="row">
                    <Col md="4" className="p-5">
                        <div className="text-center">
                            <img alt="" width="100vw" className="mb-4"/>
                        </div>
                        <Form>
                            <FormGroup>
                                <Label>Username</Label>
                                <Input>Username</Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input type="password">Password</Input>
                            </FormGroup>
                            <div className="text-center">
                                <Button className="btn btn-outline-brand w-100 text-center mb-4">Log In</Button>
                            </div>
                            <a href="/" className="forgot">Forgot Password</a>
                        </Form>
                    </Col>
                    <Col>
                        <div className="crossfade">
                            <figure></figure>
                            <figure></figure>
                            <figure></figure>
                            <figure></figure>
                            <figure></figure>
                        </div>
                    </Col>
                </Row> */}
      {/* <div className="kt-login__head">
        <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Sign Up!
        </Link>
      </div> */}
      <Row style={{height:'100%',width:'100%',position:'absolute',top:0,right:0,bottom:0}}>
        <Col md="4" className="p-5">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
             <img src={Logo} alt="logo" style={{margin:'auto',width:'35%'}}/>
              <h2>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
                {/* Welcome to BETA */}
            </h2>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              $(document).ready(function(){
                setTimeout(() => {
                  var settings = {
                    "async": true,
                    "crossDomain": false,
                    "url": API.Authapi+'v1/auth/loginAdm',
                    "method": "POST",
                    "headers": {
                      "Content-Type": "application/json"
                    },
                    "processData": false,
                    "data": "{\n\t\"mper_username\": \""+values.email+"\",\n\t\"mper_password\": \""+sha256(sha256(values.password))+"\"\n}"
                    // "data": "{\n\t\"mper_username\": \""+username+"\",\n\t\"mper_password\": \""+sha256(sha256(password))+"\"\n}"
                  }
                  // console.log("login "+username+" "+sha256(password));
                  $.ajax(settings).done(function (response) {
                    console.log(response);
                    disableLoading();
                    localStorage.removeItem("demo1-lastLocation");
                    localStorage.removeItem("demo1-lastLocation_expiresIn");
                    localStorage.removeItem("persist:demo1-auth");
                    localStorage.setItem("username", values.email);
                    localStorage.setItem("jwt", response.data.token);
                    localStorage.setItem("role", response.data.role);
                    localStorage.setItem("mmle", response.data.mmle);
                    localStorage.setItem("mper", response.data.mper);
                    props.login("access-token-8f3ae836da744329a6f93bf20594b5cc");
                  }).fail(function (response){
                    console.log(response);
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      // intl.formatMessage({
                      //   id: "AUTH.VALIDATION.INVALID_LOGIN"
                      // })
                      "Username dan/atau Password Yang Anda Masukan Salah!!!"
                    );
                  });

                  // .then(({ data: { accessToken } }) => {
                    //   disableLoading();
                    // props.login(accessToken);
                  //   console.log(accessToken);
                  //   setSubmitting(false);
                  // })
                  // .catch(() => {
                  //   disableLoading();
                  //   setSubmitting(false);
                  //   setStatus(
                    //     intl.formatMessage({
                      //       id: "AUTH.VALIDATION.INVALID_LOGIN"
                      //     })
                  //   );
                  // });
                }, 1000);
              })
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  // <div role="alert" className="alert alert-info">
                  //   <div className="alert-text">
                  //     Use account <strong>admin@demo.com</strong> and password{" "}
                  //     <strong>demo</strong> to continue.
                  //   </div>
                  // </div>
                  <div>
                  </div>
                )}

                <div className="form-group">
                    {/* <TextField id="input-with-icon-grid" label="With a grid" /> */}
                    <TextField
                      type="text"
                      label="Username"
                      margin="normal"
                      className="kt-width-full"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                </div>

                {/* <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div> */}

                <FormControl className="form-group" style={{width:'100%'}}>
                  <InputLabel htmlFor="adornment-password">Password</InputLabel>
                  <Input
                    id="adornment-password"
                    type={values2.showPassword ? 'text' : 'password'}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    style={{width:'100%'}}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                          {values2.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <div className="kt-login__actions">
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
                  </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>

          <div className="kt-login__divider">
            {/* <div className="kt-divider">
              <span />
              <span>OR</span>
              <span />
            </div> */}
          </div>

          <div className="kt-login__options">
            {/* <Link to="http://facebook.com" className="btn btn-primary kt-btn">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="http://twitter.com" className="btn btn-info kt-btn">
              <i className="fab fa-twitter" />
              Twitter
            </Link>
            <Link to="google.com" className="btn btn-danger kt-btn">
              <i className="fab fa-google" />
              Google
            </Link> */}
          </div>
        </div>
      </div>
      </Col>
      <Col>
        <div className="crossfade">
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
          <figure></figure>
        </div>
      </Col>
    </Row>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
