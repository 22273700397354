const host = 'https://prd2.betaaplikasi.com:8888/prod/';

export default {
    host: host,
    Authapi: host+'auth/api/',
    Foundingapi: host+'founding/api/',
    Userapi: host+'user/api/',
    Utilsapi: host+'utils/api/',
    Uploadapi: host+'upload/api/',
    Financeapi: host+'finance/api/',
    Logapi: host+'log/api/',
    Handle403: (res,setAlerts) => {
        if (res.status == 403 && res.responseText == `{"code":"ER_AUTH_VLD_11","data":false}`) {
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Warning !!!",
                message: "Akun Anda Sedang Digunakan"
            }])
            setTimeout(() => {
                setAlerts([])
                setTimeout(() => {
                    // alert('Logout')
                    window.location.replace("/logout");
                    return 0
                },1000)
            }, 3000)
        }
    }
}
