/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import API from "../API";
import { AlertList } from "react-bs-notifier";
import DialogHapusBatal from "./Utils/DialogHapusBatal";
import Utils from "./Utils/FunctionDetail";

export default function DataTerupload() {
  const [id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [alerts, setAlerts] = React.useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen2() {
    setOpen2(true);
  }

  function handleClose2() {
    setOpen2(false);
  }

  function handleClickOpenCancel() {
    setOpenCancel(true);
  }

  function handleCloseCancel() {
    setOpenCancel(false);
  }

  function ApproveData() {
    console.log(id);
    handleClose();
    var progress = {
      async: true,
      crossDomain: true,
      url: API.Uploadapi + "/v1/data_terupload/progressBar/" + id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(progress).done(function(response) {
      $("#data_terupload")
        .DataTable()
        .ajax.reload();
    });

    var settings = {
      async: true,
      crossDomain: true,
      url: API.Uploadapi + "/v1/BetaStatus/approver/" + id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(settings)
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Data Terupload Telah Di Approve",
          },
        ]);
        $("#data_terupload")
          .DataTable()
          .ajax.reload();
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        CancelData();
        setTimeout(() => {
          setAlerts([]);
          $("#data_terupload")
            .DataTable()
            .ajax.reload();
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  }

  function CancelData() {
    handleCloseCancel();
    console.log(id);
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Uploadapi + "/v1/BetaStatus/cancel/" + id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Data Terupload Telah Di Cancel",
          },
        ]);
        $("#data_terupload")
          .DataTable()
          .ajax.reload();
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  }

  function RejectData() {
    handleClose2();
    console.log(id);
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Uploadapi + "/v1/BetaStatus/reject/" + id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Data Terupload Telah Di Reject",
          },
        ]);
        $("#data_terupload")
          .DataTable()
          .ajax.reload();
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  }

  useEffect(() => {
    $(document).ready(function() {
      $("#data_terupload").DataTable({
        pagingType: "full_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        ajax: {
          url: API.Uploadapi + "/v1/data_terupload/index",
          type: "get",
          beforeSend: function(xhr) {
            xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
          },
          error: function(xhr, error, thrown, settings) {
            API.Handle403(xhr, setAlerts);
          },
        },
        fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          if (aData.thbd_type == "3") {
            $("td:eq(7)", nRow).css("background-color", "#AFEEEE");
          } else if (aData.thbd_type == "2") {
            $("td:eq(7)", nRow).css("background-color", "#ffc2c2");
          } else if (aData.thbd_type == "1") {
            $("td:eq(7)", nRow).css("background-color", "#a7e9af");
          }
        },
        columns: [
          {
            data: "thbd_updated_date",
          },
          {
            data: "thbd_updated_time",
          },
          {
            data: "mmle_name",
            render: function(data) {
              return data !== null ? data : "Semua Leasing";
            },
          },
          {
            data: "mmle_pic_name1",
            render: function(data) {
              return data !== null ? data : "-";
            },
          },
          {
            data: "mmle_pic_phone1",
            render: function(data) {
              return data !== null ? data : "-";
            },
          },
          // {
          //     "data": "thbd_count"
          // },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              // return " <div >" + full.thbd_status + "</div>";
              return " <div >" + Utils.StatusUpload(full.thbd_status) + "</div>";
            },
          },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              // return " <div >" + full.thbd_type + "</div>";
              return " <div >" + Utils.TypeUpload(full.thbd_type) + "</div>";
            },
          },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              // eslint-disable-next-line no-multi-str
              if (full.thbd_progress == null || full.thbd_progress == null) {
                return (
                  "<div id='aksi'> " +
                  "<button class='btn btn-outline-success btn-sm approve_btn tooltips mb-1 mr-1 app" +
                  full.thbd_id +
                  "' data-id=" +
                  full.thbd_id +
                  " id='approve'><i class='fa fa-check'></i> Approve</button>" +
                  "<button class='btn btn-outline-danger btn-sm reject_btn tooltips mb-1 mr-1 re" +
                  full.thbd_id +
                  "' data-id=" +
                  full.thbd_id +
                  " id='reject'><i class='fa fa-times'></i> Reject</button>" +
                  "</div>"
                );
              } else if (full.thbd_progress == "Processing") {
                return (
                  "<div id='aksi'> " +
                  "<button class='btn btn-outline-danger btn-sm reject_btn tooltips mb-1 mr-1 re" +
                  full.thbd_id +
                  "' data-id=" +
                  full.thbd_id +
                  " id='cancel'><i class='fa fa-close'></i> Cancel</button>" +
                  // `<div>Data Masuk : ${full.thbd_count == null ? 0 : full.thbd_count} Data</div>`+
                  `<div>${full.thbd_progress}</div>` +
                  `<div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                                    </div>
                                 </div>` +
                  "</div>"
                );
              } else if (full.thbd_progress == "Success") {
                return "<div id='aksi'> " + `<div>${full.thbd_progress}</div>` + "</div>";
              }
            },
          },
        ],
      });

      $(document).on("click", "#reject", function() {
        handleClickOpen2();
        setId($(this).attr("data-id"));
      });

      $(document).on("click", "#approve", function() {
        handleClickOpen();
        setId($(this).attr("data-id"));
      });

      $(document).on("click", "#cancel", function() {
        handleClickOpenCancel();
        setId($(this).attr("data-id"));
      });

      $(".dataTables_filter,.dataTables_paginate").addClass("pull-right");
    });
  }, [setAlerts]);
  return (
    <>
      <AlertList showIcon={false} alerts={alerts} />
      <DialogHapusBatal
        open={open}
        close={handleClose}
        title="Validasi Data"
        message="Anda Yakin Ingin Approve Data Ini ?"
        btn="Approve"
        fun={ApproveData}
        // type="resetuser"
        // id={idreset}
      />
      <DialogHapusBatal
        open={open2}
        close={handleClose2}
        title="Validasi Data"
        message="Anda Yakin Ingin Reject Data Ini ?"
        btn="Reject"
        fun={RejectData}
        // type="resetuser"
        // id={idreset}
      />
      <DialogHapusBatal
        open={openCancel}
        close={handleCloseCancel}
        title="Validasi Data"
        message="Anda Yakin Ingin Cancel Data Ini ?"
        btn="Cancel"
        fun={CancelData}
        // type="resetuser"
        // id={idreset}
      />
      <Portlet>
        <PortletBody>
          <table
            className="table table-hover table-responsive-md"
            id="data_terupload"
            style={{ width: "100%" }}
          >
            <thead className="bg-primary" style={{ color: "white" }}>
              <tr>
                <th scope="col">Tanggal Diupload</th>
                <th scope="col">Jam Diupload</th>
                <th scope="col">Nama Leasing</th>
                <th scope="col">Nama PIC 1</th>
                <th scope="col">No Hp PIC 1</th>
                {/* <th scope="col">Jumlah Data</th> */}
                <th scope="col">Status Upload</th>
                <th scope="col">Tipe Upload</th>
                <th scope="col">Aksi</th>
              </tr>
            </thead>
          </table>
          <div>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#a7e9af" }}
            ></button>
            <span style={{ color: "black" }}> : Tambahkan Data </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#ffc2c2" }}
            ></button>
            <span style={{ color: "black" }}> : Hapus Data </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#AFEEEE" }}
            ></button>
            <span style={{ color: "black" }}> : Gantikan Data </span>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}
