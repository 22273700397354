/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme  } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
import $ from 'jquery'
import Utils from '../Utils/FunctionDetail'
import { AlertList} from "react-bs-notifier";

const formatter = new Intl.NumberFormat('de-DE');

const AntTabs = withStyles({
    root: {
      // borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      // backgroundColor: '#1890ff',
    },
  })(Tabs);
  
  const AntTab = withStyles(theme => ({
    root: {
      textTransform: 'bold',
      minWidth: 80,
      // fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#ffffff',
        opacity: 1,
      },
      '&$selected': {
        color: '#ffffff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#f4a240',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailPenemuan(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const [setOpen] = React.useState(false);    
  const [value, setValue] = React.useState(0);
  const [carprice, setCarprice] = React.useState(null)
  const [bikeprice, setBikeprice] = React.useState(null)
  const [alerts, setAlerts] = React.useState([]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  React.useEffect(() => {

    var komisi = {
      "async": true,
      "crossDomain": true,
      "url": API.Foundingapi + "v1/InformanConfig/list",
      "method": "GET",
      "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
    }
    
    $.ajax(komisi).done(function (response) {
      console.log(response)
      setCarprice(response.data[0].tcif_informan_car_fee_nominal)
      setBikeprice(response.data[0].tcif_informan_bike_fee_nominal)
    }).fail(function (response) {
      API.Handle403(response,setAlerts)
    })
    
    if (props.id != null && props.step != null) {      
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi +"v1/penemuan/getPenemuanMaster/"+props.id+"/"+props.step,
        "method": "GET",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
          }
      }
      
      $.ajax(settings).done(function (response) {
        console.log(response)
        if( response.data != null ){
          $('#mper_name').val(response.data.mper_name);
          $('#mmle_name').val(response.data.mmle_name);
          $('#mper_phone').val(response.data.mper_phone);
          $('#tpnm_vplate').val(response.data.tpnm_vplate);
          $('#tpnm_vin').val(response.data.tpnm_vin);
          $('#tpnm_ein').val(response.data.tpnm_ein);
          $('#tpnm_vtype').val(`(${response.data.tpnm_vtype.toUpperCase()}) - ${response.data.tpnm_vbrand} ${response.data.tpnm_type} ${response.data.tpnm_vcolor} ${response.data.tpnm_vyear} `);
          $('#tpnm_vdoc_name').val(response.data.tpnm_vdoc_name);
          $('#tpnm_contract').val(response.data.tpnm_contract);
          $('#tpnm_branch').val(response.data.tpnm_branch);
          $('#tpnm_customer_name').val(response.data.tpnm_customer_name);
          $('#tpnm_address').val(response.data.tpnm_address);
          $('#tpnm_installment').val(response.data.tpnm_installment);
          $('#tpnm_ic_number').val(response.data.tpnm_ic_number);
          $('#tpnm_kk_number').val(response.data.tpnm_kk_number);
          $('#tpnm_debt_date').val(response.data.tpnm_debt_date);
          $('#tpnm_invoice_qty').val(response.data.tpnm_invoice_qty);
          $('#tpnm_duration').val(response.data.tpnm_duration);
          if(response.data.tpnm_monthly_charge != null && response.data.tpnm_monthly_charge != ""){
          // $('#tpnm_monthly_charge').val((parseInt(response.data.tpnm_monthly_charge)));
          $('#tpnm_monthly_charge').val(formatter.format(parseInt(response.data.tpnm_monthly_charge)));
          // $('#tpnm_total_unpaid').val((parseInt(response.data.tpnm_total_unpaid)));
          $('#tpnm_total_unpaid').val(formatter.format(parseInt(response.data.tpnm_total_unpaid)));
          }else{
          $('#tpnm_monthly_charge').val(response.data.tpnm_monthly_charge);
          $('#tpnm_total_unpaid').val(response.data.tpnm_total_unpaid);
          }
          if(response.data.tpnm_fee_revisi != null){
            $('#mitra_revisi').show();
            $('#stnk_revisi').show();
            $('#tpnm_fee_revisi').val(formatter.format(parseInt(response.data.tpnm_fee)));
            $('#tpnm_fee_stnk_revisi').val(formatter.format(parseInt(response.data.tpnm_fee_stnk)));
          }else{
            $('#mitra_revisi').hide();
            $('#stnk_revisi').hide();
          }
          
          $('#tpnm_created_date').val( response.data.tpnm_created_date+" - "+response.data.tpnm_created_time );
          $('#tpnm_created_date2').val( response.data.tpnm_created_date+" - "+response.data.tpnm_created_time );
          $('#tpnm_sk_initiated_date').val( response.data.tpnm_sk_initiated_date+" - "+response.data.tpnm_sk_initiated_time );
  
          $('#tpnm_data_approved_date').val( response.data.tpnm_data_approved_date+" - "+response.data.tpnm_data_approved_time );
          $('#tpnm_cancelled_date').val(response.data.tpnm_cancelled_date+ " - "+ response.data.tpnm_cancelled_time);
          $('#tpnm_cancelled_reason').val(response.data.tpnm_cancelled_reason);
          $('#tpnm_reason_text_detail').val(response.data.tpnm_reason_text);
          $('#tpnm_cancelled_by').val(response.data.tpnm_cancelled_by);
          $('#tpnm_cancelled_step').val(Utils.StepPembatalan(response.data.tpnm_step));
          $('#tpnm_debitur_name').val(response.data.tpnm_cancelled_vholder_name);
          $('#tpnm_debitur_phone').val(response.data.tpnm_cancelled_vholder_phone);
  
          if(response.data.tpnm_vtype == 'mobil'){
            $('#validasi_mobil').show();
            if( response.data.tpnm_medi_vld_mobil != null ){
              $('#tpnm_medi_vld_mobil').attr("href", response.data.tpnm_medi_vld_mobil);
            }else{
              $('#tpnm_medi_vld_mobil').addClass("disabled")
            }
            $('#detail_mobil').hide();
            $('#foto_mobil').show();
          }else{
            $('#detail_mobil').show();
            $('#foto_mobil').hide();
            $('#validasi_mobil').hide();
          }
          if(response.data.tpnm_cancelled_step == 7){
            $('#detail_batal').show();
          }else{
            $('#detail_batal').hide();
          }
          console.log(response.data.tpnm_cancelled_step);
          if( response.data.tpnm_medi_sk_template != null ){
            $('#tpnm_medi_sk_template').attr("href", response.data.tpnm_medi_sk_template);
          }else{
            $('#tpnm_medi_sk_template').addClass("disabled")
          }
          if( response.data.tpnm_medi_vphoto_sisi1 != null ){
            $('#tpnm_medi_vphoto_sisi1').attr("href", response.data.tpnm_medi_vphoto_sisi1);
          }else{
            $('#tpnm_medi_vphoto_sisi1').addClass("disabled")
          }
          $('#tpnm_medi_vphoto_sisi2').prop( "disabled", true );
          if( response.data.tpnm_medi_vphoto_sisi2 != null ){
            $('#tpnm_medi_vphoto_sisi2').attr("href", response.data.tpnm_medi_vphoto_sisi2);
          }else{
            $('#tpnm_medi_vphoto_sisi2').addClass("disabled")
          }
          if( response.data.tpnm_delivered_stnk_photo != null ){
            $('#tpnm_delivered_stnk_photo').attr("href", response.data.tpnm_delivered_stnk_photo);
          }else{
            $('#tpnm_delivered_stnk_photo').addClass("disabled")
          }
          if( response.data.tpnm_medi_bast_template != null ){
            $('#tpnm_medi_bast_template_1').attr("href", response.data.tpnm_medi_bast_template);
          }else{
            $('#tpnm_medi_bast_template_1').addClass("disabled")
          }
          if( response.data.tpnm_medi_bast_final != null ){
            $('#tpnm_medi_bast_final').attr("href", response.data.tpnm_medi_bast_final);
          }else{
            $('#tpnm_medi_bast_final').addClass("disabled")
          }
          if(response.data.tpnm_fee_revisi == null){
            $('#tpnm_fee_stnk').val(formatter.format(response.data.tpnm_fee_stnk));
            $('#tpnm_fee').val(formatter.format(parseInt(response.data.tpnm_fee)));
            $('#total_fee').val(formatter.format(parseInt(response.data.tpnm_fee_stnk) + parseInt(response.data.tpnm_fee)));
          }else{
            $('#tpnm_fee_stnk').val(formatter.format(response.data.tpnm_fee_stnk_revisi));
            $('#tpnm_fee').val(formatter.format(parseInt(response.data.tpnm_fee_revisi)));
            $('#total_fee').val(formatter.format(parseInt(response.data.tpnm_fee_stnk_revisi) + parseInt(response.data.tpnm_fee_revisi)));
          }
          // $('#tpnm_fee').val(formatter.format(parseInt(response.data.tpnm_fee)));
  
          $('#tpnm_delivery_dpname').val( response.data.tpnm_delivery_dpname);
          $('#tpnm_delivery_dplat').val( response.data.tpnm_delivery_dplat+" , "+response.data.tpnm_delivery_dplng);
          $('#tpnm_delivery_dpaddress').val( response.data.tpnm_delivery_dpaddress);
          $('#tpnm_delivery_dpopen').val( response.data.tpnm_delivery_dpopen+" - "+response.data.tpnm_delivery_dpclose);
          $('#tpnm_delivery_date').val( response.data.tpnm_delivery_date+" - "+response.data.tpnm_delivery_time);
  
          $('#tpnm_delivered_receiver_name').val( response.data.tpnm_delivered_receiver_name);
          $('#tpnm_delivered_receiver_phone').val( response.data.tpnm_delivered_receiver_phone);
          $('#tpnm_delivered_received_date').val( response.data.tpnm_delivered_received_date+" - "+response.data.tpnm_delivered_received_time);
          
          var details ;
          if( response.data.tpnm_status_stnk == null){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 0){
            details = "<input selected disabled checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br> <option></option><span></span>";
          }else{
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='0'> Tidak Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_1').html(details);
          if(response.data.tpnm_status_stnk == null){
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else if(response.data.tpnm_status_stnk == 1){
            details = "<input selected disabled checked type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br> <option></option><span></span>";
          }else{
            details = "<input selected disabled type='radio' name='tpnm_status_stnk' id='tpnm_status_stnk' value='1'> Ada<br><option></option><span></span>";
          }
          $('#tpnm_status_stnk_2').html(details);
  
          // if( response.data.tpnm_medi_bast_template == null || response.data.tpnm_medi_bast_template == '' ){
          //   details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
          // }else{
          //   // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
          //   details = "<a href='"+response.data.tpnm_medi_bast_template+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>";
          // }
          // $('#tpnm_medi_bast_template_1').html(details);
  
          if( response.data.tpnm_medi_bast_final == null || response.data.tpnm_medi_bast_final == '' ){
            details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
          }else{
            // details = "<button disabled type='button' class='btn btn-outline btn-circle btn-md blue'> <i class='fa fa-image'></i> Berkas Tidak Ada</button>";
            details = "<a href='"+response.data.tpnm_medi_bast_final+"' target='_blank' type='button' class='btn btn-outline btn-circle btn-md blue'><i class='fa fa-image'></i> Lihat Berkas</a>";
          }
          $('#tpnm_medi_bast_final_1').html(details);

          $('#mper_name_informan').val(response.data.mper_name_informan)
          $('#mper_phone_informan').val(response.data.mper_phone_informan)
          $('#tpif_lat-long_informan').val(response.data.tpif_lat_informan+" , "+response.data.tpif_lng_informan)
          $('#tpif_address_informan').val(response.data.tpif_address_informan)
          $('#tanggal_jam_validasi').val(response.data.tanggal_validasi+' - '+response.data.jam_validasi)
          $('#tanggal_jam_matched').val(response.data.tanggal_matchmaking+' - '+response.data.jam_matchmaking)
          // $('#tpif_fee').val(formatter.format(response.data.tpif_fee))
          console.log(response.data.tpnm_vtype)
          if (response.data.tpif_photo_validation != null) {
            $('#tpif_photo_validation').attr("href", response.data.tpif_photo_validation);
            $('#data_informan').show()
            if(response.data.tpnm_vtype == 'motor'){
              $('#tpif_fee').val(formatter.format(bikeprice))
            }else if(response.data.tpnm_vtype == 'mobil'){
              $('#tpif_fee').val(formatter.format(carprice))
            }
          }else{
            $('#tpif_photo_validation').addClass("disabled")
            $('#tpif_fee').val(0)
            $('#data_informan').hide()
            $('#order_informan').hide()
          }
        }
      }).fail(function (response) {
        API.Handle403(response,setAlerts)
      })

      var fotoMobil = {
        "async": true,
        "crossDomain": true,
        "url": API.Foundingapi+"v1/Founding/listFotoMobil/"+props.id,
        "method": "GET",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
          }
        }
      $.ajax(fotoMobil).done(function (response){
        console.log(response);
        var htmlFoto = "";
        if(response.data != null){
  
          for(let i=0; i < response.data.length; i++){
            console.log(response.data);
            htmlFoto +=  "<div class='column' style='margin:5px;display:inline-block;' >"+
            "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href="+response.data[i].medi_url+">"+
              "<img style='border-radius:5px;' src="+response.data[i].medi_url+" width='120' height='120'>"+
            "</a>"+
            "</div>";
          }
          console.log(htmlFoto);
          $('#foto_mobil_url').html(htmlFoto);
        }
    }).fail(function (response) {
      API.Handle403(response,setAlerts)
    })
    }

  })
  
  return (
    <div>
      <AlertList  showIcon={false} alerts={alerts}/>
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <AntTabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable"
                scrollButtons="auto" aria-label="scrollable auto tabs example" className={classes.title}>
                <AntTab label="Data Kendaraan" {...a11yProps(0)} />
                <AntTab label="Order Details" {...a11yProps(1)} />
                <AntTab label="Dokumen Verifikasi" {...a11yProps(2)} />
            </AntTabs>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SwipeableViews axis={theme.direction==='rtl' ? 'x-reverse' : 'x' } index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0}>
            <Portlet>
                <PortletHeader title="Data Kendaraan"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Polisi</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id='tpnm_vplate' disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Rangka</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_vin" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Mesin</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_ein" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Type Motor/Mobil</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_vtype" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nama STNK/BPKB</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_vdoc_name" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Kontrak</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_contract" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Asal Cabang</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_branch" disabled/>
                        </div>
                      </div>
                       <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Leasing</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="mmle_name" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                </Portlet>
                <Portlet>
                <PortletHeader title="Profile Debitur"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Debitur</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_customer_name" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Konsumen</label>
                        <div class="col-sm-7">
                          {/* <input type="text" class="form-control" id="tpnm_address" disabled/> */}
                          <textarea className="form-control" id="tpnm_address" rows="3" disabled></textarea>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor KTP</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_ic_number" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor KK</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_kk_number" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal Mulai Tunggak</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_debt_date" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Angsuran Ke</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_installment" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Jumlah Kwitansi</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_invoice_qty" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tenor</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_duration" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Angsuran Perbulan</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_monthly_charge" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Total Tunggakkan</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_total_unpaid" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                </Portlet>
                <Portlet id='data_informan'>
                  <PortletHeader title="Data Informan"></PortletHeader>
                  <PortletBody>
                    <Row>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Informan</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" id="mper_name_informan" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Informan</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" id="mper_phone_informan" disabled/>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div class="form-group row">
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Lat-Long Informan</label>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="tpif_lat-long_informan" disabled/>
                          </div>
                        </div>
                        <div class="form-group row" style={{marginTop:'-15px'}}>
                          <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Posisi Informan</label>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" id="tpif_address_informan" disabled/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </PortletBody>
                </Portlet>
                <Portlet>
                <PortletHeader title="Data Mitra"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Mitra</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="mper_name" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Mitra</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="mper_phone" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
              </Portlet>
              <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Portlet>
                <PortletHeader title="Order Detail"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Validasi</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_created_date" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Menunggu SK</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_sk_initiated_date" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Surat Kerja Diapprove</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_data_approved_date" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Informan</label>
                        <div className="col-sm-8">
                        <input type="text" class="form-control" id="tpif_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Mitra</label>
                        <div className="col-sm-8">
                        <input type="text" class="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Surat Tugas</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_sk_template" target='_blank' disabled="disabled"><i className="far fa-file-pdf"></i></a>
                        </div>
                      </div>
                      <div className="form-group row" id="validasi_mobil" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Validasi Mobil</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_vld_mobil" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                      <Row id="detail_mobil">
                        <Col>
                          <div className="form-group row" style={{marginTop:'-15px'}}>
                            <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Motor Sisi 1</label>
                            <div className="col-sm-8">
                              <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_vphoto_sisi1" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                            </div>
                          </div>
                          <div className="form-group row" style={{marginTop:'-15px'}}>
                            <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Motor Sisi 2</label>
                            <div className="col-sm-8">
                              <a type="button" className="btn btn-outline-brand btn-icon" id="tpnm_medi_vphoto_sisi2" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </PortletBody>
                </Portlet>
            <Portlet id="order_informan">
                <PortletHeader title="Informan"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Validasi Informan</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tanggal_jam_validasi" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Matched</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tanggal_jam_matched" disabled/>
                        </div>
                      </div>
                      {/* <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Validasi Mitra</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_created_date2" disabled/>
                        </div>
                      </div> */}
                    </Col>
                    <Col md={6} id="detail_mobil">
                      {/* <div className="form-group row">
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Informan</label>
                        <div className="col-sm-8">
                        <input type="text" class="form-control" id="tpif_fee" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Mitra</label>
                        <div className="col-sm-8">
                        <input type="text" class="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div> */}
                      <div className="form-group row" 
                      // style={{marginTop:'-15px'}}
                      >
                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Validasi Informan</label>
                        <div className="col-sm-8">
                          <a type="button" className="btn btn-outline-brand btn-icon" id="tpif_photo_validation" target='_blank' disabled="disabled"><i className="far fa-image"></i></a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
                </Portlet>
                <Portlet>
                <PortletHeader title="Detail Drop Point"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Drop Point</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_delivery_dpname" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Drop Point Lat-Lng</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_delivery_dplat" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Alamat Drop Point</label>
                        <div class="col-sm-7">
                          {/* <input type="text" class="form-control" id="tpnm_delivery_dpaddress" disabled/> */}
                          <textarea className="form-control" id="tpnm_delivery_dpaddress" rows="5" disabled></textarea>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Jam Operasional Drop Point</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_delivery_dpopen" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal Dan Jam Antar</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_delivery_date" disabled/>
                        </div>
                      </div>
                      {/* <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Fee Ke Mitra Eksekutor</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div> */}
                    </Col>
                  </Row>
                </PortletBody>
              </Portlet>
              <Portlet id="foto_mobil">
                <PortletHeader title="Foto"/>
                <PortletBody>
                  <div class='col-md-12' style={{marginTop:'10px',overflow:'auto',height:'150px',whiteSpace: 'nowrap'}} id='foto_mobil_url'></div>
                </PortletBody>
              </Portlet>
              <Portlet id="detail_batal">
                <PortletHeader title="Detail Pembatalan"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Pembatalan</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_cancelled_date" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Dibatalkan Oleh</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_cancelled_by" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Alasan Pembatalan</label>
                        <div class="col-sm-7">
                          {/* <input type="text" class="form-control" id="tpnm_delivery_dpaddress" disabled/> */}
                          <input className="form-control" id="tpnm_cancelled_reason" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Alasan Pembatalan Admin</label>
                        <div class="col-sm-7">
                          {/* <input type="text" class="form-control" id="tpnm_delivery_dpaddress" disabled/> */}
                          <input className="form-control" id="tpnm_reason_text_detail" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Pemegang Unit</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_cancelled_vholder_name" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor HP Pemegang Unit</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_cancelled_vholder_phone" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Step Pembatalan</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="tpnm_cancelled_step" disabled/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
              </Portlet>
              <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <Portlet>
                <PortletHeader title="Dokumen Verifikasi"></PortletHeader>
                <PortletBody>
                  <Row>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Penerima</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_delivered_receiver_name" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">No HP Penerima</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_delivered_receiver_phone" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Tanggal & Jam Penerima</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_delivered_received_date" disabled/>
                        </div>
                      </div>
                      <div className="form-group row" style={{marginTop:'-15px'}} id="mitra_revisi">
                          <label for="inputEmail3" className="col-sm-4 col-form-label">Fee Ke Mitra Eksekutor Sebelum Revisi</label>
                          <div className="col-sm-7">
                            <input type="text" className="form-control" id="tpnm_fee_revisi" disabled/>
                          </div>
                        </div>
                        <div className="form-group row" style={{marginTop:'-15px'}} id="stnk_revisi">
                          <label for="inputEmail3" className="col-sm-4 col-form-label">Fee STNK Sebelum Revisi</label>
                          <div className="col-sm-7">
                            <input type="text" className="form-control" id="tpnm_fee_stnk_revisi" disabled/>
                          </div>
                        </div>
                      <div class="form-group row" id="Mitra_Fee" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Fee Ke Mitra Eksekutor</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_fee" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" id="Mitra_STNK" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Fee STNK</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="tpnm_fee_stnk" disabled/>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-4 col-form-label">Total Fee</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control" id="total_fee" disabled/>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">PDF BAST</label>
                        <div class="col-sm-8">
                          <a type="button" class="btn btn-outline-brand btn-icon" id="tpnm_medi_bast_template_1" target='_blank' disabled="disabled"><i class="far fa-file-pdf"></i></a>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-3 col-form-label">BAST Akhir</label>
                        <div class="col-sm-8">
                          <a type="button" class="btn btn-outline-brand btn-icon" id="tpnm_medi_bast_final" target='_blank' disabled="disabled"><i class="far fa-image"></i></a>
                        </div>
                      </div>
                      <div class="form-group row" style={{marginTop:'-15px'}}>
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Foto STNK</label>
                        <div class="col-sm-8">
                          <a type="button" class="btn btn-outline-brand btn-icon" id="tpnm_delivered_stnk_photo" target='_blank' disabled="disabled"><i class="far fa-image"></i></a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PortletBody>
              </Portlet>
              <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
            </TabPanel>
        </SwipeableViews>
      </Dialog>
    </div>
  );
}
