import React, { useEffect } from "react";
import { AppBar, IconButton, Slide, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import NoImage from "../Leasing/noimage.jpg";
import $ from "jquery";

import API from "../../API";
import { Card, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import FormTambahMitra from "./FormTambahMitra";
import DialogHapusBatal from "../Utils/DialogHapusBatal";
import FormTambahPricing from "./FormTambahPricing";
import FormTambahMitraPotonganKorwil from "./FormTambahPotonganKorwil";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset: theme.mixins.toolbar,
    marginTop: "70px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DetailKoordinator = ({ open, close, id }) => {
  const classes = useStyles();
  const [alerts, setAlerts] = React.useState([]);
  const [openConfirmHapusMitra, setOpenConfirmHapusMitra] = React.useState(null);
  const [idMitra, setIdMitra] = React.useState(null);
  const [openConfirmHapusPricing, setOpenConfirmHapusPricing] = React.useState(null);
  const [idPricing, setIdPricing] = React.useState(null);
  const [openConfirmHapusPotongan, setOpenConfirmHapusPotongan] = React.useState(null);
  const [idPotongan, setIdPotongan] = React.useState(null);

  const loadTableMitra = () => {
    if ($.fn.DataTable.isDataTable("#koordinator-mitra-table")) {
      $("#koordinator-mitra-table")
        .DataTable()
        .clear()
        .destroy();
    }

    $("#koordinator-mitra-table").DataTable({
      pageLength: 10,
      processing: true,
      serverSide: true,
      ajax: {
        url: API.Userapi + "v1/koordinator/daftar_mitra/" + id,
        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      columns: [
        {
          data: "mper_id",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            if (full.mper_medi_profile == null) {
              return "<img src='" + NoImage + "' style='height:70px;weight:50px;'>";
            } else {
              return (
                "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href=" +
                full.mper_medi_profile +
                ">" +
                "<img src=" +
                full.mper_medi_profile +
                " style='height:70px;width:70px;border:1px solid #c5c8f0;border-radius:10px;'>" +
                "</a>"
              );
            }
          },
        },
        {
          data: "mper_name",
        },
        {
          data: "app_type",
        },
        {
          render: function(data, type, full) {
            return `
            <button id="user1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="user1">
                <button class='dropdown-item btn-hapus-mitra-anggota' id='hapus' data-id="${full.mper_id}" data-toggle='tooltip' title='Hapus Mitra Anggota'><i class='fa fa-trash'></i>Hapus Mitra Anggota</button>
            </div>`;
          },
        },
      ],
    });
  };

  const loadTablePricing = () => {
    if ($.fn.DataTable.isDataTable("#koordinator-pricing-table")) {
      $("#koordinator-pricing-table")
        .DataTable()
        .clear()
        .destroy();
    }

    $("#koordinator-pricing-table").DataTable({
      pageLength: 10,
      processing: true,
      serverSide: true,
      ajax: {
        url: API.Userapi + "v1/koordinator/daftar_pricing/" + id,
        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      columns: [
        {
          data: "mmle_name",
        },
        {
          data: "start_range_price",
        },
        {
          data: "end_range_price",
        },
        {
          data: "potongan_percent",
        },
        {
          data: "potongan_minimal",
        },
        {
          data: "potongan_stnk",
        },
        {
          render: function(data, type, full) {
            return `
            <button id="user1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="user1">
                <button class='dropdown-item btn-hapus-pricing' id='hapus' data-id="${full.range_price_id}" data-toggle='tooltip' title='Hapus Pricing'><i class='fa fa-trash'></i>Hapus Pricing</button>
            </div>`;
          },
        },
      ],
    });
  };

  const loadTablePotongan = () => {
    if ($.fn.DataTable.isDataTable("#koordinator-potongan-korwil-table")) {
      $("#koordinator-potongan-korwil-table")
        .DataTable()
        .clear()
        .destroy();
    }

    $("#koordinator-potongan-korwil-table").DataTable({
      pageLength: 10,
      processing: true,
      serverSide: true,
      ajax: {
        url: API.Userapi + "v1/koordinator/daftar_potongan_korwil/" + id,
        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      columns: [
        {
          data: "nominal_potongan",
        },
        {
          render: function(data, type, full) {
            return `
            <button id="user1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="user1">
                <button class='dropdown-item btn-hapus-potongan-korwil' id='hapus' data-id="${full.korwil_potongan_id}" data-toggle='tooltip' title='Hapus Potongan'><i class='fa fa-trash'></i>Hapus Potongan</button>
            </div>`;
          },
        },
      ],
    });
  };

  useEffect(() => {
    console.log(id);
    if (id !== null) {
      $.ajax({
        async: true,
        crossDomain: true,
        url: API.Userapi + "v1/koordinator/detail/" + id,
        method: "GET",
        headers: {
          Authorization: "Basic " + localStorage.getItem("jwt"),
        },
      }).done(function(response) {
        $("#mper_name").val(response.data.mper_name);
        $("#mper_address").val(response.data.mper_address);
        $("#transit_pool").val(response.data.transit_pool);
        $("#note").val(response.data.note);
        $("#nama_wilayah").val(response.data.nama_wilayah);
        loadTableMitra();
        loadTablePotongan();
        loadTablePricing();
      });

      $(document).ready(function() {
        $("#koordinator-mitra-table").on("click", ".btn-hapus-mitra-anggota", function() {
          setIdMitra($(this).attr("data-id"));
          setOpenConfirmHapusMitra(true);
        });

        $("#koordinator-pricing-table").on("click", ".btn-hapus-pricing", function() {
          setIdPricing($(this).attr("data-id"));
          setOpenConfirmHapusPricing(true);
        });

        $("#koordinator-potongan-korwil-table").on(
          "click",
          ".btn-hapus-potongan-korwil",
          function() {
            setIdPotongan($(this).attr("data-id"));
            setOpenConfirmHapusPotongan(true);
          }
        );
      });
    }
  });

  const deleteMitra = () => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/delete_mitra/" + idMitra,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        setOpenConfirmHapusMitra(false);
        setIdMitra(null);
        loadTableMitra();
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Mitra Telah Dihapus",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setOpenConfirmHapusMitra(false);
        setIdMitra(null);
        loadTableMitra();
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  const deletePricing = () => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/delete_pricing/" + idPricing,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        setOpenConfirmHapusPricing(false);
        setIdPricing(null);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Pricing Telah Dihapus",
          },
        ]);
        loadTablePricing();
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setOpenConfirmHapusPricing(false);
        setIdPricing(null);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  const deletePotongan = () => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/delete_potongan/" + idPotongan,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        setOpenConfirmHapusPotongan(false);
        setIdPotongan(null);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Potongan Telah Dihapus",
          },
        ]);
        loadTablePotongan();
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setOpenConfirmHapusPotongan(false);
        setIdPotongan(null);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  const SubmitEdit = () => {
    var form = new FormData();

    form.append("transit_pool", $("#transit_pool").val());
    form.append("nama_wilayah", $("#nama_wilayah").val());
    form.append("note", $("#note").val());

    $.ajax({
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/edit/" + id,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    })
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Data Koordinator Telah Berhasil Ubah",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          close();
        }, 1000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  return (
    <div>
      <DialogHapusBatal
        open={openConfirmHapusMitra}
        close={() => setOpenConfirmHapusMitra(false)}
        title="Hapus Mitra Anggota"
        message="Anda Yakin Ingin Hapus Mitra Anggota Ini ?"
        btn="Delete"
        fun={deleteMitra}
      />
      <DialogHapusBatal
        open={openConfirmHapusPricing}
        close={() => setOpenConfirmHapusPricing(false)}
        title="Hapus Pricing"
        message="Anda Yakin Ingin Hapus Pricing Ini ?"
        btn="Delete"
        fun={deletePricing}
      />
      <DialogHapusBatal
        open={openConfirmHapusPotongan}
        close={() => setOpenConfirmHapusPotongan(false)}
        title="Hapus Potongan"
        message="Anda Yakin Ingin Hapus Potongan Ini ?"
        btn="Delete"
        fun={deletePotongan}
      />
      <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Koordinator
            </Typography>
            <IconButton edge="start" color="inherit" onClick={close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Portlet className={classes.detail}>
          <PortletBody>
            <Row>
              <Col md={6}>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Nama Koordinator</label>
                  <div className="col-sm-7">
                    <input type="text" className="form-control" id="mper_name" disabled />
                  </div>
                </div>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Alamat</label>
                  <div className="col-sm-7">
                    <textarea
                      type="text"
                      className="form-control"
                      rows="4"
                      id="mper_address"
                      disabled
                    />
                  </div>
                </div>

                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Long Lat</label>
                  <div className="col-sm-7">
                    <input name="long_lat" type="text" className="form-control" disabled />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Nama Transit Pool</label>
                  <div className="col-sm-7">
                    <input id="transit_pool" type="text" className="form-control" />
                  </div>
                </div>
                {/* 
                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Cadangan Korwil</label>
                  <div className="col-sm-7">
                    <input id="cadangan_korwil" type="number" className="form-control" />
                  </div>
                </div> */}

                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Nama Wilayah</label>
                  <div className="col-sm-7">
                    <input id="nama_wilayah" type="text" className="form-control" />
                  </div>
                </div>

                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Note</label>
                  <div className="col-sm-7">
                    <input id="note" type="text" className="form-control" />
                  </div>
                </div>
              </Col>
            </Row>

            <Card style={{ marginBottom: "10px" }}>
              <Card.Body>
                <Card.Title>
                  <div className="row">
                    <div className="col-md-6">
                      <h5>Konfigurasi</h5>
                    </div>
                  </div>
                </Card.Title>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6">
                        <strong>Pricing</strong>
                      </div>
                      <div className="col-md-6 text-right">
                        <button
                          onClick={() => {
                            $("#form-add-pricing-wrapper").show("slow");
                          }}
                          type="button"
                          className="btn btn-label-primary btn-sm"
                        >
                          <i className="fas fa-plus"></i>Tambah Pricing
                        </button>
                      </div>
                    </div>

                    <FormTambahPricing
                      setAlerts={setAlerts}
                      koordinatorMperID={id}
                      loadTablePricing={loadTablePricing}
                    ></FormTambahPricing>

                    <div style={{ overflow: "auto", marginTop: "5px" }}>
                      <table
                        className="display table table-hover table-responsive-md"
                        id="koordinator-pricing-table"
                        style={{ width: "100%" }}
                      >
                        <thead className="bg-primary" style={{ color: "white" }}>
                          <tr>
                            <th scope="col">Leasing</th>
                            <th scope="col">Start Range</th>
                            <th scope="col">End Range</th>
                            <th scope="col">Potongan %</th>
                            <th scope="col">Potongan Minimal</th>
                            <th scope="col">Potongan STNK</th>
                            <th scope="col">Aksi</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-6">
                        <strong>Potongan Korwil</strong>
                      </div>
                      <div className="col-md-6 text-right">
                        <button
                          onClick={() => {
                            $("#form-add-potongan-kowril-wrapper").show("slow");
                          }}
                          type="button"
                          className="btn btn-label-primary btn-sm"
                        >
                          <i className="fas fa-plus"></i>Tambah Potongan Korwil
                        </button>
                      </div>
                    </div>

                    <FormTambahMitraPotonganKorwil
                      setAlerts={setAlerts}
                      koordinatorMperID={id}
                      loadTablePotongan={loadTablePotongan}
                    ></FormTambahMitraPotonganKorwil>

                    <div style={{ overflow: "auto", marginTop: "5px" }}>
                      <table
                        className="display table table-hover table-responsive-md"
                        id="koordinator-potongan-korwil-table"
                        style={{ width: "100%" }}
                      >
                        <thead className="bg-primary" style={{ color: "white" }}>
                          <tr>
                            <th scope="col">Potongan Korwil</th>
                            <th scope="col">Aksi</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <Card.Title>
                  <div className="row">
                    <div className="col-md-6">
                      <h5>Daftar Mitra Anggota</h5>
                    </div>
                    <div className="col-md-6 text-right">
                      <button
                        onClick={() => {
                          $("#form-add-mitra-wrapper").show("slow");
                        }}
                        type="button"
                        className="btn btn-label-primary btn-sm"
                      >
                        <i className="fas fa-plus"></i>Tambah Mitra
                      </button>
                    </div>
                  </div>
                </Card.Title>
                <FormTambahMitra
                  setAlerts={setAlerts}
                  koordinatorMperID={id}
                  loadTableMitra={loadTableMitra}
                ></FormTambahMitra>
                <Row>
                  <Col md>
                    <div style={{ overflow: "auto" }}>
                      <table
                        className="display table table-hover table-responsive-md"
                        id="koordinator-mitra-table"
                        style={{ width: "100%" }}
                      >
                        <thead className="bg-primary" style={{ color: "white" }}>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Foto Mitra</th>
                            <th scope="col">Nama Mitra</th>
                            <th scope="col">Tipe</th>
                            <th scope="col">Aksi</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <br />

            <Row>
              <Col md>
                <button type="button" className="btn btn-warning btn-elevate" onClick={close}>
                  <i class="fas fa-arrow-left"></i>Kembali
                </button>
                <button
                  type="button"
                  class="btn btn-brand btn-elevate pull-right"
                  onClick={SubmitEdit}
                >
                  <i class="fas fa-save"></i>Simpan
                </button>
              </Col>
            </Row>
          </PortletBody>
        </Portlet>
      </Dialog>
    </div>
  );
};

export default DetailKoordinator;
