import React, { useEffect } from "react";
import $ from "jquery";
import API from "../../API";
import "select2";
import DialogHapusBatal from "../Utils/DialogHapusBatal";

const FormTambahPricing = ({ setAlerts, koordinatorMperID, loadTablePricing }) => {
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);

  useEffect(() => {
    $(document).ready(function() {
      $(".mask-currency").mask("#.##0", { reverse: true });
      $("#select-leasing").select2({
        minimumInputLength: 0,
        placeholder: "Pilih leasing",
        dropdownParent: $("#form-add-pricing-wrapper"),
        allowClear: false,
        ajax: {
          type: "get",
          url: API.Userapi + "v1/leasing/index",
          headers: {
            Authorization: "Basic " + localStorage.getItem("jwt"),
          },
          dataType: "json",
          error: function(xhr, error, thrown, settings) {
            API.Handle403(xhr, setAlerts);
          },
          data: function(params) {
            return {
              select2: params.term,
              length: 10,
            };
          },
          processResults: function(response, params) {
            return {
              results: $.map(response.data, function(item) {
                return {
                  text: item.mmle_name,
                  id: item.mmle_id,
                };
              }),
            };
          },
        },
      });
    });
  });

  const getMaskingValue = (value) => {
    console.log(value);
    return value.replace(/\D/g, "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmSubmit(true);
  };

  const Submit = () => {
    var form = new FormData();

    form.append("koordinator_mper_id", koordinatorMperID);
    form.append("mmle_id", $("#select-leasing").val());
    form.append("start_range_price", getMaskingValue($("#input-startRange").val()));
    form.append("end_range_price", getMaskingValue($("#input-endRange").val()));
    form.append("potongan_percent", getMaskingValue($("#input-potonganPercent").val()));
    form.append("potongan_minimal", getMaskingValue($("#input-potonganMinimal").val()));
    form.append("potongan_stnk", getMaskingValue($("#input-potonganSTNK").val()));

    $.ajax({
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/add_pricing",
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    })
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "pricing Telah Ditambahkan",
          },
        ]);
        setAlerts([]);
        setConfirmSubmit(false);
        loadTablePricing();
        // $("#koordinator-pricing-table").ajax.reload(null, false);
        $("#select-leasing").val("");
        $("#input-startRange").val("");
        $("#input-endRange").val("");
        $("#input-potonganPercent").val("");
        $("#input-potonganMinimal").val("");
        $("#input-potonganSTNK").val("");
        $("#form-add-pricing-wrapper").hide("slow");
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          $("#form-add-pricing-wrapper").hide("slow");
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  return (
    <div id="form-add-pricing-wrapper" style={{ display: "none", marginBottom: "10px" }}>
      <DialogHapusBatal
        open={confirmSubmit}
        close={(e) => setConfirmSubmit(false)}
        title="Tambah Pricing Koordinator Wilayah"
        message="Anda Yakin Ingin Menambah Pricing Untuk Koordinator ini ?"
        btn="Tambahkan"
        fun={Submit}
      />
      <div className="row">
        <div className="col-md-2">
          <label className="form-label">Leasing</label>
          <select
            className="form-control"
            required
            id="select-leasing"
            style={{ width: "100%" }}
          ></select>
        </div>
        <div className="col-md-2">
          <label className="form-label">Start Range</label>
          <input type="text" className="form-control mask-currency" id="input-startRange" />
        </div>
        <div className="col-md-2">
          <label className="form-label">End Range</label>
          <input type="text" className="form-control mask-currency" id="input-endRange" />
        </div>
        <div className="col-md-1">
          <label className="form-label">Potongan %</label>
          <input type="text" className="form-control mask-currency" id="input-potonganPercent" />
        </div>
        <div className="col-md-2">
          <label className="form-label">Potongan Minimal</label>
          <input type="text" className="form-control mask-currency" id="input-potonganMinimal" />
        </div>
        <div className="col-md-2">
          <label className="form-label">Potongan STNK</label>
          <input type="text" className="form-control mask-currency" id="input-potonganSTNK" />
        </div>
        <div className="col-md-3" style={{ paddingTop: "25px" }}>
          <button className="btn btn-primary btn-elevate" type="button" onClick={handleSubmit}>
            Tambah
          </button>
          <button
            onClick={() => {
              $("#form-add-pricing-wrapper").hide("slow");
            }}
            className="btn btn-lable-primary"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormTambahPricing;
