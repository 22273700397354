/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React from 'react'
import $ from 'jquery'
import 'datatables.net-bs4'
import API from '../API'
import {AlertList} from 'react-bs-notifier'
import {
   Portlet,
   PortletBody,
   PortletHeader
} from '../../partials/content/Portlet'
import DetailRoleAkses from './Utils/DetailRoleAkses'


export default function RoleAkses() {
   const [open, setOpen] = React.useState(false)
   const [roleId, setRoleId] = React.useState(null)
   const [roleName, setRolename] = React.useState(null)
   const [alerts, setAlerts] = React.useState([])
   
   function handleClickOpen() {
      setOpen(true)
   }
   function handleClose() {
      setOpen(false)
   }
   React.useEffect(() => {
      $(document).ready(function () {
         $('#roleakses').DataTable({
            "pagingType": "full_numbers",
            "lengthMenu": [
               [15, 20, -1],
               [15, 20, "All"]
            ],
            "processing": true,
            "serverSide": true,
            "destroy": true,
            "ajax": {
                "url": API.Userapi + "/v1/master/roleakses",
                "type": 'get',
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts)
                }
            },
            "columns": [
               {"data": "mple_name"},
               {
                  sortable: true,
                  "render": function (data, type, full, meta) {
                     
                      return `
                        <button id="roleakses1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="roleakses1">
                           <button class='dropdown-item' id='beriakses' data-id="${full.mper_type}" data-name="${full.mple_name}" data-toggle='tooltip' title='Reset Password'><i class='fa fa-unlock-alt'></i>Beri Hak Akses</button>
                           
                        </div>`;
                  }
               },
            ]
         })
         $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
         $('#roleakses').off('click').on('click', function() { 
            
            $('#roleakses').on('click', '#beriakses', function() {
               console.log($(this).attr('data-id'))
               handleClickOpen()
               setRoleId($(this).attr('data-id'))
               setRolename($(this).attr('data-name'))
            })
         })
      })
   })
   return (
      <>
         <DetailRoleAkses open={open} close={handleClose} id={roleId} name={roleName}/>
         <Portlet>
         <AlertList  showIcon={false} alerts={alerts}/>  
            <PortletHeader title="" />
         
            <PortletBody>
                  <table className="display table table-hover table-responsive-md" id="roleakses" style={{width:'100%'}}>
                     <thead className="bg-primary" style={{color:'white'}}>
                           <tr>
                              <th scope="col">Nama Role User</th>
                              <th scope="col">Aksi</th>
                           </tr>
                     </thead>
                  </table>
            </PortletBody>
         </Portlet>
      </>
   )
}