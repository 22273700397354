import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import {
    Portlet,
    PortletHeader,
    PortletBody,

  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API';
import { AlertList} from "react-bs-notifier";
import {getPosNum} from '../../../functions/invoice'; //formula invoice

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'fixed',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    detail: {
      offset : theme.mixins.toolbar,
      marginTop : '70px'
    } 
}));
const formatter = new Intl.NumberFormat('currency');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function HistoryPembayaranInvoice(props) {
    const classes = useStyles();
    const [alerts,setAlerts] = React.useState([]);

      React.useEffect(() => {
        $(document).ready(function() {
          if(props.open == true && props.id != null) {
            HistoryPembayaran()
          }

          $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
        })
      });

      const HistoryPembayaran = () => {
        $('#historyInvoice').DataTable({
           
          //  dom: "<'row'<'col-sm-12 col-md-5'l><'col-sm-12 col-md-7'Bf>>" +
          //     "<'row'<'col-sm-12'tr>>" +
          //     "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
          //  buttons: [
          //   { 
          //     extend: 'excel', 
          //     className: 'btn btn-primary mt-4', 
          //     text:'<i class="fas fa-file-excel"></i>Export Excel', 
          //     titleAttr: "Export Excel" 
          //   },
          //  ],
          "pagingType": "full_numbers",
          //"aaSorting": [],
          "processing": true,
          "serverSide" : true,
          "destroy": true,
          "ajax": {
            "url" :  API.Financeapi + "v1/Invoice/historyPembayaran/"+props.id,
            "type" : 'get',
            "beforeSend": function (xhr) {
              xhr.setRequestHeader('Authorization',
                "Basic " + localStorage.getItem('jwt'));
            },
            error: function (xhr, error, thrown, settings) {
               API.Handle403(xhr,setAlerts)
            }
          },
          "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          },
          "columns": [
            {
              "data": "log_customer_name"
            },
            {
              "data": "log_date_paid"
            },
            {
              "data": "log_created_time"
            },
            {
              sortable: true,
              "render": function(data, type, full, meta) {
                return full.invc_total != null ? formatter.format(full.invc_total) : 0
              }
            },
            {
              sortable: true,
              "render": function(data, type, full, meta) {
                var terbayar = full.log_paid != null ? formatter.format(full.log_paid) : 0
                return terbayar
              }
            },
            {
              sortable: true,
              "render": function(data, type, full, meta) {
                var sisa = full.log_outstanding > 0 ? full.log_outstanding : 0
                return formatter.format(sisa)
              }
            },
            {
              sortable: true,
              "render": function(data, type, full, meta) {
                var lebih = ( full.log_outstanding < 0 ) ? getPosNum(full.log_outstanding) : 0
                return formatter.format(lebih)
              }
            },
            {
              "data": "log_note_msg"
            },
            {
              sortable: true,
              "render": function (data, type, full, meta) {
                var status = full.log_updated_flag
                return status == 1 ? 'Update Data Invoice' : (status == null ? '' : '???');
              }
            },
          ]
        });
      }

    return (
      <>
        <AlertList  showIcon={false} alerts={alerts}/> 
        <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    History Pembayaran Cicilan Invoice
                </Typography>
                <IconButton color="inherit" onClick={props.close} aria-label="Close">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            </AppBar>
            <TabPanel value={0} index={0}>
                <Portlet className={classes.detail} style={{marginTop:'20px'}}>
                    <PortletHeader title="" />
                    <PortletBody>
                      <Row>
                        <Col md={12}>
                            <table className="table table-hover table-responsive-md" id="historyInvoice" style={{width:'100%'}}>
                              <thead className="bg-primary" style={{color:'white'}}>
                                  <tr>
                                    <th scope="col">Debitur</th>
                                    <th scope="col">Tanggal Pembayaran</th>
                                    <th scope="col">Jam</th>
                                    <th scope="col">Total Tagihan</th>
                                    <th scope="col">Terbayar</th>
                                    <th scope="col">Kekurangan Bayar</th>
                                    <th scope="col">Kelebihan Bayar</th>
                                    <th scope="col">Note</th>
                                    <th scope="col">Update</th>
                                  </tr>
                              </thead>
                            </table>
                        </Col>
                      </Row>
                    </PortletBody>
                </Portlet>
            </TabPanel>
        </Dialog>
      </>
    )
}