/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react';
import $ from 'jquery';
import 'jquery'
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
  } from "../../partials/content/Portlet";
import Grid from '@material-ui/core/Grid';
import API from '../API'
import moment from 'moment'
import { AlertList} from "react-bs-notifier";
import 'select2'
  
export default function Lokasi() {

    const [alerts, setAlerts] = React.useState([]);

     useEffect(() => {
         $(document).ready(function () {
            $(function() {
                $('#vldfrom,#vldto').datepicker({
                    todayHighlight: true,
                    autoclose: true,
                    format: "yyyy-mm-dd",
                  })
              });
            $('#vldfrom').val(moment().format('YYYY-MM-DD'))
            $('#vldto').val(moment().format('YYYY-MM-DD'))
             $('#user').select2({
                minimumInputLength: 0,
                ajax: {
                    type: 'get',
                    url: API.Userapi +'v1/user/user',
                    dataType: "json",
                    headers: {
                        "Authorization": "Baisc "+localStorage.getItem('jwt')
                    },
                    error: function (xhr, error, thrown , settings) {
                        API.Handle403(xhr,setAlerts)
                    },
                    data: function (params) {
                        return {
                            "select2" : params.term,
                            "length": "All",
                        };
                    },
                    processResults: function (data, params) {
                      console.log(data)
                        return {
                            results: $.map(data.data, function (item) {
                                return {
                                    text: item.mper_name,
                                    id: item.mper_id,
                                };
                            })
                        };
                    }
                }
              });
         });

     },[setAlerts])

     function FilterLokasi() {
        if ($('#vldfrom').val() == 0 || $('#vldto').val() == 0) {
            return 0;
        }
        var from = moment($('#vldfrom').val()).format('MM-DD-YYYY')
        var to = moment($('#vldto').val()).format('MM-DD-YYYY')
        var mper_id = $( "#user option:selected" ).val();

        var url
        if (from != 'Invalid date' && to != 'Invalid date') {
            url = API.Logapi + "v1/log/index/"+mper_id+"?from=" + from + "&to=" + to;
        } else {
            url = API.Logapi + "v1/Voucher_lunas/index";
        }
        $('#lokasi-table').DataTable({
            "pagingType": "full_numbers",
            "bFilter": false,
            "processing": true,
            "serverSide": true,
            "destroy": true,
            "ajax": {
                "url": url,
                "type": 'get',
                "beforeSend": function (xhr) {
                    xhr.setRequestHeader('Authorization',
                        "Basic " + localStorage.getItem('jwt'));
                },
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts)
                }
            },
            "columns": [{
                    "data": "lloc_created_date"
                },
                {
                    "data": "lloc_created_time"
                },
                {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                        return full.mper_id;
                        // return "AKSI"
                    }
                },
                {
                    "data": "mper_name"
                },
                {
                    sortable: true,
                    "render": function (data, type, full, meta) {
                        return '<a href="https://www.google.com/maps/?q=' + full.lloc_lat + ',' + full.lloc_lng + '" ' +
                            ' class="btn btn-outline-brand btn-icon btn-sm" data-container="body" target="_blank" data-placement="top" data-original-title="Lihat Lokasi"><i class="fa fa-map-marker"></i></a>';
                        // return "AKSI"
                    }
                },
            ]
        });
        $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
     }

    return(
        <>  
            <AlertList  showIcon={false} alerts={alerts}/>            
            <Portlet>
                <PortletHeader title="Filter"/>
                <PortletBody>
                <Grid container spacing={2}>
                    <Grid item xs>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                                <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                                    <input type="text" className="form-control" name="start" id="vldfrom" autocomplete="off"/>
                                    <div className="input-group-append">
                                        <span className="input-group-text">To</span>
                                    </div>
                                    <input type="text" className="form-control" name="end" id="vldto" autocomplete="off"/>
                                </div>
                                <div className="form-group mb-2" style={{width:'100%'}}>
                                    <select name="state" id="user" style={{width:'100%'}} required><option value="0" selected disabled>Pilih Nama Mitra</option></select>
                                </div>
                                <button type="submit" class="btn btn-brand btn-elevate btn-sm" onClick={FilterLokasi} id="cari" style={{width:'100%'}}>Set Filter</button>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                    </Grid>
                </Grid>
                </PortletBody>
            </Portlet>
            <Portlet>
                <PortletHeader title="Data Lokasi" />
                <PortletBody>
                    <table className="table table-hover table-responsive-md" id="lokasi-table" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                    <th scope="col">Tanggal</th>
                                    <th scope="col">Waktu</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nama Mitra</th>
                                    <th scope="col">Lokasi</th>
                                </tr>
                            </thead>
                    </table>
                </PortletBody>
            </Portlet>
        </>
    )
}