/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React, {useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../API'
import DialogVersion from './Utils/DialogVersion'
import DialogHapusBatal from './Utils/DialogHapusBatal'
import { AlertList} from "react-bs-notifier";
import {
Portlet,
PortletBody,
PortletHeader,
PortletHeaderToolbar
} from "../../partials/content/Portlet";

export default function User() {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [id, setId] = React.useState('');
    const [alerts, setAlerts] = React.useState([]);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    
    function handleClickOpen2() {
        setOpen2(true);
    }

    function handleClose2() {
        setOpen2(false);
    }

    function HapusVersion() {
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Authapi + "/v1/version/del/" + id,
            "method": "GET",
            "headers": {
                "Authorization": "Baisc " + localStorage.getItem('jwt')
            }
        }

        $.ajax(settings).done(function (response) {
            handleClose2()
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Version Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        }).fail(function (response) {
            console.log(response)
            handleClose2()
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            return 0
        })
    }

     useEffect(() => {
         $(document).ready(function () {
             $('#version').DataTable({
                 "pagingType": "full_numbers",
                 "processing": true,
                 "serverSide": true,
                 "destroy": true,
                 "ajax": {
                     "url": API.Authapi + "/v1/version/list",
                     "type": 'get',
                     "beforeSend": function (xhr) {
                         xhr.setRequestHeader('Authorization',
                             "Basic " + localStorage.getItem('jwt'));
                     },
                     error: function (xhr, error, thrown, settings) {
                         //   errorData(xhr);
                     }
                 },
                 "columns": [{
                         "data": "vrsn_created_date"
                     },
                     {
                         "data": "vrsn_created_time"
                     },
                     {
                         "data": "vrsn_name"
                     },
                     {
                         "data": "vrsn_is_force"
                     },
                     {
                         "data": "vrsn_type"
                     },
                     {
                         sortable: true,
                         "render": function (data, type, full, meta) {
                             return "<div id='aksi'>" +
                                 "<button class='btn btn-outline-brand btn-icon btn-sm hapus tooltips' data-toggle='tooltip' data-placement='top' title='Tooltip on top' id='hapus' data-id=" + full.vrsn_id +
                                 " data-original-title='Hapus User'><i class='fa fa-trash'> </i></button>" +
                                 "</div>";
                         }
                     },
                 ]
             });

             $(document).on('click', '#hapus', function () {
                 handleClickOpen2()
                 setId($(this).attr('data-id'))
             })

             $('.dataTables_filter,.dataTables_paginate').addClass('pull-right').css('float', 'right')
         })
     })

    return(
        <>
            <AlertList  showIcon={false} alerts={alerts}/>
            <DialogVersion open={open} close={handleClose} title="Tambah Version"/>
            <DialogHapusBatal 
                open={open2} 
                close={handleClose2} 
                title="Hapus Version" 
                message="Anda Yakin Ingin Hapus Version Ini ?" 
                btn="Hapus"
                fun={HapusVersion} 
                // type="delete" 
                // id={id}
            />
            <Portlet>
                <PortletHeader title="" toolbar={( <PortletHeaderToolbar>
                    <button className="btn btn-label-primary" onClick={handleClickOpen}>
                        <i class="fas fa-plus"></i>Tambah Version
                    </button>
                    </PortletHeaderToolbar>
                    )}
                    >
                </PortletHeader>
                <PortletBody>
                    <table className="display table table-hover table-responsive-md" id="version" style={{width:'100%'}}>
                        <thead className="bg-primary" style={{color:'white'}}>
                            <tr>
                                <th scope="col">Tanggal Pembuatan</th>
                                <th scope="col">Jam</th>
                                <th scope="col">Version</th>
                                <th scope="col">Is Force</th>
                                <th scope="col">Tipe</th>
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                    </table>
                </PortletBody>
            </Portlet>
        </>
    )
}