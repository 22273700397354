import React, { useEffect, useState } from "react";

import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "jquery-mask-plugin";
import "bootstrap-datepicker";
import $ from "jquery";
import API from "../../API";

const getMaskingValue = (id) => {
  return $(id)
    .val()
    .replace(/\D/g, "");
};

const EditLaporanBiayaKirim = (props) => {
  const [loading, setLoading] = useState(false);
  const formatter = new Intl.NumberFormat("de-DE");

  useEffect(() => {
    $(document).ready(function() {
      $(".mask-currency").mask("#.##0", { reverse: true });
      $(".date-picker").datepicker({
        todayHighlight: true,
        autoclose: true,
        format: "yyyy-mm-dd",
      });
      if (props.data !== null && props.data !== undefined) {
        console.log(props.data);
        $('#form-edit-biaya-kirim [name="biaya_kirim"]').val(
          formatter.format(props.data.tvoc_biaya_kirim_real)
        );
        $('#form-edit-biaya-kirim [name="tanggal_kirim"]').val(props.data.tvoc_tanggal_kirim);
        $('#form-edit-biaya-kirim [name="status"]').val(props.data.tvoc_status_kirim);
      }
    });
  }, [props.data]);

  const handleSubmit = () => {
    var url = `${API.Financeapi}/v1/BiayaKirim/update_biaya_kirim/${props.data.tvoc_id}`;
    var data = {
      tvoc_biaya_kirim_real: getMaskingValue("#form-edit-biaya-kirim [name='biaya_kirim']"),
      tvoc_tanggal_kirim: $("#form-edit-biaya-kirim [name='tanggal_kirim']").val(),
      tvoc_status_kirim: $("#form-edit-biaya-kirim [name='status']").val(),
    };
    $.ajax({
      async: true,
      crossDomain: true,
      url: url,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      data: data,
    })
      .done(function(response) {
        props.close();
        props.setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Biaya Kirim Telah Diubah",
          },
        ]);
        props.updateDatatable();
        setTimeout(() => {
          props.setAlerts([]);
        }, 3000);
      })
      .fail(function(response) {
        props.setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          props.setAlerts([]);
        }, 3000);
        API.Handle403(response, props.setAlerts);
      });
  };

  return (
    <>
      <Dialog open={props.open} fullWidth={true}>
        <DialogActions>
          <DialogTitle>Edit Laporan Biaya Kirim</DialogTitle>
          <div style={{ width: "100%" }}></div>
          <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <form id="form-edit-biaya-kirim" onSubmit={(e) => e.preventDefault()}>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Biaya Kirim</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control mask-currency"
                  name="biaya_kirim"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Tanggal Kirim</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control date-picker"
                  name="tanggal_kirim"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Status</label>
              <div className="col-sm-8">
                <select className="form-control" name="status">
                  <option value="IN TRANSIT">IN TRANSIT</option>
                  <option value="SIAP TAGIH">SIAP TAGIH</option>
                </select>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              $("#form-edit-biaya-kirim")[0].reset();
              props.close();
            }}
            color="primary"
          >
            Batal
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditLaporanBiayaKirim;
