/* eslint-disable eqeqeq */

import React, {useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
  } from "../../partials/content/Portlet";
// eslint-disable-next-line no-restricted-imports
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import API from '../API'
import Utils from './Utils/FunctionDetail'
import { AlertList} from "react-bs-notifier";

export default function HasilTerupload() {

   const [alerts, setAlerts] = React.useState([]);

     useEffect(() => {
        $(document).ready(function () {            
            $(function() {
                $('#vldfrom,#vldto').datepicker({
                  todayHighlight: true,
                  autoclose: true,
                  format: "yyyy-mm-dd",
                })
            });
            $('#vldfrom').val(moment().format('YYYY-MM-DD'))
            $('#vldto').val(moment().format('YYYY-MM-DD'))
        })
     })

     function FilterTerupload() {
        if( $('#vldfrom').val() == 0 || $('#vldto').val() == 0){
            return 0 ;
        }
        var from = moment($('#vldfrom').val()).format('MM-DD-YYYY')
        var to = moment($('#vldto').val()).format('MM-DD-YYYY')
        var url
        if( from != 'Invalid date' && to != 'Invalid date' ){
            url = API.Uploadapi+"v1/Hasil_data_terupload/index?from="+from+"&to="+to;
          }else{
            url = API.Uploadapi+"v1/Hasil_data_terupload/index";
          }
        $('#hasil_upload').DataTable( {
            "pagingType": "full_numbers",
            "processing": true,
            "serverSide": true,
            "destroy" : true,
            "ajax": {
              "url": url,
              "type": 'get',
              "beforeSend": function (xhr) {
                  xhr.setRequestHeader('Authorization',
                      "Basic " + localStorage.getItem('jwt'));
            },
            error: function (xhr, error, thrown , settings) {
              API.Handle403(xhr,setAlerts)
            }
            },
            "fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
                if ( aData.thbd_status == 1  ){ // Uploaded
                  $('td:eq(7)', nRow).css('background-color', '#81D4FA');
                }else if ( aData.thbd_status == 2  ){ // Approved by Leasing Admin
                  $('td:eq(7)', nRow).css('background-color', '#FFE800');
                }else if ( aData.thbd_status == 3  ){ // Rejected by Leasing Admin
                  $('td:eq(7)', nRow).css('background-color', '#FD333F');
                }else if ( aData.thbd_status == 4  ){ // Approve by BETA
                  $('td:eq(7)', nRow).css('background-color', '#6CFF00');
                }else if ( aData.thbd_status == 5  ){ // Reject By BETA
                  $('td:eq(7)', nRow).css('background-color', '#FD333F');
                }
            },
            "columns": [
                { "data": "thbd_updated_date" },
                { "data": "thbd_updated_time" },
                { "data": "mmle_name" },
                { "data": "mmle_pic_name1" },
                { "data": "mmle_pic_phone1" },
                { "data": "thbd_count" },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                      return "<div id='status'>"+Utils.TypeUpload(full.thbd_type)+"</div>";
                    }
                },
                {
                    sortable: true,
                    "render": function ( data, type, full, meta ) {
                      return "<div id='status'>"+Utils.StatusUpload(full.thbd_status)+"</div>";
                    }
                },
            ]
        });
        $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
     }

    return(
        <>              
            <AlertList  showIcon={false} alerts={alerts}/>
            <Portlet>
            <PortletHeader
                title="Filter Tanggal"
            >
            </PortletHeader>
                <PortletBody>
                <Grid container spacing={2}>
                    <Grid item xs>
                    </Grid>
                    <Grid item xs={6} className="text-center">
                        <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                                            <input type="text" className="form-control" name="start" id="vldfrom" autocomplete="off"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">To</span>
                                            </div>
                                            <input type="text" className="form-control" name="end" id="vldto" autocomplete="off"/>
                                        </div>
                        <button type="button" class="btn btn-brand btn-elevate btn-sm" id="cari" onClick={FilterTerupload} style={{width:'100%'}}>Set Filter</button>
                    </Grid>
                    <Grid item xs>
                    </Grid>
                </Grid>
                </PortletBody>
            </Portlet>
            <Portlet>
                <PortletHeader title="Data Remedial/Repossession"/>
                <PortletBody>
                    <table className="table table-hover table-responsive-md" id="hasil_upload" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                <th scope="col">Tanggal Diupload</th>
                                <th scope="col">Jam Diupload</th>
                                <th scope="col">Nama Leasing</th>
                                <th scope="col">Nama PIC</th>
                                <th scope="col">No HP PIC</th>
                                <th scope="col">Jumlah Data</th>
                                <th scope="col">Tipe Upload</th>
                                <th scope="col">Status</th>
                                </tr>
                            </thead>
                    </table>
                    <div>
                      <button type="button" class="btn btn-default" style={{background:"#6CFF00"}}></button><span style={{color:"black"}}> : Approved  </span>
                      <button type="button" class="btn btn-default" style={{background:"#FD333F"}}></button><span style={{color:"black"}}> : Rejected  </span>
                    </div>
                </PortletBody>
            </Portlet>
        </>
    )
}