/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, {
    useEffect
} from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
} from "../../partials/content/Portlet";
import API from '../API'
import DialogReason from './Utils/DialogReason'
import Thbdtype from './Utils/FunctionDetail'
import { AlertList} from "react-bs-notifier";

export default function HasilDataApprover() {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [alerts, setAlerts] = React.useState([]);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    useEffect(() => {
        $(document).ready(function () {
            $('#data_terupload').DataTable({
                "pagingType": "full_numbers",
                "processing": true,
                "serverSide": true,
                "destroy": true,
                "ajax": {
                    "url": API.Uploadapi + "v1/Hasil_data_terupload/listTeruploadApprover",
                    "type": 'get',
                    "beforeSend": function (xhr) {
                        xhr.setRequestHeader('Authorization',
                            "Basic " + localStorage.getItem('jwt'));
                    },
                    error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts)
                    }
                },
                "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    if (aData.thbd_status == 1) { // Uploaded
                        $('td:eq(7)', nRow).css('background-color', '#81D4FA');
                    } else if (aData.thbd_status == 2) { // Approved by Leasing Admin
                        $('td:eq(7)', nRow).css('background-color', '#FFE800');
                    } else if (aData.thbd_status == 3) { // Rejected by Leasing Admin
                        $('td:eq(7)', nRow).css('background-color', '#FD333F');
                    } else if (aData.thbd_status == 4) { // Approve by BETA
                        $('td:eq(7)', nRow).css('background-color', '#6CFF00');
                    } else if (aData.thbd_status == 5) { // Reject By BETA
                        $('td:eq(7)', nRow).css('background-color', '#FD333F');
                    }
                },
                "columns": [{
                        "data": "thbd_created_date"
                    },
                    {
                        "data": "thbd_created_time"
                    },
                    {
                        "data": "mmle_name"
                    },
                    {
                        "data": "mmle_pic_name1"
                    },
                    {
                        "data": "mmle_pic_phone1"
                    },
                    {
                        "data": "thbd_count"
                    },
                    {
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                            return " <div >"+Thbdtype.TypeUpload(full.thbd_type)+"</div>";
                        }
                    },
                    {
                        sortable: true,
                        "render": function ( data, type, full, meta ) {
                          return "<div id='status'>"+Thbdtype.StatusUpload(full.thbd_status)+"</div>";
                        }
                    },
                ]
            });
            $(document).on('click','#edit',function(){
                handleClickOpen2()
            })
            $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
        })
    })
    return(
        <>
            <AlertList showIcon={false} alerts={alerts}/>
            <DialogReason open={open} close={handleClose} title={"Tambah Reason Batal"} nego="none"/>
            <DialogReason open={open2} close={handleClose2} title={"Edit Reason Batal"} button="Edit" nego="none"/>
            <Portlet>
                <PortletHeader title="Data Terupload" />
                <PortletBody>
                    <table className="table table-hover table-responsive-md" id="data_terupload" style={{width:'100%'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr>
                                    <th scope="col">Tanggal Diupload</th>
                                    <th scope="col">Jam Diupload</th>
                                    <th scope="col">Nama Leasing</th>
                                    <th scope="col">Nama PIC 1</th>
                                    <th scope="col">No Handphone PIC 1</th>
                                    <th scope="col">Jumlah Data</th>
                                    <th scope="col">Tipe Upload</th>
                                    <th scope="col">Status Upload</th>
                                </tr>
                            </thead>
                    </table>
                    <div>
                      {/* <button type="button" class="btn btn-default" style={{background:"#81D4FA"}}></button><span style={{color:"black"}}> : Uploaded  </span> */}
                      <button type="button" class="btn btn-default" style={{background:"#FFE800"}}></button><span style={{color:"black"}}> : Approved by Leasing Admin  </span>
                      <button type="button" class="btn btn-default" style={{background:"#6CFF00"}}></button><span style={{color:"black"}}> : Approve by BETA  </span>
                      <button type="button" class="btn btn-default" style={{background:"#FD333F"}}></button><span style={{color:"black"}}> : Rejected by Leasing Admin / by BETA  </span>
                    </div>
                </PortletBody>
            </Portlet>
        </>
    )
}