/* eslint-disable no-restricted-imports */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import API from "../../API";
import { Link } from "react-router-dom";
import { AlertList } from "react-bs-notifier";
import NoImage from "../Leasing/noimage.jpg";
import DetailKoordinator from "./DetailKoordinator";
import DialogHapusBatal from "../Utils/DialogHapusBatal";

export default function User() {
  const [id, setId] = React.useState(null);
  const [alerts, setAlerts] = React.useState([]);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [openConfirmHapusKoordinator, setOpenConfirmHapusKoordinator] = React.useState(false);
  var dttable;

  const initPage = () => {
    $(document).ready(function() {
      if ($.fn.DataTable.isDataTable("#koordinator-table")) {
        $("#koordinator-table")
          .DataTable()
          .clear()
          .destroy();
      }
      dttable = $("#koordinator-table").DataTable({
        pageLength: 10,
        processing: true,
        serverSide: true,
        ajax: {
          url: API.Userapi + "v1/koordinator",
          type: "get",
          beforeSend: function(xhr) {
            xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
          },
          error: function(xhr, error, thrown, settings) {
            API.Handle403(xhr, setAlerts);
          },
        },
        columns: [
          {
            data: "mper_id",
          },
          {
            sortable: true,
            render: function(data, type, full, meta) {
              if (full.mper_medi_profile == null) {
                return "<img src='" + NoImage + "' style='height:70px;weight:50px;'>";
              } else {
                return (
                  "<a class='fancybox' rel='group' data-lightbox='galery' data-title='' href=" +
                  full.mper_medi_profile +
                  ">" +
                  "<img src=" +
                  full.mper_medi_profile +
                  " style='height:70px;width:70px;border:1px solid #c5c8f0;border-radius:10px;'>" +
                  "</a>"
                );
              }
            },
          },
          {
            data: "mper_name",
          },
          {
            data: "mper_address",
          },
          {
            data: "transit_pool",
          },
          {
            data: "nama_wilayah",
          },
          // {
          //   data: "cadangan_korwil",
          // },
          {
            render: function(data, type, full) {
              return `
              <button id="user1" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="user1">

                  <button class='dropdown-item btn-detail-koordinator' id='detail' data-id="${full.mper_id}" data-toggle='tooltip' title='Edit Koordinator'><i class='fa fa-eye'></i>Edit Koordinator</button>
                  <button class='dropdown-item btn-hapus-koordinator' id='hapus' data-id="${full.mper_id}" data-toggle='tooltip' title='Hapus Koordinator'><i class='fa fa-trash'></i>Hapus Koordinator</button>
              </div>`;
            },
          },
        ],
      });

      $("#koordinator-table").on("click", ".btn-hapus-koordinator", function() {
        setId($(this).attr("data-id"));
        setOpenConfirmHapusKoordinator(true);
      });

      $("#koordinator-table").on("click", ".btn-detail-koordinator", function() {
        setId($(this).attr("data-id"));
        setOpenDetail(true);
      });

      $(".dataTables_filter,.dataTables_paginate")
        .addClass("pull-right")
        .css("float", "right");
    });
  };

  useEffect(() => {
    initPage();
  }, []);

  const deleteKoordinator = () => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/delete/" + id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };
    $.ajax(settings)
      .done(function(response) {
        setOpenConfirmHapusKoordinator(false);
        setId(null);
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Koordinator Telah Dihapus",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setOpenConfirmHapusKoordinator(false);
        setId(null);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  return (
    <>
      <AlertList showIcon={false} alerts={alerts} />
      <DetailKoordinator open={openDetail} close={() => setOpenDetail(false)} id={id} />
      <DialogHapusBatal
        open={openConfirmHapusKoordinator}
        close={() => setOpenConfirmHapusKoordinator(false)}
        title="Hapus Koordinator"
        message="Anda Yakin Ingin Hapus Koordinator Ini ?"
        btn="Delete"
        fun={deleteKoordinator}
      />
      <Portlet>
        <PortletHeader
          title="Data Koordinator Wilayah"
          toolbar={
            <PortletHeaderToolbar>
              <Link to="/tambahkoordinator" className="btn btn-label-primary">
                <i className="fas fa-user-plus"></i>Tambah Koordinator
              </Link>
            </PortletHeaderToolbar>
          }
        ></PortletHeader>
        <PortletBody>
          <div style={{ overflow: "auto" }}>
            <table
              className="display table table-hover table-responsive-md"
              id="koordinator-table"
              style={{ width: "100%" }}
            >
              <thead className="bg-primary" style={{ color: "white" }}>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Foto Korwil</th>
                  <th scope="col">Nama Korwil</th>
                  <th scope="col">Alamat Korwil</th>
                  <th scope="col">Nama Transit Pool</th>
                  <th scope="col">Nama Wilayah</th>
                  {/* <th scope="col">Cadangan Korwil</th> */}
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
            </table>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}
