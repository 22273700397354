/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Row, Col} from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import $ from 'jquery'
import API from '../../API'

const formatter = new Intl.NumberFormat('de-DE');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '90%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
}));

export default function DetailBonusLunas(props) {
    const classes = useStyles();

    React.useEffect(()=>{
      var getBayarVouhcer = {
        "async": true,
        "crossDomain": true,
        "url": API.Financeapi+"v1/VoucherTranskasi/getBayarVouhcer/"+props.id,
        "method": "GET",
        "headers": {
          "Authorization": "Baisc "+localStorage.getItem('jwt')
        }
      }
        if(props.id != null ) {
          $.ajax(getBayarVouhcer).done(function (response) {
            console.log(response)
            // $('#lvoc_admin_bank_2').val(response.data.tvoc_account_number)
            $('#lvoc_bank_2').val(response.data.lvoc_admin_bank)
            $('#tvoc_total_fee_2').val(formatter.format(response.data.lvoc_admin_tranfer-response.data.tvoc_fee_extra))
            $('#lvoc_fee_antar_bank_2').val(response.data.tvoc_fee_extra)
              if( response.data.tvoc_fee_extra != 0 ){
                $('#check_lvoc_fee_antar_bank_2').prop( "checked", true );
              }else{
                $('#check_lvoc_fee_antar_bank_2').prop( "checked", false );
              }
            $('#tvoc_total_transfer_2').val(formatter.format(response.data.lvoc_admin_tranfer))
            $('#lvoc_note_2').val(response.data.lvoc_note)
            $('#tvoc_account_name_2').val(response.data.tvoc_account_name)
            $('#tvoc_bank_name_2').val(response.data.tvoc_bank_name)
            $('#tvoc_account_number_2').val(response.data.tvoc_account_number)
          })
        }
    })

  return (
    <div>
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.close}
        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" fullWidth={true}
        maxWidth="md">
        <DialogActions>
          <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <div style={{width:'100%'}}></div>
          <IconButton edge="start" color="inherit" onClick={props.close} aria-label="Close">
                  <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <Row>
              <Col md={6}>
                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-5 col-form-label">Rekening Perusahaan</label>
                  <div class="col-sm-7">
                    <select className="form-control" id="lvoc_admin_bank_2" disabled>
                        {/* <option value="0"></option> */}
                        <option value="6241889898" selected disabled>- 6241889898 - BCA -</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:'-15px'}}>
                  <label for="inputEmail3" class="col-sm-5 col-form-label">Bank Perusahaan</label>
                  <div class="col-sm-7">
                    <input type='hidden' id='tbnk_id_2' name='tbnk_id' value='0' />
                    <input type="text" class="form-control" id="lvoc_bank_2" value='BCA' disabled/>
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:'-15px'}}>
                  <label for="inputEmail3" class="col-sm-5 col-form-label">Total Fee</label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" id="tvoc_total_fee_2" disabled/>
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:'-15px'}}>
                  <label for="inputEmail3" class="col-sm-5 col-form-label">Fee Antar Bank</label>
                  <div class="col-sm-3">
                    <input type="text" class="form-control" id="lvoc_fee_antar_bank_2" disabled/>
                  </div>
                    <div class="form-group form-check col-sm-4 pt-2">
                      <input type="checkbox" class="form-check-input" id="check_lvoc_fee_antar_bank_2" disabled/>
                      <label class="form-check-label" for="exampleCheck1">Fee Antar Bank</label>
                    </div>
                </div>
                <div class="form-group row" style={{marginTop:'-28px'}}>
                  <label for="inputEmail3" class="col-sm-5 col-form-label">Total Transfer</label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" id="tvoc_total_transfer_2" disabled/>
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:'-15px'}}>
                  <label for="inputEmail3" class="col-sm-5 col-form-label">Note</label>
                  <div class="col-sm-7">
                    <textarea type="text" class="form-control" id="lvoc_note_2" placeholder="Noted" disabled/>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Nama</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="tvoc_account_name_2" disabled/>
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:'-15px'}}>
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Nama Bank</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="tvoc_bank_name_2" disabled/>
                  </div>
                </div>
                <div class="form-group row" style={{marginTop:'-15px'}}>
                  <label for="inputEmail3" class="col-sm-4 col-form-label">Nomor Rekening</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="tvoc_account_number_2" disabled/>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
          <button type="button" className="btn btn-primary btn-elevate" onClick={props.close} style={{width:'100%',color:'white',fontWeight:'400'}}>Ok</button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );

}

