import React, { useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  // PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { Row, Col } from "react-bootstrap";
import "jquery-mask-plugin";
import $ from "jquery";
import API from "../../API";

const DetailPricingVoucher = (props) => {
  const formatter = new Intl.NumberFormat("de-DE");
  const formatCurrency = (value) => {
    if (value === null) return 0;
    return formatter.format(parseInt(value));
  };

  useEffect(() => {
    if (props.tpnm_id !== null) {
      var tvocpricing = {
        async: true,
        crossDomain: true,
        url:
          API.Financeapi +
          "v1/Voucher_verifikasi/getTvocPricing/" +
          props.tvoc_id +
          "/" +
          props.tpnm_id,
        method: "GET",
        headers: {
          Authorization: "Basic " + localStorage.getItem("jwt"),
        },
      };
      $.ajax(tvocpricing)
        .done(function(response) {
          console.log(response);
          $("#pricing_voucher_price_tpnm_base_price").val(
            formatCurrency(response.data.tpnm_base_price)
          );
          $("#pricing_voucher_price_pendapatan_lain_lain").val(
            formatCurrency(response.data.pendapatan_lain_lain)
          );
          $("#pricing_voucher_price_tpnm_bp_stnk").val(
            formatCurrency(response.data.pricing_bp_stnk)
          );
          $("#pricing_voucher_potongan_unit").val(
            formatCurrency(response.data.tpnm_base_price - response.data.tvoc_ammount)
          );
          $("#pricing_voucher_potongan_stnk").val(
            formatCurrency(response.data.pricing_bp_stnk - response.data.tvoc_fee_stnk)
          );
          $("#pricing_voucher_cadangan_korwil").val(formatCurrency(response.data.cadangan_korwil));
          $("#pricing_voucher_biaya_kirim").val(
            formatCurrency(response.data.tvoc_biaya_kirim_estimasi)
          );
          $("#pricing_voucher_price_tpnm_fee_mitra").val(
            formatCurrency(response.data.tvoc_ammount)
          );
          $("#pricing_voucher_price_tpnm_fee_stnk").val(
            formatCurrency(response.data.tvoc_fee_stnk)
          );
          $("#pricing_voucher_transit_pool").val(response.data.transit_pool);
          $("#pricing_voucher_pic_tagih").val(response.data.pic_tagih);
        })
        .fail(function(response) {
          API.Handle403(response, props.setAlerts);
          return 0;
        });
    }
  });

  return (
    <Portlet id="pricing_voucher">
      <PortletHeader title="Pricing Voucher"></PortletHeader>
      <PortletBody>
        <Row>
          <Col md={6}>
            <div className="form-group row" style={{ marginBottom: "1rem" }}>
              <label className="col-sm-5 col-form-label">
                Fee Unit Leasing <span style={{ color: "red" }}>(Base Price)</span>
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control mask-currency"
                  id="pricing_voucher_price_tpnm_base_price"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginBottom: "1rem" }}>
              <label className="col-sm-5 col-form-label">
                Fee STNK Leasing <span style={{ color: "red" }}>(BP stnk)</span>
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control mask-currency"
                  id="pricing_voucher_price_tpnm_bp_stnk"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginBottom: "1rem" }}>
              <label className="col-sm-5 col-form-label">Potongan Unit</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control mask-currency"
                  id="pricing_voucher_potongan_unit"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginBottom: "1rem" }}>
              <label className="col-sm-5 col-form-label">Potongan STNK</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control mask-currency"
                  id="pricing_voucher_potongan_stnk"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginBottom: "1rem" }}>
              <label className="col-sm-5 col-form-label">Cadangan Korwil</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control mask-currency"
                  id="pricing_voucher_cadangan_korwil"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginBottom: "1rem" }}>
              <label className="col-sm-5 col-form-label">Biaya Kirim</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control mask-currency"
                  id="pricing_voucher_biaya_kirim"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div
              className="form-group row"
              style={{ marginBottom: "1rem" }}
              id="pricing_voucher_intransit-wrapper"
            >
              <label className="col-sm-5 col-form-label">Pendapatan Lain Lain</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control mask-currency"
                  id="pricing_voucher_price_pendapatan_lain_lain"
                  disabled
                />
              </div>
            </div>
            <div
              className="form-group row"
              style={{ marginBottom: "1rem" }}
              id="pricing_voucher_intransit-wrapper"
            >
              <label className="col-sm-5 col-form-label">Intransit</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control mask-currency"
                  id="pricing_voucher_transit_pool"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginBottom: "1rem" }}>
              <label className="col-sm-5 col-form-label">
                Fee Mitra <span style={{ color: "red" }}>(Final)</span>
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  id="pricing_voucher_price_tpnm_fee_mitra"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row" style={{ marginBottom: "1rem" }}>
              <label className="col-sm-5 col-form-label">
                Fee STNK Mitra <span style={{ color: "red" }}>(Final)</span>
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  id="pricing_voucher_price_tpnm_fee_stnk"
                  disabled
                />
              </div>
            </div>

            <div className="form-group row" style={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <label className="col-sm-5 col-form-label">PIC Tagih</label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  id="pricing_voucher_pic_tagih"
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
      </PortletBody>
    </Portlet>
  );
};

export default DetailPricingVoucher;
