/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import Slide from "@material-ui/core/Slide";
import { Row, Col } from "react-bootstrap";
import { getTotalInvoice, getStatusStnk, getFeeTarik } from "../../../functions/invoice";
import {
  Portlet,
  PortletBody,
  // PortletHeader,
  // PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import Box from "@material-ui/core/Box";
import API from "../../API";
// import Utils from './FunctionDetail'
import $ from "jquery";
import moment from "moment";
import "moment/locale/id";
import Logo from "../../auth/BetaImg/logo-text.png";
import { AlertList } from "react-bs-notifier";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const formatter = new Intl.NumberFormat("de-DE");
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset: theme.mixins.toolbar,
    marginTop: "70px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailLaporanInvoice(props) {
  const classes = useStyles();
  const [alerts, setAlerts] = React.useState([]);

  React.useEffect(() => {
    var settings = {
      async: true,
      crossDomain: true,
      url: API.Financeapi + "v1/Invoice/detailInvoice/" + props.id,
      method: "GET",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    if (props.id != null) {
      $.ajax(settings).done(function(response) {
        if (response.data != null) {
          var biaya_kirim =
            response.data.tvoc_biaya_kirim_subleasing != null
              ? response.data.tvoc_biaya_kirim_subleasing
              : 0;
          $("#prnt_mmle_name_invoice").html(response.data.mmle_name);
          $("#prnt_tpnm_branch").html(response.data.tpnm_branch);
          var dpName =
            response.data.tpnm_delivery_dpname == null
              ? ""
              : response.data.tpnm_delivery_dpname.toUpperCase();
          $("#prnt_tpnm_delivery_dpname").html(dpName);
          $("#prnt_invc_created_date").html(response.data.invc_created_date);
          $("#prnt_invc_no").html(response.data.invc_no);
          $("#prnt_mper_name").val(response.data.mper_name);
          $("#prnt_tpnm_contract").val(response.data.tpnm_contract);
          $("#prnt_tpnm_status_stnk").val(getStatusStnk(response.data.tpnm_status_stnk));
          $("#prnt_tpnm_type").val(response.data.tpnm_type);
          $("#prnt_tpnm_vyear").val(response.data.tpnm_vyear);
          $("#prnt_tpnm_vplate").val(response.data.tpnm_vplate);
          $("#prnt_tpnm_vin").val(response.data.tpnm_vin);
          $("#prnt_tpnm_ein").val(response.data.tpnm_ein);
          $("#prnt_tpnm_customer_name").val(response.data.tpnm_customer_name);
          $("#prnt_tpnm_biaya_kirim").val("Rp. " + formatter.format(parseInt(biaya_kirim)));
          $("#prnt_total").val("Rp. " + formatter.format(getFeeTarik(response.data)));
          $("#prnt_total1").val("Rp. " + formatter.format(getTotalInvoice(response.data)));
        }
      });
    }
  });
  const updatePrint = () => {
    var printed = {
      async: true,
      crossDomain: true,
      url: API.Financeapi + "v1/Invoice/updateStatusPrint/" + props.id,
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
    };

    $.ajax(printed)
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Invoice Berhasil Di Print`,
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        window.print();
      })
      .fail(function(response) {
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
      });
  };

  return (
    <div>
      <AlertList showIcon={false} alerts={alerts} />
      <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Button
              // onClick={()=>window.print()}
              onClick={updatePrint}
              color="inherit"
              size="large"
              startIcon={<PrintIcon />}
            >
              Print
            </Button>
            <Typography variant="h6" className={classes.title}>
              {/* Detail Kendaraan */}
            </Typography>
            <IconButton color="inherit" onClick={props.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TabPanel value={0} index={0}>
          <Portlet className={classes.detail} style={{ marginTop: "20px" }}>
            <PortletBody style={{ height: "100vh" }}>
              <Row>
                <Col md={6}>
                  <div className="pull-left" style={{ padding: "10px 40px 10px" }}>
                    <img alt="logo" src={Logo} style={{ margin: "auto", width: "80%" }}></img>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pull-right">
                    <h1>
                      <b>PT. BETA ASET INDONESIA</b>
                    </h1>
                    <h3>Jl.Raya Bekasi KM 25 No.79,</h3>
                    <h3>Cakung - Jakarta Timur</h3>
                    <h3>Telp & WA: +62812-8918-8680</h3>
                  </div>
                </Col>
              </Row>
              <div style={{ borderBottom: "double black 3.5px" }} />
              <Row>
                <Col md={12}>
                  <div style={{ marginTop: "-1px" }}>
                    <h1 className="text-center">
                      <b>INVOICE</b>
                    </h1>
                  </div>
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col sm={6}>
                          <h6 style={{ fontSize: "18px", color: "black" }}>Nama leasing </h6>
                        </Col>
                        <Col sm={6}>
                          <label
                            class="form-control-plaintext"
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginTop: "-10px",
                              marginLeft: "-67%",
                            }}
                          >
                            : <span id="prnt_mmle_name_invoice"></span>
                          </label>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "-8px" }}>
                        <Col sm={6}>
                          <h6 style={{ fontSize: "18px", color: "black" }}>Cabang Asal</h6>
                        </Col>
                        <Col sm={6}>
                          <label
                            class="form-control-plaintext"
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginTop: "-10px",
                              marginLeft: "-67%",
                            }}
                          >
                            : <span id="prnt_tpnm_branch"></span>
                          </label>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "-10px" }}>
                        <Col sm={6}>
                          <h6 style={{ fontSize: "18px", color: "black" }}>Cabang Penerima </h6>
                        </Col>
                        <Col sm={6}>
                          <label
                            class="form-control-plaintext"
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginTop: "-10px",
                              marginLeft: "-67%",
                            }}
                          >
                            : <span id="prnt_tpnm_delivery_dpname"></span>
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row>
                    <Col sm={12}>
                      <Row style={{ marginTop: "-65px", marginLeft: "600px" }}>
                        <Col sm={6}>
                          <h6 style={{ fontSize: "18px", color: "black" }}>Tanggal Invoice </h6>
                        </Col>
                        <Col sm={6}>
                          <label
                            class="form-control-plaintext"
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginTop: "-10px",
                              marginLeft: "-30%",
                            }}
                          >
                            : <span id="prnt_invc_created_date"></span>
                          </label>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "0px", marginLeft: "600px" }}>
                        <Col sm={6}>
                          <h6 style={{ fontSize: "18px", color: "black" }}>No Invoice </h6>
                        </Col>
                        <Col sm={6}>
                          <label
                            class="form-control-plaintext"
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginTop: "-10px",
                              marginLeft: "-30%",
                            }}
                          >
                            : <span id="prnt_invc_no"></span>
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <table class="tg" style={{ tableLayout: "fixed", width: "100%" }}>
                    <colgroup>
                      <col style={{ width: "610px" }} />
                      <col style={{ width: "160px" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th class="tg-0pky" style={{ width: "70%" }}>
                          <h6
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginLeft: "350px",
                              marginTop: "5px",
                            }}
                          >
                            DESKRIPSI{" "}
                          </h6>
                        </th>
                        <th class="tg-0pky">
                          <h6
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginLeft: "70px",
                              marginTop: "5px",
                            }}
                          >
                            TOTAL{" "}
                          </h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="tg-0pky" rowspan="8">
                          <Row style={{ marginTop: "1px" }}>
                            <Col sm={5}>
                              <h6 style={{ fontSize: "18px", color: "black" }}>Nama Debitur </h6>
                            </Col>
                            <Col sm={1}>
                              <h6
                                style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}
                              >
                                {" "}
                                :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="prnt_tpnm_customer_name"
                                disabled
                                style={{
                                  fontSize: "18px",
                                  color: "black",
                                  marginTop: "-10px",
                                  marginLeft: "-175px",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row style={{ marginTop: "-25px" }}>
                            <Col sm={5}>
                              <h6 style={{ fontSize: "18px", color: "black" }}>No Kontrak </h6>
                            </Col>
                            <Col sm={1}>
                              <h6
                                style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}
                              >
                                {" "}
                                :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="prnt_tpnm_contract"
                                disabled
                                style={{
                                  fontSize: "18px",
                                  color: "black",
                                  marginTop: "-10px",
                                  marginLeft: "-175px",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row style={{ marginTop: "-25px" }}>
                            <Col sm={5}>
                              <h6 style={{ fontSize: "18px", color: "black" }}>STNK </h6>
                            </Col>
                            <Col sm={1}>
                              <h6
                                style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}
                              >
                                {" "}
                                :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="prnt_tpnm_status_stnk"
                                disabled
                                style={{
                                  fontSize: "18px",
                                  color: "black",
                                  marginTop: "-10px",
                                  marginLeft: "-175px",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row style={{ marginTop: "-25px" }}>
                            <Col sm={5}>
                              <h6 style={{ fontSize: "18px", color: "black" }}>Type Kendaraan </h6>
                            </Col>
                            <Col sm={1}>
                              <h6
                                style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}
                              >
                                {" "}
                                :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="prnt_tpnm_type"
                                disabled
                                style={{
                                  fontSize: "16px",
                                  color: "black",
                                  marginTop: "-10px",
                                  marginLeft: "-175px",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row style={{ marginTop: "-25px" }}>
                            <Col sm={5}>
                              <h6 style={{ fontSize: "18px", color: "black" }}>Tahun </h6>
                            </Col>
                            <Col sm={1}>
                              <h6
                                style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}
                              >
                                {" "}
                                :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="prnt_tpnm_vyear"
                                disabled
                                style={{
                                  fontSize: "18px",
                                  color: "black",
                                  marginTop: "-10px",
                                  marginLeft: "-175px",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row style={{ marginTop: "-25px" }}>
                            <Col sm={5}>
                              <h6 style={{ fontSize: "18px", color: "black" }}>Nomor Polisi </h6>
                            </Col>
                            <Col sm={1}>
                              <h6
                                style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}
                              >
                                {" "}
                                :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="prnt_tpnm_vplate"
                                disabled
                                style={{
                                  fontSize: "18px",
                                  color: "black",
                                  marginTop: "-10px",
                                  marginLeft: "-175px",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row style={{ marginTop: "-25px" }}>
                            <Col sm={5}>
                              <h6 style={{ fontSize: "18px", color: "black" }}>No Rangka </h6>
                            </Col>
                            <Col sm={1}>
                              <h6
                                style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}
                              >
                                {" "}
                                :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="prnt_tpnm_vin"
                                disabled
                                style={{
                                  fontSize: "18px",
                                  color: "black",
                                  marginTop: "-10px",
                                  marginLeft: "-175px",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row style={{ marginTop: "-25px" }}>
                            <Col sm={5}>
                              <h6 style={{ fontSize: "18px", color: "black" }}>No Mesin </h6>
                            </Col>
                            <Col sm={1}>
                              <h6
                                style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}
                              >
                                {" "}
                                :{" "}
                              </h6>
                            </Col>
                            <Col sm={6}>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="prnt_tpnm_ein"
                                disabled
                                style={{
                                  fontSize: "18px",
                                  color: "black",
                                  marginTop: "-10px",
                                  marginLeft: "-175px",
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                        </td>
                        <td class="tg-0pky" rowspan="8">
                          <input
                            type="text"
                            readonly
                            class="form-control-plaintext"
                            id="prnt_total"
                            disabled
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginTop: "-10px",
                              marginLeft: "40px",
                            }}
                          />
                        </td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr style={{ marginTop: "-10px" }}>
                        <td class="tg-0pky">
                          <h6
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginLeft: "340px",
                              marginTop: "6px",
                            }}
                          >
                            Biaya Pengiriman{" "}
                          </h6>
                        </td>
                        <td class="tg-0pky">
                          <input
                            type="text"
                            readonly
                            class="form-control-plaintext"
                            id="prnt_tpnm_biaya_kirim"
                            disabled
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginTop: "-10px",
                              marginLeft: "40px",
                            }}
                          />
                        </td>
                      </tr>
                      <tr style={{ marginTop: "-10px" }}>
                        <td class="tg-0pky">
                          <h6
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginLeft: "370px",
                              marginTop: "6px",
                            }}
                          >
                            TOTAL{" "}
                          </h6>
                        </td>
                        <td class="tg-0pky">
                          <input
                            type="text"
                            readonly
                            class="form-control-plaintext"
                            id="prnt_total1"
                            disabled
                            style={{
                              fontSize: "18px",
                              color: "black",
                              marginTop: "-10px",
                              marginLeft: "40px",
                            }}
                          />
                        </td>
                      </tr>
                      <Col sm={12}>
                        <Row style={{ marginTop: "5px" }}>
                          <Col sm={5}>
                            <h6 style={{ fontSize: "18px", color: "black" }}>Pembayaran Ke : </h6>
                          </Col>
                          <Col sm={1}>
                            <h6 style={{ fontSize: "18px", color: "black", opacity: "0" }}> : </h6>
                          </Col>
                          <Col sm={6}>
                            <input
                              type="text"
                              readonly
                              class="form-control-plaintext"
                              disabled
                              style={{ fontSize: "18px", color: "black", marginTop: "-10px" }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row style={{ marginTop: "-25px" }}>
                          <Col sm={5}>
                            <h6 style={{ fontSize: "18px", color: "black" }}>BANK BCA No. Rek </h6>
                          </Col>
                          <Col sm={1}>
                            <h6 style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}>
                              {" "}
                              :{" "}
                            </h6>
                          </Col>
                          <Col sm={6}>
                            <input
                              type="text"
                              readonly
                              class="form-control-plaintext"
                              value="624-1889898"
                              disabled
                              style={{
                                fontSize: "18px",
                                color: "black",
                                marginTop: "-10px",
                                fontWeight: "bold",
                                marginLeft: "-175px",
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row style={{ marginTop: "-25px" }}>
                          <Col sm={5}>
                            <h6 style={{ fontSize: "18px", color: "black" }}>Atas Nama </h6>
                          </Col>
                          <Col sm={1}>
                            <h6 style={{ fontSize: "18px", color: "black", marginLeft: "-130px" }}>
                              {" "}
                              :{" "}
                            </h6>
                          </Col>
                          <Col sm={6}>
                            <input
                              type="text"
                              readonly
                              class="form-control-plaintext"
                              value="PT. BETA ASET INDONESIA "
                              disabled
                              style={{
                                fontSize: "18px",
                                color: "black",
                                marginTop: "-10px",
                                fontWeight: "bold",
                                marginLeft: "-175px",
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} style={{ marginLeft: "180px", marginTop: "-80px" }}>
                        <div className="pull-right">
                          <h6
                            style={{ fontSize: "18px", marginBottom: "130px", marginLeft: "35px" }}
                          >
                            Dibuat Oleh:
                          </h6>
                          <h6 style={{ fontSize: "18px" }}>(Cahya Hendri Yanto)</h6>
                        </div>
                      </Col>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
        </TabPanel>
      </Dialog>
    </div>
  );
}
