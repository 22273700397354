import axios from "axios";
import $ from "jquery";
import sha256 from 'sha256'
import API from '../pages/API'

export const LOGIN_URL = API.Authapi+"v1/auth/loginAdm";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = API.Authapi+"v1/auth/loginAdm";

export function login(username, password) {
  // return axios.get(LOGIN_URL, { username, password });
  $(document).ready(function(){
    var settings = {
      "async": true,
      "crossDomain": false,
      "url": LOGIN_URL,
      "method": "POST",
      "headers": {
        "Content-Type": "application/json"
      },
      "processData": false,
      "data": "{\n\t\"mper_username\": \""+username+"\",\n\t\"mper_password\": \""+sha256(sha256(password))+"\"\n}"
      // "data": "{\n\t\"mper_username\": \""+username+"\",\n\t\"mper_password\": \""+sha256(sha256(password))+"\"\n}"
    }
    // console.log("login "+username+" "+sha256(password));
    return $.ajax(settings)
  })

}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
