/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  // PortletHeaderToolbar
} from "../../partials/content/Portlet";
import PropTypes from "prop-types";
// import { makeStyles,
//   // withStyles,
//   useTheme  } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import {Tabs as Tabs2, Tab as Tab2} from 'react-bootstrap';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import $ from "jquery";
import "datatables.net-bs4";
// import Slide from '@material-ui/core/Slide';
// import SwipeableViews from 'react-swipeable-views';
// import Table from './Penemuan/TablePenemuan'
// import DetailLaporanPenemuan from './Utils/DetailLaporanPenemuan'
// import DetailLaporanInforman from './Utils/DetailLaporanInforman'
// import DetailLaporanPembatalanInforman from './Utils/DetailLaporanPembatalanInforman'
// import DetailInforman from './Utils/DetailInforman'
// import DetailNego from './Utils/DetailNego'
// import {Badge} from 'react-bootstrap'
import API from "../API";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import DialogInvoice from "./Utils/DialogInvoice";
import DetailLaporanInvoice from "./Utils/DetailLaporanInvoice";
import HistoryPembayaranInvoice from "./Utils/HistoryPembayaranInvoice";
import DialogEditInvoice from "./Utils/DialogEditInvoice";
import DetailPrintAll from "./Utils/DetailPrintAll";
import { AlertList } from "react-bs-notifier";
import { getTotalInvoice, getBasePrice, getTagihan, getFeeStnk } from "../../functions/invoice"; //formula invoice

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const formatter = new Intl.NumberFormat("currency");

export default function LaporanInvoice() {
  const [idPay, setIdPay] = React.useState(null);
  const [openPayment, setOpenPay] = React.useState(false);
  const [id2, setId2] = React.useState(null);
  const [open2, setOpen2] = React.useState(false);
  const [id3, setId3] = React.useState(null);
  const [open3, setOpen3] = React.useState(false);
  const [idEdit, setIdEdit] = React.useState(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openPrint, setOpenPrint] = React.useState(false);
  const [feeStnk, setFeeStnk] = React.useState(null);
  const [biayaKirim, setBiayaKirim] = React.useState(null);
  const [alerts, setAlerts] = React.useState([]);
  const [invcPrint, setInvcPrint] = React.useState([]);

  const handleClickOpenPayment = () => {
    setOpenPay(true);
  };
  const handleClosePayment = () => {
    setOpenPay(false);
    $('[type="submit"]').prop("disabled", false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClickOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleClickOpenPrint = () => {
    setOpenPrint(true);
  };
  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  useEffect(() => {
    $(document).ready(function() {
      $("#TablelaporanInvoice").on("click", "#dialog_payment", function() {
        handleClickOpenPayment();
        $("#input_payment input,#input_payment textarea").val("");
        setIdPay($(this).attr("data-id"));
        if ($(this).attr("data-invc_no") != "null") {
          $("#invc_no").val($(this).attr("data-invc_no"));
          $("#invc_customer_name").val($(this).attr("data-invc_customer_name"));
          $("#invc_base_price").val($(this).attr("data-invc_base_price"));
          $("#fee_stnk").val($(this).attr("data-fee_stnk"));
          $("#biaya_kirim_leasing").val($(this).attr("data-biaya_kirim_leasing"));
          $("#invc_tagihan").val($(this).attr("data-invc_tagihan"));
          $("#invc_bayar").val($(this).attr("data-invc_bayar"));
          $("#invc_keterangan").val($(this).attr("data-invc_keterangan"));
          $("#invc_send_date").datepicker("setDate", $(this).attr("data-invc_send_date"));
          if ($(this).attr("data-invc_date_paid") != "null") {
            $("#invc_date_paid").datepicker("setDate", $(this).attr("data-invc_date_paid"));
          }
        }
      });
      $(function() {
        $("#vldto,#vldfrom").datepicker({
          todayHighlight: true,
          autoclose: true,
          format: "yyyy-mm-dd",
        });
      });
      $("#vldfrom,#vldto").val(moment().format("YYYY-MM-DD"));
    });
    $(document).on("click", "#print", function() {
      handleClickOpen2();
      setId2($(this).attr("data-id_invc"));
    });
    $(document).on("click", "#history", function() {
      handleClickOpen3();
      setId3($(this).attr("data-id_invc"));
    });
    $(document).ready(function() {
      $("#TablelaporanInvoice").on("click", "#dialog_edit", function() {
        handleClickOpenEdit();
        $("#edit_invoice input,#edit_invoice textarea").val("");
        setIdEdit($(this).attr("data-edt_id"));
        setFeeStnk($(this).attr("data-edt_invc_fee_stnk"));
        setBiayaKirim($(this).attr("data-edt_invc_biaya_kirim"));
        if ($(this).attr("data-edt_invc_no") != "null") {
          $("#edt_invc_no").val($(this).attr("data-edt_invc_no"));
          $("#edt_invc_tpnm_contract").val($(this).attr("data-edt_invc_tpnm_contract"));
          $("#edt_invc_tpnm_delivery_dpname").val(
            $(this).attr("data-edt_invc_tpnm_delivery_dpname")
          );
          $("#edt_invc_status_stnk").val($(this).attr("data-edt_invc_status_stnk"));
          $("#edt_invc_tpnm_customer_name").val($(this).attr("data-edt_invc_tpnm_customer_name"));
          $("#edt_pic_tagih").val($(this).attr("data-edt_pic_tagih"));
          if ($(this).attr("data-edt_tanggal_siap_tagih") !== "null")
            $("#edt_tanggal_siap_tagih").val($(this).attr("data-edt_tanggal_siap_tagih"));
          $("#edt_invc_mmle_name")
            .val($(this).attr("data-edt_invc_mmle_name"))
            .prop("disabled", true);
          $("#edt_invc_total")
            .val($(this).attr("data-edt_invc_total"))
            .prop("disabled", false);
          $("#edt_invc_note_msg")
            .val($(this).attr("data-edt_invc_note_msg"))
            .prop("disabled", false);

          // if( ($(this).attr('data-edt_invc_status') != 'Lunas')) {
          //   $('#edt_invc_total').val($(this).attr('data-edt_invc_total')).prop('disabled',false)
          //   $('#edt_invc_note_msg').val($(this).attr('data-edt_invc_note_msg')).prop('disabled',false)
          // }else {
          //   $('#edt_invc_total').val($(this).attr('data-edt_invc_total')).prop('disabled',true)
          //   $('#edt_invc_note_msg').val($(this).attr('data-edt_invc_note_msg')).prop('disabled',true)
          // }
        }
      });
      $(function() {
        $("#vldto,#vldfrom").datepicker({
          todayHighlight: true,
          autoclose: true,
          format: "yyyy-mm-dd",
        });
      });
      $("#vldfrom,#vldto").val(moment().format("YYYY-MM-DD"));
    });
  }, [setAlerts]);

  function FilterLaporanPenemuan() {
    if ($("#vldfrom").val() == 0 || $("#vldto").val() == 0) {
      return 0;
    }

    var from = moment($("#vldfrom").val()).format("MM-DD-YYYY");
    var to = moment($("#vldto").val()).format("MM-DD-YYYY");

    TableJumlahPenemuan(from, to);

    $(".dataTables_filter,.dataTables_paginate")
      .addClass("pull-right")
      .css("float", "right");
  }

  function PrintAll(from, to) {
    $(document).on("click", ".printAll", function() {
      var dataprints = {
        async: true,
        crossDomain: true,
        url: API.Financeapi + `v1/Invoice/printAllInvc?from=${from}&to=${to}`,
        method: "GET",
        headers: {
          Authorization: "Basic " + localStorage.getItem("jwt"),
        },
      };
      $.ajax(dataprints)
        .done(function(res) {
          handleClickOpenPrint();
          setInvcPrint(res.data);
        })
        .fail(function(res) {
          setAlerts([
            {
              id: 1,
              type: "danger",
              headline: "Gagal",
              message: "Data Tidak Ditemukan [Error Code " + res.status + "]",
            },
          ]);
          setTimeout(() => {
            setAlerts([]);
          }, 3000);
          API.Handle403(res, setAlerts);
        });
    });
  }

  const TableJumlahPenemuan = (from, to) => {
    $("#TablelaporanInvoice").DataTable({
      pagingType: "full_numbers",
      // "aaSorting": [],
      dom:
        "<'row'<'col-sm-12 col-md-5'l><'col-sm-12 col-md-7'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          extend: "excelHtml5",
          className: "btn btn-primary mt-4",
          text: '<i class="fas fa-file-excel"></i>Export Excel',
          titleAttr: "Export Excel",
          customize: function(xlsx, row) {
            var sheet = xlsx.xl.worksheets["sheet1.xml"];
            $('row c[r^="L"], row c[r^="Q"]', sheet).attr("s", 63);
          },
          exportOptions: {
            columns: [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24,
              25,
            ],
          },
        },
        {
          text: '<i class="fas fa-print"></i>Print All Invoice',
          className: "btn btn-primary mt-4 printAll",
          titleAttr: "Limit max 50 pages",
          action: function(e, dt, node, config) {
            PrintAll(from, to);
          },
        },
      ],
      lengthMenu: [
        [5, 10, 15, 20, -1],
        [5, 10, 15, 20, "All"],
      ],
      processing: true,
      serverSide: true,
      destroy: true,
      ajax: {
        url: API.Financeapi + `v1/Invoice/index?from=${from}&to=${to}&length=full`,

        type: "get",
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem("jwt"));
        },
        error: function(xhr, error, thrown, settings) {
          API.Handle403(xhr, setAlerts);
        },
      },
      fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        console.log(aData.invc_status);
        console.log(aData.invc_status_print);
        if (aData.invc_status == "Menunggu Pembayaran") {
          $("td:eq(17)", nRow).css("background-color", "#FFE800");
        } else if (aData.invc_status == "Lunas") {
          $("td:eq(17)", nRow).css("background-color", "#6CFF00");
        }

        if (aData.invc_status_print == "Sudah Print") {
          $("td:eq(20)", nRow).css("background-color", "#81D4FA");
        }
      },
      columns: [
        {
          data: "mmle_name",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return full.invc_created_date;
          },
        },
        {
          data: "invc_created_time",
        },
        {
          data: "mper_name",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return full.tpnm_branch;
          },
        },
        {
          data: "tpnm_delivery_dpname",
        },
        {
          data: "tpnm_contract",
        },
        {
          data: "tpnm_customer_name",
        },
        {
          data: "tpnm_vplate",
        },
        {
          data: "tpnm_type",
        },
        {
          data: "tpnm_vyear",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return formatter.format(parseInt(full.tpnm_base_price));
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var status = full.tpnm_status_stnk;
            return status == 1 ? "Ada" : status == 0 ? "Tidak ada" : "";
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var fee_stnk = getFeeStnk(full);
            return formatter.format(parseInt(fee_stnk));
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var biaya_kirim =
              full.tvoc_biaya_kirim_subleasing == null || full.tvoc_biaya_kirim_subleasing == ""
                ? 0
                : full.tvoc_biaya_kirim_subleasing;
            return formatter.format(parseInt(biaya_kirim));
          },
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var total = getTotalInvoice(full);
            return formatter.format(total);
          },
        },
        {
          data: "invc_no",
        },
        {
          data: "invc_send_date",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return full.invc_status;
          },
        },
        {
          data: "invc_date_paid",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var uang_masuk = full.invc_note != null ? full.invc_note : 0;
            return formatter.format(parseInt(uang_masuk));
          },
        },
        {
          data: "pic_tagih",
        },
        {
          data: "tanggal_siap_tagih",
        },
        {
          data: "transit_pool",
        },
        {
          data: "tvoc_status_kirim",
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            return full.invc_status_print != null ? full.invc_status_print : "Belum Print";
          },
        },
        {
          data: "invc_note_msg",
          visible: false,
        },
        {
          sortable: true,
          render: function(data, type, full, meta) {
            var note = full.invc_note_msg != null ? full.invc_note_msg : "";
            var tagihan = getTagihan(full);
            var fee_stnk = getFeeStnk(full);
            var base_price = parseInt(full.tpnm_base_price != null ? full.tpnm_base_price : 0);
            var total = getTotalInvoice(full);
            var biaya_kirim =
              full.tvoc_biaya_kirim_subleasing != null ? full.tvoc_biaya_kirim_subleasing : 0;

            if (full.invc_status != "Lunas") {
              return `
                <button id="laporanInvoice" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="laporanInvoice">
                    <button 
                        class='dropdown-item edit'
                        data-edt_id='${full.invc_id}'
                        data-edt_invc_no='${full.invc_no}'
                        data-edt_invc_mmle_name='${full.mmle_name}'
                        data-edt_invc_tpnm_contract='${full.tpnm_contract}'
                        data-edt_invc_tpnm_delivery_dpname='${full.tpnm_delivery_dpname}'
                        data-edt_invc_status_stnk='${full.tpnm_status_stnk}'
                        data-edt_pic_tagih='${full.pic_tagih}'
                        data-edt_tanggal_siap_tagih='${full.tanggal_siap_tagih}'
                        data-edt_invc_tpnm_customer_name='${full.tpnm_customer_name}'
                        data-edt_invc_status='${full.invc_status}'
                        data-edt_invc_total='${formatter.format(total)}'
                        data-edt_invc_fee_stnk='${fee_stnk}'
                        data-edt_invc_biaya_kirim='${biaya_kirim}'
                        data-edt_invc_note_msg='${note}'
                        id='dialog_edit'
                    >
                        <i class='fa fa-edit'></i> Edit
                    </button>
                    <button 
                        class='dropdown-item pembayaran' 
                        data-id='${full.invc_id}'
                        data-invc_no='${full.invc_no}'
                        data-invc_customer_name='${full.tpnm_customer_name}'
                        data-invc_base_price='${formatter.format(base_price)}'
                        data-fee_stnk='${formatter.format(fee_stnk)}'
                        data-invc_tagihan = '${formatter.format(tagihan)}'
                        data-biaya_kirim_leasing = '${formatter.format(biaya_kirim)}' 
                        data-invc_send_date='${full.invc_send_date}'
                        data-invc_date_paid='${full.invc_date_paid}'
                        id='dialog_payment'
                    >
                        <i class='fa fa-hand-holding-usd'></i> Input Pembayaran Invoice
                    </button>
                    <button
                        class='dropdown-item history'
                        id='history'
                        data-id_invc='${full.invc_id}'
                    >
                    <i class='fa fa-search-dollar'></i> History Pembayaran
                    </button>
                    <button 
                        class='dropdown-item print'
                        id='print'
                        data-id_invc='${full.invc_id}'
                    >
                        <i class='fa fa-print'></i> Print
                    </button>
                 </div>
              `;
            } else {
              return `
                  <button id="laporanInvoice" type="button" class="btn btn-label-primary btn-pill btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-ellipsis-v"></i>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="laporanInvoice">
                      <button 
                          class='dropdown-item edit'
                          data-edt_id='${full.invc_id}'
                          data-edt_invc_no='${full.invc_no}'
                          data-edt_invc_mmle_name='${full.mmle_name}'
                          data-edt_invc_tpnm_contract='${full.tpnm_contract}'
                          data-edt_invc_tpnm_delivery_dpname='${full.tpnm_delivery_dpname}'
                          data-edt_invc_status_stnk='${full.tpnm_status_stnk}'
                          data-edt_invc_tpnm_customer_name='${full.tpnm_customer_name}'
                          data-edt_pic_tagih='${full.pic_tagih}'
                          data-edt_tanggal_siap_tagih='${full.tanggal_siap_tagih}'
                          data-edt_invc_status='${full.invc_status}'
                          data-edt_invc_total='${formatter.format(total)}'
                          data-edt_invc_fee_stnk='${fee_stnk}'
                          data-edt_invc_biaya_kirim='${biaya_kirim}'
                          data-edt_invc_note_msg='${note}'
                          id='dialog_edit'
                      >
                          <i class='fa fa-edit'></i> Edit
                      </button>
                      <button
                          class='dropdown-item history'
                          id='history'
                          data-id_invc='${full.invc_id}'
                      >
                      <i class='fa fa-search-dollar'></i> History Pembayaran
                      </button>
                      <button 
                          class='dropdown-item print'
                          id='print'
                          data-id_invc='${full.invc_id}'
                      >
                          <i class='fa fa-print'></i> Print
                      </button>
                  </div>
                `;
            }
          },
        },
      ],
    });
  };
  const SubmitPayment = () => {
    var from = moment($("#vldfrom").val()).format("MM-DD-YYYY");
    var to = moment($("#vldto").val()).format("MM-DD-YYYY");
    var data = {
      invc_note: $("#invc_bayar")
        .val()
        .replace(/\D/g, ""),
      invc_note_msg: $("#invc_keterangan").val(),
      invc_send_date: $("#invc_send_date").val(),
      invc_date_paid: $("#invc_date_paid").val(),
    };
    var payment = {
      async: true,
      crossDomain: true,
      url: `${API.Financeapi}v1/Invoice/inputPembayaran/${idPay}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      data: JSON.stringify(data),
    };

    $.ajax(payment)
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Pembayaran Berhasil Di Isi`,
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        handleClosePayment();
        TableJumlahPenemuan(from, to);
      })
      .fail(function(response) {
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
      });
  };

  const SubmitEditInvoice = () => {
    var from = moment($("#vldfrom").val()).format("MM-DD-YYYY");
    var to = moment($("#vldto").val()).format("MM-DD-YYYY");

    var total = $("#edt_invc_total")
      .val()
      .replace(/\D/g, "");
    var fee_stnk = feeStnk;
    var biaya_kirim = biayaKirim;
    var base_price = getBasePrice(total, fee_stnk, biaya_kirim);
    var data = {
      tpnm_customer_name: $("#edt_invc_tpnm_customer_name").val(),
      tpnm_contract: $("#edt_invc_tpnm_contract").val(),
      tpnm_delivery_dpname: $("#edt_invc_tpnm_delivery_dpname").val(),
      tpnm_status_stnk: $("#edt_invc_status_stnk").val(),
      tpnm_base_price: base_price,
      invc_note_msg: $("#edt_invc_note_msg").val(),
      pic_tagih: $("#edt_pic_tagih").val(),
      tanggal_siap_tagih: $("#edt_tanggal_siap_tagih").val(),
    };
    console.log("total input", total);
    console.log("base_price", base_price);
    console.log("fee stnk", fee_stnk);
    console.log("biaya kirim", biaya_kirim);
    var settings = {
      async: true,
      crossDomain: true,
      url: `${API.Financeapi}v1/Invoice/editInvoice/${idEdit}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      data: JSON.stringify(data),
    };

    $.ajax(settings)
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: `Invoice Berhasil Di Isi`,
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        handleCloseEdit();
        TableJumlahPenemuan(from, to);
      })
      .fail(function(response) {
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
      });
  };

  return (
    <>
      <AlertList showIcon={false} alerts={alerts} />
      <DetailPrintAll open={openPrint} close={handleClosePrint} data={invcPrint} />
      <DetailLaporanInvoice open={open2} close={handleClose2} id={id2} />
      <DialogInvoice
        open={openPayment}
        close={handleClosePayment}
        title={`Input Pembayaran Invoice`}
        submit={SubmitPayment}
      />
      <DialogEditInvoice
        open={openEdit}
        close={handleCloseEdit}
        title={`Edit Invoice`}
        submit={SubmitEditInvoice}
      />
      <HistoryPembayaranInvoice open={open3} close={handleClose3} id={id3} />
      <Portlet>
        <PortletHeader title="Filter Tanggal"></PortletHeader>
        <PortletBody>
          <Grid container spacing={2}>
            <Grid item xs></Grid>
            <Grid item xs={6} className="text-center">
              <div className="input-daterange input-group date mb-2" id="kt_datepicker_5">
                <input
                  type="text"
                  className="form-control"
                  name="start"
                  id="vldfrom"
                  autocomplete="off"
                />
                <div className="input-group-append">
                  <span className="input-group-text">To</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="end"
                  id="vldto"
                  autocomplete="off"
                />
              </div>
              <button
                type="button"
                class="btn btn-brand btn-elevate btn-sm"
                onClick={FilterLaporanPenemuan}
                id="cari"
                style={{ width: "100%" }}
              >
                Set Filter
              </button>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </PortletBody>
      </Portlet>
      <Portlet>
        <PortletBody>
          <div style={{ overflow: "auto" }}>
            <table
              className="table table-hover table-responsive-md"
              id="TablelaporanInvoice"
              style={{ width: "100%" }}
            >
              <thead className="bg-primary" style={{ color: "white", fontSize: "12px" }}>
                <tr>
                  <th scope="col">Leasing</th>
                  <th scope="col">Tanggal</th>
                  <th scope="col">Jam</th>
                  <th scope="col">Nama Mitra</th>
                  <th scope="col">Asal Cabang</th>
                  <th scope="col">Drop Point</th>
                  <th scope="col">No Kontrak</th>
                  <th scope="col">Nama Debitur</th>
                  <th scope="col">Nopol</th>
                  <th scope="col">Tipe Motor</th>
                  <th scope="col">Tahun</th>
                  <th scope="col">Base Fee Tarik</th>
                  <th scope="col">Status STNK</th>
                  <th scope="col">Fee STNK</th>
                  <th scope="col">Biaya Ekspedisi Leasing</th>
                  <th scope="col">Total Tagih</th>
                  <th scope="col">No Invoice</th>
                  <th scope="col">Tanggal Kirim Invoice</th>
                  <th scope="col">Status Invoice</th>
                  <th scope="col">Tanggal Lunas</th>
                  <th scope="col">Uang Masuk</th>
                  <th scope="col">PIC Tagih</th>
                  <th scope="col">Tgl. Siap Tagih</th>
                  <th scope="col">Intransit</th>
                  <th scope="col">Siap Tagih Pengiriman</th>
                  <th scope="col">Status Print</th>
                  <th scope="col">Note</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
            </table>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#FFE800" }}
            ></button>
            <span style={{ color: "black" }}> : Menunggu Pembayaran </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#6CFF00" }}
            ></button>
            <span style={{ color: "black" }}> : Lunas </span>
            <button
              type="button"
              class="btn btn-default"
              style={{ background: "#81D4FA" }}
            ></button>
            <span style={{ color: "black" }}> : Sudah Print </span>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}
