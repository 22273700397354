import React from 'react';
import $ from 'jquery';
import 'datatables.net-bs4';
import API from '../../API';

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import Table from '../Settings/TableSetting';
import { AlertList} from "react-bs-notifier";
import DialogUpload from './DialogUpload';
import bsCustomFileInput from 'bs-custom-file-input';
import {ProgressBar, Card} from 'react-bootstrap';
import {Row, Col} from 'react-bootstrap';
import ExcelDownlineLain from './kuota_downline_kota_lain.xlsx';
import DialogKuotaLain from './DialogKuotaLain';

export default function KuotaDownlineLain() {
    const [openkdl, setOpenKDL] = React.useState(false) //upload excel downline kota lain
    const [open1, setOpen1] = React.useState(false); //edit kuota downline kota lain
    const [open2, setOpen2] = React.useState(false) //delete kuota downline kota lain
    const [open3, setOpen3] = React.useState(false) //delete semua kuota downline kota lain
    const [quotaId, setQuotaId] = React.useState(null)
    const [filekdl, setFileKDL] = React.useState(null)
    const [uploadnamekdl, setUploadnamekdl] = React.useState(null)
    const [progresskdl, setProgressKDL] = React.useState(0)
    const [alerts, setAlerts] = React.useState([])

    const ToggleUploadKDL = () => {
        $('#uploadformKDL').toggle('slow')
    }

    const FileKDL = (e) => {
        setFileKDL(e.target.files[0])
    }

    const handleClickOpenKDL = () => {
        setOpenKDL(true)
    }

    const handleCloseKDL = () => {
        setOpenKDL(false)
    }
    const handleClickOpen1 = () => {
        setOpen1(true)

        $('#kuotalaindialog input').val('')
    }
    const handleClose1 = () => {
        setOpen1(false)
    }
    const handleClickOpen2 = () => {
        setOpen2(true)
    }
    const handleClose2 = () => {
        setOpen2(false)
    }
    const handleClickOpen3 = () => {
        setOpen3(true)
    }
    const handleClose3 = () => {
        setOpen3(false)
    }
    
    React.useEffect(() => {
        $(document).ready(function() {
            TableKuotaLain()
            $('#kuotadownlinelain').on('click', '#editKuotaKotaLain', function() {
                setQuotaId($(this).attr('data-id'))
                handleClickOpen1()

                var editKuotaLain = {
                    "async":true,
                    "crossDomain":true,
                    "url": API.Userapi + "v1/master/getKuota/" + $(this).attr('data-id')+"?type=1",
                    "method":"GET",
                    "headers": {
                        "Authorization": "Basic "+localStorage.getItem('jwt')
                    }
                }
                $.ajax(editKuotaLain).done(function(response) {
                    if(response.data !=null ) {
                        $('#quota_province_lain').val(response.data.province)
                        $('#quota_max_lain').val(response.data.max_quota_value)
                    }
                }).fail(function(response) {
                    API.Handle403(response,setAlerts)
                    return 0
                })
                
            })
            $('#kuotadownlinelain').on('click', '#hapusKuotaKotaLain', function() {
                setQuotaId($(this).attr('data-id'))
                handleClickOpen2()
            })
            bsCustomFileInput.init()
        })
    },[setAlerts])

    const TableKuotaLain = () => {
        Table.KuotaDownlineLain(setAlerts);
        $('.dataTables_filter,.dataTables_paginate').addClass('pull-right')
	}

    const UploadKDL = () => {
        handleCloseKDL()
        var formKDL = new FormData()
        formKDL.append('uploaded_filekd', filekdl)
        setUploadnamekdl(filekdl.name)
        $('#uploadingkdl').show('slow')
        $('#form_kdl').hide('slow')

        var uploadKDL = {
            xhr: function() {
				var xhr = new window.XMLHttpRequest();
				xhr.upload.addEventListener('progress', function(e) {
					if(e.lengthComputable) {
						console.log(e);
						console.log('Bytes Loaded : ' + e.loaded);
                        console.log('Total Size : ' + e.total);
						console.log('Persen : ' + (e.loaded / e.total));
						
						var percentkdl = Math.round((e.loaded / e.total) * 100);
						console.log("Persen : " + percentkdl)

						setProgressKDL(percentkdl)
					}
				})
				return xhr;
            },
            "async":true,
            "crossDomain":true,
            "url": API.Userapi+"v1/Master/uploadKuotaDownline?type=1",
            "method": "POST",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem("jwt"),
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": formKDL
        }
        $.ajax(uploadKDL).done(function (response) {
            setAlerts([{
                id:1,
                type: "success",
                headline: "Berhasil",
                message: "Downline Kota Lain Telah Diupload"
            }])
            setTimeout(() => {
                $('#uploadingkdl').hide('slow')
                $('#form_kdl').show('slow')
                setAlerts([])
            }, 3000)
            TableKuotaLain()
        }).fail(function (response) {
            $('#uploadingkdl').hide('slow')
            $('#form_kdl').show('slow')
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            },3000)
            API.Handle403(response,setAlerts)
        })
    }

    const SubmitKuotaLain = () => {
        
        // var quota_province = $('#quota_province_lain').val()
        var quota_max = $('#quota_max_lain').val()

        var dataKuotaLain = {
            // 'province': quota_province,
            'max_quota_value': quota_max
        }

        var url = API.Userapi + "v1/master/putKuotaDownline/" + quotaId + "?type=1"

        var quotas = {
            "async":true,
            "crossDomain":true,
            "url": url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('jwt')
            },
            "processData":false,
            "data": JSON.stringify(dataKuotaLain)
        }
        $.ajax(quotas).done(function(response){
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Kuota Telah Disimpan"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose1()
            TableKuotaLain()
        }).fail(function (response) {
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    const DeleteKuotaLain = () => {
        
        var delKuotaLain = {
            "async":true,
            "crossDomain": true,
            "url":API.Userapi + "v1/master/delKuota/"+quotaId+"?type=1",
            "method": "GET",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem('jwt')
            }
        }
        $.ajax(delKuotaLain).done(function(response) {
            setAlerts([{
                id: 1,
				type: "success",
				headline: "Berhasil",
				message: "Quota Downline Kota Asal berhasil Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose2()
            TableKuotaLain()
        }).fail(function(response) {
            setAlerts([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
			setTimeout(() => {
					setAlerts([])
			}, 3000)
			API.Handle403(response,setAlerts)
        })
    }

    const DeleteAllKuotaLain = () => {

        var delAllKuotaLain = {
            "async":true,
            "crossDomain":true,
            "url": API.Userapi + "v1/master/delAllKuota?type=1",
            "method": "GET",
            "headers": {
                "Authorization": "Basic "+localStorage.getItem('jwt')
            }
        }
        $.ajax(delAllKuotaLain).done(function (response ) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Total "+response.total+" Skema Downline Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose3()
            TableKuotaLain()
        }).fail(function (response) {
			setAlerts([{
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]"
          }])
          setTimeout(() => {
            setAlerts([])
          }, 3000)
          API.Handle403(response,setAlerts)
		})
    }

    return (
        <>
            <Portlet>
                <DialogUpload
                    open={openkdl}
                    close={handleCloseKDL}
                    title="Upload Max Kuota Downline Kota Lain"
                    message="Anda Yakin Ingin Upload File Kuota Downline Kota Lain Ini ?"
                    btn="Upload"
                    fun={UploadKDL}
                />
                <DialogKuotaLain
                    open={open1}
                    close={handleClose1}
                    title={quotaId != null ? "Edit Kuota Downline Kota Lain" : "Tambah Kuota Downline Kota Lain"}
                    btn = {quotaId != null ? "Edit" : "Tambah"}
                    fun = {SubmitKuotaLain}
                />
                <DialogUpload
                    open={open2}
                    close={handleClose2}
                    title="Hapus Kuota Downline Kota Lain"
                    message="Anda Yakin Ingin Hapus Data Kuota Downline Kota Lain Ini ?"
                    btn="Hapus"
                    fun={DeleteKuotaLain}
                />
                <DialogUpload 
					open={open3}
					close={handleClose3}
					title="Hapus Semua Kuota Downline Kota Lain"
					message="Anda Yakin Ingin Hapus Semua Data Kuota Downline Kota lain Ini ?"
					btn= "Hapus Semua"
					fun={DeleteAllKuotaLain}
				/>
                <AlertList  showIcon={false} alerts={alerts}/>
                <PortletHeader title="Kuota Downline Kota Lain" toolbar={(
                    <PortletHeaderToolbar>
                        <a
                            href={ExcelDownlineLain}
                            type="button"
                            className="btn btn-label-primary btn-sm"
                        >
                            <i class="fas fa-file-download"></i>Template Pengisian Data
                        </a>
                        <button
                            type="button"
                            className="btn btn-label-primary btn-sm mr-1 ml-1"
                            onClick={ToggleUploadKDL}
                        >
                            <i class="fas fa-file-upload"></i>Upload Data
                        </button>
                    </PortletHeaderToolbar>
                )}  />
                <PortletBody>
                    <div class="mb-2" id="uploadformKDL" style={{display:'none'}}>
                        <div id="form_kdl" className="input-group col-md-12">
                            <div class="input-group-prepend">
                                <button class="btn btn-warning btn-elevate" type="button" onClick={ToggleUploadKDL} >Batal</button>
                            </div>
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="customfile" onChange={FileKDL} aria-describedby="customfile"/>
                                <label class="custom-file-label" for="customfile">Pilih File</label>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-primary btn-elevate" type="button" onClick={handleClickOpenKDL} id="inputGroupFileAddon04">Upload</button>
                            </div>
                        </div>
                        <div id="uploadingkdl" className="col-md-12"style={{display:'none'}}>
                            <Card bg="primary" text="white">
                                <Card.Body>
                                <Card.Title>
                                    <h3>
                                        Mengupload Data
                                    </h3>
                                </Card.Title>
                                <hr style={{borderTop: '1px solid #f4a240'}}/>
                                <Card.Text>
                                    <h4>Nama File : <span>{uploadnamekdl}</span></h4>
                                    <h4>Proses : <span>{progresskdl}% {progresskdl === 100 ? "(Memproses Di Server)" : ""}</span></h4>
                                    <ProgressBar animated  now={progresskdl} variant="warning"/>
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <button type="button" class="btn btn-danger btn-elevate mb-2" onClick={handleClickOpen3} id="delete_all"><i class='fa fa-trash'></i> Hapus Semua</button>
                        </Col>
                    </Row>
                    <table className="table table-hover table-responsive-md" id="kuotadownlinelain" style={{width:'100%',overflow:'auto'}}>
                            <thead className="bg-primary" style={{color:'white'}}>
                                <tr> 
                                    <th class="all"> Nama Provinsi </th>
                                    <th class="all"> Max Kuota</th>
                                    <th class="all"> Aksi </th>
                                </tr>
                            </thead>
                    </table>
                </PortletBody>
            </Portlet>
        </>   
    )
}