/* eslint-disable no-restricted-imports */

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'jquery-mask-plugin'
import $ from 'jquery'
import API from '../../API'
// import $ from 'jquery'
// import { AlertList} from "react-bs-notifier";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = {
        ref
    } {
        ...props
    }
    />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '80%',
    },
}));

export default function DialogEditDP(props) {

    React.useEffect(() => {
        $('#tpnm_delivery_dpname').select2({
            minimumInputLength: 0,
            dropdownParent: $('#droppoint'),
            ajax: {
                type: 'get',
                url: API.Userapi+'v1/Droppoint/masterDP',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    // API.Handle403(xhr,setAlerts);
                },
                data: function (params) {
                    return {
                        "select2" : params.term,
                        "length": 3,
                    };
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.tadp_name,
                                id: item.tadp_name,
                            };
                        })
                    };
                }
            }
        })
    })

    const classes = useStyles();

    return (
      <div>
          <Dialog
              open={props.open}
              TransitionComponent={Transition}
              fullWidth={true}
              maxWidth="sm"
              keepMounted
              onClose={props.close}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              style={{zIndex:'999999999'}}
          >
          <DialogTitle className="dptitle" id="alert-dialog-slide-title">{props.title}</DialogTitle>
          <DialogContent id="pricedialog">
            <form className={classes.root} id="droppoint" autoComplete="off">
                <div className="form-group row">
                <label for="inputEmail3" className="col-sm-5 col-form-label">Drop Point</label>
                <div className="col-sm-7">
                <select className="form-control" id="tpnm_delivery_dpname" name="tpnm_delivery_dpname" style={{width:'100%'}}>
                    <option value="0"> Pilih Drop Point</option>
                </select>
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions style={{height:'100px'}}>
            <Button style={{marginTop:'40px'}} onClick={props.close} color="primary">
              Batal
            </Button>
            <Button style={{marginTop:'40px'}} onClick={props.fun} color="primary">
              {props.btn}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      );

}

