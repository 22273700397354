/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { AlertList} from "react-bs-notifier";
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import DialogHapusBatal from '../Utils/DialogHapusBatal'
import {
    Portlet,
    PortletBody,
    // PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
import Utils from '../Utils/FunctionDetail'
import $ from 'jquery'
import moment from 'moment'
import 'bootstrap-datepicker'
import 'select2'

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset : theme.mixins.toolbar,
    marginTop : '70px'
  } 
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditAdministrator(props) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [alerts, setAlerts] = React.useState([]);
  const [alerts1, setAlerts1] = React.useState([]);
  const [open, setOpen] = React.useState(false);
//   const [save, setSave] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  const [fktp, setFktp] = React.useState(null)
  const [fuser, setFuser] = React.useState(null)
  const [fsrtf, setFsrtf] = React.useState(null)

  
  React.useEffect(() => {
        
      // console.log(props.id)
      $(document).ready(function () {
        $('#tanggal_lahir_1,#vldfrom_1,#vldto_1,#regisdate_1').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: "yyyy-mm-dd",
        });
        $('#vldfrom_1').on('change',function () {
            console.log(moment($('#vldfrom_1').datepicker('getDate')).add(1, 'years').toDate())
            $('#vldto_1').datepicker('setDate', moment($('#vldfrom_1').datepicker('getDate')).add(1, 'years').toDate())
            
        })
        $('#droppoint_1').select2({
          placeholder:"Pilih Droppoint",
          minimumInputLength: 0,
          dropdownParent: $('#testmodal'),
          ajax: {
              type: 'get',
              url: API.Userapi+'/v1/Droppoint/dpTerdaftarAdmin/'+props.mmle,
              headers: {
                  "Authorization": "Baisc "+localStorage.getItem('jwt')
              },
              dataType: "json",
              error: function (xhr, error, thrown, settings) {
                API.Handle403(xhr,setAlerts1);
            },
              data: function (params) {
                  return {
                      "select2" : params.term,
                      "length": 10,
                  };
              },
              processResults: function (response, params) {
                console.log(response.data);
                return {
                  results: $.map(response.data, function (item) {
                          return {
                              text: item.tadp_name,
                              id: item.tadp_id,
                          };
                      })
                  };
              }
          }
        });
        $('#mper_province_1').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal1'),
            ajax: {
                type: 'get',
                url: API.Utilsapi+'v1/address/provinsi',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts1);
                },
                data: function (params) {
                    return {
                        "select2" : params.term,
                        "length": 34,
                    };
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.mprv_name,
                                id: item.mprv_id,
                            };
                        })
                    };
                }
            }
        });
        $('#mper_kota_1').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal1'),
            ajax: {
                type: 'get',
                url: API.Utilsapi+'v1/address/kota',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts1);
                },
                data: function (params) {
                    var mper_provinsi = $( "#mper_province_1 option:selected" ).text();
                    console.log(mper_provinsi);
                    if(  mper_provinsi != 'Pilih Provinsi' ) {
                        return {
                            "select2" : params.term,
                            "mprv": mper_provinsi,
                            "length": 514,
                        };
                    }else{
                        return {
                            "select2" : params.term,
                            "length": 514,
                        };
                    }
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.mcty_name,
                                id: item.mcty_id,
                            };
                        })
                    };
                }
            }
        });
        $('#mper_kec_1').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal1'),
            ajax: {
                type: 'get',
                url: API.Utilsapi+'v1/address/distrik',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts1);
                },
                data: function (params) {
                    var mper_kota = $( "#mper_kota_1 option:selected" ).text();
                    console.log(mper_kota);
                    if( mper_kota != 'Pilih Kota/Kabupaten' ) {
                        return {
                            "select2" : params.term,
                            "mcty": mper_kota,
                            "length": 7215,
                        };
                    }else{
                        return {
                            "select2" : params.term,
                            "length": 7215,
                        };
                    }
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.mdst_name,
                                id: item.mdst_id,
                            };
                        })
                    };
                }
            }
        });
        $('#user_referal_1').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal'),
            ajax: {
                type: 'get',
                url: API.Userapi+'v1/user/referal',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts1);
                },
                data: function (params) {
                    return {
                        "select2" : params.term,
                        "length": 10,
                    };
                },
                processResults: function (response, params) {
                    return {
                        results: $.map(response.data, function (item) {
                            return {
                                text: item.mper_name,
                                id: item.mper_id,
                            };
                        })
                    };
                }
            }
        });
        $('#role_type_1').change( function(){
            var mper_id = props.id
            var role_type = $( "#role_type_1 option:selected" ).val();
            console.log(role_type)
    
            if( role_type ==  11  ){
                $('#dpoint').show("slow");
            }else if ( role_type ==  9  ){
                $('#dpoint').show("slow");
            }else{
                $('#dpoint').hide("slow");
            }
    
            if( role_type !=  1 && role_type != 2 ){
            $('#username_1').show("slow");
            $('#password_1').show("slow");
            $('#div_tbnk_name_1').hide();
            $('#div_tbnk_number_1').hide();
            $('#div_tbnk_receiver_name_1').hide();
            $('#referall_1').hide('slow');
            $('#div_foto_user_1').hide('slow');
            $('#div_foto_ktp_1').hide('slow');
            $('#div_foto_srtf_add_1').hide('slow');
            $('#foto_srtf_delete_1').hide('hide');
            // $('#div_foto_ktp_add').show();
            // $('#div_foto_user_add').show();
            }else{
            $('#div_tbnk_name_1').show("slow");
            $('#div_tbnk_number_1').show("slow");
            $('#div_tbnk_receiver_name_1').show("slow");
            $('#username_1').hide();
            $('#password_1').hide();
            $('#referall_1').show('slow');
            $('#div_foto_user_add_1').show('slow');
            $('#div_foto_ktp_add_1').show('slow');
            $('#div_foto_srtf_add_1').show('slow');
            $('#div_foto_user_1').hide('slow');
            $('#div_foto_ktp_1').hide('slow');
            console.log(" mper "+mper_id);
            if ( Number.isInteger(parseInt(mper_id)) ) {
                $('#foto_srtf_delete_1').show('slow');
            }else{
                $('#foto_srtf_delete_1').hide('hide');
            }
            }
            // 5 "Internal Admin" ;
            // 6 "Admin Operation" ;
            // 7 "Admin Registrasi" ;
            // 8 "Admin Finance" ;
            // 10 "Admin verifikasi";
    
            if (Number.isInteger(parseInt(mper_id))) {
            $('#div_app_type').show();
            $('#app_type').attr('disabled','disabled')
            }
    
            // if( role_type == 5 || role_type == 6 || role_type == 7 || role_type == 8 || role_type == 10 ){
            //     $('#div_app_type').hide();
            // }else{
            //     $('#div_app_type').hide();
            // }
        })
        $('#mper_ic_number_1').keypress(function(event) {
            return /\d/.test(String.fromCharCode(event.keyCode));
        }).bind('keyup paste', function(){
            this.value = this.value.replace(/[^0-9]/g, '');
        });
    })

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": API.Userapi +"v1/user/detail/"+props.id,
      "method": "GET",
      "headers": {
        "Authorization": "Baisc " + localStorage.getItem('jwt')
      }
    }

    
    if (props.id != null && load == true) {  
            $.ajax(settings).done(function (response) {
                console.log(response);
                if( response.data != null ){
                    $('#vldto_1').datepicker('setDate', response.data.mper_valid_to);
                    $('#vldfrom_1').datepicker('setDate', response.data.mper_valid_from);
        
                    $('#role_type_1').append(
                    new Option( Utils.MperAll(response.data.mper_type, response.data.mper_id) , response.data.mper_type, true, true)).trigger('change');
        
                    $('#app_type_1').append(
                    new Option( Utils.AppType(response.data.app_type) , response.data.app_type, true, true)).trigger('change');
        
                    $('#mper_registration_via_1').append(new Option( Utils.StatusRegistrasi(response.data.mper_registration_via) , response.data.mper_registration_via, true, true) ).trigger('change');
                    if( response.data.mper_registration_via == 1 ){
                    $("#pilih_registerasi_offline_1").css("display", "none");
                    }else if(response.data.mper_registration_via == 2){
                    $("#pilih_registerasi_online_1").css("display", "none");
                    }
                    // if( response.data.mper_type == 1){
                    //   $("#tbnk_name").val(response.data.tbnk_name);
                    //   $("#tbnk_number").val(response.data.tbnk_number);
                    //   $("#tbnk_receiver_name").val(response.data.tbnk_receiver_name);
                    //   $('#div_tbnk_name').show();
                    //   $('#div_tbnk_number').show();
                    //   $('#div_tbnk_receiver_name').show();
                    // }
        
                    if( response.data.mper_type !=  1 ){
                    $('#name_1').val(response.data.mper_username);
                    $('#pass_1').val(response.data.mper_password);
                    $('#username_1').show();
                    $('#password_1').show();
                    }else{
                    $("#tbnk_name_1").val(response.data.tbnk_name);
                    $("#tbnk_number_1").val(response.data.tbnk_number);
                    $("#tbnk_receiver_name_1").val(response.data.tbnk_receiver_name);
                    $('#div_tbnk_name_1').show();
                    $('#div_tbnk_number_1').show();
                    $('#div_tbnk_receiver_name_1').show();
                    }
        
                    if( response.data.mper_referal_id !=  null || response.data.mper_referal_id != "" ){
                    Utils.Referal( response.data.mper_referal_id, '#user_referal_1');
                    }
                    $('#mper_ic_number_1').val(response.data.mper_ic_number);
                    $('#mper_name_1').val(response.data.mper_name);
                    $('#mper_phone_1').val(response.data.mper_phone);
                    $('#mper_emergency_phone_1').val(response.data.mper_emergency_phone);
                    $('#tanggal_lahir_1').datepicker('setDate', response.data.mper_dob);
                    $('#mper_address_1').val(response.data.mper_address);
        
                    $('input[name=mper_status_1][value='+response.data.mper_status+']').prop("checked", true)
        
                    if( response.data.mper_province != "" ){
                        Utils.setProvinsi(response.data.mper_province,'#mper_province_1');
                    }else{
                        $('#mper_province_1').val("???????");
                    }
                    if( response.data.mper_district != "" ){
                        Utils.setKecamatan(response.data.mper_district,'#mper_kec_1');
                    }else{
                        $('#mper_kec_1').val("???????");
                    }
                    if( response.data.mper_city != "" ){
                        Utils.setKota(response.data.mper_city,'#mper_kota_1');
                    }else{
                        $('#mper_kota_1').val("???????");
                    }
                    console.log(response);
                    console.log(response.data.droppoint);
                    $('#mper_postal_code_1').val(response.data.mper_postal_code);
                    $('#mper_last_job_1').val(response.data.mper_last_job);
        
                    if (response.data.mper_type == 11) {
                        for (let index = 0; index < response.data.droppoint.length; index++) {
                            $('#droppoint_1').append(new Option(response.data.droppoint[index].tadp_name, response.data.droppoint[index].tadp_id, true, true)).trigger('change');
                        }
                    }else if (response.data.mper_type == 9) {
                        for (let index = 0; index < response.data.droppoint.length; index++) {
                            $('#droppoint_1').append(new Option(response.data.droppoint[index].tadp_name, response.data.droppoint[index].tadp_id, true, true)).trigger('change');
                        }
                    }
                    if( response.data.mper_srtf_user != null ){
                    $('#foto_srtf_delete_1').show('show');
                    }else{
                    $('#foto_srtf_delete_1').hide('hide');
                    }
                    $('#mper_type_1').val( Utils.MperAll(response.data.mper_type, response.data.mper_type) );
                    $('#regisdate_1').datepicker('setDate', response.data.mper_registration_date);
                    $('#app_type_1').val(response.data.app_type);
                    // $('#mper_registration_via').val( Utils.StatusRegistrasi(response.data.mper_registration_via) );
                    $('#mper_status_1').val( Utils.StatusUser(response.data.mper_status) );
                    // $('#tgl_valid').val(response.data.mper_valid_from+" - "+response.data.mper_valid_to);
                    setLoad(false)
                }
            }).fail(function (response) {
                console.log(response)
                API.Handle403(response,setAlerts1)
            })
    }
    // }

    })

    const Submit = () => {
        var url
        var settings

        var mper_ic_number = $('#mper_ic_number_1').val();
        var mper_name = $('#mper_name_1').val();
        var mper_phone = $('#mper_phone_1').val();
        var mper_emergency_phone = $('#mper_emergency_phone_1').val();
        var tanggal_lahir = $('#tanggal_lahir_1').val();
        var mper_address = $('#mper_address_1').val();

        var mper_province = $( "#mper_province_1 option:selected" ).val();
        var mper_kota = $( "#mper_kota_1 option:selected" ).val();
        var mper_kec = $( "#mper_kec_1 option:selected" ).val();

        var mper_postal_code = $('#mper_postal_code_1').val();
        var mper_last_job = $('#mper_last_job_1').val();
        var role_type = $('#role_type_1').val();
        var mper_username = $('#name_1').val();
        var mper_password = $('#pass_1').val();
        var mper_dpadmin = $('#droppoint_1').val();

        var mper_referal_id = $("#user_referal_1 option:selected").val();

        var mper_registration_date = $('#regisdate_1').val();
        var mper_registration_via = $('#mper_registration_via_1').val();
        if(mper_registration_via == "Offline"){
            mper_registration_via = "1"
        }else if(mper_registration_via == "Online"){
            mper_registration_via = "2"
        }
        var mper_vaild_from = $('#vldfrom_1').val();
        var mper_valid_to = $('#vldto_1').val();
        var mper_status = $('input[name=mper_status_1]:checked').val();
        if( mper_status == "Non Aktif" ) {
            mper_status = 1 ;
        }else if(mper_status == "Aktif") {
            mper_status = 0 ;
        }
        var tbnk_name = $('#tbnk_name_1').val();
        var tbnk_number = $('#tbnk_number_1').val();
        var tbnk_receiver_name = $('#tbnk_receiver_name_1').val();

        if(props.id != null){
            url = API.Userapi+"v1/user/update/"+props.id+"?mmle_id="+props.mmle
            var data = {
                'mper_ic_number': mper_ic_number,
                'mper_name': mper_name,
                'mper_phone': mper_phone,
                'mper_emergency_phone': mper_emergency_phone,
                'mper_dob': tanggal_lahir,
                'mper_address': mper_address,
                'mper_province': mper_province,
                'mper_city': mper_kota,
                'mper_district': mper_kec,
                'mper_postal_code': mper_postal_code,
                'mper_last_job': mper_last_job,
                'mper_type': role_type,
                'mper_registration_date': mper_registration_date,
                'mper_registration_via': mper_registration_via,
                'mper_valid_from': mper_vaild_from,
                'mper_valid_to': mper_valid_to,
                'mper_status': mper_status,
                // 'app_type': app_type,
            };
            if( data['mper_type'] != 1 ){
                data['mper_username'] = mper_username ;
                data['mper_password'] = mper_password ;
            }
            if( mper_referal_id != "" && mper_referal_id != null && mper_referal_id != 'null' ){
                data['mper_referal_id'] = mper_referal_id ;
            }
            if( tbnk_name != "" && tbnk_number != "" && tbnk_receiver_name != ""){
                data['tbnk_name'] = tbnk_name ;
                data['tbnk_number'] = tbnk_number ;
                data['tbnk_receiver_name'] = tbnk_receiver_name ;
            }
    
            if (data['mper_type'] == 11) {
            data['tadp_array_id'] = mper_dpadmin.toString() ;  // Dpoint
            } else if (data['mper_type'] == 9){
            data['tadp_array_id'] = mper_dpadmin.toString() ;
            }
            console.log(JSON.stringify(data));
            settings = {
                "async": true,
                "crossDomain": true,
                "url": url,
                "method": "POST",
                "headers": {
                "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                "processData": false,
                "contentType": false,
                "mimeType": "multipart/form-data",
                "data": JSON.stringify(data)
            }
        }else{
            url = API.Userapi+"v1/user/addUser?mmle_id="+props.mmle
            var form = new FormData();
            // form.append("app_type", app_type);
            form.append("mper_ic_number", mper_ic_number);
            form.append("mper_name", mper_name);
            form.append("mper_phone", mper_phone);
            form.append("mper_dob", tanggal_lahir);
            form.append("mper_address", mper_address);
            form.append("mper_postal_code", mper_postal_code);
            form.append("mper_province", mper_province);
            form.append("mper_city", mper_kota);
            form.append("mper_type", role_type);
            form.append("mper_district", mper_kec);
            form.append("mper_last_job", mper_last_job);
            form.append("mper_emergency_phone", mper_emergency_phone);
            form.append("mper_registration_date", mper_registration_date);
            form.append("mper_registration_via", mper_registration_via);
            form.append("mper_valid_from", mper_vaild_from);
            form.append("mper_valid_to", mper_valid_to);
            form.append("foto_user", fuser);
            form.append("foto_ktp", fktp);
            form.append("foto_srtf", fsrtf);
            // form.append("app_type", app_type);

            if( role_type != 1 ){
                form.append('mper_username', mper_username) ;
                form.append('mper_password', mper_password) ;
            }
            // else{
            // form['mper_face_img_id'] = foto_user ;
            // form['mper_medi_ktp'] = foto_ktp ;
            // }
            if( mper_referal_id != "" && mper_referal_id != null && mper_referal_id != 'null' ){
                form.append('mper_referal_id', mper_referal_id);
            }
            if( tbnk_name != "" && tbnk_number != "" && tbnk_receiver_name != ""){
                form.append('tbnk_name', tbnk_name) ;
                form.append('tbnk_number', tbnk_number) ;
                form.append('tbnk_receiver_name', tbnk_receiver_name) ;
            }

            if (role_type == 11) {
            form.append('tadp_array_id', mper_dpadmin) ; // Dpoint
            } else if (role_type == 9){
            form.append('tadp_array_id', mper_dpadmin) ;
            }
            settings = {
                "async": true,
                "crossDomain": true,
                "url": url,
                "method": "POST",
                "headers": {
                  "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                "processData": false,
                "contentType": false,
                "mimeType": "multipart/form-data",
                "data": form
            }
        }
        console.log(url)
        handleClose()


        $.ajax(settings).done(function (response) {
            setLoad(true)
            console.log(response)
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "User Telah Disimpan"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            handleClose()
            Close()
        }).fail(function (response) {
            handleClose()
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts1)
        })
    }

    function handleClickOpen() {
        setOpen(true);
        // setSave(true)
    }

    function handleClose() {
        setOpen(false);
    }

    function Close() {
        props.close()
        setLoad(true)
    }

    function FotoKTP(e) {
        if (props.id != null) {
            var form = new FormData();
            form.append("key", "foto_ktp");
            form.append("mper_id", props.id);
            form.append("upload_data", e.target.files[0]);
            var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/user/addFoto",
            "method": "POST",
            "headers": {
                "Authorization": "Baisc "+localStorage.getItem('jwt')
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": form
            }
            $.ajax(settings).done(function (response) {
                setAlerts1([{
                    id: 1,
                    type: "success",
                    headline: "Berhasil",
                    message: "Foto KTP Telah Diupload"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
            }).fail(function (response) {
                setAlerts1([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code "+response.status+"]"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
                API.Handle403(response,setAlerts1)
            })
        }else{
            setFktp(e.target.files[0])
        }
    }

    function FotoUser(e) {
        if (props.id != null) {
            var form = new FormData();
            form.append("key", "foto_user");
            form.append("mper_id", props.id);
            form.append("upload_data", e.target.files[0]);
            var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/user/addFoto",
            "method": "POST",
            "headers": {
                "Authorization": "Baisc "+localStorage.getItem('jwt')
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": form
            }
            $.ajax(settings).done(function (response) {
                setAlerts1([{
                    id: 1,
                    type: "success",
                    headline: "Berhasil",
                    message: "Foto USER Telah Diupload"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
            }).fail(function (response) {
                setAlerts1([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code "+response.status+"]"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
                API.Handle403(response,setAlerts1)
            })
        } else {
            setFuser(e.target.files[0])
        }
    }

    function FotoSrtfMobil(e) {
        if (props.id != null) {
            var form = new FormData();
            form.append("key", "foto_srtf");
            form.append("mper_id", props.id);
            form.append("upload_data", e.target.files[0]);
            var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/user/addFoto",
            "method": "POST",
            "headers": {
                "Authorization": "Baisc "+localStorage.getItem('jwt')
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": form
            }
            $.ajax(settings).done(function (response) {
                setAlerts1([{
                    id: 1,
                    type: "success",
                    headline: "Berhasil",
                    message: "Sertifikat Mobil Telah Diupload"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
            }).fail(function (response) {
                setAlerts1([{
                    id: 1,
                    type: "danger",
                    headline: "Gagal",
                    message: "Terjadi Kendala [Error Code "+response.status+"]"
                }])
                setTimeout(() => {
                    setAlerts1([])
                }, 3000)
                API.Handle403(response,setAlerts1)
            })
        } else {
            setFsrtf(e.target.files[0])
        }
    }

  return (
    <div>
        <DialogHapusBatal 
                open={open} 
                close={handleClose} 
                title={props.id != null ? "Edit Administrator" : "Tambah Administrator"}
                message="Anda Yakin Ingin Menyimpan User Ini ?" 
                btn="Simpan"
                fun={Submit} 
                // type="delete" 
                // id={id}
            />
        <AlertList showIcon={false} alerts={alerts}/>
        <Dialog fullScreen open={props.open} onClose={props.close} id="testmodal" TransitionComponent={Transition}>
        <AlertList showIcon={false} alerts={alerts1}/>
            <AppBar className={classes.appBar}>
            <Toolbar>                       
                <Typography variant="h6" className={classes.title}>
                    {props.title}
                </Typography>
                <IconButton edge="start" color="inherit" onClick={Close} aria-label="Close">
                <CloseIcon />
                </IconButton>
            </Toolbar>
            </AppBar>
            <Portlet className={classes.detail}>
                    <PortletBody>
                        <Row>
                            <Col md={6}>
                                <div className="form-group row" id="testmodal1">
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Role<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select id="role_type_1" className="form-control">
                                            <option value="0" selected disabled> Pilih Role</option>
                                            <option value="1" id='role_type_eksekutor' style={{display: 'none'}}>Eksekutor </option>
                                            <option value="2" id='role_type_informan'>Informan </option>
                                            <option value="11" id='role_type_leasing_cabang_eksekutor'>Leasing Cabang & Eksekutor </option>
                                            <option value="9" id='role_type_leasing_cabang'>Leasing Cabang </option>
                                            <option value="3" id='role_type_leasing_admin'>Leasing Admin</option>
                                            <option value="4" id='role_type_leasing_approver'>Leasing Approver</option>
                                            <option value="6" id='role_type_admin_operation' style={{display: 'none'}}>Admin Operation </option>
                                            <option value="8" id='role_type_admin_finance' style={{display: 'none'}}>Admin Finance </option>
                                            <option value="10" id='role_type_admin_verifikasi' style={{display: 'none'}}>Admin Verifikasi </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor KTP<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" placeholder="Contoh : 3710298703432156" className="form-control" id="mper_ic_number_1" minlength="16" maxlength="16" required/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nama<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_name_1" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor Handphone<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_phone_1" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor Handphone Darurat</label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_emergency_phone_1" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Tanggal Lahir<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="tanggal_lahir_1" autocomplete="off"/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Alamat<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <textarea type="text" className="form-control" rows="4" id="mper_address_1" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Provinsi<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select className="form-control" id="mper_province_1">
                                            <option value="0" selected disabled> Pilih Provinsi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Kota/Kabupaten<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select className="form-control" id="mper_kota_1">
                                            <option value="0" selected disabled> Pilih Kota/Kabupaten</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Kecamatan<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select className="form-control" id="mper_kec_1">
                                            <option value="0" selected disabled> Pilih Kecamatan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Kode Pos</label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_postal_code_1" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Pekerjaan Terakhir<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="mper_last_job_1" />
                                    </div>
                                </div>
                                <div className="form-group row" id="username_1" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Username</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="name_1"/>
                                    </div>
                                </div>
                                <div className="form-group row" id="password_1" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Password</label>
                                    <div className="col-sm-8">
                                        <input type="password" className="form-control" id="pass_1"/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Status</label>
                                    <div className="col-sm-8">
                                        <div className="form-check form-check-inline mt-1">
                                            <input className="form-check-input" type="radio" name="mper_status_1" id="mper_status_1" value="0"/>
                                            <label className="form-check-label" for="exampleRadios1">
                                               Tidak Aktif
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline mt-1">
                                            <input className="form-check-input" type="radio" name="mper_status_1" id="mper_status_1" value="1"/>
                                            <label className="form-check-label" for="exampleRadios1">
                                               Aktif
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row" id="referall_1" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">User Referral</label>
                                    <div className="col-sm-8">
                                        <select className="form-control" id="user_referal_1" style={{width:'100%'}}>
                                            <option value="0" selected disabled> Pilih Referal</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Registrasi<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="regisdate_1"/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Registrasi Via<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <select className="form-control" id="mper_registration_via_1">
                                            <option id="pilih_registerasi_offline_1">Offline</option>
                                            <option id="pilih_registerasi_online_1">Online</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Valid<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <div className="input-daterange input-group date" id="kt_datepicker_5">
                                            <input type="text" className="form-control" name="start" id="vldfrom_1" autocomplete="off"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">To</span>
                                            </div>
                                            <input type="text" className="form-control" name="end" id="vldto_1" autocomplete="off"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row" id="div_foto_ktp_add_1" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Foto KTP<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8 custom-file">
                                        <input type="file" className="form-control" onChange={FotoKTP}/>
                                    </div>
                                </div>
                                <div className="form-group row" id="div_foto_user_add_1" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Foto User<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8 custom-file">
                                        <input type="file" className="form-control" onChange={FotoUser}/>
                                    </div>
                                </div>
                                <div className="form-group row" id="div_foto_srtf_add_1" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Sertifikat Mobil</label>
                                    <div className="col-sm-8 custom-file">
                                        <input type="file" className="form-control" onChange={FotoSrtfMobil}/>
                                    </div>
                                </div>
                                <div className="form-group row" id="div_tbnk_name_1" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Bank<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="tbnk_name_1" />
                                    </div>
                                </div>
                                <div className="form-group row" id="div_tbnk_number_1" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">No Rekening<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="tbnk_number_1" />
                                    </div>
                                </div>
                                <div className="form-group row" id="div_tbnk_receiver_name_1" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Atas Nama Rekening<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="tbnk_receiver_name_1" />
                                    </div>
                                </div>
                                 <div className="form-group row" id="dpoint" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Drop Point<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <select className="form-control" id="droppoint_1" multiple style={{width:'100%'}} placeholder="Pilih Drop Point">
                                            <option value="0" disabled> Pilih Drop Point</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div class="form-group" id="dpoint">
                                    <label class="col-md-4 control-label dptitle"> Droppoint </label>
                                    <div class="col-md-7">
                                        <select id="droppoint" multiple>
                                            <option value="0" disabled>Pilih Droppoint</option>
                                        </select>
                                    </div>
                                </div> */}
                            </Col>
                            <Col md={6}>
                            </Col>
                        </Row>
                        <Row>
                          <Col md>
                            <button type="button" className="btn btn-warning btn-elevate" onClick={Close}><i class="fas fa-arrow-left"></i>Kembali</button>
                            <button type="button" className="btn btn-brand btn-elevate pull-right" onClick={handleClickOpen}><i className="fas fa-save"></i>Simpan</button>
                          </Col>
                        </Row>
                        {/* <button type="button" className="btn btn-brand btn-elevate" onClick={handleClickOpen}>Simpan</button>
                        <button type="button" className="btn btn-brand btn-elevate pull-right" onClick={handleClickOpen}>Simpan</button> */}
                    </PortletBody>
                </Portlet>
     </Dialog>
    </div>
  );
}
