/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { AlertList} from "react-bs-notifier";
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Row, Col} from 'react-bootstrap';
import DialogHapusBatal from '../Utils/DialogHapusBatal'
import {
    Portlet,
    PortletBody,
    // PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import Box from '@material-ui/core/Box';
import API from '../../API'
import Utils from '../Utils/FunctionDetail'
import $ from 'jquery'
import 'bootstrap-datepicker'
import 'select2'

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  detail: {
    offset : theme.mixins.toolbar,
    marginTop : '70px'
  } 
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditUser(props) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [alerts, setAlerts] = React.useState([]);
  const [alerts1, setAlerts1] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [save, setSave] = React.useState(false);
  
  React.useEffect(() => {
    $(document).ready(function () {
        $('#tanggal_lahir,#vldfrom,#vldto,#regisdate,#mper_last_login_date').datepicker({
            todayHighlight: true,
            autoclose: true,
            format: "yyyy-mm-dd",
        });
        $('#mper_province').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal'),
            ajax: {
                type: 'get',
                url: API.Utilsapi+'v1/address/provinsi',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts);
                },
                data: function (params) {
                    return {
                        "select2" : params.term,
                        "length": 34,
                    };
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.mprv_name,
                                id: item.mprv_id,
                            };
                        })
                    };
                }
            }
        }).on('change', function(){
            // $('#mper_kota,#mper_kec').val(0).trigger('change');
        });
        $('#mper_kota').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal'),
            ajax: {
                type: 'get',
                url: API.Utilsapi+'v1/address/kota',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts);
                },
                data: function (params) {
                    var mper_provinsi = $( "#mper_province option:selected" ).text();
                    console.log(mper_provinsi);
                    if(  mper_provinsi != 'Pilih Provinsi' ) {
                        return {
                            "select2" : params.term,
                            "mprv": mper_provinsi,
                            "length": 514,
                        };
                    }else{
                        return {
                            "select2" : params.term,
                            "length": 514,
                        };
                    }
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.mcty_name,
                                id: item.mcty_id,
                            };
                        })
                    };
                }
            }
        }).on('change', function(){
            //$('#mper_kec').val(0).trigger('change');
        });
        $('#mper_kec').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal'),
            ajax: {
                type: 'get',
                url: API.Utilsapi+'v1/address/distrik',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts);
                },
                data: function (params) {
                    var mper_kota = $( "#mper_kota option:selected" ).text();
                    console.log(mper_kota);
                    if( mper_kota != 'Pilih Kota/Kabupaten' ) {
                        return {
                            "select2" : params.term,
                            "mcty": mper_kota,
                            "length": 7215,
                        };
                    }else{
                        return {
                            "select2" : params.term,
                            "length": 7215,
                        };
                    }
                },
                processResults: function (data, params) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.mdst_name,
                                id: item.mdst_id,
                            };
                        })
                    };
                }
            }
        });
        $('#user_referal').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal'),
            ajax: {
                type: 'get',
                url: API.Userapi+'v1/user/referal',
                headers: {
                    "Authorization": "Baisc "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts);
                },
                data: function (params) {
                    return {
                        "select2" : params.term,
                        "length": 10,
                    };
                },
                processResults: function (response, params) {
                    return {
                        results: $.map(response.data, function (item) {
                            return {
                                text: item.mper_name,
                                id: item.mper_id,
                            };
                        })
                    };
                }
            }
        });

        $('#role_type').select2({
            minimumInputLength: 0,
            dropdownParent: $('#testmodal'),
            ajax: {
                type: 'get',
                url: API.Userapi+'v1/user/mperole',
                headers: {
                    "Authorization": "Basic "+localStorage.getItem('jwt')
                },
                dataType: "json",
                error: function (xhr, error, thrown, settings) {
                    API.Handle403(xhr,setAlerts);
                },
                data: function (params) {
                    return {
                        "select2" : params.term,
                        "length": 11,
                    };
                },
                processResults: function (response, params) {
                    return {
                        results: $.map(response.data, function (item) {
                            return {
                                text: item.mple_name,
                                id: item.mper_type,
                            };
                        })
                    };
                }
            }
        }); 
            
        // $('#user_referal').val(0).trigger('change');

        var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Userapi +"v1/user/detail/"+props.id,
        "method": "GET",
        "headers": {
            "Authorization": "Baisc " + localStorage.getItem('jwt')
        }
        }
        
        if (props.id != null && save == false) {      
        $.ajax(settings).done(function (response) {
            console.log(response);
            if( response.data != null ){
                $('#vldto').datepicker('setDate', response.data.mper_valid_to);
                $('#vldfrom').datepicker('setDate', response.data.mper_valid_from);
    
                $('#role_type').append(
                new Option( Utils.MperAll(response.data.mper_type, response.data.mper_id) , response.data.mper_type, true, true)).trigger('change');
    
                $('#app_type').append(
                new Option( Utils.AppType(response.data.app_type) , response.data.app_type, true, true)).trigger('change');
    
                $('#mper_registration_via').append(new Option( Utils.StatusRegistrasi(response.data.mper_registration_via) , response.data.mper_registration_via, true, true) ).trigger('change');
                if( response.data.mper_registration_via == 1 ){
                $("#pilih_registerasi_offline").css("display", "none");
                }else if(response.data.mper_registration_via == 2){
                $("#pilih_registerasi_online").css("display", "none");
                }
                // if( response.data.mper_type == 1){
                //   $("#tbnk_name").val(response.data.tbnk_name);
                //   $("#tbnk_number").val(response.data.tbnk_number);
                //   $("#tbnk_receiver_name").val(response.data.tbnk_receiver_name);
                //   $('#div_tbnk_name').show();
                //   $('#div_tbnk_number').show();
                //   $('#div_tbnk_receiver_name').show();
                // }
    
                if( response.data.mper_type !=  1 ){
                $('#name').val(response.data.mper_username);
                $('#pass').val(response.data.mper_password);
                $('#username').show();
                $('#password').show();
                }else{
                $("#tbnk_name").val(response.data.tbnk_name);
                $("#tbnk_number").val(response.data.tbnk_number);
                $("#tbnk_receiver_name").val(response.data.tbnk_receiver_name);
                $('#div_tbnk_name').show();
                $('#div_tbnk_number').show();
                $('#div_tbnk_receiver_name').show();
                }

                if(response.data.mper_referal_id !=  null){
                    Utils.Referal(response.data.mper_referal_id, '#user_referal');
                }else{
                    $('#user_referal').val(0).trigger('change');
                }

                if(response.data.mper_type !=  null){
                    Utils.RoleType(response.data.mper_type, '#role_type');
                }else{
                    $('#role_type').val(0).trigger('change');
                }
                $('#mper_ic_number').val(response.data.mper_ic_number);
                $('#mper_name').val(response.data.mper_name);
                $('#mper_phone').val(response.data.mper_phone);
                $('#mper_emergency_phone').val(response.data.mper_emergency_phone);
                $('#tanggal_lahir').datepicker('setDate', response.data.mper_dob);
                $('#mper_address').val(response.data.mper_address);
    
                $('input[name=mper_status][value='+response.data.mper_status+']').prop("checked", true)
    
                if( response.data.mper_province != "" ){
                    Utils.setProvinsi(response.data.mper_province,'#mper_province');
                }else{
                    $('#mper_province').val("???????");
                }
                if( response.data.mper_city != "" ){
                    Utils.setKota(response.data.mper_city,'#mper_kota');
                }else{
                    $('#mper_kota').val("???????");
                }
                if( response.data.mper_district != "" ){
                    Utils.setKecamatan(response.data.mper_district,'#mper_kec');
                }else{
                    $('#mper_kec').val("???????");
                }
                console.log(response);
                console.log(response.data.droppoint);
                $('#mper_postal_code').val(response.data.mper_postal_code);
                $('#mper_last_job').val(response.data.mper_last_job);
    
                if (response.data.mper_type == 11) {
                for (let index = 0; index < response.data.droppoint.length; index++) {
                    $('#droppoint').append(new Option(response.data.droppoint[index].tadp_name, response.data.droppoint[index].tadp_id, true, true)).trigger('change');
                }
                }else if (response.data.mper_type == 9) {
                for (let index = 0; index < response.data.droppoint.length; index++) {
                    $('#droppoint').append(new Option(response.data.droppoint[index].tadp_name, response.data.droppoint[index].tadp_id, true, true)).trigger('change');
                }
                }

                if (response.data.mper_medi_ktp != null) {
                    $('#lihatKTP').attr("href", response.data.mper_medi_ktp).show('slow');
                    $('#changeKTP').show('slow');
                    $('#cancelKTP, #inputKTP').hide('slow');
                    $(document).on('click','#changeKTP',function () {
                        $('#inputKTP').show('slow');
                        $('#cancelKTP').show();
                        $('#changeKTP,#lihatKTP').hide();
                    })
                    $(document).on('click','#cancelKTP',function () {
                        $('#inputKTP').hide('slow');
                        $('#cancelKTP').hide();
                        $('#changeKTP,#lihatKTP').show();
                    })
                } else {
                    $('#inputKTP').show('slow');
                    $('#cancelKTP,#changeKTP,#lihatKTP').hide();
                }

                if (response.data.mper_medi_profile != null) {
                    $('#lihatUser').attr("href", response.data.mper_medi_profile).show('slow');
                    $('#changeUser').show('slow');
                    $('#cancelUser, #inputUser').hide('slow');
                    $(document).on('click','#changeUser',function () {
                        $('#inputUser').show('slow');
                        $('#cancelUser').show();
                        $('#changeUser,#lihatUser').hide();
                    })
                    $(document).on('click','#cancelUser',function () {
                        $('#inputUser').hide('slow');
                        $('#cancelUser').hide();
                        $('#changeUser,#lihatUser').show();
                    })
                } else {
                    $('#inputUser').show('slow');
                    $('#cancelUser,#changeUser,#lihatUser').hide();
                }

                if (response.data.mper_srtf_user != null) {
                    $('#lihatSrtf').attr("href", response.data.mper_srtf_user).show('slow');
                    $('#deleteSrtf').show('slow');
                    $('#cancelSrtf, #inputSrtf').hide('slow');
                    // $(document).on('click','#deleteSrtf',function () {
                    //     $('#inputSrtf').show('slow');
                    //     $('#cancelSrtf').show();
                    //     $('#deleteSrtf,#lihatSrtf').hide();
                    // })
                    // $(document).on('click','#cancelSrtf',function () {
                    //     $('#inputSrtf').hide('slow');
                    //     $('#cancelSrtf').hide();
                    //     $('#deleteSrtf,#lihatSrtf').show();
                    // })
                } else {
                    $('#inputSrtf').show('slow');
                    $('#cancelSrtf,#deleteSrtf,#lihatSrtf').hide();
                }

                $('#mper_type').val( Utils.MperAll(response.data.mper_type, response.data.mper_type) );
                $('#regisdate').datepicker('setDate', response.data.mper_registration_date);
                $('#app_type').val(response.data.app_type);
                // $('#mper_registration_via').val( Utils.StatusRegistrasi(response.data.mper_registration_via) );
                $('#mper_status').val( Utils.StatusUser(response.data.mper_status) );
                // $('#tgl_valid').val(response.data.mper_valid_from+" - "+response.data.mper_valid_to);
            }
        }).fail(function (response) {
            console.log(response)
            API.Handle403(response,setAlerts)
        })
        }       

        $('#role_type').change( function(){
        var mper_id = props.id
        var role_type = $( "#role_type option:selected" ).val();
        if( role_type !=  1 && role_type != 2 ){
        $('#username').show("slow");
        $('#password').show("slow");
        $('#div_tbnk_name').hide();
        $('#div_tbnk_number').hide();
        $('#div_tbnk_receiver_name').hide();
        $('#referall').hide('slow');
        $('#div_foto_user').hide('slow');
        $('#div_foto_ktp').hide('slow');
        $('#div_foto_srtf_add').hide('slow');
        $('#foto_srtf_delete').hide('hide');
        // $('#div_foto_ktp_add').show();
        // $('#div_foto_user_add').show();
        }else{
        $('#div_tbnk_name').show("slow");
        $('#div_tbnk_number').show("slow");
        $('#div_tbnk_receiver_name').show("slow");
        $('#username').hide();
        $('#password').hide();
        $('#referall').show('slow');
        $('#div_foto_user_add').show('slow');
        $('#div_foto_ktp_add').show('slow');
        $('#div_foto_srtf_add').show('slow');
        $('#div_foto_user').hide('slow');
        $('#div_foto_ktp').hide('slow');
        console.log(" mper "+mper_id);
        if ( Number.isInteger(parseInt(mper_id)) ) {
            $('#foto_srtf_delete').show('slow');
        }else{
            $('#foto_srtf_delete').hide('hide');
        }
        }
        // 5 "Internal Admin" ;
        // 6 "Admin Operation" ;
        // 7 "Admin Registrasi" ;
        // 8 "Admin Finance" ;
        // 10 "Admin verifikasi";

        if (Number.isInteger(parseInt(mper_id))) {
        $('#div_app_type').show();
        $('#app_type').attr('disabled','disabled')
        }

        // if( role_type == 5 || role_type == 6 || role_type == 7 || role_type == 8 || role_type == 10 ){
        //     $('#div_app_type').hide();
        // }else{
        //     $('#div_app_type').hide();
        // }
        })
    })
  })

    const Submit = () => {
        var mper_ic_number = $('#mper_ic_number').val();
        var mper_name = $('#mper_name').val();
        var mper_phone = $('#mper_phone').val();
        var mper_emergency_phone = $('#mper_emergency_phone').val();
        var tanggal_lahir = $('#tanggal_lahir').val();
        var mper_address = $('#mper_address').val();

        var mper_province = $( "#mper_province option:selected" ).val();
        var mper_kota = $( "#mper_kota option:selected" ).val();
        var mper_kec = $( "#mper_kec option:selected" ).val();

        var mper_postal_code = $('#mper_postal_code').val();
        var mper_last_job = $('#mper_last_job').val();
        var role_type = $('#role_type').val();
        var mper_username = $('#name').val();
        var mper_password = $('#pass').val();
        var mper_dpadmin = $('#droppoint').val();

        var mper_referal_id = $("#user_referal option:selected").val();

        var mper_registration_date = $('#regisdate').val();
        var mper_registration_via = $('#mper_registration_via').val();
        if(mper_registration_via == "Offline"){
            mper_registration_via = "1"
        }else if(mper_registration_via == "Online"){
            mper_registration_via = "2"
        }
        var mper_vaild_from = $('#vldfrom').val();
        var mper_valid_to = $('#vldto').val();
        var mper_status = $('input[name=mper_status]:checked').val();
        if( mper_status == "Non Aktif" ) {
            mper_status = 1 ;
        }else if(mper_status == "Aktif") {
            mper_status = 0 ;
        }
        var tbnk_name = $('#tbnk_name').val();
        var tbnk_number = $('#tbnk_number').val();
        var tbnk_receiver_name = $('#tbnk_receiver_name').val();

        var data = {
            'mper_ic_number': mper_ic_number,
            'mper_name': mper_name,
            'mper_phone': mper_phone,
            'mper_emergency_phone': mper_emergency_phone,
            'mper_dob': tanggal_lahir,
            'mper_address': mper_address,
            'mper_province': mper_province,
            'mper_city': mper_kota,
            'mper_district': mper_kec,
            'mper_postal_code': mper_postal_code,
            'mper_last_job': mper_last_job,
            'mper_type': role_type,
            'mper_registration_date': mper_registration_date,
            'mper_registration_via': mper_registration_via,
            'mper_valid_from': mper_vaild_from,
            'mper_valid_to': mper_valid_to,
            'mper_status': mper_status,
            // 'app_type': app_type,
        };
        if( data['mper_type'] != 1 ){
            data['mper_username'] = mper_username ;
            data['mper_password'] = mper_password ;
        }
        if( mper_referal_id != "" && mper_referal_id != null && mper_referal_id != 'null' ){
            data['mper_referal_id'] = mper_referal_id ;
        }
        if( tbnk_name != "" && tbnk_number != "" && tbnk_receiver_name != ""){
            data['tbnk_name'] = tbnk_name ;
            data['tbnk_number'] = tbnk_number ;
            data['tbnk_receiver_name'] = tbnk_receiver_name ;
        }

        if (data['mper_type'] == 11) {
            data['tadp_array_id'] = mper_dpadmin.toString() ;  // Dpoint
        } else if (data['mper_type'] == 9){
            data['tadp_array_id'] = mper_dpadmin.toString() ;
        }

        if ($('#mper_last_login_date').val() != null && $('#mper_last_login_date').val() != "") {
            data['mper_last_login_date'] =  $('#mper_last_login_date').val()
            data['mper_mobil_last_login_date'] =  $('#mper_last_login_date').val()
        }
        console.log(data);

        // eslint-disable-next-line no-unused-vars
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/user/update/"+props.id,
            "method": "POST",
            "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
            },
            "processData": false,
            "contentType": false,
            "mimeType": "multipart/form-data",
            "data": JSON.stringify(data)
        }

        $.ajax(settings).done(function (response) {
            handleClose()
            console.log(response)
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "User Telah Diubah"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
            setSave(false)
            props.close()
            return 0;
        }).fail(function (response) {
            handleClose()
            console.log(response)
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts)
            return 0
        })
    }

    function HapusSrtf() {
        handleClose1()
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": API.Userapi+"v1/user/deleteFotoSrtf/"+props.id,
            "method": "POST",
            "headers": {
                "Authorization": "Baisc "+localStorage.getItem('jwt')
            },
        }

        $.ajax(settings).done(function (response) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Foto Sertifikat Mobil Telah Dihapus"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
        }).fail(function (response) {
            console.log(response)
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    function handleClickOpen() {
        setOpen(true);
        setSave(true)
    }

    function handleClose() {
        setOpen(false);
    }

    function handleClickOpen1() {
        setOpen1(true);
    }

    function handleClose1() {
        setOpen1(false);
    }

    function Close() {
        props.close()
        setSave(false)
    }

    function FotoKTP(e) {
        var form = new FormData();
        form.append("key", "foto_ktp");
        form.append("mper_id", props.id);
        form.append("upload_data", e.target.files[0]);
        var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Userapi+"v1/user/addFoto",
        "method": "POST",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form
        }

        $.ajax(settings).done(function (response) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Foto KTP Telah Diubah"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
        }).fail(function (response){
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    function FotoUser(e) {
        var form = new FormData();
        form.append("key", "foto_user");
        form.append("mper_id", props.id);
        form.append("upload_data", e.target.files[0]);
        var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Userapi+"v1/user/addFoto",
        "method": "POST",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form
        }
        $.ajax(settings).done(function (response) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Foto User Telah Diubah"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
        }).fail(function (response){
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

    function FotoSrtfMobil(e) {
        var form = new FormData();
        form.append("key", "foto_srtf");
        form.append("mper_id", props.id);
        form.append("upload_data", e.target.files[0]);
        var settings = {
        "async": true,
        "crossDomain": true,
        "url": API.Userapi+"v1/user/addFoto",
        "method": "POST",
        "headers": {
            "Authorization": "Baisc "+localStorage.getItem('jwt')
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form
        }
        $.ajax(settings).done(function (response) {
            setAlerts([{
                id: 1,
                type: "success",
                headline: "Berhasil",
                message: "Foto Sertifikat Mobil Telah Diubah"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
        }).fail(function (response){
            setAlerts1([{
                id: 1,
                type: "danger",
                headline: "Gagal",
                message: "Terjadi Kendala [Error Code "+response.status+"]"
            }])
            setTimeout(() => {
                setAlerts1([])
            }, 3000)
            API.Handle403(response,setAlerts)
        })
    }

  return (
    <div>
        <DialogHapusBatal 
                open={open} 
                close={handleClose} 
                title="Edit User" 
                message="Anda Yakin Ingin Mengubah User Ini ?" 
                btn="Edit"
                fun={Submit} 
                // type="delete" 
                // id={id}
            />
        <DialogHapusBatal 
                open={open1} 
                close={handleClose1} 
                title="Hapus Sertifikat Mobil" 
                message="Anda Yakin Ingin Menghapus Foto Sertifikat Ini ?" 
                btn="Hapus"
                fun={HapusSrtf} 
                // type="delete" 
                // id={id}
            />
        <AlertList showIcon={false} alerts={alerts}/>
        <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
        <AlertList showIcon={false} alerts={alerts1}/>
            <AppBar className={classes.appBar}>
            <Toolbar>                       
                <Typography variant="h6" className={classes.title}>
                    Edit User
                </Typography>
                <IconButton edge="start" color="inherit" onClick={Close} aria-label="Close">
                <CloseIcon />
                </IconButton>
            </Toolbar>
            </AppBar>
            <AlertList showIcon={false} alerts={alerts}/>
            <Portlet className={classes.detail}>
                    <PortletBody>
                        <Row id="testmodal" >
                            <Col md={6}>
                                {/* <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Role<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select id="role_type" className="form-control">
                                            <option value="0" selected disabled> Pilih Role</option>
                                            <option value="1" id='role_type_eksekutor'>Eksekutor </option>
                                            <option value="2" id='role_type_informan'>Informan </option>
                                            <option value="11" id='role_type_leasing_cabang_eksekutor' style={{display: 'none'}}>Leasing Cabang & Eksekutor </option>
                                            <option value="9" id='role_type_leasing_cabang' style={{display: 'none'}}>Leasing Cabang </option>
                                            <option value="3" id='role_type_leasing_admin' style={{display: 'none'}}>Leasing Admin</option>
                                            <option value="4" id='role_type_leasing_approver' style={{display: 'none'}}>Leasing Approver</option>
                                            <option value="6" id='role_type_admin_operation'>Admin Operation </option>
                                            <option value="8" id='role_type_admin_finance'>Admin Finance </option>
                                            <option value="10" id='role_type_admin_verifikasi'>Admin Verifikasi </option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Role<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <select className="form-control" id="role_type" name="role_type" required>
                                                <option value="0" selected disabled> Pilih Role</option>
                                            </select>
                                        </div>
                                    </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor KTP<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_ic_number" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nama<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_name" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor Handphone<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_phone" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor Handphone Darurat</label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_emergency_phone" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Tanggal Lahir<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="tanggal_lahir" autocomplete="off"/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Alamat<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <textarea type="text" className="form-control" rows="4" id="mper_address" />
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Provinsi<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select className="form-control" id="mper_province">
                                            <option value="0" selected disabled> Pilih Provinsi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Kota/Kabupaten<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select className="form-control" id="mper_kota">
                                            <option value="0" selected disabled> Pilih Kota/Kabupaten</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Kecamatan<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-7">
                                        <select className="form-control" id="mper_kec">
                                            <option value="0" selected disabled> Pilih Kecamatan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-5 col-form-label">Kode Pos</label>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" id="mper_postal_code" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Pekerjaan Terakhir<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="mper_last_job" />
                                    </div>
                                </div>
                                <div className="form-group row" id="username" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Username</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="name"/>
                                    </div>
                                </div>
                                <div className="form-group row" id="password" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Password</label>
                                    <div className="col-sm-8">
                                        <input type="password" className="form-control" id="pass"/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Status</label>
                                    <div className="col-sm-8">
                                        <div className="form-check form-check-inline mt-1">
                                            <input className="form-check-input" type="radio" name="mper_status" id="mper_status" value="0"/>
                                            <label className="form-check-label" for="exampleRadios1">
                                               Tidak Aktif
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline mt-1">
                                            <input className="form-check-input" type="radio" name="mper_status" id="mper_status" value="1"/>
                                            <label className="form-check-label" for="exampleRadios1">
                                               Aktif
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row" id="referall" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">User Referral</label>
                                    <div className="col-sm-8">
                                        <select className="form-control" id="user_referal" style={{width:'100%'}}>
                                            <option value="0" selected disabled> Pilih Referal</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Registrasi<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="regisdate"/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Terakhir Login</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="mper_last_login_date"/>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Registrasi Via<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <select className="form-control" id="mper_registration_via">
                                            <option id="pilih_registerasi_offline">Offline</option>
                                            <option id="pilih_registerasi_online">Online</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Valid<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <div className="input-daterange input-group date" id="kt_datepicker_5">
                                            <input type="text" className="form-control" name="start" id="vldfrom" autocomplete="off"/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">To</span>
                                            </div>
                                            <input type="text" className="form-control" name="end" id="vldto" autocomplete="off"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row" id="div_foto_ktp_add" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Foto KTP<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="file" id='inputKTP' className="form-control" onChange={FotoKTP}/>
                                        <a id='lihatKTP' href='#' target='_blank' type='button' class='btn btn-brand btn-elevate btn-elevate-air mb-1 mr-1'><i class='fa fa-image'></i> Lihat Foto KTP</a>
                                        <button type="button" name="foto" id="changeKTP" class="btn btn-warning btn-elevate btn-elevate-air mb-1 mr-1"><i class="fas fa-eraser"></i> Ganti</button>
                                        <button type="button" name="foto" id="cancelKTP" class="btn btn-brand btn-elevate btn-elevate-air mb-1 mr-1 mt-2"><i class="fas fa-times"></i> Batal</button>
                                    </div>
                                </div>
                                <div className="form-group row" id="div_foto_user_add" style={{marginTop:'-15px'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Foto User<span style={{color:'#F44336'}}>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="file" id='inputUser' className="form-control" onChange={FotoUser}/>
                                        <a id='lihatUser' href='#' target='_blank' type='button' class='btn btn-brand btn-elevate btn-elevate-air mb-1 mr-1'><i class='fa fa-image'></i> Lihat Foto User</a>
                                        <button type="button" name="foto" id="changeUser" class="btn btn-warning btn-elevate btn-elevate-air mb-1 mr-1"><i class="fas fa-eraser"></i> Ganti</button>
                                        <button type="button" name="foto" id="cancelUser" class="btn btn-brand btn-elevate btn-elevate-air mb-1 mr-1 mt-2"><i class="fas fa-times"></i> Batal</button>
                                    </div>
                                </div>
                                <div className="form-group row" id="div_foto_srtf_add" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Sertifikat Mobil</label>
                                    <div className="col-sm-8">
                                        <input type="file" id="inputSrtf" className="form-control" onChange={FotoSrtfMobil}/>
                                        <a id='lihatSrtf' href='#' target='_blank' type='button' class='btn btn-brand btn-elevate btn-elevate-air mb-1 mr-1'><i class='fa fa-image'></i> Lihat Foto Sertifikat Mobil</a>
                                        <button type="button" name="foto" id="deleteSrtf" onClick={handleClickOpen1} class="btn btn-warning btn-elevate btn-elevate-air mb-1 mr-1"><i class="fas fa-eraser"></i> Hapus</button>
                                        <button type="button" name="foto" id="cancelSrtf" class="btn btn-brand btn-elevate btn-elevate-air mb-1 mr-1 mt-2"><i class="fas fa-times"></i> Batal</button>
                                    </div>
                                </div>
                                <div className="form-group row" id="div_tbnk_name" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Bank<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="tbnk_name" />
                                    </div>
                                </div>
                                <div className="form-group row" id="div_tbnk_number" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">No Rekening<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="tbnk_number" />
                                    </div>
                                </div>
                                <div className="form-group row" id="div_tbnk_receiver_name" style={{marginTop:'-15px',display:'none'}}>
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Atas Nama Rekening<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="tbnk_receiver_name" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                            </Col>
                        </Row>
                        <Row>
                            <Col md>
                                <button type="button" className="btn btn-warning btn-elevate" onClick={props.close}><i class="fas fa-arrow-left"></i>Kembali</button>
                                <button type="sumbit" className="btn btn-brand btn-elevate pull-right" onClick={handleClickOpen}><i class="fas fa-save"></i>Simpan</button>
                            </Col>
                        </Row>
                    </PortletBody>
                </Portlet>
     </Dialog>
    </div>
  );
}
