/* eslint-disable no-restricted-imports */
import React from 'react';
import {
    Portlet,
    PortletBody,
    PortletHeader,
  } from "../../../partials/content/Portlet";
  import InputLabel from '@material-ui/core/InputLabel'
  import FormControl from '@material-ui/core/FormControl'
  import { ThemeProvider } from '@material-ui/styles'
  import Button from '@material-ui/core/Button'
  import { AlertContainer, Alert } from "react-bs-notifier";
  import Input from '@material-ui/core/Input';
  import {
    Nav,
    Col,
    Row,
    Tab
  } from 'react-bootstrap';
  import $ from 'jquery'
  import API from '../../API'
//   import sha256 from 'sha256'
// import { createMuiTheme,makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//     container: {
//       display: 'flex',
//       flexWrap: 'wrap',
//     },
//     textField: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//       width: 200,
//     },
//     dense: {
//       marginTop: 19,
//     },
//     menu: {
//       width: 200,
//     },
//   }));
  
  export default function SettingAkun() {
    //   const classes = useStyles();
      const [anjay, setAnjay] = React.useState(false)
      const [alerts,setAlerts] = React.useState([])
      
      const handleclick = () => {
        var oldpw = $('#passwordlama').val()
        var newpw = $('#passwordbaru').val()
        var vldpw = $('#passwordbarukonfirm').val()
        console.log(oldpw+' '+newpw+' '+vldpw)
        setAnjay(false)
        console.log(anjay)
        $(document).ready(function () {
            // eslint-disable-next-line no-unused-vars
            var settings = {
                "async": true,
                "crossDomain": true,
                "url": API.Userapi+"v1/User/change_password",
                "method": "POST",
                "headers": {
                    "Authorization": "Basic "+ localStorage.getItem('jwt'),
                    "Content-Type": "application/json"
                },
                "processData": false,
                // "data": "{\n\t\"old_password\": \""+sha256(oldpw)+"\",\n\t\"new_password\": \""+sha256(newpw)+"\"\n}"
            }
    
            // $.ajax(settings).done(function (response) {
            //     console.log(response.data)
            //     console.log(response)
            // })
        })
      }

    return (
        <>
        <AlertContainer position="top-right">
					{anjay ? (
						<Alert type="info" headline="Oh Some Info" timeout="3000">
							Something happened of only moderate importance.
						</Alert>
					) : null}
		</AlertContainer>
         <Portlet>
             <PortletHeader title="Pengaturan Akun">
             </PortletHeader>
             <PortletBody>
                <Tab.Container id="left-tabs-example" defaultActiveKey="pertama">
                    <Row>
                        <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="kedua" disabled>Profilku</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="pertama">Ganti Password</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        </Col>
                        <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="pertama">
                                {/* <div className="kt-section kt-margin-t-30">
                                    <div className="kt-section__body">
                                        <div className="form-group row">
                                            <label className="col-lg-3 col-form-label">
                                                Header Skin:
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                               <div className="row">
                                   <div className="col-md-3">

                                   </div>
                                   <div className="col-md-6">
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel
                                                htmlFor="password-current"
                                            >
                                                {'Password Lama'}
                                            </InputLabel>
                                            <Input
                                                id="passwordlama"
                                                name="currentPass"
                                                type="password"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel
                                                htmlFor="password-current"
                                            >
                                                {'Password Baru'}
                                            </InputLabel>
                                            <Input
                                                id="passwordbaru"
                                                name="currentPass"
                                                type="password"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel
                                                htmlFor="password-current"
                                            >
                                                {'Konfirmasi Password Baru'}
                                            </InputLabel>
                                            <Input
                                                id="passwordbarukonfirm"
                                                name="currentPass"
                                                type="password"
                                            />
                                        </FormControl>
                                        <ThemeProvider theme="primary">
                                            <Button variant="contained" color="primary" style={{width:'100%',marginTop:'10px'}} onClick={handleclick}>
                                                Submit
                                            </Button>
                                        </ThemeProvider>
                                   </div>
                                   <div className="col-md-3">
                                   </div>
                               </div>
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
             </PortletBody>
         </Portlet>
        </>
    )
}