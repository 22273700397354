/* eslint-disable eqeqeq */
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { AlertList} from "react-bs-notifier";
import DialogHapusBatal from '../Utils/DialogHapusBatal'
import { Link, useHistory  } from "react-router-dom";
// import { useForm } from 'react-hook-form'
import $ from 'jquery';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    // PortletHeaderToolbar
  } from "../../../partials/content/Portlet";
import API from '../../API'
import 'jquery-validation'
// import 'jquery-form-validator'
import 'bootstrap-datepicker'
import 'select2'
import moment from 'moment'

export default function TambahUser() {
    const [alerts, setAlerts] = React.useState([]);
    const [fktp, setFktp] = React.useState(null)
    const [fuser, setFuser] = React.useState(null)
    const [fsrtf, setFsrtf] = React.useState(null)
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    // const { register, handleSubmit, watch, errors } = useForm()

    React.useEffect(() => {
        $(document).ready(function () {
            $('#lahir,#vldfrom,#vldto,#regisdate').datepicker({
                todayHighlight: true,
                autoclose: true,
                format: "yyyy-mm-dd",
            });
            $('#vldfrom').on('change',function () {
                console.log(moment($('#vldfrom').datepicker('getDate')).add(1, 'years').toDate())
                $('#vldto').datepicker('setDate', moment($('#vldfrom').datepicker('getDate')).add(1, 'years').toDate())
                
            })
            $("#tambahuser").validate();
            $('#mper_province').select2({
                minimumInputLength: 0,
                ajax: {
                    type: 'get',
                    url: API.Utilsapi+'v1/address/provinsi',
                    headers: {
                        "Authorization": "Baisc "+localStorage.getItem('jwt')
                    },
                    dataType: "json",
                    error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts);
                    },
                    data: function (params) {
                        return {
                            "select2" : params.term,
                            "length": 34,
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: $.map(data.data, function (item) {
                                return {
                                    text: item.mprv_name,
                                    id: item.mprv_id,
                                };
                            })
                        };
                    }
                }
            }).on('change', function(){
                $('#mper_kota,#mper_kec').val(0).trigger('change');
            })
            $('#mper_kota').select2({
                minimumInputLength: 0,
                ajax: {
                    type: 'get',
                    url: API.Utilsapi+'v1/address/kota',
                    headers: {
                        "Authorization": "Baisc "+localStorage.getItem('jwt')
                    },
                    dataType: "json",
                    error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts);
                    },
                    data: function (params) {
                        var mper_provinsi = $( "#mper_province option:selected" ).text();
                        console.log(mper_provinsi);
                        if(  mper_provinsi != 'Pilih Provinsi' ) {
                            return {
                                "select2" : params.term,
                                "mprv": mper_provinsi,
                                "length": 514,
                            };
                        }else{
                            return {
                                "select2" : params.term,
                                "length": 514,
                            };
                        }
                    },
                    processResults: function (data, params) {
                        return {
                            results: $.map(data.data, function (item) {
                                return {
                                    text: item.mcty_name,
                                    id: item.mcty_id,
                                };
                            })
                        };
                    }
                }
            }).on('change', function(){
                $('#mper_kec').val(0).trigger('change');
            });
            $('#mper_kec').select2({
                minimumInputLength: 0,
                ajax: {
                    type: 'get',
                    url: API.Utilsapi+'v1/address/distrik',
                    headers: {
                        "Authorization": "Baisc "+localStorage.getItem('jwt')
                    },
                    dataType: "json",
                    error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts);
                    },
                    data: function (params) {
                        var mper_kota = $( "#mper_kota option:selected" ).text();
                        console.log(mper_kota);
                        if( mper_kota != 'Pilih Kota/Kabupaten' ) {
                            return {
                                "select2" : params.term,
                                "mcty": mper_kota,
                                "length": 7215,
                            };
                        }else{
                            return {
                                "select2" : params.term,
                                "length": 7215,
                            };
                        }
                    },
                    processResults: function (data, params) {
                        return {
                            results: $.map(data.data, function (item) {
                                return {
                                    text: item.mdst_name,
                                    id: item.mdst_id,
                                };
                            })
                        };
                    }
                }
            });
            $('#user_referal').select2({
                minimumInputLength: 0,
                ajax: {
                    type: 'get',
                    url: API.Userapi+'v1/user/referal',
                    headers: {
                        "Authorization": "Baisc "+localStorage.getItem('jwt')
                    },
                    dataType: "json",
                    error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts);
                    },
                    data: function (params) {
                        return {
                            "select2" : params.term,
                            "length": 10,
                        };
                    },
                    processResults: function (response, params) {
                        return {
                            results: $.map(response.data, function (item) {
                                return {
                                    text: item.mper_name,
                                    id: item.mper_id,
                                };
                            })
                        };
                    }
                }
            });

            $('#role_type').select2({
                minimumInputLength: 0,
                ajax: {
                    type: 'get',
                    url: API.Userapi+'v1/user/mperole',
                    headers: {
                        "Authorization": "Basic "+localStorage.getItem('jwt')
                    },
                    dataType: "json",
                    error: function (xhr, error, thrown, settings) {
                        API.Handle403(xhr,setAlerts);
                    },
                    data: function (params) {
                        return {
                            "select2" : params.term,
                            "length": 11,
                        };
                    },
                    processResults: function (response, params) {
                        return {
                            results: $.map(response.data, function (item) {
                                return {
                                    text: item.mple_name,
                                    id: item.mper_type,
                                };
                            })
                        };
                    }
                }
            });

            $('#role_type').change( function(){
            var mper_id = localStorage.getItem('mper')
            var role_type = $( "#role_type option:selected" ).val();
            if( role_type !=  1 && role_type != 2 ){
            $('#username').show("slow");
            $('#password').show("slow");
            $('#div_tbnk_name').hide();
            $('#div_tbnk_number').hide();
            $('#div_tbnk_receiver_name').hide();
            $('#referall').hide('slow');
            $('#div_foto_user').hide('slow');
            $('#div_foto_ktp').hide('slow');
            $('#div_foto_srtf_add').hide('slow');
            $('#foto_srtf_delete').hide('hide');
            // $('#div_foto_ktp_add').show();
            // $('#div_foto_user_add').show();
            }else{
            $('#div_tbnk_name').show("slow");
            $('#div_tbnk_number').show("slow");
            $('#div_tbnk_receiver_name').show("slow");
            $('#username').hide();
            $('#password').hide();
            $('#referall').show('slow');
            $('#div_foto_user_add').show('slow');
            $('#div_foto_ktp_add').show('slow');
            $('#div_foto_srtf_add').show('slow');
            $('#div_foto_user').hide('slow');
            $('#div_foto_ktp').hide('slow');
            console.log(" mper "+mper_id);
            if ( Number.isInteger(parseInt(mper_id)) ) {
                $('#foto_srtf_delete').show('slow');
            }else{
                $('#foto_srtf_delete').hide('hide');
            }
            }
            // 5 "Internal Admin" ;
            // 6 "Admin Operation" ;
            // 7 "Admin Registrasi" ;
            // 8 "Admin Finance" ;
            // 10 "Admin verifikasi";

            if (Number.isInteger(parseInt(mper_id))) {
            $('#div_app_type').show();
            $('#app_type').attr('disabled','disabled')
            }

            // if( role_type == 5 || role_type == 6 || role_type == 7 || role_type == 8 || role_type == 10 ){
            //     $('#div_app_type').hide();
            // }else{
            //     $('#div_app_type').hide();
            // }
            });
            $('#mper_ic_number').keypress(function(event) {
                return /\d/.test(String.fromCharCode(event.keyCode));
            }).bind('keyup paste', function(){
                this.value = this.value.replace(/[^0-9]/g, '');
            });
        })
    },[setAlerts])

    const ImageKTP = (e) => {
        setFktp(e.target.files[0])
    }

    const ImageUSER = (e) => {
        setFuser(e.target.files[0])
    }

    const ImageSRTF = (e) => {
        setFsrtf(e.target.files[0])
    }

    function handleClickOpen() { 
        if ($('#tambahuser').valid() == false) {
            setAlerts([{
                id: 1,
                type: "info",
                headline: "Validate",
                message: "Lengkapi Data Yang Tersedia"
            }])
            setTimeout(() => {
                setAlerts([])
            }, 3000)
        }else{
            setOpen(true);
        }
    }

    function handleClose() {
        setOpen(false);
    }

    const Submit = () => {
                handleClose()
                // console.log($('form').valid())
                var mper_ic_number = $('#mper_ic_number').val();
                var mper_name = $('#mper_name').val();
                var mper_phone = $('#mper_phone').val();
                var mper_emergency_phone = $('#mper_emergency_phone').val();
                var tanggal_lahir = $('#lahir').val();
                var mper_address = $('#mper_address').val();
    
                var mper_province = $( "#mper_province option:selected" ).val();
                var mper_kota = $( "#mper_kota option:selected" ).val();
                var mper_kec = $( "#mper_kec option:selected" ).val();
    
                var mper_postal_code = $('#mper_postal_code').val();
                var mper_last_job = $('#mper_last_job').val();
                var role_type = $('#role_type').val();
                var mper_username = $('#name').val();
                var mper_password = $('#pass').val();
                var mper_dpadmin = $('#droppoint').val();
    
                var mper_referal_id = $("#user_referal option:selected").val();
    
                var mper_registration_date = $('#regisdate').val();
                var mper_registration_via = $('#mper_registration_via').val();
                var mper_vaild_from = $('#vldfrom').val();
                var mper_valid_to = $('#vldto').val();
                var mper_status = $('input[name=mper_status]:checked').val();
                if( mper_status == "Non Aktif" ) {
                    mper_status = 1 ;
                }else if(mper_status == "Aktif") {
                    mper_status = 0 ;
                }
                var tbnk_name = $('#tbnk_name').val();
                var tbnk_number = $('#tbnk_number').val();
                var tbnk_receiver_name = $('#tbnk_receiver_name').val();
                var form = new FormData();
                // form.append("app_type", app_type);
                form.append("mper_ic_number", mper_ic_number);
                form.append("mper_name", mper_name);
                form.append("mper_phone", mper_phone);
                form.append("mper_dob", tanggal_lahir);
                form.append("mper_address", mper_address);
                form.append("mper_postal_code", mper_postal_code);
                form.append("mper_province", mper_province);
                form.append("mper_city", mper_kota);
                form.append("mper_type", role_type);
                form.append("mper_district", mper_kec);
                form.append("mper_last_job", mper_last_job);
                form.append("mper_emergency_phone", mper_emergency_phone);
                form.append("mper_registration_date", mper_registration_date);
                form.append("mper_registration_via", mper_registration_via);
                form.append("mper_valid_from", mper_vaild_from);
                form.append("mper_valid_to", mper_valid_to);
                form.append("foto_user", fuser);
                form.append("foto_ktp", fktp);
                form.append("foto_srtf", fsrtf);
                // form.append("app_type", app_type);
    
                if( role_type != 1 ){
                    form.append('mper_username', mper_username) ;
                    form.append('mper_password', mper_password) ;
                }
                // else{
                // form['mper_face_img_id'] = foto_user ;
                // form['mper_medi_ktp'] = foto_ktp ;
                // }
                if( mper_referal_id != "" && mper_referal_id != null && mper_referal_id != 'null' && mper_referal_id != 0 ){
                    form.append('mper_referal_id', mper_referal_id);
                }
                if( tbnk_name != "" && tbnk_number != "" && tbnk_receiver_name != ""){
                    form.append('tbnk_name', tbnk_name) ;
                    form.append('tbnk_number', tbnk_number) ;
                    form.append('tbnk_receiver_name', tbnk_receiver_name) ;
                }
    
                if (role_type == 11) {
                form.append('tadp_array_id', mper_dpadmin) ; // Dpoint
                } else if (role_type == 9){
                form.append('tadp_array_id', mper_dpadmin) ;
                }
                console.log(form);    
                var settings = {
                    "async": true,
                    "crossDomain": true,
                    "url": API.Userapi+"v1/user/addUser",
                    "method": "POST",
                    "headers": {
                      "Authorization": "Baisc "+localStorage.getItem('jwt')
                    },
                    "processData": false,
                    "contentType": false,
                    "mimeType": "multipart/form-data",
                    "data": form
                }
                
                
                    $.ajax(settings).done(function (response) {
                        console.log(response)
                        $("#tambahuser input").val('')
                        setAlerts([{
                            id: 1,
                            type: "success",
                            headline: "Berhasil",
                            message: "User Telah Ditambahkan"
                        }])
                        setTimeout(() => {
                            setAlerts([])
                            history.push("/user");
                        }, 1000)
                        return 0
                    }).fail(function (response) {
                        console.log(response)
                        setAlerts([{
                            id: 1,
                            type: "danger",
                            headline: "Gagal",
                            message: "Terjadi Kendala [Error Code "+response.status+"]"
                        }])
                        setTimeout(() => {
                            setAlerts([])
                        }, 3000)
                        API.Handle403(response,setAlerts)
                        return 0
                    })

    }

    return(
        <>
        <AlertList  showIcon={false} alerts={alerts}/>
        <DialogHapusBatal 
                open={open} 
                close={handleClose} 
                title="Tambah User" 
                message="Anda Yakin Ingin Menambah User ?" 
                btn="Tambahkan"
                // fun={handleSubmit(Submit)}
                fun={Submit} 
                // type="delete" 
                // id={id}
            />
        <Portlet>
            <PortletHeader title="Tambah User"/>
                    <PortletBody>
                        <form id="tambahuser" onSubmit={(e)=>{e.preventDefault()}}>
                            <Row>
                                <Col md={6}>
                                    {/* <div className="form-group row" >
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Role<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <select id="role_type" name="role_type" className="form-control" required>
                                                <option value="0" selected disabled> Pilih Role</option>
                                                <option value="1" id='role_type_eksekutor'>Eksekutor </option>
                                                <option value="2" id='role_type_informan'>Informan </option>
                                                <option value="11" id='role_type_leasing_cabang_eksekutor' style={{display: 'none'}}>Leasing Cabang & Eksekutor </option>
                                                <option value="9" id='role_type_leasing_cabang' style={{display: 'none'}}>Leasing Cabang </option>
                                                <option value="3" id='role_type_leasing_admin' style={{display: 'none'}}>Leasing Admin</option>
                                                <option value="4" id='role_type_leasing_approver' style={{display: 'none'}}>Leasing Approver</option>
                                                <option value="6" id='role_type_admin_operation'>Admin Operation </option>
                                                <option value="8" id='role_type_admin_finance'>Admin Finance </option>
                                                <option value="10" id='role_type_admin_verifikasi'>Admin Verifikasi </option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Role<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <select className="form-control" id="role_type" name="role_type" required>
                                                <option value="0" selected disabled> Pilih Role</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor KTP<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : 3710298703432156" className="form-control" id="mper_ic_number" name="mper_ic_number" minlength="16" maxlength="16" autocomplete="off" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nama<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Nama lengkap" className="form-control" id="mper_name" name="mper_name" required autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor Handphone<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="tel" placeholder="Contoh : 0895xxxxxxxx" className="form-control" id="mper_phone" name="mper_phone" required autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Nomor Handphone Darurat</label>
                                        <div className="col-sm-7">
                                            <input type="tel" placeholder="Contoh : 0895xxxxxxxx" className="form-control" id="mper_emergency_phone" name="mper_emergency_phone" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Tanggal Lahir<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <input type="text" placeholder="Contoh : YYYY-MM-DD" className="form-control" id="lahir" name="lahir" autocomplete="off" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Alamat<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <textarea type="text" placeholder="Alamat lengkap" className="form-control" rows="3" id="mper_address" name="mper_address" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Provinsi<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <select className="form-control" id="mper_province" name="mper_province" required>
                                                <option value="0" selected disabled> Pilih Provinsi</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Kota/Kabupaten<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <select className="form-control" id="mper_kota" name="mper_kota" required>
                                                <option value="0" selected disabled> Pilih Kota/Kabupaten</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Kecamatan<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-7">
                                            <select className="form-control" id="mper_kec" name="mper_kec" required>
                                                <option value="0" selected disabled> Pilih Kecamatan</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-5 col-form-label">Kode Pos</label>
                                        <div className="col-sm-7">
                                            <input type="number" placeholder="Contoh : 10660" className="form-control" id="mper_postal_code" name="mper_postal_code" required/>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group row">
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Pekerjaan Terakhir<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-8">
                                            <input type="text" placeholder="Contoh : Karyawan Swasta" className="form-control" id="mper_last_job" name="mper_last_job" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" id="username" style={{marginTop:'-15px',display:'none'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Username</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" id="name" name="name" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" id="password" style={{marginTop:'-15px',display:'none'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Password</label>
                                        <div className="col-sm-8">
                                            <input type="password" className="form-control" id="pass" name="pass" required/>
                                        </div>
                                    </div>
                                    {/* <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Status</label>
                                        <div className="col-sm-8">
                                        <div class="form-check form-check-inline mt-1">
                                                <input class="form-check-input" type="radio" name="mper_status" id="mper_status" value="0"/>
                                                <label class="form-check-label" for="exampleRadios1">
                                                Tidak Aktif
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline mt-1">
                                                <input class="form-check-input" type="radio" name="mper_status" id="mper_status" value="1"/>
                                                <label class="form-check-label" for="exampleRadios1">
                                                Aktif
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="form-group row" id="referall" style={{marginTop:'-15px',display:'none'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">User Referral</label>
                                        <div className="col-sm-8">
                                            <select className="form-control" id="user_referal" name="user_referal" style={{width:'100%'}}>
                                                <option value="0" selected disabled> Pilih Referal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Registrasi<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-8">
                                            <input type="text" placeholder="Contoh : YYYY-MM-DD" className="form-control" id="regisdate" name="regisdate" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Registrasi Via<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-8">
                                            <select className="form-control" id="mper_registration_via" name="mper_registration_via" required>
                                                <option value="0" selected disabled> Pilih Registrasi</option>
                                                <option>Offline</option>
                                                <option>Online</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Tanggal Valid<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-8">
                                            <div className="input-daterange input-group date" id="kt_datepicker_5">
                                                <input type="text" placeholder="2000-0-0" className="form-control" id="vldfrom" name="vldfrom" autocomplete="off"/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">To</span>
                                                </div>
                                                <input type="text" placeholder="2000-0-0" className="form-control" id="vldto" name="vldto"  autocomplete="off"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row" id="div_foto_ktp_add" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto KTP<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-8 custom-file">
                                            <input type="file" className="" id="foto_ktp_add" name="Fktp" onChange={ImageKTP} required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" id="div_foto_user_add" style={{marginTop:'-15px'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto User<span style={{color:'#F44336'}}>*</span></label>
                                        <div className="col-sm-8 custom-file">
                                            <input type="file" className="" id="foto_user_add" name="Fuser" onChange={ImageUSER} required/>
                                        </div>
                                    </div>
                                    <div className="form-group row" id="div_foto_srtf_add" style={{marginTop:'-15px',display:'none'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Foto Sertifikat Mobil</label>
                                        <div className="col-sm-8 custom-file">
                                            <input type="file" className="" id="foto_srtf_add" name="Fsrtf" onChange={ImageSRTF}/>
                                        </div>
                                    </div>
                                    <div className="form-group row" id="div_tbnk_name" style={{marginTop:'-15px',display:'none'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Nama Bank<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                        <div className="col-sm-8">
                                            <input type="text" placeholder="IDX" className="form-control" id="tbnk_name" name="tbnk_name"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" id="div_tbnk_number" style={{marginTop:'-15px',display:'none'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">No Rekening<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                        <div className="col-sm-8">
                                            <input type="number"  placeholder="12345xxxxxxxxx" className="form-control" id="tbnk_number" name="tbnk_number"/>
                                        </div>
                                    </div>
                                    <div className="form-group row" id="div_tbnk_receiver_name" style={{marginTop:'-15px',display:'none'}}>
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Atas Nama Rekening<span style={{color:'#F44336'}}> (Opsional)</span></label>
                                        <div className="col-sm-8">
                                            <input type="text"  placeholder="Santosa" className="form-control" id="tbnk_receiver_name" name="tbnk_receiver_name" />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                <Link to="/user" className="btn btn-warning btn-elevate">
                                    <i className="fas fa-arrow-left"></i>Kembali
                                </Link>
                                    {/* <button type="button" className="btn btn-danger btn-elevate" onClick={props.close}><i className="fas fa-arrow-left"></i>Kembali</button> */}
                                    <button type="submit" className="btn btn-brand btn-elevate pull-right" onClick={handleClickOpen}><i className="fas fa-save"></i>Simpan</button>
                                </Col>
                            </Row>
                        </form>
                        {/* <button type="button" class="btn btn-brand btn-elevate" onClick={handleClickOpen} style={{width:'100%'}}>Simpan</button> */}
                    </PortletBody>
                </Portlet>
        </>
    )
}