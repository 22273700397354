import React, { useEffect } from "react";
import $ from "jquery";
import API from "../../API";
import "select2";
import DialogHapusBatal from "../Utils/DialogHapusBatal";

const FormTambahMitra = ({ setAlerts, koordinatorMperID, loadTableMitra }) => {
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);

  useEffect(() => {
    $(document).ready(function() {
      $("#select-mitra").select2({
        minimumInputLength: 0,
        placeholder: "Pilih Mitra Baru",
        dropdownParent: $("#form-add-mitra-wrapper"),
        ajax: {
          type: "get",
          url: API.Userapi + "v1/koordinator/select_new_mitra",
          headers: {
            Authorization: "Basic " + localStorage.getItem("jwt"),
          },
          dataType: "json",
          error: function(xhr, error, thrown, settings) {
            API.Handle403(xhr, setAlerts);
          },
          data: function(params) {
            return {
              select2: params.term,
              length: 10,
            };
          },
          processResults: function(response, params) {
            return {
              results: $.map(response.data, function(item) {
                return {
                  mper_phone: item.mper_phone,
                  mper_address: item.mper_address,
                  text: item.mper_name,
                  id: item.mper_id,
                };
              }),
            };
          },
        },
      });
    });
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if ($("#select-mitra").val() === undefined) {
      setAlerts([
        {
          id: 1,
          type: "info",
          headline: "Validate",
          message: "Lengkapi Data Yang Tersedia",
        },
      ]);
      setTimeout(() => {
        setAlerts([]);
      }, 3000);
    } else {
      setConfirmSubmit(true);
    }
  };

  const Submit = () => {
    var form = new FormData();

    form.append("koordinator_mper_id", koordinatorMperID);
    form.append("mper_id", $("#select-mitra").val());

    $.ajax({
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/add_mitra",
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    })
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Mitra Telah Ditambahkan",
          },
        ]);
        setAlerts([]);
        loadTableMitra();
        setConfirmSubmit(false);
        // $("#koordinator-mitra-table").ajax.reload(null, false);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          $("#form-add-mitra-wrapper").hide("slow");
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  return (
    <div id="form-add-mitra-wrapper" style={{ display: "none", marginBottom: "10px" }}>
      <DialogHapusBatal
        open={confirmSubmit}
        close={(e) => setConfirmSubmit(false)}
        title="Tambah Mitra Koordinator Wilayah"
        message="Anda Yakin Ingin Menambah Mitra Untuk Koordinator ini ?"
        btn="Tambahkan"
        fun={Submit}
      />
      <div className="row">
        <div className="col-md-4">
          <select
            className="form-control"
            name="mper_id"
            required
            id="select-mitra"
            style={{ width: "100%" }}
          ></select>
        </div>
        <div className="col-md-4">
          <button className="btn btn-primary btn-elevate" type="button" onClick={handleSubmit}>
            Tambah
          </button>
          <button
            onClick={() => {
              $("#form-add-mitra-wrapper").hide("slow");
            }}
            className="btn btn-lable-primary"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormTambahMitra;
