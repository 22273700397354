import React, { useEffect } from "react";
import $ from "jquery";
import API from "../../API";
import "select2";
import DialogHapusBatal from "../Utils/DialogHapusBatal";

const FormTambahPotonganKorwil = ({ setAlerts, koordinatorMperID, loadTablePotongan }) => {
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);

  useEffect(() => {
    $(document).ready(function() {
      $(".mask-currency").mask("#.##0", { reverse: true });
    });
  });

  const getMaskingValue = (value) => {
    console.log(value);
    return value.replace(/\D/g, "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmSubmit(true);
  };

  const Submit = () => {
    var form = new FormData();

    form.append("koordinator_mper_id", koordinatorMperID);
    form.append("nominal_potongan", getMaskingValue($("#input-nominal_potongan").val()));

    $.ajax({
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/add_potongan",
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    })
      .done(function(response) {
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Potongan Korwil Telah Ditambahkan",
          },
        ]);
        loadTablePotongan();
        $("#input-nominal_potongan").val("");
        $("#form-add-potongan-kowril-wrapper").hide("slow");
        setAlerts([]);
        setConfirmSubmit(false);
        // $("#koordinator-potongan-kowril-table").ajax.reload(null, false);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          $("#form-add-potongan-kowril-wrapper").hide("slow");
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  return (
    <div
      id="form-add-potongan-kowril-wrapper"
      style={{ display: "none", marginBottom: "10px", marginTop: "10px" }}
    >
      <DialogHapusBatal
        open={confirmSubmit}
        close={(e) => setConfirmSubmit(false)}
        title="Tambah Potongan Koordinator Wilayah"
        message="Anda Yakin Ingin Menambah Potongan Untuk Koordinator ini ?"
        btn="Tambahkan"
        fun={Submit}
      />
      <div className="row">
        <div className="col-md-6">
          <input type="text" className="form-control mask-currency" id="input-nominal_potongan" />
        </div>
        <div className="col-md-6">
          <button className="btn btn-primary btn-elevate" type="button" onClick={handleSubmit}>
            Tambah
          </button>
          <button
            onClick={() => {
              $("#form-add-potongan-kowril-wrapper").hide("slow");
            }}
            className="btn btn-lable-primary"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormTambahPotonganKorwil;
