import React, { useEffect, useState } from "react";
import { AlertList } from "react-bs-notifier";
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import $, { ajax } from "jquery";
import API from "../../API";
import "select2";
import { Link, useHistory } from "react-router-dom";
import DialogHapusBatal from "../Utils/DialogHapusBatal";

const TambahKoordinator = () => {
  const [alerts, setAlerts] = useState([]);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const history = useHistory();

  useEffect(() => {
    $(document).ready(function() {
      $("#select-koordinator").select2({
        minimumInputLength: 0,
        placeholder: "Pilih Koordinator",
        ajax: {
          type: "get",
          url: API.Userapi + "v1/koordinator/select_new_koordinator",
          headers: {
            Authorization: "Basic " + localStorage.getItem("jwt"),
          },
          dataType: "json",
          error: function(xhr, error, thrown, settings) {
            API.Handle403(xhr, setAlerts);
          },
          data: function(params) {
            return {
              select2: params.term,
              length: 10,
            };
          },
          processResults: function(response, params) {
            return {
              results: $.map(response.data, function(item) {
                return {
                  mper_phone: item.mper_phone,
                  mper_address: item.mper_address,
                  text: item.mper_name,
                  id: item.mper_id,
                };
              }),
            };
          },
        },
      });

      $("#select-koordinator").on("change", function() {
        var data = $("#select-koordinator").select2("data")[0];
        $("#mper_address").val(data.mper_address);
      });
    });
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if ($("#tambahKorWil").valid() == false) {
      setAlerts([
        {
          id: 1,
          type: "info",
          headline: "Validate",
          message: "Lengkapi Data Yang Tersedia",
        },
      ]);
      setTimeout(() => {
        setAlerts([]);
      }, 3000);
    } else {
      setConfirmSubmit(true);
    }
  };

  const Submit = () => {
    var form = new FormData();

    form.append("mper_id", $("#select-koordinator").val());
    form.append("transit_pool", $("#transit_pool").val());
    form.append("cadangan_korwil", $("#cadangan_korwil").val());
    form.append("nama_wilayah", $("#nama_wilayah").val());
    form.append("note", $("#note").val());

    $.ajax({
      async: true,
      crossDomain: true,
      url: API.Userapi + "v1/koordinator/add",
      method: "POST",
      headers: {
        Authorization: "Basic " + localStorage.getItem("jwt"),
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    })
      .done(function(response) {
        $("#tambahKorWil")[0].reset();
        setAlerts([
          {
            id: 1,
            type: "success",
            headline: "Berhasil",
            message: "Koordinator Telah Ditambahkan",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
          history.push("/koordinator");
        }, 1000);
        return 0;
      })
      .fail(function(response) {
        console.log(response);
        setAlerts([
          {
            id: 1,
            type: "danger",
            headline: "Gagal",
            message: "Terjadi Kendala [Error Code " + response.status + "]",
          },
        ]);
        setTimeout(() => {
          setAlerts([]);
        }, 3000);
        API.Handle403(response, setAlerts);
        return 0;
      });
  };

  return (
    <>
      <AlertList showIcon={false} alerts={alerts}></AlertList>
      <DialogHapusBatal
        open={confirmSubmit}
        close={(e) => setConfirmSubmit(false)}
        title="Tambah Koordinator Wilayah"
        message="Anda Yakin Ingin Menambah Koordinator Wilayah ?"
        btn="Tambahkan"
        // fun={handleSubmit(Submit)}
        fun={Submit}
        // type="delete"
        // id={id}
      />
      <Portlet>
        <PortletHeader title="Tambah Koordinator Wilayah"></PortletHeader>
        <PortletBody>
          <form onSubmit={handleSubmit} id="tambahKorWil">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-5 col-form-label">Nama Koordinator Wilayah</label>
                  <div className="col-sm-7">
                    <select
                      className="form-control"
                      name="koordinator_id"
                      required
                      id="select-koordinator"
                      style={{ width: "100%" }}
                    ></select>
                  </div>
                </div>

                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Alamat</label>
                  <div className="col-sm-7">
                    <textarea
                      type="text"
                      className="form-control"
                      rows="4"
                      id="mper_address"
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Long Lat</label>
                  <div className="col-sm-7">
                    <input name="long_lat" type="text" className="form-control" readOnly />
                  </div>
                </div>

                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Nama Transit Pool</label>
                  <div className="col-sm-7">
                    <input id="transit_pool" type="text" className="form-control" />
                  </div>
                </div>

                {/* <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Cadangan Korwil</label>
                  <div className="col-sm-7">
                    <input id="cadangan_korwil" type="number" className="form-control" />
                  </div>
                </div> */}

                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Nama Wilayah</label>
                  <div className="col-sm-7">
                    <input id="nama_wilayah" type="text" className="form-control" />
                  </div>
                </div>

                <div className="form-group row" style={{ marginTop: "-15px" }}>
                  <label className="col-sm-5 col-form-label">Note</label>
                  <div className="col-sm-7">
                    <input id="note" type="text" className="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            <div className="row">
              <div className="col">
                <Link to="/koordinator" className="btn btn-warning btn-elevate">
                  <i className="fas fa-arrow-left"></i>Kembali
                </Link>
                <button type="submit" className="btn btn-brand btn-elevate pull-right">
                  <i className="fas fa-save"></i>Simpan
                </button>
              </div>
            </div>
          </form>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default TambahKoordinator;
